import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dhl-shipments-delete-all-dialog',
  templateUrl: './shipments-delete-all-dialog.component.html',
  styleUrls: ['./shipments-delete-all-dialog.component.scss']
})
export class ShipmentsDeleteAllDialogComponent{

  constructor(public dialogRef: MatDialogRef<ShipmentsDeleteAllDialogComponent>) { }

  closeDialog(willDelete: boolean) {
    this.dialogRef.close(willDelete);
  }
}
