import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { ICustomerException } from '../models/customer.model';


@Injectable({
  providedIn: 'root'
})
export class CustomerException implements Resolve<Array<ICustomerException>> {

  constructor(private customerService: CustomerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const exception: ICustomerException = {
      EXCEPTION_ID: route.params.id,
      PAGE_NUMBER: 0,
      PAGE_SIZE: 20
    }
    return this.customerService.getCustomerException(exception);
  }
}
