export interface ICustomer {
  CUS_ID?: number;
  CUS_CODE?: string;
  CUS_NAME?: string;
  CUS_ADDRESS?: string;
  CUS_STATE?: string;
  CUS_UNLOCO?: string;
  CUS_TAX?: string;
  CUS_STATUS?: 0 | 1;
  CUS_USER?: string;
  CUS_GROUP_ID?: number;
  CUS_COUNTRY?: string;
  CUS_CONTACT?: string;
  CUS_FILTER?: string;
  IS_SELECT?: number;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface ICustomerContact {
  CONTACT_ID?: number;
  CUS_ID?: number;
  CUS_CODE?: string;
  CUS_NAME?: string;
  CONTACT_NAME?: string;
  CONTACT_EMAIL?: string;
  CONTACT_STATUS?: 0 | 1;
  CONTACT_USER?: string;
  CONTACT_INC_DATE?: Date;
  CONTACT_DATETIME?: Date;
}

export interface ICustomerException {
  EXCEPTION_ID?: number;
  CUS_ID?: number;
  CUS_NAME?: string;
  CUS_GRP_ID?: number;
  CUS_GRP_NAME?: string;
  EXCEPTION_CUT_OFF?: 0 | 1;
  EXCEPTION_CUT_OFF_DAYS?: number;
  EXCEPTION_REFERENCE?: 0 | 1;
  EXCEPTION_REVIEW?: 0 | 1;
  EXCEPTION_OBSERVATION?: string;
  EXCEPTION_REQUESTOR?: string;
  EXCEPTION_RECEIVED_DATE?: Date;
  EXCEPTION_START_DATE?: Date;
  EXCEPTION_SD_START_RANGE?: Date;
  EXCEPTION_SD_FINAL_RANGE?: Date;
  EXCEPTION_END_DATE?: Date;
  EXCEPTION_ED_START_RANGE?: Date;
  EXCEPTION_ED_FINAL_RANGE?: Date;
  EXCEPTION_ANALYSIS_DEADLINE?: number;
  EXCEPTION_STATUS?: 0 | 1;
  EXCEPTION_USER?: string;
  EXCEPTION_INC_DATE?: Date;
  EXCEPTION_DATETIME?: Date;
  EXCEPTION_DEFAULT_DEADLINE?: 0 | 1;
  EXCEPTION_CMT_ATCH?: number;
  ANALYSIS_DEADLINE_DEFAULT?: number;
  CUSTOMER_CONTACTS?: string;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface ICustomerNotification {
  NOTIFICATION_ID?: number;
  CUS_ID?: number;
  CUS_CODE?: string;
  CUS_NAME?: string;
  INVOICE_ID?: number;
  NOTIFICATION_CONTACTS?: string;
  NOTIFICATION_CREATION_DATE?: Date;
  NOTIFICATION_DUE_DATE?: Date;
  NOTIFICATION_SITUATION?: string;
  NOTIFICATION_STATUS?: 0 | 1;
  NOTIFICATION_USER?: string;
  NOTIFICATION_DATETIME?: Date;
  NOTIFICATION_INVOICE_URL?: string;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}