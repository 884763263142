import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { NavItem } from './navbar.model';
import { navigation } from './navbar.config';

@Component({
  selector: 'dhl-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  navigation = navigation;
  year: number = new Date().getFullYear();

  isSidebarOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  destroy$: Subject<null> = new Subject<null>();
  
  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isSidebarOpen$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isOpen: boolean) => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    });
  }

  ngOnDestroy(): void {
    this.isSidebarOpen$.next(false);
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  toggleAllGroups(willExpand: boolean): void {
    this.navigation.forEach((group) => group.isExpanded = willExpand);
  }
  
  onRouteChange(currentGroup: NavItem | null): void {
    this.navigation.forEach((group) => group.isExpanded = group === currentGroup);
    this.isSidebarOpen$.next(false);
  }

  canAccessItem(route: string): boolean {
    return this.authService.userAllowedUrls.includes(route) || this.authService.isUserAdmin;
  }
  
  canAccessGroup(group: NavItem): boolean {
    for (const item of group.items) {
      if (this.canAccessItem(item.path)) {
        return true;
      }
    }
    
    return false;
  }
}
