import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dhl-reprocess-container-dialog',
  templateUrl: './reprocess-container-dialog.component.html',
  styleUrls: ['./reprocess-container-dialog.component.scss']
})
export class ReprocessContainerDialogComponent{

  constructor(public dialogRef: MatDialogRef<ReprocessContainerDialogComponent>) { }

  closeDialog(willDelete: boolean) {
    this.dialogRef.close(willDelete);
  }

}