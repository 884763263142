import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICountry, IContainerType, IPort, IRegion, ICarrier, ICusNotifParam } from '../models/parameter.model';

@Injectable({
  providedIn: 'root'
})
export abstract class ParameterService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getCountry(): Observable<Array<ICountry>> {
    return this.httpClient.get<Array<ICountry>>(`${this.apiURL}/api/country/`, this.httpOptions);
  }

  getCountryParameter(): Observable<Array<ICountry>> {
    return this.httpClient.get<Array<ICountry>>(`${this.apiURL}/api/country/parameter`, this.httpOptions);
  }

  postCountry(country: ICountry): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/country?filter=${JSON.stringify(country)}`, null, this.httpOptions)
  }

  putCountry(country: ICountry): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/country?filter=${JSON.stringify(country)}`, null, this.httpOptions)
  }

  getContainerType(): Observable<Array<IContainerType>> {
    return this.httpClient.get<Array<IContainerType>>(`${this.apiURL}/api/containertype/`, this.httpOptions);
  }

  getIsotankContainerType(containerType: IContainerType): Observable<Array<IContainerType>> {
    return this.httpClient.get<Array<IContainerType>>(`${this.apiURL}/api/containertype/parameter?filter=${JSON.stringify(containerType)}`, this.httpOptions);
  }

  getContainerTypeParameter(): Observable<Array<IContainerType>> {
    return this.httpClient.get<Array<IContainerType>>(`${this.apiURL}/api/containertype/parameter`, this.httpOptions);
  }

  postContainerType(containerType: IContainerType): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/containertype?filter=${JSON.stringify(containerType)}`, null, this.httpOptions)
  }

  putContainerType(containerType: IContainerType): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/containertype?filter=${JSON.stringify(containerType)}`, null, this.httpOptions)
  }

  getPort(): Observable<Array<IPort>> {
    return this.httpClient.get<Array<IPort>>(`${this.apiURL}/api/port/`, this.httpOptions);
  }

  getPortParameter(): Observable<Array<IPort>> {
    return this.httpClient.get<Array<IPort>>(`${this.apiURL}/api/port/parameter`, this.httpOptions);
  }

  postPort(port: IPort): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/port?filter=${JSON.stringify(port)}`, null, this.httpOptions)
  }

  putPort(port: IPort): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/port?filter=${JSON.stringify(port)}`, null, this.httpOptions)
  }

  getCarrier(): Observable<Array<ICarrier>> {
    return this.httpClient.get<Array<ICarrier>>(`${this.apiURL}/api/carrier/`, this.httpOptions);
  }

  getCarrierParameter(): Observable<Array<ICarrier>> {
    return this.httpClient.get<Array<ICarrier>>(`${this.apiURL}/api/carrier/parameter`, this.httpOptions);
  }

  postCarrier(carrier: ICarrier): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/carrier?filter=${JSON.stringify(carrier)}`, null, this.httpOptions)
  }

  putCarrier(carrier: ICarrier): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/carrier?filter=${JSON.stringify(carrier)}`, null, this.httpOptions)
  }

  getRegion(): Observable<Array<IRegion>> {
    return this.httpClient.get<Array<IRegion>>(`${this.apiURL}/api/region/`, this.httpOptions);
  }

  getRegionParameter(): Observable<Array<IRegion>> {
    return this.httpClient.get<Array<IRegion>>(`${this.apiURL}/api/region/parameter`, this.httpOptions);
  }

  postRegion(region: IRegion): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/region?filter=${JSON.stringify(region)}`, null, this.httpOptions)
  }

  putRegion(region: IRegion): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/region?filter=${JSON.stringify(region)}`, null, this.httpOptions)
  }

  //-----------------------Customer Notification Parameter-----------------------

  getCustomerNotification(filter: ICusNotifParam = {}): Observable<Array<ICusNotifParam>> {
    return this.httpClient.get<Array<ICusNotifParam>>(`${this.apiURL}/api/customer/notification/parameter?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  postCustomerNotification(notification: ICusNotifParam): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/customer/notification/parameter`, notification, this.httpOptions);
  }

  putCustomerNotification(notification: ICusNotifParam): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer/notification/parameter`, notification, this.httpOptions);
  }

  deleteCustomerNotification(notification: ICusNotifParam): Observable<Object> {
    return this.httpClient.delete(`${this.apiURL}/api/customer/notification/parameter?filter=${JSON.stringify(notification)}`, this.httpOptions);
  }
}
