<h1 mat-dialog-title *ngIf="this.defaultRuleType === 'carrier'">Filter Standard {{this.defaultRuleType | titlecase}}</h1>
<h1 mat-dialog-title *ngIf="this.defaultRuleType === 'dhl'">Filter Standard {{this.defaultRuleType | uppercase}}</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>DDM Agreement</mat-label>
        <input matInput type="text" name="id" formControlName="id">
        <button mat-icon-button type="button" matSuffix (click)="resetField('id')" *ngIf="fieldHasValue('id')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput type="text" name="name" formControlName="name">
        <button mat-icon-button type="button" matSuffix (click)="resetField('name')" *ngIf="fieldHasValue('name')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field *ngIf="defaultRuleType != 'dhl'">
        <mat-label>Service</mat-label>
        <mat-select name="service" formControlName="service">
          <mat-option value="demurrage">DEMURRAGE</mat-option>
          <mat-option value="detention">DETENTION</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('service')" *ngIf="fieldHasValue('service')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>            
    </div>
    <div class="form-group">
      <mat-form-field *ngIf="defaultRuleType != 'dhl'">
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('carrier')" *ngIf="fieldHasValue('carrier')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status">
          <mat-option value="1">ACTIVE</mat-option>
          <mat-option value="0">INACTIVE</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('status')" *ngIf="fieldHasValue('status')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Validity Date Range</mat-label>
        <mat-date-range-input [rangePicker]="valRangePicker">
          <input matStartDate name="valStart" formControlName="valStart" placeholder="Start date">
          <input matEndDate name="valFinal" formControlName="valFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="valRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('valStart'); resetField('valFinal')" *ngIf="fieldHasValue('valStart') || fieldHasValue('valFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #valRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Inclusion Date Range</mat-label>
        <mat-date-range-input [rangePicker]="incRangePicker">
          <input matStartDate name="incStart" formControlName="incStart" placeholder="Start date">
          <input matEndDate name="incFinal" formControlName="incFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="incRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('incStart'); resetField('incFinal')" *ngIf="fieldHasValue('incStart') || fieldHasValue('incFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #incRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="reset()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>
