import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { ICustomer, ICustomerContact } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { HotToastService } from '@ngneat/hot-toast';
import { CustomerDialogComponent, ICustomerDialog } from './customers-dialogs/customers-dialog/customers-dialog.component';
import { CustomerActionDialogComponent } from './customers-dialogs/customers-action-dialog/customers-action-dialog.component';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { JoinCustomerGroupService } from 'src/app/services/join-customer-group.service';
import { IJoinCustomerList } from 'src/app/models/join-customer-group.model';
import { CustomerDeleteAllDialogComponent } from './customers-dialogs/customers-delete-all-dialog/customers-delete-all-dialog.component';
import { CustomerFilterDialogComponent, ICustomerFilterDialog } from './customers-dialogs/customers-filter-dialog/customers-filter-dialog.component';
import { XlsxExporterService } from 'mat-table-exporter';
import { TimelineService } from 'src/app/services/timeline.service';
import { ITimeline } from 'src/app/models/timeline.model';
import { FilterService } from 'src/app/services/filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CustomersContactDialogComponent, ICustomerContactDialog } from './customers-dialogs/customers-contact-dialog/customers-contact-dialog.component';
import { CustomerContactsImportDialogComponent } from './customers-dialogs/customer-contacts-import-dialog/customer-contacts-import-dialog.component';

@Component({
  selector: 'dhl-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  pageKey = 'customers';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<ICustomer>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: ICustomer;
  filtersNumber: number = 0;

  customerTableDataSource = new MatTableDataSource<ICustomer>();
  customerExportDataSource = new MatTableDataSource<ICustomer>();
  filteredCustomerTableDataSource = new MatTableDataSource<ICustomer>();
  customerTableDisplayedColumns: Array<string> = ['select', 'CUS_CODE', 'CUS_NAME', 'CUS_ADDRESS', 'CUS_STATE', 'CUS_UNLOCO', 'CUS_GROUP_NAME', 'contacts', 'actions'];

  @ViewChild(MatTable) customerTable: MatTable<ICustomer>;
  @ViewChild('customerSort') customerSort: MatSort;
  @ViewChild('customerPaginator') customerPaginator: MatPaginator;

  isSidebarOpen: boolean = false;
  timeline: Array<ITimeline> = [];

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private customerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private joinCustomerGroupService: JoinCustomerGroupService,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private timelineService: TimelineService,
    private authService: AuthService,
    private filterService: FilterService
  ) {
    this.titleService.setTitle('DHL | Customers');
  }

  ngOnInit(): void {   
    this.filterEntity = {
      CUS_NAME: '',
      CUS_ADDRESS: '',
      CUS_STATE: ''
    }
    
    const filter = this.filterService.getFilter('customer');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getCustomer(this.filterEntity);
    });

    this.getCustomer(this.filterEntity);
  }

  getCustomer(filter: ICustomer): void {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.customerTableDataSource.data.length === 0) this.hideLoader = false;
    this.customerService.getCustomer({
      ...filter, 
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((customers: Array<ICustomer>) => {
      this.hideLoader = true;
      customers.forEach((customer) => {
        delete customer.CUS_USER;
        delete customer.CUS_STATUS;
      });
      this.customerTableDataSource.data = customers;
      this.filteredCustomerTableDataSource.data = this.customerTableDataSource.data;
      this.changeDetectorRef.detectChanges();
      this.length = customers[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredCustomerTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.filteredCustomerTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getCustomer(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getCustomer(this.filterEntity);
  }

  openCustomerDialog(data: ICustomerDialog): void {
    const dialogRef = this.dialog.open(CustomerDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((customer: ICustomer) => {
      if (!customer) return;

      if (data.mode == 'add') {
        this.customerService.postCustomer(customer).subscribe((response) => {
          this.toast.success(String(response));
          this.getCustomer(this.filterEntity);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        this.customerService.putCustomer(customer).subscribe((response) => {
          if (data.mode == 'edit') {
            this.toast.success(String(response));
          } else {
            this.toast.success('Customer removed successfully.');
          }
          this.getCustomer(this.filterEntity);
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  openContactDialog(data: ICustomerContactDialog): void {
    const dialogRef = this.dialog.open(CustomersContactDialogComponent, {
      data: data
    });
  }

  openCustomerActionDialog(): void {
    const dialogRef = this.dialog.open(CustomerActionDialogComponent);

    dialogRef.afterClosed().subscribe((customerGroup: ICustomerGroup) => {
      if (!customerGroup) return;

      let customerIds: Array<number> = [];
      this.selection.selected.forEach((customer) => {
        customerIds.push(customer.CUS_ID);
      });

      const joinCustomerGroupList: IJoinCustomerList = {
        CUS_ID: customerIds,
        CUS_GROUP_ID: [customerGroup.CUS_GRP_ID],
        CUS_USER: this.authService.userId
      }

      this.joinCustomerGroupService.postJoinCustomerGroup(joinCustomerGroupList).subscribe((response) => {
        this.getCustomer(this.filterEntity);
        this.selection.clear();
        this.toast.success(String(response));
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  deleteSelected(): void {
    const dialogRef = this.dialog.open(CustomerDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {
        let customerIds: Array<number> = [];
        this.selection.selected.forEach((customer) => {
          customerIds.push(customer.CUS_ID);
        });

        let customers: IJoinCustomerList = {
          CUS_ID: customerIds,
          CUS_USER: this.authService.userId
        }

        this.customerService.deleteSelectedCustomers(customers).subscribe((response) => {
          this.getCustomer(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  setFilters(): void {
    const dialogRef = this.dialog.open(CustomerFilterDialogComponent, {
      data: {
        name: this.filterEntity.CUS_NAME,
        address: this.filterEntity.CUS_ADDRESS,
        state: this.filterEntity.CUS_STATE
      }
    });

    dialogRef.afterClosed().subscribe((filters: ICustomerFilterDialog) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity.CUS_NAME = filters.name.toUpperCase();
      this.filterEntity.CUS_ADDRESS = filters.address.toUpperCase();
      this.filterEntity.CUS_STATE = filters.state.toUpperCase();

      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('customer', this.filterEntity);

      this.getCustomer(this.filterEntity);
    });
  }

  export(): void {
    const headers = {
      CUS_CODE: 'CODE',
      CUS_NAME: 'NAME',
      CUS_ADDRESS: 'ADDRESS',
      CUS_STATE: 'STATE',
      CUS_UNLOCO: 'BRANCH',
      CUS_GROUP_NAME: 'GROUP NAME',
      CUS_TAX: 'CNPJ',
      CUS_CONTACT: 'CONTACTS',
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.customerService.getCustomer({
        ...this.filterEntity,
        EXPORT: 1,
        SEARCH: search, 
        SORT_COL: this.sortCol,
        SORT_ORDER: this.sortOrder
      }).subscribe((dataExport) => {
        this.customerExportDataSource.data = dataExport;
        const data: Array<ICustomer> = this.prepareDataToExport([...this.customerExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `customers_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref?.close();
      });
    } else {
      const data: Array<ICustomer> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `customers_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
    
  }

  prepareDataToExport(data: Array<ICustomer>): Array<ICustomer> {
    const dataToExport: Array<ICustomer> = [];

    data.forEach((customer) => {
      const newData = {...customer};
      delete newData.CUS_ID;
      delete newData.CUS_GROUP_ID;
      delete newData.CUS_USER;
      delete newData.CUS_STATUS;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getTimeline(): void {
    const timeline: ITimeline = {
      TIMELINE_GROUP: 'customer',
      TIMELINE_REG_ID: null
    }

    this.timelineService.getTimeline(timeline).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  import(){
    const dialog = this.dialog.open(CustomerContactsImportDialogComponent);

    dialog.afterClosed().subscribe((isImport: boolean)=>{
      if(isImport) this.getCustomer(this.filterEntity);
    });
  }
}