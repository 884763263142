import { IUserList } from '../models/user-list.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from '../models/user.model';
import { IUserGroup } from 'src/app/models/user-group.model';

@Injectable({
  providedIn: 'root'
})
export abstract class UserService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getUser(user: IUser): Observable<Array<IUser>> {
    return this.httpClient.get<Array<IUser>>(`${this.apiURL}/api/user?filter=${JSON.stringify(user)}`, this.httpOptions);
  }

  getAllGroups(user: IUser): Observable<Array<IUserGroup>> {
    return this.httpClient.get<Array<IUserGroup>>(`${this.apiURL}/api/user/group/groups?filter=${JSON.stringify(user)}`, this.httpOptions);
  }

  postUser(user: IUser): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/user?filter=${JSON.stringify(user)}`, null, this.httpOptions);
  }

  putUser(user: IUser): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/user?filter=${JSON.stringify(user)}`, null, this.httpOptions);
  }

  deleteSelectedUsers(users: IUserList): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/user/deletelist?filter=${JSON.stringify(users)}`, null, this.httpOptions);
  }
}
