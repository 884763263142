import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAgreement } from 'src/app/models/agreement.model';
import { AuthService } from 'src/app/services/auth.service';

export interface ICommentDialog {
  element?: IAgreement | Array<IAgreement>;
}

@Component({
  selector: 'dhl-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {
  comment: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICommentDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<CommentDialogComponent>,
  ) { }

  ngOnInit(): void {    
  }

  close(value: [Array<IAgreement>, string]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newAgreement: Array<IAgreement> = [];

    if (this.data.element instanceof Array) {      
      newAgreement = this.data.element.map((element) => {
        return {
          DIM_SK_CUS_RUL: element.DIM_SK_CUS_RUL,
          DIM_RUL_USER: this.authService.userId
        }
      });
    } else {
      newAgreement.push({
        DIM_SK_CUS_RUL: this.data.element.DIM_SK_CUS_RUL,        
        DIM_RUL_USER: this.authService.userId
      });
    }

    this.close([newAgreement, this.comment]);
  }

  onComment(comment: string) {
    this.comment = comment;
  }
}
