<h1 mat-dialog-title>Filter logs</h1>
<div mat-dialog-content>
  <form [formGroup]="filterForm" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>User</mat-label>
        <mat-select name="user" formControlName="user">
          <mat-option>
            <ngx-mat-select-search formControlName="userFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user.LOG_USER">
            {{user.LOG_USER}}
          </mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('user').setValue('')" *ngIf="filterForm.get('user').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Group</mat-label>
        <mat-select name="group" formControlName="group">
          <mat-option>
            <ngx-mat-select-search formControlName="groupFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let group of filteredGroups | async" [value]="group.LOG_GROUP">
            {{group.LOG_GROUP}}
          </mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('group').setValue('')" *ngIf="filterForm.get('group').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Date range</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate name="startDate" formControlName="startDate" placeholder="Start date">
          <input matEndDate name="finalDate" formControlName="finalDate" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('startDate').setValue(''); filterForm.get('finalDate').setValue('')" *ngIf="filterForm.get('startDate').value !== '' || filterForm.get('finalDate').value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #rangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetFilters()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>