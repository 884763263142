<header>
  <h2>Unique Terms - Customers</h2>
  <div class="actions-terms">
    <div class="search-terms">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [routerLink]="['/unique-terms/customer']">Create</button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="filteredTermsTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #termsTable [dataSource]="filteredTermsTableDataSource" matSort #termsSort="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="UNI_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
        <td mat-cell [matTooltipDisabled]="!isOverflow(element.UNI_NAME)" [matTooltip]="element.UNI_NAME" *matCellDef="let element">{{element.UNI_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Account">Account</th>
        <td [matTooltipDisabled]="!isOverflow(element.CUS_CODE)" [matTooltip]="element.CUS_CODE" mat-cell *matCellDef="let element">{{element.CUS_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td [matTooltipDisabled]="!isOverflow(element.CUS_NAME)" [matTooltip]="element.CUS_NAME" mat-cell *matCellDef="let element">{{element.CUS_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_RECEIVED_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Received Date">Received Date</th>
        <td mat-cell *matCellDef="let element">{{element.UNI_RECEIVED_DT | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_BRANCH">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Branch">Branch</th>
        <td mat-cell *matCellDef="let element">{{element.UNI_BRANCH}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_START_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Start Date">Start Date</th>
        <td mat-cell *matCellDef="let element">{{element.UNI_START_DT | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_END_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Validity Date">Validity Date</th>
        <td mat-cell *matCellDef="let element">{{element.UNI_END_DT | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_SL_RECEIVERS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Sale Receivers">Sale Receivers</th>
        <td [matTooltipDisabled]="!isOverflow(element.UNI_SL_RECEIVERS)" [matTooltip]="element.UNI_SL_RECEIVERS" mat-cell *matCellDef="let element">{{element.UNI_SL_RECEIVERS}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_OPER_RECEIVERS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Operation Receivers">Operation Receivers</th>
        <td [matTooltipDisabled]="!isOverflow(element.UNI_OPER_RECEIVERS)" [matTooltip]="element.UNI_OPER_RECEIVERS" mat-cell *matCellDef="let element">{{element.UNI_OPER_RECEIVERS}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_SITUATION">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Status</th>
        <td mat-cell *matCellDef="let element">{{element.UNI_SITUATION}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_INC_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Creation Date">Creation Date</th>
        <td mat-cell *matCellDef="let element">{{element.UNI_INC_DT | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="UNI_CMT_ATCH">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Comment/Attachment">Comment / Attachment</th>
        <td [matTooltipDisabled]="!isOverflow(element.UNI_CMT_ATCH)" [matTooltip]="element.UNI_CMT_ATCH" mat-cell *matCellDef="let element">
          <a class="cmt-atch" (click)="openSidebar(element.UNI_ID)">
            <span class="flag"
              [ngClass]="{
                'grey': element.UNI_CMT_ATCH === 0,
                'green': element.UNI_CMT_ATCH > 0             
              }">
            </span>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [routerLink]="['/unique-terms/customer/edit/', element.UNI_ID]" matTooltip="Edit">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button mat-icon-button [routerLink]="['/unique-terms/customer/view/', element.UNI_ID]" matTooltip="See details">
            <mat-icon color="primary">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="termsTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: termsTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator #termsPaginator [pageSizeOptions]="[5, 10, 15, 20]" pageSize="20" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>