import { IntegrationLogsComponent } from './pages/integration-logs/integration-logs.component';
import { FinancialCustomersDashboardComponent } from './pages/financial-customers-dashboard/financial-customers-dashboard.component';
import { PaymentOrderComponent } from './pages/payment-order/payment-order.component';
import { PayablesGenerateComponent } from './pages/payables-generate/payables-generate.component';
import { CustomerUniqueTermComponent } from './pages/unique-terms/customer-unique-term/customer-unique-term.component';
import { CustomersUniqueTermsComponent } from './pages/unique-terms/customers-unique-terms/customers-unique-terms.component';
import { ProcessContainerComponent } from './pages/process-container/process-container.component';
import { PagesComponent } from './pages/pages/pages.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { UserGuard } from './guards/user.guard';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ShipmentComponent } from './pages/shipment/shipment.component';
import { ShipmentsComponent } from './pages/shipments/shipments.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { CustomerGroupsComponent } from './pages/customer-groups/customer-groups.component';
import { ParametersComponent } from './pages/parameters/parameters.component';
import { LogsComponent } from './pages/logs/logs.component';
import { LogComponent } from './pages/log/log.component';
import { UsersComponent } from './pages/users/users.component';
import { UserGroupsComponent } from './pages/user-groups/user-groups.component';
import { AgreementComponent } from './pages/agreement/agreement.component';
import { AgreementsComponent } from './pages/agreements/agreements.component';
import { AgreementResolver } from './resolvers/agreement.resolver';
import { DefaultRulesComponent } from './pages/default-rules/default-rules.component';
import { DefaultRuleComponent } from './pages/default-rule/default-rule.component';
import { ShipmentResolver } from './resolvers/shipment.resolver';
import { DemurrageManualComponent } from './pages/demurrage-manual/demurrage-manual.component';
import { DetentionManualComponent } from './pages/detention-manual/detention-manual.component';
import { DemurrageAutomaticComponent } from './pages/demurrage-automatic/demurrage-automatic.component';
import { DetentionAutomaticComponent } from './pages/detention-automatic/detention-automatic.component';
import { InvoicePrintComponent } from './pages/invoice-print/invoice-print.component';
import { UniqueTerm } from './resolvers/unique-terms.resolver';
import { CarriersUniqueTermsComponent } from './pages/unique-terms/carriers-unique-terms/carriers-unique-terms.component';
import { CarrierUniqueTermComponent } from './pages/unique-terms/carrier-unique-term/carrier-unique-term.component';
import { ProcessPayContainerComponent } from './pages/process-pay-container/process-pay-container.component';
import { PayablesComponent } from './pages/payables/payables.component';
import { PayableComponent } from './pages/payable/payable.component';
import { PayableResolver } from './resolvers/payable.resolver';
import { FinancialDashboardComponent } from './pages/financial-dashboard/financial-dashboard.component';
import { FinancialListComponent } from './pages/financial-list/financial-list.component';
import { RpaLogsComponent } from './pages/rpa-logs/rpa-logs.component';
import { ShipmentsDrtComponent } from './pages/shipments-drt/shipments-drt.component';
import { CustomerExceptionsComponent } from './pages/customer-exceptions/customer-exceptions.component';
import { CustomerExceptionComponent } from './pages/customer-exception/customer-exception.component';
import { CustomerException } from './resolvers/customer-exception.resolver';
import { CustomerNotificationLogsComponent } from './pages/customer-notification-logs/customer-notification-logs.component';
import { PartialInvoicesComponent } from './pages/invoices/partial-invoices/partial-invoices.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: 'demurrage/process', component: DemurrageManualComponent, canActivate: [UserGuard] },
      { path: 'demurrage/automatic', component: DemurrageAutomaticComponent, canActivate: [UserGuard] },
      { path: 'demurrage/generate', component: ProcessContainerComponent, canActivate: [UserGuard] },
      { path: 'demurrage/payables', component: ProcessPayContainerComponent, canActivate: [UserGuard] },
      { path: 'detention/process', component: DetentionManualComponent, canActivate: [UserGuard] },
      { path: 'detention/automatic', component: DetentionAutomaticComponent, canActivate: [UserGuard] },
      { path: 'detention/generate', component: ProcessContainerComponent, canActivate: [UserGuard] },
      { path: 'detention/payables', component: ProcessPayContainerComponent, canActivate: [UserGuard] },
      { path: 'shipment', component: ShipmentComponent, canActivate: [UserGuard] },
      {
        path: 'shipment/edit/:id',
        component: ShipmentComponent,
        canActivate: [UserGuard],
        resolve: {
          shipment: ShipmentResolver
        }
      },
      {
        path: 'shipment/:action/:id',
        component: ShipmentComponent,
        canActivate: [UserGuard],
        resolve: {
          shipment: ShipmentResolver
        }
      },
      { path: 'shipments', component: ShipmentsComponent, canActivate: [UserGuard] },
      { path: 'shipments/drt', component: ShipmentsDrtComponent, canActivate: [UserGuard] },      
      { path: 'invoices', component: InvoicesComponent, canActivate: [UserGuard] },
      { path: 'invoices/print/:id', component: InvoicePrintComponent, canActivate: [UserGuard] },
      { path: 'invoices/partial', component: PartialInvoicesComponent, canActivate: [UserGuard] },
      { path: 'invoices/partial/print/:id', component: InvoicePrintComponent, canActivate: [UserGuard] },
      { path: 'payables', component: PayablesComponent, canActivate: [UserGuard] },
      { path: 'payable', component: PayableComponent, canActivate: [UserGuard] },
      {
        path: 'payable/edit/:id',
        component: PayableComponent,
        canActivate: [UserGuard],
        resolve: {
          payable: PayableResolver
        }
      },
      {
        path: 'payable/:action/:id',
        component: PayableComponent,
        canActivate: [UserGuard],
        resolve: {
          payable: PayableResolver
        }
      },
      { path: 'payables/generate', component: PayablesGenerateComponent, canActivate: [UserGuard] },
      { path: 'payables/po', component: PaymentOrderComponent, canActivate: [UserGuard] },
      { path: 'agreement/:type', component: AgreementComponent, canActivate: [UserGuard] },
      {
        path: 'agreement/:type/edit/:id',
        component: AgreementComponent,
        canActivate: [UserGuard],
        resolve: {
          agreement: AgreementResolver
        }
      },
      {
        path: 'agreement/:type/:action/:id',
        component: AgreementComponent,
        canActivate: [UserGuard],
        resolve: {
          agreement: AgreementResolver
        }
      },
      { path: 'agreements/:type', component: AgreementsComponent, canActivate: [UserGuard] },
      { path: 'agreement/default/:type', component: DefaultRuleComponent, canActivate: [UserGuard] },
      {
        path: 'agreement/default/:type/edit/:id',
        component: DefaultRuleComponent,
        canActivate: [UserGuard],
        resolve: {
          agreement: AgreementResolver
        }
      },
      {
        path: 'agreement/default/:type/:action/:id',
        component: DefaultRuleComponent,
        canActivate: [UserGuard],
        resolve: {
          agreement: AgreementResolver
        }
      },
      { path: 'agreements/default/:type', component: DefaultRulesComponent, canActivate: [UserGuard] },
      { path: 'customers', component: CustomersComponent, canActivate: [UserGuard] },
      { path: 'customer/groups', component: CustomerGroupsComponent, canActivate: [UserGuard] },
      { path: 'customer/exceptions', component: CustomerExceptionsComponent, canActivate: [UserGuard]},
      { path: 'customer/exception', component: CustomerExceptionComponent, canActivate: [UserGuard] },
      {
        path: 'customer/exception/edit/:id',
        component: CustomerExceptionComponent,
        canActivate: [UserGuard],
        resolve: {
          exception: CustomerException
        }
      },
      {
        path: 'customer/exception/view/:id',
        component: CustomerExceptionComponent,
        canActivate: [UserGuard],
        resolve: {
          exception: CustomerException
        }
      },
      { path: 'parameters', component: ParametersComponent, canActivate: [UserGuard] },
      { path: 'logs', component: LogsComponent, canActivate: [UserGuard] },
      { path: 'log/:id', component: LogComponent, canActivate: [UserGuard] },
      { path: 'logs/rpa', component: RpaLogsComponent, canActivate: [UserGuard] },
      { path: 'logs/integration', component: IntegrationLogsComponent, canActivate: [UserGuard] },
      { path: 'logs/notification', component: CustomerNotificationLogsComponent, canActivate: [UserGuard] },
      { path: 'users', component: UsersComponent, canActivate: [UserGuard] },
      { path: 'user/groups', component: UserGroupsComponent, canActivate: [UserGuard] },
      { path: 'user/pages', component: PagesComponent, canActivate: [UserGuard] },
      { path: 'unique-terms/customers', component: CustomersUniqueTermsComponent, canActivate: [UserGuard] },
      { path: 'unique-terms/customer', component: CustomerUniqueTermComponent, canActivate: [UserGuard] },
      {
        path: 'unique-terms/customer/view/:id',
        component: CustomerUniqueTermComponent,
        canActivate: [UserGuard],
        resolve: {
          customerUniqueTerm: UniqueTerm
        }
      },
      {
        path: 'unique-terms/customer/edit/:id',
        component: CustomerUniqueTermComponent,
        canActivate: [UserGuard],
        resolve: {
          customerUniqueTerm: UniqueTerm
        }
      },
      { path: 'unique-terms/carriers', component: CarriersUniqueTermsComponent, canActivate: [UserGuard] },
      { path: 'unique-terms/carrier', component: CarrierUniqueTermComponent, canActivate: [UserGuard] },
      {
        path: 'unique-terms/carrier/view/:id',
        component: CarrierUniqueTermComponent,
        canActivate: [UserGuard],
        resolve: {
          carrierUniqueTerm: UniqueTerm
        }
      },
      {
        path: 'unique-terms/carrier/edit/:id',
        component: CarrierUniqueTermComponent,
        canActivate: [UserGuard],
        resolve: {
          carrierUniqueTerm: UniqueTerm
        }
      },
      { path: 'financial/dashboard', component: FinancialDashboardComponent, canActivate: [UserGuard] },
      { path: 'financial/dashboard/customers', component: FinancialCustomersDashboardComponent, canActivate: [UserGuard] },
      { path: 'financial/list', component: FinancialListComponent, canActivate: [UserGuard] }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    canActivate: [LoginGuard],
    children: [
      { path: 'login/:hash', component: LoginComponent },
      { path: 'login', component: LoginComponent },
    ]
  },
  { path: '**', component: NotFoundPageComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
