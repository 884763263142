<h1 mat-dialog-title>Filter Agreements</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>DDM Agreement</mat-label>
        <input matInput type="text" name="id" formControlName="id">
        <button mat-icon-button type="button" matSuffix (click)="resetField('id')" *ngIf="fieldHasValue('id')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>House Bill</mat-label>
        <input matInput type="text" name="hbl" formControlName="hbl">
        <button mat-icon-button type="button" matSuffix (click)="resetField('hbl')" *ngIf="fieldHasValue('hbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Service</mat-label>
        <mat-select name="service" formControlName="service">
          <mat-option value="DEMURRAGE">DEMURRAGE</mat-option>
          <mat-option value="DETENTION">DETENTION</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('service');" *ngIf="fieldHasValue('service')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <mat-select name="description" formControlName="description">
          <mat-option value="BID">BID</mat-option>
          <mat-option value="SPOT">SPOT</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('description');" *ngIf="fieldHasValue('description')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer" (selectionChange)="toggleField('customer', 'customerGroup')">
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('customer'); resetField('customerFilter'); toggleField('customer', 'customerGroup');" *ngIf="fieldHasValue('customer')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Customer Group</mat-label>
        <mat-select name="customerGroup" formControlName="customerGroup" (selectionChange)="toggleField('customerGroup', 'customer')">
          <mat-option>
            <ngx-mat-select-search formControlName="customerGroupFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customerGroup of filteredCustomerGroups" [value]="customerGroup.CUS_GRP_ID">{{customerGroup.CUS_GRP_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('customerGroup'); toggleField('customerGroup', 'customer');" *ngIf="fieldHasValue('customerGroup')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>    
    <div class="form-group">
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carriers" formControlName="carriers">
          <mat-option>
            <ngx-mat-select-search formControlName="carriersFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('carriers');" *ngIf="fieldHasValue('carriers')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Container Type</mat-label>
        <mat-select name="containerType" formControlName="containerType">
          <mat-option>
            <ngx-mat-select-search formControlName="containersTypeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let ctn of filteredContainers" [value]="ctn.DIM_SK_CTN_TYP">{{ctn.CONTYPPF_QAMPZ}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('containerType');" *ngIf="fieldHasValue('containerType')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Origin Region</mat-label>
        <mat-select name="originRegion" formControlName="originRegion" placeholder="" (selectionChange)="toggleOriginDestinyFields('originRegion', 'originCountry', 'originPort');">
          <mat-option>
            <ngx-mat-select-search formControlName="originRegionFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let region of filteredRegions" [value]="region.REG_ID">{{region.REG_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('originRegion'); toggleOriginDestinyFields('originRegion', 'originCountry', 'originPort');" *ngIf="fieldHasValue('originRegion')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Origin Country</mat-label>
        <mat-select name="originCountry" formControlName="originCountry" placeholder="" (selectionChange)="toggleOriginDestinyFields('originCountry', 'originRegion', 'originPort');">
          <mat-option>
            <ngx-mat-select-search formControlName="originCountriesFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let country of filteredCountries" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('originCountry'); toggleOriginDestinyFields('originCountry', 'originRegion', 'originPort');" *ngIf="fieldHasValue('originCountry')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Origin Port</mat-label>
        <mat-select name="originPort" formControlName="originPort" placeholder="" (selectionChange)="toggleOriginDestinyFields('originPort', 'originCountry', 'originRegion');">
          <mat-option>
            <ngx-mat-select-search formControlName="originPortsFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let port of filteredPorts" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('originPort'); toggleOriginDestinyFields('originPort', 'originCountry', 'originRegion');" *ngIf="fieldHasValue('originPort')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Destiny Region</mat-label>
        <mat-select name="destinyRegion" formControlName="destinyRegion" placeholder="" (selectionChange)="toggleOriginDestinyFields('destinyRegion', 'destinyCountry', 'destinyPort');">
          <mat-option>
            <ngx-mat-select-search formControlName="destinyRegionFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let region of filteredRegions" [value]="region.REG_ID">{{region.REG_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('destinyRegion'); toggleOriginDestinyFields('destinyRegion', 'destinyCountry', 'destinyPort');" *ngIf="fieldHasValue('destinyRegion')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Destiny Country</mat-label>
        <mat-select name="destinyCountry" formControlName="destinyCountry" placeholder="" (selectionChange)="toggleOriginDestinyFields('destinyCountry', 'destinyRegion', 'destinyPort');">
          <mat-option>
            <ngx-mat-select-search formControlName="destinyCountriesFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let country of filteredCountries" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('destinyCountry'); toggleOriginDestinyFields('destinyCountry', 'destinyRegion', 'destinyPort');" *ngIf="fieldHasValue('destinyCountry')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Destiny Port</mat-label>
        <mat-select name="destinyPort" formControlName="destinyPort" placeholder="" (selectionChange)="toggleOriginDestinyFields('destinyPort', 'destinyCountry', 'destinyRegion');">
          <mat-option>
            <ngx-mat-select-search formControlName="destinyPortsFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let port of filteredPorts" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('destinyPort'); toggleOriginDestinyFields('destinyPort', 'destinyCountry', 'destinyRegion');" *ngIf="fieldHasValue('destinyPort')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status">
          <mat-option value="VALID">VALID</mat-option>
          <mat-option value="EXPIRED">EXPIRED</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('status')" *ngIf="fieldHasValue('status')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Start Date Range</mat-label>
        <mat-date-range-input [rangePicker]="stRangePicker">
          <input matStartDate name="stStart" formControlName="stStart" placeholder="Start date">
          <input matEndDate name="stFinal" formControlName="stFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="stRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('stStart'); resetField('stFinal')" *ngIf="fieldHasValue('stStart') || fieldHasValue('stFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #stRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Validity Date Range</mat-label>
        <mat-date-range-input [rangePicker]="valRangePicker">
          <input matStartDate name="valStart" formControlName="valStart" placeholder="Start date">
          <input matEndDate name="valFinal" formControlName="valFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="valRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('valStart'); resetField('valFinal')" *ngIf="fieldHasValue('valStart') || fieldHasValue('valFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #valRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Inclusion Date Range</mat-label>
        <mat-date-range-input [rangePicker]="incRangePicker">
          <input matStartDate name="incStart" formControlName="incStart" placeholder="Start date">
          <input matEndDate name="incFinal" formControlName="incFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="incRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('incStart'); resetField('incFinal')" *ngIf="fieldHasValue('incStart') || fieldHasValue('incFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #incRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="reset()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>