import { ReprocessContainerDialogComponent } from './dialogs/reprocess-container-dialog/reprocess-container-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { HotToastService } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatTableFilter } from 'mat-table-filter';
import moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICustomer } from 'src/app/models/customer.model';
import { IContainerType } from 'src/app/models/parameter.model';
import { IContainer } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FilterService } from 'src/app/services/filter.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { DateRejectedDialogComponent } from './dialogs/date-rejected-dialog/date-rejected-dialog.component';
import { FilterDialogComponent } from './dialogs/filter-dialog/filter-dialog.component';

@Component({
  selector: 'dhl-demurrage-automatic',
  templateUrl: './demurrage-automatic.component.html',
  styleUrls: ['./demurrage-automatic.component.scss']
})
export class DemurrageAutomaticComponent implements OnInit {
  readonly pageKey = 'demurrageAutomatic';

  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IContainer>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IContainer = {    
    CTN_NUMBER: null,
    CTN_HBL: null,
    CTN_MBL: null,
    CTN_TYPE: null,
    CTN_CUSTOMER: null,
    CTN_CAR: null,
    CTN_POL: null,
    CTN_POD: null,
    CTN_ATA_START_DT: null,
    CTN_ATA_FINAL_DT: null,
    CTN_GOU_START_DT: null,
    CTN_GOU_FINAL_DT: null,    
    CTN_INC_START_DT: null,
    CTN_INC_FINAL_DT: null
  };
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;

  demurrageExportDataSource = new MatTableDataSource<IContainer>();
  demurrageTableDataSource = new MatTableDataSource<IContainer>();
  filteredDemurrageTableDataSource = new MatTableDataSource<IContainer>();
  demurrageTableDisplayedColumns: Array<string> = [
    'select',
    'CTN_NUMBER',
    'CTN_TYPE',
    'CTN_CUSTOMER',
    'CTN_HBL',
    'SHIP_DPT_CODE',
    'CTN_CAR',
    'CTN_MBL',
    'CTN_POL',
    'CTN_POD',
    'CTN_ATA',
    'CTN_GOU',
    'actions'
  ];

  @ViewChild(MatTable) demurrageTable: MatTable<IContainer>;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private shipmentService: ShipmentService,
    private toast: HotToastService,    
    private authService: AuthService,
    private exporter: XlsxExporterService,
    private filterService: FilterService
  ) {
    this.titleService.setTitle('DHL | Demurrage (Robots Date)')
  }

  ngOnInit(): void {
    const filter = this.filterService.getFilter('demurrageAutomatic');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getContainers(this.filterEntity);
    });

    this.getContainers(this.filterEntity);
  }

  getContainers(filter: IContainer): void {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredDemurrageTableDataSource.data.length === 0) this.hideLoader = false;
    this.shipmentService.getAutomaticContainers({
      ...filter,
      CTN_DESTINY: 0,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((containers) => {
      this.hideLoader = true;
      this.demurrageTableDataSource.data = containers;
      this.filteredDemurrageTableDataSource.data = containers;
      this.length = containers[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.demurrageTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.demurrageTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element: string) {
    if (element) {
      return element.length > 20;
    }
  }

  export(): void {
    const headers = {
      CTN_NUMBER:'CONTAINER',
      CTN_TYPE:'TYPE',
      CTN_CUSTOMER:'CUSTOMER',
      CTN_HBL:'HBL',
      SHIP_DPT_CODE:'DEPT',
      CTN_CAR: 'CARRIER',
      CTN_MBL:'MBL',
      CTN_POL:'POL',
      CTN_POD:'POD',
      CTN_ATA_TEXT:'ARRIVAL',
      CTN_GOU_TEXT:'EMPTY RETURN',
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.shipmentService.getAutomaticContainers({
          ...this.filterEntity,
          EXPORT: 1,
          CTN_DESTINY: 0,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.demurrageExportDataSource.data = dataExport;

        const data: Array<IContainer> = this.prepareDataToExport([...this.demurrageExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `${this.pageKey}_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    } else {
      const data: Array<IContainer> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `demurrageAutomatic_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IContainer>): Array<IContainer> {
    const dataToExport: Array<IContainer> = [];

    const datesText = {
      CTN_ATA_TEXT: '',
      CTN_GOU_TEXT: ''
    }
  

    data.forEach((container) => {
      const newData = {...container, ...datesText};

      if(newData.CTN_ATA !== null) {
        newData.CTN_ATA_TEXT = moment(newData.CTN_ATA).format('DD/MM/YYYY');
      }

      if(newData.CTN_GOU !== null) {
        newData.CTN_GOU_TEXT = moment(newData.CTN_GOU).format('DD/MM/YYYY');
      }

      delete newData.CTN_ATA;
      delete newData.CTN_GOU;
      delete newData.CTN_ID;
      delete newData.SHIP_ID;
      delete newData.CTN_STATUS;
      delete newData.CTN_USER;
      delete newData.CTN_RUL;
      delete newData.CTN_FRT;
      delete newData.CTN_PROCESS_STATUS;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getContainers(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getContainers(this.filterEntity);
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.filterEntity
      }
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);
      this.filterEntity = filters;
      this.pageNumber = 0;           
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('demurrageAutomatic', this.filterEntity);

      this.getContainers(this.filterEntity);
    });
  }

  onDateRejected(element: IContainer): void {
    const dialog = this.dialog.open(DateRejectedDialogComponent, { data: { element } });
    dialog.afterClosed().subscribe((container: IContainer) => {
      if (!container) return;

      this.shipmentService.putContainer(container).subscribe((response) => {
        this.toast.success('Dates approved successfully.');
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error('Failed to approve dates.');
      });
    });
  }

  onDateAccepted(element: IContainer): void {
    const container: IContainer =  {
      SHIP_ID: element.SHIP_ID,
      CTN_ID: element.CTN_ID,
      CTN_NUMBER: element.CTN_NUMBER,
      CTN_GOU: element.CTN_GOU,
      CTN_USER: this.authService.userId
    }

    this.shipmentService.putContainer(container).subscribe((response) => {
      this.toast.success('Dates approved successfully.');
      this.getContainers(this.filterEntity);
    }, (error) => {
      this.toast.error('Failed to approve dates.');
    });
  }

  approveAll() {
    const containers = this.selection.selected.filter(item => this.verifyDates(item)).map((container) => {
      const newContainer: IContainer = {
        SHIP_ID: container.SHIP_ID,
        CTN_ID: container.CTN_ID,
        CTN_NUMBER: container.CTN_NUMBER,
        CTN_GOU: container.CTN_GOU,
        CTN_USER: this.authService.userId
      }
      return newContainer;
    });

    if(containers.length > 0){
      this.shipmentService.approveAll(containers).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }

  }

  onReprocessContainer(element: IContainer | Array<IContainer>): void {
    const dialog = this.dialog.open(ReprocessContainerDialogComponent);
    dialog.afterClosed().subscribe((willDelete: boolean) => {
      if (!willDelete) return;

      let containers: Array<IContainer> = [];

      if(element instanceof Array){
        containers = element;
      }else{
        containers.push(element);
      }

      this.shipmentService.reprocessContainer(containers).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  verifyDates(container: IContainer): boolean{
    const ata: Date = new Date(container.CTN_ATA);
    const gou: Date = new Date(container.CTN_GOU);
    
    if(ata <= gou){
      return true;
    }

    return false;
  }
}
