import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dhl-agreement-delete-all-dialog',
  templateUrl: './default-rule-delete-all-dialog.component.html',
  styleUrls: ['./default-rule-delete-all-dialog.component.scss']
})
export class DefaultRuleDeleteAllDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DefaultRuleDeleteAllDialogComponent>
  ) { }

  ngOnInit() {
  }


  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}
