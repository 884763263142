<header>
  <h2>Analytics - Finance</h2>
  <div class="financial-dashboard-filters">
    <mat-form-field class="month">
      <mat-label>Month</mat-label>
      <mat-select [formControl]="month">
        <ng-container *ngFor="let month of numberToMonthMap | keyvalue">
          <mat-option *ngIf="(month.key <= date.getMonth() + 1) || (year.value < date.getFullYear())"
            [value]="month.key">
            {{month.value}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="year">
      <mat-label>Year</mat-label>
      <mat-select [formControl]="year">
        <mat-option *ngFor="let year of reportYears" [value]="year.FIN_YEAR">{{year.FIN_YEAR}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</header>
<div class="dashboard-container">
  <div class="dashboard">
    <div class="expandable">
      <button type="button" class="open-expandable" [ngClass]="{'open': openGroups[0]}"
        (click)="openGroups[0] = !openGroups[0]">
        <span>Monthly Performance GP • Demurrage and Detention</span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <div class="expandable-content" [ngClass]="{'collapsed': !openGroups[0]}">
        <div class="row main">
          <div class="chart-column full">
            <apx-chart #barChart [colors]="barChartOptions.colors" [series]="barChartOptions.series"
              [chart]="barChartOptions.chart" [stroke]="barChartOptions.stroke" [title]="barChartOptions.title"
              [xaxis]="barChartOptions.xaxis" [yaxis]="barChartOptions.yaxis" [tooltip]="barChartOptions.tooltip"
              [legend]="barChartOptions.legend"></apx-chart>
          </div>
          <div class="chart-column full">
            <apx-chart #cricketChart [colors]="cricketChartOptions.colors" [series]="cricketChartOptions.series"
              [chart]="cricketChartOptions.chart" [plotOptions]="cricketChartOptions.plotOptions"
              [title]="cricketChartOptions.title" [tooltip]="cricketChartOptions.tooltip"
              [legend]="cricketChartOptions.legend" [labels]="cricketChartOptions.labels"></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard">
    <div class="expandable">
      <button type="button" class="open-expandable" [ngClass]="{'open': openGroups[1]}"
        (click)="openGroups[1] = !openGroups[1]">
        <span>Anual Performance GP • Demurrage and Detention</span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <div class="expandable-content" [ngClass]="{'collapsed': !openGroups[1]}">
        <div class="row">
          <div class="chart-column full">
            <apx-chart #mainChart [colors]="mainChartOptions.colors" [series]="mainChartOptions.series"
              [chart]="mainChartOptions.chart" [stroke]="mainChartOptions.stroke" [title]="mainChartOptions.title"
              [xaxis]="mainChartOptions.xaxis" [yaxis]="mainChartOptions.yaxis" [tooltip]="mainChartOptions.tooltip"
              [legend]="mainChartOptions.legend"></apx-chart>
          </div>
        </div>
        <div class="table-container">
          <ng-container *ngIf="hideLoader; else loader">
            <div>
              <button mat-flat-button color="accent" (click)="export()">Export</button>
            </div>
            <table mat-table #financialReportTable [dataSource]="financialReportTableDataSource" matSort
              #financialReportSort="matSort">
              <ng-container matColumnDef="FIN_MONTH">
                <th mat-header-cell *matHeaderCellDef>Month</th>
                <td [matTooltipDisabled]="!isOverflow(element.FIN_MONTH)" [matTooltip]="element.FIN_MONTH" mat-cell
                  *matCellDef="let element">{{numberToMonthMap.get(element.FIN_MONTH)}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_EXP">
                <th mat-header-cell *matHeaderCellDef>Containers (Exp)</th>
                <td [matTooltipDisabled]="!isOverflow(element.FIN_EXP)" [matTooltip]="element.FIN_EXP" mat-cell
                  *matCellDef="let element">{{element.FIN_EXP}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_IMP">
                <th mat-header-cell *matHeaderCellDef>Containers (Imp)</th>
                <td [matTooltipDisabled]="!isOverflow(element.FIN_IMP)" [matTooltip]="element.FIN_IMP" mat-cell
                  *matCellDef="let element">{{element.FIN_IMP}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_REV_BRL">
                <th mat-header-cell *matHeaderCellDef>Revenue BRL</th>
                <td [matTooltipDisabled]="!isOverflow(element.FIN_REV_BRL)" [matTooltip]="element.FIN_REV_BRL" mat-cell
                  *matCellDef="let element" [ngClass]="{negative: element.FIN_REV_BRL < 0}">{{element.FIN_REV_BRL | currency:'BRL'}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_COS_BRL">
                <th mat-header-cell *matHeaderCellDef>Cost BRL</th>
                <td mat-cell [matTooltipDisabled]="!isOverflow(element.FIN_COS_BRL)" [matTooltip]="element.FIN_COS_BRL"
                  *matCellDef="let element" [ngClass]="{negative: element.FIN_COS_BRL < 0}">{{element.FIN_COS_BRL |
                  currency:'BRL'}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_ACT_BRL">
                <th mat-header-cell *matHeaderCellDef>Actual GP BRL</th>
                <td mat-cell [matTooltipDisabled]="!isOverflow(element.FIN_ACT_BRL)" [matTooltip]="element.FIN_ACT_BRL"
                  *matCellDef="let element" [ngClass]="{negative: element.FIN_ACT_BRL < 0}">{{element.FIN_ACT_BRL |
                  currency:'BRL'}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_ACT_EUR">
                <th mat-header-cell *matHeaderCellDef>Actual GP EUR</th>
                <td mat-cell [matTooltipDisabled]="!isOverflow(element.FIN_ACT_EUR)" [matTooltip]="element.FIN_ACT_EUR"
                  *matCellDef="let element" [ngClass]="{negative: element.FIN_ACT_EUR < 0}">{{element.FIN_ACT_EUR |
                  currency:'EUR'}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_PY_EUR">
                <th mat-header-cell *matHeaderCellDef>Past Year GP EUR</th>
                <td mat-cell [matTooltipDisabled]="!isOverflow(element.FIN_PY_EUR)" [matTooltip]="element.FIN_PY_EUR"
                  *matCellDef="let element" [ngClass]="{negative: element.FIN_PY_EUR < 0}">{{element.FIN_PY_EUR |
                  currency:'EUR'}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_BUD_EUR">
                <th mat-header-cell *matHeaderCellDef>Budget GP EUR</th>
                <td [matTooltipDisabled]="!isOverflow(element.FIN_BUD_EUR)" [matTooltip]="element.FIN_BUD_EUR" mat-cell
                  *matCellDef="let element" [ngClass]="{negative: element.FIN_BUD_EUR < 0}">{{element.FIN_BUD_EUR | currency:'EUR'}}</td>
              </ng-container>
              <ng-container matColumnDef="FIN_ACT_BUD">
                <th mat-header-cell *matHeaderCellDef>Actual vs Budget</th>
                <td [matTooltipDisabled]="!isOverflow(element.FIN_ACT_BUD)" [matTooltip]="element.FIN_ACT_BUD" mat-cell
                  *matCellDef="let element" [ngClass]="{negative: element.FIN_ACT_BUD < 0}">{{element.FIN_ACT_BUD / 100 | percent}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="financialReportTableDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: financialReportTableDisplayedColumns;"></tr>
            </table>
          </ng-container>
          <ng-template #loader>
            <dhl-spinner></dhl-spinner>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>