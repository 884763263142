<header>
  <h2>Log - Integration</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="integrationLogTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <div class="row">
    <div class="total-display gray" *ngIf="integrationLogIndicators.LDI_REC_FILES !== null">
      <h2>{{integrationIndicatorsTableDataSource.data[0]?.LDI_REC_FILES}}</h2>
      <p>Total queries</p>
    </div>
    <div class="total-display green" *ngIf="integrationLogIndicators.LDI_LOAD_OK !== null">
      <h2>{{integrationIndicatorsTableDataSource.data[0]?.LDI_LOAD_OK}}</h2>
      <p>OK queries</p>
    </div>
    <div class="total-display red" *ngIf="integrationLogIndicators.LDI_LOAD_ERR !== null">
      <h2>{{integrationIndicatorsTableDataSource.data[0]?.LDI_LOAD_ERR}}</h2>
      <p>Queries with errors</p>
    </div>
  </div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #logTable [dataSource]="integrationLogTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="LI_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
        <td [matTooltipDisabled]="!isOverflow(element.LI_ID)" [matTooltip]="element.LI_ID" mat-cell *matCellDef="let element">{{element.LI_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="LI_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by HBL">HBL</th>
        <td [matTooltipDisabled]="!isOverflow(element.LI_HBL)" [matTooltip]="element.LI_HBL" mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.LI_SHIP_ID">
            <a class="dhl-link" matTooltip="Open Shipment information" [routerLink]="['/shipment/edit', element.LI_SHIP_ID]" target="_blank">
              {{element.LI_HBL}}
            </a>
          </ng-container>
          <ng-container *ngIf="!element.LI_SHIP_ID">
            {{element.LI_HBL}}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="LI_SHIP_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Shipment ID">Shipment ID</th>
        <td [matTooltipDisabled]="!isOverflow(element.LI_SHIP_CODE)" [matTooltip]="element.LI_SHIP_CODE" mat-cell *matCellDef="let element">{{element.LI_SHIP_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="LI_MESSAGE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Message">Message</th>
        <td [matTooltipDisabled]="!isOverflow(element.LI_MESSAGE)" [matTooltip]="element.LI_MESSAGE" mat-cell *matCellDef="let element">{{element.LI_MESSAGE}}</td>
      </ng-container>
      <ng-container matColumnDef="LI_STATUS_ITEM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status Item">Status Item</th>
        <td [matTooltipDisabled]="!isOverflow(element.LI_STATUS_ITEM)" [matTooltip]="element.LI_STATUS_ITEM" mat-cell *matCellDef="let element">{{element.LI_STATUS_ITEM}}</td>
      </ng-container>
      <ng-container matColumnDef="LI_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Execution Date">Execution Date</th>
        <td mat-cell *matCellDef="let element">{{element.LI_DATE | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="json">
        <th mat-header-cell *matHeaderCellDef>Json</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="openJsonViewDialog(element)">View Json</button>      
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="integrationLogTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: integrationLogTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
