import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICustomer } from 'src/app/models/customer.model';
import { ICustomerGroup } from '../models/customer-group.model';
import { IJoinCustomerGroup, IJoinCustomerList } from '../models/join-customer-group.model';

@Injectable({
  providedIn: 'root'
})
export abstract class JoinCustomerGroupService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getAllCustomerGroups(customer: ICustomer): Observable<Array<ICustomerGroup>> {
    return this.httpClient.get<Array<ICustomerGroup>>(`${this.apiURL}/api/joincustomergroup/customer-group?filter=${JSON.stringify(customer)}`, this.httpOptions);
  }

  getAllCustomers(customerGroup: ICustomerGroup): Observable<Array<ICustomer>> {
    return this.httpClient.get<Array<ICustomer>>(`${this.apiURL}/api/joincustomergroup/customer?filter=${JSON.stringify(customerGroup)}`, this.httpOptions);
  }

  postJoinCustomerGroup(joinCustomerGroup: IJoinCustomerList): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/joincustomergroup/list?filter=${JSON.stringify(joinCustomerGroup)}`, null, this.httpOptions);
  }

  putJoinCustomerGroup(joinCustomerGroup: IJoinCustomerGroup): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/joincustomergroup?filter=${JSON.stringify(joinCustomerGroup)}`, null, this.httpOptions);
  }
}
