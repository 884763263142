import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { DateDialogComponent } from './date-dialog/date-dialog.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DateRejectedDialogComponent } from './date-rejected-dialog/date-rejected-dialog.component';
import { ReprocessContainerDialogComponent } from './reprocess-container-dialog/reprocess-container-dialog.component';

@NgModule({
  declarations: [
    DateDialogComponent,
    FilterDialogComponent,
    DateRejectedDialogComponent,
    ReprocessContainerDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxMatSelectSearchModule
  ]
})
export class DetentionAutomaticDialogsModule { }
