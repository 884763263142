<header>
  <h2>Log - DDM</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="logTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #logTable [dataSource]="logTableDataSource" matSort #logSort="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="LOG_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
        <td mat-cell *matCellDef="let element">{{element.LOG_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="LOG_USER">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by User">User</th>
        <td [matTooltipDisabled]="!isOverflow(element.LOG_USER)" [matTooltip]="element.LOG_USER" mat-cell *matCellDef="let element">{{element.LOG_USER}}</td>
      </ng-container>
      <ng-container matColumnDef="LOG_ACTION">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Action">Action</th>
        <td mat-cell *matCellDef="let element">{{element.LOG_ACTION}}</td>
      </ng-container>
      <ng-container matColumnDef="LOG_GROUP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Group">Group</th>
        <td mat-cell *matCellDef="let element">{{element.LOG_GROUP}}</td>
      </ng-container>
      <ng-container matColumnDef="LOG_ENTITY">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Entity">Entity</th>
        <td mat-cell *matCellDef="let element">{{element.LOG_ENTITY}}</td>
      </ng-container>
      <ng-container matColumnDef="LOG_DATETIME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date">Date</th>
        <td mat-cell *matCellDef="let element">{{element.LOG_DATETIME}}</td>
      </ng-container>
      <ng-container matColumnDef="changes">
        <th mat-header-cell *matHeaderCellDef>Changes</th>
        <td mat-cell *matCellDef="let element"><a mat-stroked-button color="accent" [routerLink]="['/log/', element.LOG_ID]">View Changes</a></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="logTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: logTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator #logPaginator [pageSizeOptions]="[5, 10, 15, 20]" pageSize="20" showFirstLastButtons aria-label="Select page of customer"></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
