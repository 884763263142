import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'dhl-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent{

  constructor(private dialogRef: MatDialogRef<DeleteDialogComponent>) { }

  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}