import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { IAttachment } from 'src/app/models/attachment.model';
import { IComment } from 'src/app/models/comment.model';
import { IPayable, IPayableComment } from 'src/app/models/payable.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { AwsService } from 'src/app/services/aws.service';
import { CommentService } from 'src/app/services/comment.service';
import { PayableService } from 'src/app/services/payable.service';
import { TimelineService } from 'src/app/services/timeline.service';

@Component({
  selector: 'dhl-payable',
  templateUrl: './payable.component.html',
  styleUrls: ['./payable.component.scss']
})
export class PayableComponent implements OnInit {
  payableForm: UntypedFormGroup;  

  payableId: number;
  payableAction: string;
  payable: IPayable;

  timelineComments: Array<IPayableComment> = [];

  isSidebarOpen = false;
  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];

  isFormDisabled: boolean = false;

  minExpirationDate: Date;
  minEndDate: Date;

  payableStatus: Array<string> = [
    "New Process",
    "Under Analysis",
    "Dispute DHL",
    "Dispute Carrier",
    "Programed Payment",
    "Paid (Dispute)",
    "Paid",
    "Invoice Canceled"
  ];

  payableSubStatus: Array<string> = [
    "Under Internal Review",
    "FT & PD Incorrect",
    "Incorrect Invoice",
    "Partial Invoice",
    "Invoice Not Found",
    "Waiting Confirmation (Free-time)",
    "Waiting for Loss Approval",
    "Waiting to Process Billing",
    "Waiting Confirmation of Ship Transfer",
    "Ok to Pay",
    "Paid",
    "Old Payment",
    "Invoice Canceled",
    "Ship Delay",
    "Terminal Window Delay",
    "Waiting Payment Order",
    "Waiting Reimbursement",
    "Dispute",
    "Waiting Customer to Pay",
  ];
  
  constructor(
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private payableService: PayableService,
    private awsService: AwsService,
    private commentService: CommentService,
    private attachmentService: AttachmentService,
    private timelineService: TimelineService,
    private authService: AuthService,
    private toast: HotToastService,
  ) { 
    this.titleService.setTitle('DHL | Payable');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.payableId = params.id;
      this.payableAction = params.action;
    });

    this.payable = this.route.snapshot.data['payable'] && this.route.snapshot.data['payable'][0][0];

    if(this.payableId){
      this.timelineComments = this.route.snapshot.data['payable'][1];
      
      this.getComments();
      this.getAttachments();
      this.getTimeline();
    }

    if(this.payableAction === 'clone'){
      this.payable.PB_INV_NUM = null;
      this.payable.PB_PRC_ST = 0;
      this.payable.PB_PRC_SUB_ST = 0;

      this.timelineComments = [];
    }

    this.payableForm = this.formBuilder.group({
      carrier: new UntypedFormControl(this.payable?.PB_CAR, [Validators.required]),
      invoiceNumbers: new UntypedFormControl(this.payable?.PB_INV_NUM, [Validators.required]),
      emissionDate: new UntypedFormControl(this.payable?.PB_EMIS_DT),
      expirationDate: new UntypedFormControl(this.payable?.PB_EXP_DT),
      mbl: new UntypedFormControl(this.payable?.PB_MBL),
      booking: new UntypedFormControl(this.payable?.PB_BK),
      container: new UntypedFormControl(this.payable?.PB_CTN, [Validators.required]),
      equipmentType: new UntypedFormControl(this.payable?.PB_CTN_TYPE),
      contract: new UntypedFormControl(this.payable?.PB_CONT),
      pol: new UntypedFormControl(this.payable?.PB_POL),
      pod: new UntypedFormControl(this.payable?.PB_POD),
      totalDays: new UntypedFormControl(this.payable?.PB_TL_DAYS, [Validators.pattern('[0-9]*')]),
      freeDays: new UntypedFormControl(this.payable?.PB_FRT, [Validators.required, Validators.pattern('[0-9]*')]),
      chargeDays: new UntypedFormControl(this.payable?.PB_CH_DAYS, [Validators.pattern('[0-9]*')]),
      startDate: new UntypedFormControl(this.payable?.PB_ST_DT),
      endDate: new UntypedFormControl(this.payable?.PB_END_DT),
      totalUsd: new UntypedFormControl(this.payable?.PB_VAL_USD, [Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
      status: new UntypedFormControl(this.payable?.PB_PRC_ST ? String(this.payable?.PB_PRC_ST) : "0"),
      subStatus: new UntypedFormControl(this.payable?.PB_PRC_SUB_ST ? String(this.payable?.PB_PRC_SUB_ST) : "0")
    });

    this.payableForm.get('emissionDate').valueChanges.subscribe((date: Date) => {
      this.minExpirationDate = date;
    });

    this.payableForm.get('startDate').valueChanges.subscribe((date: Date) => {
      this.minEndDate = date;
    });

    if(this.payableAction === 'detail'){
      this.payableForm.disable();
    }
  }

  getErrorMessage(formControl: string): string {
    if (this.payableForm.get(formControl).hasError('required')) {
      return 'You must enter a value'; 
    } else if (this.payableForm.get(formControl).hasError('pattern')) {
      return 'The value must be a number';   
    } else {
      return '';
    }
  }

  onPayableFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.payableForm.value;

    const payable: IPayable = {
      PB_CAR: formValue.carrier,
      PB_INV_NUM: formValue.invoiceNumbers,
      PB_EMIS_DT: formValue.emissionDate,
      PB_EXP_DT: formValue.expirationDate,
      PB_MBL: formValue.mbl,
      PB_BK: formValue.booking,
      PB_CTN: formValue.container,
      PB_CTN_TYPE: formValue.equipmentType,
      PB_CONT: formValue.contract,
      PB_POL: formValue.pol,
      PB_POD: formValue.pod,
      PB_TL_DAYS: formValue.totalDays,
      PB_FRT: formValue.freeDays,
      PB_CH_DAYS: formValue.chargeDays,
      PB_ST_DT: formValue.startDate,
      PB_END_DT: formValue.endDate,
      PB_VAL_USD: formValue.totalUsd.toString().replace(',','.'),
      PB_PRC_ST: formValue.status,
      PB_PRC_SUB_ST: formValue.subStatus,
      PB_USER: this.authService.userId
    }

    if (!this.payable || this.payableAction === 'clone') {
      this.payableService.post(payable).subscribe((response) => {
        this.payable = response;
        this.toast.success(String(response));
        this.router.navigate(['/payables']);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    } else {
      payable.PB_ID = this.payableId;
      this.payableService.put(payable).subscribe((response) => {
        this.toast.success(String(response));
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }
  }

  getTimelineComment(): void{
    this.payableService.getPayableComment({ PB_ID: this.payableId }).subscribe(comments => this.timelineComments = comments);
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `payable`, REG_ID: this.payableId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: this.payableId,
          COMM_REF_GROUP: `payable`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, error => this.toast.error(error.error.Message));
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: `payable`, REG_ID: this.payableId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, `payable`, [{ REG_ID: this.payableId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    const timeline: ITimeline = {
      TIMELINE_GROUP: 'payable',
      TIMELINE_REG_ID: this.payableId?.toString()
    }

    this.timelineService.getTimeline(timeline).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

}
