import { IIntegrationLog, IRpaJson } from './../../../../models/log.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from 'src/app/services/log.service';

export interface IJsonViewDialog{
  element?: IIntegrationLog
}

@Component({
  selector: 'app-json-view-dialog',
  templateUrl: './json-view-dialog.component.html',
  styleUrls: ['./json-view-dialog.component.scss']
})
export class JsonViewDialogComponent implements OnInit {
  integrationJson: Object;

  constructor(
    public dialogRef: MatDialogRef<JsonViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IJsonViewDialog,
    public logService: LogService
  ) { }

  ngOnInit() {    
    if(this.isJsonString(this.data))
    this.integrationJson = JSON.parse(this.data[0].LI_ITEM.replace(/'/g, '"'));
  }

  isJsonString(str): boolean {
    try {
        JSON.parse(str[0].LI_ITEM.replace(/'/g, '"'));
    } catch (e) {
        return false;
    }
    return true;
  }

  close(): void{
    this.dialogRef.close();
  }

}
