<h1 mat-dialog-title>Change Charge Code</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Charge Code</mat-label>
        <input matInput name="chargeCode" formControlName="chargeCode">
        <mat-error *ngIf="form.get('chargeCode').invalid">{{getErrorMessage('chargeCode')}}</mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid">Apply</button>
    </div>
  </form>
</div>