<h1 mat-dialog-title>{{data.mode}} Masterbill</h1>
<div mat-dialog-content>
  <form [formGroup]="masterbillForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Master Bill of Landing</mat-label>
        <input matInput name="number" formControlName="number">
        <mat-error *ngIf="masterbillForm.get('number').invalid">You must provide a value</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Consol ID</mat-label>
        <input matInput name="consolId" formControlName="consolId">
        <mat-error *ngIf="masterbillForm.get('consolId').invalid">You must provide a value</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Consol Type</mat-label>
        <input matInput name="consolType" formControlName="consolType">
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Vessel</mat-label>
        <input matInput name="vesselName" formControlName="vesselName">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Vessel Arrival</mat-label>
        <input matInput name="vesselArrivalDate" formControlName="vesselArrivalDate" [matDatepicker]="arrivalPicker" [disabled]="fieldsLocked">
        <mat-datepicker-toggle matSuffix [for]="arrivalPicker"></mat-datepicker-toggle>
        <mat-datepicker #arrivalPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Vessel Departure</mat-label>
        <input matInput name="vesselDepartureDate" formControlName="vesselDepartureDate" [matDatepicker]="departurePicker" [disabled]="fieldsLocked">
        <mat-datepicker-toggle matSuffix [for]="departurePicker"></mat-datepicker-toggle>
        <mat-datepicker #departurePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Voyage</mat-label>
        <input matInput name="voyageFlightNumber" formControlName="voyageFlightNumber">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier" [disabled]="fieldsLocked">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="masterbillForm.get('carrier').setValue('')" *ngIf="!!masterbillForm.get('carrier').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode === 'delete'">Do you really wish to delete the masterbill {{data.element.MBL_ID}}?</p>
    <div mat-dialog-actions *ngIf="data.mode !== 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'add'" [disabled]="masterbillForm.invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode === 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" [disabled]="masterbillForm.invalid">Save</button>
    </div>
  </form>
</div>
