import { CustomerService } from 'src/app/services/customer.service';
import { IPayable } from 'src/app/models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier } from 'src/app/models/parameter.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface IFilterDialog {
  element: IPayable;
  carrierList: Array<ICarrier>;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;

  customers: Array<ICustomer> = [];
  
  carriers: Array<ICarrier> = [];
  filteredCarriers: Array<ICarrier> = [];
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog
  ) { }

  ngOnInit(): void {
    this.carriers = this.data?.carrierList;
    this.filteredCarriers = this.data?.carrierList;

    this.form = this.formBuilder.group({
      service: new UntypedFormControl(this.data?.element.PRC_SER),
      hbl: new UntypedFormControl(this.data?.element.PRC_HBL),
      customer: new UntypedFormControl(this.data?.element.PRC_CUS),
      customerFilter: new UntypedFormControl(''),
      carrier: new UntypedFormControl(this.data?.element.PB_CAR),
      carrierFilter: new UntypedFormControl(''),
      incStart: new UntypedFormControl(this.data?.element.PB_INC_START_DT),
      incFinal: new UntypedFormControl(this.data?.element.PB_INC_FINAL_DT),
    });

    this.getCustomers({ CUS_ID: this.form.get('customer').value, IS_SELECT: 1, CUS_COUNTRY: 'BR' });

    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));
    
    this.form.get('carrierFilter').valueChanges.subscribe(value => this.filterCarriers(value));
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  filterCustomers(filter: string): void {
    if (!this.form.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1, CUS_COUNTRY: 'BR' }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  filterCarriers(filter: string): void {
    this.filteredCarriers = this.data.carrierList.filter((element) => {
      return element.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase().trim()) || 
             element.RELATIPF_APA3CD.toLowerCase().includes(filter.toLowerCase().trim());
    });
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue('');
  }

  close(filters: IPayable): void {
    this.dialogRef.close(filters);
  }

  resetForm(): void {
    this.form.setValue({
      service: null,
      hbl: null,
      customer: null,
      customerFilter: '',
      carrier: null,
      carrierFilter: '',
      incStart: null,
      incFinal: null
    })
  }

  handleSubmit(e: Event): void {
    e.preventDefault();

    let filter: IPayable = {
      PRC_SER: this.form.value.service,
      PRC_HBL: this.form.value.hbl,
      PRC_CUS: this.form.value.customer,
      PB_CAR: this.form.value.carrier,
      PB_INC_START_DT: this.form.value.incStart,
      PB_INC_FINAL_DT: this.form.value.incFinal
    }

    this.dialogRef.close(filter);
  }
}
