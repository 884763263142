import { ICarrier } from './../../models/parameter.model';
import { ParameterService } from 'src/app/services/parameter.service';
import { JsonViewDialogComponent } from './rpa-logs-dialogs/json-view-dialog/json-view-dialog.component';
import { IRpaIndicators, IRpaLog } from './../../models/log.model';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { LogService } from 'src/app/services/log.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterDialogComponent } from './rpa-logs-dialogs/filter-dialog/filter-dialog.component';
import { XlsxExporterService } from 'mat-table-exporter';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dhl-rpa-logs',
  templateUrl: './rpa-logs.component.html',
  styleUrls: ['./rpa-logs.component.scss']
})
export class RpaLogsComponent implements OnInit {
  pageKey = 'rpaLogs';
  hideLoader: boolean;

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filtersNumber: number = 0;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IRpaLog>(this.allowMultiSelect, this.initialSelection);
  logFilters: IRpaLog = {
    LDR_SERVICE: null,
    LDR_MBL: null,
    LDR_CTN: null,
    LDR_CAR_ID: null,
    LDR_FUN: null,
    LDR_STATUS_EXEC: null,
    LDR_YEAR: null,
    LDR_MONTH: null,
  };

  rpaIndicatorsTableDataSource = new MatTableDataSource<IRpaIndicators>();
  rpaLogTableDataSource = new MatTableDataSource<IRpaLog>();
  rpaLogTableDisplayedColumns: Array<string> = [
    'select',
    'LDR_LOG_ID',
    'LDR_SERVICE',
    'LDR_MBL',
    'LDR_CTN',
    'LDR_CAR',
    'LDR_FUN',
    'LDR_STATUS_EXEC',
    'LDR_EXC_DATETIME',
    'json'
  ];

  @ViewChild(MatTable) logTable: MatTable<IRpaLog>;
  @ViewChild('rpaLogSort') rpaLogSort: MatSort;
  @ViewChild('rpaLogPaginator') rpaLogPaginator: MatPaginator;

  carrierList: Array<ICarrier> = [];
  
  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private exporter: XlsxExporterService,
    private filterService: FilterService,
    private logService: LogService,
    private carrierService: ParameterService
  ) {
    this.titleService.setTitle('DHL | Logs (RPA)');
  }

  ngOnInit(): void {
    this.carrierService.getCarrier().subscribe(carriers => this.carrierList = carriers);

    const filter = this.filterService.getFilter('rpaLog');
    if (filter) {
      this.logFilters = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.getRpaLogs(this.logFilters);
    this.getRpaIndicators(this.logFilters);

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.filterRpaLogsTable(value);
    });
  }

  getRpaLogs(filter: IRpaLog): void {
    this.rpaLogTableDataSource.filteredData = [];
    if(this.rpaLogTableDataSource.filteredData.length === 0) this.hideLoader = false;
    this.logService.getRpaLogs(filter).subscribe((logs: Array<IRpaLog>) => {
      this.hideLoader = true;
      this.rpaLogTableDataSource.data = logs;
      this.changeDetectorRef.detectChanges();
      this.rpaLogTableDataSource.sort = this.rpaLogSort;
      this.rpaLogTableDataSource.paginator = this.rpaLogPaginator;

      this.filterRpaLogsTable(this.globalFilter.value);
    });
  }

  getRpaIndicators(filter: IRpaLog): void{
    this.logService.getRpaIndicators(filter).subscribe((indicators: Array<IRpaIndicators>)=>{
      this.rpaIndicatorsTableDataSource.data = indicators;
    });
  }

  filterRpaLogsTable(filter: string): void {
    this.rpaLogTableDataSource.filter = filter;
  }

  openJsonViewDialog(data: IRpaLog): void{
    this.logService.getRpaJson(data).subscribe((data)=>{
      this.dialog.open(JsonViewDialogComponent, { data });
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rpaLogTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.rpaLogTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  setFilters(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.logFilters,
        carrierList: this.carrierList
      }
    });

    dialogRef.afterClosed().subscribe((filters: IRpaLog) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.logFilters.LDR_SERVICE = filters.LDR_SERVICE;
      this.logFilters.LDR_MBL = filters.LDR_MBL;
      this.logFilters.LDR_CTN = filters.LDR_CTN;
      this.logFilters.LDR_CAR_ID = filters.LDR_CAR_ID;
      this.logFilters.LDR_FUN = filters.LDR_FUN;
      this.logFilters.LDR_STATUS_EXEC = filters.LDR_STATUS_EXEC;
      this.logFilters.LDR_YEAR = filters.LDR_YEAR;
      this.logFilters.LDR_MONTH = filters.LDR_MONTH;

      this.filterService.setFilter('rpaLog', this.logFilters);

      this.getRpaLogs(this.logFilters);
      this.getRpaIndicators(this.logFilters);
    });
  }

  export(): void {
    const headers = {
      LDR_LOG_ID: 'ID',
      LDR_SERVICE: 'SERVICE',
      LDR_MBL: 'MBL',
      LDR_CTN: 'CONTAINER',
      LDR_CAR: 'CARRIER',
      LDR_FUN: 'RPA',
      LDR_STATUS_EXEC: 'STATUS',
      LDR_EXC_DATETIME_TEXT: 'EXECUTION DATE'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<IRpaLog> = this.prepareDataToExport([...this.rpaLogTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<IRpaLog> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `rpaLogs_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<IRpaLog>): Array<IRpaLog> {
    const dataToExport: Array<IRpaLog> = [];

    const fieldText = {
      LDR_EXC_DATETIME_TEXT: ""
    }

    data.forEach((rpa) => {
      const newData = {...rpa, ...fieldText};

      newData.LDR_EXC_DATETIME_TEXT = newData.LDR_EXC_DATETIME && moment(newData.LDR_EXC_DATETIME).format('DD/MM/YYYY');

      delete newData.LDR_EXC_DATETIME;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
