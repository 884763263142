import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HotToastService } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { ICustomer, ICustomerContact } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerContactViewDialogComponent, ICustomerContactViewDialog } from '../customer-contact-view-dialog/customer-contact-view-dialog.component';

export interface ICustomerContactDialog{
  customer?: ICustomer;
}

@Component({
  selector: 'dhl-customers-contact-dialog',
  templateUrl: './customers-contact-dialog.component.html',
  styleUrls: ['./customers-contact-dialog.component.scss']
})
export class CustomersContactDialogComponent implements OnInit{
  hideLoaderCustomerContact: boolean;
  customerContact: Array<ICustomerContact>;

  filterCustomerContactValue: string = '';

  customerContactTableDataSource = new MatTableDataSource<ICustomerContact>();
  customerContactTableDisplayedColumns: Array<string> = ['CONTACT_NAME', 'CONTACT_EMAIL', 'actions'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICustomerContactDialog,
    private dialogRef: MatDialogRef<CustomersContactDialogComponent>,
    private customerService: CustomerService,
    private toast: HotToastService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private exporter: XlsxExporterService
  ) { }

  @ViewChild(MatTable) customerContactTable: MatTable<ICustomerContact>;
  @ViewChild('customerContactSort') customerContactSort: MatSort;
  @ViewChild('customerContactPaginator') customerContactPaginator: MatPaginator;

  ngOnInit(){
    this.getCustomerContact();
  }

  getCustomerContact(): void {
    if(this.customerContactTableDataSource.data.length === 0) this.hideLoaderCustomerContact = false;
    this.customerService.getCustomerContact({
      CUS_ID: this.data.customer?.CUS_ID
    }).subscribe((contacts: Array<ICustomerContact>) => {
      this.hideLoaderCustomerContact = true;
      this.customerContact = contacts;      
      this.customerContactTableDataSource.data = contacts;
      this.changeDetectorRef.detectChanges();
      this.customerContactTableDataSource.sort = this.customerContactSort;
      this.customerContactTableDataSource.paginator = this.customerContactPaginator;
    }, (error) => {
      this.hideLoaderCustomerContact = true;
      this.toast.error(error.message);
    });
  }

  filterCustomerContactTable(filterValue: string): void {
    this.customerContactTableDataSource.filter = filterValue;
  }

  openCustomerContactViewDialog(data: ICustomerContactViewDialog): void {
    let dialogRef = this.dialog.open(CustomerContactViewDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: ICustomerContact) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.customerService.postCustomerContact(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getCustomerContact();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }

      if (data.mode == 'edit') {
        this.customerService.putCustomerContact(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getCustomerContact();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }

      if (data.mode == 'delete') {
        this.customerService.deleteCustomerContact(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getCustomerContact();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  exportCustomerContacts(): void {
    const headers = {
      CONTACT_NAME: 'NAME',
      CONTACT_EMAIL: 'EMAIL'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<ICustomerContact> = this.prepareCustomerContactDataToExport([...this.customerContactTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `customerContacts_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareCustomerContactDataToExport(data: Array<ICustomerContact>): Array<ICustomerContact> {
    const dataToExport: Array<ICustomerContact> = [];

    data.forEach((customerContact) => {
      const newData = {...customerContact};
      delete newData.CONTACT_ID;
      delete newData.CUS_ID;
      delete newData.CONTACT_STATUS;
      delete newData.CONTACT_USER;
      delete newData.CONTACT_INC_DATE;
      delete newData.CONTACT_DATETIME;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  closeDialog(){
    this.dialogRef.close()
  }

}
