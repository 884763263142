import { IProcessContainer } from 'src/app/models/process-container.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier, IContainerType, IPort } from 'src/app/models/parameter.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ParameterService } from 'src/app/services/parameter.service';
import { CustomerService } from 'src/app/services/customer.service';

export interface IFilterDialog {
  element: IProcessContainer;
  destiny: string;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  customers: Array<ICustomer> = [];  

  containerTypes: Array<IContainerType> = [];
  filteredContainerTypes: Subject<Array<IContainerType>> = new Subject<Array<IContainerType>>();

  carriers: Array<ICarrier> = [];
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  ports: Array<IPort> = [];
  filteredOriginPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();
  filteredDestinationPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();

  chargeDaysStatus: Array<string> = [
    "No Charge Days",
    "Charge Days"
  ]

  destiny: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private parameterService: ParameterService,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog
  ) { }

  ngOnInit(): void {
    this.destiny = this.data?.destiny?.toLowerCase();

    this.form = this.formBuilder.group({      
      status: new UntypedFormControl(this.data.element?.PROCESS_CH_ST),
      container: new UntypedFormControl(this.data.element?.PROCESS_CTN),
      type: new UntypedFormControl(this.data.element?.PROCESS_CTN_TYPE),
      typeFilter: new UntypedFormControl(''),
      hbl: new UntypedFormControl(this.data.element?.PROCESS_HBL),
      customer: new UntypedFormControl(this.data.element?.PROCESS_CUS),
      customerFilter: new UntypedFormControl(''),
      carrier: new UntypedFormControl(this.data.element?.PROCESS_CAR),
      carrierFilter: new UntypedFormControl(''),
      originPort: new UntypedFormControl(this.data.element?.PROCESS_POL),
      originPortFilter: new UntypedFormControl(''),
      destinationPort: new UntypedFormControl(this.data.element?.PROCESS_POD),
      destinationPortFilter: new UntypedFormControl(''),
      rul: new UntypedFormControl(this.data.element?.PROCESS_AGREEMENT),
      isPartial: new UntypedFormControl(this.data.element?.PROCESS_IS_PARTIAL),
      ataStart: new UntypedFormControl(this.data.element?.PROCESS_ATA_START_DT),
      ataFinal: new UntypedFormControl(this.data.element?.PROCESS_ATA_FINAL_DT),
      gouStart: new UntypedFormControl(this.data.element?.PROCESS_GOU_START_DT),
      gouFinal: new UntypedFormControl(this.data.element?.PROCESS_GOU_FINAL_DT),
      ginStart: new UntypedFormControl(this.data.element?.PROCESS_GIN_START_DT),
      ginFinal: new UntypedFormControl(this.data.element?.PROCESS_GIN_FINAL_DT),
      ldgStart: new UntypedFormControl(this.data.element?.PROCESS_LDG_START_DT),
      ldgFinal: new UntypedFormControl(this.data.element?.PROCESS_LDG_FINAL_DT),
      incStart: new UntypedFormControl(this.data.element?.PROCESS_INC_START_DT),
      incFinal: new UntypedFormControl(this.data.element?.PROCESS_INC_FINAL_DT)
    });

    this.getCustomers({ CUS_ID: this.form.get('customer').value, IS_SELECT: 1 });
    this.getContainerTypes();
    this.getCarriers();
    this.getPorts();

    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    this.form.get('typeFilter')?.valueChanges.subscribe((value) => this.filterContainerTypes(value));
    this.form.get('carrierFilter')?.valueChanges.subscribe((value) => this.filterCarriers(value));
    this.form.get('originPortFilter')?.valueChanges.subscribe((value) => this.filterOriginPort(value));
    this.form.get('destinationPortFilter')?.valueChanges.subscribe((value) => this.filterDestinationPort(value));
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getContainerTypes(): void{
    this.parameterService.getContainerType().subscribe((containerTypes)=>{
      this.containerTypes = containerTypes;
      this.filteredContainerTypes.next(containerTypes);
    });
  }

  getCarriers(): void{
    this.parameterService.getCarrier().subscribe((carriers)=>{
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });
  }

  getPorts(): void{
    this.parameterService.getPort().subscribe((ports)=>{
      this.ports = ports;
      this.filteredOriginPorts.next(ports);
      this.filteredDestinationPorts.next(ports);
    });
  }
  
  filterCustomers(filter: string): void {
    if (!this.form.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
    });
  }
  
  filterContainerTypes(value: string): void {
    this.filteredContainerTypes.next(this.containerTypes.filter((containerType) => containerType.CONTYPPF_QAMPZ.toLowerCase().includes(value.toLowerCase())));
  }

  filterCarriers(filter: string): void {
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase()) || carrier.RELATIPF_APA3CD.toLowerCase().includes(filter.toLowerCase())));
  }

  filterOriginPort(filter: string): void {
    this.filteredOriginPorts.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter.toLowerCase())));
  }

  filterDestinationPort(filter: string): void {
    this.filteredDestinationPorts.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter.toLowerCase())));
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  closeDialog(filterProcessContainer: IProcessContainer): void {
    this.dialogRef.close(filterProcessContainer);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    let filter: IProcessContainer = {
      PROCESS_AGREEMENT: this.form.value.rul,
      PROCESS_CUS: this.form.value.customer,
      PROCESS_HBL: this.form.value.hbl,
      PROCESS_CTN: this.form.value.container,
      PROCESS_CTN_TYPE: this.form.value.type,
      PROCESS_CAR: this.form.value.carrier,
      PROCESS_POL: this.form.value.originPort,
      PROCESS_POD: this.form.value.destinationPort,
      PROCESS_CH_ST: this.form.value.status,
      PROCESS_IS_PARTIAL: this.form.value.isPartial,
      PROCESS_ATA_START_DT: this.form.value.ataStart,
      PROCESS_ATA_FINAL_DT: this.form.value.ataFinal,
      PROCESS_GOU_START_DT: this.form.value.gouStart,
      PROCESS_GOU_FINAL_DT: this.form.value.gouFinal,
      PROCESS_GIN_START_DT: this.form.value.ginStart,
      PROCESS_GIN_FINAL_DT: this.form.value.ginFinal,
      PROCESS_LDG_START_DT: this.form.value.ldgStart,
      PROCESS_LDG_FINAL_DT: this.form.value.ldgFinal,
      PROCESS_EXC_START_DT: this.form.value.excStart,
      PROCESS_EXC_FINAL_DT: this.form.value.excFinal,
      PROCESS_INC_START_DT: this.form.value.incStart,
      PROCESS_INC_FINAL_DT: this.form.value.incFinal
    }

    this.closeDialog(filter);
  }

  reset(): void {
    this.form.setValue({
      status: null,
      container: null,
      type: null,
      typeFilter: '',
      hbl: null,
      customer: null,
      customerFilter: '',
      carrier: null,
      carrierFilter: '',
      originPort: null,
      originPortFilter: '',
      destinationPort: null,
      destinationPortFilter: '',
      rul: null,
      isPartial: null,
      ataStart: null,
      ataFinal: null,
      gouStart: null,
      gouFinal: null,
      ginStart: null,
      ginFinal: null,
      ldgStart: null,
      ldgFinal: null,
      incStart: null,
      incFinal: null,
    });
  }
}
