<header>
  <h2>Invoices</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">      
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber !== 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu xPosition="before">
        <button mat-menu-item (click)="applyDiscount({element: selection.selected})">
          <mat-icon>attach_money</mat-icon>
          <span>Apply discount</span>
        </button>
        <button mat-menu-item (click)="changeStatus({element: selection.selected})">
          <mat-icon>check_circle</mat-icon>
          <span>Change status</span>
        </button>
        <button mat-menu-item (click)="insertComment({element: selection.selected})">
          <mat-icon>chat</mat-icon>
          <span>Insert comment</span>
        </button>
        <button mat-menu-item (click)="insertAttachment({element: selection.selected})">
          <mat-icon>attach_file</mat-icon>
          <span>Insert attachment</span>
        </button>
      </mat-menu>
      
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="menuExport">Export</button>
      <mat-menu #menuExport xPosition="before">
        <button mat-menu-item (click)="export()" [disabled]="filteredInvoicesTableDataSource.data.length === 0">Export List</button>
        <button mat-menu-item (click)="exportContainers(selection.selected)">Export Containers</button>
        <button mat-menu-item (click)="savePDF()" [disabled]="selection.isEmpty()">Export PDF</button>
      </mat-menu>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #invoicesTable [dataSource]="filteredInvoicesTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">Proforma</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Service">Service</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_SHIPMENT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Shipment">Shipment</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_SHIPMENT}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by HBL">House Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.INVOICE_HBL)" [matTooltip]="element.INVOICE_HBL" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_HBL}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_DPT_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Dept">Dept</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_DPT_CODE)" [matTooltip]="element.SHIP_DPT_CODE" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.SHIP_DPT_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_CUS_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td matTooltip="{{element.INVOICE_CUS_CODE}} - {{element.INVOICE_CUS_NAME}}" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_CUS_NAME}}</td>
      </ng-container>      
      <ng-container matColumnDef="INVOICE_CAR_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.INVOICE_CAR_NAME)" [matTooltip]="element.INVOICE_CAR_NAME" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_CAR_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_CONSOL_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Consol Type">Consol Type</th>
        <td [matTooltipDisabled]="!isOverflow(element.INVOICE_CONSOL_TYPE)" [matTooltip]="element.INVOICE_CONSOL_TYPE" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_CONSOL_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_INC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Incoterm">Incoterm</th>
        <td [matTooltipDisabled]="!isOverflow(element.INVOICE_INC)" [matTooltip]="element.INVOICE_INC" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_INC}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_POL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POL">Origin Port</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_POL}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_POD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POD">Destination Port</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_POD}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_START_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Start Date">Start Date</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_START_DT | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_END_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by End Date">End Date</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_END_DT | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_TOTAL_AMOUNT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Total">Amount USD</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_TOTAL_AMOUNT | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_DISCOUNT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Discount">Discount USD</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">
          {{element.INVOICE_DISCOUNT_TYPE === '0' ? (element.INVOICE_DISCOUNT / 100 | percent) : ''}}
          {{element.INVOICE_DISCOUNT_TYPE === '1' ? (element.INVOICE_DISCOUNT | currency) : ''}}
        </td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_AMT_DISCOUNT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Total With Discount">Total With Discount USD</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_AMT_DISCOUNT | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_PF">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Invoice Payable Forecast">Payables Forecast</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_PF | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_ORIGIN_AMT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Origin Station">Origin Station</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_ORIGIN_AMT | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_SITUATION">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Invoice Status</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{getStatus(element.INVOICE_SITUATION)}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_CUS_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Invoice Date">Invoice Date</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_CUS_DT | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_INC_DATETIME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Calculation Date">Calculation Date</th>
        <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">{{element.INVOICE_INC_DATETIME | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_CMT_ATCH">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Comment/Attachment">Comment / Attachment</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_CMT_ATCH)" [matTooltip]="element.INVOICE_CMT_ATCH" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">
          <a class="cmt-atch" (click)="openSidebar(element.INVOICE_ID)">
            <span class="flag"
              [ngClass]="{
                'grey': element.INVOICE_CMT_ATCH === 0,
                'green': element.INVOICE_CMT_ATCH > 0             
              }">
            </span>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="EXCEPTION_COUNT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer Exceptions">Customer Exceptions</th>
        <td [matTooltipDisabled]="!isOverflow(element.EXCEPTION_COUNT)" [matTooltip]="element.INVOICE_CMT_ATCH" mat-cell *matCellDef="let element; let i = index" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">
          <a class="cmt-atch" (click)="openExceptions(element.INVOICE_ID)">
            <span class="flag"
              [ngClass]="{
                'grey': element.EXCEPTION_COUNT === 0,
                'green': element.EXCEPTION_COUNT > 0             
              }">
            </span>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="NOTIFICATION_SITUATION">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Notification Status">Notification Status</th>
        <td mat-cell *matCellDef="let element; let i = index" [matTooltip]="element.NOTIFICATION_TOOLTIP" [ngClass]="{ 'highlight-row': isRowHovered(i)}" (mouseover)="defineRowHovered(i)">
          <mat-icon
            class="material-symbols-outlined notification"
            *ngIf="element.NOTIFICATION_SITUATION"
            [ngClass]="{
              'yellow': element.NOTIFICATION_SITUATION == 'Pending',
              'blue': element.NOTIFICATION_SITUATION == 'Active',
              'green': element.NOTIFICATION_SITUATION == 'Closed',
              'red': element.NOTIFICATION_SITUATION == 'Failure' || element.NOTIFICATION_SITUATION == 'Dispute'
            }"
          >
            {{ showNotificationIcon(element.NOTIFICATION_SITUATION) }}
          </mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            <button *ngIf="element.INVOICE_SITUATION !== '3' && element.INVOICE_SITUATION !== '4'" mat-icon-button matTooltip="Apply Discount" (click)="applyDiscount({ element })">
              <mat-icon color="primary">attach_money</mat-icon>
            </button>
            <button *ngIf="element.INVOICE_SITUATION !== '4'" mat-icon-button matTooltip="Change Status" (click)="changeStatus({ element })">
              <mat-icon color="primary">check_circle</mat-icon>
            </button>
          </ng-container>
          <a mat-icon-button matTooltip="View Invoice" [routerLink]="['/invoices/print', element.INVOICE_ID]">
            <mat-icon color="primary">visibility</mat-icon>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="invoicesTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: invoicesTableDisplayedColumns"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>

<dhl-sidebar [isOpen]="isExceptionsOpen" (close)="closeExceptions()">
  <dhl-exception [customer_exception]="customer_exception" [group_exception]="group_exception" [customer_contacts]="customer_contacts"></dhl-exception>
</dhl-sidebar>

<!--Salvar PDF-->
<div style="display: none;">
  <div id="print">
    <div class="watermark" *ngIf="invoicePdf?.INVOICE_SITUATION === '4'">
      <h1 class="watermark-message">Cancelado</h1>
    </div>
    <div class="invoice-header">
      <div class="invoice-dhl">
        <svg width="165px" height="23" viewBox="0 0 284 40" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414"><g fill="#d40511" fill-rule="nonzero"><path d="M0 36.6h34.4l-2 2.8H0v-2.8zM283.5 39.4h-42.1l2.1-2.8h40v2.8zM0 31.4h38.3l-2.1 2.8H0v-2.8zM0 26.2h42.2L40.1 29H0v-2.8zM283.5 34.2h-38.2l2.1-2.8h36.1v2.8zM251.3 26.2h32.2V29h-34.3l2.1-2.8zM37.1 39.4l18.2-24.3h22.6c2.5 0 2.5.9 1.2 2.6-1.2 1.6-3.3 4.5-4.6 6.2-.6.9-1.8 2.4 2 2.4h30.2c-2.5 3.4-10.7 13.2-25.3 13.2-11.8-.1-44.3-.1-44.3-.1zM141.3 26.2l-9.9 13.2h-26l9.9-13.2h26zM178.9 26.2L169 39.4h-26l9.9-13.2h26zM187.4 26.2s-1.9 2.6-2.8 3.8c-3.3 4.3-.4 9.4 10.3 9.4h41.8l9.9-13.2h-59.2z"/><path d="M49.9 0l-9.1 12.1h49.4c2.5 0 2.5.9 1.2 2.6-1.2 1.6-3.3 4.5-4.6 6.2-.6.8-1.8 2.4 2 2.4H109l6-8c3.7-4.9.3-15.2-12.9-15.2C90.2 0 49.9 0 49.9 0zM181.2 23.2h-63.7L134.9 0h26L151 13.3h11.6l10-13.3h26l-17.4 23.2zM234.6 0l-17.4 23.2h-27.6L207 0h27.6z"/></g></svg>
        <address>
          <p><strong>DHL GLOBAL FORWARDING (BRAZIL) LOGISTICS LTDA</strong></p>
          <p>CNPJ Matriz: 10.228.777/0001-61</p>
          <p>Santos - São Paulo</p>
        </address>
      </div>
      <div class="invoice-customer">
        <h3>Proforma N°. {{invoicePdf?.INVOICE_ID}}</h3>
        <span class="date" *ngIf="invoicePdf?.INVOICE_CUS_DT">
          <p><strong>Emissão Proforma: </strong>{{ invoicePdf?.INVOICE_CUS_DT | date:'MMM d, y':'':'pt-BR' | titlecase}}</p>
        </span>
        <span>Cliente:</span>
        <address>
          <p><strong>{{invoicePdf?.INVOICE_CUS_NAME}}</strong></p>
          <p>{{invoicePdf?.INVOICE_CUS_ADDRESS | uppercase}}, {{invoicePdf?.INVOICE_CUS_STATE}}</p>
        </address>
      </div>
    </div>
    <div class="invoice-content">
      <div class="invoice-title">
        <h3>{{invoicePdf?.INVOICE_TYPE}}</h3>        
      </div>
      <div class="invoice-details">
        <div>
          <p><strong>Navio: </strong>{{invoicePdf?.INVOICE_VESSEL}}</p>
          <p><strong>{{invoicePdf?.INVOICE_TYPE == 'DEMURRAGE' ? 'Data Chegada: ' : 'Data Embarque: '}}</strong>{{invoicePdf?.INVOICE_TYPE == 'DEMURRAGE' ? (invoicePdf?.INVOICE_ATA | date:'dd/MM/YYYY') : (invoicePdf?.INVOICE_ATD | date:'dd/MM/YYYY')}}</p>
          <p *ngIf="invoicePdf?.INVOICE_CHARGE_TYPE"><strong>Tipo de Cobrança: </strong>{{invoicePdf?.INVOICE_CHARGE_TYPE}}</p>
        </div>
        <div>
          <p><strong>Porto de Origem: </strong>{{invoicePdf?.INVOICE_POL}}</p>
          <p><strong>Porto de Destino: </strong>{{invoicePdf?.INVOICE_POD}}</p>
        </div>
        <div>
          <p><strong>House B/L: </strong>{{invoicePdf?.INVOICE_HBL}}</p>
          <p *ngIf="invoicePdf?.INVOICE_REFERENCE"><strong>Referência: </strong>{{invoicePdf?.INVOICE_REFERENCE}}</p>
        </div>
      </div>
      <table *ngIf="invoicePdf != null">
        <thead>
          <tr>
            <th>Contêiner(es)</th>
            <th>Tipo(s)</th>
            <th>Início da Cobrança</th>
            <th>Fim da Cobrança</th>
            <th>Dias Livres</th>
            <th>Dias de Cobrança</th>
            <th>Tarifa Diária</th>
            <th>Total USD</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let container of invoicePdf.INVOICE_CTNS">
            <td>{{container?.INVOICE_CTN_NUMBER}}</td>
            <td>{{container?.INVOICE_CTN_TYPE}}</td>
            <td>{{container?.INVOICE_CTN_PENALTY_START | date:'dd/MM/YYYY'}}</td>
            <td>{{container?.INVOICE_CTN_PENALTY_END | date:'dd/MM/YYYY'}}</td>
            <td>{{container?.INVOICE_CTN_FREE_TIME}}</td>
            <td>{{container?.INVOICE_CTN_PENALTY_DAYS}}</td>
            <td>{{container?.INVOICE_CTN_RATE_RANGE}}</td>
            <td>{{container?.INVOICE_CTN_AMOUNT | currency:'USD ':'code'}}</td>
          </tr>
        </tbody>
      </table>
      <div class="invoice-total">
        <div>
          <strong>Total USD: </strong>
          <span>{{invoicePdf.INVOICE_TOTAL_AMOUNT | currency:'USD ':'code'}}</span>
        </div>
        <div *ngIf="(invoicePdf?.INVOICE_TOTAL_AMOUNT - invoicePdf?.INVOICE_AMT_DISCOUNT) > 0">
          <strong>Desconto: </strong>
          <span>{{invoicePdf?.INVOICE_TOTAL_AMOUNT - invoicePdf?.INVOICE_AMT_DISCOUNT | currency:'USD ':'code'}}</span>
        </div>
        <div *ngIf="(invoicePdf?.INVOICE_TOTAL_AMOUNT - invoicePdf?.INVOICE_AMT_DISCOUNT) > 0">
          <strong>Total: </strong>
          <span>{{invoicePdf?.INVOICE_AMT_DISCOUNT | currency:'USD ':'code'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>