import { HotToastService } from '@ngneat/hot-toast';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { UniqueTermService } from './../../../services/unique-term.service';
import { IUniqueTerm } from './../../../models/unique-term';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AwsService } from 'src/app/services/aws.service';
import { IAttachment } from 'src/app/models/attachment.model';
import { ICustomer } from 'src/app/models/customer.model';
import { AttachmentService } from 'src/app/services/attachment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ThisReceiver } from '@angular/compiler';
import { AuthService } from 'src/app/services/auth.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { CommentService } from 'src/app/services/comment.service';
import { ITimeline } from 'src/app/models/timeline.model';
import { IComment } from 'src/app/models/comment.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-customer-unique-term',
  templateUrl: './customer-unique-term.component.html',
  styleUrls: ['./customer-unique-term.component.scss']
})
export class CustomerUniqueTermComponent implements OnInit {
  cusUniqueTermForm: UntypedFormGroup;
  term: IUniqueTerm = {};
  uniqTermId = 0;
  customers: Array<ICustomer>;
  filteredCustomers: Subject<Array<ICustomer>> = new Subject<Array<ICustomer>>();
  addOnBlur: boolean = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  uniSlReceiversList: Array<string> = [];
  uniOperReceiversList: Array<string> = [];

  isSidebarOpen = false;
  comments: Array<IComment> = [];
  timeline: Array<ITimeline> = [];
  attachments: Array<IAttachment> = [];
  
  files: Array<File> = [];
  hasFile = false;
  showAttachments: boolean = true;
  isFormDisabled: boolean = false;

  showToUploadTab = false;

  minEndDate: Date;

  constructor(
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private uniqueTermService: UniqueTermService,
    private customerService: CustomerService,
    private toast: HotToastService,
    private attachmentService: AttachmentService,
    private commentService: CommentService,
    private timelineService: TimelineService,
    private awsService: AwsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.titleService.setTitle('DHL | Unique Terms (Customers)');    
  }

  ngOnInit() {
    this.term = this.route.snapshot.data['customerUniqueTerm'] && this.route.snapshot.data['customerUniqueTerm'][0];

    this.uniSlReceiversList = this.term?.UNI_SL_RECEIVERS?.split(', ') || [];
    this.uniOperReceiversList = this.term?.UNI_OPER_RECEIVERS?.split(', ') || [];
    
    this.cusUniqueTermForm = this.formBuilder.group({
      uniName: new UntypedFormControl(this.term?.UNI_NAME, [Validators.required]),
      uniReceivedDt: new UntypedFormControl(this.term?.UNI_RECEIVED_DT, [Validators.required]),
      uniStartDt: new UntypedFormControl(this.term?.UNI_START_DT, [Validators.required]),
      uniEndDt: new UntypedFormControl(this.term?.UNI_END_DT, [Validators.required]),
      uniSlReceivers: new UntypedFormControl('', [Validators.required, Validators.email]),
      uniOperReceivers: new UntypedFormControl('', [Validators.required, Validators.email]),
      customer: new UntypedFormControl(this.term?.CUS_ID, [Validators.required]),
      customerFilter: new UntypedFormControl(''),
      uniComment: new UntypedFormControl(this.term?.UNI_COMMENT, [Validators.required]),
      uniBranch: new UntypedFormControl(this.term?.UNI_BRANCH, [Validators.required])
    });

    this.verifyReceivers();
    
    this.minEndDate = this.cusUniqueTermForm.get('uniStartDt').value;

    this.getCustomers({ CUS_ID: this.cusUniqueTermForm.value.customer, IS_SELECT: 1, CUS_COUNTRY: 'BR' });

    this.cusUniqueTermForm.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));
    
    this.cusUniqueTermForm.get('uniStartDt').valueChanges.subscribe((date: Date) => {
      this.minEndDate = this.cusUniqueTermForm.get('uniStartDt').value;
    });

    if (this.term) {
      this.getAttachments();
      this.getComments();
      this.getTimeline();
    }

    this.route.url.subscribe((url) => {
      if (url[2]?.path === 'view') {
        this.isFormDisabled = true;
        this.cusUniqueTermForm.disable();
      } else if (!url[2]?.path) {
        this.showAttachments = false;
      }
    });
  }

  filterCustomers(filter: string): void {
    if (!this.cusUniqueTermForm.get('customer').value)    
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1, CUS_COUNTRY: 'BR' }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  verifyReceivers(): void{
    if(this.uniSlReceiversList.length && !this.uniOperReceiversList.length){
      this.cusUniqueTermForm.get('uniOperReceivers').setValidators([Validators.email]);
      this.cusUniqueTermForm.get('uniOperReceivers').updateValueAndValidity();

      this.cusUniqueTermForm.get('uniSlReceivers').setValidators([]);
      this.cusUniqueTermForm.get('uniSlReceivers').updateValueAndValidity();
    }
  
    if(!this.uniSlReceiversList.length && this.uniOperReceiversList.length){
      this.cusUniqueTermForm.get('uniSlReceivers').setValidators([Validators.email]);
      this.cusUniqueTermForm.get('uniSlReceivers').updateValueAndValidity();

      this.cusUniqueTermForm.get('uniOperReceivers').setValidators([]);
      this.cusUniqueTermForm.get('uniOperReceivers').updateValueAndValidity();
    }
  
    if(!this.uniSlReceiversList.length && !this.uniOperReceiversList.length){
      this.cusUniqueTermForm.get('uniOperReceivers').setValidators([Validators.required, Validators.email]);
      this.cusUniqueTermForm.get('uniOperReceivers').updateValueAndValidity();
  
      this.cusUniqueTermForm.get('uniSlReceivers').setValidators([Validators.required, Validators.email]);
      this.cusUniqueTermForm.get('uniSlReceivers').updateValueAndValidity();
    }

    if(this.uniSlReceiversList.length && this.uniOperReceiversList.length){
      this.cusUniqueTermForm.get('uniOperReceivers').setValidators([]);
      this.cusUniqueTermForm.get('uniOperReceivers').updateValueAndValidity();
  
      this.cusUniqueTermForm.get('uniSlReceivers').setValidators([]);
      this.cusUniqueTermForm.get('uniSlReceivers').updateValueAndValidity();
    }
  };

  getErrorMessage(formControl: string): string {
    if (this.cusUniqueTermForm.get(formControl).hasError('email')) {
      return 'You must enter a valid email';
    } else if (this.cusUniqueTermForm.get(formControl).hasError('number')) {
      return 'The value must be a number';
    } else if (this.cusUniqueTermForm.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

  addSalesMails(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (value && this.cusUniqueTermForm.get('uniSlReceivers').hasError('email')) {
      this.toast.error('You must provide a valid email.');
    } else if (value) {
      this.uniSlReceiversList.push(value);
    }

    event.chipInput!.clear();
    this.verifyReceivers();
  }

  removeSalesMails(requested: string): void {
    const index = this.uniSlReceiversList.indexOf(requested);

    if (index >= 0) {
      this.uniSlReceiversList.splice(index, 1);
    }

    if (this.uniSlReceiversList.length === 0) {
      this.cusUniqueTermForm.get('uniSlReceivers').setValue('');
    }

    this.verifyReceivers();
  }

  addOperationsMails(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (value && this.cusUniqueTermForm.get('uniOperReceivers').hasError('email')) {
      this.toast.error('You must provide a valid email.');
    } else if (value) {
      this.uniOperReceiversList.push(value);
    }

    event.chipInput!.clear();  
    this.verifyReceivers(); 
  }

  removeOperationsMails(requested: string): void {
    const index = this.uniOperReceiversList.indexOf(requested);

    if (index >= 0) {
      this.uniOperReceiversList.splice(index, 1);
    }

    if (this.uniOperReceiversList.length === 0) {
      this.cusUniqueTermForm.get('uniOperReceivers').setValue('');
    }

    this.verifyReceivers(); 
  }

  onFormSubmit(e: Event){
    e.preventDefault();

    const formValue = this.cusUniqueTermForm.value;

    let term: IUniqueTerm = {
      UNI_NAME: encodeURIComponent(formValue.uniName),
      UNI_SL_RECEIVERS: this.uniSlReceiversList.join(', '),
      UNI_OPER_RECEIVERS: this.uniOperReceiversList.join(', '),
      UNI_RECEIVED_DT: formValue.uniReceivedDt,
      UNI_START_DT: formValue.uniStartDt,
      UNI_END_DT: formValue.uniEndDt,
      CUS_ID: formValue.customer,
      UNI_COMMENT: formValue.uniComment,
      UNI_BRANCH: formValue.uniBranch,
      UNI_USER: this.authService.userId
    }

    if(!this.term) {
      this.uniqueTermService.post(term).subscribe((response) => {
        this.toast.success(String(response));
        this.getTermId(term);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }
    else {
      term.UNI_ID = this.term.UNI_ID;
      term.UNI_STATUS = 1;

      this.uniqTermId = term.UNI_ID;

      this.uniqueTermService.put(term).subscribe((response) => {
        this.toast.success(String(response));
        if(this.files.length > 0) {
          this.uploadFiles(this.files);
        }
        else {
          this.router.navigate(['/unique-terms/customers']);
        }
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }

  }

  getTermId(term: IUniqueTerm){
    this.uniqueTermService.getTermId(term).subscribe((id: number) => {
      this.uniqTermId = id;
      if(this.uniqTermId > 0){
        this.uploadFiles(this.files);
      }
    });
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `customer_unique_term`, REG_ID: this.term.UNI_ID }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: this.term.UNI_ID,
          COMM_REF_GROUP: `customer_unique_term`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, error => this.toast.error(error.error.Message));
  }

  getAttachments(): void {
    if(this.term == undefined) {
      return;
    }

    this.attachmentService.getAttachments({ ATCH_REF_GROUP: `customer_unique_term`, REG_ID: this.term.UNI_ID }).subscribe(
      (attachments) => {
        this.attachments = attachments;
        this.hasFile = attachments.length > 0 ? true : false;
      }
    );
  }

  onUpload(files: Array<File>) {  
    this.files = [];
    files.forEach(file => {
      this.files.push(file);
    });

    this.hasFile = true;
  }

  uploadFiles(files: Array<File>) {
    this.awsService
      .uploadFiles(files, `customer_unique_term`, [{ REG_ID: this.uniqTermId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () =>'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.files = null;
          this.router.navigate(['/unique-terms/customers']);
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    const timeline: ITimeline = {
      TIMELINE_GROUP: 'customer_unique_term',
      TIMELINE_REG_ID: this.term.UNI_ID?.toString()
    }

    this.timelineService.getTimeline(timeline).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

}