import { IUniqueTerm } from './../models/unique-term';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UniqueTermService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getCustomerTerms(term: IUniqueTerm): Observable<Array<IUniqueTerm>> {
    return this.httpClient.get<Array<IUniqueTerm>>(`${this.apiURL}/api/unique-terms/customers?filter=${JSON.stringify(term)}`, this.httpOptions);
  };

  getCarrierTerms(term: IUniqueTerm): Observable<Array<IUniqueTerm>> {
    return this.httpClient.get<Array<IUniqueTerm>>(`${this.apiURL}/api/unique-terms/carriers?filter=${JSON.stringify(term)}`, this.httpOptions);
  };

  getTerm(term: IUniqueTerm): Observable<IUniqueTerm> {
    return this.httpClient.get<IUniqueTerm>(`${this.apiURL}/api/unique-term?filter=${JSON.stringify(term)}`, this.httpOptions);
  };

  getTermId(term: IUniqueTerm): Observable<Number> {
    return this.httpClient.get<Number>(`${this.apiURL}/api/unique-term-id?filter=${JSON.stringify(term)}`, this.httpOptions);
  };

  post(term: IUniqueTerm): Observable<IUniqueTerm> {
    return this.httpClient.post(`${this.apiURL}/api/unique-term?filter=${JSON.stringify(term)}`, null, this.httpOptions);
  }

  put(term: IUniqueTerm): Observable<IUniqueTerm> {
    return this.httpClient.put(`${this.apiURL}/api/unique-term?filter=${JSON.stringify(term)}`, null, this.httpOptions);
  }

}
