import { IPaymentOrder } from './../../../../models/payable.model';
import { IPayable } from '../../../../models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IGeneratePoDialog {
  element: IPayable | Array<IPayable>;
}

@Component({
  selector: 'dhl-generate-po-dialog',
  templateUrl: './generate-po-dialog.component.html',
  styleUrls: ['./generate-po-dialog.component.scss']
})
export class GeneratePoDialogComponent implements OnInit {
  form: UntypedFormGroup;

  totalUsd: number = 0;
  discount: number = 0;
  totalDiscount: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IGeneratePoDialog,
    private dialogRef: MatDialogRef<GeneratePoDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.totalUsd = 0;
    this.discount = 0;
    this.totalDiscount = 0;

    if(this.data.element instanceof Array){
      this.data.element.map((payable) => {
        this.totalUsd += payable?.PB_VAL_USD;
        this.totalDiscount += payable?.PB_VAL_DIS;
      });
    }else{
      this.totalUsd = this.data.element?.PB_VAL_USD;
      this.totalDiscount = this.data.element?.PB_VAL_DIS;
    }

    this.discount = this.totalDiscount ? (this.totalUsd - this.totalDiscount) : null;

    this.form = this.formBuilder.group({
      totalUsd: new UntypedFormControl(this.totalUsd?.toFixed(2)),
      discount: new UntypedFormControl(this.discount?.toFixed(2)),
      totalDiscount: new UntypedFormControl(this.totalDiscount?.toFixed(2)),
      rate: new UntypedFormControl('', [Validators.required]),
      totalBrl: new UntypedFormControl('', [Validators.required])
    });

    this.form.get('rate').valueChanges.subscribe(()=>{      
      this.form.get('totalBrl').setValue((this.form.get('rate').value * this.form.get('totalDiscount').value).toFixed(2));
    });
  }

  close(value: IPaymentOrder): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();

    let pbList: Array<number> = [];
    const isBulk = this.isBulk(this.data.element) ? 1 : 0;

    if(this.data.element instanceof Array){
      this.data.element.map((pb) => {pbList.push(pb.PB_ID)});
    }else{
      pbList.push(this.data.element.PB_ID);      
    }
    
    let newPaymentOrder: IPaymentOrder = {
      PB_LIST: pbList,
      PO_TYPE: 0,
      PO_VAL_USD: this.form.value.totalUsd.toString().replace(',','.'),
      PO_VAL_DIS: this.form.value.totalDiscount.toString().replace(',','.'),
      PO_CUR_RATE: this.form.value.rate.toString().replace(',','.'),
      PO_VAL_BRL: this.form.value.totalBrl.toString().replace(',','.'),
      PO_BULK: isBulk,
      PO_USER: this.authService.userId
    };

    this.close(newPaymentOrder);
  }

  getErrorMessage(formControl: string): string {
    if (this.form.get(formControl).hasError('number')) {
      return 'The value must be a number';
    } else if (this.form.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

  isBulk(data) {
    if (Array.isArray(data)) {
        return data.some(item => item.PB_CTN_TYPE && item.PB_CTN_TYPE.includes("TC"));
    } else { 
        return data.PB_CTN_TYPE && data.PB_CTN_TYPE.includes("TC");
    }
  }
}
