import { JsonViewDialogComponent } from './integration-logs-dialogs/json-view-dialog/json-view-dialog.component';
import { IIntegrationIndicators, IIntegrationLog } from './../../models/log.model';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { LogService } from 'src/app/services/log.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterDialogComponent } from './integration-logs-dialogs/filter-dialog/filter-dialog.component';
import { XlsxExporterService } from 'mat-table-exporter';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatTableFilter } from 'mat-table-filter';
import { HotToastService } from '@ngneat/hot-toast';

const integrationLogs: Array<IIntegrationLog> = [];

@Component({
  selector: 'dhl-integration-logs',
  templateUrl: './integration-logs.component.html',
  styleUrls: ['./integration-logs.component.scss']
})
export class IntegrationLogsComponent implements OnInit {
  pageKey = 'integrationLogs';
  hideLoader: boolean;

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IIntegrationLog>(this.allowMultiSelect, this.initialSelection);
  logFilters: IIntegrationLog = {
    LI_ID: null,
    LI_HBL: null,
    LI_SHIP_CODE: null,
    LI_MESSAGE: null,
    LI_STATUS_ITEM: null,
    LI_MONTH: null,
    LI_YEAR: null
  };
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;

  integrationIndicatorsTableDataSource = new MatTableDataSource<IIntegrationIndicators>();
  integrationLogTableDataSource = new MatTableDataSource<IIntegrationLog>(integrationLogs);
  integrationLogExportDataSource = new MatTableDataSource<IIntegrationLog>();
  integrationLogTableDisplayedColumns: Array<string> = [
    'select', 
    'LI_ID', 
    'LI_HBL', 
    'LI_SHIP_CODE', 
    'LI_MESSAGE', 
    'LI_STATUS_ITEM', 
    'LI_DATE', 
    'json'
  ];

  @ViewChild(MatTable) logTable: MatTable<IIntegrationLog>;
  @ViewChild('integrationLogSort') integrationLogSort: MatSort;
  @ViewChild('integrationLogPaginator') integrationLogPaginator: MatPaginator;

  integrationLogIndicators: IIntegrationIndicators = {
    LDI_REC_FILES: 1000,
    LDI_LOAD_OK: 933,
    LDI_LOAD_ERR: 67,
    LDI_PEND_FILES: 0,
    LDI_MONTH: 9,
    LDI_YEAR: 2022
  }

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private logService: LogService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private exporter: XlsxExporterService,
    private filterService: FilterService,
    private toast: HotToastService,
  ) {
    this.titleService.setTitle('DHL | Logs (Integration)');
  }

  ngOnInit(): void {
    const filter = this.filterService.getFilter('integrationLog');
    if (filter) {
      this.logFilters = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.getIntegrationIndicators({LDI_MONTH: this.logFilters.LI_MONTH, LDI_YEAR: this.logFilters.LI_YEAR});
    this.getIntegrationLogs(this.logFilters);

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getIntegrationLogs(this.logFilters);
    });
  }

  getIntegrationLogs(filter: IIntegrationLog): void {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.integrationLogTableDataSource.data.length === 0) this.hideLoader = false;
    this.logService.getIntegrationLogs({
      ...filter,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((logs: Array<IIntegrationLog>) => {
      this.hideLoader = true;
      this.integrationLogTableDataSource.data = logs;
      this.length = logs[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  getIntegrationIndicators(filter: IIntegrationIndicators): void{
    this.logService.getIntegrationIndicators(filter).subscribe((indicators: Array<IIntegrationIndicators>)=>{
      this.integrationIndicatorsTableDataSource.data = indicators;
    })
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getIntegrationLogs(this.logFilters);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getIntegrationLogs(this.logFilters);
  }

  openJsonViewDialog(data: IIntegrationLog): void{
    this.logService.getIntegrationJson(data).subscribe((data)=>{
      this.dialog.open(JsonViewDialogComponent, { data });
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.integrationLogTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.integrationLogTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  setFilters(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.logFilters
      }
    });

    dialogRef.afterClosed().subscribe((filters: IIntegrationLog) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.logFilters = filters;
      this.pageNumber = 0;      
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.logFilters.LI_ID = filters.LI_ID;
      this.logFilters.LI_HBL = filters.LI_HBL;
      this.logFilters.LI_SHIP_CODE = filters.LI_SHIP_CODE;
      this.logFilters.LI_MESSAGE = filters.LI_MESSAGE;
      this.logFilters.LI_STATUS_ITEM = filters.LI_STATUS_ITEM;
      this.logFilters.LI_MONTH = filters.LI_MONTH;
      this.logFilters.LI_YEAR = filters.LI_YEAR;

      this.filterService.setFilter('integrationLog', this.logFilters);

      this.getIntegrationIndicators({LDI_MONTH: this.logFilters.LI_MONTH, LDI_YEAR: this.logFilters.LI_YEAR});
      this.getIntegrationLogs(this.logFilters);
    });
  }

  export(): void {
    const headers = {
      LI_ID: 'ID',
      LI_HBL: 'HBL',
      LI_SHIP_CODE: 'SHIPMENT ID',
      LI_MESSAGE: 'MESSAGE',
      LI_STATUS_ITEM: 'STATUS ITEM',
      LI_DATE_TEXT: 'EXECUTION DATE'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.logService.getIntegrationLogs({
        ...this.logFilters,
        EXPORT: 1,
        SEARCH: search, 
        SORT_COL: this.sortCol,
        SORT_ORDER: this.sortOrder
      }).subscribe((dataExport: Array<IIntegrationLog>) => {
        this.integrationLogExportDataSource.data = dataExport;

        const data: Array<IIntegrationLog> = this.prepareDataToExport([...this.integrationLogExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `integrationLogs_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref?.close();
      });      
    } else {
      const data: Array<IIntegrationLog> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `integrationLogs_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IIntegrationLog>): Array<IIntegrationLog> {
    const dataToExport: Array<IIntegrationLog> = [];

    const fieldText = {
      LI_DATE_TEXT: ""
    }

    data.forEach((integration) => {
      const newData = {...integration, ...fieldText};

      newData.LI_DATE_TEXT = newData.LI_DATE && moment(newData.LI_DATE).format('DD/MM/YYYY');

      delete newData.LI_DATE;
      delete newData.LI_STATUS;
      delete newData.LI_INC_DATE;
      delete newData.LI_SHIP_ID;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
