import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IContainer } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';

interface IRejectedDateDialog {
  element: IContainer;
}

@Component({
  selector: 'dhl-date-rejected-dialog',
  templateUrl: './date-rejected-dialog.component.html',
  styleUrls: ['./date-rejected-dialog.component.scss']
})
export class DateRejectedDialogComponent implements OnInit {
  dateForm = this.formBuilder.group({
    gateOut: new UntypedFormControl(this.data.element.CTN_GOU, [Validators.required]),
    gateIn: new UntypedFormControl(this.data.element.CTN_GIN, [Validators.required]),
    loading: new UntypedFormControl(this.data.element.CTN_LDG, [Validators.required])
  });

  maxGateOut: Date = this.dateForm.get('gateIn').value || this.dateForm.get('loading').value || new Date();
  minGateIn: Date = this.dateForm.get('gateOut').value;
  maxGateIn: Date = this.dateForm.get('loading').value || new Date();
  minLoading: Date = this.dateForm.get('gateIn').value || this.dateForm.get('gateOut').value;
  maxLoading: Date = new Date();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DateRejectedDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: IRejectedDateDialog,
  ) { }

  ngOnInit(): void {
    this.dateForm.get('gateOut').valueChanges.subscribe((date: Date) => {
      this.minGateIn = date;
      this.minLoading = this.dateForm.get('gateIn').value || date;
    });

    this.dateForm.get('gateIn').valueChanges.subscribe((date: Date) => {
      this.maxGateOut = date || this.dateForm.get('loading').value || new Date();
      this.minLoading = date || this.dateForm.get('gateOut').value;
    });

    this.dateForm.get('loading').valueChanges.subscribe((date: Date) => {
      this.maxGateOut = this.dateForm.get('gateIn').value || date || new Date();
      this.maxGateIn = date || new Date();
    });
  }

  closeDialog(element: IContainer): void {
    this.dialogRef.close(element);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    
    const container: IContainer = {
      SHIP_ID: this.data.element.SHIP_ID,
      CTN_ID: this.data.element.CTN_ID,
      CTN_NUMBER: this.data.element.CTN_NUMBER,
      CTN_GOU: this.dateForm.get('gateOut').value,
      CTN_GIN: this.dateForm.get('gateIn').value,
      CTN_LDG: this.dateForm.get('loading').value,
      CTN_USER: this.authService.userId
    }

    this.closeDialog(container);
  }

  resetForm(): void {
    this.dateForm.setValue({
      gateOut: '',
      gateIn: '',
      loading: ''
    });
  }
}
