import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { IPage } from 'src/app/models/page.model';
import { IUserGroup } from 'src/app/models/user-group.model';
import { IUser } from 'src/app/models/user.model';
import { UserGroupService } from 'src/app/services/user-group.service';

export interface IUserGroupViewDialog {
  userGroup: IUserGroup;
  mode: 'pages' | 'users';
}

@Component({
  selector: 'dhl-user-group-view-dialog',
  templateUrl: './user-group-view-dialog.component.html',
  styleUrls: ['./user-group-view-dialog.component.scss']
})
export class UserGroupViewDialogComponent implements OnInit {
  userGroupTableDataSource: MatTableDataSource<IUser> | MatTableDataSource<IPage>;
  userGroupTableDisplayedColumns: Array<string> = ['USER_ID', 'PAG_NAME'];
  filterValue: string = '';

  @ViewChild(MatTable) userGroupTable: MatTable<ICustomerGroup>;
  @ViewChild('userGroupSort') userGroupSort: MatSort;

  constructor(
    private userGroupService: UserGroupService,
    @Inject(MAT_DIALOG_DATA) public data: IUserGroupViewDialog,
    private dialogRef: MatDialogRef<UserGroupViewDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.data.mode === 'users') {
      this.getUsers();
      this.userGroupTableDisplayedColumns = ['USER_ID'];
    } else {
      this.getPages();
      this.userGroupTableDisplayedColumns = ['PAG_NAME'];
    }
  }

  getUsers(): void {
    const userGroup: IUserGroup = {
      GRP_ID: this.data.userGroup.GRP_ID
    }

    this.userGroupService.getAllUsers(userGroup).subscribe((users) => {
      this.userGroupTableDataSource = new MatTableDataSource<IUser>(users);
      this.changeDetectorRef.detectChanges();
      this.userGroupTableDataSource.sort = this.userGroupSort;      
    });
  }

  getPages(): void {
    const userGroup: IUserGroup = {
      GRP_ID: this.data.userGroup.GRP_ID
    }

    this.userGroupService.getAllPages(userGroup).subscribe((pages) => {
      this.userGroupTableDataSource = new MatTableDataSource<IPage>(pages);
      this.changeDetectorRef.detectChanges();
      this.userGroupTableDataSource.sort = this.userGroupSort;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  filterUserGroupTable(filterValue: string) {
    this.userGroupTableDataSource.filter = filterValue;
  }

}
