import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ICustomer } from "src/app/models/customer.model";
import { ICustomerGroup } from "src/app/models/customer-group.model";
import { IState } from "src/app/models/state.model";
import { CustomerGroupService } from "src/app/services/customer-group.service";
import { StateService } from "src/app/services/state.service";
import { ReplaySubject } from "rxjs";
import { AuthService } from "src/app/services/auth.service";

export interface ICustomerDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  customer?: ICustomer;
}

@Component({
  selector: 'dhl-customer-dialog',
  templateUrl: './customers-dialog.component.html',
  styleUrls: ['./customers-dialog.component.scss']
})
export class CustomerDialogComponent implements OnInit {
  customerForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  states: Array<IState> = [];
  filteredStates: ReplaySubject<Array<IState>> = new ReplaySubject<Array<IState>>(1);
  customerGroups: Array<ICustomerGroup> = [];
  filteredCustomerGroups: ReplaySubject<Array<ICustomerGroup>> = new ReplaySubject<Array<ICustomerGroup>>(1);

  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CustomerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICustomerDialog, private customerGroupService: CustomerGroupService, private stateService: StateService, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit(): void {
    this.getState();
    this.getCustomerGroup();

    this.customerForm = this.formBuilder.group({
      code: new UntypedFormControl(this.data.customer?.CUS_CODE, [Validators.required]),
      name: new UntypedFormControl(this.data.customer?.CUS_NAME, [Validators.required]),
      address: new UntypedFormControl(this.data.customer?.CUS_ADDRESS, [Validators.required]),
      state: new UntypedFormControl(this.data.customer?.CUS_STATE, [Validators.required]),
      stateFilter: new UntypedFormControl(''),
      unloco: new UntypedFormControl(this.data.customer?.CUS_UNLOCO, [Validators.required]),
      tax: new UntypedFormControl(this.data.customer?.CUS_TAX, [Validators.required]),
      group: new UntypedFormControl(this.data.customer?.CUS_GROUP_ID),
      groupFilter: new UntypedFormControl('')
    });

    if (this.data.mode == 'add' || this.data.mode == 'edit') {
      this.customerForm.get('stateFilter').valueChanges.subscribe(() => {
        this.filterStates();
      });

      this.customerForm.get('groupFilter').valueChanges.subscribe(() => {
        this.filterGroups();
      });
    }

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.customerForm.disable();
    }
  }
  
  getState(state: IState = null): void {
    this.stateService.getState(state).subscribe((states: Array<IState>) => {
      this.states = states;
      this.filteredStates.next(this.states);
    });
  }

  getCustomerGroup(): void {
    this.customerGroupService.getCustomerGroup().subscribe((customerGroups: Array<ICustomerGroup>) => {
      this.customerGroups = customerGroups;
      this.filteredCustomerGroups.next(customerGroups);
    });
  }

  filterStates(): void {
    const filterValue = this.customerForm.get('stateFilter').value.toLowerCase();
    this.filteredStates.next(this.states.filter((state) => state.STT_CODE.toLowerCase().includes(filterValue)));
  }

  filterGroups(): void {
    const filterValue = this.customerForm.get('groupFilter').value.toLowerCase();
    this.filteredCustomerGroups.next(this.customerGroups.filter((group) => group.CUS_GRP_NAME.toLowerCase().includes(filterValue)));
  }

  closeDialog(customer: ICustomer) {
    this.dialogRef.close(customer);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.customerForm.get('name').enable();
    this.customerForm.get('address').enable();
    this.customerForm.get('state').enable();
    this.customerForm.get('unloco').enable();
    this.customerForm.get('tax').enable();
    this.customerForm.get('group').enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.customerForm.value;
    let customer: ICustomer = null;

    if (this.data.mode == 'add') {
      customer = {
        CUS_CODE: formValue.code,
        CUS_NAME: encodeURIComponent(formValue.name),
        CUS_STATE: formValue.state,
        CUS_USER: this.authService.userId,
        CUS_ADDRESS: encodeURIComponent(formValue.address),
        CUS_UNLOCO: formValue.unloco,
        CUS_TAX: formValue.tax,
        CUS_GROUP_ID: formValue.group
      }
    } else if (this.data.mode == 'edit') {
      customer = {
        CUS_ID: this.data.customer.CUS_ID,
        CUS_CODE: formValue.code,
        CUS_NAME: formValue.name ? encodeURIComponent(formValue.name) : null,
        CUS_STATE: formValue.state,
        CUS_USER: this.authService.userId,
        CUS_ADDRESS: formValue.address ? encodeURIComponent(formValue.address) : null,
        CUS_UNLOCO: formValue.unloco,
        CUS_TAX: formValue.tax,
        CUS_STATUS: 1,
        CUS_GROUP_ID: formValue.group
      }
    } else if (this.data.mode == 'delete') {
      customer = {
        CUS_ID: this.data.customer.CUS_ID,
        CUS_CODE: null,
        CUS_NAME: null,
        CUS_STATE: null,
        CUS_USER: this.authService.userId,
        CUS_ADDRESS: null,
        CUS_UNLOCO: null,
        CUS_TAX: null,
        CUS_STATUS: 0,
        CUS_GROUP_ID: null
      }
    }

    this.closeDialog(customer);
  }

  areInputsValid(): boolean {
    const isCodeInvalid = this.customerForm.get('code').invalid;
    const isNameInvalid = this.customerForm.get('name').invalid;
    const isAddressInvalid = this.customerForm.get('address').invalid;
    const isStateInvalid = this.customerForm.get('state').invalid;
    const isUnlocoInvalid = this.customerForm.get('unloco').invalid;
    const isTaxInvalid = this.customerForm.get('tax').invalid;
    return isCodeInvalid || isNameInvalid || isAddressInvalid || isStateInvalid || isUnlocoInvalid || isTaxInvalid;
  }
}
