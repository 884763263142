import { IJoinCustomerGroup } from '../../../../models/join-customer-group.model';
import { ICustomer } from 'src/app/models/customer.model';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { CustomerGroupService } from 'src/app/services/customer-group.service';

export interface ICustomerGroupViewDialog {
  title?: string;
  customerGroup?: ICustomerGroup;
}

@Component({
  selector: 'dhl-customer-groups-view-dialog',
  templateUrl: './customer-groups-view-dialog.component.html',
  styleUrls: ['./customer-groups-view-dialog.component.scss']
})
export class CustomerGroupsViewDialogComponent implements OnInit {
  customerTableDataSource = new MatTableDataSource<ICustomer>();
  hasData: boolean = true;
  customerTableDisplayedColumns: Array<string> = ['CUS_CODE','CUS_NAME'];
  filterValue: string = '';

  @ViewChild(MatTable) customerGroupTable: MatTable<ICustomerGroup>;
  @ViewChild('customerSort') customerSort: MatSort;
  @ViewChild('customerPaginator') customerPaginator: MatPaginator;

  constructor(private customerGroupService: CustomerGroupService, @Inject(MAT_DIALOG_DATA) public data: ICustomerGroupViewDialog, private toast: HotToastService, private dialogRef: MatDialogRef<CustomerGroupsViewDialogComponent>) { }

  ngOnInit() {
    this.getCustomers();
  }

  getCustomers() {
    let joinCustomerGroup: IJoinCustomerGroup = {
      CUS_GROUP_ID: this.data.customerGroup.CUS_GRP_ID,
      CUS_ID: 0
    }

    this.customerGroupService.getAllCustomers(joinCustomerGroup).subscribe((customer: Array<ICustomer>) => {
      this.customerTableDataSource.data = customer;
      this.customerTableDataSource.sort = this.customerSort;
      this.customerTableDataSource.paginator = this.customerPaginator;
    }, () => {
      if (this.customerTableDataSource.data.length == 0) this.hasData = false;
    });
  }

  closeDialog() {
    this.dialogRef.close({event:'Close'});
  }

  filterCustomerTable(filterValue: string) {
    this.customerTableDataSource.filter = filterValue;
  }
}
