<h1 mat-dialog-title *ngIf="data.title">Container Partial ({{data.container.CTN_NUMBER}})</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Gate Out</mat-label>
        <input matInput type="text" name="ctnStart" formControlName="ctnStart" [matDatepicker]="pickerStart" disabled>
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ isTank ? 'Loading' : 'Gate In' }}</mat-label>
        <input matInput type="text" name="ctnFinal" formControlName="ctnFinal" [matDatepicker]="pickerFinal" [min]="minFinal" [max]="maxFinal">
        <mat-datepicker-toggle matSuffix [for]="pickerFinal"></mat-datepicker-toggle>
        <mat-datepicker #pickerFinal></mat-datepicker>
        <mat-error *ngIf="form.get('ctnFinal').hasError('required')">You must enter a {{ isTank ? 'loading' : 'gate in'}} date</mat-error>
        <mat-error *ngIf="form.get('ctnFinal').hasError('freetimeInvalid')">Not possible to generate parcial with this date</mat-error>
      </mat-form-field>      
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" [disabled]="form.invalid">Save</button>
    </div>    
  </form>
</div>