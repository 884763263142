import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';
import { ICarrier } from 'src/app/models/parameter.model';
import { IContainer, IMasterbill, IShipment } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'dhl-masterbill-dialog',
  templateUrl: './masterbill-dialog.component.html',
  styleUrls: ['./masterbill-dialog.component.scss']
})
export class MasterbillDialogComponent implements OnInit {
  masterbillForm: UntypedFormGroup;

  carriers: Array<ICarrier>;
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  fieldsLocked = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MasterbillDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<IMasterbill>,
    private parameterService: ParameterService,
    private authService: AuthService,
    private shipmentService: ShipmentService
  ) { }

  ngOnInit(): void {
    this.parameterService.getCarrier().subscribe((carriers) => {
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });

    this.masterbillForm = this.formBuilder.group({
      number: new UntypedFormControl(this.data.element?.MBL_NUMBER, [Validators.required]),
      consolId: new UntypedFormControl(this.data.element?.MBL_CONSOL_ID, [Validators.required]),
      consolType: new UntypedFormControl(this.data.element?.MBL_CONSOL_TYPE),
      vesselArrivalDate: new UntypedFormControl(this.data.element?.MBL_VES_ARR_DATE),
      vesselDepartureDate: new UntypedFormControl(this.data.element?.MBL_VES_DEP_DATE),
      vesselName: new UntypedFormControl(this.data.element?.MBL_VES_NAME),
      voyageFlightNumber: new UntypedFormControl(this.data.element?.MBL_VOY_FLT_NUMBER),
      carrier: new UntypedFormControl(this.data.element?.MBL_CAR),
      carrierFilter: new UntypedFormControl('')
    });

    this.masterbillForm.get('carrierFilter').valueChanges.subscribe((value) => {
      this.filterCarriers(value);
    });

    this.getContainers();
  }

  closeDialog(masterbill: IMasterbill): void {
    this.dialogRef.close(masterbill);
  }

  filterCarriers(value: string): void {
    this.filteredCarriers.next(this.carriers.filter((carrier) => {
      return carrier.RELATIPF_APCPTK.toLowerCase().includes(value.toLowerCase()) || carrier.RELATIPF_APA3CD.toLowerCase().includes(value.toLowerCase());
    }));
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.masterbillForm.value;
    let newMasterbill: IMasterbill;

    if (this.data.mode === 'add') {
      newMasterbill = {
        MBL_ID: null,
        MBL_NUMBER: formValue.number,
        MBL_CONSOL_ID: formValue.consolId,
        MBL_CONSOL_TYPE: formValue.consolType,
        MBL_VES_ARR_DATE: formValue.vesselArrivalDate,
        MBL_VES_DEP_DATE: formValue.vesselDepartureDate,
        MBL_VES_NAME: formValue.vesselName,
        MBL_VOY_FLT_NUMBER: formValue.voyageFlightNumber,
        MBL_CAR: formValue.carrier,
        MBL_STATUS: 1,
        MBL_USER: this.authService.userId
      }
    } else if (this.data.mode === 'edit') {
      newMasterbill = {
        MBL_ID: this.data.element.MBL_ID,
        MBL_NUMBER: formValue.number,
        MBL_CONSOL_ID: formValue.consolId,
        MBL_CONSOL_TYPE: formValue.consolType,
        MBL_VES_ARR_DATE: formValue.vesselArrivalDate,
        MBL_VES_DEP_DATE: formValue.vesselDepartureDate,
        MBL_VES_NAME: formValue.vesselName,
        MBL_VOY_FLT_NUMBER: formValue.voyageFlightNumber,
        MBL_CAR: formValue.carrier,
        MBL_STATUS: 1,
        MBL_USER: this.authService.userId
      }
    } else if (this.data.mode === 'delete') {
      newMasterbill = {
        MBL_ID: this.data.element.MBL_ID,
        MBL_NUMBER: null,
        MBL_CONSOL_ID: null,
        MBL_CONSOL_TYPE: null,
        MBL_VES_ARR_DATE: null,
        MBL_VES_DEP_DATE: null,
        MBL_VES_NAME: null,
        MBL_VOY_FLT_NUMBER: null,
        MBL_CAR: null,
        MBL_STATUS: 0,
        MBL_USER: this.authService.userId
      }
    }

    this.closeDialog(newMasterbill);
  }

  getContainers(): void {
    let container: IContainer = {
      SHIP_ID: this.data.element.SHIP_ID
    };

    this.shipmentService.getContainer(container).subscribe((ctn) => {
      this.lockFields(ctn);
    });
  }

  lockFields(containers: IContainer[]) {
    containers.forEach(ctn => {
      if(ctn.CTN_PROCESS_STATUS == 5) {
        this.fieldsLocked = true;
      }
    });
  }
}
