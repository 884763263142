import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { IGetLog, ILog } from 'src/app/models/log.model';
import { LogService } from 'src/app/services/log.service';
import { isMoment } from 'moment';

@Component({
  selector: 'dhl-logs-filter-dialog',
  templateUrl: './logs-filter-dialog.component.html',
  styleUrls: ['./logs-filter-dialog.component.scss']
})
export class LogsFilterDialogComponent implements OnInit {
  filterForm: UntypedFormGroup;
  users: Array<ILog>;
  filteredUsers: ReplaySubject<Array<ILog>> = new ReplaySubject<Array<ILog>>(1);
  groups: Array<ILog>;
  filteredGroups: ReplaySubject<Array<ILog>> = new ReplaySubject<Array<ILog>>(1);

  constructor(public dialogRef: MatDialogRef<LogsFilterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IGetLog, private formBuilder: UntypedFormBuilder, private logService: LogService) { }

  ngOnInit(): void {
    this.getUsers();
    this.getGroups();

    this.filterForm = this.formBuilder.group({
      user: new UntypedFormControl(this.data?.LOG_USER),
      userFilter: new UntypedFormControl(''),
      group: new UntypedFormControl(this.data?.LOG_GROUP),
      groupFilter: new UntypedFormControl(''),
      startDate: new UntypedFormControl(this.data.LOG_START_DATETIME ? new Date(this.data?.LOG_START_DATETIME) : ''),
      finalDate: new UntypedFormControl(this.data.LOG_FINAL_DATETIME ? new Date(this.data?.LOG_FINAL_DATETIME) : '')
    });

    this.filterForm.get('userFilter').valueChanges.subscribe(() => {
      this.filterUsers();
    });

    this.filterForm.get('groupFilter').valueChanges.subscribe(() => {
      this.filterGroups();
    });
  }

  getUsers(): void {
    this.logService.getLogUsers().subscribe((users: Array<ILog>) => {
      this.users = users;
      this.filteredUsers.next(this.users);
    });
  }

  getGroups(): void {
    this.logService.getLogGroups().subscribe((groups: Array<ILog>) => {
      this.groups = groups;
      this.filteredGroups.next(this.groups);
    });
  }

  filterUsers(): void {
    const filterValue = this.filterForm.get('userFilter').value.toLowerCase();
    this.filteredUsers.next(this.users.filter((user) => user.LOG_USER.toLowerCase().includes(filterValue)));
  }

  filterGroups(): void {
    const filterValue = this.filterForm.get('groupFilter').value.toLowerCase();
    this.filteredGroups.next(this.groups.filter((group) => group.LOG_GROUP.toLowerCase().includes(filterValue)));
  }

  closeDialog(filters: IGetLog) {
    this.dialogRef.close(filters);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.filterForm.value;

    let filters: IGetLog = {
      LOG_USER: formValue.user.trim(),
      LOG_GROUP: formValue.group.trim(),
      LOG_START_DATETIME: isMoment(formValue.startDate) ? new Date(formValue.startDate).toLocaleDateString('en-US') : '',
      LOG_FINAL_DATETIME: isMoment(formValue.finalDate) ? new Date(formValue.finalDate).toLocaleDateString('en-US') : ''
    }

    this.closeDialog(filters);
  }

  resetFilters(): void {
    this.filterForm.setValue({
      user: '',
      userFilter: '',
      group: '',
      groupFilter: '',
      startDate: '',
      finalDate: ''
    });
  }
}
