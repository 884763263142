import { AuthService } from 'src/app/services/auth.service';
import { IPaymentOrderExport } from './../../models/payable.model';
import { IPoExtraDialog, PoExtraDialogComponent } from './payment-order-dialogs/po-extra-dialog/po-extra-dialog.component';
import { ParameterService } from 'src/app/services/parameter.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { InsertSatDialogComponent, IInsertSatDialog } from './payment-order-dialogs/insert-sat-dialog/insert-sat-dialog.component';
import { FilterDialogComponent } from './payment-order-dialogs/filter-dialog/filter-dialog.component';
import { HotToastService } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { FilterService } from 'src/app/services/filter.service';
import { MatTableFilter } from 'mat-table-filter';
import { ICarrier } from 'src/app/models/parameter.model';
import { PayableService } from 'src/app/services/payable.service';
import { IPaymentOrder } from 'src/app/models/payable.model';
import { InvoicesViewDialogComponent } from './payment-order-dialogs/invoices-view-dialog/invoices-view-dialog.component';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeleteDialogComponent } from './payment-order-dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'dhl-payment-order',
  templateUrl: './payment-order.component.html',
  styleUrls: ['./payment-order.component.scss']
})

export class PaymentOrderComponent implements OnInit {
  pageKey = 'paymentOrder';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IPaymentOrder>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IPaymentOrder = {
    PO_ID: null,
    PO_TYPE: null,
    PO_CAR: null,
    PO_SAT: null,
    PO_PRC_ST: null,
    PO_PAY_START_DT: null,
    PO_PAY_FINAL_DT: null,
    PO_INC_START_DT: null,
    PO_INC_FINAL_DT: null
  };
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;

  paymentOrderExportTableDataSource = new MatTableDataSource<IPaymentOrderExport>();
  paymentOrderTableDataSource = new MatTableDataSource<IPaymentOrder>();
  filteredPaymentOrderTableDataSource = new MatTableDataSource<IPaymentOrder>();
  paymentOrderTableDisplayedColumns: Array<string> = [
    'select',
    'PO_ID',
    'PO_TYPE',
    'PO_CAR',
    'PO_BULK',
    'PO_VAL_USD',
    'PO_VAL_DIS',
    'PO_CUR_RATE',
    'PO_VAL_BRL',
    'PO_SAT',
    'PO_PAY_DT',
    'PO_PRC_ST',
    'invoices',
    'actions'
  ];

  paymentOrderStatus: Array<string> = [
    "Waiting for SAT",
    "Closed"
  ];

  paymentOrderType: Array<string> = [
    "Original",
    "Extra"
  ];

  carrierList: Array<ICarrier> = []

  @ViewChild(MatTable) paymentOrderTable: MatTable<IPaymentOrder>;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private paymentOrderService: PayableService,
    private carrierService: ParameterService,
    private filterService: FilterService,
    private authService: AuthService
  ) {
    this.titleService.setTitle('DHL | Payables (Payment Order)');
   }

  ngOnInit(): void {
    this.carrierService.getCarrier().subscribe(carriers => this.carrierList = carriers);

    const filter = this.filterService.getFilter('paymentOrder');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getPaymentOrder(this.filterEntity);
    });

    this.getPaymentOrder(this.filterEntity);
  }

  getPaymentOrder(filter: IPaymentOrder): void{
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredPaymentOrderTableDataSource.data.length === 0) this.hideLoader = false;
    this.paymentOrderService.getPaymentOrder({
      ...filter,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((paymentOrders) => {
      this.hideLoader = true;
      this.paymentOrderTableDataSource.data = paymentOrders;
      this.filteredPaymentOrderTableDataSource.data = paymentOrders;
      this.length = paymentOrders[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredPaymentOrderTableDataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? (
      this.selection.clear()
    ) : (
      this.filteredPaymentOrderTableDataSource.filteredData.forEach((row) => this.selection.select(row))
    );
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPaymentOrder(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getPaymentOrder(this.filterEntity);
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.filterEntity,
        carrierList: this.carrierList
      }
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity = filters;
      this.pageNumber = 0;      
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;
      
      this.filterService.setFilter('paymentOrder', this.filterEntity);
      
      this.getPaymentOrder(this.filterEntity);
    });
  }

  openInsertSatDialog(data: IInsertSatDialog): void{
    const dialog = this.dialog.open(InsertSatDialogComponent, { data });

    dialog.afterClosed().subscribe((paymentOrder: IPaymentOrder) => {
      if(!paymentOrder){
        return;
      }

      this.paymentOrderService.insertSat(paymentOrder).subscribe((response) => {
        this.toast.success(String(response));
        this.getPaymentOrder(this.filterEntity);
      }, (error) =>{
        this.toast.error(error.error.Message);
      });

    });
  }

  openPaymentOrderExtraDialog(data: IPoExtraDialog){
    const dialog = this.dialog.open(PoExtraDialogComponent, { data });

    dialog.afterClosed().subscribe((paymentOrder: IPaymentOrder) => {
      if(!paymentOrder){
        return;
      }

      this.paymentOrderService.generateExtra(paymentOrder).subscribe((response) => {
        this.toast.success(String(response));
        this.getPaymentOrder(this.filterEntity);
      }, (error) =>{
        this.toast.error(error.error.Message);
      });

    });
  }

  openInvoicesViewDialog(data: IPaymentOrder): void{
    this.dialog.open(InvoicesViewDialogComponent, {
      data: data
    });
  }

  openDeletePoDialog(po: IPaymentOrder | Array<IPaymentOrder>): void{
    const dialog = this.dialog.open(DeleteDialogComponent);
    
    dialog.afterClosed().subscribe((willDelete: boolean)=>{
      if(!willDelete) return;

      let paymentOrders: Array<IPaymentOrder> = [];

      if(po instanceof Array){
        paymentOrders = po.map(item => item = {...item, PO_USER: this.authService.userId});
      }else{
        paymentOrders.push({...po, PO_USER: this.authService.userId});
      }

      this.paymentOrderService.deletePo(paymentOrders).subscribe((response)=>{
        this.toast.success(String(response));
        this.getPaymentOrder(this.filterEntity);
      }, (error) =>{
        this.toast.error(error.error.Message);
      });
    });
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  export(paymentOrders: Array<IPaymentOrder>): void {
    const headers = {
      PO_ID: "DDM PAYMENT ORDER",
      PO_TYPE: "TYPE",
      PB_CAR: "CARRIER",
      PO_BULK_TEXT: "IS BULK",
      PRC_SER: "SERVICE",
      PRC_HBL: "HOUSE BILL",
      PRC_CUS: "CUSTOMER",
      PRC_SHIP: "SHIPMENT",
      PB_INV_NUM: "INVOICE NUMBER",
      PB_EMIS_DT_TEXT: "EMISSION DATE",
      PB_EXP_DT_TEXT: "EXPIRATION DATE",
      PB_MBL: "MASTER BILL",
      PB_BK: "BOOKING",
      PB_CTN: "CONTAINER",
      PB_CTN_TYPE: "TYPE",
      PB_CONT: "CONTRACT",
      PB_POL: "ORIGIN PORT",
      PB_POD: "DESTINATION PORT",
      PB_TL_DAYS: "TOTAL DAYS",
      PB_FRT: "FREETIME",
      PB_CH_DAYS: "CHARGE DAYS",
      PB_ST_DT_TEXT: "START DATE",
      PB_END_DT_TEXT: "END DATE",
      PB_VAL_USD: "AMOUNT USD",
      PB_DIS_TEXT: "DISCOUNT USD",
      PB_VAL_DIS: "TOTAL WITH DISCOUNT USD",
      PB_CUR_RATE: "CURRENCY RATE",
      PB_VAL_BRL: "TOTAL BRL",
      PB_CH_CODE: "CHARGE CODE",
      PO_SAT: "SAT",
      PO_PAY_DT_TEXT: "PAYMENT DATE"
    }

    const dataToExport: Array<any> = [headers];

    const po: IPaymentOrder = {
      PO_ID_LIST: paymentOrders.map((item)=> item.PO_ID).join(',')
    }

    const ref = this.toast.loading('Exporting...',{autoClose: false});
    this.paymentOrderService.exportPo(po).subscribe((dataExport)=>{
      this.paymentOrderExportTableDataSource.data = dataExport;

      const data: Array<IPaymentOrderExport> = this.prepareDataToExport([...this.paymentOrderExportTableDataSource.data]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `paymentOrder_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        
        columnWidths: []
      });

      ref.close();
    });
    
  }

  prepareDataToExport(data: Array<IPaymentOrderExport>): Array<IPaymentOrderExport> {
    const dataToExport: Array<IPaymentOrderExport> = [];

    const datesText = {
      PB_EMIS_DT_TEXT: '',
      PB_EXP_DT_TEXT: '',
      PB_ST_DT_TEXT: '',
      PB_END_DT_TEXT: '',
      PO_PAY_DT_TEXT: '',
      PB_DIS_TEXT: '',
      PO_BULK_TEXT: '',
    }

    data.forEach((paymentOrder) => {
      const newData = {...paymentOrder, ...datesText};
      
      newData.PB_EMIS_DT_TEXT = newData.PB_EMIS_DT && moment(newData.PB_EMIS_DT).format('DD/MM/YYYY');
      newData.PB_EXP_DT_TEXT = newData.PB_EXP_DT && moment(newData.PB_EXP_DT).format('DD/MM/YYYY');
      newData.PB_ST_DT_TEXT = newData.PB_ST_DT && moment(newData.PB_ST_DT).format('DD/MM/YYYY');
      newData.PB_END_DT_TEXT = newData.PB_END_DT && moment(newData.PB_END_DT).format('DD/MM/YYYY');
      newData.PO_PAY_DT_TEXT = newData.PO_PAY_DT && moment(newData.PO_PAY_DT).format('DD/MM/YYYY');
      newData.PB_DIS_TEXT = newData.PB_DIS ? (newData.PB_DIS_TYPE === 0) ? `${newData.PB_DIS}%` : `$${newData.PB_DIS}` : '';
      newData.PO_BULK_TEXT = Number(newData.PO_BULK) === 1 ? 'Yes' : 'No';

      delete newData.PB_EMIS_DT;
      delete newData.PB_EXP_DT;
      delete newData.PB_ST_DT;
      delete newData.PB_END_DT;
      delete newData.PO_PAY_DT;
      delete newData.PB_DIS;
      delete newData.PB_DIS_TYPE;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

}