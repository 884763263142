<form [formGroup]="cusUniqueTermForm" (submit)="onFormSubmit($event)">
  <h2>Customer Unique Term</h2>
  <div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput name="uniName" formControlName="uniName">
        <mat-error *ngIf="cusUniqueTermForm.get('uniName').invalid">{{getErrorMessage('uniName')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer">
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_CODE}} - {{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="cusUniqueTermForm.get('customer').setValue(''); cusUniqueTermForm.get('customerFilter').setValue('');" *ngIf="!!cusUniqueTermForm.get('customer').value" [disabled]="isFormDisabled">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-error *ngIf="cusUniqueTermForm.get('customer').invalid">{{getErrorMessage('customer')}}</mat-error>
      </mat-form-field>

    </div>

    <div class="form-group">
      <mat-form-field>
        <mat-label>Sale Receivers</mat-label>
        <mat-chip-list #salesReceiversList formControlName="uniSlReceivers">
          <mat-chip *ngFor="let mailSales of uniSlReceiversList" (removed)="removeSalesMails(mailSales)">
            {{mailSales}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input name="uniSlReceivers" formControlName="uniSlReceivers"
                  [matChipInputFor]="salesReceiversList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addSalesMails($event)">
        </mat-chip-list>
        <mat-error *ngIf="cusUniqueTermForm.get('uniSlReceivers').invalid">{{getErrorMessage('uniSlReceivers')}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Operation Receivers</mat-label>
        <mat-chip-list #operationsReceiversList formControlName="uniOperReceivers">
          <mat-chip *ngFor="let mailOperations of uniOperReceiversList" (removed)="removeOperationsMails(mailOperations)">
            {{mailOperations}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input name="uniOperReceivers" formControlName="uniOperReceivers"
                  [matChipInputFor]="operationsReceiversList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addOperationsMails($event)">
        </mat-chip-list>
        <mat-error *ngIf="cusUniqueTermForm.get('uniOperReceivers').invalid">{{getErrorMessage('uniOperReceivers')}}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field>
        <mat-label>Received Date</mat-label>
        <input matInput name="uniReceivedDt" formControlName="uniReceivedDt" [matDatepicker]="uniReceivedDatePicker">
        <mat-datepicker-toggle matSuffix [for]="uniReceivedDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #uniReceivedDatePicker></mat-datepicker>
        <mat-error *ngIf="cusUniqueTermForm.get('uniReceivedDt').invalid">{{getErrorMessage('uniReceivedDt')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput name="uniStartDt" formControlName="uniStartDt" [matDatepicker]="uniStartDatePicker">
        <mat-datepicker-toggle matSuffix [for]="uniStartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #uniStartDatePicker></mat-datepicker>
        <mat-error *ngIf="cusUniqueTermForm.get('uniStartDt').invalid">{{getErrorMessage('uniStartDt')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput name="uniEndDt" formControlName="uniEndDt" [matDatepicker]="uniEndDatePicker" [min]="minEndDate">
        <mat-datepicker-toggle matSuffix [for]="uniEndDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #uniEndDatePicker></mat-datepicker>
        <mat-error *ngIf="cusUniqueTermForm.get('uniEndDt').invalid">{{getErrorMessage('uniEndDt')}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Branch</mat-label>
        <input matInput name="uniBranch" formControlName="uniBranch">
        <mat-error *ngIf="cusUniqueTermForm.get('uniBranch').invalid">{{getErrorMessage('uniBranch')}}</mat-error>
      </mat-form-field>

    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Comment</mat-label>
        <textarea matInput name="uniComment" formControlName="uniComment"></textarea>
        <mat-error *ngIf="cusUniqueTermForm.get('uniComment').invalid">{{getErrorMessage('uniComment')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <dhl-attachment
        [attachments]="attachments"
        [showAttachmentsTab]="showAttachments"
        [showUploadTab]="!isFormDisabled"
        (upload)="onUpload($event)"
        [offSideAttachments]="true"
        [showRenameFile]="true"
      ></dhl-attachment>
    </div>

  </div>

  <div class="actions-group">
    <a mat-button color="accent" [routerLink]="['/unique-terms/customers']">Cancel</a>
    <button mat-flat-button type="submit" color="accent" [disabled]="cusUniqueTermForm.invalid || hasFile == false || isFormDisabled">Save</button>
  </div>
</form>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
<button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar" (click)="openSidebar()" *ngIf="this.term">
  <mat-icon>menu_open</mat-icon>
</button>