import { bool } from "aws-sdk/clients/signer";

export interface IPayable {
  PB_ID?: number;
  PB_PRC_ID?: number;
  PRC_SER?: string;
  PRC_HBL?: string;
  SHIP_DPT_CODE?: string;
  PRC_CUS?: string;
  PRC_SHIP?: string;
  PRC_CTN?: string;
  PRC_CTN_TYPE?: string;
  PRC_INV_FRT?: number;
  PRC_INV_CH_DAYS?: number;
  PRC_INV_VAL?: number;
  PRC_INV_SITUATION?: string;
  PRC_PAY_FRT?: number;
  PRC_PAY_CH_DAYS?: number;
  PRC_PAY_VAL?: number;
  PB_CAR?: string;
  PB_INV_NUM?: string;
  PB_EMIS_DT?: Date;
  PB_EXP_DT?: Date;
  PB_MBL?: string;
  PB_BK?: string;
  PB_CTN?: string;
  PB_CTN_TYPE?: string;
  PB_CONT?: string;
  PB_POL?: string;
  PB_POD?: string;
  PB_TL_DAYS?: number;
  PB_FRT?: number;
  PB_CH_DAYS?: number;
  PB_ST_DT?: Date;
  PB_END_DT?: Date;
  PB_VAL_USD?: number;
  PB_DIS?: number;
  PB_DIS_TYPE?: number;
  PB_VAL_DIS?: number;
  PB_CUR_RATE?: number;
  PB_VAL_BRL?: number;
  PB_PRC_ST?: number;
  PB_PRC_SUB_ST?: number;
  PB_VER_ST?: string;
  PB_CMT_USER_NAME?: string;
  PB_CMT_INC_DT?: Date;
  PB_CMT_ST?: number;
  PB_CMT_SUB_ST?: number;
  PB_CMT?: string;
  PB_STATUS?: string;
  PB_USER?: string;
  PB_INC_DT?: Date;
  PB_GEN_PO_INC_DT?: Date;
  PB_INV_LIST?:string;
  PB_CH_CODE?: string;
  PB_SAT?: string;
  PB_PAY_DT?: Date;
  PB_PRC_ST_LIST?: string;
  PB_PRC_SUB_ST_LIST?: string;
  PB_VER_ST_LIST?: string;
  PB_CMT_ATCH?: number;  
  PB_INC_START_DT?: Date;
  PB_INC_FINAL_DT?: Date;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface IPayableComment {
  PB_CMT_ID?: number;
  PB_ID?: number;
  PB_CMT_ST?: number;
  PB_CMT_SUB_ST?: number;
  PB_CMT?: string;
  PB_CMT_USER_NAME?: string;
  PB_CMT_USER?: string;
  PB_CMT_INC_DT?: Date;
}

export interface IPayableImport {
  PB_CAR: string;
  PB_INV_NUM: string;
  PB_EMIS_DT: Date;
  PB_EXP_DT: Date;
  PB_MBL: string;
  PB_BK: string;
  PB_CTN: string;
  PB_CTN_TYPE: string;
  PB_CONT: string;
  PB_POL: string;
  PB_POD: string;
  PB_TL_DAYS: number;
  PB_FRT: number;
  PB_CH_DAYS: number;
  PB_ST_DT: Date;
  PB_END_DT: Date;
  PB_VAL_USD: number;
  PB_PRC_ST: string;
  PB_PRC_SUB_ST: string;
  PB_USER: string;
  IS_VALID: number;
}

export interface IPayableDuplicate{
  PB_INV_NUM?:string;
  PB_CTN?:string;
}

export interface IPaymentOrder {
  PO_ID?: number;
  PO_CAR?: string;
  PO_TYPE?: number;
  PO_VAL_USD?: number;
  PO_VAL_DIS?: number;
  PO_CUR_RATE?: number;
  PO_VAL_BRL?: number;
  PO_SAT?: string;
  PO_PAY_DT?: Date;
  PO_PRC_ST?: number;
  PO_STATUS?: number;
  PO_BULK?: number;
  PO_USER?: string;
  PO_INC_DT?: Date;
  PB_LIST?: Array<number>;
  PO_CTN?: string;
  PO_ID_LIST?: string;
  PO_PAY_START_DT?: Date;
  PO_PAY_FINAL_DT?: Date;
  PO_INC_START_DT?: Date;
  PO_INC_FINAL_DT?: Date;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface IPaymentOrderExport{  
  PO_ID?: string,
  PO_TYPE?: string,
  PB_CAR?: string,	
  PO_BULK?: number,	
  PRC_SER?: string,
  PRC_HBL?: string,
  PRC_CUS?: string,
  PRC_SHIP?: string,
  PB_INV_NUM?: string,
  PB_EMIS_DT?: Date,
  PB_EXP_DT?: Date,
  PB_MBL?: string,
  PB_BK?: string,
  PB_CTN?: string,
  PB_CTN_TYPE?: string,
  PB_CONT?: string,
  PB_POL?: string,
  PB_POD?: string,
  PB_TL_DAYS?: number,
  PB_FRT?: number,
  PB_CH_DAYS?: number,
  PB_ST_DT?: Date,
  PB_END_DT?: Date,
  PB_VAL_USD?: number,
  PB_DIS?: number,
  PB_DIS_TYPE?: number,
  PB_VAL_DIS?: number,
  PB_CUR_RATE?: number,
  PB_VAL_BRL?: number,
  PB_CH_CODE?: string,
  PO_SAT?: string,
  PO_PAY_DT?: Date
}