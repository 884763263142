import { ComponentsModule } from 'src/app/components/components.module';
import { CustomDateDialogComponent } from './custom-date-dialog/custom-date-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { DateDialogComponent } from './date-dialog/date-dialog.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OnholdDialogComponent } from './onhold-dialog/onhold-dialog.component';
import { DetentionExcelImportDialogComponent } from './detention-excel-import-dialog/detention-excel-import-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PrtlCtnDetentionDialogComponent } from './prtl-ctn-detention-dialog/prtl-ctn-detention-dialog.component';
import { NewPrtlDetentionDialogComponent } from './new-prtl-detention-dialog/new-prtl-detention-dialog.component';

@NgModule({
  declarations: [
    DateDialogComponent,
    FilterDialogComponent,
    CustomDateDialogComponent,
    OnholdDialogComponent,
    DetentionExcelImportDialogComponent,
    NewPrtlDetentionDialogComponent,
    PrtlCtnDetentionDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxMatSelectSearchModule,
    ComponentsModule,
    NgxDropzoneModule
  ]
})
export class DetentionManualDialogsModule { }
