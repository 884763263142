import { Observable } from 'rxjs';
import { IShipment, IException, IMasterbill, IContainer, IShipmentList } from '../models/shipment.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ShipmentIntegration } from '../models/shipment-integration.model';

@Injectable({
  providedIn: 'root'
})
export abstract class ShipmentService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };

  //-----------------------Shipment-----------------------
  getShipment(shipment: IShipment): Observable<Array<IShipment>> {
    return this.httpClient.get<Array<IShipment>>(`${this.apiURL}/api/shipments2?filter=${JSON.stringify(shipment)}`, this.httpOptions);
  }

  getSingleShipment(shipment: IShipment): Observable<IShipment> {
    return this.httpClient.get<IShipment>(`${this.apiURL}/api/shipment2?filter=${JSON.stringify(shipment)}`, this.httpOptions);
  }

  postShipment(shipment: IShipment): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/shipment2?filter=${JSON.stringify(shipment)}`, null, this.httpOptions);
  }

  putShipment(shipment: IShipment): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2?filter=${JSON.stringify(shipment)}`, null, this.httpOptions);
  }

  deleteShipments(shipments: IShipmentList): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/deletelist?filter=${JSON.stringify(shipments)}`, null, this.httpOptions);
  }

  //-----------------------Exception-----------------------
  getException(exception: IException): Observable<Array<IException>> {
    return this.httpClient.get<Array<IException>>(`${this.apiURL}/api/shipment2/exception?filter=${JSON.stringify(exception)}`, this.httpOptions);
  }

  postException(exception: IException): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/shipment2/exception?filter=${JSON.stringify(exception)}`, null, this.httpOptions);
  }

  putException(exception: IException): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/exception?filter=${JSON.stringify(exception)}`, null, this.httpOptions);
  }

  //-----------------------Masterbill-----------------------
  getMasterbill(masterbill: IMasterbill): Observable<Array<IMasterbill>> {
    return this.httpClient.get<Array<IMasterbill>>(`${this.apiURL}/api/shipment2/masterbill?filter=${JSON.stringify(masterbill)}`, this.httpOptions);
  }

  postMasterbill(masterbill: IMasterbill): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/shipment2/masterbill?filter=${JSON.stringify(masterbill)}`, null, this.httpOptions);
  }

  putMasterbill(masterbill: IMasterbill): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/masterbill?filter=${JSON.stringify(masterbill)}`, null, this.httpOptions);
  }

  //-----------------------Container-----------------------
  getContainer(container: IContainer): Observable<Array<IContainer>> {
    return this.httpClient.get<Array<IContainer>>(`${this.apiURL}/api/shipment2/container?filter=${JSON.stringify(container)}`, this.httpOptions);
  }

  getContainers(container: IContainer): Observable<Array<IContainer>> {
    return this.httpClient.get<Array<IContainer>>(`${this.apiURL}/api/shipment2/containers?filter=${JSON.stringify(container)}`, this.httpOptions);
  }

  getAutomaticContainers(container: IContainer): Observable<Array<IContainer>> {
    return this.httpClient.get<Array<IContainer>>(`${this.apiURL}/api/shipment2/automatic-containers?filter=${JSON.stringify(container)}`, this.httpOptions);
  }

  postContainer(container: IContainer): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/shipment2/container?filter=${JSON.stringify(container)}`, null, this.httpOptions);
  }

  putContainer(container: IContainer): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/container?filter=${JSON.stringify(container)}`, null, this.httpOptions);
  }

  approveAll(containers: Array<IContainer>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/containers/approve`, containers, this.httpOptions);
  }

  putEndReferenceContainer(container: IContainer): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/container/endreference?filter=${JSON.stringify(container)}`, null, this.httpOptions);
  }

  putDateContainer(container: IContainer): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/container/date?filter=${JSON.stringify(container)}`, null, this.httpOptions);
  }

  putOnHold(containers: Array<IContainer>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/container/onhold`, containers, this.httpOptions);
  }

  reprocessContainer(containers: Array<IContainer>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/shipment2/container/reprocess`, containers, this.httpOptions);
  }

  //-----------------------Import-----------------------
  postShipmentIntegration(shipments: ShipmentIntegration[]): Observable<Object>{
    return this.httpClient.post(`${this.apiURL}/api/shipment/integration`, shipments, this.httpOptions);
  }

}
