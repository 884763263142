<h1 mat-dialog-title>{{data.mode}} Exception</h1>
<div mat-dialog-content>
  <form [formGroup]="exceptionsForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput name="description" formControlName="description">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Event Code</mat-label>
        <input matInput name="code" formControlName="code">
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Staff Code</mat-label>
        <input matInput name="staffCode" formControlName="staffCode">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Timestamp</mat-label>
        <input matInput name="timeStamp" formControlName="timeStamp" [matDatepicker]="timeStampPicker">
        <mat-datepicker-toggle matSuffix [for]="timeStampPicker"></mat-datepicker-toggle>
        <mat-datepicker #timeStampPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode === 'delete'">Do you really wish to delete the exception {{data.element.EXC_ID}}?</p>
    <div mat-dialog-actions *ngIf="data.mode !== 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'add'">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode === 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent">Save</button>
    </div>
  </form>
</div>
