import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dhl-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comments: Array<string> = [];
  @Input() showCommentsTab: boolean = true;
  @Input() offSideComments: boolean = false;
  @Output() comment: EventEmitter<string> = new EventEmitter<string>();
  
  commentText: string = '';
  tabIndex: number = 0;

  constructor() { }

  ngOnInit() { }

  onComment(): void {
    this.comment.emit(this.commentText);
    this.commentText = '';
    this.tabIndex = 0;
  }

  onOffSideComments(): void {
    if (!this.offSideComments) return;
    this.comment.emit(this.commentText);
  }
}
