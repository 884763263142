<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-contente>
  <form [formGroup]="pageForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>
          Name
        </mat-label>
        <input matInput type="text" name="name" maxlength="100" formControlName="name" required>
        <mat-error *ngIf="pageForm.get('name').invalid">You must enter a name</mat-error>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Groups</mat-label>
        <mat-select name="pageGroups" formControlName="pageGroups" multiple>
          <mat-option>
            <ngx-mat-select-search formControlName="pageGroupFilter" placeholderLabel="Search"
              noEntryFoundLabel="No entries found">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let page of filteredPages | async" [value]="page.GRP_ID">
            {{page.GRP_NAME}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>
          URL
        </mat-label>
        <input matInput type="text" name="url" maxlength="50" formControlName="url" required>
        <mat-error *ngIf="pageForm.get('url').invalid">You must enter a URL</mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the page {{data.page.GRP_NAME}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'"
        [disabled]="areInputsValid()">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled"
        [disabled]="areInputsValid()">Ok</button>
    </div>
  </form>
</div>