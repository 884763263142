import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ICountry, IRegion } from 'src/app/models/parameter.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

export interface ICountryDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  country?: ICountry;
  regions?: Array<IRegion>;
}

@Component({
  selector: 'country-dialog',
  templateUrl: 'country-dialog.component.html',
  styleUrls: ['country-dialog.component.scss']
})
export class CountryDialogComponent implements OnInit {
  countryForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  regions: Array<IRegion>;
  filteredRegions: ReplaySubject<Array<IRegion>> = new ReplaySubject<Array<IRegion>>(1);
  
  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CountryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICountryDialog, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit(): void {
    this.countryForm = this.formBuilder.group({
      code: new UntypedFormControl(this.data.country?.COU_CODE, [Validators.required]),
      description: new UntypedFormControl(this.data.country?.COU_DESC, [Validators.required]),
      regions: new UntypedFormControl((this.data.country?.REG_LIST) ? (this.data.country?.REG_LIST.toString()).split(',').map(Number) : null),
      regionsFilter: new UntypedFormControl('')
    });

    if (this.data.mode == 'add' || this.data.mode == 'edit') {
      this.filteredRegions.next(this.data.regions);

      this.countryForm.get('regionsFilter').valueChanges.subscribe(() => {
        this.filterRegions();
      });
    }

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.countryForm.disable();
    }
  }

  filterRegions(): void {
    const filterValue = this.countryForm.get('regionsFilter').value.toLowerCase();
    this.filteredRegions.next(this.data.regions.filter((region) => region.REG_NAME.toLowerCase().includes(filterValue)));
  }

  closeDialog(country: ICountry): void {
    this.dialogRef.close(country);
  }

  enableForm(): void {
    this.isFormEnabled = true;
    this.countryForm.get('regions').enable();
    this.countryForm.get('description').enable();
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.countryForm.value;
    let newCountry: ICountry;

    if (this.data.mode == 'add') {
      newCountry = {
        COU_CODE: formValue.code,
        COU_DESC: formValue.description,
        COU_USER: this.authService.userId,
        REG_LIST: formValue.regions
      }
    } else if (this.data.mode == 'edit') {
      newCountry = {
        COU_ID: this.data.country.COU_ID,
        COU_CODE: formValue.code,
        COU_DESC: formValue.description,
        COU_STATUS: 1,
        COU_USER: this.authService.userId,
        REG_LIST: formValue.regions
      }
      console.log(newCountry)
    } else if (this.data.mode == 'delete') {
      newCountry = {
        COU_ID: this.data.country.COU_ID,
        COU_CODE: null,
        COU_DESC: null,
        COU_STATUS: 0,
        COU_USER: this.authService.userId
      }
    }

    this.closeDialog(newCountry);
  }

  getCodeErrorMessage(): string {
    if (this.countryForm.get('code').hasError('required')) {
      return 'You must enter a code';
    } else {
      return '';
    }
  }

  getDescriptionErrorMessage(): string {
    if (this.countryForm.get('description').hasError('required')) {
      return 'You must enter a description';
    } else {
      return '';
    }
  }
}