import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

export interface IShipmentsDeleteDialog {
  id: number;
}

@Component({
  selector: 'dhl-shipments-delete-dialog',
  templateUrl: './shipments-delete-dialog.component.html',
  styleUrls: ['./shipments-delete-dialog.component.scss']
})
export class ShipmentsDeleteDialogComponent implements OnInit {
  id: number;

  constructor(public dialogRef: MatDialogRef<ShipmentsDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IShipmentsDeleteDialog) {
    this.id = data.id;
  }

  ngOnInit() {
  }

  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}
