import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { IExportInvoiceContainers, IInvoice, IInvoicePdf } from 'src/app/models/invoice.model';
import { XlsxExporterService } from 'mat-table-exporter';
import { InvoiceService } from 'src/app/services/invoice.service';
import { HotToastService } from '@ngneat/hot-toast';
import { MatTableFilter } from 'mat-table-filter';
import { formatDate } from '@angular/common';
import { AwsService } from 'src/app/services/aws.service';
import { CommentService } from 'src/app/services/comment.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { IAttachment, IAttachmentRef } from 'src/app/models/attachment.model';
import { IComment, ICommentRef } from 'src/app/models/comment.model';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FormControl } from '@angular/forms';
import { ITimeline } from 'src/app/models/timeline.model';
import { TimelineService } from 'src/app/services/timeline.service';
import { ICustomerException, ICustomerNotification } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { IApplyDiscountDialog, ApplyDiscountDialogComponent } from '../invoices-dialogs/apply-discount-dialog/apply-discount-dialog.component';
import { IAttachmentDialog, AttachmentDialogComponent } from '../invoices-dialogs/attachment-dialog/attachment-dialog.component';
import { IChangeStatusDialog, ChangeStatusDialogComponent } from '../invoices-dialogs/change-status-dialog/change-status-dialog.component';
import { ICommentDialog, CommentDialogComponent } from '../invoices-dialogs/comment-dialog/comment-dialog.component';
import { CustomerExceptionInfoDialogComponent } from '../invoices-dialogs/customer-exception-info-dialog/customer-exception-info-dialog.component';
import { FilterDialogComponent } from '../invoices-dialogs/filter-dialog/filter-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

declare var html2pdf: () => any;

export interface IPdfToZip {
  pdf?: any;
  pdfName?: string;
}

@Component({
  selector: 'dhl-partial-invoices',
  templateUrl: './partial-invoices.component.html',
  styleUrls: ['./partial-invoices.component.scss']
})
export class PartialInvoicesComponent implements OnInit {
  pageKey = 'invoices';
  hideLoader: boolean;
  initialSelection: Array<IInvoice> = [];
  allowMultiSelect: boolean = true;
  selection = new SelectionModel<IInvoice>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filtersNumber: number = 0;
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filterEntity: IInvoice = {
    INVOICE_ID: null,
    INVOICE_TYPE: null,
    INVOICE_HBL: null,
    INVOICE_CUS: null,
    INVOICE_CAR: null,
    INVOICE_POL: null,
    INVOICE_POD: null,
    INVOICE_SITUATION_LIST: null,
    INVOICE_CONSOL_TYPE: null,
    INVOICE_INC: null,
    NOTIFICATION_SITUATION: null,
    INVOICE_CTN_NUMBER: null,
    INVOICE_ST_START_DT: null,
    INVOICE_ST_FINAL_DT: null,
    INVOICE_END_START_DT: null,
    INVOICE_END_FINAL_DT: null,
    INVOICE_INV_START_DT: null,
    INVOICE_INV_FINAL_DT: null,
    INVOICE_INC_START_DT: null,
    INVOICE_INC_FINAL_DT: null
  }

  invoicesExportDataSource = new MatTableDataSource<IInvoice>();
  invoicesTableDataSource = new MatTableDataSource<IInvoice>();
  filteredInvoicesTableDataSource = new MatTableDataSource<IInvoice>();
  invoicesTableDisplayedColumns: Array<string> = [
    'select',
    'INVOICE_ID',
    'PRTL_CTN_ORDER',
    'INVOICE_TYPE',
    'INVOICE_CTN_NUMBER',
    'INVOICE_SHIPMENT',
    'INVOICE_HBL',
    'SHIP_DPT_CODE',
    'INVOICE_CUS_NAME',
    'INVOICE_CAR_NAME',
    'INVOICE_CONSOL_TYPE',
    'INVOICE_INC',
    'INVOICE_POL',
    'INVOICE_POD',
    'INVOICE_START_DT',
    'INVOICE_END_DT',
    'INVOICE_TOTAL_AMOUNT',
    'INVOICE_DISCOUNT',
    'INVOICE_AMT_DISCOUNT',
    'INVOICE_PF',
    'INVOICE_ORIGIN_AMT',
    'INVOICE_SITUATION',
    'INVOICE_CUS_DT',
    'INVOICE_INC_DATETIME',
    'INVOICE_CMT_ATCH',
    'EXCEPTION_COUNT',
    'NOTIFICATION_SITUATION',
    'actions'
  ];

  @ViewChild(MatTable) invoicesTable: MatTable<IInvoice>;

  isSidebarOpen = false;
  isExceptionsOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  customer_exception: ICustomerException;
  group_exception: ICustomerException;
  customer_contacts: string;
  invoiceId: number;

  statusMap: Array<string> = [
    'Open',
    'In Dispute',
    'Invoiced',
    'Paid',
    'Cancelled',
    'Under Analysis',
    'Release Billing',
    'Customer Notified'
  ];

  invoicePdf: IInvoicePdf = {};
  pdfsToZip: Array<IPdfToZip> = [];
  refPdf: any;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  rowHovered = -1;

  lastHBL: string;

  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private exporter: XlsxExporterService,
    private invoiceService: InvoiceService,
    private awsService: AwsService,
    private commentService: CommentService,
    private attachmentService: AttachmentService,
    private toast: HotToastService,
    private authService: AuthService,
    private filterService: FilterService,
    private timelineService: TimelineService,
    private customerService: CustomerService
  ) {
    this.titleService.setTitle('DHL | Invoices');
  }

  ngOnInit(): void {    
    const filter = this.filterService.getFilter('invoice');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: any) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getInvoices(this.filterEntity);
    });

    this.getInvoices(this.filterEntity);
  }

  getInvoices(filter: IInvoice): void {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredInvoicesTableDataSource.data.length === 0) this.hideLoader = false;
    this.invoiceService.getPartialInvoices({
      ...filter, 
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((invoices) => {
      this.hideLoader = true;
      invoices.map(invoice => invoice.NOTIFICATION_TOOLTIP = this.showNotificationTooltip(invoice.NOTIFICATION_SITUATION, invoice.NOTIFICATION_DUE_DATE))
      this.invoicesTableDataSource.data = invoices;
      this.filteredInvoicesTableDataSource.data = invoices;
      this.length = invoices[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  exportContainers(invoices: Array<IInvoice>): void{
    const headers = {
      "INVOICE_ID": "PROFORMA",
      "PRTL_CTN_ORDER": "PARTIAL ORDER",
      "INVOICE_TYPE": "SERVICE",
      "INVOICE_CTN_NUMBER": "CONTAINER",
      "INVOICE_CTN_TYPE": "TYPE",
      "INVOICE_HBL": "HOUSE BILL",
      "INVOICE_CUS_NAME": "CUSTOMER",
      "INVOICE_CAR_NAME": "CARRIER",
      "INVOICE_POL": "ORIGIN PORT",
      "INVOICE_POD": "DESTINATION PORT",
      "INVOICE_INC": "INCONTERM",
      "INVOICE_CTN_PENALTY_START": "CHARGE START",
      "INVOICE_CTN_PENALTY_END": "CHARGE END",
      "INVOICE_CTN_FREE_TIME": "FREETIME",
      "INVOICE_CTN_PENALTY_DAYS": "CHARGE DAYS",
      "INVOICE_CTN_RATE_RANGE": "PERDIEM",
      "INVOICE_CTN_AMOUNT": "AMOUNT USD",
      "INVOICE_PF": "PAYABLE FORECAST",
      "INVOICE_CTN_ORIGIN_AMT": "ORIGIN STATION",
      "INVOICE_SITUATION": "INVOICE STATUS",
      "INVOICE_STATUS_CMT": "STATUS COMMENT",
      "INVOICE_OWNER": "OWNER",
      "INVOICE_BRANCH": "BRANCH"
    }

    const dataToExport: Array<any> = [headers];

    const invoice: IInvoice = {
      INVOICE_LIST: invoices.map((item)=> item.INVOICE_ID).join(',')
    }

    const ref = this.toast.loading('Exporting...',{autoClose: false});
    this.invoiceService.exportContainers(invoice).subscribe((dataExport: Array<IExportInvoiceContainers>)=>{
      dataExport.forEach((invoice)=>{
        const newData = invoice;

        newData.INVOICE_SITUATION = this.statusMap[newData.INVOICE_SITUATION];
        newData.INVOICE_STATUS_CMT = newData.INVOICE_STATUS_CMT?.includes(' ') ? newData.INVOICE_STATUS_CMT : decodeURIComponent(newData.INVOICE_STATUS_CMT);

        dataToExport.push(newData);
      });      

      this.exporter.export(dataToExport, {
        fileName: `invoiceContainers_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        
        columnWidths: []
      });

      ref.close();
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.invoicesTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.invoicesTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getInvoices(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getInvoices(this.filterEntity);
  }

  setFilters(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {data: {element: this.filterEntity, isPartial: true}, scrollStrategy: new NoopScrollStrategy()});

    dialog.afterClosed().subscribe((filter: IInvoice) => {
      if (!filter) return;

      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
      this.pageNumber = 0;      
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('invoice', this.filterEntity);

      this.getInvoices(this.filterEntity);
    });
  }

  applyDiscount(data: IApplyDiscountDialog): void {
    const dialog = this.dialog.open(ApplyDiscountDialogComponent, { data, scrollStrategy: new NoopScrollStrategy() });

    dialog.afterClosed().subscribe(([invoices, comment, files]: [Array<IInvoice>, string, Array<File>]) => {
      if (invoices.length === 0 || !comment || files.length === 0) {
        return;
      }

      const attachmentRefs: Array<IAttachmentRef> = [];
      const commentRefs: Array<ICommentRef> = [];

      invoices.forEach(invoice => {
        attachmentRefs.push({
          REG_ID: Number(invoice.INVOICE_ID)
        });
        commentRefs.push({
          REG_ID: Number(invoice.INVOICE_ID),
          COMM_REF_GROUP: 'invoice',
          COMM_REF_USER: this.authService.userId
        });
      });

      const newComment: IComment = {
        COMM_MESSAGE: comment,
        COMM_USER: this.authService.userId,
        COMM_REF: commentRefs
      }

      this.commentService.postComment(newComment).subscribe((response) => {
        this.toast.success(String(response));
      }, (error) => {
        this.toast.error(error.error.Message);
      });

      this.awsService
        .uploadFiles(files, 'invoice', attachmentRefs)
        .pipe(this.toast.observe({
          loading: 'Uploading files...',
          success: () => 'Files uploaded successfully.',
          error: (e) => `Error uploading files: ${e}`
        }))
        .subscribe((attachments) => {
          this.attachmentService.postAttachment(attachments).subscribe((response) => {
            this.toast.success(String(response));
          }, (error) => {
            this.toast.error(error.error.Message)
          });
        });

      this.invoiceService.putInvoice(invoices).subscribe((response) => {
        this.toast.success(String(response));
        this.getInvoices(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  insertComment(data: ICommentDialog): void {
    const dialog = this.dialog.open(CommentDialogComponent, { data, scrollStrategy: new NoopScrollStrategy() });

    dialog.afterClosed().subscribe(([invoices, comment]: [Array<IInvoice>, string]) => {
      if (invoices.length === 0) {
        return;
      }

      if (comment) {
        const commentRefs: Array<ICommentRef> = [];

        invoices.forEach(invoice => {
          commentRefs.push({
            REG_ID: Number(invoice.INVOICE_ID),
            COMM_REF_GROUP: 'invoice',
            COMM_REF_USER: this.authService.userId
          });
        });

        const newComment: IComment = {
          COMM_MESSAGE: comment,
          COMM_USER: this.authService.userId,
          COMM_REF: commentRefs
        }

        this.commentService.postComment(newComment).subscribe((response) => {
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  insertAttachment(data: IAttachmentDialog): void {
    const dialog = this.dialog.open(AttachmentDialogComponent, { data, scrollStrategy: new NoopScrollStrategy() });

    dialog.afterClosed().subscribe(([invoices, files]: [Array<IInvoice>, Array<File>]) => {
      if (invoices.length === 0 || files.length === 0) {
        return;
      }

      const attachmentRefs: Array<IAttachmentRef> = [];

      invoices.forEach(invoice => {
        attachmentRefs.push({
          REG_ID: Number(invoice.INVOICE_ID)
        });
      });

      this.awsService
        .uploadFiles(files, 'invoice', attachmentRefs)
        .pipe(this.toast.observe({
          loading: 'Uploading files...',
          success: () => 'Files uploaded successfully.',
          error: (e) => `Error uploading files: ${e}`
        }))
        .subscribe((attachments) => {
          this.attachmentService.postAttachment(attachments).subscribe((response) => {
            this.toast.success(String(response));
          }, (error) => {
            this.toast.error(error.error.Message)
          });
        });
    });
  }

  async changeStatus(data: IChangeStatusDialog): Promise<void> {
    const dialog = this.dialog.open(ChangeStatusDialogComponent, { data, scrollStrategy: new NoopScrollStrategy() });

    try {
      const result = await dialog.afterClosed().toPromise();

      if(result){        
        const [invoices, invoicesNotified, comment] = result;
  
        if (invoices.length === 0) {
          return;
        }
  
        const hasException = invoices.some(invoice => invoice.EXCEPTION_COUNT > 0);
        const sendNotification = invoices.some(invoice => invoice.INVOICE_SITUATION === '7');
  
        if (hasException) {
          const exceptionDialog = this.dialog.open(CustomerExceptionInfoDialogComponent, { scrollStrategy: new NoopScrollStrategy() });
          const willContinue = await exceptionDialog.afterClosed().toPromise();
  
          if (!willContinue) {
            return;
          }
          
        }

        if(sendNotification) await this.generateInvoices(invoicesNotified);
  
        if (comment) {
          const commentRefs: Array<ICommentRef> = [];
  
          invoices.forEach(invoice => {
            commentRefs.push({
              REG_ID: Number(invoice.INVOICE_ID),
              COMM_REF_GROUP: 'invoice',
              COMM_REF_USER: this.authService.userId
            });
          });
  
          const newComment: IComment = {
            COMM_MESSAGE: comment,
            COMM_USER: this.authService.userId,
            COMM_REF: commentRefs
          }
  
          this.commentService.postComment(newComment).subscribe((response) => {
            this.toast.success(String(response));
          }, (error) => {
            this.toast.error(error.error.Message);
          });
        }
  
        this.invoiceService.putInvoice(invoices).subscribe((response) => {
          this.toast.success(String(response));
          this.getInvoices(this.filterEntity);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
      
      
    } catch (error) {
      console.error('Error:', error);
    }
  }

  getStatus(status: number): string {
    return this.statusMap[Number(status)];
  }

  export(): void {
    const headers = {
      INVOICE_ID: 'PROFORMA',
      PRTL_CTN_ORDER: "PARTIAL ORDER",
      INVOICE_TYPE: 'SERVICE',
      INVOICE_CTN_NUMBER: "CONTAINER",
      INVOICE_SHIPMENT: 'SHIPMENT',
      INVOICE_HBL: 'HOUSE BILL',
      SHIP_DPT_CODE: 'DEPT',
      INVOICE_CUS_NAME: 'CUSTOMER',
      INVOICE_CAR_NAME: 'CARRIER',
      INVOICE_CONSOL_TYPE: 'CONSOL TYPE',
      INVOICE_INC: 'INCOTERM',
      INVOICE_POL: 'ORIGIN PORT',
      INVOICE_POD: 'DESTINATION PORT',
      INVOICE_START_DT_TEXT: 'START DATE',
      INVOICE_END_DT_TEXT: 'END DATE',
      INVOICE_TOTAL_AMOUNT: 'AMOUNT USD',
      INVOICE_DISCOUNT: 'DISCOUNT USD',
      INVOICE_AMT_DISCOUNT: 'TOTAL WITH DISCOUNT USD',
      INVOICE_PF: 'PAYABLE FORECAST',
      INVOICE_ORIGIN_AMT: 'ORIGIN STATION',
      INVOICE_CUS_DT_TEXT: 'INVOICE DATE',
      INVOICE_INC_DATETIME: 'CALCULATION DATE',
      INVOICE_SITUATION: 'INVOICE STATUS',
      INVOICE_STATUS_CMT_TEXT: 'STATUS COMMENT',
      INVOICE_OWNER: 'OWNER',
      INVOICE_BRANCH: 'BRANCH'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.invoiceService.getInvoices({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.invoicesExportDataSource.data = dataExport;

        const data: Array<IInvoice> = this.prepareDataToExport([...this.invoicesExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `invoices_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    } else {
      const data: Array<IInvoice> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `invoices_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IInvoice>): Array<IInvoice> {
    const dataToExport: Array<IInvoice> = [];

    const datesText = {
      INVOICE_START_DT_TEXT: '',
      INVOICE_END_DT_TEXT: '',
      INVOICE_CUS_DT_TEXT: '',
      INVOICE_STATUS_CMT_TEXT: ''
    }

    data.forEach((invoice) => {
      const newData = {...invoice, ...datesText};

      newData.INVOICE_START_DT_TEXT = newData.INVOICE_START_DT && moment(newData.INVOICE_START_DT).format('DD/MM/YYYY');
      newData.INVOICE_END_DT_TEXT = newData.INVOICE_END_DT && moment(newData.INVOICE_END_DT).format('DD/MM/YYYY');
      newData.INVOICE_CUS_DT_TEXT = newData.INVOICE_CUS_DT && moment(newData.INVOICE_CUS_DT).format('DD/MM/YYYY');
      newData.INVOICE_INC_DATETIME = newData.INVOICE_INC_DATETIME && moment(newData.INVOICE_INC_DATETIME).format('DD/MM/YYYY');
      newData.INVOICE_SITUATION = this.statusMap[newData.INVOICE_SITUATION];
      newData.INVOICE_STATUS_CMT_TEXT = newData.INVOICE_STATUS_CMT?.includes(' ') ? newData.INVOICE_STATUS_CMT : decodeURIComponent(newData.INVOICE_STATUS_CMT);

      delete newData.INVOICE_START_DT;
      delete newData.INVOICE_END_DT;
      delete newData.INVOICE_CUS_DT;
      delete newData.INVOICE_VOYAGE;
      delete newData.INVOICE_CUS_CODE;
      delete newData.INVOICE_CUS_CNPJ;
      delete newData.INVOICE_CUS_ADDRESS;
      delete newData.INVOICE_CUS_STATE;
      delete newData.INVOICE_DHL_NAME;
      delete newData.INVOICE_DHL_CNPJ;
      delete newData.INVOICE_DHL_PHONE;
      delete newData.INVOICE_DHL_EMAIL;
      delete newData.INVOICE_DISCOUNT_TYPE;
      delete newData.INVOICE_STATUS;
      delete newData.INVOICE_USER;
      delete newData.SHIP_ID;
      delete newData.INVOICE_MBL;
      delete newData.INVOICE_VESSEL;
      delete newData.INVOICE_REFERENCE;
      delete newData.INVOICE_ATA;
      delete newData.INVOICE_ATD;
      delete newData.INVOICE_CHARGE_TYPE;
      delete newData.INVOICE_CMT_ATCH;
      delete newData.INVOICE_STATUS_CMT;
      delete newData.INVOICE_CMT_ST;
      delete newData.INVOICE_CMT;
      delete newData.INVOICE_CMT_BRANCH;
      delete newData.INVOICE_CMT_OWNER;
      delete newData.INVOICE_CMT_USERNAME;
      delete newData.INVOICE_CMT_INC_DT;
      delete newData.INVOICE_PRTL;
      delete newData.INVOICE_CMT_INC_DT;
      delete newData.INVOICE_PRTL_CTN_ID;
      delete newData.EXCEPTION_COUNT;
      delete newData.NOTIFICATION_DUE_DATE;
      delete newData.NOTIFICATION_SITUATION;
      delete newData.NOTIFICATION_TOOLTIP;
      delete newData.NOTIFICATION_INVOICE_URL;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openSidebar(invoiceId: number): void {
    this.invoiceId = invoiceId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';    
  }

  openExceptions(invoiceId: number): void {
    this.invoiceId = invoiceId;

    this.getExceptions();

    this.isExceptionsOpen = true;
    document.body.style.overflow = 'hidden';    
  }

  closeSidebar(): void {
    this.rowHovered = -1;
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  closeExceptions(): void {
    this.rowHovered = -1;
    this.isExceptionsOpen = false;
    document.body.style.overflow = 'unset';

    this.customer_exception = {};
    this.group_exception = {};
    this.customer_contacts = '';
  }

  getExceptions(): void {
    this.invoiceService.getExceptions({ INVOICE_ID: String(this.invoiceId) }).subscribe(exceptions => {
      this.customer_exception = exceptions.filter(exception => exception.CUS_NAME)[0];
      this.group_exception = exceptions.filter(exception => exception.CUS_GRP_NAME)[0];
      this.customer_contacts = exceptions.filter(exception => exception.CUSTOMER_CONTACTS)[0].CUSTOMER_CONTACTS;
    });
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `invoice`, REG_ID: this.invoiceId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.invoiceId),
          COMM_REF_GROUP: `invoice`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'invoice', REG_ID: this.invoiceId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'invoice', [{ REG_ID: this.invoiceId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'invoice', TIMELINE_REG_ID: this.invoiceId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  async generateInvoices(invoices: Array<IInvoice>){
    const notifications: Array<ICustomerNotification> = [];

    this.refPdf = this.toast.loading('Generating Proforma(s)...',{autoClose: false});

    for (const element of invoices) {
      const pdfName =
        element.INVOICE_TYPE == 'DEMURRAGE'
          ? `proformas/proforma-dem-${element.INVOICE_ID}.pdf`
          : `proformas/proforma-det-${element.INVOICE_ID}.pdf`;
  
      const invoice: IInvoice = {
        INVOICE_ID: element.INVOICE_ID,
      };
  
      const container = await this.invoiceService.getInvoiceContainer(invoice).toPromise();
  
      this.invoicePdf = {
        INVOICE_ID: element.INVOICE_ID,
        SHIP_ID: element.SHIP_ID,
        INVOICE_ATA: element.INVOICE_ATA,
        INVOICE_ATD: element.INVOICE_ATD,
        INVOICE_VESSEL: element.INVOICE_VESSEL,
        INVOICE_VOYAGE: element.INVOICE_VOYAGE,
        INVOICE_START_DT: element.INVOICE_START_DT,
        INVOICE_END_DT: element.INVOICE_END_DT,
        INVOICE_CUS_DT: element.INVOICE_CUS_DT,
        INVOICE_POL: element.INVOICE_POL,
        INVOICE_POD: element.INVOICE_POD,
        INVOICE_HBL: element.INVOICE_HBL,
        INVOICE_MBL: element.INVOICE_MBL,
        INVOICE_REFERENCE: element.INVOICE_REFERENCE,
        INVOICE_TOTAL_AMOUNT: element.INVOICE_TOTAL_AMOUNT,
        INVOICE_CUS_NAME: element.INVOICE_CUS_NAME,
        INVOICE_CUS_CNPJ: element.INVOICE_CUS_CNPJ,
        INVOICE_CUS_ADDRESS: element.INVOICE_CUS_ADDRESS,
        INVOICE_CUS_STATE: element.INVOICE_CUS_STATE,
        INVOICE_SITUATION: element.INVOICE_SITUATION,
        INVOICE_DISCOUNT: element.INVOICE_DISCOUNT,
        INVOICE_DISCOUNT_TYPE: element.INVOICE_DISCOUNT_TYPE,
        INVOICE_AMT_DISCOUNT: element.INVOICE_AMT_DISCOUNT,
        INVOICE_INC_DATETIME: element.INVOICE_INC_DATETIME,
        INVOICE_TYPE: element.INVOICE_TYPE,
        INVOICE_STATUS: element.INVOICE_STATUS,
        INVOICE_USER: element.INVOICE_USER,
        INVOICE_CAR_NAME: element.INVOICE_CAR_NAME,
        INVOICE_CHARGE_TYPE: element.INVOICE_CHARGE_TYPE,
        INVOICE_PRTL: element.INVOICE_PRTL,
        PRTL_CTN_ORDER: element.PRTL_CTN_ORDER,
        INVOICE_CTNS: container
      };
  
      await this.onUploadInvoice('print', pdfName);

      const notification: ICustomerNotification = {
        INVOICE_ID: Number(element.INVOICE_ID),
        NOTIFICATION_INVOICE_URL: pdfName,
        NOTIFICATION_USER: this.authService.userId,
      }

      notifications.push(notification);
    }
  
    this.refPdf.close();

    await this.sendNotification(notifications);
  }

  async onUploadInvoice(elementId: any, pdfName: string) {
    var worker = html2pdf();
    var opt = {
        pageSize: 'A4',
        margin: 8,
        pagebreak: { mode: "avoid-all" },
        enableLinks: false,
        html2canvas: {
            useCORS: true,
            letterRendering: true,
            scale: 2,
        },
        jsPDF: { orientation: 'landscape' }
    }

    let jsonPdf = await worker.set(opt).from(document.getElementById(elementId)).output('blob');

    this.awsService
      .uploadInvoice(jsonPdf, pdfName)
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      })).toPromise();
  }

  sendNotification(notifications: Array<ICustomerNotification>){
    this.customerService.postCustomerNotification(notifications).subscribe((response) => {
      this.toast.success(String(response));
    }, (error) => {
      this.toast.error(error.error.Message);
    });
  }

  defineRowHovered(index: number) {
    this.rowHovered = index;
  }

  isRowHovered(index: number) {
    return (this.isExceptionsOpen || this.isSidebarOpen) && this.rowHovered === index;
  }

  showNotificationIcon(status: string): string{
    switch(status){
      case 'Active':
        return 'schedule'
        break;
      case 'Closed':
        return 'check_circle'
        break;
      case 'Pending':
        return 'error'
        break;
      case 'Failure':
        return 'cancel'
        break;
      case 'Dispute':
        return 'error'
        break;
    }
  }

  showNotificationTooltip(status: string, dueDate: Date): string{
    const actualDate = new Date();
    const futureDate = new Date(dueDate);
    const time = futureDate.getTime() - actualDate.getTime();
    const hoursRemaining = time / (1000 * 60 * 60);

    if(status === 'Active'){      
      return `Active: ${Math.round(hoursRemaining)} hour(s) remaining`;
    }else{
      return status;
    }
  }

  showSeparatorRow(index: number, element: IInvoice) {
    if (index === 0) {
      this.lastHBL = element.INVOICE_HBL;
      return false;
    }

    if (this.lastHBL === null || this.lastHBL === undefined) {
      this.lastHBL = element.INVOICE_HBL;
      return false;
    }

    if (this.lastHBL === element.INVOICE_HBL) {
      this.lastHBL = element.INVOICE_HBL;
      return false;
    }

    this.lastHBL = element.INVOICE_HBL;
    return true;
  }

  /**** Salvar PDF ******/
  savePDF(){
    this.pdfsToZip = [];

    this.refPdf = this.toast.loading('Exporting...',{autoClose: false});
    this.selection.selected.forEach(element => {
      var pdfName = element.INVOICE_TYPE == 'DEMURRAGE' ? `Proforma DEM-${element.INVOICE_ID}.pdf` : `Proforma DET-${element.INVOICE_ID}.pdf`;

      const invoice: IInvoice = {
        INVOICE_ID: element.INVOICE_ID
      }

      this.invoiceService.getInvoiceContainer(invoice).subscribe((container) => {
        this.invoicePdf = {
          INVOICE_ID: element.INVOICE_ID,
          SHIP_ID: element.SHIP_ID,
          INVOICE_ATA: element.INVOICE_ATA,
          INVOICE_ATD: element.INVOICE_ATD,
          INVOICE_VESSEL: element.INVOICE_VESSEL,
          INVOICE_VOYAGE: element.INVOICE_VOYAGE,
          INVOICE_START_DT: element.INVOICE_START_DT,
          INVOICE_END_DT: element.INVOICE_END_DT,
          INVOICE_CUS_DT: element.INVOICE_CUS_DT,
          INVOICE_POL: element.INVOICE_POL,
          INVOICE_POD: element.INVOICE_POD,
          INVOICE_HBL: element.INVOICE_HBL,
          INVOICE_MBL: element.INVOICE_MBL,
          INVOICE_REFERENCE: element.INVOICE_REFERENCE,
          INVOICE_TOTAL_AMOUNT: element.INVOICE_TOTAL_AMOUNT,
          INVOICE_CUS_NAME: element.INVOICE_CUS_NAME,
          INVOICE_CUS_CNPJ: element.INVOICE_CUS_CNPJ,
          INVOICE_CUS_ADDRESS: element.INVOICE_CUS_ADDRESS,
          INVOICE_CUS_STATE: element.INVOICE_CUS_STATE,
          INVOICE_SITUATION: element.INVOICE_SITUATION,
          INVOICE_DISCOUNT: element.INVOICE_DISCOUNT,
          INVOICE_DISCOUNT_TYPE: element.INVOICE_DISCOUNT_TYPE,
          INVOICE_AMT_DISCOUNT: element.INVOICE_AMT_DISCOUNT,
          INVOICE_INC_DATETIME: element.INVOICE_INC_DATETIME,
          INVOICE_TYPE: element.INVOICE_TYPE,
          INVOICE_STATUS: element.INVOICE_STATUS,
          INVOICE_USER: element.INVOICE_USER,
          INVOICE_CAR_NAME: element.INVOICE_CAR_NAME,
          INVOICE_CHARGE_TYPE: element.INVOICE_CHARGE_TYPE,
          INVOICE_PRTL: element.INVOICE_PRTL,
          PRTL_CTN_ORDER: element.PRTL_CTN_ORDER
        };

        this.invoicePdf.INVOICE_CTNS = container;

        this.convertBase64('print', pdfName);

        if(this.pdfsToZip.length === this.selection.selected.length){
          this.createZipFile(this.pdfsToZip);          
        }
      })
    });
  }

  createZipFile(pdfs: Array<IPdfToZip>) {
    let zip = new JSZip();
    let invoicesZipName = `invoices_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}.zip`;

    pdfs.forEach(element => {
      zip.file(element.pdfName, element.pdf);
      console.log(element);
    });

    zip.generateAsync({type:"blob"})
    .then(function(content) {
        saveAs(content, invoicesZipName); 
    }).finally(()=>{
      this.refPdf.close();
    });
  }

  convertBase64(elementId: any, pdfName: string) {
    var worker = html2pdf();
    var opt = {
        pageSize: 'A4',
        margin: 8,
        pagebreak: { mode: "avoid-all" },
        enableLinks: false,
        html2canvas: {
            useCORS: true,
            letterRendering: true,
            scale: 2,
        },
        jsPDF: { orientation: 'landscape' }
    }

    let jsonPdf = worker.set(opt).from(document.getElementById(elementId)).output('blob');

    this.pdfsToZip.push({
      pdf: jsonPdf,
      pdfName: pdfName
    });
  }

}