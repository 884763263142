import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInvoice } from 'src/app/models/invoice.model';
import { AuthService } from 'src/app/services/auth.service';

export interface IChangeStatusDialog {
  element: IInvoice | Array<IInvoice>;
}

@Component({
  selector: 'dhl-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.scss']
})
export class ChangeStatusDialogComponent implements OnInit {
  form: UntypedFormGroup;

  comment: string = '';

  invoiceStatus: Array<string> = [
    "",
    "In Dispute",
    "Invoiced",
    "Paid",
    "Canceled",
    "Under Analysis",
    "Release Billing",
    "Customer Notified"
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChangeStatusDialog,
    private dialogRef: MatDialogRef<ChangeStatusDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    
    this.form = this.formBuilder.group({
      status: new UntypedFormControl(this.data.element instanceof Array ? '' : this.data.element?.INVOICE_SITUATION),
      branch: new UntypedFormControl('', Validators.required),
      owner: new UntypedFormControl('', Validators.required)
    });
  }

  close(value: [Array<IInvoice>, Array<IInvoice>, string]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newInvoice: Array<IInvoice> = [];
    let invoicesNotified: Array<IInvoice> = [];

    if (this.data.element instanceof Array) {
      newInvoice = this.data.element.map((element) => {
        if(this.verifySituation(element)){
          return {
            INVOICE_ID: element.INVOICE_ID,
            EXCEPTION_COUNT: element.EXCEPTION_COUNT,
            INVOICE_SITUATION: this.form.value.status,
            INVOICE_BRANCH: this.form.value.branch ? this.form.value.branch : ' ',
            INVOICE_OWNER: this.form.value.owner ? this.form.value.owner : ' ',
            INVOICE_STATUS_CMT: this.comment ? this.comment : ' ',
            INVOICE_USER: this.authService.userId
          }
        }
      });

      invoicesNotified = this.data.element.map((element) => {
        if(this.verifySituation(element)){
          return {
            ...element,
            INVOICE_ID: element.INVOICE_ID,
            EXCEPTION_COUNT: element.EXCEPTION_COUNT,
            INVOICE_SITUATION: this.form.value.status,
            INVOICE_BRANCH: this.form.value.branch ? this.form.value.branch : ' ',
            INVOICE_OWNER: this.form.value.owner ? this.form.value.owner : ' ',
            INVOICE_STATUS_CMT: this.comment ? this.comment : ' ',
            INVOICE_USER: this.authService.userId
          }
        }
      });
    } else {
      if(this.verifySituation(this.data.element)){
        newInvoice.push({
          INVOICE_ID: this.data.element.INVOICE_ID,
          EXCEPTION_COUNT: this.data.element.EXCEPTION_COUNT,
          INVOICE_SITUATION: this.form.value.status,
          INVOICE_BRANCH: this.form.value.branch ? this.form.value.branch : ' ',
          INVOICE_OWNER: this.form.value.owner ? this.form.value.owner : ' ',
          INVOICE_STATUS_CMT: this.comment ? this.comment : ' ',
          INVOICE_USER: this.authService.userId
        });

        invoicesNotified.push({
          ...this.data.element,
          INVOICE_ID: this.data.element.INVOICE_ID,
          EXCEPTION_COUNT: this.data.element.EXCEPTION_COUNT,
          INVOICE_SITUATION: this.form.value.status,
          INVOICE_BRANCH: this.form.value.branch ? this.form.value.branch : ' ',
          INVOICE_OWNER: this.form.value.owner ? this.form.value.owner : ' ',
          INVOICE_STATUS_CMT: this.comment ? this.comment : ' ',
          INVOICE_USER: this.authService.userId
        });
      }
    }

    this.close([newInvoice, invoicesNotified, this.comment]);
  }

  onComment(comment: string) {
    this.comment = comment;
  }

  showComment(): boolean{
    let show: boolean = false;
    const validStatus = [1,4,5];
    validStatus.forEach((value)=>{
      if(this.form.get('status').value == value){
        show = true;
      }
    })

    return show;
  }

  verifySituation(data: IInvoice): boolean{
    let valid: boolean = false;
    const validStatus = [0,1,2,3,5,6,7];
    validStatus.forEach((value)=>{
      if(data.INVOICE_SITUATION == value.toString()){
        valid = true;
      }
    });

    return valid;
  }

  verifyStatus(): void{
    if(this.form.get('status').value == 4){
      this.form.get('branch').clearValidators();
      this.form.get('branch').updateValueAndValidity();

      this.form.get('owner').clearValidators();
      this.form.get('owner').updateValueAndValidity();
    }else{
      this.form.get('branch').setValidators([Validators.required]);
      this.form.get('branch').updateValueAndValidity();

      this.form.get('owner').setValidators([Validators.required]);
      this.form.get('owner').updateValueAndValidity();
    }
  }

  isValidComment(): boolean{
    if(this.data.element instanceof Array || !this.data.element.INVOICE_CMT_USERNAME){
      return false;
    }else{
      return true;
    }
  }

  decodeUri(uri: string): string{
    return decodeURIComponent(uri);
  }
}
