<header>
  <h2>Customers</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="openCustomerActionDialog()">
          <mat-icon>add</mat-icon>
          <span>Add To Group</span>
        </button>
        <button mat-menu-item (click)="deleteSelected()">
          <mat-icon>delete</mat-icon>
          <span>Delete All</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="import()">Import Excel</button>
      <button mat-flat-button color="accent" (click)="openCustomerDialog({title: 'Add customer', mode: 'add'})">Create Customer</button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="filteredCustomerTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #customerTable [dataSource]="filteredCustomerTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="CUS_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
        <td mat-cell *matCellDef="let element">{{element.CUS_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">Code</th>
        <td [matTooltipDisabled]="!isOverflow(element.CUS_CODE)" [matTooltip]="element.CUS_CODE" mat-cell *matCellDef="let element">{{element.CUS_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
        <td [matTooltipDisabled]="!isOverflow(element.CUS_NAME)" [matTooltip]="element.CUS_NAME" mat-cell *matCellDef="let element">{{element.CUS_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_ADDRESS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Address">Addess</th>
        <td [matTooltipDisabled]="!isOverflow(element.CUS_ADDRESS)" [matTooltip]="element.CUS_ADDRESS" mat-cell *matCellDef="let element">{{element.CUS_ADDRESS}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_STATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by State">State</th>
        <td mat-cell *matCellDef="let element">{{element.CUS_STATE}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_UNLOCO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Branch">Branch</th>
        <td [matTooltipDisabled]="!isOverflow(element.CUS_UNLOCO)" [matTooltip]="element.CUS_UNLOCO" mat-cell *matCellDef="let element">{{element.CUS_UNLOCO}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_GROUP_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Group Name">Group Name</th>
        <td [matTooltipDisabled]="!isOverflow(element.CUS_GROUP_NAME)" [matTooltip]="element.CUS_GROUP_NAME" mat-cell *matCellDef="let element">{{element.CUS_GROUP_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="contacts">
        <th mat-header-cell *matHeaderCellDef>Contacts</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="openContactDialog({customer: element})">View Contacts</button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openCustomerDialog({title: 'Edit customer', mode: 'edit', customer: element})">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button mat-icon-button (click)="openCustomerDialog({title: 'Delete customer', mode: 'delete', customer: element})">
            <mat-icon color="primary">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="customerTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: customerTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
<!-- <dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
<button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar" (click)="openSidebar()" *ngIf="timeline.length !== 0">
  <mat-icon>menu_open</mat-icon>
</button> -->
