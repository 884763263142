import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAttachment, IAttachmentRef } from 'src/app/models/attachment.model';
import { DatePipe } from '@angular/common';
import { SlugifyPipe } from 'ngx-pipes';
import S3 from 'aws-sdk/clients/s3';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export abstract class AwsService {
  protected apiURL = environment.apiURL;

  constructor(
    protected httpClient: HttpClient,
    private datePipe: DatePipe,
    private slugifyPipe: SlugifyPipe,
    private authService: AuthService
  ) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  uploadFiles(files: Array<File>, attachmentGroup: string, attachmentRef: Array<IAttachmentRef>): Observable<Array<IAttachment>> {

    return new Observable<Array<IAttachment>>((observer) => {
      const attachments: Array<IAttachment> = [];
      const formData = new FormData();       

      for (const [index, file] of files.entries()) {
        const fileName = this.slugifyPipe.transform(file.name.substring(0, file.name.lastIndexOf('.')));
        const fileExtension = file.name.split('.').pop();
        const date = this.datePipe.transform(new Date(), 'dd-MM-YYYY_HH-mm-ss');

        const newFileName = `${fileName}_${date}.${fileExtension}`;
        const newFile = new File([file], newFileName);
        formData.append("file", newFile);
        
        attachments.push({
          ATCH_FILE_NAME: newFileName,
          ATCH_REF_GROUP: attachmentGroup,
          LIST_ATCH_REF: attachmentRef,
          ATCH_USER: this.authService.userId
        });          

        if (index + 1 === files.length) {     
          this.httpClient.post(`${this.apiURL}/api/upload`, formData, this.httpOptions).subscribe(()=>{
            observer.next(attachments);
            observer.complete();
          },(error)=>{
            observer.error(error);
          });     
        }
      }      
    });
  }

  uploadInvoice(file: Blob, fileName: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const formData = new FormData();
      const newFile = new File([file], fileName);
      formData.append("file", newFile);

      this.httpClient.post(`${this.apiURL}/api/upload`, formData, this.httpOptions).subscribe(()=>{
        observer.next(true);
        observer.complete();
      },(error)=>{
        observer.error(error);
      });  
    });
  }
}
