import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../angular-material.module';
import { NavbarComponent } from './navbar/navbar.component';
import { CommentComponent } from './comment/comment.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PipesModule } from '../pipes/pipes.module';
import { TimelineComponent } from './timeline/timeline.component';
import { NgPipesModule, TimeAgoPipe } from 'ngx-pipes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ExceptionComponent } from './exception/exception.component';

@NgModule({
  declarations: [
    NavbarComponent,
    CommentComponent,
    AttachmentComponent,
    SpinnerComponent,
    SidebarComponent,
    TimelineComponent,
    ExceptionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularMaterialModule,
    NgxDropzoneModule,
    PipesModule,
    NgPipesModule,
    BrowserAnimationsModule,
    NgxChartsModule
  ],
  exports: [
    NavbarComponent,
    CommentComponent,
    AttachmentComponent,
    SpinnerComponent,
    SidebarComponent,
    TimelineComponent,
    ExceptionComponent
  ],
  providers: [TimeAgoPipe]
})
export class ComponentsModule { }
