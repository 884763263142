import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITimeline } from '../models/timeline.model';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };

  getTimeline(timeline: ITimeline): Observable<Array<ITimeline>> {
    return this.httpClient.get<Array<ITimeline>>(`${this.apiURL}/api/timeline?filter=${JSON.stringify(timeline)}`, this.httpOptions);
  }
}
