<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="userGroupForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" name="name" formControlName="name" required>
        <mat-error *ngIf="userGroupForm.get('name').invalid">You must enter a name</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field color="accent">
        <mat-label>Users</mat-label>
        <mat-select name="userList" formControlName="userList" multiple>
          <mat-option>
            <ngx-mat-select-search formControlName="userFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user.USER_ID">
            {{user.USER_ID}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Pages</mat-label>
        <mat-select name="pageList" formControlName="pageList" multiple>
          <mat-option>
            <ngx-mat-select-search formControlName="pageFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let page of filteredPages | async" [value]="page.PAG_ID">
            {{page.PAG_NAME}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode === 'delete'">Do you really wish to delete the user group {{data.userGroup.GRP_NAME}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'add'" [disabled]="userGroupForm.get('name').invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="userGroupForm.get('name').invalid">Ok</button>
    </div>
  </form>
</div>