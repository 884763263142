import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { IPage } from 'src/app/models/page.model';
import { IUserGroup } from 'src/app/models/user-group.model';
import { IUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { PageService } from 'src/app/services/page.service';
import { UserService } from 'src/app/services/user.service';

export interface IUserGroupDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  userGroup?: IUserGroup;
}

@Component({
  selector: 'dhl-user-groups-dialog',
  templateUrl: './user-groups-dialog.component.html',
  styleUrls: ['./user-groups-dialog.component.scss']
})
export class UserGroupsDialogComponent implements OnInit {
  userGroupForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  hidePassword: boolean = true;
  users: Array<IUser>;
  filteredUsers: ReplaySubject<Array<IUser>> = new ReplaySubject<Array<IUser>>(1);
  pages: Array<IPage>;
  filteredPages: ReplaySubject<Array<IPage>> = new ReplaySubject<Array<IPage>>(1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UserGroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUserGroupDialog,
    private userService: UserService,
    private authService: AuthService,
    private pageService: PageService
  ) { }

  ngOnInit(): void {
    this.userGroupForm = this.formBuilder.group({
      name: new UntypedFormControl(this.data.userGroup?.GRP_NAME, [Validators.required]),
      userList: new UntypedFormControl( this.data.userGroup?.USER_LIST && this.data.userGroup?.USER_LIST.toString().split(', ')),
      userFilter: new UntypedFormControl(''),
      pageList: new UntypedFormControl(this.data.userGroup?.PAGE_LIST && this.data.userGroup?.PAGE_LIST.toString().split(', ').map(Number)),
      pageFilter: new UntypedFormControl('')
    });

    this.getUsers();
    this.getPages();

    if(this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.userGroupForm.disable();
    }
  }

  getUsers(): void {
    this.userService.getUser({}).subscribe((users) => {
      this.users = users;
      this.filteredUsers.next(users);
      if (this.data.mode == 'add' || this.data.mode == 'edit') {
        this.userGroupForm.get('userFilter').valueChanges.subscribe(() => {
          this.filterUsers();
        });
      }
    });
  }

  getPages(): void {
    this.pageService.getPage().subscribe((pages) => {
      this.pages = pages;
      this.filteredPages.next(pages)
      if (this.data.mode == 'add' || this.data.mode == 'edit') {
        this.userGroupForm.get('pageFilter').valueChanges.subscribe(() => {
          this.filterPages();
        });
      }
    });
  }

  filterUsers(): void {
    const filterValue = this.userGroupForm.get('userFilter').value.toLowerCase();
    this.filteredUsers.next(this.users.filter((user) => user.PI_USER_NAME.toLowerCase().includes(filterValue)));
  }

  filterPages(): void {
    const filterValue = this.userGroupForm.get('pageFilter').value.toLowerCase();
    this.filteredPages.next(this.pages.filter((page) => page.PAG_NAME.toLowerCase().includes(filterValue)));
  }

  closeDialog(userGroup: IUserGroup) {
    this.dialogRef.close(userGroup);
  }

  enableForm(): void {
    this.isFormEnabled = true;
    this.userGroupForm.get('name').enable();
    this.userGroupForm.get('userList').enable();
    this.userGroupForm.get('pageList').enable();
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.userGroupForm.value;
    let userGroup: IUserGroup = null;

    if (this.data.mode === 'add') {
      userGroup = {
        GRP_NAME: encodeURIComponent(formValue.name),
        GRP_STATUS: 1,
        GRP_USER: this.authService.userId,
        PAGE_LIST: formValue.pageList,
        USER_LIST: formValue.userList
      }
    } else if (this.data.mode === 'edit') {
      userGroup = {
        GRP_ID: this.data.userGroup.GRP_ID,
        GRP_NAME: encodeURIComponent(formValue.name),
        GRP_STATUS: 1,
        GRP_USER: this.authService.userId,
        PAGE_LIST: formValue.pageList,
        USER_LIST: formValue.userList
      }
    } else if (this.data.mode === 'delete') {
      userGroup = {
        GRP_ID: this.data.userGroup.GRP_ID,
        GRP_NAME: null,
        GRP_STATUS: 0,
        GRP_USER: this.authService.userId,
        PAGE_LIST: null,
        USER_LIST: null
      }
    }

    this.closeDialog(userGroup);
  }
}
