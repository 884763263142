import { LogService } from 'src/app/services/log.service';
import { IRpaLog, IIntegrationLog } from './../../../../models/log.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IFilterDialog {
  element: IIntegrationLog;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;

  messageList: Array<IIntegrationLog> = [];
  statusList: Array<IIntegrationLog> = [];

  years: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog,
    private formBuilder: UntypedFormBuilder,
    private logService: LogService
  ) { }

  ngOnInit(): void {
    this.populateYears();
    this.logService.getIntegrationMessage().subscribe((data) => { this.messageList = data });
    this.logService.getIntegrationStatusItem().subscribe((data) => { this.statusList = data });
    
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.data.element?.LI_ID),      
      hbl: new UntypedFormControl(this.data.element?.LI_HBL),      
      ship: new UntypedFormControl(this.data.element?.LI_SHIP_CODE),      
      message: new UntypedFormControl(this.data.element?.LI_MESSAGE),      
      statusItem: new UntypedFormControl(this.data.element?.LI_STATUS_ITEM),      
      month: new UntypedFormControl(this.data.element?.LI_MONTH),      
      year: new UntypedFormControl(this.data.element?.LI_YEAR),      
    });
  }

  closeDialog(filters: IIntegrationLog) {
    this.dialogRef.close(filters);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    let filters: IIntegrationLog = {
      LI_ID: this.form.value.id,
      LI_HBL: this.form.value.hbl,
      LI_SHIP_CODE: this.form.value.ship,
      LI_MESSAGE: this.form.value.message,
      LI_STATUS_ITEM: this.form.value.statusItem,
      LI_MONTH: this.form.value.month,
      LI_YEAR: this.form.value.year,
    }

    this.closeDialog(filters);
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue('');
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetFilters(): void {
    this.form.setValue({
      id: null,
      hbl: null,
      ship: null,
      message: null,
      statusItem: null,
      month: null,
      year: null
    });
  }

  populateYears() {
    const currentYear = new Date().getFullYear();
    for (let year = 2022; year <= currentYear; year++) {
      this.years.push(year);
    }
  }
}
