import { DiscountDialogComponent, IDiscountDialog } from './payables-generate-dialogs/discount-dialog/discount-dialog.component';
import { ChargeCodeDialogComponent, IChargeCodeDialog } from './payables-generate-dialogs/charge-code-dialog/charge-code-dialog.component';
import { IPaymentOrder } from 'src/app/models/payable.model';
import { CustomerService } from 'src/app/services/customer.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ICustomer } from './../../models/customer.model';
import { IPayable } from './../../models/payable.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { GeneratePoDialogComponent, IGeneratePoDialog } from './payables-generate-dialogs/generate-po-dialog/generate-po-dialog.component';
import { FilterDialogComponent } from './payables-generate-dialogs/filter-dialog/filter-dialog.component';
import { HotToastService } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { FilterService } from 'src/app/services/filter.service';
import { PayableService } from 'src/app/services/payable.service';
import { MatTableFilter } from 'mat-table-filter';
import { ICarrier } from 'src/app/models/parameter.model';
import moment from 'moment';
import { formatDate } from '@angular/common';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-payables-generate',
  templateUrl: './payables-generate.component.html',
  styleUrls: ['./payables-generate.component.scss']
})

export class PayablesGenerateComponent implements OnInit {
  pageKey = 'payablesGenerate';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IPayable>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IPayable = {
    PRC_SER: null,
    PRC_HBL: null,
    PRC_CUS: null,
    PB_CAR: null,
    PB_INC_START_DT: null,
    PB_INC_FINAL_DT: null
  };
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;

  payableExportDataSource = new MatTableDataSource<IPayable>();
  payableTableDataSource = new MatTableDataSource<IPayable>();
  filteredPayableTableDataSource = new MatTableDataSource<IPayable>();
  payableTableDisplayedColumns: Array<string> = [
    'select',
    'PRC_SER',
    'PRC_HBL',
    'PRC_CUS',
    'PRC_SHIP',
    'PB_CAR',
    'PB_INV_NUM',
    'PB_MBL',
    'PB_BK',
    'PB_CTN',
    'PB_CTN_TYPE',
    'PB_CONT',
    'PB_POL',
    'PB_POD',
    'PB_FRT',
    'PB_CH_DAYS',
    'PB_ST_DT',
    'PB_END_DT',
    'PB_VAL_USD',
    'PB_DIS',
    'PB_VAL_DIS',
    'PB_CH_CODE',
    'actions'
  ];

  carrierList: Array<ICarrier> = [];

  @ViewChild(MatTable) payableTable: MatTable<IPayable>;
  
  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private payableService: PayableService,
    private carrierService: ParameterService,
    private customerService: CustomerService,
    private filterService: FilterService
  ) {
    this.titleService.setTitle('DHL | Payables (Generate P.O.)');
   }

  ngOnInit(): void {
    this.carrierService.getCarrier().subscribe(carriers => this.carrierList = carriers);

    const filter = this.filterService.getFilter('generatePO');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getPayablesGenerate(this.filterEntity);
    });

    this.getPayablesGenerate(this.filterEntity);
  }

  getPayablesGenerate(filter: IPayable): void{
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredPayableTableDataSource.data.length === 0) this.hideLoader = false;
    this.payableService.getGeneratePO({
      ...filter,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((payables) => {
      this.hideLoader = true;
      this.payableTableDataSource.data = payables;
      this.filteredPayableTableDataSource.data = payables;
      this.length = payables[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredPayableTableDataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? (
      this.selection.clear()
    ) : (
      this.filteredPayableTableDataSource.filteredData.forEach((row) => this.selection.select(row))
    );
  } 

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPayablesGenerate(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getPayablesGenerate(this.filterEntity);
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.filterEntity,
        carrierList: this.carrierList
      }
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);
      this.filterEntity = filters;
      this.pageNumber = 0;      
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('generatePO', this.filterEntity);

      this.getPayablesGenerate(this.filterEntity);
    });
  }

  openDiscountDialog(data: IDiscountDialog): void{
    const dialog = this.dialog.open(DiscountDialogComponent, { data });
    dialog.afterClosed().subscribe((discounts: Array<IPayable>)=>{
      if(discounts.length === 0) return;

      this.payableService.discount(discounts).subscribe((response)=>{
        this.toast.success(String(response));
        this.getPayablesGenerate(this.filterEntity);
      },(error)=>{
        this.toast.error(error.error.Message);
      });
    });
  }

  openChargeCodeDialog(data: IChargeCodeDialog): void{
    const dialog = this.dialog.open(ChargeCodeDialogComponent, { data });
    dialog.afterClosed().subscribe((chargeCode: IPayable)=>{
      if(!chargeCode) return;

      this.payableService.chargeCode(chargeCode).subscribe((response)=>{
        this.toast.success(String(response));
        this.getPayablesGenerate(this.filterEntity);
      },(error)=>{
        this.toast.error(error.error.Message);
      });
    });
  }

  openGeneratePoDialog(data: IGeneratePoDialog): void{
    const dialog = this.dialog.open(GeneratePoDialogComponent, { data });

    dialog.afterClosed().subscribe((paymentOrder: IPaymentOrder) => {
      if(!paymentOrder){
        return;
      }

      this.payableService.postPaymentOrder(paymentOrder).subscribe((response) => {
        this.toast.success(String(response));
        this.getPayablesGenerate(this.filterEntity);
      }, (error) =>{
        this.toast.error(error.error.Message);
      });

    });
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  willGeneratePo(data: Array<IPayable>): boolean{    
    let result: boolean = true;
    data.map((value, index, arr)=>{
      if(arr[index].PB_CAR.trim() !== arr[0].PB_CAR.trim()) result = false;
    });    
    return result;
  }

  export(): void {
    const headers = {
      PRC_SER: "SERVICE (FILE INFORMATION)",
      PRC_HBL: "HOUSE BILL (FILE INFORMATION)",
      PRC_CUS: "CUSTOMER (FILE INFORMATION)",
      PRC_SHIP: "SHIPMENT (FILE INFORMATION)",      
      PB_CAR: "CARRIER",
      PB_INV_NUM: "INVOICE NUMBER",
      PB_EMIS_DT_TEXT: "EMISSION DATE",
      PB_EXP_DT_TEXT: "EXPIRATION DATE",
      PB_MBL: "MASTER BILL",
      PB_BK: "BOOKING",
      PB_CTN: "CONTAINER",
      PB_CTN_TYPE: "TYPE",
      PB_CONT: "CONTRACT",
      PB_POL: "ORIGIN PORT",
      PB_POD: "DESTINATION PORT",
      PB_TL_DAYS: "TOTAL DAYS",
      PB_FRT: "FREETIME",
      PB_CH_DAYS: "CHARGE DAYS",
      PB_ST_DT_TEXT: "START DATE",
      PB_END_DT_TEXT: "END DATE",
      PB_VAL_USD: "AMOUNT USD",
      PB_DIS_TEXT: "DISCOUNT USD",
      PB_VAL_DIS: "TOTAL WITH DISCOUNT USD",
      PB_CH_CODE: "CHARGE CODE"
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.payableService.getGeneratePO({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.payableExportDataSource.data = dataExport;

        const data: Array<IPayable> = this.prepareDataToExport([...this.payableExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `generatePO_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    } else {
      const data: Array<IPayable> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `generatePO_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IPayable>): Array<IPayable> {
    const dataToExport: Array<IPayable> = [];

    const datesText = {
      PB_DIS_TEXT: "",
      PB_EMIS_DT_TEXT: "",
      PB_EXP_DT_TEXT: "",
      PB_ST_DT_TEXT: "",
      PB_END_DT_TEXT: "",
    }

    data.forEach((defaultRule) => {
      const newData = {...defaultRule, ...datesText};

      newData.PB_DIS_TEXT = newData.PB_DIS ? (newData.PB_DIS_TYPE === 0) ? `${newData.PB_DIS}%` : `$${newData.PB_DIS}` : '';
      newData.PB_EMIS_DT_TEXT = newData.PB_EMIS_DT && moment(newData.PB_EMIS_DT).format('DD/MM/YYYY');
      newData.PB_EXP_DT_TEXT = newData.PB_EXP_DT && moment(newData.PB_EXP_DT).format('DD/MM/YYYY');
      newData.PB_ST_DT_TEXT = newData.PB_ST_DT && moment(newData.PB_ST_DT).format('DD/MM/YYYY');
      newData.PB_END_DT_TEXT = newData.PB_END_DT && moment(newData.PB_END_DT).format('DD/MM/YYYY');

      delete newData.PB_ID;
      delete newData.PB_EMIS_DT;
      delete newData.PB_EXP_DT;
      delete newData.PB_ST_DT;
      delete newData.PB_END_DT;
      delete newData.PB_DIS;
      delete newData.PB_DIS_TYPE;
      delete newData.PB_USER;
      delete newData.PB_INC_DT;
      delete newData.PB_GEN_PO_INC_DT;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
