import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICustomer } from 'src/app/models/customer.model';
import { IInvoice } from 'src/app/models/invoice.model';
import { ICarrier, IPort } from 'src/app/models/parameter.model';
import { CustomerService } from 'src/app/services/customer.service';
import { ParameterService } from 'src/app/services/parameter.service';

export interface IFIlterDialogData {
  element: IInvoice;
  isPartial: boolean;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;

  customers: Array<ICustomer>;

  carriers: Array<ICarrier>;
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  ports: Array<IPort>;
  filteredOriginPort: Subject<Array<IPort>> = new Subject<Array<IPort>>();
  filteredDestinationPort: Subject<Array<IPort>> = new Subject<Array<IPort>>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFIlterDialogData,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      proforma: new UntypedFormControl(this.data.element?.INVOICE_ID),
      container: new UntypedFormControl(this.data.element?.INVOICE_CTN_NUMBER),
      service: new UntypedFormControl(this.data.element?.INVOICE_TYPE),
      hbl: new UntypedFormControl(this.data.element?.INVOICE_HBL),
      customer: new UntypedFormControl(this.data.element?.INVOICE_CUS?.split(';').map((id)=> Number(id))),
      customerFilter: new UntypedFormControl(''),
      carrier: new UntypedFormControl(this.data.element?.INVOICE_CAR),
      carrierFilter: new UntypedFormControl(''),
      originPort: new UntypedFormControl(this.data.element?.INVOICE_POL),
      originPortFilter: new UntypedFormControl(''),
      destinationPort: new UntypedFormControl(this.data.element?.INVOICE_POD),
      destinationPortFilter: new UntypedFormControl(''),
      status: new UntypedFormControl(this.data.element?.INVOICE_SITUATION_LIST?.split(';')),
      consolType: new UntypedFormControl(this.data.element?.INVOICE_CONSOL_TYPE),
      incoterm: new UntypedFormControl(this.data.element?.INVOICE_INC),
      notification: new UntypedFormControl(this.data.element?.NOTIFICATION_SITUATION),
      stStart: new UntypedFormControl(this.data.element?.INVOICE_ST_START_DT),
      stFinal: new UntypedFormControl(this.data.element?.INVOICE_ST_FINAL_DT),
      endStart: new UntypedFormControl(this.data.element?.INVOICE_END_START_DT),
      endFinal: new UntypedFormControl(this.data.element?.INVOICE_END_FINAL_DT),
      invStart: new UntypedFormControl(this.data.element?.INVOICE_INV_START_DT),
      invFinal: new UntypedFormControl(this.data.element?.INVOICE_INV_FINAL_DT),
      incStart: new UntypedFormControl(this.data.element?.INVOICE_INC_START_DT),
      incFinal: new UntypedFormControl(this.data.element?.INVOICE_INC_FINAL_DT)
    });

    this.getCustomers({ CUS_FILTER: this.form.value.customer?.join('|'), IS_SELECT: 1, CUS_COUNTRY: 'BR' });
    this.getCarriers();
    this.getPorts();

    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    this.form.get('carrierFilter').valueChanges.subscribe(value => this.filterCarriers(value));

    this.form.get('originPortFilter').valueChanges.subscribe(value => this.filterOriginPort(value));

    this.form.get('destinationPortFilter').valueChanges.subscribe(value => this.filterDestinationPort(value));
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getCarriers(): void{
    this.parameterService.getCarrier().subscribe((carriers) => {
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });
  }

  getPorts(): void{
    this.parameterService.getPort().subscribe((ports) => {
      this.ports = ports;
      this.filteredOriginPort.next(ports);
      this.filteredDestinationPort.next(ports);
    });
  }

  filterCustomers(filter: string): void {
    const final = filter ? `${filter}|${this.form.value.customer?.join('|')}` : this.form.value.customer ? `${this.form.value.customer?.join('|')}` : '';
    this.customerService.getCustomer({ CUS_FILTER: final ? final : null, IS_SELECT: 1, CUS_COUNTRY: 'BR' }).subscribe((customers)=>{
      this.customers = customers;
    });
  }
  
  filterCarriers(filter: string): void {
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.RELATIPF_APCPTK.toLowerCase().includes(filter) || carrier.RELATIPF_APA3CD.toLowerCase().includes(filter)));
  }

  filterOriginPort(filter: string): void {
    this.filteredOriginPort.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter)));
  }

  filterDestinationPort(filter: string): void {
    this.filteredDestinationPort.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter)));
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  close(value: IInvoice): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();

    let newInvoice: IInvoice = {
      INVOICE_ID: this.form.value.proforma,
      INVOICE_CTN_NUMBER: this.form.value.container,
      INVOICE_TYPE: this.form.value.service,
      INVOICE_HBL: this.form.value.hbl,
      INVOICE_CUS: this.form.value.customer ? this.form.value.customer.join(';') : null,
      INVOICE_CAR: this.form.value.carrier,
      INVOICE_POL: this.form.value.originPort,
      INVOICE_POD: this.form.value.destinationPort,
      INVOICE_SITUATION_LIST: this.form.value.status ? this.form.value.status.join(';') : null,
      INVOICE_CONSOL_TYPE: this.form.value.consolType,
      INVOICE_INC: this.form.value.incoterm,
      NOTIFICATION_SITUATION: this.form.value.notification,
      INVOICE_ST_START_DT: this.form.value.stStart,
      INVOICE_ST_FINAL_DT: this.form.value.stFinal,
      INVOICE_END_START_DT: this.form.value.endStart,
      INVOICE_END_FINAL_DT: this.form.value.endFinal,
      INVOICE_INV_START_DT: this.form.value.invStart,
      INVOICE_INV_FINAL_DT: this.form.value.invFinal,
      INVOICE_INC_START_DT: this.form.value.incStart,
      INVOICE_INC_FINAL_DT: this.form.value.incFinal
    }
    
    this.close(newInvoice);
  }

  reset(): void {
    this.form.setValue({
      proforma: null,
      container: null,
      service: null,
      hbl: null,
      status: null,
      customer: null,
      customerFilter: '',
      carrier: null,
      carrierFilter: '',
      originPort: null,
      originPortFilter: '',
      destinationPort: null,
      destinationPortFilter: '',
      consolType: null,
      incoterm: null,
      notification: null,
      stStart: null,
      stFinal: null,
      endStart: null,
      endFinal: null,
      invStart: null,
      invFinal: null,
      incStart: null,
      incFinal: null
    });
  }
}