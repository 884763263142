import { OnInit, Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ParameterService } from 'src/app/services/parameter.service';
import { ICountry, IContainerType, IPort, ICarrier, IRegion, ICusNotifParam } from 'src/app/models/parameter.model';
import { HotToastService } from '@ngneat/hot-toast';
import { MatDialog } from '@angular/material/dialog';
import { CountryDialogComponent, ICountryDialog } from './parameters-dialogs/country-dialog/country-dialog.component';
import { ContainerTypeDialogComponent, IContainerTypeDialog } from './parameters-dialogs/container-type-dialog/container-type-dialog.component';
import { PortDialogComponent, IPortDialog } from './parameters-dialogs/port-dialog/port-dialog.component';
import { CarrierDialogComponent, ICarrierDialog } from './parameters-dialogs/carrier-dialog/carrier-dialog.component';
import { RegionDialogComponent, IRegionDialog } from './parameters-dialogs/region-dialog/region-dialog.component';
import { XlsxExporterService } from 'mat-table-exporter';
import { CustomerNotificationDialogComponent, ICustomerNotificationDialog } from './parameters-dialogs/customer-notification-dialog/customer-notification-dialog.component';

@Component({
  selector: 'dhl-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit {
  hideLoaderCountry: boolean;
  hideLoaderContainerType: boolean;
  hideLoaderPort: boolean;
  hideLoaderCarrier: boolean;
  hideLoaderRegion: boolean;
  hideLoaderCustomerNotification: boolean;
  countries: Array<ICountry>;
  regions: Array<IRegion>;
  customerNotification: Array<ICusNotifParam>;

  filterCountryValue: string = '';
  filterContainerTypeValue: string = '';
  filterPortsValue: string = '';
  filterCarrierValue: string = '';
  filterRegionValue: string = '';
  filterCustomerNotificationValue: string = '';

  countryTableDataSource = new MatTableDataSource<ICountry>();
  coutryTableDisplayedColumns: Array<string> = ['COU_CODE', 'COU_DESC', 'REG_NAME_LIST', 'actions'];

  containerTypeTableDataSource = new MatTableDataSource<IContainerType>();
  containerTypeTableDisplayedColumns: Array<string> = ['CONTYPPF_QAMPZ', 'CONTYPPF_QAMQA', 'CONTYPPF_QAMQB', 'actions'];

  portTableDataSource = new MatTableDataSource<IPort>();
  portTableDisplayedColumns: Array<string> = ['DHLPORTS_AIAACD', 'DHLPORTS_AIAOCD', 'DHLPORTS_AIB4TX', 'REG_NAME_LIST', 'actions'];

  carrierTableDataSource = new MatTableDataSource<ICarrier>();
  carrierTableDisplayedColumns: Array<string> = ['RELATIPF_APA3CD', 'RELATIPF_APCPTK', 'actions'];

  regionTableDataSource = new MatTableDataSource<IRegion>();
  regionTableDisplayedColumns: Array<string> = ['REG_NAME', 'REG_DESCRIPTION', 'actions'];

  customerNotificationTableDataSource = new MatTableDataSource<ICusNotifParam>();
  customerNotificationTableDisplayedColumns: Array<string> = ['NOTIF_PARAM_CODE', 'NOTIF_PARAM_NAME', 'NOTIF_PARAM_VALUE', 'actions'];

  constructor(
    private titleService: Title,
    private parameterService: ParameterService,
    private toast: HotToastService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private exporter: XlsxExporterService
  ) {
    this.titleService.setTitle("DHL | Parameters");
  }

  @ViewChild(MatTable) countryTable: MatTable<ICountry>;
  @ViewChild(MatTable) containerTypeTable: MatTable<IContainerType>;
  @ViewChild(MatTable) portTable: MatTable<IPort>;
  @ViewChild(MatTable) carrierTable: MatTable<ICarrier>;
  @ViewChild(MatTable) regionTable: MatTable<IRegion>;
  @ViewChild(MatTable) customerNotificationTable: MatTable<ICusNotifParam>;

  @ViewChild('countrySort') countrySort: MatSort;
  @ViewChild('containerTypeSort') containerTypeSort: MatSort;
  @ViewChild('portSort') portSort: MatSort;
  @ViewChild('carrierSort') carrierSort: MatSort;
  @ViewChild('regionSort') regionSort: MatSort;
  @ViewChild('customerNotificationSort') customerNotificationSort: MatSort;

  @ViewChild('countryPaginator') countryPaginator: MatPaginator;
  @ViewChild('containerTypePaginator') containerTypePaginator: MatPaginator;
  @ViewChild('portPaginator') portPaginator: MatPaginator;
  @ViewChild('carrierPaginator') carrierPaginator: MatPaginator;
  @ViewChild('regionPaginator') regionPaginator: MatPaginator;
  @ViewChild('customerNotificationPaginator') customerNotificationPaginator: MatPaginator;

  ngOnInit(): void {
    this.getCountry();
    this.getContainerType();
    this.getPort();
    this.getCarrier();
    this.getRegion();
    this.getCustomerNotification();
  }

  getCountry(): void {
    if(!this.countryTableDataSource.filteredData) this.hideLoaderCountry = false;
    this.parameterService.getCountryParameter().subscribe((countries: Array<ICountry>) => {
      this.hideLoaderCountry = true;
      this.countries = countries;
      countries.forEach((country) => {
        delete country.COU_USER;
        delete country.COU_STATUS;
      });
      this.countryTableDataSource.data = countries;
      this.changeDetectorRef.detectChanges();
      this.countryTableDataSource.sort = this.countrySort;
      this.countryTableDataSource.paginator = this.countryPaginator;
    }, (error) => {
      this.hideLoaderCountry = true;
      this.toast.error(error.message);
    });
  }

  filterCountryTable(filterValue: string): void {
    this.countryTableDataSource.filter = filterValue;
  }

  openCountryDialog(data: ICountryDialog): void {
    let dialogRef = this.dialog.open(CountryDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: ICountry) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.parameterService.postCountry(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getCountry();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        this.parameterService.putCountry(result).subscribe((response) => {
          if (data.mode == 'edit'){
            this.toast.success(String(response));
          }else{
            this.toast.success('Country removed successfully.');
          }
          this.getCountry();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  exportCountries(): void {
    const headers = {
      COU_CODE: 'CODE',
      COU_DESC: 'DESCRIPTION',
      REG_NAME_LIST: 'REGIONS'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<ICountry> = this.prepareCountriesDataToExport([...this.countryTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `countries_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareCountriesDataToExport(data: Array<ICountry>): Array<ICountry> {
    const dataToExport: Array<ICountry> = [];

    data.forEach((countries) => {
      const newData = {...countries};
      delete newData.COU_ID;
      delete newData.COU_STATUS;
      delete newData.COU_USER;
      delete newData.REG_LIST;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  getContainerType(): void {
    if(!this.containerTypeTableDataSource.filteredData) this.hideLoaderContainerType = false;
    this.parameterService.getContainerTypeParameter().subscribe((containerTypes: Array<IContainerType>) => {
      this.hideLoaderContainerType = true;
      containerTypes.forEach((containerType) => {
        delete containerType.CON_TYPE_USER;
        delete containerType.CON_TYPE_STATUS;
      });
      this.containerTypeTableDataSource = new MatTableDataSource(containerTypes);
      this.changeDetectorRef.detectChanges();
      this.containerTypeTableDataSource.sort = this.containerTypeSort;
      this.containerTypeTableDataSource.paginator = this.containerTypePaginator;
    }, (error) => {
      this.hideLoaderContainerType = true;
      this.toast.error(error.message);
    });
  }

  filterContainerTypeTable(filterValue: string): void {
    this.containerTypeTableDataSource.filter = filterValue;
  }

  openContainerTypeDialog(data: IContainerTypeDialog): void {
    let dialogRef = this.dialog.open(ContainerTypeDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: IContainerType) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.parameterService.postContainerType(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getContainerType();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        this.parameterService.putContainerType(result).subscribe((response) => {
          if (data.mode == 'edit'){
            this.toast.success(String(response));
          }else{
            this.toast.success('Container type removed successfully.');
          }
          this.getContainerType();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  exportContainerTypes(): void {
    const headers = {
      CONTYPPF_QAMPZ:'CODE',
      CONTYPPF_QAMQA:'DESCRIPTION',
      CONTYPPF_QAMQB:'TEUS QUANTITY'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IContainerType> = this.prepareContainerTypesToExport([...this.containerTypeTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `containerTypes_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareContainerTypesToExport(data: Array<IContainerType>): Array<IContainerType> {
    const dataToExport: Array<IContainerType> = [];

    data.forEach((containerTypes) => {
      const newData = {...containerTypes};
      delete newData.DIM_SK_CTN_TYP;
      delete newData.CON_TYPE_STATUS;
      delete newData.CON_TYPE_USER;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  getPort(): void {
    if(!this.portTableDataSource.filteredData) this.hideLoaderPort = false;
    this.parameterService.getPortParameter().subscribe((ports: Array<IPort>) => {
      this.hideLoaderPort = true;
      ports.forEach((port) => {
        delete port.PORT_USER;
        delete port.PORT_STATUS;
      });
      this.portTableDataSource = new MatTableDataSource(ports);
      this.changeDetectorRef.detectChanges();
      this.portTableDataSource.sort = this.portSort;
      this.portTableDataSource.paginator = this.portPaginator;
    }, (error) => {
      this.hideLoaderPort = true;
      this.toast.error(error.message);
    });
  }

  filterPortTable(filterValue: string): void {
    this.portTableDataSource.filter = filterValue;
  }

  openPortDialog(data: IPortDialog): void {
    let dialogRef = this.dialog.open(PortDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: IPort) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.parameterService.postPort(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getPort();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        this.parameterService.putPort(result).subscribe((response) => {
          if (data.mode == 'edit') {
            this.toast.success(String(response));
          } else {
            this.toast.success('Port removed successfully.');
          }
          this.getPort();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  exportPorts(): void {
    const headers = {
      DHLPORTS_AIAACD:'COUNTRY CODE',
      DHLPORTS_AIAOCD:'PORT COUNTRY CODE',
      DHLPORTS_AIB4TX:'PORT COUNTRY DESCRIPTION',
      REG_NAME_LIST:'REGIONS'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IPort> = this.preparePortsDataToExport([...this.portTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `ports_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  preparePortsDataToExport(data: Array<IPort>): Array<IPort> {
    const dataToExport: Array<IPort> = [];

    data.forEach((port) => {
      const newData = {...port};
      delete newData.DIM_SK_PORT;
      delete newData.PORT_STATUS;
      delete newData.PORT_USER;
      delete newData.REG_LIST;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  getCarrier() {
    if (!this.carrierTableDataSource.filteredData) this.hideLoaderCarrier = false;
    this.parameterService.getCarrierParameter().subscribe((carriers: Array<ICarrier>) => {
      this.hideLoaderCarrier = true;
      carriers.forEach((carrier) => {
        delete carrier.CRR_USER;
        delete carrier.CRR_STATUS;
      });
      this.carrierTableDataSource.data = carriers;
      this.changeDetectorRef.detectChanges();
      this.carrierTableDataSource.sort = this.carrierSort;
      this.carrierTableDataSource.paginator = this.carrierPaginator;
    }, (error) => {
      this.hideLoaderCarrier = true;
      this.toast.error(error.message);
    });
  }

  filterCarrierTable(filterValue: string) {
    this.carrierTableDataSource.filter = filterValue;
  }

  openCarrierDialog(data: ICarrierDialog) {
    let dialogRef = this.dialog.open(CarrierDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: ICarrier) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.parameterService.postCarrier(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getCarrier();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        this.parameterService.putCarrier(result).subscribe((response) => {
          if (data.mode == 'edit'){
            this.toast.success(String(response));
          }else{
            this.toast.success('Carrier removed successfully.');
          }
          this.getCarrier();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  exportCarriers(): void {
    const headers = {
      RELATIPF_APA3CD: 'ACCOUNT',
      RELATIPF_APCPTK: 'NAME'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<ICarrier> = this.prepareCarriersDataToExport([...this.carrierTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `carriers_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareCarriersDataToExport(data: Array<ICarrier>): Array<ICarrier> {
    const dataToExport: Array<ICarrier> = [];

    data.forEach((carrier) => {
      const newData = {...carrier};
      delete newData.DIM_SK_CRR;
      delete newData.CRR_STATUS;
      delete newData.CRR_USER;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  getRegion() {
    if (!this.regionTableDataSource.filteredData) this.hideLoaderRegion = false;
    this.parameterService.getRegionParameter().subscribe((regions: Array<IRegion>) => {
      this.hideLoaderRegion = true;
      this.regions = regions;
      regions.forEach((region) => {
        delete region.REG_USER;
        delete region.REG_STATUS;
      });
      this.regionTableDataSource.data = regions;
      this.changeDetectorRef.detectChanges();
      this.regionTableDataSource.sort = this.regionSort;
      this.regionTableDataSource.paginator = this.regionPaginator;
    }, (error) => {
      this.hideLoaderRegion = true;
      this.toast.error(error.message);
    });
  }

  filterRegionTable(filterValue: string) {
    this.regionTableDataSource.filter = filterValue;
  }

  openRegionDialog(data: IRegionDialog) {
    let dialogRef = this.dialog.open(RegionDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: IRegion) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.parameterService.postRegion(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getRegion();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        this.parameterService.putRegion(result).subscribe((response) => {
          if (data.mode == 'edit'){
            this.toast.success(String(response));
          }else{
            this.toast.success('Region removed successfully.');
          }
          this.getRegion();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  exportRegions(): void {
    const headers = {
      REG_NAME: 'NAME',
      REG_DESCRIPTION: 'DESCRIPTION'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IRegion> = this.prepareRegionsDataToExport([...this.regionTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `regions_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareRegionsDataToExport(data: Array<IRegion>): Array<IRegion> {
    const dataToExport: Array<IRegion> = [];

    data.forEach((region) => {
      const newData = {...region};
      delete newData.REG_ID;
      delete newData.REG_STATUS;
      delete newData.REG_USER;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  getCustomerNotification(): void {
    if(!this.customerNotificationTableDataSource.filteredData) this.hideLoaderCountry = false;
    this.parameterService.getCustomerNotification().subscribe((notificationParameters: Array<ICusNotifParam>) => {
      this.hideLoaderCountry = true;
      this.customerNotification = notificationParameters;      
      this.customerNotificationTableDataSource.data = notificationParameters;
      this.changeDetectorRef.detectChanges();
      this.customerNotificationTableDataSource.sort = this.customerNotificationSort;
      this.customerNotificationTableDataSource.paginator = this.customerNotificationPaginator;
    }, (error) => {
      this.hideLoaderCountry = true;
      this.toast.error(error.message);
    });
  }

  filterCustomerNotificationTable(filterValue: string): void {
    this.customerNotificationTableDataSource.filter = filterValue;
  }

  openCustomerNotificationDialog(data: ICustomerNotificationDialog): void {
    let dialogRef = this.dialog.open(CustomerNotificationDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: ICusNotifParam) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.parameterService.postCustomerNotification(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getCustomerNotification();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        if (data.mode == 'edit'){
          this.parameterService.putCustomerNotification(result).subscribe((response) => {
            this.toast.success(String(response));
            this.getCustomerNotification();
          }, (error) => {
            this.toast.error(error.error.Message);
          });          
        }else{
          this.parameterService.deleteCustomerNotification(result).subscribe((response) => {
            this.toast.success(String(response));
            this.getCustomerNotification();
          }, (error) => {
            this.toast.error(error.error.Message);
          });
        }        
      }
    });
  }

  exportCustomerNotifications(): void {
    const headers = {
      NOTIF_PARAM_CODE: 'CODE',
      NOTIF_PARAM_NAME: 'NAME',
      NOTIF_PARAM_VALUE: 'VALUE'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<ICusNotifParam> = this.prepareCustomerNotificationDataToExport([...this.customerNotificationTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `customerNotificationParameters_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareCustomerNotificationDataToExport(data: Array<ICusNotifParam>): Array<ICusNotifParam> {
    const dataToExport: Array<ICusNotifParam> = [];

    data.forEach((customerNotification) => {
      const newData = {...customerNotification};
      delete newData.NOTIF_PARAM_ID;
      delete newData.NOTIF_PARAM_STATUS;
      delete newData.NOTIF_PARAM_USER;
      delete newData.NOTIF_PARAM_INC_DATE;
      delete newData.NOTIF_PARAM_DATETIME;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }
}
