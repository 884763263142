import { ShipmentService } from 'src/app/services/shipment.service';

import { FilterService } from 'src/app/services/filter.service';
import { ActivatedRoute } from '@angular/router';
import { IProcessContainer } from 'src/app/models/process-container.model';
import { ProcessContainerService } from 'src/app/services/process-container.service';
import { formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatTableFilter } from 'mat-table-filter';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { InvoiceService } from 'src/app/services/invoice.service';
import moment from 'moment';
import { FilterDialogComponent } from './process-container-dialogs/filter-dialog/filter-dialog.component';
import { IContainer } from 'src/app/models/shipment.model';
import { IComment, ICommentRef } from 'src/app/models/comment.model';
import { CommentService } from 'src/app/services/comment.service';
import { IProcessCustomDateDialog, ProcessCtnEndReferenceDialogComponent } from './process-container-dialogs/process-ctn-end-reference-dialog/process-ctn-end-reference-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-process-container',
  templateUrl: './process-container.component.html',
  styleUrls: ['./process-container.component.scss']
})
export class ProcessContainerComponent implements OnInit {
  pageKey = '';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IProcessContainer>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl('');

  filterEntity: IProcessContainer = {
    PROCESS_AGREEMENT: null,
    PROCESS_CUS: null,
    PROCESS_HBL: null,
    PROCESS_CTN: null,
    PROCESS_CTN_TYPE: null,
    PROCESS_CAR: null,
    PROCESS_POL: null,
    PROCESS_POD: null,
    PROCESS_CH_ST: null,
    PROCESS_IS_PARTIAL: null,
    PROCESS_ATA_START_DT: null,
    PROCESS_ATA_FINAL_DT: null,
    PROCESS_GOU_START_DT: null,
    PROCESS_GOU_FINAL_DT: null,
    PROCESS_GIN_START_DT: null,
    PROCESS_GIN_FINAL_DT: null,
    PROCESS_LDG_START_DT: null,
    PROCESS_LDG_FINAL_DT: null,
    PROCESS_EXC_START_DT: null,
    PROCESS_EXC_FINAL_DT: null,
    PROCESS_INC_START_DT: null,
    PROCESS_INC_FINAL_DT: null,
  };
  filtersNumber: number = 0;
  destiny: string;
  isPayable: boolean = false;

  processContainerExportDataSource = new MatTableDataSource<IProcessContainer>();
  processContainerTableDataSource = new MatTableDataSource<IProcessContainer>();
  filteredProcessContainerTableDataSource = new MatTableDataSource<IProcessContainer>();
  processContainerTableDisplayedColumns: Array<string> = [
    'select',
    'PROCESS_CUS',
    'PROCESS_HBL',
    'SHIP_DPT_CODE',
    'PROCESS_CTN',
    'PROCESS_CTN_TYPE',
    'PROCESS_CAR',
    'PROCESS_MBL',
    'PROCESS_VES',
    'PROCESS_VOY',
    'PROCESS_POL',
    'PROCESS_POD',
    'PROCESS_ATD',
    'PROCESS_ARR_DATE',
    'PROCESS_GOU',
    'PROCESS_GIN_GOU',
    'PROCESS_GIN',
    'PROCESS_LDG_GIN',
    'PROCESS_LDG',
    'PROCESS_CUSTOM_DATE',
    'PROCESS_FRT',
    'PROCESS_DEMU_DAYS',
    'PROCESS_PERDIEM',
    'PROCESS_AMT',
    'PROCESS_ORIGIN_AMT',
    'PF_AMT',
    'PROCESS_AGREEMENT',
    'PROCESS_INC_DATETIME',
    'PROCESS_EXC_DESC',
    'PROCESS_IS_PARTIAL',
    'END_REFERENCE',
    'actions'
  ];

  @ViewChild(MatTable) processContainerTable: MatTable<IProcessContainer>;

  lastShipId: number;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private processContainerService: ProcessContainerService,
    private commentService: CommentService,
    private invoiceService: InvoiceService,
    private shipmentService: ShipmentService,
    private authService: AuthService,
    private filterService: FilterService,
    private titlecasePipe:TitleCasePipe
  ) {
    
  }

  ngOnInit(): void {
    this.route.url.subscribe((route) => {
      this.destiny = route[0].path;
      
      if (this.destiny === 'demurrage') {
        this.pageKey = 'demurrageGenerate';
      } else if (this.destiny === 'detention') {
        this.pageKey = 'detentionGenerate';
      }

      this.titleService.setTitle(`DHL | ${this.titlecasePipe.transform(this.destiny)} (Generate Invoice)`);

      this.globalFilter.setValue(this.filterService.getSearch(this.pageKey) || '');
    });

    const filter = this.filterService.getFilter('generateInvoice');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getProcessContainer(this.filterEntity);
    });

    this.getProcessContainer(this.filterEntity);
    this.checkDestiny();
  }

  getProcessContainer(filter: IProcessContainer): void{
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredProcessContainerTableDataSource.data.length === 0) this.hideLoader = false;
    this.processContainerService.getProcessContainer({
      ...filter,
      PROCESS_DESTINY: this.destiny,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((processContainer: Array<IProcessContainer>) => {
      this.hideLoader = true;
      this.processContainerTableDataSource.data = processContainer;
      this.filteredProcessContainerTableDataSource.data = processContainer;
      this.length = processContainer[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredProcessContainerTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.filteredProcessContainerTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  checkDestiny(): void{
    if (this.destiny === 'demurrage'){
      const list: Array<string> = [
        'PROCESS_GIN_GOU',
        'PROCESS_GIN',
        'PROCESS_LDG_GIN',
        'PROCESS_LDG',
        'PROCESS_CUSTOM_DATE',
        'PROCESS_MBL',
        'PROCESS_VES',
        'PROCESS_VOY',
        'END_REFERENCE'
      ];

      list.forEach(item =>{
        const index = this.processContainerTableDisplayedColumns.indexOf(item);
        this.processContainerTableDisplayedColumns.splice(index, 1);
      });
    }else{
      const list: Array<string> = [
        'PROCESS_ATD',
        'PROCESS_ARR_DATE'
      ];

      list.forEach(item =>{
        const index = this.processContainerTableDisplayedColumns.indexOf(item);
        this.processContainerTableDisplayedColumns.splice(index, 1);
      });
    }
  }

  process(container: IProcessContainer | Array<IProcessContainer>) {
    let newContainers: Array<IProcessContainer> = [];

    if (container instanceof Array) {
      newContainers = container.map((c) => {
        return {...c, PROCESS_USER: this.authService.userId}
      });
    } else {
      newContainers.push({...container, PROCESS_USER: this.authService.userId});
    }

    this.invoiceService.processInvoice(newContainers).subscribe((response) => {
      this.toast.success(String(response));
      this.getProcessContainer(this.filterEntity);
    }, (error) => {
      this.toast.error(error.error.Message);
    });
  }

  processPartial(container: IProcessContainer | Array<IProcessContainer>) {
    let newContainers: Array<IProcessContainer> = [];

    if (container instanceof Array) {
      newContainers = container.map((c) => {
        return {...c, PROCESS_USER: this.authService.userId}
      });
    } else {
      newContainers.push({...container, PROCESS_USER: this.authService.userId});
    }

    this.invoiceService.processPartialInvoice(newContainers).subscribe((response) => {
      this.toast.success(String(response));
      this.getProcessContainer(this.filterEntity);
    }, (error) => {
      this.toast.error(error.error.Message);
    });
  }

  export(): void {
    const headers = {
      PROCESS_CUS:'CUSTOMER',
      PROCESS_HBL:'HOUSE BILL',
      SHIP_DPT_CODE:'DEPT',
      PROCESS_SHIP_CODE:'SHIPMENT',
      PROCESS_CTN:'CONTAINER',
      PROCESS_CTN_TYPE:'TYPE',
      PROCESS_CAR:'CARRIER',
      PROCESS_MBL:'MASTER BILL',
      PROCESS_VES:'VESSEL',
      PROCESS_VOY:'VOYAGE',
      PROCESS_POL:'ORIGIN PORT',
      PROCESS_POD:'DESTINATION PORT',
      PROCESS_ATD_TEXT:'LOADING',
      PROCESS_ATA_TEXT:'ARRIVAL',
      PROCESS_GOU_TEXT: this.destiny === 'demurrage' ? 'EMPTY RETURN' : 'GATE OUT',
      PROCESS_GIN_GOU: 'DAYS',
      PROCESS_GIN_TEXT:'GATE IN',
      PROCESS_LDG_GIN:'DAYS',
      PROCESS_CUSTOM_DATE_TEXT:'END REFERENCE',
      PROCESS_LDG_TEXT:'LOADING',
      PROCESS_ARR_DATE_TEXT:  'START CHARGE',
      PROCESS_RET_DATE_TEXT: 'END CHARGE',
      PROCESS_FRT:'FREE TIME',
      PROCESS_DEMU_DAYS:'CHARGE DAYS',
      PROCESS_PERDIEM: 'PERDIEM',
      PROCESS_AMT:'AMOUNT USD',
      PROCESS_ORIGIN_AMT:'ORIGIN STATION',
      PF_AMT:'PAYABLES FORECAST',
      PROCESS_AGREEMENT:'DDM AGREEMENT',
      PROCESS_INC_DATETIME_TEXT:'INCLUSION DATE',
      PROCESS_EXC_DESC:'EXCEPTION DESCRIPTION',
      PROCESS_EXC_DATE_TEXT:'EXCEPTION DATE',
      PROCESS_PRTL_TEXT:'IS PARTIAL'
    }

    if (this.destiny === 'demurrage'){
      const list: Array<string> = [
        'PROCESS_GIN_GOU',
        'PROCESS_GIN_TEXT',
        'PROCESS_LDG_GIN',
        'PROCESS_LDG_TEXT',
        'PROCESS_CUSTOM_DATE_TEXT',
        'PROCESS_MBL',
        'PROCESS_VES',
        'PROCESS_VOY',
        'PROCESS_EXC_DESC',
        'PROCESS_EXC_DATE_TEXT',
        'PROCESS_ARR_DATE_TEXT',
        'PROCESS_RET_DATE_TEXT'
      ];

      list.forEach(item =>{
        delete headers[item];
      });
    }else{
      const list: Array<string> = [
        'PROCESS_ATD_TEXT',
        'PROCESS_ATA_TEXT'
      ];

      list.forEach(item =>{
        delete headers[item];
      });
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.processContainerService.getProcessContainer({
          ...this.filterEntity,
          EXPORT: 1,
          PROCESS_DESTINY: this.destiny,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.processContainerExportDataSource.data = dataExport;

        const data: Array<IProcessContainer> = this.prepareDataToExport([...this.processContainerExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `${this.destiny}GenerateInvoices_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    } else {
      const data: Array<IProcessContainer> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `${this.destiny}GenerateInvoices_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IProcessContainer>): Array<IProcessContainer> {
    const dataToExport: Array<IProcessContainer> = [];

    const datesText = {
      PROCESS_GOU_TEXT: '',
      PROCESS_GIN_TEXT: '',
      PROCESS_LDG_TEXT: '',
      PROCESS_CUSTOM_DATE_TEXT: '',
      PROCESS_ATD_TEXT: '',
      PROCESS_ATA_TEXT: '',
      PROCESS_INC_DATETIME_TEXT: '',
      PROCESS_EXC_DATE_TEXT: '',
      PROCESS_ARR_DATE_TEXT: '',
      PROCESS_RET_DATE_TEXT: '',
      PROCESS_PRTL_TEXT: ''
    }

    data.forEach((processContainer) => {
      const newData = {...processContainer, ...datesText};

      newData.PROCESS_GOU_TEXT = newData.PROCESS_GOU && moment(newData.PROCESS_GOU).format('DD/MM/YYYY');
      newData.PROCESS_GIN_TEXT = newData.PROCESS_GIN && moment(newData.PROCESS_GIN).format('DD/MM/YYYY');
      newData.PROCESS_LDG_TEXT = newData.PROCESS_LDG && moment(newData.PROCESS_LDG).format('DD/MM/YYYY');
      newData.PROCESS_CUSTOM_DATE_TEXT = newData.PROCESS_CUSTOM_DATE ? moment(newData.PROCESS_CUSTOM_DATE).format('DD/MM/YYYY') : moment(newData.PROCESS_GIN).format('DD/MM/YYYY');
      newData.PROCESS_ATD_TEXT = newData.PROCESS_ATD && moment(newData.PROCESS_ATD).format('DD/MM/YYYY');
      newData.PROCESS_ATA_TEXT = newData.PROCESS_ARR_DATE && moment(newData.PROCESS_ARR_DATE).format('DD/MM/YYYY');
      newData.PROCESS_INC_DATETIME_TEXT = newData.PROCESS_INC_DATETIME && moment(newData.PROCESS_INC_DATETIME).format('DD/MM/YYYY');
      newData.PROCESS_EXC_DATE_TEXT = newData.PROCESS_EXC_DATE && moment(newData.PROCESS_EXC_DATE).format('DD/MM/YYYY');
      newData.PROCESS_ARR_DATE_TEXT = newData.PROCESS_ARR_DATE && moment(newData.PROCESS_ARR_DATE).format('DD/MM/YYYY');
      newData.PROCESS_RET_DATE_TEXT = newData.PROCESS_RET_DATE && moment(newData.PROCESS_RET_DATE).format('DD/MM/YYYY');
      newData.PROCESS_PRTL_TEXT = newData.PROCESS_IS_PARTIAL === '1' ? 'YES' : 'NO';

      delete newData.PROCESS_GOU;
      delete newData.PROCESS_GIN;
      delete newData.PROCESS_CUSTOM_DATE;
      delete newData.PROCESS_LDG;
      delete newData.PROCESS_ATD;
      delete newData.PROCESS_ATA;
      delete newData.PROCESS_INC_DATETIME;
      delete newData.PROCESS_EXC_DATE;
      delete newData.PROCESS_ID;
      delete newData.PROCESS_SHIP;
      delete newData.PROCESS_TOTAL_CTN;
      delete newData.PROCESS_ARR_DATE;
      delete newData.PROCESS_RET_DATE;
      delete newData.PROCESS_STATUS;
      delete newData.PROCESS_DIS;
      delete newData.PROCESS_DIS_AMT;
      delete newData.PROCESS_CH_ST;
      delete newData.PROCESS_CTN_ID;
      delete newData.PROCESS_DUP_MBL;
      delete newData.PROCESS_PRTL_CTN_ID;
      delete newData.PROCESS_IS_PARTIAL;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;

      if (this.destiny === 'demurrage'){
        delete newData.PROCESS_GIN_TEXT;
        delete newData.PROCESS_CUSTOM_DATE_TEXT;
        delete newData.PROCESS_LDG_TEXT;
        delete newData.PROCESS_ARR_DATE_TEXT;
        delete newData.PROCESS_RET_DATE_TEXT;
        delete newData.PROCESS_MBL;
        delete newData.PROCESS_VES;
        delete newData.PROCESS_VOY;
        delete newData.PROCESS_EXC_DESC;
        delete newData.PROCESS_EXC_DATE_TEXT;
      }else{
        delete newData.PROCESS_ATD_TEXT;
        delete newData.PROCESS_ATA_TEXT;
      }

      dataToExport.push(newData);
    });

    return dataToExport;
  }


  recalculate(container: IProcessContainer | Array<IProcessContainer>) {
    let newContainers: Array<IProcessContainer> = [];

    if (container instanceof Array) {
      newContainers = container.map((c) => {
        return {...c, PROCESS_USER: this.authService.userId}
      });
    } else {
      newContainers.push({...container, PROCESS_USER: this.authService.userId});
    }

    console.log(newContainers)

    this.invoiceService.recalculateContainer(newContainers).subscribe((response) => {
      this.toast.success(String(response));
      this.getProcessContainer(this.filterEntity);
    }, (error) => {
      this.toast.error(error.error.Message);
    });
  }

  showSeparatorRow(index: number, element: IProcessContainer) {    
    if (index === 0) {
      this.lastShipId = element.PROCESS_SHIP;
      return false;
    }

    if (this.lastShipId === null || this.lastShipId === undefined) {
      this.lastShipId = element.PROCESS_SHIP;
      return false;
    }

    if (this.lastShipId === element.PROCESS_SHIP) {
      this.lastShipId = element.PROCESS_SHIP;
      return false;
    }

    this.lastShipId = element.PROCESS_SHIP;
    return true;
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getProcessContainer(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getProcessContainer(this.filterEntity);
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.filterEntity,
        destiny: this.destiny
      }
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filterEntity = filters
      this.filtersNumber = this.filterService.getFilterQuantity(filters);
      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('generateInvoice', this.filterEntity);

      this.getProcessContainer(this.filterEntity);
    });
  }

  openCustomDateDialog(data: IProcessCustomDateDialog): void {
    const dialogRef = this.dialog.open(ProcessCtnEndReferenceDialogComponent, { data });

    dialogRef.afterClosed().subscribe(([endReference, comment]:[IProcessContainer, string]) => {
      if (!endReference) return;

      const commentRefs: Array<ICommentRef> = [];

      commentRefs.push({
        REG_ID: endReference.PROCESS_SHIP,
        COMM_REF_GROUP: 'shipment',
        COMM_REF_USER: this.authService.userId
      })

      const newComment: IComment = {
        COMM_MESSAGE: comment,
        COMM_USER: this.authService.userId,
        COMM_REF: commentRefs
      }

      const newContainer: IContainer = {
        SHIP_ID: endReference.PROCESS_SHIP,
        CTN_ID: endReference.PROCESS_CTN_ID,
        PRTL_CTN_ID: endReference.PROCESS_PRTL_CTN_ID,
        CTN_NUMBER: endReference.PROCESS_CTN,
        CTN_GOU: endReference.PROCESS_GOU,
        CTN_GIN: endReference.PROCESS_GIN,
        CTN_LDG: endReference.PROCESS_LDG,
        CTN_END_REFERENCE: endReference.PROCESS_END_REFERENCE,
        CTN_USER: this.authService.userId
      }

      this.shipmentService.putEndReferenceContainer(newContainer).subscribe((response) => {
        this.toast.success(String(response));

        if(comment !== '') {
          this.commentService.postComment(newComment).subscribe((response) => {
            this.toast.success(String(response));
          }, (error) => {
            this.toast.error(error.error.Message);
          });
        }

        this.recalculate(endReference);

        this.getProcessContainer(this.filterEntity);

      }, (error) => {
        this.toast.error(error.error.Message);
      });

    });
  }

  canProcessContainers(processes: IProcessContainer[]): boolean{
    return processes.every(item => item.PROCESS_IS_PARTIAL === '0');
  }
}
