import { IUserList } from '../../models/user-list.model';
import { UserDeleteAllDialogComponent } from './users-dialogs/user-delete-all-dialog/user-delete-all-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { HotToastService } from '@ngneat/hot-toast';
import { MatTableFilter } from 'mat-table-filter';
import { IUser } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { IUserDialog, UsersDialogComponent } from './users-dialogs/users-dialog/users-dialog.component';
import { IUserFilterDialog, UsersFilterDialogComponent } from './users-dialogs/users-filter-dialog/users-filter-dialog.component';
import { UserViewDialogComponent } from './users-dialogs/users-view-dialog/users-view-dialog.component';
import { XlsxExporterService } from 'mat-table-exporter';
import { FilterService } from 'src/app/services/filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dhl-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  pageKey = 'users';
  hideLoader: boolean;

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IUser;
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IUser>(this.allowMultiSelect, this.initialSelection);

  userTableDataSource = new MatTableDataSource<IUser>();
  filteredUserTableDataSource = new MatTableDataSource<IUser>();
  userTableDisplayedColumns: Array<string> = ['select', 'USER_NAME', 'USER_IS_ADMIN', 'USER_IS_ACTIVE', 'groups', 'actions'];

  @ViewChild(MatTable) userTable: MatTable<IUser>;
  @ViewChild('userSort') userSort: MatSort;
  @ViewChild('userPaginator') userPaginator: MatPaginator;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private filterService: FilterService,
    private authService: AuthService
  ) {
    this.titleService.setTitle('DHL | Users');
  }

  ngOnInit(): void {
    this.getUser();

    this.filterEntity = {
      PI_USER_NAME: '',
      PI_USER_IS_ADMIN: '',
      PI_USER_IS_ACTIVE: ''
    }

    const filter = this.filterService.getFilter('user');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.filterUserTable(value);
    });
  }

  getUser(): void {
    if(!this.userTableDataSource.filteredData) this.hideLoader = false;
    this.userService.getUser({}).subscribe((users: Array<IUser>) => {
      this.hideLoader = true;
      this.userTableDataSource.data = users;
      this.filteredUserTableDataSource.data = users;
      this.changeDetectorRef.detectChanges();
      this.filteredUserTableDataSource.sort = this.userSort;
      this.filteredUserTableDataSource.paginator = this.userPaginator;

      this.filterUserTable(this.globalFilter.value);
    });
  }

  filterUserTable(filter: string): void {
    this.userTableDataSource.filter = filter;
    this.filteredUserTableDataSource.data = this.userTableDataSource.filteredData;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredUserTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.filteredUserTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  openUserDialog(data: IUserDialog): void {
    const dialogRef = this.dialog.open(UsersDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((user: IUser) => {
      if (!user) return;

      if (data.mode === 'add') {
        this.userService.postUser(user).subscribe((response) => {
          this.toast.success(String(response));
          this.getUser();
        }, (error) => {
          this.toast.error(String(error));
        });
      } else if (data.mode === 'edit') {
        this.userService.putUser(user).subscribe((response) => {
          this.toast.success(String(response));
          this.getUser();
        }, (error) => {
          this.toast.error(String(error));
        });
      } else if (data.mode === 'deactivate') {
        this.userService.putUser(user).subscribe(() => {
          this.toast.success('User deactivated successfully.');
          this.getUser();
        }, (error) => {
          this.toast.error(String(error));
        });
      } else if (data.mode === 'activate') {
        this.userService.putUser(user).subscribe(() => {
          this.toast.success('User activated successfully.');
          this.getUser();
        }, (error) => {
          this.toast.error(String(error));
        });
      }
    });
  }

  openUserViewDialog(data: IUser): void{
    this.dialog.open(UserViewDialogComponent, {
      data: data
    });
  }

  setFilters(): void {
    const dialogRef = this.dialog.open(UsersFilterDialogComponent, {
      data: {
        userName: this.filterEntity.PI_USER_NAME,
        isAdmin: this.filterEntity.PI_USER_IS_ADMIN,
        isActive: this.filterEntity.PI_USER_IS_ACTIVE
      }
    });

    dialogRef.afterClosed().subscribe((filters: IUserFilterDialog) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity.PI_USER_NAME = filters.userName;
      this.filterEntity.PI_USER_IS_ADMIN = filters.isAdmin;
      this.filterEntity.PI_USER_IS_ACTIVE = filters.isActive;

      this.filterService.setFilter('user', this.filterEntity);
    });
  }

  deleteSelected(): void {
    const dialogRef = this.dialog.open(UserDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {
        let usersIds: Array<string> = [];
        this.selection.selected.forEach((user) => {
          usersIds.push(user.PI_USER_ID);
        });

        let userList: IUserList = {
          LIST_USER_ID: usersIds,
          USER: this.authService.userId
        }

        this.userService.deleteSelectedUsers(userList).subscribe((response) => {
          this.getUser();
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  export(): void {
    const headers = {
      USER_ID: 'USER_ID',
      USER_NAME: 'USER_NAME',
      USER_IS_ADMIN: 'IS ADMIN',
      USER_IS_ACTIVE: 'IS ACTIVE',
      GRP_NAME_LIST: 'GROUPS'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<IUser> = this.prepareDataToExport([...this.userTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<IUser> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `users_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<IUser>): Array<IUser> {
    const dataToExport: Array<IUser> = [];

    data.forEach((user) => {
      const newData = {...user};
      delete newData.PI_USER_ID;
      delete newData.PI_USER_NAME;
      delete newData.PI_USER_IS_ADMIN;
      delete newData.PI_USER_IS_ACTIVE;
      delete newData.PI_USER_PASS;
      delete newData.PI_O_ERROR;
      delete newData.PI_TOKEN;
      delete newData.GRP_LIST;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
