import { IFinancial, IFinancialMonth, IFinancialYear, IFinancialRate, IFinancialBudget, IFinancialCustomer, IFinancialTopCustomer } from './../models/financial.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Json } from 'aws-sdk/clients/robomaker';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  //-----------------------Financial Dashboard-----------------------
  getYear(filter: IFinancialYear): Observable<Array<IFinancialYear>> {
    return this.httpClient.get<Array<IFinancialYear>>(`${this.apiURL}/api/financial/dashboard/year?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  getMonth(filter: IFinancialMonth): Observable<Array<IFinancialMonth>> {
    return this.httpClient.get<Array<IFinancialMonth>>(`${this.apiURL}/api/financial/dashboard/month?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  getReportYear(): Observable<Array<IFinancialYear>>{
    return this.httpClient.get<Array<IFinancialYear>>(`${this.apiURL}/api/financial/report/years`, this.httpOptions);
  }

  //-----------------------Financial Customers Dashboard-----------------------
  getCustomersYear(filter: IFinancialCustomer): Observable<Array<IFinancialCustomer>> {
    return this.httpClient.get<Array<IFinancialCustomer>>(`${this.apiURL}/api/financial/dashboard/customer/month?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  getCustomers(filter: IFinancialCustomer): Observable<Array<IFinancialCustomer>> {
    return this.httpClient.get<Array<IFinancialCustomer>>(`${this.apiURL}/api/financial/dashboard/customer?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  getTopCustomers(filter: IFinancialTopCustomer): Observable<Array<IFinancialTopCustomer>> {
    return this.httpClient.get<Array<IFinancialTopCustomer>>(`${this.apiURL}/api/financial/dashboard/customer/top?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  //-----------------------Financial-----------------------
  get(filter: IFinancial): Observable<Array<IFinancial>> {
    return this.httpClient.get<Array<IFinancial>>(`${this.apiURL}/api/financial?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  getFilter(filter: IFinancial): Observable<Array<IFinancial>> {
    return this.httpClient.get<Array<IFinancial>>(`${this.apiURL}/api/financial/filter?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  getFinancialYears(): Observable<Array<IFinancial>>{
    return this.httpClient.get<Array<IFinancial>>(`${this.apiURL}/api/financial/years`, this.httpOptions);
  }

  post(financials: Array<IFinancial>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/financials`, financials, this.httpOptions);
  }

  //-----------------------Rate-----------------------
  postRate(rate: IFinancialRate): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/financial/rate?filter=${JSON.stringify(rate)}`, null, this.httpOptions);
  }

  putRate(rate: IFinancialRate): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/financial/rate?filter=${JSON.stringify(rate)}`, null, this.httpOptions);
  }

  getRate(filter: IFinancialRate): Observable<IFinancialRate> {
    return this.httpClient.get<IFinancialRate>(`${this.apiURL}/api/financial/rate?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  //-----------------------Budget-----------------------
  postBudget(budgets: Array<IFinancialBudget>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/financial/budget?filter=${JSON.stringify(budgets)}`, null, this.httpOptions);
  }

  putBudget(budgets: Array<IFinancialBudget>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/financial/budget?filter=${JSON.stringify(budgets)}`, null, this.httpOptions);
  }

  getBudget(filter: IFinancialBudget): Observable<Array<IFinancialBudget>> {
    return this.httpClient.get<Array<IFinancialBudget>>(`${this.apiURL}/api/financial/budget?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };

  //-----------------------Container Handling-----------------------
  putContainer(container: Array<IFinancialYear>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/financial/report/containers?filter=${JSON.stringify(container)}`, null, this.httpOptions);
  }

  getContainer(filter: IFinancialYear): Observable<Array<IFinancialYear>> {
    return this.httpClient.get<Array<IFinancialYear>>(`${this.apiURL}/api/financial/report/containers?filter=${JSON.stringify(filter)}`, this.httpOptions);
  };
}
