import { IJoinGroupRelPage } from 'src/app/models/join-group-rel-page.model';
import { PageService } from 'src/app/services/page.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPage } from 'src/app/models/page.model';
import { IUserGroup } from 'src/app/models/user-group.model';

export interface IPagesViewDialog {
  title?: string;
  page?: IPage;
}

@Component({
  selector: 'dhl-pages-view-dialog',
  templateUrl: './pages-view-dialog.component.html',
  styleUrls: ['./pages-view-dialog.component.scss']
})
export class PagesViewDialogComponent implements OnInit {

  pageGroupsTableDataSource = new MatTableDataSource<IUserGroup>();
  hasData: boolean = true;
  pageGroupsTableDisplayedColumns: Array<string> = ['GRP_NAME'];
  filterValue: string = '';

  @ViewChild(MatTable) pageGroupsTable: MatTable<IUserGroup>;
  @ViewChild('pageGroupsSort') pageGroupsSort: MatSort;
  @ViewChild('pageGroupsPaginator') pageGroupsPaginator: MatPaginator;

  constructor(
    private pageService: PageService,
    @Inject(MAT_DIALOG_DATA) public data: IPagesViewDialog,
      private dialogRef: MatDialogRef<PagesViewDialogComponent>
  ) {   }

  ngOnInit() {
    this.getPageGroups();
  }

  getPageGroups() {
     let page: IJoinGroupRelPage = {
      PAG_ID: this.data.page.PAG_ID
    }

    this.pageService.getGroupByPage(page).subscribe((pageGroups: Array<IUserGroup>) => {
      this.pageGroupsTableDataSource.data = pageGroups;
      this.pageGroupsTableDataSource.sort = this.pageGroupsSort;
      this.pageGroupsTableDataSource.paginator = this.pageGroupsPaginator;
    }, () => {
      if(this.pageGroupsTableDataSource.data.length == 0) this.hasData = false;
    });
  }

  filterPageGroupTable(filterValue: string) {
    this.pageGroupsTableDataSource.filter = filterValue;
  }

  closeDialog() {
    this.dialogRef.close({event:'Close'});
  }

}
