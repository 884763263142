<h1 mat-dialog-title>Insert SAT</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>SAT</mat-label>
        <input matInput name="sat" formControlName="sat">
        <mat-error *ngIf="form.get('sat').invalid">{{getErrorMessage('sat')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Payment Date</mat-label>
        <input matInput name="paymentDate" formControlName="paymentDate" [matDatepicker]="paymentDatePicker">
        <mat-datepicker-toggle matSuffix [for]="paymentDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #paymentDatePicker></mat-datepicker>
        <mat-error *ngIf="form.get('paymentDate').invalid">{{getErrorMessage('paymentDate')}}</mat-error>
      </mat-form-field>
    </div>        
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid">Apply</button>
    </div>
  </form>
</div>
