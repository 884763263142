<h1 mat-dialog-title>Generate P.O.</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Total USD</mat-label>        
        <input matInput type="number" name="totalUsd" formControlName="totalUsd" readonly>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Discount</mat-label>        
        <input matInput type="number" name="discount" formControlName="discount" readonly>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Total With Discount</mat-label>        
        <input matInput type="number" name="totalDiscount" formControlName="totalDiscount" readonly>
      </mat-form-field>
    </div>    
    <div class="form-group">
      <mat-form-field>
        <mat-label>Currency Rate</mat-label>
        <input matInput type="number" name="rate" formControlName="rate">
        <mat-error *ngIf="form.get('rate').invalid">{{getErrorMessage('rate')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Total BRL</mat-label>        
        <input matInput type="number" name="totalBrl" formControlName="totalBrl" readonly>        
      </mat-form-field>
    </div>
    
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid">Apply</button>
    </div>
  </form>
</div>
