<header>
  <h2>Agreement - {{agreementType}}</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber !== 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="menu" [disabled]="selection.isEmpty()">
        Action
      </button>
      <mat-menu #menu xPosition="before">
        <button mat-menu-item (click)="deleteSelected()">
          <mat-icon>delete</mat-icon>
          <span>Delete all</span>
        </button>
        <button mat-menu-item (click)="insertComment({element: selection.selected})">
          <mat-icon>chat</mat-icon>
          <span>Insert comment</span>
        </button>
        <button mat-menu-item (click)="insertAttachment({element: selection.selected})">
          <mat-icon>attach_file</mat-icon>
          <span>Insert attachment</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" class="capitalize" [routerLink]="['/agreement', agreementType]">Create {{agreementType}} Agreement</button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="agreementTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #agreementTable [dataSource]="filteredAgreementTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="DIM_SK_CUS_RUL" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" >DDM Agreement</th>
        <td mat-cell *matCellDef="let element" class="link">{{element.DIM_SK_CUS_RUL}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_DESCRIPTION">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Agreement Type</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_DESCRIPTION)" [matTooltip]="element.DIM_DESCRIPTION" mat-cell *matCellDef="let element">{{element.DIM_DESCRIPTION}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_NAC_FAK">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">FAK / NAC</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_NAC_FAK)" [matTooltip]="element.DIM_NAC_FAK" mat-cell *matCellDef="let element">{{element.DIM_NAC_FAK}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_DOSIERPF_BHFFTX">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by HBL/MBL">House Bill / Master Bill / Shipment</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_DOSIERPF_BHFFTX)" [matTooltip]="element.DIM_DOSIERPF_BHFFTX" mat-cell *matCellDef="let element">{{element.DIM_DOSIERPF_BHFFTX}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_SERVICE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Service</th>
        <td mat-cell *matCellDef="let element">{{element.DIM_SERVICE}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_CAR)" [matTooltip]="element.DIM_CAR" mat-cell *matCellDef="let element">{{element.DIM_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_CUS_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer / Group</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_CUS_NAME)" [matTooltip]="element.DIM_CUS_NAME" mat-cell *matCellDef="let element">{{element.DIM_CUS_NAME}}</td>
      </ng-container> 
      <ng-container matColumnDef="DIM_ORIGIN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Origin">Origin</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_ORIGIN)" [matTooltip]="element.DIM_ORIGIN" mat-cell *matCellDef="let element">{{element.DIM_ORIGIN}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_DESTINATION">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Destination">Destination</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_DESTINATION)" [matTooltip]="element.DIM_DESTINATION" mat-cell *matCellDef="let element">{{element.DIM_DESTINATION}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_CTN_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container Type">Type</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_CTN_TYPE)" [matTooltip]="element.DIM_CTN_TYPE" mat-cell *matCellDef="let element">{{element.DIM_CTN_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_VL_FRT_AMT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Freetime">Freetime</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_VL_FRT_AMT)" [matTooltip]="element.DIM_VL_FRT_AMT" mat-cell *matCellDef="let element">{{element.DIM_VL_FRT_AMT}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_PERDIEM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Perdiem">Perdiem</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_PERDIEM)" [matTooltip]="element.DIM_PERDIEM" mat-cell *matCellDef="let element">{{element.DIM_PERDIEM}}</td>
      </ng-container>     
      <ng-container matColumnDef="DIM_DT_STR_EFF">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Start Date Effective">Start Date</th>
        <td mat-cell *matCellDef="let element">{{element.DIM_DT_STR_EFF | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_DT_FIN_EFF">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Finish Date Effective">Validity Date</th>
        <td mat-cell *matCellDef="let element">{{element.DIM_DT_FIN_EFF | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_STATUS_RUL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Agreement Status</th>
        <td mat-cell *matCellDef="let element">{{element.DIM_STATUS_RUL}}</td>
      </ng-container>
      <ng-container matColumnDef="DIM_CMT_ATCH">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Comment/Attachment">Comment / Attachment</th>
        <td [matTooltipDisabled]="!isOverflow(element.DIM_CMT_ATCH)" [matTooltip]="element.DIM_CMT_ATCH" mat-cell *matCellDef="let element">
          <a class="cmt-atch" (click)="openSidebar(element.DIM_SK_CUS_RUL)">
            <span class="flag"
              [ngClass]="{
                'grey': element.DIM_CMT_ATCH === 0,
                'green': element.DIM_CMT_ATCH > 0             
              }">
            </span>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a mat-icon-button matTooltip="Clone Agreement" [routerLink]="['/agreement', agreementType, 'clone', element.DIM_SK_CUS_RUL]">
            <mat-icon color="primary">content_copy</mat-icon>
          </a>
          <a mat-icon-button [routerLink]="['/agreement', agreementType, 'edit', element.DIM_SK_CUS_RUL]">
            <mat-icon color="primary">edit</mat-icon>
          </a>
          <button mat-icon-button (click)="deleteAgreement(element)">
            <mat-icon color="primary">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="agreementTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: agreementTableDisplayedColumns"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container> 
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>