<h1 mat-dialog-title>Apply Discount</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput type="number" name="value" formControlName="value" [max]="maxDiscount()">
        <mat-icon matSuffix color="primary" *ngIf="form.get('type').value === '0'">percent</mat-icon>
        <mat-icon matSuffix color="primary" *ngIf="form.get('type').value === '1'">attach_money</mat-icon>
        <mat-error *ngIf="form.get('value').invalid">
          Max {{form.get('type').value === '0' ? 'percentage' : 'flat value'}} is {{form.get('type').value === '0' ? '100%' : minValue + '$'}}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select name="type" formControlName="type">
          <mat-option value="0">Percent</mat-option>
          <mat-option value="1">Flat</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('type').invalid">{{getErrorMessage('type')}}</mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid">Apply</button>
    </div>
  </form>
</div>