<h1 mat-dialog-title>Filter Invoices</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Proforma</mat-label>
        <input matInput name="proforma" formControlName="proforma">
        <button type="button" mat-icon-button matSuffix (click)="form.get('proforma').setValue('')" *ngIf="form.get('proforma').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field> 
      <mat-form-field *ngIf="data.isPartial">
        <mat-label>Container</mat-label>
        <input matInput name="container" formControlName="container">
        <button type="button" mat-icon-button matSuffix (click)="form.get('container').setValue('')" *ngIf="form.get('container').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status" multiple>
          <mat-option value="0">Open</mat-option>
          <mat-option value="1">In Dispute</mat-option>
          <mat-option value="2">Invoiced</mat-option>
          <mat-option value="3">Paid</mat-option>
          <mat-option value="4">Canceled</mat-option>
          <mat-option value="5">Under Analysis</mat-option>
          <mat-option value="6">Release Billing</mat-option>
          <mat-option value="7">Customer Notified</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="form.get('status').setValue('')" *ngIf="form.get('status').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Notification Status</mat-label>
        <mat-select name="notification" formControlName="notification">
          <mat-option value="Active">Active</mat-option>
          <mat-option value="Closed">Closed</mat-option>
          <mat-option value="Dispute">Dispute</mat-option>
          <mat-option value="Pending">Pending</mat-option>
          <mat-option value="Failure">Failure</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="form.get('notification').setValue('')" *ngIf="form.get('notification').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Service</mat-label>
        <mat-select name="service" formControlName="service">
          <mat-option value="DEMURRAGE">Demurrage</mat-option>
          <mat-option value="DETENTION">Detention</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="form.get('service').setValue('')" *ngIf="form.get('service').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>House Bill</mat-label>
        <input matInput name="hbl" formControlName="hbl">
        <button type="button" mat-icon-button matSuffix (click)="form.get('hbl').setValue('')" *ngIf="form.get('hbl').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>             
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer" [disabled]="!customers" multiple>
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_CODE}} - {{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('customer')" (click)="resetField('customer'); resetField('customerFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier" [disabled]="!carriers">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('carrier')" (click)="resetField('carrier')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Consol Type</mat-label>
        <mat-select name="consolType" formControlName="consolType">
          <mat-option value="AGT">AGT</mat-option>
          <mat-option value="DRT">DRT</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="resetField('consolType')" *ngIf="fieldHasValue('consolType')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field> 
      <mat-form-field>
        <mat-label>Incoterm</mat-label>
        <mat-select name="incoterm" formControlName="incoterm">
          <mat-option value="CFR">CFR</mat-option>          
          <mat-option value="CIF">CIF</mat-option>          
          <mat-option value="CIP">CIP</mat-option>          
          <mat-option value="CPT">CPT</mat-option>          
          <mat-option value="DAP">DAP</mat-option>          
          <mat-option value="DAT">DAT</mat-option>          
          <mat-option value="DDP">DDP</mat-option>          
          <mat-option value="DPU">DPU</mat-option>          
          <mat-option value="EXW">EXW</mat-option>          
          <mat-option value="FAS">FAS</mat-option>          
          <mat-option value="FC1">FC1</mat-option>          
          <mat-option value="FC2">FC2</mat-option>          
          <mat-option value="FCA">FCA</mat-option>          
          <mat-option value="FOB">FOB</mat-option>          
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="resetField('incoterm')" *ngIf="fieldHasValue('incoterm')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>      
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Origin Port</mat-label>
        <mat-select name="originPort" formControlName="originPort" [disabled]="!ports">
          <mat-option>
            <ngx-mat-select-search formControlName="originPortFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let originPort of filteredOriginPort | async" [value]="originPort.DIM_SK_PORT">{{originPort.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('originPort')" (click)="resetField('originPort')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>     
      <mat-form-field>
        <mat-label>Destination Port</mat-label>
        <mat-select name="destinationPort" formControlName="destinationPort" [disabled]="!ports">
          <mat-option>
            <ngx-mat-select-search formControlName="destinationPortFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let destinationPort of filteredDestinationPort | async" [value]="destinationPort.DIM_SK_PORT">{{destinationPort.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('destinationPort')" (click)="resetField('destinationPort')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Start Date Range</mat-label>
        <mat-date-range-input [rangePicker]="stRangePicker">
          <input matStartDate name="stStart" formControlName="stStart" placeholder="Start date">
          <input matEndDate name="stFinal" formControlName="stFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="stRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="form.get('stStart').setValue(null); form.get('stFinal').setValue(null)" *ngIf="form.get('stStart').value !== null || form.get('stFinal').value !== null">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #stRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date Range</mat-label>
        <mat-date-range-input [rangePicker]="endRangePicker">
          <input matStartDate name="endStart" formControlName="endStart" placeholder="Start date">
          <input matEndDate name="endFinal" formControlName="endFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="endRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="form.get('endStart').setValue(null); form.get('endFinal').setValue(null)" *ngIf="form.get('endStart').value !== null || form.get('endFinal').value !== null">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #endRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Invoice Date Range</mat-label>
        <mat-date-range-input [rangePicker]="invRangePicker">
          <input matStartDate name="invStart" formControlName="invStart" placeholder="Start date">
          <input matEndDate name="invFinal" formControlName="invFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="invRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="form.get('invStart').setValue(null); form.get('invFinal').setValue(null)" *ngIf="form.get('invStart').value !== null || form.get('invFinal').value !== null">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #invRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Calculation Date Range</mat-label>
        <mat-date-range-input [rangePicker]="incRangePicker">
          <input matStartDate name="incStart" formControlName="incStart" placeholder="Start date">
          <input matEndDate name="incFinal" formControlName="incFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="incRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="form.get('incStart').setValue(null); form.get('incFinal').setValue(null)" *ngIf="form.get('incStart').value !== null || form.get('incFinal').value !== null">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #incRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-stroked-button color="accent" type="button" (click)="reset()">Reset All</button>
      <button mat-flat-button color="accent" type="submit">Apply</button>
    </div>
  </form>
</div>