import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IAgreement } from '../models/agreement.model';
import { AgreementService } from '../services/agreement.service';

@Injectable({
  providedIn: 'root'
})
export class AgreementResolver implements Resolve<IAgreement> {

  constructor(private agreementService: AgreementService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const agreement: IAgreement = {
      DIM_SK_CUS_RUL: route.params.id
    }
    return this.agreementService.getAgreement(agreement);
  }
}
