<h1 mat-dialog-title>Filter shipments</h1>
<div mat-dialog-content>
  <form [formGroup]="filterForm" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Service</mat-label>
        <mat-select name="service" formControlName="service">
          <mat-option value="DEMURRAGE">Demurrage</mat-option>
          <mat-option value="DETENTION">Detention</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('service')" (click)="resetField('service')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field> 
      <mat-form-field>
        <mat-label>Shipment ID</mat-label>
        <input matInput type="text" name="code" formControlName="code">
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('code')" (click)="resetField('code')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>House Bill</mat-label>
        <input matInput type="text" name="hbl" formControlName="hbl">
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('hbl')" (click)="resetField('hbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Master Bill</mat-label>
        <input matInput type="text" name="mbl" formControlName="mbl">
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('mbl')" (click)="resetField('mbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer" [disabled]="!customers">
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_CODE}} - {{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('customer')" (click)="resetField('customer'); resetField('customerFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier" [disabled]="!carriers">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('carrier')" (click)="resetField('carrier')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Consol Type</mat-label>
        <mat-select name="consolType" formControlName="consolType">
          <mat-option value="AGT">AGT</mat-option>
          <mat-option value="DRT">DRT</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="resetField('consolType')" *ngIf="fieldHasValue('consolType')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field> 
      <mat-form-field>
        <mat-label>Incoterm</mat-label>
        <mat-select name="incoterm" formControlName="incoterm">
          <mat-option value="CFR">CFR</mat-option>          
          <mat-option value="CIF">CIF</mat-option>          
          <mat-option value="CIP">CIP</mat-option>          
          <mat-option value="CPT">CPT</mat-option>          
          <mat-option value="DAP">DAP</mat-option>          
          <mat-option value="DAT">DAT</mat-option>          
          <mat-option value="DDP">DDP</mat-option>          
          <mat-option value="DPU">DPU</mat-option>          
          <mat-option value="EXW">EXW</mat-option>          
          <mat-option value="FAS">FAS</mat-option>          
          <mat-option value="FC1">FC1</mat-option>          
          <mat-option value="FC2">FC2</mat-option>          
          <mat-option value="FCA">FCA</mat-option>          
          <mat-option value="FOB">FOB</mat-option>          
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="resetField('incoterm')" *ngIf="fieldHasValue('incoterm')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field> 
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Mode</mat-label>
        <input matInput type="text" name="mode" formControlName="mode">
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('mode')" (click)="resetField('mode')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Dept</mat-label>
        <input matInput type="text" name="dept" formControlName="dept">
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('dept')" (click)="resetField('dept')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Origin Port</mat-label>
        <mat-select name="originPort" formControlName="originPort" [disabled]="!ports">
          <mat-option>
            <ngx-mat-select-search formControlName="originPortFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let originPort of filteredOriginPort | async" [value]="originPort.DIM_SK_PORT">{{originPort.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('originPort')" (click)="resetField('originPort')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>     
      <mat-form-field>
        <mat-label>Destination Port</mat-label>
        <mat-select name="destinationPort" formControlName="destinationPort" [disabled]="!ports">
          <mat-option>
            <ngx-mat-select-search formControlName="destinationPortFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let destinationPort of filteredDestinationPort | async" [value]="destinationPort.DIM_SK_PORT">{{destinationPort.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('destinationPort')" (click)="resetField('destinationPort')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>ATD Date Range</mat-label>
        <mat-date-range-input [rangePicker]="atdRangePicker">
          <input matStartDate name="atdStart" formControlName="atdStart" placeholder="Start date">
          <input matEndDate name="atdFinal" formControlName="atdFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="atdRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('atdStart'); resetField('atdFinal')" *ngIf="fieldHasValue('atdStart') || fieldHasValue('atdFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #atdRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>ATA Date Range</mat-label>
        <mat-date-range-input [rangePicker]="ataRangePicker">
          <input matStartDate name="ataStart" formControlName="ataStart" placeholder="Start date">
          <input matEndDate name="ataFinal" formControlName="ataFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="ataRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('ataStart'); resetField('ataFinal')" *ngIf="fieldHasValue('ataStart') || fieldHasValue('ataFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #ataRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Inclusion Date Range</mat-label>
        <mat-date-range-input [rangePicker]="incRangePicker">
          <input matStartDate name="incStart" formControlName="incStart" placeholder="Start date">
          <input matEndDate name="incFinal" formControlName="incFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="incRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('incStart'); resetField('incFinal')" *ngIf="fieldHasValue('incStart') || fieldHasValue('incFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #incRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetFilters()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>