import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInvoice } from 'src/app/models/invoice.model';
import { AuthService } from 'src/app/services/auth.service';

export interface IAttachmentDialog {
  element?: IInvoice | Array<IInvoice>;
}

@Component({
  selector: 'dhl-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss']
})
export class AttachmentDialogComponent implements OnInit {
  files: Array<File> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAttachmentDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AttachmentDialogComponent>,
  ) { }

  ngOnInit(): void {    
  }

  close(value: [Array<IInvoice>, Array<File>]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newInvoice: Array<IInvoice> = [];

    if (this.data.element instanceof Array) {
      newInvoice = this.data.element.map((element) => {
        return {
          INVOICE_ID: element.INVOICE_ID,
          INVOICE_USER: this.authService.userId
        }
      });
    } else {
      newInvoice.push({
        INVOICE_ID: this.data.element.INVOICE_ID,
        INVOICE_USER: this.authService.userId
      });
    }

    this.close([newInvoice, this.files]);
  }

  onUpload(files: Array<File>) {
    files.forEach(file => this.files.push(file));
  }
}
