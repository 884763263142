import { Component, Inject, OnInit } from '@angular/core';
import { IRpaJson, IRpaLog } from 'src/app/models/log.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from 'src/app/services/log.service';

export interface IJsonViewDialog{
  element?: IRpaLog
}

@Component({
  selector: 'app-json-view-dialog',
  templateUrl: './json-view-dialog.component.html',
  styleUrls: ['./json-view-dialog.component.scss']
})

export class JsonViewDialogComponent implements OnInit {
  rpaJson: Object;

  constructor(
    public dialogRef: MatDialogRef<JsonViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IJsonViewDialog,
    public logService: LogService
  ) { }

  ngOnInit() {
    if(this.isJsonString(this.data))
    this.rpaJson = JSON.parse(this.data[0].LDR_DESC.replace(/'/g, '"'));
  }

  isJsonString(str): boolean {
    try {
        JSON.parse(str[0].LDR_DESC.replace(/'/g, '"'));
    } catch (e) {
        return false;
    }
    return true;
  }

  close(): void{
    this.dialogRef.close();
  }

}
