import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { ContainerIntegration, ContainerIntegrationExcel } from 'src/app/models/container-integration';
import { ContainerIntegrationService } from 'src/app/services/container-integration.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-demurrage-excel-import-dialog',
  templateUrl: './demurrage-excel-import-dialog.component.html',
  styleUrls: ['./demurrage-excel-import-dialog.component.scss']
})
export class DemurrageExcelImportDialogComponent implements OnInit {
  protected bucketName = environment.bucketName;
  templateUrl = `https://${this.bucketName}.s3.amazonaws.com/template/containers-template.xlsx`;

  files: Array<File> = [];
  containers: ContainerIntegration[] = [];
  isImport: boolean = false;

  types: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  ];

  constructor(
    private dialogRef: MatDialogRef<DemurrageExcelImportDialogComponent>,
    private containerIntegrationService: ContainerIntegrationService,
    private toast: HotToastService,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  onSelect(e: NgxDropzoneChangeEvent): void {
    this.containers = [];
    this.files.push(...e.addedFiles);

    const file = this.files[0];

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (ev: ProgressEvent): void => {
      let binary = "";
      let bytes = new Uint8Array((<any>ev.target).result);
      let length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
      const excelData = workbook.SheetNames.reduce((initial: any, name: any) => {
        return XLSX.utils.sheet_to_json(workbook.Sheets[name]);
      }, {});

      const jsonData: ContainerIntegration[] = excelData.map((original: ContainerIntegrationExcel) => {
        const converted = this.convertContainerObject(original);

        return converted;
      });

      this.containers.push(...jsonData);
    }
  }

  convertContainerObject(original: ContainerIntegrationExcel): ContainerIntegration{
    return {
        SHIP_HBL: original.SHIP_HBL,
        CTN_NUMBER: original.CTN_NUMBER,
        CTN_GOU: original.CTN_GOU,
        CTN_GIN: original.CTN_GIN,
        CTN_LDG: original.CTN_LDG,
        USER_ID: this.authService.userId
    };
  }

  import(): void {
    this.isImport = true;
    const ref = this.toast.loading('Saving...',{autoClose: false});
    this.containerIntegrationService.putContainerIntegrationExcel(this.containers)
      .subscribe((response)=>{
        ref.close();
        this.toast.success(String(response));
        this.close();
      },(error) =>{
        if(error.status === 0){
          ref.close();
          this.toast.success('Container(s) dates have been updated successfully.');
          this.close();
        }else{
          ref.close();
          this.toast.error(error.error.Message);
        }
      });
  }

  onRemove(file: File): void {
    this.files.splice(this.files.indexOf(file), 1);
  }

  close(): void {
    this.dialogRef.close(this.isImport);
  }
}
