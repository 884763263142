import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPrtlCtn } from 'src/app/models/prtl-ctn';
import { IContainer } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';
import { freetimeValidator } from './freetime-validator';

export interface INewPrtlCtnDemurrageDialog {
  title:'';
  container?: IContainer;
  mode: 'add' | 'edit' | 'delete';
}

@Component({
  selector: 'app-new-prtl-ctn-demurrage-dialog',
  templateUrl: './new-prtl-ctn-demurrage-dialog.component.html',
  styleUrls: ['./new-prtl-ctn-demurrage-dialog.component.scss']
})
export class NewPrtlCtnDemurrageDialogComponent implements OnInit {
  newPrtlCtnForm: UntypedFormGroup;
  isFormEnabled: boolean = true;

  minGou: Date;
  maxGou: Date = new Date();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NewPrtlCtnDemurrageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INewPrtlCtnDemurrageDialog,
    private authService: AuthService

  ) { }

  ngOnInit() {
    this.newPrtlCtnForm = this.formBuilder.group({
      ctnStart: new UntypedFormControl(this.data.container?.START_DATE || this.data.container?.CTN_ATA, [Validators.required]),
      ctnGou: new UntypedFormControl('', [Validators.required, freetimeValidator(this.data.container)])
    });

    this.minGou = this.data.container?.START_DATE || this.data.container?.CTN_ATA;

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.newPrtlCtnForm.disable();
    }
  }

  closeDialog(prtlCtn: IPrtlCtn) {
    this.dialogRef.close(prtlCtn);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.newPrtlCtnForm.get('ctnStart').enable();
    this.newPrtlCtnForm.get('ctnGou').enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.newPrtlCtnForm.value;
    let prtlCtn: IPrtlCtn = null;

    if (this.data.mode == 'add') {
      prtlCtn = {
        CTN_ID: this.data.container.CTN_ID,
        PRTL_CTN_ATA: formValue.ctnStart,
        PRTL_CTN_GOU: formValue.ctnGou,
        PRTL_CTN_USER: this.authService.userId
      }
    } else if (this.data.mode == 'edit') {
      prtlCtn = {
        PRTL_CTN_ID: this.data.container.CTN_ID,
        PRTL_CTN_ATA: formValue.ctnStart,
        PRTL_CTN_GOU: formValue.ctnGou,
        PRTL_CTN_USER: this.authService.userId
      }
    } else if (this.data.mode == 'delete') {
      prtlCtn = {
        PRTL_CTN_ID: this.data.container.CTN_ID,
        PRTL_CTN_USER: this.authService.userId
      }
    }

    this.closeDialog(prtlCtn);
  }

  areInputsValid(): boolean {
    const isCtnGouInvalid = this.newPrtlCtnForm.get('ctnGou').invalid;
    return isCtnGouInvalid;
  }

}
