import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dhl-customer-exception-info-dialog',
  templateUrl: './customer-exception-info-dialog.component.html',
  styleUrls: ['./customer-exception-info-dialog.component.scss']
})
export class CustomerExceptionInfoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CustomerExceptionInfoDialogComponent>
  ) {}

  closeDialog(willContinue: boolean): void {
    this.dialogRef.close(willContinue);
  }
}
