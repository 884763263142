import { formatDate } from '@angular/common';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { CommentService } from 'src/app/services/comment.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { IComment } from 'src/app/models/comment.model';
import { IAttachment } from 'src/app/models/attachment.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { HotToastService } from '@ngneat/hot-toast';
import { AwsService } from 'src/app/services/aws.service';
import { FilterService } from 'src/app/services/filter.service';
import { ICustomerException } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ExceptionFilterDialogComponent, SelectionFilter } from './exceptions-dialogs/exception-filter-dialog/exception-filter-dialog.component';
import { ExceptionDeleteDialogComponent } from './exceptions-dialogs/exception-delete-dialog/exception-delete-dialog.component';

@Component({
  selector: 'dhl-customer-exceptions',
  templateUrl: './customer-exceptions.component.html',
  styleUrls: ['./customer-exceptions.component.scss']
})
export class CustomerExceptionsComponent implements OnInit {

  readonly activeMap = new Map([
    ['0', 'No'],
    ['1', 'Yes']
  ]);

  readonly pageKey = 'customerExceptions';

  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<ICustomerException>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filtersNumber: number = 0;
  filterEntity: ICustomerException = {
    CUS_ID: null,
    CUS_GRP_ID: null
  };

  selectionFilter: SelectionFilter = {
    customer: null
  }

  exceptionsTableDataSource = new MatTableDataSource<ICustomerException>();
  exceptionsExportTableDataSource = new MatTableDataSource<ICustomerException>();
  filteredExceptionsTableDataSource = new MatTableDataSource<ICustomerException>();
  exceptionsTableDisplayedColumns: Array<string> = ['select','CUS_NAME','EXCEPTION_CUT_OFF','EXCEPTION_CUT_OFF_DAYS','EXCEPTION_REFERENCE', 'EXCEPTION_REVIEW', 'EXCEPTION_RECEIVED_DATE','EXCEPTION_START_DATE','EXCEPTION_END_DATE','EXCEPTION_ANALYSIS_DEADLINE','EXCEPTION_CMT_ATCH','actions'];

  @ViewChild(MatTable) exceptionsTable: MatTable<ICustomerException>;

  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  uniqueTermId: number;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private customerService: CustomerService,
    private filterService: FilterService,
    private awsService: AwsService,
    private authService: AuthService,
    private attachmentService: AttachmentService,
    private commentService: CommentService,
    private timelineService: TimelineService
  ) { 
    this.titleService.setTitle('DHL | Customers (Exceptions)');
  }

  ngOnInit() {
    const filter = this.filterService.getFilter(this.pageKey);
    if (filter) {
      this.filterEntity = filter[0];
      this.selectionFilter = filter[1];
      this.filtersNumber = this.filterService.getFilterQuantity(filter[0]);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getCustomerExceptions(this.filterEntity);
    });

    this.getCustomerExceptions(this.filterEntity);
  }

  getCustomerExceptions(filter: ICustomerException) {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredExceptionsTableDataSource.data.length === 0) this.hideLoader = false;
    this.customerService.getCustomerException({
      ...filter, 
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((exceptions: Array<ICustomerException>) => {
      this.hideLoader = true;
      this.exceptionsTableDataSource.data = exceptions;
      this.filteredExceptionsTableDataSource.data = exceptions;
      this.length = exceptions[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredExceptionsTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.filteredExceptionsTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getCustomerExceptions(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getCustomerExceptions(this.filterEntity);
  }

  
  openFilterDialog(){
    const dialogRef = this.dialog.open(ExceptionFilterDialogComponent, {
      data: {
        element: this.filterEntity,
        selectionFilter: this.selectionFilter
      }
    });

    dialogRef.afterClosed().subscribe(([filters, field])=>{
      if(!filters) return;

      this.filterEntity = filters
      this.filtersNumber = this.filterService.getFilterQuantity(filters);
      this.selectionFilter.customer = field.customer;
      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter(this.pageKey,  [this.filterEntity, this.selectionFilter]);

      this.getCustomerExceptions(this.filterEntity);
    });
  }

  /*deleteSelected(){
    const dialogRef = this.dialog.open(ExceptionDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean)=>{
      if (willDelete){
        let shipmentsIds: Array<number> = [];
        this.selection.selected.forEach((shipment)=>{
          shipmentsIds.push(shipment.SHIP_ID);
        });

        let shipments: IShipmentList = {
          SHIP_ID: shipmentsIds,
          SHIP_USER: this.authService.userId
        }

        this.shipmentService.deleteShipments(shipments).subscribe((response) => {
          this.getShipment(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    })
  }*/

  deleteShipment(data: ICustomerException) {
    const dialogRef = this.dialog.open(ExceptionDeleteDialogComponent, {
      data: {
        id: data.EXCEPTION_ID
      }
    });

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {

        const exceptions: Array<ICustomerException> = [{
          EXCEPTION_ID: data.EXCEPTION_ID,
          EXCEPTION_USER:  this.authService.userId
        }];

        this.customerService.deleteCustomerException(exceptions).subscribe((response) => {
          this.getCustomerExceptions(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  export(): void {
    const headers = {
      CUS_NAME: 'CUSTOMER/GROUP',
      EXCEPTION_CUT_OFF_TEXT:'CUT OFF',
      EXCEPTION_CUT_OFF_DAYS:'CUT OFF DAYS',
      EXCEPTION_REFERENCE_TEXT:'REFERENCE',
      EXCEPTION_REVIEW_TEXT: 'REVIEW',
      EXCEPTION_RECEIVED_DATE_TEXT: 'RECEIVED DATE',
      EXCEPTION_START_DATE_TEXT: 'START DATE',
      EXCEPTION_END_DATE_TEXT: 'END DATE',
      EXCEPTION_ANALYSIS_DEADLINE_TEXT: 'ANALYSIS DEADLINE'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.customerService.getCustomerException({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.exceptionsExportTableDataSource.data = dataExport;

        const data: Array<ICustomerException> = this.prepareDataToExport([...this.exceptionsExportTableDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `customerExceptions_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });      
    } else {
      const data: Array<ICustomerException> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `customerExceptions_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: [],
      });
    }
  }

  prepareDataToExport(data: Array<ICustomerException>): Array<ICustomerException> {
    const dataToExport: Array<ICustomerException> = [];

    const datesText = {
      EXCEPTION_CUT_OFF_TEXT: '',
      EXCEPTION_REFERENCE_TEXT: '',
      EXCEPTION_REVIEW_TEXT: '',
      EXCEPTION_RECEIVED_DATE_TEXT: '',
      EXCEPTION_START_DATE_TEXT: '',
      EXCEPTION_END_DATE_TEXT: '',
      EXCEPTION_ANALYSIS_DEADLINE_TEXT: 0
    }

    data.forEach((shipment) => {
      const newData = {...shipment, ...datesText};

      newData.EXCEPTION_CUT_OFF_TEXT = this.activeMap.get(String(newData.EXCEPTION_CUT_OFF));

      newData.EXCEPTION_REFERENCE_TEXT = this.activeMap.get(String(newData.EXCEPTION_REFERENCE));

      newData.EXCEPTION_REVIEW_TEXT = this.activeMap.get(String(newData.EXCEPTION_REVIEW));
      
      newData.EXCEPTION_RECEIVED_DATE_TEXT = newData.EXCEPTION_RECEIVED_DATE ? moment(newData.EXCEPTION_RECEIVED_DATE).format('DD/MM/YYYY') : null;

      newData.EXCEPTION_START_DATE_TEXT = newData.EXCEPTION_START_DATE ? moment(newData.EXCEPTION_START_DATE).format('DD/MM/YYYY') : null;

      newData.EXCEPTION_END_DATE_TEXT = newData.EXCEPTION_END_DATE ? moment(newData.EXCEPTION_END_DATE).format('DD/MM/YYYY') : null;

      newData.EXCEPTION_ANALYSIS_DEADLINE_TEXT = Number(newData.EXCEPTION_DEFAULT_DEADLINE) === 0 ? Number(newData.EXCEPTION_ANALYSIS_DEADLINE) : Number(newData.ANALYSIS_DEADLINE_DEFAULT);

      delete newData.EXCEPTION_ID;
      delete newData.CUS_ID;
      delete newData.CUS_GRP_ID;
      delete newData.EXCEPTION_OBSERVATION;
      delete newData.EXCEPTION_REQUESTOR;
      delete newData.EXCEPTION_RECEIVED_DATE;
      delete newData.EXCEPTION_START_DATE;
      delete newData.EXCEPTION_END_DATE;
      delete newData.EXCEPTION_CMT_ATCH;
      delete newData.EXCEPTION_STATUS;
      delete newData.EXCEPTION_USER;
      delete newData.EXCEPTION_INC_DATE;
      delete newData.EXCEPTION_DATETIME;
      delete newData.EXCEPTION_DEFAULT_DEADLINE;
      delete newData.ANALYSIS_DEADLINE_DEFAULT;
      delete newData.EXCEPTION_ANALYSIS_DEADLINE;
      delete newData.EXCEPTION_CUT_OFF;
      delete newData.EXCEPTION_REFERENCE;
      delete newData.EXCEPTION_REVIEW;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openSidebar(uniqueTermId: number): void {
    this.uniqueTermId = uniqueTermId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';    
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `customer_exception`, REG_ID: this.uniqueTermId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.uniqueTermId),
          COMM_REF_GROUP: `customer_exception`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'customer_exception', REG_ID: this.uniqueTermId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'customer_exception', [{ REG_ID: this.uniqueTermId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'customer_exception', TIMELINE_REG_ID: this.uniqueTermId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }
  
}
