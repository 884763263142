import { CustomerGroupService } from 'src/app/services/customer-group.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { ICustomer, ICustomerException } from 'src/app/models/customer.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface IExceptiontFilterDialog {
  element: ICustomerException;
  selectionFilter: SelectionFilter;
}

export interface SelectionFilter{
  customer: string
}

@Component({
  selector: 'dhl-exception-filter-dialog',
  templateUrl: './exception-filter-dialog.component.html',
  styleUrls: ['./exception-filter-dialog.component.scss']
})
export class ExceptionFilterDialogComponent implements OnInit {
  form: UntypedFormGroup;

  customers: Array<ICustomer>;

  customerGroups: Array<ICustomerGroup>;
  filteredCustomerGroups: Array<ICustomerGroup>;

  selectionFilter: SelectionFilter = {
    customer: null,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ExceptionFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IExceptiontFilterDialog,
    private customerService: CustomerService,
    private customerGroupService: CustomerGroupService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      customer: new UntypedFormControl(this.data.element.CUS_ID),
      customerFilter: new UntypedFormControl(''),
      customerGroup: new UntypedFormControl(this.data.element.CUS_GRP_ID),
      customerGroupFilter: new UntypedFormControl(''),
      sdStartRange: new UntypedFormControl(this.data.element.EXCEPTION_SD_START_RANGE),
      sdFinalRange: new UntypedFormControl(this.data.element.EXCEPTION_SD_FINAL_RANGE),
      edStartRange: new UntypedFormControl(this.data.element.EXCEPTION_ED_START_RANGE),
      edFinalRange: new UntypedFormControl(this.data.element.EXCEPTION_ED_FINAL_RANGE),
    });

    this.getCustomers({ CUS_ID: this.form.get('customer').value, IS_SELECT: 1 });
    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    this.getCustomerGroups();
    this.form.get('customerGroupFilter').valueChanges.subscribe(value => this.filterCustomerGroups(value));

    this.toggleField('customer', 'customerGroup');
    this.toggleField('customerGroup', 'customer');
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers
    })
  }

  filterCustomers(filter: string): void {
    if (!this.form.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getCustomerGroups(): void{
    this.customerGroupService.getCustomerGroup().subscribe((customerGroups)=>{
      this.customerGroups = customerGroups;
      this.filteredCustomerGroups = customerGroups;
    })
  }

  filterCustomerGroups(filter: string): void {
    if(this.customerGroups){
      this.filteredCustomerGroups = this.customerGroups.filter((element) => {
        return element.CUS_GRP_NAME.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  closeDialog(filter: ICustomerException): void {
    this.dialogRef.close([filter, this.selectionFilter]);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    const exception: ICustomerException = {
      CUS_ID: this.form.value.customer ? this.form.value.customer : null,
      CUS_GRP_ID: this.form.value.customerGroup ? this.form.value.customerGroup : null,
      EXCEPTION_SD_START_RANGE: this.form.value.sdStartRange,
      EXCEPTION_SD_FINAL_RANGE: this.form.value.sdFinalRange,
      EXCEPTION_ED_START_RANGE: this.form.value.edStartRange,
      EXCEPTION_ED_FINAL_RANGE: this.form.value.edFinalRange
    };

    this.closeDialog(exception);
  }

  reset(): void {
    this.form.setValue({
      customer: null,
      customerFilter: '',
      customerGroup: null,
      customerGroupFilter: '',
      sdStartRange: null,
      sdFinalRange: null,
      edStartRange: null,
      edFinalRange: null,
    });

    this.selectionFilter = {
      customer: null
    };
    
    this.toggleField('customer', 'customerGroup');
    this.toggleField('customerGroup', 'customer');
  }

  toggleField(field: string, fieldToDisable: string): void {
    if (this.form.get(field).value) {
      this.selectionFilter.customer = field;
      this.form.get(fieldToDisable).setValue('');
      this.form.get(fieldToDisable).disable();
    } else {
      this.form.get(fieldToDisable).enable();
    }
  }

}
