import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dhl-default-rule-dialog',
  templateUrl: './default-rule-dialog.component.html',
  styleUrls: ['./default-rule-dialog.component.scss']
})
export class DefaultRuleDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DefaultRuleDialogComponent>) { }

  ngOnInit() {
  }

  closeDialog(willClone: number): void {
    this.dialogRef.close(willClone);
  }
}

