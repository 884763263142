import { FinancialService } from 'src/app/services/financial.service';
import { IFinancial } from './../../../../../models/financial.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IFilterDialog {
  element: IFinancial;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  years: Array<IFinancial> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private finacialService: FinancialService,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog
  ) { }

  ngOnInit(): void {
    this.finacialService.getFinancialYears().subscribe((years)=>{
      this.years = years;
    });
    
    this.form = this.formBuilder.group({
      jobNumber: new UntypedFormControl(this.data?.element.FBF_JOB_NUM),
      chargeCode: new UntypedFormControl(this.data?.element.FBF_CHARGE_CODE),
      trnDept: new UntypedFormControl(this.data?.element.FBF_TRN_DEPT),
      lineType: new UntypedFormControl(this.data?.element.FBF_LINE_TYPE),
      periodMonth: new UntypedFormControl(this.data?.element.FBF_POST_PERIOD),
      periodYear: new UntypedFormControl(this.data?.element.FBF_POST_PERIOD_YEAR),
      client: new UntypedFormControl(this.data?.element.FBF_CLIENT_NAME)
    });
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  close(filters: IFinancial): void {
    this.dialogRef.close(filters);
  }

  resetForm(): void {
    this.form.setValue({
      jobNumber: null,
      chargeCode: null,
      trnDept: null,
      lineType: null,
      periodMonth: null,
      periodYear: null,
      client: null
    })
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    console.log(this.form.value);
    this.dialogRef.close(this.form.value);
  }

}
