<h1 mat-dialog-title>Change Status</h1>
<form [formGroup]="form" (submit)="handleSubmit($event)">  
  <div mat-dialog-content class="wide">
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select name="status" formControlName="status">
        <mat-option value="0">Deactivate</mat-option>
        <mat-option value="1">Activate</mat-option>
      </mat-select>
    </mat-form-field>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit">Apply</button>
    </div>
  </div>
</form>