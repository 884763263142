import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { ICustomerContact } from 'src/app/models/customer.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'dhl-customer-contacts-import-dialog',
  templateUrl: './customer-contacts-import-dialog.component.html',
  styleUrls: ['./customer-contacts-import-dialog.component.scss']
})
export class CustomerContactsImportDialogComponent implements OnInit {
  protected bucketName = environment.bucketName;
  templateUrl = `https://${this.bucketName}.s3.amazonaws.com/template/customer-contacts-template.xlsx`
  
  files: Array<File> = [];
  contacts: ICustomerContact[] = [];
  isImport: boolean = false;

  types: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  ];

  constructor(
    private dialogRef: MatDialogRef<CustomerContactsImportDialogComponent>,
    private customerService: CustomerService,
    private toast: HotToastService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
  }

  onSelect(e: NgxDropzoneChangeEvent): void {
    this.contacts = [];
    this.files.push(...e.addedFiles);

    const file = this.files[0];

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (ev: ProgressEvent): void => {
      let binary = "";
      let bytes = new Uint8Array((<any>ev.target).result);
      let length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      
      const workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
      const excelData = workbook.SheetNames.reduce((initial: any, name: any) => {
        return XLSX.utils.sheet_to_json(workbook.Sheets[name]);
      }, {});

      var sheet_name_list = workbook.SheetNames;
      let columnHeaders = [];
      for (var sheetIndex = 0; sheetIndex < sheet_name_list.length; sheetIndex++) {
          var worksheet = workbook.Sheets[sheet_name_list[sheetIndex]];
          for (let key in worksheet) {
              let regEx = new RegExp("^\(\\w\)\(1\){1}$");
              if (regEx.test(key) == true) {
                  columnHeaders.push(worksheet[key].v);
              }
          }
      }

      const payableHeaders = [
        'CUS_CODE',
        'CONTACT_NAME',
        'CONTACT_EMAIL'
      ];
      
      let validFile = true;

      if(JSON.stringify(payableHeaders)!==JSON.stringify(columnHeaders)){
        validFile = false;
      }
      
      if (!validFile) {
        this.files = [];
        this.toast.error('File is not a valid customer contacts spreadsheet');
        return;
      }else{
        const jsonData: ICustomerContact[] = excelData.map((data: any) => {
          return {
            CUS_CODE: data.CUS_CODE,
            CONTACT_NAME: data.CONTACT_NAME,
            CONTACT_EMAIL: data.CONTACT_EMAIL,
            CONTACT_USER: this.authService.userId
          }          
        });

        this.contacts = jsonData;
      }
    }
  }

  import(): void{
    this.isImport = true;
    const ref = this.toast.loading('Saving...',{autoClose: false});
    this.customerService.importCustomerContact(this.contacts)
      .subscribe((response)=>{
        ref.close();
        this.toast.success(String(response));
        this.close();
      },(error) =>{
        if(error.status === 0){
          ref.close();
          this.toast.success('Contact(s) imported successfully.');
          this.close();
        }else{
          ref.close();
          this.toast.error(error.error.Message);
        }
      });
  }

  onRemove(file: File): void {
    this.files.splice(this.files.indexOf(file), 1);
  }

  close(): void {
    this.dialogRef.close(this.isImport);
  }

}
