<div class="customerGroupDialog">
  <h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
  <div mat-dialog-content>
    <form [formGroup]="customerGroupForm" (submit)="onFormSubmit($event)">
      <div class="form-group" *ngIf="data.mode != 'delete'">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" name="name" maxlength="100" formControlName="name" required>
          <mat-error *ngIf="customerGroupForm.get('name').invalid">You must enter a name</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>Customers</mat-label>
          <mat-select name="customers" formControlName="customers" multiple>
            <mat-option>
              <ngx-mat-select-search formControlName="customersFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">
              {{customer.CUS_CODE}} - {{customer.CUS_NAME}}
            </mat-option>
          </mat-select>
          <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('customers')" (click)="resetField('customers'); resetField('customersFilter')">
            <mat-icon color="primary">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <p *ngIf="data.mode == 'delete'">Do you really wish to delete the customer group {{data.customerGroup.CUS_GRP_NAME}}?</p>
      <div mat-dialog-actions *ngIf="data.mode != 'edit'">
        <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
        <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="areInputsValid()">Save</button>
        <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
      </div>
      <div mat-dialog-actions *ngIf="data.mode == 'edit'">
        <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
        <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm()">Edit</button>
        <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="areInputsValid()">Ok</button>
      </div>
    </form>
  </div>
</div>