<header>
  <h2>User - Registration</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="deleteSelected()">
          <mat-icon>delete</mat-icon>
          <span>Delete All</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="openUserDialog({title: 'Add user', mode: 'add'})">Create User</button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="filteredUserTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #userTable [dataSource]="filteredUserTableDataSource" matSort #userSort="matSort" matTableFilter [exampleEntity]="filterEntity" [filterType]="filterType">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="USER_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
        <td mat-cell *matCellDef="let element">{{element.USER_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="USER_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
        <td [matTooltipDisabled]="!isOverflow(element.USER_NAME)" [matTooltip]="element.USER_NAME" mat-cell *matCellDef="let element">{{element.USER_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="USER_IS_ADMIN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Role">Role</th>
        <td mat-cell *matCellDef="let element">{{element.USER_IS_ADMIN === 'Y' ? 'ADMIN' : 'STANDARD'}}</td>
      </ng-container>
      <ng-container matColumnDef="USER_IS_ACTIVE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Status</th>
        <td mat-cell *matCellDef="let element">{{element.USER_IS_ACTIVE === 'Y' ? 'ACTIVE' : 'INACTIVE'}}</td>
      </ng-container>
      <ng-container matColumnDef="groups">
        <th mat-header-cell *matHeaderCellDef>Groups</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="openUserViewDialog({user: element})" *ngIf="element.GRP_LIST">View Groups</button>
          <p class="empty-groups" *ngIf="element.GRP_LIST === null">Empty Group</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openUserDialog({title: 'Edit user', mode: 'edit', user: element})">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button mat-icon-button (click)="openUserDialog({title: 'Deactivate user', mode: 'deactivate', user: element})" *ngIf="element.USER_IS_ACTIVE === 'Y'">
            <mat-icon color="primary" matTooltip="Deactivate User">person_remove</mat-icon>
          </button>
          <button mat-icon-button (click)="openUserDialog({title: 'Activate user', mode: 'activate', user: element})" *ngIf="element.USER_IS_ACTIVE !== 'Y'">
            <mat-icon color="primary" matTooltip="Activate User">person_add_alt</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="userTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: userTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator #userPaginator [pageSizeOptions]="[5, 10, 15, 20]" pageSize="20" showFirstLastButtons aria-label="Select page of customer"></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
