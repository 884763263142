import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { HotToastService } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { IUserGroupList } from 'src/app/models/user-group-list.model';
import { IUserGroup } from 'src/app/models/user-group.model';
import { AuthService } from 'src/app/services/auth.service';
import { FilterService } from 'src/app/services/filter.service';
import { UserGroupService } from 'src/app/services/user-group.service';
import { UserGroupDeleteAllDialogsComponent } from './user-groups-dialogs/user-group-delete-all-dialogs/user-group-delete-all-dialogs.component';
import { IUserGroupViewDialog, UserGroupViewDialogComponent } from './user-groups-dialogs/user-group-view-dialog/user-group-view-dialog.component';
import { IUserGroupDialog, UserGroupsDialogComponent } from './user-groups-dialogs/user-groups-dialog/user-groups-dialog.component';

@Component({
  selector: 'dhl-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit {
  pageKey = 'userGroups';
  hideLoader: boolean;

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IUserGroup>(this.allowMultiSelect, this.initialSelection);

  userGroupTableDataSource = new MatTableDataSource<IUserGroup>();
  userGroupTableDisplayedColumns: Array<string> = ['select', 'GRP_NAME', 'users', 'pages', 'actions'];

  @ViewChild(MatTable) userGroupTable: MatTable<IUserGroup>;
  @ViewChild('userGroupSort') userGroupSort: MatSort;
  @ViewChild('userPaginator') userGroupPaginator: MatPaginator;

  constructor(
    private titleService: Title,
    private userGroupService: UserGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private authService: AuthService,
    private exporter: XlsxExporterService,
    private filterService: FilterService
  ) {
    this.titleService.setTitle('DHL | Users (Groups)')
  }

  ngOnInit(): void {
    this.getUserGroup();

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.filterUserGroupTable(value);
    });
  }

  getUserGroup(): void {
    if(!this.userGroupTableDataSource.filteredData) this.hideLoader = false;
    this.userGroupService.getUserGroup().subscribe((userGroups: Array<IUserGroup>) => {
      this.hideLoader = true;
      userGroups.forEach((userGroup) => {
        delete userGroup.GRP_STATUS;
        delete userGroup.GRP_USER;
      });
      this.userGroupTableDataSource.data = userGroups;
      this.changeDetectorRef.detectChanges();
      this.userGroupTableDataSource.sort = this.userGroupSort;
      this.userGroupTableDataSource.paginator = this.userGroupPaginator;

      this.filterUserGroupTable(this.globalFilter.value);
    });
  }

  filterUserGroupTable(filter: string): void {
    this.userGroupTableDataSource.filter = filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.userGroupTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.userGroupTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  openUserGroupDialog(data: IUserGroupDialog): void {
    const dialogRef = this.dialog.open(UserGroupsDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((userGroup: IUserGroup) => {
      if (!userGroup) return;

      if (data.mode === 'add') {
        this.userGroupService.postUserGroup(userGroup).subscribe((response) => {
          this.toast.success(String(response));
          this.getUserGroup();
        }, (error) => {
          this.toast.error(error.message);
        });
      } else if (data.mode === 'edit') {
        this.userGroupService.putUserGroup(userGroup).subscribe((response) => {
          this.toast.success(String(response));
          this.getUserGroup();
        }, (error) => {
          this.toast.error(error.message);
        });
      } else if (data.mode === 'delete') {
        this.userGroupService.putUserGroup(userGroup).subscribe((response) => {
          this.toast.success('User group deleted successfully.');
          this.getUserGroup();
        }, (error) => {
          this.toast.error(error.message);
        });
      }
    });
  }

  openUserGroupViewDialog(data: IUserGroupViewDialog): void {
    this.dialog.open(UserGroupViewDialogComponent, {
      data: data
    });
  }

  deleteSelected(): void {
    const dialogRef = this.dialog.open(UserGroupDeleteAllDialogsComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {
        let userGroupsIds: Array<number> = [];
        this.selection.selected.forEach((group) => {
          userGroupsIds.push(group.GRP_ID);
        });

        let userGroups: IUserGroupList = {
          LIST_GRP_ID: userGroupsIds,
          GRP_USER: this.authService.userId
        }

        this.userGroupService.deleteSelectedUserGroups(userGroups).subscribe((response) => {
          this.getUserGroup();
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }


  export(): void {
    const headers = {
      GRP_NAME: 'NAME',
      USER_LIST: 'USERS',
      PAGE_NAME_LIST: 'PAGES'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<IUserGroup> = this.prepareDataToExport([...this.userGroupTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<IUserGroup> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `userGroups_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<IUserGroup>): Array<IUserGroup> {
    const dataToExport: Array<IUserGroup> = [];

    data.forEach((userGroup) => {
      const newData = {...userGroup};
      delete newData.GRP_ID;
      delete newData.GRP_USER;
      delete newData.GRP_STATUS;
      delete newData.PAGE_LIST;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
