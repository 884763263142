<h1 mat-dialog-title>Replace Rejected Date</h1>
<div mat-dialog-content>
  <form [formGroup]="dateForm" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Empty Return</mat-label>
        <input matInput name="date" formControlName="date" [matDatepicker]="picker" [min]="minEmpty" [max]="maxEmpty">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" [disabled]="dateForm.invalid">Apply</button>
    </div>
  </form>
</div>
