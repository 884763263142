import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProcessPayContainer } from '../models/process-pay-container';


@Injectable({
  providedIn: 'root'
})
export class ProcessPayContainerService {

  protected apiURL = environment.apiURL;

  constructor(private httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getProcessPayContainer(processContainers: IProcessPayContainer): Observable<Array<IProcessPayContainer>> {
    return this.httpClient.get<Array<IProcessPayContainer>>(`${this.apiURL}/api/processcontainer/process-pay-container?filter=${JSON.stringify(processContainers)}`, this.httpOptions);
  }

  recalculateContainer(containers: Array<IProcessPayContainer>): Observable<IProcessPayContainer> {
    return this.httpClient.put(`${this.apiURL}/api/processcontainer/process-pay-container/recalculation`, containers, this.httpOptions);
  }

}
