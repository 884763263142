import { HotToastService } from '@ngneat/hot-toast';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AwsService } from 'src/app/services/aws.service';
import { IAttachment } from 'src/app/models/attachment.model';
import { ICustomer, ICustomerException } from 'src/app/models/customer.model';
import { AttachmentService } from 'src/app/services/attachment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { CommentService } from 'src/app/services/comment.service';
import { ITimeline } from 'src/app/models/timeline.model';
import { IComment } from 'src/app/models/comment.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { CustomerGroupService } from 'src/app/services/customer-group.service';

@Component({
  selector: 'dhl-customer-exception',
  templateUrl: './customer-exception.component.html',
  styleUrls: ['./customer-exception.component.scss']
})
export class CustomerExceptionComponent implements OnInit {
  exceptionsForm: UntypedFormGroup;
  exception: ICustomerException = {};
  exceptionId = 0;

  customers: Array<ICustomer>;
  filteredCustomers: Subject<Array<ICustomer>> = new Subject<Array<ICustomer>>();

  customerGroups: Array<ICustomerGroup>;
  filteredCustomerGroups: Subject<Array<ICustomerGroup>> = new Subject<Array<ICustomerGroup>>();

  addOnBlur: boolean = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  requestors: Array<string> = [];

  isSidebarOpen = false;
  comments: Array<IComment> = [];
  timeline: Array<ITimeline> = [];
  attachments: Array<IAttachment> = [];
  
  files: Array<File> = [];
  hasFile = false;
  showAttachments: boolean = true;
  isFormDisabled: boolean = false;

  showToUploadTab = false;

  constructor(
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private customerGroupService: CustomerGroupService,
    private toast: HotToastService,
    private attachmentService: AttachmentService,
    private commentService: CommentService,
    private timelineService: TimelineService,
    private awsService: AwsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.titleService.setTitle('DHL | Customers (Exception)');    
  }

  ngOnInit() {
    this.exception = this.route.snapshot.data['exception'] && this.route.snapshot.data['exception'][0];
    
    this.requestors = this.exception?.EXCEPTION_REQUESTOR?.split(', ') || [];
    
    this.exceptionsForm = this.formBuilder.group({    
      customer: new UntypedFormControl(this.exception?.CUS_ID, [Validators.required]),
      customerFilter: new UntypedFormControl(''),
      customerGroup: new UntypedFormControl(this.exception?.CUS_GRP_ID, [Validators.required]),
      customerGroupFilter: new UntypedFormControl(''),
      cutOff: new UntypedFormControl(this.exception?.EXCEPTION_CUT_OFF, [Validators.required]),
      cutOffDays: new UntypedFormControl(this.exception?.EXCEPTION_CUT_OFF_DAYS),
      reference: new UntypedFormControl(this.exception?.EXCEPTION_REFERENCE, [Validators.required]),
      review: new UntypedFormControl(this.exception?.EXCEPTION_REVIEW, [Validators.required]),
      observation: new UntypedFormControl(this.exception?.EXCEPTION_OBSERVATION, [Validators.required]),
      requestor: new UntypedFormControl('', [Validators.required, Validators.email]),
      receivedDate: new UntypedFormControl(this.exception?.EXCEPTION_RECEIVED_DATE, [Validators.required]),
      startDate: new UntypedFormControl(this.exception?.EXCEPTION_START_DATE, [Validators.required]),
      endDate: new UntypedFormControl(this.exception?.EXCEPTION_END_DATE, [Validators.required]),
      analysisDeadline: new UntypedFormControl(this.exception?.EXCEPTION_ANALYSIS_DEADLINE),
      defaultDeadline: new UntypedFormControl(this.exception?.EXCEPTION_DEFAULT_DEADLINE, [Validators.required])
    });

    this.checkAnalysisDeadline();
    this.checkCuteOff();
    
    this.getCustomers({ CUS_ID: this.exceptionsForm.value.customer, IS_SELECT: 1 });
    this.getGroups();

    this.exceptionsForm.get('customerGroupFilter').valueChanges.subscribe(() => {
      this.filterGroups();
    });

    this.exceptionsForm.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    if (this.exception) {
      this.getAttachments();
      this.getComments();
      this.getTimeline();
    }

    this.route.url.subscribe((url) => {
      if (url[2]?.path === 'view') {
        this.isFormDisabled = true;
        this.exceptionsForm.disable();
      } else if (!url[2]?.path) {
        this.showAttachments = false;
      }
    });

    this.setExceptionValidators();
    this.hasRequestor();
  }

  filterCustomers(filter: string): void {
    if (!this.exceptionsForm.get('customer').value)    
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1, CUS_COUNTRY: 'BR' }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  filterGroups(): void {
    if (!this.customerGroups?.length) return;
    const filterValue = this.exceptionsForm.get('customerGroupFilter').value.toLowerCase();
    this.filteredCustomerGroups.next(this.customerGroups.filter((group) => group.CUS_GRP_NAME.toLowerCase().includes(filterValue)));
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getGroups(): void{
    this.customerGroupService.getCustomerGroup().subscribe((customerGroups) => {
      this.customerGroups = customerGroups.sort(function(a, b) {
        return a.CUS_GRP_NAME.localeCompare(b.CUS_GRP_NAME);
      });
      this.filteredCustomerGroups.next(customerGroups);
    });
  }

  getErrorMessage(formControl: string): string {
    if (this.exceptionsForm.get(formControl).hasError('email')) {
      return 'You must enter a valid email';
    } else if (this.exceptionsForm.get(formControl).hasError('number')) {
      return 'The value must be a number';
    } else if (this.exceptionsForm.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

  addRequestor(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (value && this.exceptionsForm.get('requestor').hasError('email')) {
      this.toast.error('You must provide a valid email.');
    } else if (value) {
      this.requestors.push(value);
    }

    event.chipInput!.clear();
    this.hasRequestor();
  }

  removeRequestor(requested: string): void {
    const index = this.requestors.indexOf(requested);

    if (index >= 0) {
      this.requestors.splice(index, 1);
    }

    if (this.requestors.length === 0) {
      this.exceptionsForm.get('requestor').setValue('');
    }

    this.hasRequestor();
  }

  onFormSubmit(e: Event){
    e.preventDefault();

    const formValue = this.exceptionsForm.value;

    let exception: ICustomerException = {
      CUS_ID: formValue.customer || null,
      CUS_GRP_ID: formValue.customerGroup || null,
      EXCEPTION_CUT_OFF: formValue.cutOff,
      EXCEPTION_CUT_OFF_DAYS: formValue.cutOffDays,
      EXCEPTION_REFERENCE: formValue.reference,
      EXCEPTION_REVIEW: formValue.review,
      EXCEPTION_OBSERVATION: formValue.observation,
      EXCEPTION_REQUESTOR: this.requestors.join(', '),
      EXCEPTION_RECEIVED_DATE: formValue.receivedDate,
      EXCEPTION_START_DATE: formValue.startDate,
      EXCEPTION_END_DATE: formValue.endDate,
      EXCEPTION_ANALYSIS_DEADLINE: formValue.analysisDeadline,
      EXCEPTION_DEFAULT_DEADLINE: formValue.defaultDeadline,
      EXCEPTION_USER: this.authService.userId
    }

    if(!this.exception) {
      this.customerService.postCustomerException(exception).subscribe(async (response) => {
        this.toast.success(String(response));
        this.getExceptionId(exception);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }
    else {
      exception.EXCEPTION_ID = this.exception.EXCEPTION_ID;
      this.exceptionId = exception.EXCEPTION_ID;

      this.customerService.putCustomerException(exception).subscribe((response) => {
        this.toast.success(String(response));
        if(this.files.length > 0) {
          this.uploadFiles(this.files, () => {          
            this.router.navigate(['/customer/exceptions']);
          });
        }
        else {
          this.router.navigate(['/customer/exceptions']);
        }
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }

  }

  getExceptionId(exception: ICustomerException){
    this.customerService.getCustomerExceptionId(exception).subscribe((id: number) => {
      this.exceptionId = id;
      if(this.exceptionId > 0 && this.files.length){
        this.uploadFiles(this.files, () => {          
          this.router.navigate(['/customer/exceptions']);
        });        
      }
    });
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `customer_exception`, REG_ID: this.exception.EXCEPTION_ID }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: this.exception.EXCEPTION_ID,
          COMM_REF_GROUP: `customer_exception`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, error => this.toast.error(error.error.Message));
  }

  getAttachments(): void {
    if(this.exception == undefined) {
      return;
    }

    this.attachmentService.getAttachments({ ATCH_REF_GROUP: `customer_exception`, REG_ID: this.exception.EXCEPTION_ID }).subscribe(
      (attachments) => {
        this.attachments = attachments;
        this.hasFile = attachments.length > 0 ? true : false;
      }
    );
  }

  onUpload(files: Array<File>) {  
    this.files = [];
    files.forEach(file => {
      this.files.push(file);
    });

    this.hasFile = this.files.length > 0 ? true : false;
  }

  uploadFiles(files: Array<File>, callback?: () => void) {
    this.awsService
      .uploadFiles(files, `customer_exception`, [{ REG_ID: this.exceptionId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () =>'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.files = null;
          if (callback) {
            callback();
          }
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    const timeline: ITimeline = {
      TIMELINE_GROUP: 'customer_exception',
      TIMELINE_REG_ID: this.exception.EXCEPTION_ID?.toString()
    }

    this.timelineService.getTimeline(timeline).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  checkAnalysisDeadline(): void{
    const value = this.exceptionsForm.value.defaultDeadline;

    if(value === '1'){
      this.exceptionsForm.get('analysisDeadline').setValue('');
      this.exceptionsForm.get('analysisDeadline').disable();
    }else{
      this.exceptionsForm.get('analysisDeadline').enable();
      this.exceptionsForm.get('analysisDeadline').setValidators(Validators.required);
      this.exceptionsForm.get('analysisDeadline').updateValueAndValidity();
    }
  }

  checkCuteOff(): void{
    const value = this.exceptionsForm.value.cutOff;

    if(value === '0'){
      this.exceptionsForm.get('cutOffDays').setValue('');
      this.exceptionsForm.get('cutOffDays').disable();
    }else{
      this.exceptionsForm.get('cutOffDays').enable();
      this.exceptionsForm.get('cutOffDays').setValidators(Validators.required);
      this.exceptionsForm.get('cutOffDays').updateValueAndValidity();
    }
  }

  setCustomerValidator(currentField: string, targetFields: Array<string>): void{
    const value = this.exceptionsForm.get(currentField).value;
    if (value && value.length !== 0) {
      targetFields.forEach((field) => {
        this.exceptionsForm.get(field).setValidators([]);
        this.exceptionsForm.get(field).updateValueAndValidity();
      });
    } else {
      targetFields.forEach((field) => {
        this.exceptionsForm.get(field).setValidators([Validators.required]);
        this.exceptionsForm.get(field).updateValueAndValidity();
      });
    }
  }

  setExceptionValidators(): void{
    if (this.exceptionsForm.get('customer').value) {
      this.setCustomerValidator('customer',['customerGroup']);
    } else if (this.exceptionsForm.get('customerGroup').value) {
      this.setCustomerValidator('customerGroup', ['customer']);
    }
  }

  hasRequestor(): void{
    if(!this.requestors.length && !this.requestors.length){
      this.exceptionsForm.get('requestor').setValidators([Validators.required, Validators.email]);
      this.exceptionsForm.get('requestor').updateValueAndValidity();
    }

    if(this.requestors.length && this.requestors.length){
      this.exceptionsForm.get('requestor').setValidators([]);
      this.exceptionsForm.get('requestor').updateValueAndValidity();
    }
  }

}