import { UniqueTermsFilterDialogComponent } from '../unique-terms-dialogs/unique-terms-filter-dialog/unique-terms-filter-dialog.component';
import { formatDate } from '@angular/common';
import { FilterService } from '../../../services/filter.service';
import { UniqueTermService } from '../../../services/unique-term.service';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { IUniqueTerm } from '../../../models/unique-term';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { CommentService } from 'src/app/services/comment.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { IComment } from 'src/app/models/comment.model';
import { IAttachment } from 'src/app/models/attachment.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { HotToastService } from '@ngneat/hot-toast';
import { AwsService } from 'src/app/services/aws.service';

@Component({
  selector: 'dhl-unique-terms',
  templateUrl: './customers-unique-terms.component.html',
  styleUrls: ['./customers-unique-terms.component.scss']
})
export class CustomersUniqueTermsComponent implements OnInit {
  pageKey = 'customerUniqueTerms';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IUniqueTerm>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filtersNumber: number = 0;
  filterEntity: IUniqueTerm = {
    START_DATE: null,
    FINISH_DATE: null,
    UNI_SITUATION: null,
  };

  termsTableDataSource = new MatTableDataSource<IUniqueTerm>();
  filteredTermsTableDataSource = new MatTableDataSource<IUniqueTerm>();
  termsTableDisplayedColumns: Array<string> = ['select','UNI_NAME','CUS_CODE','CUS_NAME','UNI_RECEIVED_DT', 'UNI_BRANCH', 'UNI_START_DT','UNI_END_DT','UNI_SL_RECEIVERS','UNI_OPER_RECEIVERS','UNI_SITUATION','UNI_INC_DT','UNI_CMT_ATCH','actions'];

  @ViewChild(MatTable) termsTable: MatTable<IUniqueTerm>;
  @ViewChild('termsSort') termsSort: MatSort;
  @ViewChild('termsPaginator') termsPaginator: MatPaginator;

  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  uniqueTermId: number;

  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private uniqueTermService: UniqueTermService,
    private filterService: FilterService,
    private awsService: AwsService,
    private authService: AuthService,
    private attachmentService: AttachmentService,
    private commentService: CommentService,
    private timelineService: TimelineService
  ) { 
    this.titleService.setTitle('DHL | Unique Terms (Customers)');
  }

  ngOnInit() {
    this.filterEntity = {
      UNI_SITUATION: ''
    }

    const filter = this.filterService.getFilter('customerUniqueTerm');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.getUniqueTerms(this.filterEntity);

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.filterTermsTable(value);
    });
  }

  getUniqueTerms(uniqueTerm: IUniqueTerm) {
    this.uniqueTermService.getCustomerTerms(uniqueTerm).subscribe((terms: Array<IUniqueTerm>) => {
      this.hideLoader = true;
      this.termsTableDataSource.data = terms;
      this.filteredTermsTableDataSource.data = terms;
      this.changeDetectorRef.detectChanges();
      this.filteredTermsTableDataSource.sort = this.termsSort;
      this.filteredTermsTableDataSource.paginator = this.termsPaginator;

      this.filterTermsTable(this.globalFilter.value);
    });
  }

  filterTermsTable(filter: string): void{
    this.termsTableDataSource.filter = filter;
    this.filteredTermsTableDataSource.data = this.termsTableDataSource.filteredData;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredTermsTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.filteredTermsTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  openFilterDialog(){
    const dialogRef = this.dialog.open(UniqueTermsFilterDialogComponent, {
      data: this.filterEntity
    });


    dialogRef.afterClosed().subscribe((filters)=>{
      if(!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity.START_DATE = filters.START_DATE;
      this.filterEntity.FINISH_DATE = filters.FINISH_DATE;
      this.filterEntity.UNI_SITUATION = filters.UNI_SITUATION;

      this.filterService.setFilter('customerUniqueTerm', this.filterEntity)

      this.getUniqueTerms(this.filterEntity);

    });
  }

  export(): void {
    const headers = {
      CUS_NAME: 'CUSTOMER',
      CUS_TAX: 'CNPJ',
      UNI_RECEIVED_DT_TEXT:'RECEIVED DATE',
      UNI_BRANCH: 'BRANCH',
      UNI_START_DT_TEXT:'START DATE',
      UNI_END_DT_TEXT:'END DATE',
      UNI_SL_RECEIVERS:'SALE RECEIVERS',
      UNI_OPER_RECEIVERS: 'OPERATION RECEIVERS',
      UNI_COMMENT: 'COMMENT',
      UNI_SITUATION: 'STATUS',
      UNI_INC_DT_TEXT: 'CREATION DATE'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<IUniqueTerm> = this.prepareDataToExport([...this.filteredTermsTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<IUniqueTerm> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `customersUniqueTerms_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: [],
    });
  }

  prepareDataToExport(data: Array<IUniqueTerm>): Array<IUniqueTerm> {
    const dataToExport: Array<IUniqueTerm> = [];

    const datesText = {
      UNI_RECEIVED_DT_TEXT:'',
      UNI_START_DT_TEXT:'',
      UNI_END_DT_TEXT:'',
      UNI_INC_DT_TEXT:''
    }

    data.forEach((term) => {
      const newData = {...term, ...datesText};

      if(newData.UNI_RECEIVED_DT !== null) {
        newData.UNI_RECEIVED_DT_TEXT = moment(newData.UNI_RECEIVED_DT).format('DD/MM/YYYY');
      }

      if(newData.UNI_START_DT !== null) {
        newData.UNI_START_DT_TEXT = moment(newData.UNI_START_DT).format('DD/MM/YYYY');
      }

      if(newData.UNI_END_DT !== null) {
        newData.UNI_END_DT_TEXT = moment(newData.UNI_END_DT).format('DD/MM/YYYY');
      }

      newData.UNI_INC_DT_TEXT = newData.UNI_INC_DT ? moment(newData.UNI_INC_DT).format('DD/MM/YYYY') : null

      delete newData.UNI_NAME,
      delete newData.UNI_RECEIVED_DT,
      delete newData.UNI_START_DT,
      delete newData.UNI_END_DT,
      delete newData.UNI_ID;
      delete newData.CUS_ID;
      delete newData.CARR_ID;
      delete newData.CARR_NAME;
      delete newData.UNI_STATUS;
      //delete newData.UNI_TER_ATTACH;
      delete newData.UNI_USER;
      delete newData.UNI_DATE;
      delete newData.CUS_CODE;
      delete newData.UNI_INC_DT;
      delete newData.UNI_CMT_ATCH;

      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openSidebar(uniqueTermId: number): void {
    this.uniqueTermId = uniqueTermId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';    
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `customer_unique_term`, REG_ID: this.uniqueTermId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.uniqueTermId),
          COMM_REF_GROUP: `customer_unique_term`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'customer_unique_term', REG_ID: this.uniqueTermId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'customer_unique_term', [{ REG_ID: this.uniqueTermId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'customer_unique_term', TIMELINE_REG_ID: this.uniqueTermId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

}
