import { IntegrationLogsComponent } from './integration-logs/integration-logs.component';
import { RpaLogsComponent } from './rpa-logs/rpa-logs.component';
import { PaymentOrderComponent } from './payment-order/payment-order.component';
import { PayablesGenerateComponent } from './payables-generate/payables-generate.component';
import { CustomerUniqueTermComponent } from './unique-terms/customer-unique-term/customer-unique-term.component';
import { CustomersUniqueTermsComponent } from './unique-terms/customers-unique-terms/customers-unique-terms.component';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPrintModule } from 'ngx-print'
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../angular-material.module';
import { ComponentsModule } from '../components/components.module';
import { InvoicesComponent } from './invoices/invoices.component';
import { ProcessContainerComponent } from './process-container/process-container.component';
import { LoginComponent } from './login/login.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ParametersComponent } from './parameters/parameters.component';
import { CustomersComponent } from './customers/customers.component';
import { HomeComponent } from './home/home.component';
import { CustomerGroupsComponent } from './customer-groups/customer-groups.component';
import { LogsComponent } from './logs/logs.component';
import { LogComponent } from './log/log.component';
import { UsersComponent } from './users/users.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { PagesComponent } from './pages/pages.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { AgreementComponent } from './agreement/agreement.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { DefaultRuleComponent } from './default-rule/default-rule.component';
import { DefaultRulesComponent } from './default-rules/default-rules.component';
import { DemurrageManualComponent } from './demurrage-manual/demurrage-manual.component';
import { DialogsModule } from './dialogs.module';
import { DetentionManualComponent } from './detention-manual/detention-manual.component';
import { DemurrageAutomaticComponent } from './demurrage-automatic/demurrage-automatic.component';
import { DetentionAutomaticComponent } from './detention-automatic/detention-automatic.component';
import { InvoicePrintComponent } from './invoice-print/invoice-print.component';
import { NgPipesModule, SlugifyPipe } from 'ngx-pipes';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CarriersUniqueTermsComponent } from './unique-terms/carriers-unique-terms/carriers-unique-terms.component';
import { CarrierUniqueTermComponent } from './unique-terms/carrier-unique-term/carrier-unique-term.component';
import { PayablesComponent } from './payables/payables.component';
import { PayableComponent } from './payable/payable.component';
import { ProcessPayContainerComponent } from './process-pay-container/process-pay-container.component';
import { FinancialDashboardComponent } from './financial-dashboard/financial-dashboard.component';
import { FinancialListComponent } from './financial-list/financial-list.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FinancialCustomersDashboardComponent } from './financial-customers-dashboard/financial-customers-dashboard.component';
import { ShipmentsDrtComponent } from './shipments-drt/shipments-drt.component';
import { CustomerExceptionsComponent } from './customer-exceptions/customer-exceptions.component';
import { CustomerExceptionComponent } from './customer-exception/customer-exception.component';
import { CustomerNotificationLogsComponent } from './customer-notification-logs/customer-notification-logs.component';
import { PartialInvoicesComponent } from './invoices/partial-invoices/partial-invoices.component';

@NgModule({
  declarations: [
    InvoicesComponent,
    PartialInvoicesComponent,
    LoginComponent,
    NotFoundPageComponent,
    ParametersComponent,
    CustomersComponent,
    HomeComponent,
    CustomerGroupsComponent,
    LogsComponent,
    LogComponent,
    UsersComponent,
    UserGroupsComponent,
    PagesComponent,
    ShipmentComponent,
    ShipmentsComponent,
    AgreementsComponent,
    AgreementComponent,
    DefaultRulesComponent,
    DefaultRuleComponent,
    ProcessContainerComponent,
    DemurrageManualComponent,
    DetentionManualComponent,
    DemurrageAutomaticComponent,
    DetentionAutomaticComponent,
    InvoicePrintComponent,
    CustomersUniqueTermsComponent,
    CustomerUniqueTermComponent,
    CarriersUniqueTermsComponent,
    CarrierUniqueTermComponent,
    PayablesComponent,
    PayableComponent,
    ProcessPayContainerComponent,
    PayablesGenerateComponent,
    PaymentOrderComponent,
    FinancialDashboardComponent,
    FinancialListComponent,
    FinancialCustomersDashboardComponent,
    RpaLogsComponent,
    IntegrationLogsComponent,
    ShipmentsDrtComponent,
    CustomerExceptionsComponent,
    CustomerExceptionComponent,
    CustomerNotificationLogsComponent    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgxMaskModule.forRoot(),
    RouterModule,
    AngularMaterialModule,
    ComponentsModule,
    DialogsModule,
    NgxPrintModule,
    NgPipesModule,
    NgxChartsModule,
    NgApexchartsModule
  ],
  exports: [
    LoginComponent
  ],
  providers: [DatePipe, SlugifyPipe, TitleCasePipe, CurrencyPipe]
})
export class PagesModule { }
