import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IGetLog, ILog } from 'src/app/models/log.model';
import { LogService } from 'src/app/services/log.service';

interface IData {
  OLD_DATA: Array<Record<string, any>>,
  NEW_DATA: Array<Record<string, any>>,
  LOG: Array<ILog>
}

@Component({
  selector: 'dhl-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  id: number;
  data: IData;
  oldData: Array<Record<string, any>> = [];
  newData: Array<Record<string, any>> = [];

  log: ILog = {
    LOG_ID: null,
    LOG_USER: null,
    LOG_GROUP: null,
    LOG_ENTITY: null,
    LOG_DATETIME: null,
    LOG_ACTION: null
  };

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private logService: LogService
  ) {
    this.titleService.setTitle('DHL | Log (DDM)');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = Number(params.get('id'));
    });

    const log: IGetLog = {
      LOG_ID: this.id
    }
    
    this.logService.getLogData(log).subscribe((data: IData) => {
      if (data.OLD_DATA) {
        Object.entries(data.OLD_DATA[0]).forEach(([key, value]) => this.oldData.push({key, value}));
      } else {
        this.oldData = null;
      }

      Object.entries(data.NEW_DATA[0]).forEach(([key, value]) => this.newData.push({key, value}));
      this.log = data.LOG[0];
    });
  }

  isDataChanged(itemKey: string): boolean {
    if (!this.oldData) {
      return false;
    }

    if (this.newData.find(element => element.key === itemKey).value !== this.oldData.find(element => element.key === itemKey).value) {
      return true;
    } else {
      return false;
    }
  }

  setTwoDigits(date: number): string {
    return date.toString().length === 1 ? '0' + date.toString() : date.toString();
  }
}
