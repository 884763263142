<header>
  <h2>Log - Invoice Notification</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')"
          *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" (click)="export()"
        [disabled]="notificationLogTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #logTable [dataSource]="notificationLogTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="CUS_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td mat-cell *matCellDef="let element">{{element.CUS_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="INVOICE_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Invoice ID">Invoice ID</th>
        <td [matTooltipDisabled]="!isOverflow(element.INVOICE_ID)" [matTooltip]="element.INVOICE_ID" mat-cell
          *matCellDef="let element">{{element.INVOICE_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="NOTIFICATION_CONTACTS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer Contacts">Customer Contacts</th>
        <td [matTooltipDisabled]="!isOverflow(element.NOTIFICATION_CONTACTS)" [matTooltip]="element.NOTIFICATION_CONTACTS" mat-cell
          *matCellDef="let element">{{element.NOTIFICATION_CONTACTS}}</td>
      </ng-container>
      <ng-container matColumnDef="NOTIFICATION_CREATION_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Creation Date">Creation
          Date</th>
        <td mat-cell *matCellDef="let element">{{element.NOTIFICATION_CREATION_DATE | date:'dd/MM/yyyy HH:mm'}}</td>
      </ng-container>
      <ng-container matColumnDef="NOTIFICATION_DUE_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Due Date">Due
          Date</th>
        <td mat-cell *matCellDef="let element">{{element.NOTIFICATION_DUE_DATE | date:'dd/MM/yyyy HH:mm'}}</td>
      </ng-container>
      <ng-container matColumnDef="NOTIFICATION_SITUATION">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Status</th>
        <td [matTooltipDisabled]="!isOverflow(element.NOTIFICATION_SITUATION)" [matTooltip]="element.NOTIFICATION_SITUATION" mat-cell
          *matCellDef="let element">{{element.NOTIFICATION_SITUATION}}</td>
      </ng-container>
      <ng-container matColumnDef="NOTIFICATION_USER">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by User">User</th>
        <td [matTooltipDisabled]="!isOverflow(element.NOTIFICATION_USER)" [matTooltip]="element.NOTIFICATION_USER" mat-cell
          *matCellDef="let element">{{element.NOTIFICATION_USER}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="notificationLogTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: notificationLogTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>