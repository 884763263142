<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="carrierForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>Account</mat-label>
        <input matInput type="text" name="account" maxlength="12" formControlName="account" required>
        <mat-error *ngIf="carrierForm.get('account').invalid">{{getAccountErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" name="carrierName" maxlength="70" formControlName="carrierName" required>
        <mat-error *ngIf="carrierForm.get('carrierName').invalid">{{getCarrierNameErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the Carrier {{data.carrier.RELATIPF_APCPTK}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="carrierForm.get('account').invalid || carrierForm.get('carrierName').invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="carrierForm.get('account').invalid || carrierForm.get('carrierName').invalid">Ok</button>
    </div>
  </form>
</div>