import { formatDate } from '@angular/common';
import { CustomerGroupsViewDialogComponent } from './customer-groups-dialogs/customer-groups-view-dialog/customer-groups-view-dialog.component';
import { CustomerGroupService } from 'src/app/services/customer-group.service';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { CustomerGroupsDialogComponent, ICustomerGroupDialog } from './customer-groups-dialogs/customer-groups-dialog/customer-groups-dialog.component';
import { CustomerGroupsDeleteAllDialogComponent } from './customer-groups-dialogs/customer-groups-delete-all-dialog/customer-groups-delete-all-dialog.component';
import { IJoinCustomerList } from 'src/app/models/join-customer-group.model';
import { XlsxExporterService } from 'mat-table-exporter';
import { FormControl } from '@angular/forms';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'app-customer-groups',
  templateUrl: './customer-groups.component.html',
  styleUrls: ['./customer-groups.component.scss']
})
export class CustomerGroupsComponent implements OnInit {
  pageKey = 'customerGroups';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<ICustomerGroup>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  customerGroup: ICustomerGroup[] = [];

  customerGroupTableDataSource = new MatTableDataSource<ICustomerGroup>();
  customerGroupTableDisplayedColumns: Array<string> = ['select', 'CUS_GRP_NAME', 'customers', 'actions'];

  @ViewChild(MatTable) customerGroupTable: MatTable<ICustomerGroup>;
  @ViewChild('customerGroupSort') customerGroupSort: MatSort;
  @ViewChild('customerGroupPaginator') customerGroupPaginator: MatPaginator;

  constructor(
    private titleService: Title,
    private customerGroupService: CustomerGroupService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private filterService: FilterService
  ) {
    this.titleService.setTitle('DHL | Customers (Groups)');
  }

  ngOnInit() {
    this.getCustomerGroup();

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.filterCustomerGroupTable(value);
    });
  }

  getCustomerGroup(): void {
    if(!this.customerGroupTableDataSource.filteredData) this.hideLoader = false
    this.customerGroupService.getCustomerGroup().subscribe((customerGroups: Array<ICustomerGroup>) => {
      this.hideLoader = true;
      customerGroups.forEach((group) => {
        delete group.CUS_GRP_USER;
        delete group.CUS_GRP_STATUS;
      });
      this.customerGroupTableDataSource.data = customerGroups;
      this.changeDetectorRef.detectChanges();
      this.customerGroupTableDataSource.sort = this.customerGroupSort;
      this.customerGroupTableDataSource.paginator = this.customerGroupPaginator;
      this.hideLoader = true;

      this.filterCustomerGroupTable(this.globalFilter.value);
    }, (error) => {
      this.toast.error(error.message)
    });
  }

  filterCustomerGroupTable(filter: string) {
    this.customerGroupTableDataSource.filter = filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.customerGroupTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.customerGroupTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  openCustomerGroupDialog(data: ICustomerGroupDialog): void {
    let dialogRef = this.dialog.open(CustomerGroupsDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: ICustomerGroup) => {
      if(!result) return;

      if(data.mode == 'add'){
        this.customerGroupService.postCustomerGroup(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getCustomerGroup();
        },(error) => {
          this.toast.error(error.error.Message);
        });
      } else {
        this.customerGroupService.putCustomerGroup(result).subscribe((response) => {
          if(data.mode == 'edit'){
            this.toast.success(String(response));
          } else {
            this.toast.success('Customer Group removed successfully.');
          }
          this.getCustomerGroup();
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  openCustomerGroupViewDialog(data: ICustomerGroup): void{
    this.dialog.open(CustomerGroupsViewDialogComponent, {
      data: data
    });
  }

  deleteSelected(): void {
    const dialogRef = this.dialog.open(CustomerGroupsDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {
        let groupsIds: Array<number> = [];
        this.selection.selected.forEach((group) => {
          groupsIds.push(group.CUS_GRP_ID);
        });

        let groups: IJoinCustomerList = {
          CUS_GROUP_ID: groupsIds
        }

        this.customerGroupService.deleteSelectedGroups(groups).subscribe((response) => {
          this.getCustomerGroup();
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  export(): void {
    const headers = {
      CUS_GRP_NAME: 'GROUP NAME',
      CUS_NAME_LIST: 'CUSTOMERS'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<ICustomerGroup> = this.prepareDataToExport([...this.customerGroupTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<ICustomerGroup> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `customerGroups_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<ICustomerGroup>): Array<ICustomerGroup> {
    const dataToExport: Array<ICustomerGroup> = [];

    data.forEach((customerGroup) => {
      const newData = {...customerGroup};
      delete newData.CUS_GRP_ID;
      delete newData.CUS_LIST;
      delete newData.CUS_GRP_USER;
      delete newData.CUS_GRP_STATUS;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
