<header>
  <div class="title">
    <a mat-icon-button matTooltip="Return to Log List"aria-label="Return to log list" [routerLink]="['/logs']">
      <mat-icon>chevron_left</mat-icon>
    </a>
    <h2>Log Data</h2>
  </div>
  <div class="header-content">
    <div class="log-info">
      <span>Action</span>
      <p>{{log.LOG_ACTION}}</p>
    </div>
    <div class="log-info">
      <span>Group</span>
      <p>{{log.LOG_GROUP}}</p>
    </div>
    <div class="log-info">
      <span>Entity</span>
      <p>{{log.LOG_ENTITY}}</p>
    </div>
    <div class="log-info">
      <span>User</span>
      <p>{{log.LOG_USER}}</p>
    </div>
    <div class="log-info">
      <span>Date</span>
      <p>{{log.LOG_DATETIME}}</p>
    </div>
  </div>
</header>
<div class="data-container">
  <table *ngIf="oldData">
    <tr>
      <th colspan="2" class="table-header">Old Data</th>
    </tr>
    <tr>
      <th>Field Name</th>
      <th>Value</th>
    </tr>
    <tr *ngFor="let item of oldData" [ngClass]="{'changed': isDataChanged(item.key)}">
      <td>{{item.key}}</td>
      <td>{{item.value}}</td>
    </tr>
  </table>
  <table>
    <tr>
      <th colspan="2" class="table-header">New Data</th>
    </tr>
    <tr>
      <th>Field Name</th>
      <th>Value</th>
    </tr>
    <tr *ngFor="let item of newData" [ngClass]="{'changed': isDataChanged(item.key)}">
      <td>{{item.key}}</td>
      <td>{{item.value}}</td>
    </tr>
  </table>
</div>
