import { IPaymentOrder, IPayable } from 'src/app/models/payable.model';
import { PayableService } from 'src/app/services/payable.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IContainersViewDialog {  
  paymentOrder?: IPaymentOrder;
}

@Component({
  selector: 'dhl-invoices-view-dialog',
  templateUrl: './invoices-view-dialog.component.html',
  styleUrls: ['./invoices-view-dialog.component.scss']
})
export class InvoicesViewDialogComponent implements OnInit {
  invoiceTableDataSource = new MatTableDataSource<IPayable>();
  hasData: boolean = true;
  invoiceTableDisplayedColumns: Array<string> = [
    'PRC_SER',
    'PRC_HBL',
    'PRC_CUS',
    'PRC_SHIP',
    'PB_CAR',
    'PB_INV_NUM',
    'PB_MBL',
    'PB_BK',
    'PB_CTN',
    'PB_CTN_TYPE',
    'PB_CONT',
    'PB_POL',
    'PB_POD',
    'PB_FRT',
    'PB_CH_DAYS',
    'PB_ST_DT',
    'PB_END_DT',
    'PB_VAL_USD',
    'PB_DIS',
    'PB_VAL_DIS',
    'PB_CH_CODE',
  ];
  filterValue: string = '';

  @ViewChild(MatTable) invoiceTable: MatTable<IPayable>;
  @ViewChild('invoiceSort') invoiceSort: MatSort;

  constructor(
    private payableService: PayableService,
    @Inject(MAT_DIALOG_DATA) public data: IContainersViewDialog,
    private dialogRef: MatDialogRef<InvoicesViewDialogComponent>) { }

  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {  
    this.payableService.paymentOrderInvoices(this.data?.paymentOrder).subscribe((invoices: Array<IPayable>) => {
      this.invoiceTableDataSource.data = invoices;
      this.invoiceTableDataSource.sort = this.invoiceSort;
    }, () => {
      if (this.invoiceTableDataSource.data.length == 0) this.hasData = false;
    });
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  closeDialog() {
    this.dialogRef.close({event:'Close'});
  }

  filterInvoiceTable(filterValue: string) {
    this.invoiceTableDataSource.filter = filterValue;
  }
}
