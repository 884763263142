import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

export interface IDefaultRuleDeleteDialog {
  name: string;
}

@Component({
  selector: 'dhl-default-rule-delete-dialog',
  templateUrl: './default-rule-delete-dialog.component.html',
  styleUrls: ['./default-rule-delete-dialog.component.scss']
})
export class DefaultRuleDeleteDialogComponent implements OnInit {
  name: string;

  constructor(public dialogRef: MatDialogRef<DefaultRuleDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IDefaultRuleDeleteDialog) {
    this.name = data.name;
  }

  ngOnInit() {
  }

  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}
