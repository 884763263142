<header>
    <h2>Customer - Exceptions</h2>
    <div class="actions-exceptions">
      <div class="search-exceptions">
        <mat-form-field class="search">
          <mat-label>Search</mat-label>
          <mat-icon matPrefix color="primary">search</mat-icon>
          <input matInput type="search" [formControl]="globalFilter">
          <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
            <mat-icon color="primary">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="actions-group">
        <button mat-stroked-button color="accent" (click)="openFilterDialog()">
          <span>Filters</span>
          <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
        </button>
        <button mat-flat-button color="accent" [routerLink]="['/customer/exception']">Create</button>
        <button mat-flat-button color="accent" (click)="export()" [disabled]="filteredExceptionsTableDataSource.data.length === 0">Export</button>
      </div>
    </div>
  </header>
  <div>
    <ng-container *ngIf="hideLoader; else loader">
      <table mat-table #exceptionsTable [dataSource]="filteredExceptionsTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="CUS_NAME">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Customer / Group</th>
          <td mat-cell [matTooltipDisabled]="!isOverflow(element.CUS_NAME)" [matTooltip]="element.CUS_NAME" *matCellDef="let element">{{element.CUS_NAME}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_CUT_OFF">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Cut Off">Cut Off</th>
          <td [matTooltipDisabled]="!isOverflow(element.EXCEPTION_CUT_OFF)" [matTooltip]="activeMap.get(element.EXCEPTION_CUT_OFF)" mat-cell *matCellDef="let element">{{activeMap.get(element.EXCEPTION_CUT_OFF)}}</td>
        </ng-container>        
        <ng-container matColumnDef="EXCEPTION_CUT_OFF_DAYS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Cut Off Days">Cut Off Days</th>
          <td [matTooltipDisabled]="!isOverflow(element.EXCEPTION_CUT_OFF_DAYS)" [matTooltip]="element.EXCEPTION_CUT_OFF_DAYS" mat-cell *matCellDef="let element">{{element.EXCEPTION_CUT_OFF_DAYS}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_REFERENCE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Reference">Reference</th>
          <td [matTooltipDisabled]="!isOverflow(element.EXCEPTION_REFERENCE)" [matTooltip]="activeMap.get(element.EXCEPTION_REFERENCE)" mat-cell *matCellDef="let element">{{activeMap.get(element.EXCEPTION_REFERENCE)}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_REVIEW">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Review">Review</th>
          <td [matTooltipDisabled]="!isOverflow(element.EXCEPTION_REVIEW)" [matTooltip]="activeMap.get(element.EXCEPTION_REVIEW)" mat-cell *matCellDef="let element">{{activeMap.get(element.EXCEPTION_REVIEW)}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_RECEIVED_DATE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Received Date">Received Date</th>
          <td mat-cell *matCellDef="let element">{{element.EXCEPTION_RECEIVED_DATE | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_START_DATE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Start Date">Start Date</th>
          <td mat-cell *matCellDef="let element">{{element.EXCEPTION_START_DATE | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_END_DATE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by End Date">End Date</th>
          <td mat-cell *matCellDef="let element">{{element.EXCEPTION_END_DATE | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_ANALYSIS_DEADLINE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Analysis Deadline">Analysis Deadline</th>
          <td mat-cell *matCellDef="let element">{{element.EXCEPTION_DEFAULT_DEADLINE === '0' ? element.EXCEPTION_ANALYSIS_DEADLINE : element.ANALYSIS_DEADLINE_DEFAULT}}</td>
        </ng-container>
        <ng-container matColumnDef="EXCEPTION_CMT_ATCH">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Comment/Attachment">Comment / Attachment</th>
          <td [matTooltipDisabled]="!isOverflow(element.EXCEPTION_CMT_ATCH)" [matTooltip]="element.EXCEPTION_CMT_ATCH" mat-cell *matCellDef="let element">
            <a class="cmt-atch" (click)="openSidebar(element.EXCEPTION_ID)">
              <span class="flag"
                [ngClass]="{
                  'grey': element.EXCEPTION_CMT_ATCH === 0,
                  'green': element.EXCEPTION_CMT_ATCH > 0             
                }">
              </span>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [routerLink]="['/customer/exception','edit',element.EXCEPTION_ID]" matTooltip="Edit">
              <mat-icon color="primary">edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete" (click)="deleteShipment(element)">
              <mat-icon color="primary">delete</mat-icon>
            </button>
            <button mat-icon-button [routerLink]="['/customer/exception','view',element.EXCEPTION_ID]" matTooltip="See details">
              <mat-icon color="primary">info</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="exceptionsTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: exceptionsTableDisplayedColumns;"></tr>
      </table>
      <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
    </ng-container>
    <ng-template #loader>
      <dhl-spinner></dhl-spinner>
    </ng-template>
  </div>
  <dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
    <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
    <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
    <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
  </dhl-sidebar>