import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { IAttachment } from 'src/app/models/attachment.model';
import { IComment } from 'src/app/models/comment.model';
import { ICustomerNotification } from 'src/app/models/customer.model';
import { IInvoice, IInvoiceContainer } from 'src/app/models/invoice.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { AwsService } from 'src/app/services/aws.service';
import { CommentService } from 'src/app/services/comment.service';
import { CustomerService } from 'src/app/services/customer.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { TimelineService } from 'src/app/services/timeline.service';

@Component({
  selector: 'dhl-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.scss']
})
export class InvoicePrintComponent implements OnInit {
  invoiceId: number;
  invoice: IInvoice;
  invoiceContainers: Array<IInvoiceContainer>;

  isPartial = false;
  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private awsService: AwsService,
    private commentService: CommentService,
    private attachmentService: AttachmentService,
    private timelineService: TimelineService,
    private authService: AuthService,
    private toast: HotToastService,
    private customerService: CustomerService
  ) {
    this.titleService.setTitle('DHL | Invoice');
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.invoiceId = Number(params.id));
    this.route.url.subscribe(segment => this.isPartial = segment[1].path === 'partial');
    
    if(this.isPartial) this.invoiceService.getPartialInvoice({INVOICE_ID: this.invoiceId.toString()}).subscribe(invoice => this.invoice = invoice[0]);
    if(!this.isPartial) this.invoiceService.getInvoice({INVOICE_ID: this.invoiceId.toString()}).subscribe(invoice => this.invoice = invoice[0]);

    this.invoiceService.getInvoiceContainer({INVOICE_ID: this.invoiceId.toString()}).subscribe(containers => this.invoiceContainers = containers);
    this.getComments();
    this.getAttachments();
    this.getTimeline();
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `invoice`, REG_ID: this.invoiceId }).subscribe(comments => this.comments = comments)
  }

  getNameProforma(): string{
    const name = this.invoice?.INVOICE_TYPE == 'DEMURRAGE' ? `Proforma DEM-${this.invoiceId}` : `Proforma DET-${this.invoiceId}`;
    return name;
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.invoiceId),
          COMM_REF_GROUP: `invoice`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'invoice', REG_ID: this.invoiceId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'invoice', [{ REG_ID: this.invoiceId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'invoice', TIMELINE_REG_ID: this.invoiceId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  sendNotification(): void{
    const notification: Array<ICustomerNotification> = [
      {
        INVOICE_ID: Number(this.invoice.INVOICE_ID),
        NOTIFICATION_INVOICE_URL: this.invoice.NOTIFICATION_INVOICE_URL,
        NOTIFICATION_USER: this.authService.userId
      }
    ]

    this.customerService.postCustomerNotification(notification).subscribe((response) => {
      this.toast.success(String(response));
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  goBack(){
    window.history.back();
  }
}
