import { IDialogData } from './../../../../interfaces/dialog-data.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { Component, Inject, OnInit } from '@angular/core';

export interface ICustomDateDialog {
  date: Moment;
  willDelete: boolean;
}

@Component({
  selector: 'dhl-custom-date-dialog',
  templateUrl: './custom-date-dialog.component.html',
  styleUrls: ['./custom-date-dialog.component.scss']
})
export class CustomDateDialogComponent implements OnInit {
  dateForm: FormGroup;

  comment: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CustomDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<Date>,
  ) { }

  ngOnInit() {
    this.dateForm = this.formBuilder.group({
      date: new FormControl(this.data?.element, [Validators.required])
    });
  }

  closeDialog(value: [ICustomDateDialog, string]): void {
    this.dialogRef.close(value);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    if (this.data.mode === 'add' || this.data.mode === 'edit') {
      const date: ICustomDateDialog = {
        date: this.dateForm.get('date').value,
        willDelete: false
      }
      this.closeDialog([date, this.comment]);
    } else if (this.data.mode === 'delete') {
      const date: ICustomDateDialog = {
        date: null,
        willDelete: true
      }
      this.closeDialog([date, this.comment]);
    }
  }

  onComment(comment: string) {
    this.comment = comment;
  }
}
