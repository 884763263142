<h1 mat-dialog-title>Filter customers</h1>
<div mat-dialog-content>
  <form [formGroup]="filterForm" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" name="name" maxlength="100" formControlName="name">
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('name').setValue('')" *ngIf="filterForm.get('name').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput type="text" name="state" maxlength="2" formControlName="state">
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('state').setValue('')" *ngIf="filterForm.get('state').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Address</mat-label>
        <input matInput type="text" name="address" maxlength="400" formControlName="address">
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('address').setValue('')" *ngIf="filterForm.get('address').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetFilters()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>