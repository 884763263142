import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IContainer } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';

interface IRejectedDateDialog {
  element: IContainer;
}

@Component({
  selector: 'dhl-date-rejected-dialog',
  templateUrl: './date-rejected-dialog.component.html',
  styleUrls: ['./date-rejected-dialog.component.scss']
})
export class DateRejectedDialogComponent implements OnInit {
  dateForm = this.formBuilder.group({
    date: new UntypedFormControl(this.data.element.CTN_GOU)
  });
  
  minEmpty: Date;
  maxEmpty: Date = new Date();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DateRejectedDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: IRejectedDateDialog,
  ) { }

  ngOnInit(): void { 
    this.minEmpty = new Date(this.data.element.CTN_ATA);
    this.dateForm.get('date').setValue('');
  }

  closeDialog(element: IContainer): void {
    this.dialogRef.close(element);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    
    const container: IContainer = {
      SHIP_ID: this.data.element.SHIP_ID,
      CTN_ID: this.data.element.CTN_ID,
      CTN_NUMBER: this.data.element.CTN_NUMBER,
      CTN_GOU: this.dateForm.get('date').value,
      CTN_USER: this.authService.userId
    }

    this.closeDialog(container);
  }
}
