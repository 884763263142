import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IPrtlCtn } from 'src/app/models/prtl-ctn';
import { IContainer } from 'src/app/models/shipment.model';
import { freetimeValidator } from './freetime-validator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface INewPrtlCtnDetentionDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  container?: IContainer;
}

@Component({
  selector: 'app-new-prtl-detention-dialog',
  templateUrl: './new-prtl-detention-dialog.component.html',
  styleUrls: ['./new-prtl-detention-dialog.component.scss']
})
export class NewPrtlDetentionDialogComponent implements OnInit {
  form: UntypedFormGroup;
  isFormEnabled: boolean = true;

  minFinal: Date;
  maxFinal: Date = new Date();

  isTank = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NewPrtlDetentionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INewPrtlCtnDetentionDialog,
    private authService: AuthService
  ) { }

  ngOnInit() {
    console.log(this.data.container)

    this.form = this.formBuilder.group({
      ctnStart: new UntypedFormControl(this.data.container?.START_DATE || this.data.container?.CTN_GOU, [Validators.required]),
      ctnFinal: new UntypedFormControl('', [Validators.required, freetimeValidator(this.data.container)])
    });

    this.minFinal = this.form.get('ctnStart').value;
    this.isTank = this.data.container.CTN_TYPE.toString().includes('TC');
  }

  closeDialog(prtlCtn: IPrtlCtn) {
    this.dialogRef.close(prtlCtn);
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.form.value;
    let prtlCtn: IPrtlCtn = null;

    if (this.data.mode == 'add') {
      prtlCtn = {
        CTN_ID: this.data.container.CTN_ID,
        PRTL_CTN_GOU: formValue.ctnStart,
        PRTL_CTN_GIN: formValue.ctnFinal,
        PRTL_CTN_LDG: formValue.ctnFinal,
        PRTL_CTN_USER: this.authService.userId
      }
    }

    this.closeDialog(prtlCtn);
  }
}
