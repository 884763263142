import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { IShipment } from './../../models/shipment.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { ShipmentDrtService } from 'src/app/services/shipment-drt.service';
import { ChangeStatusDialogComponent, IChangeStatusDialog } from './shipments-drt-dialogs/change-status-dialog/change-status-dialog.component';
import { FilterDialogComponent } from './shipments-drt-dialogs/filter-dialog/filter-dialog.component';

@Component({
  selector: 'app-shipments-drt',
  templateUrl: './shipments-drt.component.html',
  styleUrls: ['./shipments-drt.component.scss']
})
export class ShipmentsDrtComponent implements OnInit {
  readonly pageKey = 'shipmentDrt';

  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IShipment>(this.allowMultiSelect, this.initialSelection);
  
  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IShipment;
  filtersNumber: number = 0;

  shipmentTableDataSource = new MatTableDataSource<IShipment>();
  shipmentExportDataSource = new MatTableDataSource<IShipment>();
  filteredShipmentTableDataSource = new MatTableDataSource<IShipment>();
  shipmentTableDisplayedColumns: Array<string> = ['select','SHIP_DESTINY','SHIP_HBL','SHIP_CODE','SHIP_MBL','SHIP_CUS_NAME','SHIP_CAR_NAME','SHIP_CTN_COUNT','SHIP_POL_NAME','SHIP_POD_NAME','SHIP_CONSOL_TYPE','SHIP_INC','SHIP_CTN_MODE','SHIP_DPT_CODE','SHIP_ATD','SHIP_ATA','SHIP_EXC_DESC','SHIP_EXC_DATE','actions'];

  @ViewChild(MatTable) shipmentTable: MatTable<IShipment>;

  shipmentId: number;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private shipmentDrtService: ShipmentDrtService,
    private filterService: FilterService,
    private authService: AuthService
  ) {
    this.titleService.setTitle('DHL | Shipments DRT');
  }

  ngOnInit(): void {
    this.filterEntity = {
      SHIP_DESTINY: null,
      SHIP_CODE: null,
      SHIP_HBL: null,
      SHIP_MBL: null,
      SHIP_CUS: null,
      SHIP_CAR: null,
      DRT_STATUS: null,
      SHIP_INC: null,
      SHIP_CTN_MODE: null,
      SHIP_DPT_CODE: null,
      SHIP_POL: null,
      SHIP_POD: null,
      SHIP_ATD_START_DT: null,
      SHIP_ATD_FINAL_DT: null,
      SHIP_ATA_START_DT: null,
      SHIP_ATA_FINAL_DT: null,
      SHIP_INC_START_DT: null,
      SHIP_INC_FINAL_DT: null
    }

    const filter = this.filterService.getFilter(this.pageKey);
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getShipmentDrt(this.filterEntity);
    });

    this.getShipmentDrt(this.filterEntity);
  }

  getShipmentDrt(filter: IShipment): void{
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredShipmentTableDataSource.data.length === 0) this.hideLoader = false;
    this.shipmentDrtService.getShipmentDrt({
        ...filter, 
        SEARCH: search, 
        SORT_COL: this.sortCol,
        SORT_ORDER: this.sortOrder, 
        PAGE_NUMBER: this.pageNumber, 
        PAGE_SIZE: this.pageSize
    }).subscribe((shipments: Array<IShipment>) => {
      this.hideLoader = true;
      shipments.forEach(ship=> ship.DRT_ACTIVE = ship.DRT_STATUS === '1' ? true : false);
      this.shipmentTableDataSource.data = shipments;
      this.filteredShipmentTableDataSource.data = shipments;
      this.length = shipments[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredShipmentTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.filteredShipmentTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getShipmentDrt(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getShipmentDrt(this.filterEntity);
  }

  export(): void {
    const headers = {
      SHIP_DESTINY: 'SERVICE',
      SHIP_HBL:'HOUSE BILL',
      SHIP_CODE:'SHIPMENT',
      SHIP_MBL:'MASTER BILL',
      SHIP_CUS_NAME: 'CUSTOMER',
      SHIP_CAR_NAME: 'CARRIER',
      SHIP_CTN_COUNT: 'CONTAINER COUNT',
      SHIP_POL_NAME: 'ORIGIN PORT',
      SHIP_POD_NAME: 'DESTINATION PORT',
      SHIP_CONSOL_TYPE: 'CONSOL TYPE',
      SHIP_INC: 'INCOTERM',
      SHIP_CTN_MODE: 'MODE',
      SHIP_DPT_CODE: 'DEPT',
      SHIP_ATD_TEXT: 'ATD',
      SHIP_ATA_TEXT: 'ATA',
      SHIP_EXC_DESC: 'EXCEPTION DESCRIPTION',
      SHIP_EXC_DATE_TEXT: 'EXCEPTION DATE'
    }

    const dataToExport: Array<any> = [headers];

    if(this.selection.isEmpty()){
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.shipmentDrtService.getShipmentDrt({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.shipmentExportDataSource.data = dataExport;
        const data: Array<IShipment> = this.prepareDataToExport([...this.shipmentExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `shipmentsDrt_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    }else{
      console.log(this.selection.selected)
      const data: Array<IShipment> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `shipmentsDrt_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }    
  }

  prepareDataToExport(data: Array<IShipment>): Array<IShipment> {
    const dataToExport: Array<IShipment> = [];

    const datesText = {
      SHIP_ATA_TEXT: '',
      SHIP_ATD_TEXT: '',
      SHIP_EXC_DATE_TEXT: ''
    }

    data.forEach((shipment) => {
      const newData = {...shipment, ...datesText};

      if(newData.SHIP_ATA !== null) {
        newData.SHIP_ATA_TEXT = moment(newData.SHIP_ATA).format('DD/MM/YYYY');
      }

      if(newData.SHIP_ATD !== null) {
        newData.SHIP_ATD_TEXT = moment(newData.SHIP_ATD).format('DD/MM/YYYY');
      }

      newData.SHIP_EXC_DATE_TEXT = newData.SHIP_EXC_DATE ? moment(newData.SHIP_EXC_DATE).format('DD/MM/YYYY') : null;

      delete newData.SHIP_ATA;
      delete newData.SHIP_ATD;
      delete newData.SHIP_ID;
      delete newData.SHIP_COU_CODE;
      delete newData.SHIP_COMM_CODE;
      delete newData.SHIP_CO_CODE;
      delete newData.SHIP_TRANS_MODE;
      delete newData.SHIP_DLV_COU;
      delete newData.SHIP_PU_COU;
      delete newData.SHIP_CNEE;
      delete newData.SHIP_LOC_CUS;
      delete newData.SHIP_NTF_PT;
      delete newData.SHIP_EXP;
      delete newData.SHIP_POL;
      delete newData.SHIP_POD;
      delete newData.SHIP_ETA;
      delete newData.SHIP_ETD;
      delete newData.SHIP_REFERENCE;
      delete newData.SHIP_STATUS;
      delete newData.SHIP_USER;
      delete newData.SHIP_DUP_MBL;
      delete newData.SHIP_EXC_DATE;
      delete newData.DRT_STATUS;
      delete newData.DRT_ACTIVE;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  setFilters(){
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
       element: this.filterEntity
      }
    });

    dialogRef.afterClosed().subscribe((filters: IShipment)=>{
      if(!filters) return;

      this.filterEntity = filters
      this.filtersNumber = this.filterService.getFilterQuantity(filters);
      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('shipmentDrt', this.filterEntity);

      this.getShipmentDrt(this.filterEntity);
    });
  }

  onToggleSlide(ship: IShipment) {
    const shipDrt: Array<IShipment> = [
      {
        DRT_HBL: ship.SHIP_HBL,
        DRT_STATUS: ship.DRT_ACTIVE ? '1' : '0',
        DRT_USER: this.authService.userId
      }
    ]

    this.shipmentDrtService.postShipmentDrt(shipDrt).subscribe((response)=>{
      this.toast.success(String(response))
    },(error)=>{
      ship.DRT_ACTIVE = !ship.DRT_ACTIVE;
      this.toast.error(error.error.Message);
    });
  }

  changeStatus(data: IChangeStatusDialog): void {
    const dialog = this.dialog.open(ChangeStatusDialogComponent, { data });

    dialog.afterClosed().subscribe((shipments: Array<IShipment>) => {    
      if (!shipments) {
        return;
      }

      this.shipmentDrtService.postShipmentDrt(shipments).subscribe((response)=>{
        this.toast.success(String(response));
        this.getShipmentDrt(this.filterEntity);
      },(error)=>{
        this.toast.error(error.error.Message);
      })
      
    });
  }
}