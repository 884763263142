import { BudgetDialogComponent } from './budget-dialog/budget-dialog.component';
import { RateDialogComponent } from './rate-dialog/rate-dialog.component';
import { ImportFinancialDialogComponent } from './import-financial-dialog/import-financial-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { ContainerDialogComponent } from './container-dialog/container-dialog.component';

@NgModule({
  declarations: [
    FilterDialogComponent,
    ImportFinancialDialogComponent,
    RateDialogComponent,
    BudgetDialogComponent,
    ContainerDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxDropzoneModule,
    ComponentsModule,
    NgxMatSelectSearchModule
  ]  
})
export class FinancialDialogsModule { }
