import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupsDialogComponent } from './user-groups-dialog/user-groups-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UserGroupViewDialogComponent } from './user-group-view-dialog/user-group-view-dialog.component';
import { UserGroupDeleteAllDialogsComponent } from './user-group-delete-all-dialogs/user-group-delete-all-dialogs.component';

@NgModule({
  declarations: [
    UserGroupsDialogComponent,
    UserGroupViewDialogComponent,
    UserGroupDeleteAllDialogsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxMatSelectSearchModule
  ]
})
export class UserGroupsDialogsModule { }
