import { IFinancialImport } from 'src/app/models/financial.model';
import { IFinancial } from './../models/financial.model';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  importFromExcel(file): Observable<Array<IFinancialImport>> {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    return new Observable<Array<IFinancialImport>>((observer): void => {
      fileReader.onload = (ev: ProgressEvent): void => {
        let binary = "";
        let bytes = new Uint8Array((<any>ev.target).result);
        let length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        
        const workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
        const excelData = workbook.SheetNames.reduce((initial: any, name: any) => {
          return XLSX.utils.sheet_to_json(workbook.Sheets[name]);
        }, {});
        
        const wsname = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsname];

        const headers = []
        const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1
        for (let i = 0; i < columnCount; ++i) {
          headers[i] = ws[`${XLSX.utils.encode_col(i)}1`].v
        }

        const financialHeaders = [
          'JOB NUM',
          'SALES GROUP',
          'CHARGE GROUP',
          'CHARGE CODE',
          'CHARGE CODE & DESC',
          'TRN BRANCH',
          'TRN DEPT',
          'TRN DESC',
          'LINE TYPE',
          'TRN RECOG DATE',
          'INVOICE NUMBER',
          'INVOICE DATE',
          'LOCAL AMOUNT',
          'IS APPORTIONED (Y/N)',
          'MOST RELEVANT CONSOL',
          'LEDGER TYPE',
          'TRN TYPE',
          'TRN ORG',
          'TRN ORG NAME',
          'POSTING DATE',
          'ACCT GRP CD',
          'JOB RECOG MONTH (YYYY/MM)',
          'TRN RECOG MONTH (YYYY/MM)',
          'OUT OF PERIOD',
          'IS AUTO RATED',
          'RATING OVERRIDDEN',
          'CFX PERCENT TYPE',
          'CREATED USED',
          'JOB STATUS',
          'JOB BRANCH',
          'JOB DEPT',
          'JOB OPEN DATE',
          'JOB RECOG DATE',
          'JOB CLOSE DATE',
          'JOB SALES REP',
          'JOB NUMBER COMPANY',
          'TRN INCOME',
          'TRN PROFIT',
          'TRN EXPENSE',
          'INCOME EUR',
          'TRN PROFIT EUR',
          'EXPENSE EUR',
          'OS VAT',
          'TRANSPORT MODE',
          'OS AMOUNT',
          'EXCHANGE RATE',
          'OS CCY',
          'LOCAL CLIENT',
          'SHIPPER SUPPLIER',
          'CONSIGNEE IMPORTER',
          'HAWB',
          'MAWB',
          'ORIGIN ETD',
          'DESTINATION ETA',
          'SUMMARY DESC',
          'DEBTOR CODE',
          'POSTING PERIOD (MONTH)',
          'POSTING PERIOD (YEAR)',
          'CLIENT NAME'
        ];
        
        if(JSON.stringify(financialHeaders)!==JSON.stringify(headers)){
          observer.error('File is not a valid Financial spreadsheet');
        }

        observer.next(excelData);
        observer.complete();
      } 

      fileReader.onerror = (error: any): void => {
        observer.error(error);
      }
    });
  }

}