<h1 mat-dialog-title>Attachment</h1>
<form (submit)="handleSubmit($event)">
  <div mat-dialog-content class="wide">
    <dhl-attachment [showAttachmentsTab]="false" (upload)="onUpload($event)"></dhl-attachment>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="files.length === 0">Apply</button>
    </div>
  </div>
</form>
