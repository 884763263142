import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatTableFilter } from 'mat-table-filter';
import moment from 'moment';
import { IProcessPayContainer } from 'src/app/models/process-pay-container';
import { AuthService } from 'src/app/services/auth.service';
import { FilterService } from 'src/app/services/filter.service';
import { ProcessPayContainerService } from 'src/app/services/process-pay-container.service';
import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-process-pay-container',
  templateUrl: './process-pay-container.component.html',
  styleUrls: ['./process-pay-container.component.scss']
})
export class ProcessPayContainerComponent implements OnInit {
  pageKey = '';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IProcessPayContainer>(this.allowMultiSelect, this.initialSelection);
  
  globalFilter: FormControl = new FormControl('');

  destiny: string;

  filterEntity: IProcessPayContainer;

  filtersNumber: number = 0;

  processPayTableDataSource = new MatTableDataSource<IProcessPayContainer>();
  processPayExportDataSource = new MatTableDataSource<IProcessPayContainer>();
  filteredProcessPayTableDataSource = new MatTableDataSource<IProcessPayContainer>();

  processPayTableDisplayedColumns: Array<string> = [
    'select',
    'PF_CUS',
    'PF_HBL',
    'SHIP_DPT_CODE',
    'PF_CTN',
    'PF_CTN_TYPE',
    'PF_CAR',
    'PF_MBL',
    'PF_VES',
    'PF_VOY',
    'PF_POL',
    'PF_POD',
    'PF_ATD',
    'PF_ATA',
    'PF_GOU',
    'PF_GIN',
    'PF_LDG',
    'PF_FRT',
    'PF_CHARGE_DAYS',
    'PF_PERDIEM',
    'PF_AMT',
    'PF_AGREEMENT',
    'PF_INC_DATETIME',
    'PF_IS_PARTIAL',
    'actions'
  ];

  @ViewChild(MatTable) processPayTable: MatTable<IProcessPayContainer>;
  @ViewChild('processPaySort') processPaySort: MatSort;
  @ViewChild('processPayPaginator') processPayPaginator: MatPaginator;

  lastShipId: number;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private payCtnService: ProcessPayContainerService,
    private route: ActivatedRoute,
    private exporter: XlsxExporterService,
    private authService: AuthService,
    private toast: HotToastService,
    private filterService: FilterService,
    private titlecasePipe:TitleCasePipe,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.route.url.subscribe((route) => {
      this.destiny = route[0].path;

      if (this.destiny === 'demurrage'){
        this.pageKey = 'demurragePayables';
      } else {
        this.pageKey = 'detentionPayables';
      }

      this.titleService.setTitle(`DHL | ${this.titlecasePipe.transform(this.destiny)} (Payables Forecast)`);

      this.globalFilter.setValue(this.filterService.getSearch(this.pageKey) || '');
    });
    
    this.checkDestiny();

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getProcessContainer(this.filterEntity);
    });

    this.getProcessContainer(this.filterEntity);
  }

  getProcessContainer(filter: IProcessPayContainer) {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredProcessPayTableDataSource.data.length === 0) this.hideLoader = false;
    this.payCtnService.getProcessPayContainer({
      ...filter,
      PF_DESTINY: this.destiny,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((containers) =>{
      this.hideLoader = true;
      this.processPayTableDataSource.data = containers;
      this.filteredProcessPayTableDataSource.data = containers;
      this.length = containers[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredProcessPayTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.filteredProcessPayTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getProcessContainer(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getProcessContainer(this.filterEntity);
  }

  checkDestiny(): void{
    if (this.destiny === 'demurrage'){
      const list: Array<string> = [
        'PF_GIN',
        'PF_LDG',
        'PF_MBL',
        'PF_VES',
        'PF_VOY'
      ];

      list.forEach(item =>{
        const index = this.processPayTableDisplayedColumns.indexOf(item);
        this.processPayTableDisplayedColumns.splice(index, 1);
      });
    }else{
      const list: Array<string> = [
        'PF_ATD',
        'PF_ATA'
      ];

      list.forEach(item =>{
        const index = this.processPayTableDisplayedColumns.indexOf(item);
        this.processPayTableDisplayedColumns.splice(index, 1);
      });
    }
  }

  recalculate(container: IProcessPayContainer | Array<IProcessPayContainer>) {
    let newContainers: Array<IProcessPayContainer> = [];

    if (container instanceof Array) {
      newContainers = container.map((c) => {
        return {...c, PROCESS_USER: this.authService.userId}
      });
    } else {
      newContainers.push({...container, PF_USER: this.authService.userId});
    }

    this.payCtnService.recalculateContainer(newContainers).subscribe((response) => {
      this.toast.success(String(response));
      this.getProcessContainer(this.filterEntity);
    }, (error) => {
      this.toast.error(error.error.Message);
    });
  }

  showSeparatorRow(index: number, element: IProcessPayContainer) {
    if (index === 0) {
      this.lastShipId = element.PF_SHIP;
      return false;
    }

    if (this.lastShipId === null || this.lastShipId === undefined) {
      this.lastShipId = element.PF_SHIP;
      return false;
    }

    if (this.lastShipId === element.PF_SHIP) {
      this.lastShipId = element.PF_SHIP;
      return false;
    }

    this.lastShipId = element.PF_SHIP;
    return true;
  }

  export(): void {
    const headers = {
      PF_CUS:'CUSTOMER',
      PF_HBL:'HOUSE BILL',
      SHIP_DPT_CODE:'DEPT',
      PF_CTN:'CONTAINER',
      PF_CTN_TYPE:'TYPE',
      PF_CAR:'CARRIER',
      PF_MBL:'MATER BILL',
      PF_VES:'VESSEL',
      PF_VOY:'VOYAGE',
      PF_POL:'ORIGIN PORT',
      PF_POD:'DESTINATION PORT',
      PF_ATD_TEXT:'LOADING',
      PF_ATA_TEXT:'ARRIVAL',
      PF_GOU_TEXT: this.destiny === 'demurrage' ? 'EMPTY RETURN' : 'GATE OUT',
      PF_GIN_TEXT:'GATE IN',
      PF_LDG_TEXT:'LOADING',
      PF_ARR_DATE_TEXT:  'START CHARGE',
      PF_RET_DATE_TEXT: 'END CHARGE',
      PF_FRT:'FREE TIME',
      PF_CHARGE_DAYS:'CHARGE DAYS',
      PF_PERDIEM: 'PERDIEM',
      PF_AMT:'AMOUNT USD',
      PF_AGREEMENT:'DDM AGREEMENT',
      PF_INC_DATETIME_TEXT:'INCLUSION DATE',
      PF_EXC_DESC:'EXCEPTION DESCRIPTION',
      PF_EXC_DATE_TEXT:'EXCEPTION DATE',
      PF_PRTL_TEXT:'IS PARTIAL',
    }

    if (this.destiny === 'demurrage'){
      const list: Array<string> = [
        'PF_GIN_TEXT',
        'PF_LDG_TEXT',
        'PF_MBL',
        'PF_VES',
        'PF_VOY',
        'PF_EXC_DESC',
        'PF_EXC_DATE_TEXT',
        'PF_ARR_DATE_TEXT',
        'PF_RET_DATE_TEXT',
      ];

      list.forEach(item =>{
        delete headers[item];
      });
    }else{
      const list: Array<string> = [
        'PF_ATD_TEXT',
        'PF_ATA_TEXT'
      ];

      list.forEach(item =>{
        delete headers[item];
      });
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.payCtnService.getProcessPayContainer({
          ...this.filterEntity,
          EXPORT: 1,
          PF_DESTINY: this.destiny,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.processPayExportDataSource.data = dataExport;

        const data: Array<IProcessPayContainer> = this.prepareDataToExport([...this.processPayExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `${this.destiny}PayForecast_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    } else {
      const data: Array<IProcessPayContainer> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `${this.destiny}PayForecast_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }    
  }

  prepareDataToExport(data: Array<IProcessPayContainer>): Array<IProcessPayContainer> {
    const dataToExport: Array<IProcessPayContainer> = [];

    const datesText = {
      PF_GOU_TEXT: '',
      PF_GIN_TEXT: '',
      PF_LDG_TEXT: '',
      PF_ATD_TEXT: '',
      PF_ATA_TEXT: '',
      PF_INC_DATETIME_TEXT: '',
      PF_EXC_DATE_TEXT: '',
      PF_ARR_DATE_TEXT:  '',
      PF_RET_DATE_TEXT: '',
      PF_PRTL_TEXT: ''
    }

    data.forEach((processContainer) => {
      const newData = {...processContainer, ...datesText};

      if(newData.PF_GOU !== null) {
        newData.PF_GOU_TEXT = moment(newData.PF_GOU).format('DD/MM/YYYY');
      }

      if(newData.PF_GIN !== null) {
        newData.PF_GIN_TEXT = moment(newData.PF_GIN).format('DD/MM/YYYY');
      }

      if(newData.PF_LDG !== null) {
        newData.PF_LDG_TEXT = moment(newData.PF_LDG).format('DD/MM/YYYY');
      }

      if(newData.PF_ATD !== null) {
        newData.PF_ATD_TEXT = moment(newData.PF_ATD).format('DD/MM/YYYY');
      }

      if(newData.PF_ATA !== null) {
        newData.PF_ATA_TEXT = moment(newData.PF_ATA).format('DD/MM/YYYY');
      }

      if(newData.PF_INC_DATETIME !== null) {
        newData.PF_INC_DATETIME_TEXT = moment(newData.PF_INC_DATETIME).format('DD/MM/YYYY');
      }

      if(newData.PF_EXC_DATE !== null) {
        newData.PF_EXC_DATE_TEXT = moment(newData.PF_EXC_DATE).format('DD/MM/YYYY');
      }

      if(newData.PF_ARR_DATE !== null) {
        newData.PF_ARR_DATE_TEXT = moment(newData.PF_ARR_DATE).format('DD/MM/YYYY');
      }

      if(newData.PF_RET_DATE !== null) {
        newData.PF_RET_DATE_TEXT = moment(newData.PF_RET_DATE).format('DD/MM/YYYY');
      }

      newData.PF_PRTL_TEXT = newData.PF_IS_PARTIAL === '1' ? 'YES' : 'NO';

      delete newData.PF_GOU;
      delete newData.PF_GIN;
      delete newData.PF_LDG;
      delete newData.PF_ATD;
      delete newData.PF_ATA;
      delete newData.PF_INC_DATETIME;
      delete newData.PF_EXC_DATE;
      delete newData.PF_ID;
      delete newData.PF_SHIP;
      delete newData.PF_TOTAL_CTN;
      delete newData.PF_ARR_DATE;
      delete newData.PF_RET_DATE;
      delete newData.PF_STATUS;
      delete newData.PF_DIS;
      delete newData.PF_DIS_AMT;
      delete newData.PF_PRTL_CTN_ID;
      delete newData.PF_IS_PARTIAL;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;

      if (this.destiny === 'demurrage'){
        delete newData.PF_GIN_TEXT;
        delete newData.PF_LDG_TEXT;
        delete newData.PF_MBL;
        delete newData.PF_VES;
        delete newData.PF_VOY;
        delete newData.PF_EXC_DESC;
        delete newData.PF_EXC_DATE_TEXT;
        delete newData.PF_ARR_DATE_TEXT;
      delete newData.PF_RET_DATE_TEXT;
      }else{
        delete newData.PF_ATD_TEXT;
        delete newData.PF_ATA_TEXT;
      }

      dataToExport.push(newData);
    });

    return dataToExport;
  }

}
