import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { ExceptionsDialogComponent } from './exceptions-dialog/exceptions-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MasterbillDialogComponent } from './masterbill-dialog/masterbill-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ContainersDialogComponent } from './containers-dialog/containers-dialog.component';

@NgModule({
  declarations: [ 
    ExceptionsDialogComponent, MasterbillDialogComponent, ContainersDialogComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule
  ]
})
export class ShipmentDialogsModule { }
