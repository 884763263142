import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ILoginInfo } from '../models/login-info.model';
import { IUserCredentials } from '../models/user-credentials.model';
import { IUser } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthService {
  protected apiURL = environment.apiURL;

  public userId: string = '';
  public userName: string = '';
  public userAllowedUrls: Array<string> = [];
  public isUserAdmin: boolean = false;
  public hasLoadedInfo: boolean = false;

  constructor(
    protected httpClient: HttpClient,
    private router: Router
  ) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json, text/plain, */*'
    })
  }

  protected httpOptionsLogout = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  login(user: IUser) {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', user.PI_USER_ID)
      .set('password', user.PI_USER_PASS);

    return this.httpClient.post<ILoginInfo>(`${this.apiURL}/api/seguranca/token`, body.toString(), this.httpOptions);
  }

  loginFromHash(hash: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `bearer ${hash}`,
      })
    }

    return this.httpClient.get<any>(`https://sso.bonobotec.link/api/user`, httpOptions);
  }

  getCredentials(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${token}`,
      })
    };

    return this.httpClient.get<IUserCredentials>(`${this.apiURL}/api/user/identity`, httpOptions);
  }

  logout(): void {
    this.httpClient.post(`${this.apiURL}/api/user/logout`, { PI_USER_ID: this.userId }, this.httpOptionsLogout).subscribe();
    
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public get isTokenExpired(): boolean {
    if (!localStorage.getItem('expiry')) {
      return false;
    }

    if ((Math.floor(new Date().getTime() / 1000)) > Number(localStorage.getItem('expiry'))) {
      return true;
    } else {
      return false;
    }
  }
}
