import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier, IContainerType, IPort } from 'src/app/models/parameter.model';
import { IContainer } from 'src/app/models/shipment.model';
import { CustomerService } from 'src/app/services/customer.service';
import { ParameterService } from 'src/app/services/parameter.service';

export interface IFilterDialog {
  element: IContainer;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  customers: Array<ICustomer> = [];  

  containerTypes: Array<IContainerType> = [];
  filteredContainerTypes: Subject<Array<IContainerType>> = new Subject<Array<IContainerType>>();

  carriers: Array<ICarrier> = [];
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  ports: Array<IPort> = [];
  filteredOriginPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();
  filteredDestinationPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog
  ) { }

  ngOnInit(): void {    
    this.form = this.formBuilder.group({
      container: new UntypedFormControl(this.data.element?.CTN_NUMBER),
      hbl: new UntypedFormControl(this.data.element?.CTN_HBL),
      mbl: new UntypedFormControl(this.data.element?.CTN_MBL),
      type: new UntypedFormControl(this.data.element?.CTN_TYPE),
      typeFilter: new UntypedFormControl(''),
      customer: new UntypedFormControl(this.data.element?.CTN_CUSTOMER),
      customerFilter: new UntypedFormControl(''),
      carrier: new UntypedFormControl(this.data.element?.CTN_CAR),
      carrierFilter: new UntypedFormControl(''),
      originPort: new UntypedFormControl(this.data.element?.CTN_POL),
      originPortFilter: new UntypedFormControl(''),
      destinationPort: new UntypedFormControl(this.data.element?.CTN_POD),
      destinationPortFilter: new UntypedFormControl(''),
      ataStart: new UntypedFormControl(this.data.element?.CTN_ATA_START_DT),
      ataFinal: new UntypedFormControl(this.data.element?.CTN_ATA_FINAL_DT),
      gouStart: new UntypedFormControl(this.data.element?.CTN_GOU_START_DT),
      gouFinal: new UntypedFormControl(this.data.element?.CTN_GOU_FINAL_DT),      
      incStart: new UntypedFormControl(this.data.element?.CTN_INC_START_DT),
      incFinal: new UntypedFormControl(this.data.element?.CTN_INC_FINAL_DT)
    });

    this.getCustomers({ CUS_ID: this.form.get('customer').value, IS_SELECT: 1 });
    this.getContainerTypes();
    this.getCarriers();
    this.getPorts();

    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));
    this.form.get('typeFilter')?.valueChanges.subscribe((value) => this.filterContainerTypes(value));
    this.form.get('carrierFilter')?.valueChanges.subscribe((value) => this.filterCarriers(value));
    this.form.get('originPortFilter')?.valueChanges.subscribe((value) => this.filterOriginPort(value));
    this.form.get('destinationPortFilter')?.valueChanges.subscribe((value) => this.filterDestinationPort(value));
  }
  
  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getContainerTypes(): void{
    this.parameterService.getContainerType().subscribe((containerTypes)=>{
      this.containerTypes = containerTypes;
      this.filteredContainerTypes.next(containerTypes);
    });
  }

  getCarriers(): void{
    this.parameterService.getCarrier().subscribe((carriers)=>{
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });
  }

  getPorts(): void{
    this.parameterService.getPort().subscribe((ports)=>{
      this.ports = ports;
      this.filteredOriginPorts.next(ports);
      this.filteredDestinationPorts.next(ports);
    });
  }
  
  filterCustomers(filter: string): void {
    if (!this.form.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
    });
  }
  
  filterContainerTypes(value: string): void {
    this.filteredContainerTypes.next(this.containerTypes.filter((containerType) => containerType.CONTYPPF_QAMPZ.toLowerCase().includes(value.toLowerCase())));
  }

  filterCarriers(filter: string): void {
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase()) || carrier.RELATIPF_APA3CD.toLowerCase().includes(filter.toLowerCase())));
  }

  filterOriginPort(filter: string): void {
    this.filteredOriginPorts.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter.toLowerCase())));
  }

  filterDestinationPort(filter: string): void {
    this.filteredDestinationPorts.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter.toLowerCase())));
  }

  closeDialog(filterContainer: IContainer): void {
    this.dialogRef.close(filterContainer);
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    const filters: IContainer = {
      CTN_NUMBER: this.form.value.container,
      CTN_HBL: this.form.value.hbl,
      CTN_MBL: this.form.value.mbl,
      CTN_TYPE: this.form.value.type,
      CTN_CUSTOMER: this.form.value.customer,
      CTN_CAR: this.form.value.carrier,
      CTN_POL: this.form.value.originPort,
      CTN_POD: this.form.value.destinationPort,
      CTN_ATA_START_DT: this.form.value.ataStart,
      CTN_ATA_FINAL_DT: this.form.value.ataFinal,
      CTN_GOU_START_DT: this.form.value.gouStart,
      CTN_GOU_FINAL_DT: this.form.value.gouFinal,      
      CTN_INC_START_DT: this.form.value.incStart,
      CTN_INC_FINAL_DT: this.form.value.incFinal
    }

    this.closeDialog(filters);
  }

  reset(): void {
    this.form.setValue({
      container: null,
      hbl: null,
      mbl: null,
      type: null,
      typeFilter: '',
      customer: null,
      customerFilter: '',
      carrier: null,
      carrierFilter: '',
      originPort: null,
      originPortFilter: '',
      destinationPort: null,
      destinationPortFilter: '',
      ataStart: null,
      ataFinal: null,
      gouStart: null,
      gouFinal: null,      
      incStart: null,
      incFinal: null
    });
  }
}
