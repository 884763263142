import { Component, Input, OnInit } from '@angular/core';
import { ICustomerException } from 'src/app/models/customer.model';

@Component({
  selector: 'dhl-exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.scss']
})
export class ExceptionComponent{
  @Input() customer_exception: ICustomerException;
  @Input() group_exception: ICustomerException;
  @Input() customer_contacts: string;

  readonly activeMap = new Map([
    ['0', 'No'],
    ['1', 'Yes']
  ]);
}
