import { IPayable } from 'src/app/models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IChargeCodeDialog{
  element?: IPayable
}

@Component({
  selector: 'app-charge-code-dialog',
  templateUrl: './charge-code-dialog.component.html',
  styleUrls: ['./charge-code-dialog.component.scss']
})
export class ChargeCodeDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChargeCodeDialog,
    private dialogRef: MatDialogRef<ChargeCodeDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      chargeCode: new UntypedFormControl(this.data.element?.PB_CH_CODE, Validators.required)
    });
  }

  close(value: IPayable): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newPayable: IPayable = {
      PB_ID: this.data.element.PB_ID,
      PB_CH_CODE: this.form.value.chargeCode,
      PB_USER: this.authService.userId
    };

    this.close(newPayable);
  }

  getErrorMessage(formControl: string): string {
    if (this.form.get(formControl).hasError('number')) {
      return 'The value must be a number';
    } else if (this.form.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

}
