import { AgreementDialogPurchaseSaleComponent } from './agreement-dialog-purchase-sale/agreement-dialog-purchase-sale.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { AgreementDialogComponent } from './agreement-dialog/agreement-dialog.component';

@NgModule({
  declarations: [
    AgreementDialogComponent,
    AgreementDialogPurchaseSaleComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule
  ]
})
export class AgreementDialogsModule { }
