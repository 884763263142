
import { NgModule } from "@angular/core";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ShipmentsFilterDialogComponent } from './shipments-filter-dialog/shipments-filter-dialog.component';
import { ShipmentsDeleteAllDialogComponent } from './shipments-delete-all-dialog/shipments-delete-all-dialog.component';
import { ShipmentsDeleteDialogComponent } from "./shipments-delete-dialog/shipments-delete-dialog.component";
import { CommentDialogComponent } from "./comment-dialog/comment-dialog.component";
import { AttachmentDialogComponent } from "./attachment-dialog/attachment-dialog.component";
import { ComponentsModule } from "src/app/components/components.module";
import { ShipmentsImportDialogComponent } from './shipments-import-dialog/shipments-import-dialog.component';
import { NgxDropzoneModule } from "ngx-dropzone";

@NgModule({
    declarations:[
        ShipmentsDeleteAllDialogComponent,
        ShipmentsFilterDialogComponent,
        ShipmentsDeleteDialogComponent,
        CommentDialogComponent,
        AttachmentDialogComponent,
        ShipmentsImportDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        NgxMatSelectSearchModule,
        NgxDropzoneModule,
        ComponentsModule
    ]
})
export class ShipmentsDialogsModule { }