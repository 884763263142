import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IComment, ICommentRef } from 'src/app/models/comment.model';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };

  getComments(comment: ICommentRef): Observable<Array<IComment>> {
    return this.httpClient.get<Array<IComment>>(`${this.apiURL}/api/comment?filter=${JSON.stringify(comment)}`, this.httpOptions);
  }

  postComment(comment: IComment): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/comment`, comment, this.httpOptions);
  }

  putComment(comment: IComment): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/comment`, comment, this.httpOptions);
  }
}
