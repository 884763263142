import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IShipment } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';

export interface IChangeStatusDialog {
  element?: Array<IShipment>;
}

@Component({
  selector: 'app-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.scss']
})
export class ChangeStatusDialogComponent implements OnInit {
  form: UntypedFormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChangeStatusDialog,
    private dialogRef: MatDialogRef<ChangeStatusDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    console.log(this.data.element);

    this.form = this.formBuilder.group({
      status: new UntypedFormControl()
    });
  }

  close(value: Array<IShipment>): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    const shipments: Array<IShipment> = this.data.element.map((drt)=>{
      return{
        DRT_HBL: drt.SHIP_HBL,
        DRT_STATUS: this.form.value.status,
        DRT_USER: this.authService.userId
      }
    });

    this.close(shipments);
  }
}