<div class="attachment-container">
  <div class="attachment">
    <mat-tab-group mat-stretch-tabs animationDuration="0" [(selectedIndex)]="tabIndex">
      <mat-tab label="Attachments" *ngIf="showAttachmentsTab">
        <div class="files-list" *ngIf="attachments.length !== 0; else noAttachments">
          <div class="file" *ngFor="let attachment of attachments">
            <div class="file-details" [matTooltip]="attachment.ATCH_FILE_NAME">
              <mat-icon color="primary">description</mat-icon>
              <p class="file-name">{{attachment.ATCH_FILE_NAME}}</p>
            </div>
            <div class="file-actions">
              <a mat-icon-button [href]="bucketUrl + attachment.ATCH_FILE_NAME" [download]="Teste">
                <mat-icon color="primary">download</mat-icon>
              </a>
              <a mat-icon-button [href]="bucketUrl + attachment.ATCH_FILE_NAME" target="_blank">
                <mat-icon color="primary">open_in_new</mat-icon>
              </a>
            </div>
          </div>
        </div>
        <ng-template #noAttachments>
          <div class="empty-attachments">
            No attachments found for this registry
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Upload Attachment" *ngIf="showUploadTab">
        <div class="attachment-content">
          <ngx-dropzone (change)="onSelect($event)" [maxFileSize]="15728640">
            <ngx-dropzone-label>Drag and drop files to upload here</ngx-dropzone-label>
          </ngx-dropzone>
          <div class="files-view" *ngIf="filesToUpload.length !== 0">
            <div class="file" *ngFor="let file of filesToUpload; let i = index">
              <div class="file-details" [matTooltip]="file.name" [ngClass]="{'file-details': !showRenameFile, 'new-file-details': showRenameFile}">
                <mat-icon color="primary">upload_file</mat-icon>
                <p class="file-name">{{file.name}}</p>                
                <input class="new-file-name" matInput name="newFile" placeholder="New file name" (input)="inputText($event.target.value, i)" *ngIf="showRenameFile">
              </div>
              <button mat-icon-button (click)="onRemove(i)">
                <mat-icon color="primary">close</mat-icon>
              </button>
            </div>
          </div>
          <div class="button-container">
            <button mat-flat-button color="accent" [disabled]="filesToUpload.length === 0" (click)="onUpload()" *ngIf="!offSideAttachments">Upload</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
