import { ChargeCodeDialogComponent } from './charge-code-dialog/charge-code-dialog.component';
import { DiscountDialogComponent } from './discount-dialog/discount-dialog.component';
import { GeneratePoDialogComponent } from './generate-po-dialog/generate-po-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
  declarations: [
    FilterDialogComponent,
    GeneratePoDialogComponent,
    DiscountDialogComponent,
    ChargeCodeDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxDropzoneModule,
    ComponentsModule,
    NgxMatSelectSearchModule
  ]
})
export class PayablesGenerateDialogsModule { }
