<h1 mat-dialog-title>{{data.mode}} Date ({{data.destiny}})</h1>
<div mat-dialog-content>
  <form [formGroup]="dateForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Gate Out</mat-label>
        <input matInput name="gateOut" formControlName="gateOut" [matDatepicker]="gateOutPicker" [max]="maxGateOut" [disabled]="isPartial" />
        <mat-datepicker-toggle matSuffix [for]="gateOutPicker"></mat-datepicker-toggle>
        <mat-datepicker #gateOutPicker></mat-datepicker>
        <button type="button" mat-icon-button matSuffix (click)="dateForm.get('gateOut').setValue('')" *ngIf="(dateForm.get('gateOut').value) && !isPartial">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Gate In</mat-label>
        <input matInput name="gateIn" formControlName="gateIn" [matDatepicker]="gateInPicker" [min]="minGateIn" [max]="maxGateIn">
        <mat-datepicker-toggle matSuffix [for]="gateInPicker"></mat-datepicker-toggle>
        <mat-datepicker #gateInPicker></mat-datepicker>
        <button type="button" mat-icon-button matSuffix (click)="dateForm.get('gateIn').setValue('')" *ngIf="dateForm.get('gateIn').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Loading</mat-label>
        <input matInput name="loading" formControlName="loading" [matDatepicker]="loadingPicker" [min]="minLoading" [max]="maxLoading">
        <mat-datepicker-toggle matSuffix [for]="loadingPicker"></mat-datepicker-toggle>
        <mat-datepicker #loadingPicker></mat-datepicker>
        <button type="button" mat-icon-button matSuffix (click)="dateForm.get('loading').setValue('')" *ngIf="dateForm.get('loading').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode === 'delete'">Do you really wish to delete the date {{data.date}}?</p>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode !== 'delete'" [disabled]="dateForm.invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'delete'">Delete</button>
    </div>
  </form>
</div>
