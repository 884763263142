import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HotToastService } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { IPrtlCtn } from 'src/app/models/prtl-ctn';
import { PrtlCtnService } from 'src/app/services/prtl-ctn.service';
import { INewPrtlCtnDemurrageDialog, NewPrtlCtnDemurrageDialogComponent } from '../new-prtl-ctn-demurrage-dialog/new-prtl-ctn-demurrage-dialog.component';
import { IContainer } from 'src/app/models/shipment.model';
import moment from 'moment';

export interface IPrtlCtnDemurrageDialog{
  container?: IContainer;
}

@Component({
  selector: 'app-prtl-ctn-demurrage-dialog',
  templateUrl: './prtl-ctn-demurrage-dialog.component.html',
  styleUrls: ['./prtl-ctn-demurrage-dialog.component.scss']
})
export class PrtlCtnDemurrageDialogComponent implements OnInit {
  hideLoader: boolean;
  prtlCtnList: Array<IPrtlCtn>;

  statusProcess: Array<string> = [
    '',
    '',
    'Ship Arrived',
    'Container Returned',
    'Demurrage Calculated',
    'Invoice Generated',
    'Cancelled',
    'No Demurrage',
    'Agreement Not Found',
    'Invoice Sent',
  ];

  filterPrtlCtnValue: string = '';

  prtlCtnTableDataSource = new MatTableDataSource<IPrtlCtn>();
  prtlCtnTableDisplayedColumns: Array<string> = ['PRTL_CTN_ORDER', 'PRTL_CTN_ATA', 'PRTL_CTN_GOU', 'PRTL_CTN_PROCESS_STATUS'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPrtlCtnDemurrageDialog,
    private dialogRef: MatDialogRef<PrtlCtnDemurrageDialogComponent>,
    private prtlCtnService: PrtlCtnService,
    private toast: HotToastService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private exporter: XlsxExporterService
  ) { }

  @ViewChild(MatTable) prtlCtnTable: MatTable<IPrtlCtn>;
  @ViewChild('prtlCtnSort') prtlCtnSort: MatSort;
  @ViewChild('prtlCtnPaginator') prtlCtnPaginator: MatPaginator;

  ngOnInit() {
    this.getPrtlCtn();
  }

  filterPrtlCtnTable(filterValue: string): void {
    this.prtlCtnTableDataSource.filter = filterValue;
  }

  getPrtlCtn(): void {
    if(this.prtlCtnTableDataSource.data.length === 0) this.hideLoader = false;
    this.prtlCtnService.getPrtlCtn({
      CTN_ID: this.data.container?.CTN_ID
    }).subscribe((ctn: Array<IPrtlCtn>) => {
      this.hideLoader = true;
      this.prtlCtnList = ctn;
      this.prtlCtnTableDataSource.data = ctn;
      this.changeDetectorRef.detectChanges();
      this.prtlCtnTableDataSource.sort = this.prtlCtnSort;
      this.prtlCtnTableDataSource.paginator = this.prtlCtnPaginator;
    }, (error) => {
      this.hideLoader = true;
      this.toast.error(error.message);
    });
  } 

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }


  closeDialog() {
    this.dialogRef.close();
  }

  exportPrtlCtn() {
    const headers = {
      CTN_NUMBER_TEXT: 'CONTAINER NUMBER',
      PRTL_CTN_ORDER: 'PARTIAL ORDER',
      PRTL_CTN_ATA_TEXT: 'ARRIVAL',
      PRTL_CTN_GOU_TEXT: 'EMPTY RETURN',
      PRTL_CTN_PROCESS_STATUS_TEXT: 'PROCESS STATUS',
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IPrtlCtn> = this.preparePrtlCtnDataToExport([...this.prtlCtnTableDataSource.filteredData]);
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `containerPartial_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  preparePrtlCtnDataToExport(data: Array<IPrtlCtn>): Array<IPrtlCtn> {
    const dataToExport: Array<IPrtlCtn> = [];

    const datesText = {
      CTN_NUMBER_TEXT: '',
      PRTL_CTN_ATA_TEXT: '',
      PRTL_CTN_GOU_TEXT: '',
      PRTL_CTN_PROCESS_STATUS_TEXT: '',
    }
    
    data.forEach((prtlCtn) => {
      const newData = {...prtlCtn, ...datesText};

      newData.CTN_NUMBER_TEXT = this.data.container.CTN_NUMBER;
      newData.PRTL_CTN_ATA_TEXT = newData.PRTL_CTN_ATA && moment(newData.PRTL_CTN_ATA).format('DD/MM/YYYY');
      newData.PRTL_CTN_GOU_TEXT = newData.PRTL_CTN_GOU && moment(newData.PRTL_CTN_GOU).format('DD/MM/YYYY');
      newData.PRTL_CTN_PROCESS_STATUS_TEXT = this.getStatusProcess(Number(newData.PRTL_CTN_PROCESS_STATUS));

      delete newData.CTN_ID;
      delete newData.PRTL_CTN_ID;
      delete newData.PRTL_CTN_ATA;
      delete newData.PRTL_CTN_GOU;
      delete newData.PRTL_CTN_GIN;
      delete newData.PRTL_CTN_LDG;
      delete newData.PRTL_CTN_STATUS;
      delete newData.PRTL_CTN_PROCESS_STATUS;
      delete newData.PRTL_CTN_USER;
      delete newData.PRTL_CTN_DATETIME;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  getStatusProcess(id: number): string{
    return this.statusProcess[id];
  }

}
