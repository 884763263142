import { TimelineService } from 'src/app/services/timeline.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Subject } from 'rxjs';
import { IAgreement, IRulCou, IRulCtnType, IRulPort, IRulTrd } from 'src/app/models/agreement.model';
import { IAttachment } from 'src/app/models/attachment.model';
import { IComment } from 'src/app/models/comment.model';
import { ICarrier, IContainerType, ICountry, IPort, IRegion } from 'src/app/models/parameter.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { AgreementService } from 'src/app/services/agreement.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { AwsService } from 'src/app/services/aws.service';
import { CommentService } from 'src/app/services/comment.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { DefaultRuleDialogComponent } from './default-rule-dialogs/default-rule-dialog/default-rule-dialog.component';

@Component({
  selector: 'dhl-default-rule',
  templateUrl: './default-rule.component.html',
  styleUrls: ['./default-rule.component.scss']
})
export class DefaultRuleComponent implements OnInit {
  isAgreementInformationOpen: boolean = true;
  isCustomerInformationOpen: boolean = true;
  isAgreementParametersOpen: boolean = true;
  defaultRuleForm: UntypedFormGroup;

  carriers: Array<ICarrier>;
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  containerTypes: Array<IContainerType>;
  filteredContainerTypes: Subject<Array<IContainerType>> = new Subject<Array<IContainerType>>();

  countries: Array<ICountry>;
  filteredCountries: Subject<Array<ICountry>> = new Subject<Array<ICountry>>();

  ports: Array<IPort>;
  filteredPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();

  regions: Array<IRegion>;
  filteredRegions: Subject<Array<IRegion>> = new Subject<Array<IRegion>>();

  defaultRuleType: string;
  defaultRuleTitle: string;
  defaultRuleAction: string;
  defaultRuleId: number;
  defaultRule: IAgreement;
  
  cloned: boolean = false;

  isSidebarOpen = false;
  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  
  minValidityDate: Date;

  constructor(
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private parameterService: ParameterService,
    private agreementService: AgreementService,
    private commentService: CommentService,
    private awsService: AwsService,
    private authService: AuthService,
    private attachmentService: AttachmentService,
    private toast: HotToastService,
    private timelineService: TimelineService
  ) { 
    this.parameterService.getCarrier().subscribe((carriers) => {
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });

    this.parameterService.getContainerType().subscribe((containerTypes) => {
      this.containerTypes = containerTypes;
      this.filteredContainerTypes.next(containerTypes);
    });

    this.parameterService.getCountry().subscribe((countries) => {
      this.countries = countries;
      this.filteredCountries.next(countries);
    });

    this.parameterService.getPort().subscribe((ports) => {
      this.ports = ports;
      this.filteredPorts.next(ports);
    });

    this.parameterService.getRegion().subscribe((regions) => {
      this.regions = regions;
      this.filteredRegions.next(regions);
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.defaultRuleType = params.type;
      this.defaultRuleAction = params.action;
      this.defaultRuleId = params.id;
      this.defaultRuleTitle = (this.defaultRuleType == 'dhl' ? this.defaultRuleType.toUpperCase() : this.defaultRuleType.charAt(0).toUpperCase() + this.defaultRuleType.slice(1));
    });

    this.titleService.setTitle(`DHL | Agreement (Standard ${this.defaultRuleTitle})`);

    let containerTypeList = [];
    let originTradeList = [];
    let originCountryList = [];
    let polList = [];
    let destinationTradeList = [];
    let destinationCountryList = [];
    let podList = [];

    this.defaultRule = this.route.snapshot.data['agreement'];

    if (this.defaultRuleAction === 'clone') {
      this.cloned = true;
      this.defaultRule.DIM_SK_CUS_RUL = null;
      this.defaultRule.CTN_TYPE_LIST = [];
      this.defaultRule.DIM_VL_FRT_AMT = null;
      this.defaultRule.DIM_NAC_FAK = null;
      this.defaultRule.DIM_CONTRACT = null;
      this.defaultRule.DIM_STR_REF = null;
      this.defaultRule.DIM_END_REF = null;
      this.defaultRule.PERDIEM_LIST[0].DIM_VL_RNG_BEG = null;
      this.defaultRule.PERDIEM_LIST[0].DIM_VL_RNG_END = null;
      this.defaultRule.PERDIEM_LIST[0].DIM_VL_RNG_VAL = null;
      this.defaultRule.PERDIEM_LIST[1].DIM_VL_RNG_BEG = null;
      this.defaultRule.PERDIEM_LIST[1].DIM_VL_RNG_END = null;
      this.defaultRule.PERDIEM_LIST[1].DIM_VL_RNG_VAL = null;
      this.defaultRule.PERDIEM_LIST[2].DIM_VL_RNG_BEG = null;
      this.defaultRule.PERDIEM_LIST[2].DIM_VL_RNG_END = null;
      this.defaultRule.PERDIEM_LIST[2].DIM_VL_RNG_VAL = null;
      this.defaultRule.PERDIEM_LIST[3].DIM_VL_RNG_BEG = null;
      this.defaultRule.PERDIEM_LIST[3].DIM_VL_RNG_END = null;
      this.defaultRule.PERDIEM_LIST[3].DIM_VL_RNG_VAL = null;
    }
    
    if (this.defaultRule) {
      containerTypeList = this.defaultRule.CTN_TYPE_LIST.map((el) => Number(el.DIM_SK_CTN_TYP));
      originTradeList = this.defaultRule.REG_LIST.filter((el) => el.DIM_RUL_TRD_TYPE === 'O').map(el => Number(el.REG_ID));
      originCountryList = this.defaultRule.COU_LIST.filter((el) => el.DIM_RUL_COU_TYPE === 'O').map(el => Number(el.COU_ID));
      polList = this.defaultRule.PORT_LIST.filter((el) => el.DIM_RUL_PORT_TYPE === 'O').map(el => Number(el.DIM_SK_PORT));
      destinationTradeList = this.defaultRule.REG_LIST.filter((el) => el.DIM_RUL_TRD_TYPE === 'D').map(el => Number(el.REG_ID));
      destinationCountryList = this.defaultRule.COU_LIST.filter((el) => el.DIM_RUL_COU_TYPE === 'D').map(el => Number(el.COU_ID));
      podList = this.defaultRule.PORT_LIST.filter((el) => el.DIM_RUL_PORT_TYPE === 'D').map(el => Number(el.DIM_SK_PORT));

      this.getComments();
      this.getAttachments();
      this.getTimeline();
    }

    const status = isNaN(Number(this.defaultRule?.DIM_STATUS)) ? 1 : Number(this.defaultRule?.DIM_STATUS);

    this.defaultRuleForm = this.formBuilder.group({
      name: new UntypedFormControl(this.defaultRule?.DIM_RUL_DEF_NAM || ''),
      service: new UntypedFormControl(this.defaultRule?.DIM_SERVICE.toLowerCase() || '', this.defaultRuleType === 'carrier' ? [Validators.required] : []),
      carrier: new UntypedFormControl(Number(this.defaultRule?.DIM_CAR) || '', this.defaultRuleType === 'carrier' ? [Validators.required] : []),
      carrierFilter: new UntypedFormControl(''),
      type: new UntypedFormControl(this.defaultRule?.DIM_TYPE.toLowerCase() || '', this.defaultRuleType === 'dhl' ? [Validators.required] : []),
      startDate: new UntypedFormControl(this.defaultRule?.DIM_DT_STR_EFF ? new Date(this.defaultRule?.DIM_DT_STR_EFF) : '', [Validators.required]),
      validityDate: new UntypedFormControl(this.defaultRule?.DIM_DT_FIN_EFF ? new Date(this.defaultRule?.DIM_DT_FIN_EFF) : ''),
      status: new UntypedFormControl(status, [Validators.required]),
      containerType: new UntypedFormControl(containerTypeList, [Validators.required]),
      containerTypeFilter: new UntypedFormControl(''),
      originTrade: new UntypedFormControl(originTradeList),
      originTradeFilter: new UntypedFormControl(''),
      originCountry: new UntypedFormControl(originCountryList),
      originCountryFilter: new UntypedFormControl(''),
      pol: new UntypedFormControl(polList),
      polFilter: new UntypedFormControl(''),
      destinationTrade: new UntypedFormControl(destinationTradeList),
      destinationTradeFilter: new UntypedFormControl(''),
      destinationCountry: new UntypedFormControl(destinationCountryList),
      destinationCountryFilter: new UntypedFormControl(''),
      pod: new UntypedFormControl(podList),
      podFilter: new UntypedFormControl(''),
      perDiemType: new UntypedFormControl(this.defaultRule?.DIM_PERDIEM_TYPE || 'Custom Rule', [Validators.required]),
      freetime: new UntypedFormControl(this.defaultRule?.DIM_VL_FRT_AMT || '', [Validators.required, Validators.pattern('[0-9]*')]),
      beginDay1: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[0]?.DIM_VL_RNG_BEG || '', [Validators.pattern('[0-9]*')]),
      endDay1: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[0]?.DIM_VL_RNG_END || '', [Validators.pattern('[0-9]*')]),
      valuePerDay1: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[0]?.DIM_VL_RNG_VAL || '', [Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
      beginDay2: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[1]?.DIM_VL_RNG_BEG || '', [Validators.pattern('[0-9]*')]),
      endDay2: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[1]?.DIM_VL_RNG_END || '', [Validators.pattern('[0-9]*')]),
      valuePerDay2: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[1]?.DIM_VL_RNG_VAL || '', [Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
      beginDay3: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[2]?.DIM_VL_RNG_BEG || '', [Validators.pattern('[0-9]*')]),
      endDay3: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[2]?.DIM_VL_RNG_END || '', [Validators.pattern('[0-9]*')]),
      valuePerDay3: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[2]?.DIM_VL_RNG_VAL || '', [Validators.pattern(/\-?\d*\.?\d{1,2}/)]),
      beginDay4: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[3]?.DIM_VL_RNG_BEG || '', [Validators.pattern('[0-9]*')]),
      endDay4: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[3]?.DIM_VL_RNG_END || '', [Validators.pattern('[0-9]*')]),
      valuePerDay4: new UntypedFormControl(this.defaultRule?.PERDIEM_LIST[3]?.DIM_VL_RNG_VAL || '', [Validators.pattern(/\-?\d*\.?\d{1,2}/)])
    });

    this.minValidityDate = this.defaultRuleForm.get('startDate').value;

    this.defaultRuleForm.get('carrierFilter').valueChanges.subscribe(() => {
      this.filterCarriers();
    });

    this.defaultRuleForm.get('containerTypeFilter').valueChanges.subscribe(() => {
      this.filterContainerTypes();
    });

    this.defaultRuleForm.get('originTradeFilter').valueChanges.subscribe(() => {
      this.filterRegions();
    });

    this.defaultRuleForm.get('originCountryFilter').valueChanges.subscribe(() => {
      this.filterCountries();
    });

    this.defaultRuleForm.get('polFilter').valueChanges.subscribe(() => {
      this.filterPorts();
    });

    this.defaultRuleForm.get('destinationTradeFilter').valueChanges.subscribe(() => {
      this.filterRegions();
    });

    this.defaultRuleForm.get('destinationCountryFilter').valueChanges.subscribe(() => {
      this.filterCountries();
    });

    this.defaultRuleForm.get('podFilter').valueChanges.subscribe(() => {
      this.filterPorts();
    });

    this.getBeginDayValue('freetime', 'beginDay1', 'valuePerDay1');
    this.getBeginDayValue('endDay1', 'beginDay2', 'valuePerDay2');
    this.getBeginDayValue('endDay2', 'beginDay3', 'valuePerDay3');
    this.getBeginDayValue('endDay3', 'beginDay4', 'valuePerDay4');    
  }

  handleEndDayInput(endDay: string, nextBeginDay: string, nextValuePerDay: string, index: number): void {
    this.getBeginDayValue(endDay, nextBeginDay, nextValuePerDay);
    this.clearPerDiem(endDay, index);

    Object.entries(this.defaultRuleForm.controls).forEach(([key, value]) => {
      console.log(key, value.value, this.defaultRuleForm.get(key).invalid)
    });
  }

  getBeginDayValue(endDay: string, nextBeginDay: string, nextValuePerDay: string): void {
    this.defaultRuleForm.get(nextBeginDay).setValue(Number(this.defaultRuleForm.get(endDay).value) && Number(this.defaultRuleForm.get(endDay).value) + 1);
    if (this.defaultRuleForm.get(nextBeginDay).value !== 0) {
      this.defaultRuleForm.get(nextValuePerDay).setValidators([Validators.required]);
      this.defaultRuleForm.get(nextValuePerDay).updateValueAndValidity();
    }
  }

  clearPerDiem(endDay, index: number) {
    if (this.defaultRuleForm.get(endDay).value) return;
    for (let i = index + 1; i <= 4; i++) {
      this.defaultRuleForm.get(`endDay${i}`).setValue('');
      this.defaultRuleForm.get(`valuePerDay${i}`).setValue('');
      this.defaultRuleForm.get(`valuePerDay${i}`).setValidators([]);
      this.defaultRuleForm.get(`valuePerDay${i}`).updateValueAndValidity();
    }
  }

  checkValidDate(endDay: string, lastBeginDay: string): void {
    if (!this.defaultRuleForm.get(endDay).value) return;

    if (Number(this.defaultRuleForm.get(endDay).value) <= Number(this.defaultRuleForm.get(lastBeginDay).value)) {
      this.defaultRuleForm.get(endDay).setValue('');
      this.toast.error('The end day value must be bigger than the begin day of the same period');
    }
  }

  checkValidValuePerDay(valuePerDay: string, lastValuePerDay: string): void {
    if (!this.defaultRuleForm.get(valuePerDay).value) return;

    if (Number(this.defaultRuleForm.get(valuePerDay).value) <= Number(this.defaultRuleForm.get(lastValuePerDay).value)) {
      this.defaultRuleForm.get(valuePerDay).setValue('');
      this.toast.error('The value per day must be bigger than the one of the last period');
    }
  }  

  filterCarriers(): void {
    const filterValue = this.defaultRuleForm.get('carrierFilter').value.toLowerCase();
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.RELATIPF_APCPTK.toLowerCase().includes(filterValue) || carrier.RELATIPF_APA3CD.toLowerCase().includes(filterValue)));
  }

  filterContainerTypes(): void {
    const filterValue = this.defaultRuleForm.get('containerTypeFilter').value.toLowerCase();
    this.filteredContainerTypes.next(this.containerTypes.filter((containerType) => containerType.CONTYPPF_QAMPZ.toLowerCase().includes(filterValue)));
  }

  filterRegions(): void {
    const filterValue = this.defaultRuleForm.get('originTradeFilter').value.toLowerCase() || this.defaultRuleForm.get('destinationTradeFilter').value.toLowerCase();
    this.filteredRegions.next(this.regions.filter((region) => region.REG_NAME.toLowerCase().includes(filterValue)));
  }

  filterCountries(): void {
    const filterValue = this.defaultRuleForm.get('originCountryFilter').value.toLowerCase() || this.defaultRuleForm.get('destinationCountryFilter').value.toLowerCase();
    this.filteredCountries.next(this.countries.filter((country) => country.COU_CODE.toLowerCase().includes(filterValue)));
  }

  filterPorts(): void {
    const filterValue = this.defaultRuleForm.get('polFilter').value.toLowerCase() || this.defaultRuleForm.get('podFilter').value.toLowerCase();
    this.filteredPorts.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filterValue)));
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.defaultRuleForm.value;
    let containerTypeList: Array<IRulCtnType>;
    let regionOrigin: Array<IRulTrd>;
    let regionDestination: Array<IRulTrd>;
    let couListOrigin: Array<IRulCou>;
    let couListDestination: Array<IRulCou>;
    let portListOrigin: Array<IRulPort>;
    let portListDestination: Array<IRulPort>;

    if (formValue.containerType) {
      containerTypeList = formValue.containerType.map((containerType: number) => {
        const cntType: IRulCtnType = {
          DIM_SK_CTN_TYP: containerType
        }
        return cntType;
      });
    }

    if (formValue.originTrade) {
      regionOrigin = formValue.originTrade.map((region: number) => {
        const trd: IRulTrd = {
          REG_ID: region,
          DIM_RUL_TRD_TYPE: 'O'
        }
        return trd;
      });
    }

    if (formValue.destinationTrade) {
      regionDestination = formValue.destinationTrade.map((region: number) => {
        const trd: IRulTrd = {
          REG_ID: region,
          DIM_RUL_TRD_TYPE: 'D'
        }
        return trd;
      });
    }

    if (formValue.originCountry) {
      couListOrigin = formValue.originCountry.map((country: number) => {
        const cou: IRulCou = {
          COU_ID: country,
          DIM_RUL_COU_TYPE: 'O'
        }
        return cou;
      });
    }

    if (formValue.destinationCountry) {
      couListDestination = formValue.destinationCountry.map((country: number) => {
        const cou: IRulCou = {
          COU_ID: country,
          DIM_RUL_COU_TYPE: 'D'
        }
        return cou;
      });
    }

    if (formValue.pol) {
      portListOrigin = formValue.pol.map((port: number) => {
        const pol: IRulPort = {
          DIM_SK_PORT: port,
          DIM_RUL_PORT_TYPE: 'O'
        }
        return pol;
      });
    }

    if (formValue.pod) {
      portListDestination = formValue.pod.map((port: number) => {
        const pol: IRulPort = {
          DIM_SK_PORT: port,
          DIM_RUL_PORT_TYPE: 'D'
        }
        return pol;
      });
    }

    const regList = regionOrigin.concat(regionDestination);
    const couList = couListOrigin.concat(couListDestination);
    const portList = portListOrigin.concat(portListDestination);

    const defaultRule: IAgreement = {
      DIM_RUL_DEF_NAM: formValue.name,
      DIM_SERVICE: formValue.service,
      DIM_TYPE: this.defaultRuleType === 'carrier' ? 'purchase' : formValue.type,
      DIM_DT_STR_EFF: formValue.startDate,
      DIM_DT_FIN_EFF: formValue.validityDate,
      DIM_STATUS: formValue.status,
      DIM_CAR: formValue.carrier || null,
      DIM_VL_FRT_AMT: formValue.freetime,
      CTN_TYPE_LIST: containerTypeList,
      REG_LIST: regList,
      COU_LIST: couList,
      PORT_LIST: portList,
      PERDIEM_LIST: [
        {
          DIM_VL_RNG_BEG: formValue.beginDay1 || null,
          DIM_VL_RNG_END: formValue.endDay1 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay1.replace(",",".") || null,
          DIM_CD_RNG_ORG: '1'
        },
        {
          DIM_VL_RNG_BEG: formValue.beginDay2 || null,
          DIM_VL_RNG_END: formValue.endDay2 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay2.replace(",",".") || null,
          DIM_CD_RNG_ORG: '2'
        },
        {
          DIM_VL_RNG_BEG: formValue.beginDay3 || null,
          DIM_VL_RNG_END: formValue.endDay3 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay3.replace(",",".") || null,
          DIM_CD_RNG_ORG: '3'
        },
        {
          DIM_VL_RNG_BEG: formValue.beginDay4 || null,
          DIM_VL_RNG_END: formValue.endDay4 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay4.replace(",",".") || null,
          DIM_CD_RNG_ORG: '4'
        }
      ],
      DIM_RUL_DEF: 1,
      DIM_RUL_DEF_TYPE: this.defaultRuleType === 'dhl' ? 1 : 0,
      DIM_RUL_USER: this.authService.userId
    }
    
    if (!this.defaultRule || this.defaultRuleAction === 'clone') {
      defaultRule.DIM_STATUS = 1;
      this.agreementService.postAgreement(defaultRule).subscribe((response) => {
        this.toast.success(String(response));
        const dialogRef = this.dialog.open(DefaultRuleDialogComponent);
        dialogRef.afterClosed().subscribe((willClone) => {
          if (willClone === 0) {
            this.router.navigate(['/agreements/default', this.defaultRuleType]);            
          }else if (willClone === 1){
            window.location.reload();
            window.scrollTo(0, 0);
          } else {
            this.defaultRuleForm.patchValue({
              containerType: [],
              freetime: '',
              beginDay1: '',
              endDay1: '',
              valuePerDay1: '',
              beginDay2: '',
              endDay2: '',
              valuePerDay2: '',
              beginDay3: '',
              endDay3: '',
              valuePerDay3: '',
              beginDay4: '',
              endDay4: '',
              valuePerDay4: ''
            });
            window.scrollTo(0, 0);
            this.cloned = true;
          }
        });
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    } else {
      defaultRule.DIM_SK_CUS_RUL = this.defaultRuleId;
      this.agreementService.putAgreement(defaultRule).subscribe((response) => {
        this.toast.success(String(response));
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }
  }

  isFieldDisabled(field1: string, field2: string): boolean {
    return this.defaultRuleForm.get(field1).value.length !== 0 || this.defaultRuleForm.get(field2).value.length !== 0;
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `agreement`, REG_ID: this.defaultRuleId }).subscribe(comments => this.comments = comments);
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: this.defaultRuleId,
          COMM_REF_GROUP: `agreement`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: `agreement`, REG_ID: this.defaultRuleId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, `agreement`, [{ REG_ID: this.defaultRuleId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'agreement', TIMELINE_REG_ID: this.defaultRuleId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }
}
