<header>
  <h2>Shipment - DRT</h2>
  <div class="actions-shipment">
    <div class="search-shipment">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="changeStatus({ element: selection.selected })">
          <mat-icon>published_with_changes</mat-icon>
          <span>Change Status</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="filteredShipmentTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #shipmentTable [dataSource]="filteredShipmentTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="SHIP_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">ID</th>
        <td mat-cell *matCellDef="let element">{{element.SHIP_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_DESTINY">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Service">Service</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_DESTINY)" [matTooltip]="element.SHIP_DESTINY" mat-cell *matCellDef="let element">{{element.SHIP_DESTINY}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by House Bill of Lading">House Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_HBL)" [matTooltip]="element.SHIP_HBL" mat-cell *matCellDef="let element">{{element.SHIP_HBL}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Shipment ID">Shipment</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_CODE)" [matTooltip]="element.SHIP_CODE" mat-cell *matCellDef="let element">{{element.SHIP_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_MBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by MBL">Master Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_MBL)" [matTooltip]="element.SHIP_MBL" mat-cell *matCellDef="let element">
          {{element.SHIP_MBL}}
          <sup class="mbl-dup" *ngIf="element.SHIP_DUP_MBL > 1">({{element.SHIP_DUP_MBL}})</sup>
        </td>
      </ng-container>
      <ng-container matColumnDef="SHIP_CUS_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_CUS_NAME)" [matTooltip]="element.SHIP_CUS_NAME" mat-cell *matCellDef="let element">{{element.SHIP_CUS_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_CAR_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_CAR_NAME)" [matTooltip]="element.SHIP_CAR_NAME" mat-cell *matCellDef="let element">{{element.SHIP_CAR_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_CTN_COUNT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container Count">Container Count</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_CTN_COUNT)" [matTooltip]="element.SHIP_CTN_COUNT" mat-cell *matCellDef="let element">{{element.SHIP_CTN_COUNT}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_POL_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POL">Origin Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_POL_NAME)" [matTooltip]="element.SHIP_POL_NAME" mat-cell *matCellDef="let element">{{element.SHIP_POL_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_POD_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POD">Destination Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_POD_NAME)" [matTooltip]="element.SHIP_POD_NAME" mat-cell *matCellDef="let element">{{element.SHIP_POD_NAME}}</td>
      </ng-container>      
      <ng-container matColumnDef="SHIP_CONSOL_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Consol Type">Consol Type</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_CONSOL_TYPE)" [matTooltip]="element.SHIP_CONSOL_TYPE" mat-cell *matCellDef="let element">{{element.SHIP_CONSOL_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_INC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Incoterm">Incoterm</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_INC)" [matTooltip]="element.SHIP_INC" mat-cell *matCellDef="let element">{{element.SHIP_INC}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_CTN_MODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Mode">Mode</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_CTN_MODE)" [matTooltip]="element.SHIP_CTN_MODE" mat-cell *matCellDef="let element">{{element.SHIP_CTN_MODE}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_DPT_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Dept">Dept</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_DPT_CODE)" [matTooltip]="element.SHIP_DPT_CODE" mat-cell *matCellDef="let element">{{element.SHIP_DPT_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_ATD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ATD">ATD</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_ATD)" [matTooltip]="element.SHIP_ATD" mat-cell *matCellDef="let element">{{element.SHIP_ATD | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_ATA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ATA">ATA</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_ATA)" [matTooltip]="element.SHIP_ATA" mat-cell *matCellDef="let element">{{element.SHIP_ATA | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_EXC_DESC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Exception Description">Exception Description</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_EXC_DESC)" [matTooltip]="element.SHIP_EXC_DESC" mat-cell *matCellDef="let element">{{element.SHIP_EXC_DESC}}</td>
      </ng-container>
      <ng-container matColumnDef="SHIP_EXC_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Exception Date">Exception Date</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_EXC_DATE)" [matTooltip]="element.SHIP_EXC_DATE" mat-cell *matCellDef="let element">{{element.SHIP_EXC_DATE | date:'dd/MM/YYYY'}}</td>
      </ng-container>      
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [routerLink]="['/shipment','edit',element.SHIP_ID]">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <mat-slide-toggle
            [(ngModel)]="element.DRT_ACTIVE"
            (change)="onToggleSlide(element)"
            color="accent">
          </mat-slide-toggle>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="shipmentTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: shipmentTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>