<section>
  <h2>Parameter Registration</h2>
  <div class="parameter-items">  
    <div class="parameter-group">
      <div class="parameter-heading">
        <h3>Countries</h3>
        <div class="form-group">
          <mat-form-field class="parameter-search">
            <mat-label>Search</mat-label>
            <mat-icon matPrefix color="primary">search</mat-icon>
            <input matInput type="search" [(ngModel)]="filterCountryValue" (input)="filterCountryTable(filterCountryValue)">
            <button type="button" mat-icon-button matSuffix (click)="filterCountryValue = ''; filterCountryTable(filterCountryValue)" *ngIf="filterCountryValue != ''">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <button mat-stroked-button color="accent" (click)="openCountryDialog({title: 'Add Country', mode: 'add', regions: regions});">Add</button>
          <button mat-stroked-button color="accent" class="btn-export" (click)="exportCountries()" [disabled]="countryTableDataSource.data.length === 0">Export</button>
        </div>
      </div>
      <div class="parameter-body">
        <ng-container *ngIf="hideLoaderCountry; else loader">
          <table mat-table #countryTable [dataSource]="countryTableDataSource" matSort #countrySort="matSort">
            <ng-container matColumnDef="COU_ID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
              <td mat-cell *matCellDef="let element">{{element.COU_ID}}</td>
            </ng-container>
            <ng-container matColumnDef="COU_CODE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">Code</th>
              <td mat-cell *matCellDef="let element">{{element.COU_CODE}}</td>
            </ng-container>
            <ng-container matColumnDef="COU_DESC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Description</th>
              <td [matTooltipDisabled]="!isOverflow(element.COU_DESC)" [matTooltip]="element.COU_DESC" mat-cell *matCellDef="let element">{{element.COU_DESC}}</td>
            </ng-container>
            <ng-container matColumnDef="REG_NAME_LIST">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Region Id">Region</th>
              <td [matTooltipDisabled]="!isOverflow(element.REG_NAME_LIST)" [matTooltip]="element.REG_NAME_LIST" mat-cell *matCellDef="let element">{{element.REG_NAME_LIST}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openCountryDialog({title: 'Edit Country', mode: 'edit', country: element, regions: regions})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openCountryDialog({title: 'Delete Country', mode: 'delete', country: element})">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="coutryTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: coutryTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
      <div class="parameter-footer">
        <button mat-icon-button (click)="getCountry()">
          <mat-icon color="primary">refresh</mat-icon>
        </button>
        <mat-paginator #countryPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of country"></mat-paginator>
      </div>
    </div>
    <div class="parameter-group">
      <div class="parameter-heading">
        <h3>Container Types</h3>
        <div class="form-group">
          <mat-form-field class="parameter-search">
            <mat-label>Search</mat-label>
            <mat-icon matPrefix color="primary">search</mat-icon>
            <input matInput type="search" [(ngModel)]="filterContainerTypeValue" (input)="filterContainerTypeTable(filterContainerTypeValue)">
            <button type="button" mat-icon-button matSuffix (click)="filterContainerTypeValue = ''; filterContainerTypeTable(filterContainerTypeValue)" *ngIf="filterContainerTypeValue != ''">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <button mat-stroked-button color="accent" (click)="openContainerTypeDialog({title: 'Add Container Type', mode: 'add'});">Add</button>
          <button mat-stroked-button color="accent" class="btn-export" (click)="exportContainerTypes()" [disabled]="containerTypeTableDataSource.data.length === 0">Export</button>
        </div>
      </div>
      <div class="parameter-body">
        <ng-container *ngIf="hideLoaderContainerType; else loader">
          <table mat-table #containerTypeTable [dataSource]="containerTypeTableDataSource" matSort #containerTypeSort="matSort">
            <ng-container matColumnDef="DIM_SK_CTN_TYP">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
              <td mat-cell *matCellDef="let element">{{element.DIM_SK_CTN_TYP}}</td>
            </ng-container>
            <ng-container matColumnDef="CONTYPPF_QAMPZ">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">Code</th>
              <td mat-cell *matCellDef="let element">{{element.CONTYPPF_QAMPZ}}</td>
            </ng-container>
            <ng-container matColumnDef="CONTYPPF_QAMQA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Description</th>
              <td [matTooltipDisabled]="!isOverflow(element.CONTYPPF_QAMQA)" [matTooltip]="element.CONTYPPF_QAMQA" mat-cell *matCellDef="let element">{{element.CONTYPPF_QAMQA}}</td>
            </ng-container>
            <ng-container matColumnDef="CONTYPPF_QAMQB">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by TEUS Quantity">TEUS Quantity</th>
              <td mat-cell *matCellDef="let element">{{element.CONTYPPF_QAMQB}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openContainerTypeDialog({title: 'Edit Container Type', mode: 'edit', containerType: element})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openContainerTypeDialog({title: 'Delete Container Type', mode: 'delete', containerType: element})">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="containerTypeTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: containerTypeTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
      <div class="parameter-footer">
        <button mat-icon-button (click)="getContainerType()">
          <mat-icon color="primary">refresh</mat-icon>
        </button>
        <mat-paginator #containerTypePaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of container type"></mat-paginator>
      </div>
    </div>
    <div class="parameter-group">
      <div class="parameter-heading">
        <h3>Ports</h3>
        <div class="form-group">
          <mat-form-field class="parameter-search">
            <mat-label>Search</mat-label>
            <mat-icon matPrefix color="primary">search</mat-icon>
            <input matInput type="search" [(ngModel)]="filterPortsValue" (input)="filterPortTable(filterPortsValue)">
            <button type="button" mat-icon-button matSuffix (click)="filterPortsValue = ''; filterPortTable(filterPortsValue)" *ngIf="filterPortsValue != ''">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <button mat-stroked-button color="accent" (click)="openPortDialog({title: 'Add Port', mode: 'add', countries: countries, regions: regions});">Add</button>
          <button mat-stroked-button color="accent" class="btn-export" (click)="exportPorts()" [disabled]="portTableDataSource.data.length === 0">Export</button>
        </div>
      </div>
      <div class="parameter-body">
        <ng-container *ngIf="hideLoaderPort; else loader">
          <table mat-table #containerTypeTable [dataSource]="portTableDataSource" matSort #portSort="matSort">
            <ng-container matColumnDef="DIM_SK_PORT">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">ID</th>
              <td mat-cell *matCellDef="let element">{{element.DIM_SK_PORT}}</td>
            </ng-container>
            <ng-container matColumnDef="DHLPORTS_AIAACD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Country Code">Country Code</th>
              <td mat-cell *matCellDef="let element">{{element.DHLPORTS_AIAACD}}</td>
            </ng-container>
            <ng-container matColumnDef="DHLPORTS_AIAOCD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Port Country Code">Port Country Code</th>
              <td mat-cell *matCellDef="let element">{{element.DHLPORTS_AIAOCD}}</td>
            </ng-container>
            <ng-container matColumnDef="DHLPORTS_AIB4TX">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Port Country Description">Port Country Description</th>
              <td [matTooltipDisabled]="!isOverflow(element.DHLPORTS_AIB4TX)" [matTooltip]="element.DHLPORTS_AIB4TX" mat-cell *matCellDef="let element">{{element.DHLPORTS_AIB4TX}}</td>
            </ng-container>
            <ng-container matColumnDef="REG_NAME_LIST">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Region Id">Region</th>
              <td [matTooltipDisabled]="!isOverflow(element.REG_NAME_LIST)" [matTooltip]="element.REG_NAME_LIST" mat-cell *matCellDef="let element">{{element.REG_NAME_LIST}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openPortDialog({title: 'Edit Port', mode: 'edit', port: element, regions: regions})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openPortDialog({title: 'Delete Port', mode: 'delete', port: element})">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="portTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: portTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>         
      </div>
      <div class="parameter-footer">
        <button mat-icon-button (click)="getPort()">
          <mat-icon color="primary">refresh</mat-icon>
        </button>
        <mat-paginator #portPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of port"></mat-paginator>
      </div>
    </div> 
    <div class="parameter-group">
      <div class="parameter-heading">
        <h3>Carriers</h3>
        <div class="form-group">
          <mat-form-field class="parameter-search">
            <mat-label>Search</mat-label>
            <mat-icon matPrefix color="primary">search</mat-icon>
            <input matInput type="search" [(ngModel)]="filterCarrierValue" (input)="filterCarrierTable(filterCarrierValue)">
            <button type="button" mat-icon-button matSuffix (click)="filterCarrierValue = ''; filterCarrierTable(filterCarrierValue)" *ngIf="filterCarrierValue != ''">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <button mat-stroked-button color="accent" (click)="openCarrierDialog({title: 'Add Carrier', mode: 'add'});">Add</button>
          <button mat-stroked-button color="accent" class="btn-export" (click)="exportCarriers()" [disabled]="carrierTableDataSource.data.length === 0">Export</button>
        </div>
      </div>
      <div class="parameter-body">
        <ng-container *ngIf="hideLoaderCarrier; else loader">
          <table mat-table #carrierTable [dataSource]="carrierTableDataSource" matSort #carrierSort="matSort">
            <ng-container matColumnDef="DIM_SK_CRR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
              <td mat-cell *matCellDef="let element">{{element.DIM_SK_CRR}}</td>
            </ng-container>
            <ng-container matColumnDef="RELATIPF_APA3CD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Account">Account</th>
              <td mat-cell *matCellDef="let element">{{element.RELATIPF_APA3CD}}</td>
            </ng-container>
            <ng-container matColumnDef="RELATIPF_APCPTK">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
              <td [matTooltipDisabled]="!isOverflow(element.RELATIPF_APCPTK)" [matTooltip]="element.RELATIPF_APCPTK" mat-cell *matCellDef="let element">{{element.RELATIPF_APCPTK}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openCarrierDialog({title: 'Edit Carrier', mode: 'edit', carrier: element})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openCarrierDialog({title: 'Delete Carrier', mode: 'delete', carrier: element})">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="carrierTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: carrierTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
      <div class="parameter-footer">
        <button mat-icon-button (click)="getCarrier()">
          <mat-icon color="primary">refresh</mat-icon>
        </button>
        <mat-paginator #carrierPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of carrier"></mat-paginator>
      </div>        
    </div>
    <div class="parameter-group">
      <div class="parameter-heading">
        <h3>Regions</h3>
        <div class="form-group">
          <mat-form-field class="parameter-search">
            <mat-label>Search</mat-label>
            <mat-icon matPrefix color="primary">search</mat-icon>
            <input matInput type="search" [(ngModel)]="filterRegionValue" (input)="filterRegionTable(filterRegionValue)">
            <button type="button" mat-icon-button matSuffix (click)="filterRegionValue = ''; filterRegionTable(filterRegionValue)" *ngIf="filterRegionValue != ''">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <button mat-stroked-button color="accent" (click)="openRegionDialog({title: 'Add Region', mode: 'add'});">Add</button>
          <button mat-stroked-button color="accent" class="btn-export" (click)="exportRegions()" [disabled]="regionTableDataSource.data.length === 0">Export</button>
        </div>
      </div>
      <div class="parameter-body">
        <ng-container *ngIf="hideLoaderRegion; else loader">
          <table mat-table #regionTable [dataSource]="regionTableDataSource" matSort #regionSort="matSort">
            <ng-container matColumnDef="REG_ID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
              <td mat-cell *matCellDef="let element">{{element.REG_ID}}</td>
            </ng-container>
            <ng-container matColumnDef="REG_NAME">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
              <td mat-cell *matCellDef="let element">{{element.REG_NAME}}</td>
            </ng-container>
            <ng-container matColumnDef="REG_DESCRIPTION">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Description</th>
              <td [matTooltipDisabled]="!isOverflow(element.REG_DESCRIPTION)" [matTooltip]="element.REG_DESCRIPTION" mat-cell *matCellDef="let element">{{element.REG_DESCRIPTION}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openRegionDialog({title: 'Edit Region', mode: 'edit', region: element})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openRegionDialog({title: 'Delete Region', mode: 'delete', region: element})">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="regionTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: regionTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
      <div class="parameter-footer">
        <button mat-icon-button (click)="getRegion()">
          <mat-icon color="primary">refresh</mat-icon>
        </button>
        <mat-paginator #regionPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of region"></mat-paginator>
      </div>
    </div>
    <div class="parameter-group">
      <div class="parameter-heading">
        <h3>Customer Notifications</h3>
        <div class="form-group">
          <mat-form-field class="parameter-search">
            <mat-label>Search</mat-label>
            <mat-icon matPrefix color="primary">search</mat-icon>
            <input matInput type="search" [(ngModel)]="filterCustomerNotificationValue" (input)="filterCustomerNotificationTable(filterCustomerNotificationValue)">
            <button type="button" mat-icon-button matSuffix (click)="filterCustomerNotificationValue = ''; filterCustomerNotificationTable(filterCustomerNotificationValue)" *ngIf="filterCustomerNotificationValue != ''">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <button mat-stroked-button color="accent" (click)="openCustomerNotificationDialog({title: 'Add Notification Parameter', mode: 'add'});">Add</button>
          <button mat-stroked-button color="accent" class="btn-export" (click)="exportCustomerNotifications()" [disabled]="customerNotificationTableDataSource.data.length === 0">Export</button>
        </div>
      </div>
      <div class="parameter-body">
        <ng-container *ngIf="hideLoaderRegion; else loader">
          <table mat-table #regionTable [dataSource]="customerNotificationTableDataSource" matSort #customerNotificationSort="matSort">
            <ng-container matColumnDef="NOTIF_PARAM_CODE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">Code</th>
              <td mat-cell *matCellDef="let element">{{element.NOTIF_PARAM_CODE}}</td>
            </ng-container>
            <ng-container matColumnDef="NOTIF_PARAM_NAME">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
              <td [matTooltipDisabled]="!isOverflow(element.NOTIF_PARAM_NAME)" [matTooltip]="element.NOTIF_PARAM_NAME" mat-cell *matCellDef="let element">{{element.NOTIF_PARAM_NAME}}</td>
            </ng-container>
            <ng-container matColumnDef="NOTIF_PARAM_VALUE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Value">Value</th>
              <td [matTooltipDisabled]="!isOverflow(element.NOTIF_PARAM_VALUE)" [matTooltip]="element.NOTIF_PARAM_VALUE" mat-cell *matCellDef="let element">{{element.NOTIF_PARAM_VALUE}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openCustomerNotificationDialog({title: 'Edit Notification Parameter', mode: 'edit', customerNotification: element})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openCustomerNotificationDialog({title: 'Delete Notification Parameter', mode: 'delete', customerNotification: element})">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="customerNotificationTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: customerNotificationTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
      <div class="parameter-footer">
        <button mat-icon-button (click)="getCustomerNotification()">
          <mat-icon color="primary">refresh</mat-icon>
        </button>
        <mat-paginator #customerNotificationPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of customer notification"></mat-paginator>
      </div>
    </div>
  </div>
</section>
