<h1 mat-dialog-title>Add customers to group</h1>
<div mat-dialog-content>
  <form [formGroup]="groupForm" (submit)="onFormSubmit($event)">
    <mat-form-field>
        <mat-label>Group</mat-label>
        <mat-select name="group" formControlName="group" required>
          <mat-option>
            <ngx-mat-select-search formControlName="groupFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customerGroup of filteredCustomerGroups | async" [value]="customerGroup.CUS_GRP_ID">
            {{customerGroup.CUS_GRP_NAME}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="groupForm.get('group').invalid">You must select a group</mat-error>
      </mat-form-field>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" [disabled]="groupForm.get('group').invalid">Save</button>
    </div>
  </form>
</div>