import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IPort, ICountry, IRegion } from 'src/app/models/parameter.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParameterService } from 'src/app/services/parameter.service';
import { ReplaySubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

export interface IPortDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  port?: IPort;
  countries?: Array<ICountry>;
  regions?: Array<IRegion>;
}

@Component({
  selector: 'port-dialog',
  templateUrl: 'port-dialog.component.html',
  styleUrls: ['port-dialog.component.scss']
})
export class PortDialogComponent implements OnInit {
  portForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  filteredCountries: ReplaySubject<Array<ICountry>> = new ReplaySubject<Array<ICountry>>(1);
  filteredRegions: ReplaySubject<Array<IRegion>> = new ReplaySubject<Array<IRegion>>(1);
  
  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<PortDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IPortDialog, private parameterService: ParameterService, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit(): void {
    this.portForm = this.formBuilder.group({
      countryCode: new UntypedFormControl(this.data.port?.DHLPORTS_AIAACD, [Validators.required]),
      countryCodeFilter: new UntypedFormControl(),
      code: new UntypedFormControl(this.data.port?.DHLPORTS_AIAOCD, [Validators.required]),
      description: new UntypedFormControl(this.data.port?.DHLPORTS_AIB4TX, [Validators.required]),
      regions: new UntypedFormControl((this.data.port?.REG_LIST) ? (this.data.port?.REG_LIST.toString()).split(',').map(Number) : null),
      regionsFilter: new UntypedFormControl('')
    });

    console.log(this.data.regions); 

    if (this.data.mode == 'add' || this.data.mode == 'edit') {
      this.filteredCountries.next(this.data.countries);
      this.filteredRegions.next(this.data.regions);

      this.portForm.get('countryCodeFilter').valueChanges.subscribe(() => {
        this.filterCountries()
      });

      this.portForm.get('regionsFilter').valueChanges.subscribe(() => {
        this.filterRegions();
      });

    }
    
    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.portForm.disable();
    }
  }  

  filterRegions(): void {
    const filterValue = this.portForm.get('regionsFilter').value.toLowerCase();
    this.filteredRegions.next(this.data.regions.filter((region) => region.REG_NAME.toLowerCase().includes(filterValue)));
  }

  filterCountries(): void {
    const filterValue = this.portForm.get('countryCodeFilter').value.toLowerCase();
    this.filteredCountries.next(this.data.countries.filter((country) => country.COU_CODE.toLowerCase().includes(filterValue)));   
  }

  closeDialog(port: IPort): void {
    this.dialogRef.close(port);
  }

  enableForm(): void {
    this.isFormEnabled = true;
    this.portForm.get('description').enable();
    this.portForm.get('regions').enable();
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.portForm.value;
    let newPort: IPort;

    if (this.data.mode == 'add'){
      newPort = {
        DHLPORTS_AIAACD: formValue.countryCode,
        DHLPORTS_AIAOCD: formValue.code,
        DHLPORTS_AIB4TX: formValue.description,
        PORT_USER: this.authService.userId,
        REG_LIST: formValue.regions
      }
    } else if (this.data.mode == 'edit') {
      newPort = {
        DIM_SK_PORT: this.data.port.DIM_SK_PORT,
        DHLPORTS_AIAACD: formValue.countryCode,
        DHLPORTS_AIAOCD: formValue.code,
        DHLPORTS_AIB4TX: formValue.description,
        PORT_STATUS: 1,
        PORT_USER: this.authService.userId,
        REG_LIST: formValue.regions
      }
    } else if(this.data.mode == 'delete') {
      newPort = {
        DIM_SK_PORT: this.data.port.DIM_SK_PORT,
        DHLPORTS_AIAACD: null,
        DHLPORTS_AIAOCD: null,
        DHLPORTS_AIB4TX: null,
        PORT_STATUS: 0,
        PORT_USER: this.authService.userId
      }
    }

    this.closeDialog(newPort);
  }

  getCountryCodeErrorMessage(): string {
    if (this.portForm.get('countryCode').hasError('required')) {
      return 'You must select a country code';
    } else {
      return '';
    }
  }

  getCodeErrorMessage(): string {
    if (this.portForm.get('code').hasError('required')) {
      return 'You must enter a code';
    } else {
      return '';
    }
  }

  getDescriptionErrorMessage(): string {
    if (this.portForm.get('description').hasError('required')) {
      return 'You must enter a description';
    } else {
      return '';
    }
  }

  areInputsValid(): boolean {
    const code = this.portForm.get('countryCode');
    const teus = this.portForm.get('code');
    const description = this.portForm.get('description');

    return code.invalid || teus.invalid || description.invalid;
  }
}