<h1 mat-dialog-title>Filter Outstanding</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Service</mat-label>
        <mat-select name="status" formControlName="service">
          <mat-option value="DEMURRAGE">Demurrage</mat-option>
          <mat-option value="DETENTION">Detention</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('service')" (click)="resetField('service')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Invoice Status</mat-label>
        <mat-select name="invoiceStatus" formControlName="invoiceStatus">
          <mat-option value="0">Open</mat-option>
          <mat-option value="1">In Dispute</mat-option>
          <mat-option value="2">Invoiced</mat-option>
          <mat-option value="3">Paid</mat-option>
          <mat-option value="4">Canceled</mat-option>
          <mat-option value="5">Under Analysis</mat-option>
          <mat-option value="6">Release Billing</mat-option>
          <mat-option value="7">Customer Notified</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('invoiceStatus')" (click)="resetField('invoiceStatus')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Invoice Number</mat-label>
        <input matInput type="text" formControlName="invNumber">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('invNumber')" (click)="resetField('invNumber')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Container</mat-label>
        <input matInput type="text" formControlName="container">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('container')" (click)="resetField('container')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>HBL</mat-label>
        <input matInput type="text" formControlName="hbl">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('hbl')" (click)="resetField('hbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>MBL</mat-label>
        <input matInput type="text" formControlName="mbl">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('mbl')" (click)="resetField('mbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer">
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_CODE}} - {{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('customer')" (click)="resetField('customer'); resetField('customerFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers" [value]="carrier.RELATIPF_APCPTK">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('carrier')" (click)="resetField('carrier')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status" multiple>
          <mat-option value="0">New Process</mat-option>
          <mat-option value="1">Under Analysis</mat-option>
          <mat-option value="2">Dispute DHL</mat-option>
          <mat-option value="3">Dispute Carrier</mat-option>
          <mat-option value="4">Programed Payment</mat-option>
          <mat-option value="5">Paid (Dispute)</mat-option>
          <mat-option value="6">Paid</mat-option>
          <mat-option value="7">Invoice Canceled</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('status')" (click)="resetField('status')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sub-Status</mat-label>
        <mat-select name="subStatus" formControlName="subStatus" multiple>
          <mat-option value="0">Under Internal Review</mat-option>
          <mat-option value="1">FT & PD Incorrect</mat-option>
          <mat-option value="2">Incorrect Invoice</mat-option>
          <mat-option value="3">Partial Invoice</mat-option>
          <mat-option value="4">Invoice Not Found</mat-option>
          <mat-option value="5">Waiting Confirmation (Free-time)</mat-option>
          <mat-option value="6">Waiting for Loss Approval</mat-option>
          <mat-option value="7">Waiting to Process Billing</mat-option>
          <mat-option value="8">Waiting Confirmation of Ship Transfer</mat-option>
          <mat-option value="9">Ok to Pay</mat-option>
          <mat-option value="10">Paid</mat-option>
          <mat-option value="11">Old Payment</mat-option>
          <mat-option value="12">Invoice Canceled</mat-option>
          <mat-option value="13">Ship Delay</mat-option>
          <mat-option value="14">Terminal Window Delay</mat-option>
          <mat-option value="15">Waiting Payment Order</mat-option>
          <mat-option value="16">Waiting Reimbursement</mat-option>
          <mat-option value="17">Dispute</mat-option>
          <mat-option value="18">Waiting Customer to Pay</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('subStatus')" (click)="resetField('subStatus')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Verification Status</mat-label>
        <mat-select name="verificationStatus" formControlName="verificationStatus" multiple>
          <mat-option value="0">Unable To Verify</mat-option>
          <mat-option value="1">Verified</mat-option>
          <mat-option value="2">Over Valued</mat-option>
          <mat-option value="3">Under Valued</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('verificationStatus')" (click)="resetField('verificationStatus')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Container Type</mat-label>
        <mat-select name="containerType" formControlName="containerType" [disabled]="containerTypes.length === 0">
          <mat-option>
            <ngx-mat-select-search formControlName="containerTypeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let containerType of filteredContainerTypes | async" [value]="containerType.CONTYPPF_QAMPZ">{{containerType.CONTYPPF_QAMPZ}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('containerType')" (click)="resetField('containerType'); resetField('containerTypeFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>      
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Inclusion Date Range</mat-label>
        <mat-date-range-input [rangePicker]="incRangePicker">
          <input matStartDate name="incStart" formControlName="incStart" placeholder="Start date">
          <input matEndDate name="incFinal" formControlName="incFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="incRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="form.get('incStart').setValue(null); form.get('incFinal').setValue(null)" *ngIf="form.get('incStart').value !== null || form.get('incFinal').value !== null">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #incRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="close(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetForm()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>