import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerGroupsDialogComponent } from './customer-groups-dialog/customer-groups-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { HotToastModule } from '@ngneat/hot-toast';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomerGroupsViewDialogComponent } from './customer-groups-view-dialog/customer-groups-view-dialog.component';
import { CustomerGroupsDeleteAllDialogComponent } from './customer-groups-delete-all-dialog/customer-groups-delete-all-dialog.component';

@NgModule({
  declarations: [
    CustomerGroupsDialogComponent,
    CustomerGroupsViewDialogComponent,
    CustomerGroupsDeleteAllDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HotToastModule,
    NgxMatSelectSearchModule
  ]
})
export class CustomerGroupDialogsModule { }
