import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAttachment, IAttachmentRef } from 'src/app/models/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };

  getAttachments(attachmentRef: IAttachmentRef): Observable<Array<IAttachment>> {
    return this.httpClient.get<Array<IAttachment>>(`${this.apiURL}/api/attachment?filter=${JSON.stringify(attachmentRef)}`, this.httpOptions);
  }

  postAttachment(attachments: Array<IAttachment>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/attachment?filter=${JSON.stringify(attachments)}`, null, this.httpOptions);
  }

  putAttachment(attachments: Array<IAttachment>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/attachment/delete?filter=${JSON.stringify(attachments)}`, null, this.httpOptions);
  }
}
