import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { ShipmentExcel, ShipmentIntegration } from 'src/app/models/shipment-integration.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'dhl-shipments-import-dialog',
  templateUrl: './shipments-import-dialog.component.html',
  styleUrls: ['./shipments-import-dialog.component.scss']
})
export class ShipmentsImportDialogComponent implements OnInit {
  protected bucketName = environment.bucketName;
  templateUrl = `https://${this.bucketName}.s3.amazonaws.com/template/shipments-template.xlsx`;
  
  files: Array<File> = [];
  shipments: ShipmentIntegration[] = [];
  isImport: boolean = false;

  types: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  ];

  constructor(
    private dialogRef: MatDialogRef<ShipmentsImportDialogComponent>,
    private shipmentService: ShipmentService,
    private toast: HotToastService,
  ) { }

  ngOnInit() {
  }

  onSelect(e: NgxDropzoneChangeEvent): void {
    this.shipments = [];
    this.files.push(...e.addedFiles);

    const file = this.files[0];

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (ev: ProgressEvent): void => {
      let binary = "";
      let bytes = new Uint8Array((<any>ev.target).result);
      let length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      
      const workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
      const excelData = workbook.SheetNames.reduce((initial: any, name: any) => {
        return XLSX.utils.sheet_to_json(workbook.Sheets[name]);
      }, {});

      const jsonData: ShipmentIntegration[] = excelData.map((original: ShipmentExcel) => {
        const converted = this.convertShipmentObject(original);

        if (converted.Shipment.Exceptions?.[0].Code === "-") {
          converted.Shipment.Exceptions = null;
        }

        if(converted.Shipment.Consignee?.Code === "-"){
          converted.Shipment.Consignee = null;
        }

        if(converted.Shipment.LocalClient?.Code === "-"){
          converted.Shipment.LocalClient = null;
        }

        if(converted.Shipment.NotifyParty?.Code === "-"){
          converted.Shipment.NotifyParty = null;
        }

        if(converted.Shipment.Shipper?.Code === "-"){
          converted.Shipment.Shipper = null;
        }
        
        return converted;
      });

      this.shipments.push(...jsonData);
    }
  }

  convertShipmentObject(original: ShipmentExcel): ShipmentIntegration{   
    return {
      Shipment: {
        HousebillNumber: original.HousebillNumber,
        ShipmentID: original.ShipmentID,
        CompanyCode: original.CompanyCode,
        CountryCode: original.CountryCode,
        CommodityCode: original.CommodityCode === "-" ? null : original.CommodityCode,
        ContainerMode: original.ContainerMode,
        DepartmentCode: original.DepartmentCode,
        DeliveryCountry: original.DeliveryCountry,
        Incoterms: original.Incoterms,
        PickUpCountry: original.PickUpCountry,
        TransportMode: original.TransportMode,
        ShipmentETA: Number(original.ShipmentETA) ? original.ShipmentETA : null,
        ShipmentETD: Number(original.ShipmentETD) ? original.ShipmentETD : null,
        ShipmentATA: Number(original.ShipmentATA) ? original.ShipmentATA : null,
        ShipmentATD: Number(original.ShipmentATD) ? original.ShipmentATD : null,
        Consignee: {
          Code: original.ConsigneeCode,
          Name: original.ConsigneeName,
          Address: original.ConsigneeAddress,
        },
        LocalClient: {
          Code: original.LocalClientCode,
          Name: original.LocalClientName,
          Address: original.LocalClientAddress,
          TaxRegistrationNumber: original.LocalClientTaxRegistrationNumber,
        },
        NotifyParty: {
          Code: original.NotifyPartyCode,
          Name: original.NotifyPartyName,
          Address: original.NotifyPartyAddress,
        },
        Shipper: {
          Code: original.ShipperCode,
          Name: original.ShipperName,
          Address: original.ShipperAddress,
        },
        PortOfLoading: {
          LocationCode: original.PortOfLoadingLocationCode,
          LocationName: original.PortOfLoadingLocationName,
        },
        PortOfDischarge: {
          LocationCode: original.PortOfDischargeLocationCode,
          LocationName: original.PortOfDischargeLocationName,
        },
        Exceptions: [
          {
            Description: original.ExceptionDescription,
            Code: original.ExceptionCode,
            StaffCode: original.ExceptionStaffCode,
            Timestamp: Number(original.ExceptionTimestamp) ? original.ExceptionTimestamp : null,
          }
        ],
        Masterbills: [
          {
            MasterbillNumber: original.MasterbillNumber === "-" ? null : original.MasterbillNumber,
            ConsolID: original.MasterbillConsolID,
            ConsolType: original.MasterbillConsolType,
            VesselDepartureDate: Number(original.MasterbillVesselDepartureDate) ? original.MasterbillVesselDepartureDate : null,
            VesselName: original.MasterbillVesselName,
            VoyageFlightNumber: original.MasterbillVoyageFlightNumber === "-" ? null : original.MasterbillVoyageFlightNumber,
            Carrier: {
              CarrierCode: original.CarrierCode,
              CarrierName: original.CarrierName,
            },
          },
        ],
        TransportUnits: [
          {
            TransportUnitID: original.TransportUnitID,
            TransportUnitType: original.TransportUnitType,
            EmptyContainerPickUp: Number(original.TransportUnitEmptyContainerPickUp) ? original.TransportUnitEmptyContainerPickUp : null,
            GatedInAtTerminalDate: Number(original.TransportUnitGatedInAtTerminalDate) ? original.TransportUnitGatedInAtTerminalDate : null,
          },
        ],
      },
    };
  }

  import(): void{
    this.isImport = true;
    const ref = this.toast.loading('Saving...',{autoClose: false});
    this.shipmentService.postShipmentIntegration(this.shipments)
      .subscribe((response)=>{
        ref.close();
        this.toast.success(String(response));
        this.close();
      },(error) =>{
        if(error.status === 0){
          ref.close();
          this.toast.success('Container(s) imported successfully.');
          this.close();
        }else{
          ref.close();
          this.toast.error(error.error.Message);
        }
      });
  }

  onRemove(file: File): void {
    this.files.splice(this.files.indexOf(file), 1);
  }

  close(): void {
    this.dialogRef.close(this.isImport);
  }
}