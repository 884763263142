<h1 mat-dialog-title>Edit Date</h1>
<div mat-dialog-content>
  <form [formGroup]="dateForm" (submit)="onFormSubmit($event)">
    <div class="form-group vertical">
      <mat-form-field>
        <mat-label>Date</mat-label>
        <input matInput name="date" formControlName="date" [matDatepicker]="picker" [disabled]="data.dateType !== 'custom'" [min]="minDate" [max]="maxDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-group vertical">
      <dhl-comment [showCommentsTab]="false" (comment)="onComment($event)" [offSideComments]="true"></dhl-comment>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" [disabled]="dateForm.invalid || !comment">Save</button>
    </div>
  </form>
</div>

