import { Component } from '@angular/core';

@Component({
  selector: 'dhl-login-layout',
  templateUrl: './login-layout.component.html'
})
export class LoginLayoutComponent {

  constructor() { }
  
}
