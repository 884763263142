<header>
  <h2>Analytics - Customers</h2>
  <div class="financial-dashboard-filters">
    <mat-form-field>
      <mat-label>Customer</mat-label>
      <mat-select name="customer" [formControl]="customer" multiple [disabled]="!filteredCustomers.length">
        <mat-option>
          <ngx-mat-select-search [formControl]="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let customer of filteredCustomers" [value]="customer.REP_CUS_NAME">{{customer.REP_CUS_NAME}}</mat-option>
      </mat-select>
      <button type="button" mat-icon-button matSuffix (click)="customer.setValue(null)" *ngIf="customer.value?.length > 0">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </mat-form-field>    
    <mat-form-field class="year">
      <mat-label>Year</mat-label>
      <mat-select [formControl]="year">
        <mat-option *ngFor="let year of reportYears" [value]="year.FIN_YEAR">{{year.FIN_YEAR}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</header>
<div class="dashboard-container">
  <div class="dashboard">
    <div class="expandable">
      <button type="button" class="open-expandable" [ngClass]="{'open': openGroups[0]}" (click)="openGroups[0] = !openGroups[0]">
        <span>Top Customers - Revenue BRL</span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <div class="expandable-content" [ngClass]="{'collapsed': !openGroups[0]}">
        <ng-container *ngIf="hideLoaderTop; else loader">
          <div>
            <button mat-flat-button color="accent" (click)="exportTopCustomers()">Export</button>
          </div>
          <table mat-table #financialTopCustomerTable [dataSource]="financialTopCustomerTableDataSource">
            <ng-container matColumnDef="TOP_CUS_NAME">
              <th mat-header-cell *matHeaderCellDef>Customer</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_NAME)" [matTooltip]="element.TOP_CUS_NAME" mat-cell *matCellDef="let element">{{element.TOP_CUS_NAME}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_PDT">
              <th mat-header-cell *matHeaderCellDef>Product</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_PDT)" [matTooltip]="element.TOP_CUS_PDT" mat-cell *matCellDef="let element">{{element.TOP_CUS_PDT}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_JAN">
              <th mat-header-cell *matHeaderCellDef>January</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_JAN)" [matTooltip]="element.TOP_CUS_JAN" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_JAN < 0}">{{element.TOP_CUS_JAN | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_FEV">
              <th mat-header-cell *matHeaderCellDef>February</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_FEV)" [matTooltip]="element.TOP_CUS_FEV" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_FEV < 0}">{{element.TOP_CUS_FEV | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_MAR">
              <th mat-header-cell *matHeaderCellDef>March</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_MAR)" [matTooltip]="element.TOP_CUS_MAR" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_MAR < 0}">{{element.TOP_CUS_MAR | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_APR">
              <th mat-header-cell *matHeaderCellDef>April</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_APR)" [matTooltip]="element.TOP_CUS_APR" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_APR < 0}">{{element.TOP_CUS_APR | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_MAY">
              <th mat-header-cell *matHeaderCellDef>May</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_MAY)" [matTooltip]="element.TOP_CUS_MAY" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_MAY < 0}">{{element.TOP_CUS_MAY | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_JUN">
              <th mat-header-cell *matHeaderCellDef>June</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_JUN)" [matTooltip]="element.TOP_CUS_JUN" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_JUN < 0}">{{element.TOP_CUS_JUN | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_JUL">
              <th mat-header-cell *matHeaderCellDef>July</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_JUL)" [matTooltip]="element.TOP_CUS_JUL" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_JUL < 0}">{{element.TOP_CUS_JUL | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_AUG">
              <th mat-header-cell *matHeaderCellDef>August</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_AUG)" [matTooltip]="element.TOP_CUS_AUG" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_AUG < 0}">{{element.TOP_CUS_AUG | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_SEP">
              <th mat-header-cell *matHeaderCellDef>September</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_SEP)" [matTooltip]="element.TOP_CUS_SEP" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_SEP < 0}">{{element.TOP_CUS_SEP | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_OCT">
              <th mat-header-cell *matHeaderCellDef>October</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_OCT)" [matTooltip]="element.TOP_CUS_OCT" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_OCT < 0}">{{element.TOP_CUS_OCT | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_NOV">
              <th mat-header-cell *matHeaderCellDef>November</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_NOV)" [matTooltip]="element.TOP_CUS_NOV" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_NOV < 0}">{{element.TOP_CUS_NOV | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_DEC">
              <th mat-header-cell *matHeaderCellDef>December</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_DEC)" [matTooltip]="element.TOP_CUS_DEC" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_NOV < 0}">{{element.TOP_CUS_DEC | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="TOP_CUS_REV">
              <th mat-header-cell *matHeaderCellDef>Revenue Total</th>
              <td [matTooltipDisabled]="!isOverflow(element.TOP_CUS_REV)" [matTooltip]="element.TOP_CUS_REV" mat-cell *matCellDef="let element" [ngClass]="{negative: element.TOP_CUS_REV < 0}">{{element.TOP_CUS_REV | currency:'BRL'}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="financialTopCustomerTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: financialTopCustomerTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="dashboard">
    <div class="expandable">
      <button type="button" class="open-expandable" [ngClass]="{'open': openGroups[1]}" (click)="openGroups[1] = !openGroups[1]">
        <span>Anual Performance GP • Demurrage and Detention</span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <div class="expandable-content" [ngClass]="{'collapsed': !openGroups[1]}">
        <div class="row">
          <div class="chart-column full">
            <apx-chart #mainChart
              [colors]="mainChartOptions.colors"
              [series]="mainChartOptions.series"
              [chart]="mainChartOptions.chart"
              [stroke]="mainChartOptions.stroke"
              [title]="mainChartOptions.title"
              [xaxis]="mainChartOptions.xaxis"
              [yaxis]="mainChartOptions.yaxis"
              [tooltip]="mainChartOptions.tooltip"
              [legend]="mainChartOptions.legend"
            ></apx-chart>
          </div>
        </div>
        <ng-container *ngIf="hideLoader; else loader">
          <div>
            <button mat-flat-button color="accent" (click)="exportAnualPerformance()">Export</button>
          </div>
          <table mat-table #financialCustomerAnualTable [dataSource]="financialCustomerAnualTableDataSource">
            <ng-container matColumnDef="REP_CUS_MONTH">
              <th mat-header-cell *matHeaderCellDef>Month</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_MONTH)" [matTooltip]="element.REP_CUS_MONTH" mat-cell *matCellDef="let element">{{element.REP_CUS_NAME === 'Sum' ? element.REP_CUS_NAME : numberToMonthMap.get(element.REP_CUS_MONTH)}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_REV">
              <th mat-header-cell *matHeaderCellDef>Revenue BRL</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_REV)" [matTooltip]="element.REP_CUS_REV" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_REV < 0}">{{element.REP_CUS_REV | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_COS">
              <th mat-header-cell *matHeaderCellDef>Cost BRL</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_COS)" [matTooltip]="element.REP_CUS_COS" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_COS < 0}">{{element.REP_CUS_COS | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_ACT_BRL">
              <th mat-header-cell *matHeaderCellDef>Actual GP BRL</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_ACT_BRL)" [matTooltip]="element.REP_CUS_ACT_BRL" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_ACT_BRL < 0}">{{element.REP_CUS_ACT_BRL | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_ACT_EUR">
              <th mat-header-cell *matHeaderCellDef>Actual GP EUR</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_ACT_EUR)" [matTooltip]="element.REP_CUS_ACT_EUR" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_ACT_EUR < 0}">{{element.REP_CUS_ACT_EUR | currency:'EUR'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_PY_EUR">
              <th mat-header-cell *matHeaderCellDef>Past Year GP EUR</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_PY_EUR)" [matTooltip]="element.REP_CUS_PY_EUR" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_PY_EUR < 0}">{{element.REP_CUS_PY_EUR | currency:'EUR'}}</td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="financialCustomerAnualTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: financialCustomerAnualTableDisplayedColumns;"></tr>
          </table>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="dashboard">
    <div class="expandable">
      <button type="button" class="open-expandable" [ngClass]="{'open': openGroups[2]}" (click)="openGroups[2] = !openGroups[2]">
        <span>Customers Performance GP ({{getSelectedMonthsString()}})</span>
        <mat-icon>expand_more</mat-icon>
      </button>      
      <div class="expandable-content" [ngClass]="{'collapsed': !openGroups[2]}">
        <ng-container *ngIf="hideLoader; else loader">
          <div class="customer-header">
            <button mat-flat-button color="accent" (click)="exportCustomersPerformance()">Export</button>
            <div class="financial-dashboard-filters">
              <mat-form-field class="month">
                <mat-label>Month</mat-label>
                <mat-select [formControl]="month" multiple>
                  <ng-container *ngFor="let month of numberToMonthMap | keyvalue">
                    <mat-option *ngIf="(month.key <= date.getMonth() + 1) || (year.value < date.getFullYear())" [value]="month.key">
                      {{month.value}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <table mat-table #financialCustomerTable [dataSource]="financialCustomerTableDataSource">
            <ng-container matColumnDef="REP_CUS_NAME">
              <th mat-header-cell *matHeaderCellDef>Customer</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_NAME)" [matTooltip]="element.REP_CUS_NAME" mat-cell *matCellDef="let element">{{element.REP_CUS_NAME}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_REV">
              <th mat-header-cell *matHeaderCellDef>Revenue BRL</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_REV)" [matTooltip]="element.REP_CUS_REV" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_REV < 0}">{{element.REP_CUS_REV | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_COS">
              <th mat-header-cell *matHeaderCellDef>Cost BRL</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_COS)" [matTooltip]="element.REP_CUS_COS" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_COS < 0}">{{element.REP_CUS_COS | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_ACT_BRL">
              <th mat-header-cell *matHeaderCellDef>Actual GP BRL</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_ACT_BRL)" [matTooltip]="element.REP_CUS_ACT_BRL" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_ACT_BRL < 0}">{{element.REP_CUS_ACT_BRL | currency:'BRL'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_ACT_EUR">
              <th mat-header-cell *matHeaderCellDef>Actual GP EUR</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_ACT_EUR)" [matTooltip]="element.REP_CUS_ACT_EUR" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_ACT_EUR < 0}">{{element.REP_CUS_ACT_EUR | currency:'EUR'}}</td>
            </ng-container>
            <ng-container matColumnDef="REP_CUS_PY_EUR">
              <th mat-header-cell *matHeaderCellDef>Past Year GP EUR</th>
              <td [matTooltipDisabled]="!isOverflow(element.REP_CUS_PY_EUR)" [matTooltip]="element.REP_CUS_PY_EUR" mat-cell *matCellDef="let element" [ngClass]="{negative: element.REP_CUS_PY_EUR < 0}">{{element.REP_CUS_PY_EUR | currency:'EUR'}}</td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="financialCustomerTableDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: financialCustomerTableDisplayedColumns;"></tr>
          </table>
          <mat-paginator #financialCustomerPaginator [pageSizeOptions]="[10, 20, 30]" pageSize="10" showFirstLastButtons aria-label="select page of financial customer" ></mat-paginator>
        </ng-container>
        <ng-template #loader>
          <dhl-spinner></dhl-spinner>
        </ng-template>
      </div>
    </div>
  </div>
</div>
