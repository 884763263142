import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ICarrier } from "src/app/models/parameter.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/services/auth.service";

export interface ICarrierDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  carrier?: ICarrier;
}

@Component({
  selector: 'carrier-dialog',
  templateUrl: 'carrier-dialog.component.html',
  styleUrls: ['carrier-dialog.component.scss']
})
export class CarrierDialogComponent implements OnInit {
  carrierForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  
  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CarrierDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICarrierDialog, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit() {
    this.carrierForm = this.formBuilder.group({
      account: new UntypedFormControl(this.data.carrier?.RELATIPF_APA3CD, [Validators.required]),
      carrierName: new UntypedFormControl(this.data.carrier?.RELATIPF_APCPTK, [Validators.required])
    });

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.carrierForm.disable();
    }
  }

  closeDialog(carrier: ICarrier) {
    this.dialogRef.close(carrier);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.carrierForm.get('carrierName').enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.carrierForm.value;
    let newCarrier: ICarrier;

    if (this.data.mode == 'add') {
      newCarrier = {
        RELATIPF_APA3CD: formValue.account,
        RELATIPF_APCPTK: formValue.carrierName,
        CRR_USER: this.authService.userId
      }
    } else if (this.data.mode == 'edit') {
      newCarrier = {
        DIM_SK_CRR: this.data.carrier.DIM_SK_CRR,
        RELATIPF_APA3CD: formValue.account,
        RELATIPF_APCPTK: formValue.carrierName,
        CRR_STATUS: 1,
        CRR_USER: this.authService.userId
      }
    } else if (this.data.mode == 'delete') {
      newCarrier = {
        DIM_SK_CRR: this.data.carrier.DIM_SK_CRR,
        RELATIPF_APA3CD: null,
        RELATIPF_APCPTK: null,
        CRR_STATUS: 0,
        CRR_USER: this.authService.userId
      }
    }

    this.closeDialog(newCarrier);
  }

  getAccountErrorMessage(): string {
    if (this.carrierForm.get('account').hasError('required')) {
      return 'You must enter an account';
    } else {
      return '';
    }
  }

  getCarrierNameErrorMessage(): string {
    if (this.carrierForm.get('carrierName').hasError('required')) {
      return 'You must enter a name';
    } else {
      return '';
    }
  }
}