import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

export interface IAgreementDeleteDialog {
  id: number;
}

@Component({
  selector: 'dhl-agreement-delete-dialog',
  templateUrl: './agreement-delete-dialog.component.html',
  styleUrls: ['./agreement-delete-dialog.component.scss']
})
export class AgreementDeleteDialogComponent implements OnInit {
  id: number;

  constructor(public dialogRef: MatDialogRef<AgreementDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IAgreementDeleteDialog) {
    this.id = data.id;
  }

  ngOnInit() {
  }

  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}
