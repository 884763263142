import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';
import { Moment } from 'moment';


export interface IDateDialog {
  date: Moment;
  willDelete: boolean;
}

@Component({
  selector: 'dhl-date-dialog',
  templateUrl: './date-dialog.component.html',
  styleUrls: ['./date-dialog.component.scss']
})
export class DateDialogComponent implements OnInit {
  dateForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<Date>,
  ) { }

  ngOnInit(): void {
    this.dateForm = this.formBuilder.group({
      date: new UntypedFormControl(this.data?.element, [Validators.required])
    });
  }

  closeDialog(date: IDateDialog): void {
    this.dialogRef.close(date);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    if (this.data.mode === 'add' || this.data.mode === 'edit') {
      const date: IDateDialog = {
        date: this.dateForm.get('date').value,
        willDelete: false
      }
      this.closeDialog(date);
    } else if (this.data.mode === 'delete') {
      const date: IDateDialog = {
        date: null,
        willDelete: true
      }
      this.closeDialog(date);
    }
  }
}
