import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInvoice } from 'src/app/models/invoice.model';
import { AuthService } from 'src/app/services/auth.service';

export interface IApplyDiscountDialog {
  element?: IInvoice | Array<IInvoice>;
}

@Component({
  selector: 'dhl-apply-discount-dialog',
  templateUrl: './apply-discount-dialog.component.html',
  styleUrls: ['./apply-discount-dialog.component.scss']
})
export class ApplyDiscountDialogComponent implements OnInit {
  form: UntypedFormGroup;

  comment: string = '';
  files: Array<File> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IApplyDiscountDialog,
    private dialogRef: MatDialogRef<ApplyDiscountDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      value: new UntypedFormControl(this.data.element instanceof Array ? '' : this.data.element?.INVOICE_DISCOUNT),
      type: new UntypedFormControl(this.data.element instanceof Array ? '' : this.data.element?.INVOICE_DISCOUNT_TYPE)
    });
  }

  close(value: [Array<IInvoice>, string, Array<File>]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newInvoice: Array<IInvoice> = [];

    if (this.data.element instanceof Array) {
      this.data.element = this.data.element.filter((element) => {
        return element.INVOICE_SITUATION !== '2' && element.INVOICE_SITUATION !== '3';
      });

      newInvoice = this.data.element.map((element) => {
        return {
          INVOICE_ID: element.INVOICE_ID,
          INVOICE_DISCOUNT: this.form.value.value,
          INVOICE_DISCOUNT_TYPE: this.form.value.type,
          INVOICE_USER: this.authService.userId
        }
      });
    } else {
      newInvoice.push({
        INVOICE_ID: this.data.element.INVOICE_ID,
        INVOICE_DISCOUNT: this.form.value.value,
        INVOICE_DISCOUNT_TYPE: this.form.value.type,
        INVOICE_USER: this.authService.userId
      });
    }

    this.close([newInvoice, this.comment, this.files]);
  }

  maxDiscount(): number {
    if (this.form.get('type').value === '0') {
      return 100;
    } else {
      if (!(this.data.element instanceof Array)) {
        return Number(this.data.element?.INVOICE_TOTAL_AMOUNT);
      }
    }
  }

  onComment(comment: string) {
    this.comment = comment;
  }

  onUpload(files: Array<File>) {
    this.files = [];
    files.forEach(file => this.files.push(file));
  }
}
