import { Navigation, NavItem } from './navbar.model';

const shipmentsNavigation: NavItem = {
  text: 'Shipments',
  icon: 'local_shipping',
  isExpanded: false,
  items: [
    {
      text: 'Shipments',
      path: '/shipments'
    },
    {
      text: 'DRT',
      path: '/shipments/drt'
    }
  ]
};

const demurrageNavigation: NavItem = {
  text: 'Demurrage',
  icon: 'exit_to_app',
  isExpanded: false,
  items: [
    {
      text: 'Overview Analysis',
      path: '/demurrage/process'
    },
    {
      text: 'Robot Dates',
      path: '/demurrage/automatic'
    },
    {
      text: 'Generate Invoices',
      path: '/demurrage/generate'
    },
    {
      text: 'Payables Forecast',
      path: '/demurrage/payables'
    }
  ]
};

const detentionNavigation: NavItem = {
  text: 'Detention',
  icon: 'output',
  isExpanded: false,
  items: [
    {
      text: 'Overview Analysis',
      path: '/detention/process'
    },
    {
      text: 'Robot Dates',
      path: '/detention/automatic'
    },
    {
      text: 'Generate Invoices',
      path: '/detention/generate'
    },
    {
      text: 'Payables Forecast',
      path: '/detention/payables'
    }
  ]
};

const invoicesNavigation: NavItem = {
  text: 'Invoices',
  icon: 'receipt',
  isExpanded: false,
  items: [
    {
      text: 'Invoices',
      path: '/invoices'
    },
    {
      text: 'Partials',
      path: '/invoices/partial'
    }
  ]
};

const payablesNavigation: NavItem = {
  text: 'Payables',
  icon: 'payments',
  isExpanded: false,
  items: [
    {
      text: 'Outstanding',
      path: '/payables'
    },
    {
      text: 'Generate P.O.',
      path: '/payables/generate'
    },
    {
      text: 'Payment Order (SAT)',
      path: '/payables/po'
    }
  ]
};

const agreementsNavigation: NavItem = {
  text: 'Agreements',
  icon: 'assignment',
  isExpanded: false,
  items: [
    {
      text: 'Sale Agreements',
      path: '/agreements/sale'
    },
    {
      text: 'Purchase Agreements',
      path: '/agreements/purchase'
    },
    {
      text: 'Standard DHL Rules',
      path: '/agreements/default/dhl'
    },
    {
      text: 'Standard Carrier Rules',
      path: '/agreements/default/carrier'
    }
  ]
};

const customersNavigation: NavItem = {
  text: 'Customers',
  icon: 'group',
  isExpanded: false,
  items: [
    {
      text: 'Customers',
      path: '/customers'
    },
    {
      text: 'Customer Groups',
      path: '/customer/groups'
    },
    {
      text: 'Customer Exceptions',
      path: '/customer/exceptions'
    }
  ]
};

const termsNavigation: NavItem = {
  text: 'Terms',
  icon: 'description',
  isExpanded: false,
  items: [
    {
      text: 'Customer Terms',
      path: '/unique-terms/customers'
    },
    {
      text: 'Carrier Terms',
      path: '/unique-terms/carriers'
    }
  ]
};

const parametersNavigation: NavItem = {
  text: 'Parameters',
  icon: 'settings',
  isExpanded: false,
  items: [
    {
      text: 'Parameters',
      path: '/parameters'
    }
  ]
};

const logsNavigation: NavItem = {
  text: 'Logs',
  icon: 'folder',
  isExpanded: false,
  items: [
    {
      text: 'DDM Logs',
      path: '/logs'
    },
    {
      text: 'RPA Logs',
      path: '/logs/rpa'
    },
    {
      text: 'Integration Logs',
      path: '/logs/integration'
    },
    {
      text: 'Invoice Notification Logs',
      path: '/logs/notification'
    }
  ]
};

const usersNavigation: NavItem = {
  text: 'Users',
  icon: 'person',
  isExpanded: false,
  items: [
    {
      text: 'Users',
      path: '/users'
    },
    {
      text: 'User Groups',
      path: '/user/groups'
    },
    {
      text: 'User Pages',
      path: '/user/pages'
    }
  ]
};

const analyticsNavigation: NavItem = {
  text: 'Analytics',
  icon: 'monitoring',
  isExpanded: false,
  items: [
    {
      text: 'Finances',
      path: '/financial/dashboard'
    },
    {
      text: 'Customers',
      path: '/financial/dashboard/customers'
    },
    {
      text: 'FRP List',
      path: '/financial/list'
    }
  ]
};

export const navigation: Navigation = [
  shipmentsNavigation,
  demurrageNavigation,
  detentionNavigation,
  invoicesNavigation,
  payablesNavigation,
  agreementsNavigation,
  customersNavigation,
  termsNavigation,
  parametersNavigation,
  logsNavigation,
  usersNavigation,
  analyticsNavigation
];
