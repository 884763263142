<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="portForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <div class="form-group-row">
        <mat-form-field *ngIf="data.mode == 'edit'">
          <mat-label>Country Code</mat-label>
          <input matInput type="text" name="countryCode" formControlName="countryCode" required>
        </mat-form-field>
        <mat-form-field *ngIf="data.mode == 'add'">
          <mat-label>Country Code</mat-label>
          <mat-select name="countryCode" formControlName="countryCode" required>
            <mat-option>
              <ngx-mat-select-search formControlName="countryCodeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.COU_CODE">
              {{country.COU_CODE}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="portForm.get('countryCode').invalid">{{getCountryCodeErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Port Country Code</mat-label>
          <input matInput name="code" maxlength="6" formControlName="code" required>
          <mat-error *ngIf="portForm.get('code').invalid">{{getCodeErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Port Country Description</mat-label>
        <input matInput name="description" maxlength="40" formControlName="description" required>
        <mat-error *ngIf="portForm.get('description').invalid">{{getDescriptionErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Region Code</mat-label>
        <mat-select name="regions" formControlName="regions" multiple>
          <mat-option>
            <ngx-mat-select-search formControlName="regionsFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let region of filteredRegions | async" [value]="region.REG_ID">
            {{region.REG_NAME}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the port {{data.port.DHLPORTS_AIB4TX}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="areInputsValid()">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="areInputsValid()">Ok</button>
    </div>
  </form>
</div>