import { Component, Input, OnInit } from '@angular/core';
import { ITimeline } from 'src/app/models/timeline.model';

@Component({
  selector: 'dhl-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @Input() timeline: Array<ITimeline> = [];

  constructor() { }

  ngOnInit(): void { }

  timelineIcon(event: ITimeline): string {
    switch (event.TIMELINE_ACTION) {
      case 'create':
        return 'add';
      case 'insert':
        return 'add';
      case 'update':
        return 'edit';
      case 'remove':
        return 'delete';
      default:
        return '';
    }
  }

  detailIcon(event: ITimeline): string {
    switch (event.TIMELINE_FIELD) {
      case 'Attachment':
        return 'attach_file';
      case 'Comment':
        return 'chat';
    }
  }

  timelineAction(event: ITimeline): string {
    switch (event.TIMELINE_ACTION) {
      case 'insert':
        return `Inserted ${event.TIMELINE_NEW_VALUE} into ${event.TIMELINE_FIELD}`;
      case 'update':
        return `Updated ${event.TIMELINE_FIELD} from ${event.TIMELINE_OLD_VALUE} to ${event.TIMELINE_NEW_VALUE}`;
      case 'remove':
        return `Removed ${event.TIMELINE_NEW_VALUE} from ${event.TIMELINE_FIELD}`;
      default:
        return '';
    }
  }

  detailAction(event: ITimeline): string {
    switch (event.TIMELINE_FIELD) {
      case 'Attachment':
        return 'Attached a file';
      case 'Comment':
        return 'Posted a comment';
    }
  }
}
