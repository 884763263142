import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ShipmentService } from 'src/app/services/shipment.service';
import { formatDate } from '@angular/common';
import { ShipmentsFilterDialogComponent, IShipmentFilterDialog } from './shipments-dialogs/shipments-filter-dialog/shipments-filter-dialog.component';
import { ShipmentsDeleteAllDialogComponent } from './shipments-dialogs/shipments-delete-all-dialog/shipments-delete-all-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { IShipment, IShipmentList } from './../../models/shipment.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatTableFilter } from 'mat-table-filter';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { ShipmentsDeleteDialogComponent } from './shipments-dialogs/shipments-delete-dialog/shipments-delete-dialog.component';
import { FilterService } from 'src/app/services/filter.service';
import { AwsService } from 'src/app/services/aws.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { CommentService } from 'src/app/services/comment.service';
import { CommentDialogComponent, ICommentDialog } from './shipments-dialogs/comment-dialog/comment-dialog.component';
import { IComment, ICommentRef } from 'src/app/models/comment.model';
import { AttachmentDialogComponent, IAttachmentDialog } from './shipments-dialogs/attachment-dialog/attachment-dialog.component';
import { IAttachment, IAttachmentRef } from 'src/app/models/attachment.model';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { ITimeline } from 'src/app/models/timeline.model';
import { TimelineService } from 'src/app/services/timeline.service';
import { ShipmentsImportDialogComponent } from './shipments-dialogs/shipments-import-dialog/shipments-import-dialog.component';

@Component({
  selector: 'dhl-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})
export class ShipmentsComponent implements OnInit {
  readonly pageKey = 'shipments';

  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IShipment>(this.allowMultiSelect, this.initialSelection);
  
  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IShipment;
  filtersNumber: number = 0;

  shipmentTableDataSource = new MatTableDataSource<IShipment>();
  shipmentExportDataSource = new MatTableDataSource<IShipment>();
  filteredShipmentTableDataSource = new MatTableDataSource<IShipment>();
  shipmentTableDisplayedColumns: Array<string> = ['select','SHIP_DESTINY','SHIP_HBL','SHIP_CODE','SHIP_MBL','SHIP_CUS_NAME','SHIP_CAR_NAME','SHIP_VESSEL','SHIP_POL_NAME','SHIP_POD_NAME','SHIP_CONSOL_TYPE','SHIP_INC','SHIP_CTN_MODE','SHIP_DPT_CODE','SHIP_ATD','SHIP_ATA','SHIP_EXC_DESC','SHIP_EXC_DATE','SHIP_CMT_ATCH','actions'];

  @ViewChild(MatTable) shipmentTable: MatTable<IShipment>;

  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  shipmentId: number;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private shipmentService: ShipmentService,
    private filterService: FilterService,
    private awsService: AwsService,
    private authService: AuthService,
    private attachmentService: AttachmentService,
    private commentService: CommentService,
    private timelineService: TimelineService
  ) {
    this.titleService.setTitle('DHL | Shipments');
  }

  ngOnInit(): void {
    this.filterEntity = {
      SHIP_DESTINY: null,
      SHIP_CODE: null,
      SHIP_HBL: null,
      SHIP_MBL: null,
      SHIP_CUS: null,
      SHIP_CAR: null,
      SHIP_CONSOL_TYPE: null,
      SHIP_INC: null,
      SHIP_CTN_MODE: null,
      SHIP_DPT_CODE: null,
      SHIP_POL: null,
      SHIP_POD: null,
      SHIP_ATD_START_DT: null,
      SHIP_ATD_FINAL_DT: null,
      SHIP_ATA_START_DT: null,
      SHIP_ATA_FINAL_DT: null,
      SHIP_INC_START_DT: null,
      SHIP_INC_FINAL_DT: null
    }

    const filter = this.filterService.getFilter(this.pageKey);
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getShipment(this.filterEntity);
    });

    this.getShipment(this.filterEntity);
  }

  getShipment(filter: IShipment): void{
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredShipmentTableDataSource.data.length === 0) this.hideLoader = false;
    this.shipmentService.getShipment({
        ...filter, 
        SEARCH: search, 
        SORT_COL: this.sortCol,
        SORT_ORDER: this.sortOrder, 
        PAGE_NUMBER: this.pageNumber, 
        PAGE_SIZE: this.pageSize
    }).subscribe((shipments: Array<IShipment>) => {
      this.hideLoader = true;
      this.shipmentTableDataSource.data = shipments;
      this.filteredShipmentTableDataSource.data = shipments;
      this.length = shipments[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredShipmentTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.filteredShipmentTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getShipment(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getShipment(this.filterEntity);
  }

  setFilters(){
    const dialogRef = this.dialog.open(ShipmentsFilterDialogComponent, {
      data: {
       element: this.filterEntity
      }
    });

    dialogRef.afterClosed().subscribe((filters: IShipment)=>{
      if(!filters) return;

      this.filterEntity = filters
      this.filtersNumber = this.filterService.getFilterQuantity(filters);
      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('shipment', this.filterEntity);

      this.getShipment(this.filterEntity);
    });
  }

  deleteSelected(){
    const dialogRef = this.dialog.open(ShipmentsDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean)=>{
      if (willDelete){
        let shipmentsIds: Array<number> = [];
        this.selection.selected.forEach((shipment)=>{
          shipmentsIds.push(shipment.SHIP_ID);
        });

        let shipments: IShipmentList = {
          SHIP_ID: shipmentsIds,
          SHIP_USER: this.authService.userId
        }

        this.shipmentService.deleteShipments(shipments).subscribe((response) => {
          this.getShipment(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    })
  }

  deleteShipment(data: IShipment) {
    const dialogRef = this.dialog.open(ShipmentsDeleteDialogComponent, {
      data: {
        id: data.SHIP_ID
      }
    });

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {

        let shipmentsIds: Array<number> = [];

        shipmentsIds.push(+data.SHIP_ID);

        let shipments: IShipmentList = {
          SHIP_ID: shipmentsIds,
          SHIP_USER: this.authService.userId
        }

        this.shipmentService.deleteShipments(shipments).subscribe((response) => {
          this.getShipment(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  insertComment(data: ICommentDialog): void {
    const dialog = this.dialog.open(CommentDialogComponent, { data });

    dialog.afterClosed().subscribe(([shipments, comment]: [Array<IShipment>, string]) => {
      if (shipments.length === 0) {
        return;
      }

      if (comment) {
        const commentRefs: Array<ICommentRef> = [];

        shipments.forEach(shipment => {
          commentRefs.push({
            REG_ID: Number(shipment.SHIP_ID),
            COMM_REF_GROUP: 'shipment',
            COMM_REF_USER: this.authService.userId
          });
        });

        const newComment: IComment = {
          COMM_MESSAGE: comment,
          COMM_USER: this.authService.userId,
          COMM_REF: commentRefs
        }

        this.commentService.postComment(newComment).subscribe((response) => {
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  insertAttachment(data: IAttachmentDialog): void {
    const dialog = this.dialog.open(AttachmentDialogComponent, { data });

    dialog.afterClosed().subscribe(([shipments, files]: [Array<IShipment>, Array<File>]) => {
      if (shipments.length === 0 || files.length === 0) {
        return;
      }

      const attachmentRefs: Array<IAttachmentRef> = [];

      shipments.forEach(shipment => {
        attachmentRefs.push({
          REG_ID: Number(shipment.SHIP_ID)
        });
      });

      this.awsService
        .uploadFiles(files, 'shipment', attachmentRefs)
        .pipe(this.toast.observe({
          loading: 'Uploading files...',
          success: () => 'Files uploaded successfully.',
          error: (e) => `Error uploading files: ${e}`
        }))
        .subscribe((attachments) => {
          this.attachmentService.postAttachment(attachments).subscribe((response) => {
            this.toast.success(String(response));
          }, (error) => {
            this.toast.error(error.error.Message)
          });
        });
    });
  }

  export(): void {
    const headers = {
      SHIP_DESTINY: 'SERVICE',
      SHIP_HBL:'HOUSE BILL',
      SHIP_CODE:'SHIPMENT',
      SHIP_MBL:'MASTER BILL',
      SHIP_CUS_NAME: 'CUSTOMER',
      SHIP_CAR_NAME: 'CARRIER',
      SHIP_VESSEL: 'VESSEL',
      SHIP_POL_NAME: 'ORIGIN PORT',
      SHIP_POD_NAME: 'DESTINATION PORT',
      SHIP_CONSOL_TYPE: 'CONSOL TYPE',
      SHIP_INC: 'INCOTERM',
      SHIP_CTN_MODE: 'MODE',
      SHIP_DPT_CODE: 'DEPT',
      SHIP_ATD_TEXT: 'ATD',
      SHIP_ATA_TEXT: 'ATA',
      SHIP_EXC_DESC: 'EXCEPTION DESCRIPTION',
      SHIP_EXC_DATE_TEXT: 'EXCEPTION DATE'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.shipmentService.getShipment({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.shipmentExportDataSource.data = dataExport;

        const data: Array<IShipment> = this.prepareDataToExport([...this.shipmentExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `shipments_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });      
    } else {
      const data: Array<IShipment> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `shipments_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: [],
      });
    }
  }

  prepareDataToExport(data: Array<IShipment>): Array<IShipment> {
    const dataToExport: Array<IShipment> = [];

    const datesText = {
      SHIP_ATA_TEXT: '',
      SHIP_ATD_TEXT: '',
      SHIP_EXC_DATE_TEXT: ''
    }

    data.forEach((shipment) => {
      const newData = {...shipment, ...datesText};

      if(newData.SHIP_ATA !== null) {
        newData.SHIP_ATA_TEXT = moment(newData.SHIP_ATA).format('DD/MM/YYYY');
      }

      if(newData.SHIP_ATD !== null) {
        newData.SHIP_ATD_TEXT = moment(newData.SHIP_ATD).format('DD/MM/YYYY');
      }

      newData.SHIP_EXC_DATE_TEXT = newData.SHIP_EXC_DATE ? moment(newData.SHIP_EXC_DATE).format('DD/MM/YYYY') : null;

      delete newData.SHIP_ATA;
      delete newData.SHIP_ATD;
      delete newData.SHIP_ID;
      delete newData.SHIP_COU_CODE;
      delete newData.SHIP_COMM_CODE;
      delete newData.SHIP_CO_CODE;
      delete newData.SHIP_TRANS_MODE;
      delete newData.SHIP_DLV_COU;
      delete newData.SHIP_INC;
      delete newData.SHIP_PU_COU;
      delete newData.SHIP_CNEE;
      delete newData.SHIP_LOC_CUS;
      delete newData.SHIP_NTF_PT;
      delete newData.SHIP_EXP;
      delete newData.SHIP_POL;
      delete newData.SHIP_POD;
      delete newData.SHIP_ETA;
      delete newData.SHIP_ETD;
      delete newData.SHIP_REFERENCE;
      delete newData.SHIP_STATUS;
      delete newData.SHIP_USER;
      delete newData.SHIP_DUP_MBL;
      delete newData.SHIP_EXC_DATE;
      delete newData.SHIP_CMT_ATCH;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openSidebar(shipmentId: number): void {
    this.shipmentId = shipmentId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';    
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `shipment`, REG_ID: this.shipmentId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.shipmentId),
          COMM_REF_GROUP: `shipment`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'shipment', REG_ID: this.shipmentId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'shipment', [{ REG_ID: this.shipmentId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'shipment', TIMELINE_REG_ID: this.shipmentId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  import(){
    const dialog = this.dialog.open(ShipmentsImportDialogComponent);

    dialog.afterClosed().subscribe((isImport: boolean)=>{
      if(isImport) this.getShipment(this.filterEntity);
    });
  }
}