<div class="parameter-group">
    <div class="parameter-heading">
      <h3>Customer Contacts</h3>
      <div class="form-group">
        <mat-form-field class="parameter-search">
          <mat-label>Search</mat-label>
          <mat-icon matPrefix color="primary">search</mat-icon>
          <input matInput type="search" [(ngModel)]="filterCustomerContactValue" (input)="filterCustomerContactTable(filterCustomerContactValue)">
          <button type="button" mat-icon-button matSuffix (click)="filterCustomerContactValue = ''; filterCustomerContactTable(filterCustomerContactValue)" *ngIf="filterCustomerContactValue != ''">
            <mat-icon color="primary">close</mat-icon>
          </button>
        </mat-form-field>
        <button mat-stroked-button color="accent" (click)="openCustomerContactViewDialog({title: 'Add Contact', mode: 'add', customerId: data.customer.CUS_ID});">Add</button>
        <button mat-stroked-button color="accent" class="btn-export" (click)="exportCustomerContacts()" [disabled]="customerContactTableDataSource.data.length === 0">Export</button>
      </div>
    </div>
    <div class="parameter-body">
      <ng-container *ngIf="hideLoaderCustomerContact; else loader">
        <table mat-table #regionTable [dataSource]="customerContactTableDataSource" matSort #customerContactSort="matSort">
          <ng-container matColumnDef="CONTACT_NAME">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
            <td [matTooltipDisabled]="!isOverflow(element.CONTACT_NAME)" [matTooltip]="element.CONTACT_NAME" mat-cell *matCellDef="let element">{{element.CONTACT_NAME}}</td>
          </ng-container>
          <ng-container matColumnDef="CONTACT_EMAIL">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email">Email</th>
            <td [matTooltipDisabled]="!isOverflow(element.CONTACT_EMAIL)" [matTooltip]="element.CONTACT_EMAIL" mat-cell *matCellDef="let element">{{element.CONTACT_EMAIL}}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="openCustomerContactViewDialog({title: 'Edit Contact', mode: 'edit', customerId: data.customer.CUS_ID, customerContact: element})">
                <mat-icon color="primary">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openCustomerContactViewDialog({title: 'Delete Contact', mode: 'delete', customerId: data.customer.CUS_ID, customerContact: element})">
                <mat-icon color="primary">delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="customerContactTableDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: customerContactTableDisplayedColumns;"></tr>
        </table>
      </ng-container>
      <ng-template #loader>
        <!-- <dhl-spinner></dhl-spinner> -->
      </ng-template>
    </div>
    <div class="parameter-footer">
      <button mat-icon-button (click)="getCustomerContact()">
        <mat-icon color="primary">refresh</mat-icon>
      </button>
      <mat-paginator #customerContactPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of customer contact"></mat-paginator>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button type="button" color="accent" (click)="closeDialog()">Close</button>
  </div>