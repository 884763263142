<div [ngClass]="{'page-group-list': this.pageGroupsTableDataSource.data.length != 0}">
  <h1 mat-dialog-title>Groups of {{data.page.PAG_NAME}}</h1>
  <div class="actions-container" *ngIf="this.pageGroupsTableDataSource.data.length != 0">
    <mat-form-field class="page-group-search">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix color="primary">search</mat-icon>
      <input matInput type="search" [(ngModel)]="filterValue" (input)="filterPageGroupTable(filterValue)">
      <button type="button" mat-icon-button matSuffix (click)="filterValue = ''; filterPageGroupTable(filterValue)" [attr.aria-label]="'Hide password'" *ngIf="filterValue != ''">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div mat-dialog-content>
    <div class="table-container" [ngClass]="{'hidden': this.pageGroupsTableDataSource.data.length == 0}" *ngIf="hasData">
      <table #pageGroupsTable mat-table [dataSource]="pageGroupsTableDataSource" matSort #pageGroupSort="matSort">
        <ng-container matColumnDef="GRP_NAME">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
          <td mat-cell *matCellDef="let element">{{element.GRP_NAME}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="pageGroupsTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pageGroupsTableDisplayedColumns;"></tr>
      </table>
    </div>
    <p *ngIf="!hasData">Empty page group.</p>
    <div mat-dialog-actions>
      <button mat-flat-button type="button" color="accent" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>
