import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ICustomerFilterDialog {
  name: string;
  address: string;
  state: string;
}

@Component({
  selector: 'dhl-customer-filter-dialog',
  templateUrl: './customers-filter-dialog.component.html',
  styleUrls: ['./customers-filter-dialog.component.scss']
})
export class CustomerFilterDialogComponent implements OnInit {
  filterForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<CustomerFilterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICustomerFilterDialog, private formBuilder: UntypedFormBuilder) {
    this.data = data;
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      name: new UntypedFormControl(this.data.name),
      address: new UntypedFormControl(this.data.address),
      state: new UntypedFormControl(this.data.state)
    });
  }

  closeDialog(filters: ICustomerFilterDialog) {
    this.dialogRef.close(filters);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.filterForm.value;

    let filters: ICustomerFilterDialog = {
      name: formValue.name.trim(),
      address: formValue.address.trim(),
      state: formValue.state.trim()
    }

    this.closeDialog(filters);
  }

  resetFilters(): void {
    this.filterForm.setValue({
      name: '',
      address: '',
      state: ''
    });
  }
}
