<div class="dashboard-actions">
  <div class="filter">
    <mat-form-field class="month">
      <mat-label>Months</mat-label>
      <mat-select [formControl]="month" multiple>
        <mat-option *ngFor="let month of months; let index = index" [value]="index + 1">
          {{month}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="month">
      <mat-label>Customers</mat-label>
      <mat-select [formControl]="customer" multiple [disabled]="!filteredCustomers.length">
        <mat-option>
          <ngx-mat-select-search [formControl]="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let customer of filteredCustomers" [value]="customer.CUS_ID">
          {{customer.CUS_NAME}}
        </mat-option>
      </mat-select>
      <button type="button" mat-icon-button matSuffix (click)="customer.setValue(null) && getData()" *ngIf="customer.value?.length">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="action-group">
    <button mat-icon-button color="primary" matTooltip="Refresh" (click)="getData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>
<div class="dashboard">
  <div class="row">
    <div class="total-display" *ngIf="invoiceDetention$ | async as detention">
      <h2>{{detention[0]?.value | currency:'USD ':'code'}}</h2>
      <p>{{detention[0]?.name}}</p>
    </div>
    <div class="total-display" *ngIf="invoiceDemurrage$ | async as demurrage">
      <h2>{{demurrage[0]?.value | currency:'USD ':'code'}}</h2>
      <p>{{demurrage[0]?.name}}</p>
    </div>
    <div class="total-display" *ngIf="totalInvoices$ | async as total">
      <h2>{{total[0]?.value | currency:'USD ':'code'}}</h2>
      <p>{{total[0]?.name}}</p>
    </div>
    <div class="total-display" *ngIf="invoicesAverage$ | async as average">
      <h2>{{average[0]?.value | currency:'USD ':'code'}}</h2>
      <p>{{average[0]?.name}}</p>
    </div>
  </div>
  <div class="row main">
    <div class="chart-column">
      <apx-chart #containerChart
        [colors]="containerChartOptions.colors"
        [series]="containerChartOptions.series"
        [chart]="containerChartOptions.chart"
        [stroke]="containerChartOptions.stroke"
        [title]="containerChartOptions.title"
        [xaxis]="containerChartOptions.xaxis"
        [yaxis]="containerChartOptions.yaxis"
        [tooltip]="containerChartOptions.tooltip"
        [legend]="containerChartOptions.legend"
      ></apx-chart>
    </div>
    <div class="chart-column main">
      <apx-chart #topCustomersChart
        [colors]="topCustomersChartOptions.colors"
        [series]="topCustomersChartOptions.series"
        [chart]="topCustomersChartOptions.chart"
        [plotOptions]="topCustomersChartOptions.plotOptions"
        [dataLabels]="topCustomersChartOptions.dataLabels"
        [title]="topCustomersChartOptions.title"
        [xaxis]="topCustomersChartOptions.xaxis"
        [yaxis]="topCustomersChartOptions.yaxis"
        [tooltip]="topCustomersChartOptions.tooltip"
      ></apx-chart>
    </div>
  </div>
</div>
