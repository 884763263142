<h1 mat-dialog-title>Import Excel</h1>
<div mat-dialog-content class="import-financial">
  <a mat-stroked-button color="accent" [href]="templateUrl" download>
    <div class="button-wrapper">
      <mat-icon>download</mat-icon>
      <span>Download Template</span>
    </div>
  </a>
  <ngx-dropzone (change)="onSelect($event)" *ngIf="files.length === 0" [accept]="types.join()">
    <ngx-dropzone-label>Drag and drop files to import</ngx-dropzone-label>
  </ngx-dropzone>
  <div class="files-view" *ngIf="files.length !== 0">
    <div class="file" *ngFor="let file of files">
      <div class="file-details">
        <mat-icon color="primary">upload_file</mat-icon>
        <p>{{file.name}}</p>
      </div>
      <button mat-icon-button (click)="onRemove(file)">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button type="button" color="accent" (click)="close()">Cancel</button>
    <button mat-flat-button color="accent" [disabled]="files.length === 0 || containers.length === 0" (click)="import()">Import</button>
  </div>
</div>

