<h1 mat-dialog-title>Filter users</h1>
<div mat-dialog-content>
  <form [formGroup]="filterForm" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>User Name</mat-label>
        <input matInput type="text" name="name" formControlName="userName">
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('userName').setValue('')" *ngIf="filterForm.get('userName').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select name="isAdmin" formControlName="isAdmin">
          <mat-option value="N">
            Standard
          </mat-option>
          <mat-option value="Y">
            Admin
          </mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('isAdmin').setValue('')" *ngIf="filterForm.get('isAdmin').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="isActive" formControlName="isActive">
          <mat-option value="Y">
            Active
          </mat-option>
          <mat-option value="N">
            Inactive
          </mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('isActive').setValue('')" *ngIf="filterForm.get('isActive').value != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetFilters()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>