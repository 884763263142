import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dhl-pages-delete-all-dialog',
  templateUrl: './pages-delete-all-dialog.component.html',
  styleUrls: ['./pages-delete-all-dialog.component.scss']
})
export class PagesDeleteAllDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PagesDeleteAllDialogComponent>) { }

  ngOnInit() {
  }

  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}
