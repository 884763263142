<h1 mat-dialog-title>Filter Exceptions</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer" (selectionChange)="toggleField('customer', 'customerGroup')">
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('customer'); resetField('customerFilter'); toggleField('customer', 'customerGroup');" *ngIf="fieldHasValue('customer')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Customer Group</mat-label>
        <mat-select name="customerGroup" formControlName="customerGroup" (selectionChange)="toggleField('customerGroup', 'customer')">
          <mat-option>
            <ngx-mat-select-search formControlName="customerGroupFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customerGroup of filteredCustomerGroups" [value]="customerGroup.CUS_GRP_ID">{{customerGroup.CUS_GRP_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('customerGroup'); toggleField('customerGroup', 'customer');" *ngIf="fieldHasValue('customerGroup')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Start Date Range</mat-label>
        <mat-date-range-input [rangePicker]="sdRangePicker">
          <input matStartDate name="sdStartRange" formControlName="sdStartRange" placeholder="Start date">
          <input matEndDate name="sdFinalRange" formControlName="sdFinalRange" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="sdRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('sdStartRange'); resetField('sdFinalRange')" *ngIf="fieldHasValue('sdStartRange') || fieldHasValue('sdFinalRange')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #sdRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date Range</mat-label>
        <mat-date-range-input [rangePicker]="edRangePicker">
          <input matStartDate name="edStartRange" formControlName="edStartRange" placeholder="Start date">
          <input matEndDate name="edFinalRange" formControlName="edFinalRange" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="edRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('edStartRange'); resetField('edFinalRange')" *ngIf="fieldHasValue('edStartRange') || fieldHasValue('edFinalRange')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #edRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>    
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="reset()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>
