<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="regionForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput name="name" maxlength="9" formControlName="name" required>
        <mat-error *ngIf="regionForm.get('name').invalid">{{getNameErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput name="description" maxlength="200" formControlName="description" required>
        <mat-error *ngIf="regionForm.get('description').invalid">{{getDescriptionErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the region {{data.region.REG_NAME}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="regionForm.get('name').invalid || regionForm.get('description').invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="regionForm.get('name').invalid || regionForm.get('description').invalid">Ok</button>
    </div>
  </form>
</div>