<h1 mat-dialog-title>Container Handling</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Year</mat-label>
        <mat-select name="year" formControlName="year">
          <mat-option *ngFor="let year of financialYears" [value]="year.FBF_POST_PERIOD_YEAR">{{year.FBF_POST_PERIOD_YEAR}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div> 
    
    <ng-container *ngFor="let month of numberToMonthMap | keyvalue">
      <div class="form-group" *ngIf="selectedYear === actualYear ? month.key <= actualMonth : true">      
        <mat-form-field>
          <input matInput type="text" [value]="month.value" readonly>        
        </mat-form-field>
        <mat-form-field>
          <mat-label>Imp</mat-label>
          <input matInput type="number" formControlName="imp{{month.key}}">
          <mat-error *ngIf="form.get('imp' + month.key).invalid">{{getErrorMessage('imp' + month.key)}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Exp</mat-label>
          <input matInput type="number" formControlName="exp{{month.key}}">
          <mat-error *ngIf="form.get('exp' + month.key).invalid">{{getErrorMessage('exp' + month.key)}}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close()">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid">Apply</button>
    </div>
  </form>
</div>
