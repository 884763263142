<header>
  <h2>{{destiny | titlecase}} - Pay Forecast</h2>
  <div class="actions-process-container">
    <div class="search-process-container">
      <mat-form-field class="process-container-search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu xPosition="before">
        <button mat-menu-item (click)="recalculate(selection.selected)">
          <mat-icon>refresh</mat-icon>
          <span>Recalculate container(s)</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="filteredProcessPayTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader"> <!---->
    <table mat-table #processPayTable [dataSource]="filteredProcessPayTableDataSource" multiTemplateDataRows matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="PF_CUS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_CUS)" [matTooltip]="element.PF_CUS" mat-cell *matCellDef="let element">{{element.PF_CUS}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by HBL">House Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_HBL)" [matTooltip]="element.PF_HBL" mat-cell *matCellDef="let element">
          <a class="dhl-link" matTooltip="Open Shipment information" [routerLink]="['/shipment/detail', element.PF_SHIP]" target="_blank">
            {{element.PF_HBL}}
            <sup>({{element.PF_TOTAL_CTN}})</sup>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="SHIP_DPT_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Dept">Dept</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_DPT_CODE)" [matTooltip]="element.SHIP_DPT_CODE" mat-cell *matCellDef="let element">{{element.SHIP_DPT_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_CTN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container Number">Container</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_CTN)" [matTooltip]="element.PF_CTN" mat-cell *matCellDef="let element">{{element.PF_CTN}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_CTN_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container Type">Type</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_CTN_TYPE)" [matTooltip]="element.PF_CTN_TYPE" mat-cell *matCellDef="let element">{{element.PF_CTN_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_CAR)" [matTooltip]="element.PF_CAR" mat-cell *matCellDef="let element">{{element.PF_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_MBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by MBL">Master Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_MBL)" [matTooltip]="element.PF_MBL" mat-cell *matCellDef="let element">{{element.PF_MBL}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_VES">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Vessel">Vessel</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_VES)" [matTooltip]="element.PF_VES" mat-cell *matCellDef="let element">{{element.PF_VES}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_VOY">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Voyage">Voyage</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_VOY)" [matTooltip]="element.PF_VOY" mat-cell *matCellDef="let element">{{element.PF_VOY}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_POL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POL">Origin Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_POL)" [matTooltip]="element.PF_POL" mat-cell *matCellDef="let element">{{element.PF_POL}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_POD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POD">Destination Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_POD)" [matTooltip]="element.PF_POD" mat-cell *matCellDef="let element">{{element.PF_POD}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_ATD" *ngIf="destiny === 'demurrage'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
        <td mat-cell *matCellDef="let element">{{element.PF_ATD | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_ATA" *ngIf="destiny === 'demurrage'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Arrival">Arrival</th>
        <td mat-cell *matCellDef="let element">{{element.PF_ATA | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_GOU">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Empty Return/Gate Out">{{destiny === 'demurrage' ? 'Empty Return' : 'Gate Out'}}</th>
        <td mat-cell *matCellDef="let element">{{element.PF_GOU | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_GIN" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate In">Gate In</th>
        <td mat-cell *matCellDef="let element">{{element.PF_GIN | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_LDG" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
        <td mat-cell *matCellDef="let element">{{element.PF_LDG | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_RET_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by End Date">End Date</th>
        <td mat-cell *matCellDef="let element">{{element.PF_RET_DATE | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_FRT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Free Time">Free Time</th>
        <td mat-cell *matCellDef="let element">{{element.PF_FRT}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_CHARGE_DAYS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Charge Days">Charge Days</th>
        <td mat-cell *matCellDef="let element">{{element.PF_CHARGE_DAYS}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_PERDIEM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Perdiem">Perdiem</th>
        <td [matTooltipDisabled]="!isOverflow(element.PF_PERDIEM)" [matTooltip]="element.PF_PERDIEM" mat-cell *matCellDef="let element">{{element.PF_PERDIEM}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_AMT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Amount">Amount USD</th>
        <td mat-cell *matCellDef="let element">{{element.PF_AMT | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_AGREEMENT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Agreement">Agreement</th>
        <td mat-cell *matCellDef="let element">{{element.PF_AGREEMENT}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_INC_DATETIME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Inclusion Date">Inclusion Date</th>
        <td mat-cell *matCellDef="let element">{{element.PF_INC_DATETIME | date:'dd/MM/YYYY'}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_IS_PARTIAL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Partial">Is Partial</th>
        <td mat-cell *matCellDef="let element">
          <div class="exception">
            <span class="flag"
              [ngClass]="{
                'grey': element.PF_IS_PARTIAL === '0',
                'green': element.PF_IS_PARTIAL === '1'             
              }">
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Recalculate container" color="primary" (click)="recalculate(element)">
            <mat-icon>refresh</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="blank-line">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="separator-cell" mat-cell *matCellDef="let element" [attr.colspan]="processPayTableDisplayedColumns.length"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="processPayTableDisplayedColumns"></tr>
      <tr class="separator-row" mat-row *matRowDef="let row; columns: ['blank-line']; when: showSeparatorRow"></tr>
      <tr mat-row *matRowDef="let row; columns: processPayTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
