<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="countryForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput name="code" maxlength="2" formControlName="code" required>
        <mat-error *ngIf="countryForm.get('code').invalid">{{getCodeErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Region Code</mat-label>
        <mat-select name="regions" formControlName="regions" multiple>
          <mat-option>
            <ngx-mat-select-search formControlName="regionsFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let region of filteredRegions | async" [value]="region.REG_ID">
            {{region.REG_NAME}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field *ngIf="data.mode != 'delete'">
      <mat-label>Description</mat-label>
      <input matInput name="description" maxlength="50" formControlName="description" required>
      <mat-error *ngIf="countryForm.get('description').invalid">{{getDescriptionErrorMessage()}}</mat-error>
    </mat-form-field>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the country {{data.country.COU_DESC}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="countryForm.get('code').invalid || countryForm.get('description').invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="countryForm.get('code').invalid || countryForm.get('description').invalid">Ok</button>
    </div>
  </form>
</div>