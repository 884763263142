import { LogService } from 'src/app/services/log.service';
import { IRpaLog } from './../../../../models/log.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICarrier } from 'src/app/models/parameter.model';

export interface IFilterDialog {
  element?: IRpaLog;
  carrierList?: Array<ICarrier>
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  rpaList: Array<IRpaLog>;
  rpaStatus: Array<IRpaLog>;

  carriers: Array<ICarrier> = [];
  filteredCarriers: Array<ICarrier> = [];

  years: number[] = [];
  
  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog,
    private formBuilder: UntypedFormBuilder,
    private logService: LogService
  ) { }

  ngOnInit(): void {
    this.populateYears();
    this.getRpaList();
    this.getRpaStatus();

    this.carriers = this.data?.carrierList;
    this.filteredCarriers = this.data?.carrierList;

    this.form = this.formBuilder.group({    
      service: new UntypedFormControl(this.data.element?.LDR_SERVICE),      
      mbl: new UntypedFormControl(this.data.element?.LDR_MBL),       
      container: new UntypedFormControl(this.data.element?.LDR_CTN),  
      carrier: new UntypedFormControl(this.data?.element.LDR_CAR_ID),
      carrierFilter: new UntypedFormControl(''),    
      rpa: new UntypedFormControl(this.data.element?.LDR_FUN),      
      status: new UntypedFormControl(this.data.element?.LDR_STATUS_EXEC),      
      month: new UntypedFormControl(this.data.element?.LDR_MONTH),      
      year: new UntypedFormControl(this.data.element?.LDR_YEAR),      
    });

    this.form.get('carrierFilter').valueChanges.subscribe(value => this.filterCarriers(value));
  }

  filterCarriers(filter: string): void {
    this.filteredCarriers = this.data.carrierList.filter((element) => {
      return element.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase().trim()) || 
             element.RELATIPF_APA3CD.toLowerCase().includes(filter.toLowerCase().trim());
    });
  }

  getRpaList(): void{
    this.logService.getRpaList().subscribe((data)=>{
      this.rpaList = data;
    })
  }

  getRpaStatus(): void{
    this.logService.getRpaStatusList().subscribe((data)=>{
      this.rpaStatus = data;
    })
  }

  closeDialog(filters: IRpaLog) {
    this.dialogRef.close(filters);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    let filters: IRpaLog = {
      LDR_SERVICE: this.form.value.service,
      LDR_MBL: this.form.value.mbl,
      LDR_CTN: this.form.value.container,
      LDR_CAR_ID: this.form.value.carrier,
      LDR_FUN: this.form.value.rpa,
      LDR_STATUS_EXEC: this.form.value.status,
      LDR_MONTH: this.form.value.month,
      LDR_YEAR: this.form.value.year,
    }

    this.closeDialog(filters);
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue('');
  }

  resetFilters(): void {
    this.form.setValue({
      service: null,
      mbl: null,
      container: null,
      carrier: null,
      carrierFilter: '',
      rpa: null,
      status: null,
      month: null,
      year: null
    });
  }

  populateYears() {
    const currentYear = new Date().getFullYear();
    for (let year = 2022; year <= currentYear; year++) {
      this.years.push(year);
    }
  }
}
