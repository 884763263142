import { InvoicesViewDialogComponent } from './invoices-view-dialog/invoices-view-dialog.component';
import { PoExtraDialogComponent } from './po-extra-dialog/po-extra-dialog.component';
import { InsertSatDialogComponent } from './insert-sat-dialog/insert-sat-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';


@NgModule({
  declarations: [
    FilterDialogComponent,
    InsertSatDialogComponent,
    PoExtraDialogComponent,
    InvoicesViewDialogComponent,
    DeleteDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxDropzoneModule,
    ComponentsModule,
    NgxMatSelectSearchModule
  ]
})
export class PaymentOrderDialogsModule { }
