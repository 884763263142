import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { XlsxExporterService } from 'mat-table-exporter';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { FilterDialogComponent } from './notification-logs-dialogs/filter-dialog/filter-dialog.component';
import { ICustomerNotification } from 'src/app/models/customer.model';
import { MatTableFilter } from 'mat-table-filter';
import { HotToastService } from '@ngneat/hot-toast';
import { CustomerService } from 'src/app/services/customer.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-customer-notification-logs',
  templateUrl: './customer-notification-logs.component.html',
  styleUrls: ['./customer-notification-logs.component.scss']
})
export class CustomerNotificationLogsComponent implements OnInit {
  pageKey = 'notificationLogs';
  hideLoader: boolean;

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filtersNumber: number = 0;
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<ICustomerNotification>(this.allowMultiSelect, this.initialSelection);
  logFilters: ICustomerNotification = {
    CUS_ID: null,
    INVOICE_ID: null,
    NOTIFICATION_SITUATION: null
  };

  notificationLogTableDataSource = new MatTableDataSource<ICustomerNotification>();
  notificationLogExportDataSource = new MatTableDataSource<ICustomerNotification>();
  notificationLogTableDisplayedColumns: Array<string> = [
    'select',
    'CUS_NAME',
    'INVOICE_ID',
    'NOTIFICATION_CONTACTS',
    'NOTIFICATION_CREATION_DATE',
    'NOTIFICATION_DUE_DATE',
    'NOTIFICATION_SITUATION',
    'NOTIFICATION_USER'
  ];

  @ViewChild(MatTable) logTable: MatTable<ICustomerNotification>;
  @ViewChild('notificationLogSort') notificationLogSort: MatSort;
  @ViewChild('notificationLogPaginator') notificationLogPaginator: MatPaginator;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private exporter: XlsxExporterService,
    private filterService: FilterService,
    private customerService: CustomerService,
    private toast: HotToastService,
  ) {
    this.titleService.setTitle('DHL | Logs (Invoice Notification)');
  }

  ngOnInit(): void {
    const filter = this.filterService.getFilter('notificationLog');
    if (filter) {
      this.logFilters = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.getNotificationLogs(this.logFilters);

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getNotificationLogs(this.logFilters);
    });
  }

  getNotificationLogs(filter: ICustomerNotification): void {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.notificationLogTableDataSource.data.length === 0) this.hideLoader = false;
    this.customerService.getCustomerNotification({
      ...filter,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((logs: Array<ICustomerNotification>) => {
      this.hideLoader = true;
      this.notificationLogTableDataSource.data = logs;
      this.length = logs[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getNotificationLogs(this.logFilters);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getNotificationLogs(this.logFilters);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.notificationLogTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.notificationLogTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  setFilters(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.logFilters
      }
    });

    dialogRef.afterClosed().subscribe((filters: ICustomerNotification) => {
      if (!filters) return;

      console.log(filters);

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.logFilters = filters;
      this.pageNumber = 0;      
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.logFilters.CUS_ID = filters.CUS_ID;
      this.logFilters.INVOICE_ID = filters.INVOICE_ID;
      this.logFilters.NOTIFICATION_SITUATION = filters.NOTIFICATION_SITUATION;

      this.filterService.setFilter(this.pageKey, this.logFilters);

      this.getNotificationLogs(this.logFilters);
    });
  }

  export(): void {
    const headers = {
      CUS_NAME: 'CUSTOMER',
      INVOICE_ID: 'INVOICE ID',
      NOTIFICATION_CONTACTS: 'CUSTOMER CONTACTS',
      NOTIFICATION_CREATION_DATE_TEXT: 'CREATION DATE',
      NOTIFICATION_DUE_DATE_TEXT: 'DUE DATE',
      NOTIFICATION_SITUATION: 'STATUS',
      NOTIFICATION_USER: 'USER'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.customerService.getCustomerNotification({
        ...this.logFilters,
        EXPORT: 1,
        SEARCH: search, 
        SORT_COL: this.sortCol,
        SORT_ORDER: this.sortOrder
      }).subscribe((dataExport: Array<ICustomerNotification>) => {
        this.notificationLogExportDataSource.data = dataExport;

        const data: Array<ICustomerNotification> = this.prepareDataToExport([...this.notificationLogExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `notificationLogs_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref?.close();
      });      
    } else {
      const data: Array<ICustomerNotification> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `notificationLogs_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<ICustomerNotification>): Array<ICustomerNotification> {
    const dataToExport: Array<ICustomerNotification> = [];

    const fieldText = {
      NOTIFICATION_CREATION_DATE_TEXT: "",
      NOTIFICATION_DUE_DATE_TEXT: ""
    }

    data.forEach((notification) => {
      const newData = {...notification, ...fieldText};

      newData.NOTIFICATION_CREATION_DATE_TEXT = newData.NOTIFICATION_CREATION_DATE && moment(newData.NOTIFICATION_CREATION_DATE).format('DD/MM/YYYY HH:MM');
      newData.NOTIFICATION_DUE_DATE_TEXT = newData.NOTIFICATION_DUE_DATE && moment(newData.NOTIFICATION_DUE_DATE).format('DD/MM/YYYY HH:MM');

      delete newData.NOTIFICATION_ID;
      delete newData.CUS_ID;
      delete newData.CUS_CODE;
      delete newData.NOTIFICATION_INVOICE_URL;
      delete newData.NOTIFICATION_STATUS;
      delete newData.NOTIFICATION_CREATION_DATE;
      delete newData.NOTIFICATION_DUE_DATE;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

}
