import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { IFinancial, IFinancialImport } from 'src/app/models/financial.model';
import { ExcelService } from 'src/app/services/excel.service';
import { FinancialService } from 'src/app/services/financial.service';

@Component({
  selector: 'dhl-import-financial-dialog',
  templateUrl: './import-financial-dialog.component.html',
  styleUrls: ['./import-financial-dialog.component.scss']
})
export class ImportFinancialDialogComponent implements OnInit {
  files: Array<File> = [];
  financials: Array<IFinancial> = [];
  isImport: boolean = false;

  types: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  ];

  constructor(
    private dialogRef: MatDialogRef<ImportFinancialDialogComponent>,
    private excelService: ExcelService,
    private financialService: FinancialService,
    private toast: HotToastService,
  ) { }

  ngOnInit() {
  }

  onSelect(e: NgxDropzoneChangeEvent): void {
    this.financials = [];
    this.files.push(...e.addedFiles);

    const file = this.files[0];

    this.excelService.importFromExcel(file)
      .pipe(this.toast.observe({
        loading: 'Uploading file...',        
        success: () => 'File uploaded successfully.',
        error: (e) => `Error uploading file: ${e}`
      }))
      .subscribe((response: IFinancialImport[]) => {        
        response.map((excel) =>{
          let financial: IFinancial = {            
            FBF_JOB_NUM: excel['JOB NUM'],
            FBF_SALES_GROUP: excel['SALES GROUP'],
            FBF_CHARGE_GROUP: excel['CHARGE GROUP'],
            FBF_CHARGE_CODE: excel['CHARGE CODE'],
            FBF_CHARGE_CODE_DESC: excel['CHARGE CODE & DESC'],
            FBF_TRN_BRANCH: excel['TRN BRANCH'],
            FBF_TRN_DEPT: excel['TRN DEPT'],
            FBF_TRN_DESC: excel['TRN DESC'],
            FBF_LINE_TYPE: excel['LINE TYPE'],
            FBF_TRN_RECOG_DT: Number(excel['TRN RECOG DATE']) ? new Date(Date.UTC(0, 0, Number(excel['TRN RECOG DATE']))) : null,
            FBF_INVOICE_NUMBER: excel['INVOICE NUMBER'],
            FBF_INVOICE_DATE: Number(excel['INVOICE DATE']) ? new Date(Date.UTC(0, 0, Number(excel['INVOICE DATE']))) : null,
            FBF_LOCAL_AMOUNT: Number(excel['LOCAL AMOUNT']) || null,
            FBF_APPORTIONED: excel['IS APPORTIONED (Y/N)'],
            FBF_MOST_RELEV_CONSOL: excel['MOST RELEVANT CONSOL'],
            FBF_LEDGER_TYPE: excel['LEDGER TYPE'],
            FBF_TRN_TYPE: excel['TRN TYPE'],
            FBF_TRN_ORG: excel['TRN ORG'],
            FBF_TRN_ORG_NAME: excel['TRN ORG NAME'],
            FBF_POSTING_DT: Number(excel['POSTING DATE']) ? new Date(Date.UTC(0, 0, Number(excel['POSTING DATE']))) : null,
            FBF_ACCT_GRP_CD: excel['ACCT GRP CD'],
            FBF_JOB_RECOG_MTH_YYYYMM: excel['JOB RECOG MONTH (YYYY/MM)'],
            FBF_TRN_RECOG_MTH_YYYYMM: excel['TRN RECOG MONTH (YYYY/MM)'],
            FBF_OUT_OF_PERIOD: excel['OUT OF PERIOD'],
            FBF_IS_AUTO_RATED: excel['IS AUTO RATED'],
            FBF_RATING_OVERRIDDEN: excel['RATING OVERRIDDEN'],
            FBF_CFX_PERCENT_TYPE: excel['CFX PERCENT TYPE'],
            FBF_CREATED_USER: excel['CREATED USED'],
            FBF_JOB_STATUS: excel['JOB STATUS'],
            FBF_JOB_BRANCH: excel['JOB BRANCH'],
            FBF_JOB_DEPT: excel['JOB DEPT'],
            FBF_JOB_OPEN_DT: Number(excel['JOB OPEN DATE']) ? new Date(Date.UTC(0, 0, Number(excel['JOB OPEN DATE']))) : null,
            FBF_JOB_RECOG_DT: Number(excel['JOB RECOG DATE']) || null,
            FBF_JOB_CLOSED_DT: Number(excel['JOB CLOSE DATE']) ? new Date(Date.UTC(0, 0, Number(excel['JOB CLOSE DATE']))) : null,
            FBF_JOB_SL_REP: excel['JOB SALES REP'],
            FBF_JOB_NUM_COMPANY: excel['JOB NUMBER COMPANY'],
            FBF_TRN_INCOME: Number(excel['TRN INCOME']) || null,
            FBF_TRN_PROFIT: Number(excel['TRN PROFIT']) || null,
            FBF_TRN_EXPENSE: excel['TRN EXPENSE'],
            FBF_INCOME_EUR: Number(excel['INCOME EUR']) || null,
            FBF_TRN_PROFIT_EUR: Number(excel['TRN PROFIT EUR']) || null,
            FBF_EXPENSE_EUR: Number(excel['EXPENSE EUR']) || null,
            FBF_OS_VAT: Number(excel['OS VAT']) || null,
            FBF_TRANSPORT_MODE: excel['TRANSPORT MODE'],
            FBF_OS_AMOUNT: Number(excel['OS AMOUNT']) || null,
            FBF_EXCHANGE_RATE: Number(excel['EXCHANGE RATE']) || null,
            FBF_OS_CCY: excel['OS CCY'],
            FBF_LOCAL_CLIENT: excel['LOCAL CLIENT'],
            FBF_SHIP_SUPPLIER: excel['SHIPPER SUPPLIER'],
            FBF_CONSIGNEE_IMPORTER: excel['CONSIGNEE IMPORTER'],
            FBF_HAWB: excel['HAWB'],
            FBF_MAWB: excel['MAWB'],
            FBF_ORIGIN_ETD: Number(excel['ORIGIN ETD']) || null,
            FBF_DESTINATION_ETA: Number(excel['DESTINATION ETA']) || null,
            FBF_SUMARY_DESC: excel['SUMMARY DESC'],
            FBF_DEBTOR_CODE: excel['DEBTOR CODE'],
            FBF_POST_PERIOD: Number(excel['POSTING PERIOD (MONTH)']) || null,
            FBF_POST_PERIOD_YEAR: Number(excel['POSTING PERIOD (YEAR)']) || null,
            FBF_CLIENT_NAME: excel['CLIENT NAME']
          };

          if(this.willImport(financial)) this.financials.push(financial);          
        });
      });
  }

  willImport(financial: IFinancial): boolean{
    const year: number = new Date().getFullYear();
    const lastYear: number = new Date().getFullYear()-1;
    const month: number = new Date().getMonth()+1;
    const lastMonth: number = ((new Date().getMonth()+1) === 1) ? 12 : month-1;

    if((financial.FBF_POST_PERIOD === month || financial.FBF_POST_PERIOD === lastMonth) &&
       (financial.FBF_POST_PERIOD_YEAR === year || financial.FBF_POST_PERIOD_YEAR === lastYear)){
        return true;
    }else{
      return false;
    }    
  }  

  import(): void{
    this.isImport = true;
    const ref = this.toast.loading('Saving...',{autoClose: false});
    this.financialService.post(this.financials)
      .subscribe((response)=>{
        ref.close();
        this.toast.success(String(response));
        this.close();
      },(error) =>{
        if(error.status === 0){
          ref.close();
          this.toast.success('Financial(s) imported successfully.');
          this.close();
        }else{
          ref.close();
          this.toast.error(error.error.Message);
        }
      });    
  }

  onRemove(file: File): void {
    this.files.splice(this.files.indexOf(file), 1);
  }

  close(): void {
    this.dialogRef.close(this.isImport);
  }
}
