<header>
  <h2>Log - RPA</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="setFilters()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="rpaLogTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <div class="row">
    <div class="total-display gray" *ngIf="rpaIndicatorsTableDataSource.data[0]?.LDR_CONS !== null">
      <h2>{{rpaIndicatorsTableDataSource.data[0]?.LDR_CONS}}</h2>
      <p>Total queries</p>
    </div>
    <div class="total-display green" *ngIf="rpaIndicatorsTableDataSource.data[0]?.LDR_CONS_OK !== null">
      <h2>{{rpaIndicatorsTableDataSource.data[0]?.LDR_CONS_OK}}</h2>
      <p>OK queries</p>
    </div>
    <div class="total-display red" *ngIf="rpaIndicatorsTableDataSource.data[0]?.LDR_CONS_ERR !== null">
      <h2>{{rpaIndicatorsTableDataSource.data[0]?.LDR_CONS_ERR}}</h2>
      <p>Queries with errors</p>
    </div>
    <div class="total-display yellow" *ngIf="rpaIndicatorsTableDataSource.data[0]?.LDR_CONS_PEND !== null">
      <h2>{{rpaIndicatorsTableDataSource.data[0]?.LDR_CONS_PEND}}</h2>
      <p>Pending queries</p>
    </div>
  </div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #logTable [dataSource]="rpaLogTableDataSource" matSort #rpaLogSort="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="LDR_LOG_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
        <td mat-cell *matCellDef="let element">{{element.LDR_LOG_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="LDR_SERVICE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Service">Service</th>
        <td [matTooltipDisabled]="!isOverflow(element.LDR_SERVICE)" [matTooltip]="element.LDR_SERVICE" mat-cell *matCellDef="let element">{{element.LDR_SERVICE}}</td>
      </ng-container>
      <ng-container matColumnDef="LDR_MBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by MBL">MBL</th>
        <td [matTooltipDisabled]="!isOverflow(element.LDR_MBL)" [matTooltip]="element.LDR_MBL" mat-cell *matCellDef="let element">{{element.LDR_MBL}}</td>
      </ng-container>
      <ng-container matColumnDef="LDR_CTN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container">Container</th>
        <td mat-cell *matCellDef="let element">{{element.LDR_CTN}}</td>
      </ng-container>
      <ng-container matColumnDef="LDR_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.LDR_CAR)" [matTooltip]="element.LDR_CAR" mat-cell *matCellDef="let element">{{element.LDR_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="LDR_FUN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Rpa Fun">RPA</th>
        <td [matTooltipDisabled]="!isOverflow(element.LDR_FUN)" [matTooltip]="element.LDR_FUN" mat-cell *matCellDef="let element">{{element.LDR_FUN}}</td>
      </ng-container>      
      <ng-container matColumnDef="LDR_STATUS_EXEC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Status</th>
        <td [matTooltipDisabled]="!isOverflow(element.LDR_STATUS_EXEC)" [matTooltip]="element.LDR_STATUS_EXEC" mat-cell *matCellDef="let element">{{element.LDR_STATUS_EXEC}}</td>
      </ng-container>
      <ng-container matColumnDef="LDR_EXC_DATETIME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Execution Date">Execution Date</th>
        <td mat-cell *matCellDef="let element">{{element.LDR_EXC_DATETIME | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="json">
        <th mat-header-cell *matHeaderCellDef>Json</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="openJsonViewDialog(element)">View Json</button>      
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="rpaLogTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: rpaLogTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator #rpaLogPaginator [pageSizeOptions]="[5, 10, 15, 20]" pageSize="20" showFirstLastButtons aria-label="Select page of RPA Logs"></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
