import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICustomerContact } from 'src/app/models/customer.model';
import { AuthService } from 'src/app/services/auth.service';

export interface ICustomerContactViewDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  customerId?: number;
  customerContact?: ICustomerContact;
}

@Component({
  selector: 'dhl-customer-contact-view-dialog',
  templateUrl: './customer-contact-view-dialog.component.html',
  styleUrls: ['./customer-contact-view-dialog.component.scss']
})
export class CustomerContactViewDialogComponent implements OnInit {
  customerContactForm: UntypedFormGroup;
  isFormEnabled: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomerContactViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICustomerContactViewDialog,    
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.customerContactForm = this.formBuilder.group({
      name: new UntypedFormControl(this.data.customerContact?.CONTACT_NAME, [Validators.required]),
      email: new UntypedFormControl(this.data.customerContact?.CONTACT_EMAIL, [Validators.required, Validators.email])
    });

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.customerContactForm.disable();
    }
  }

  closeDialog(customerContact: ICustomerContact) {
    this.dialogRef.close(customerContact);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.customerContactForm.get('name').enable();
    this.customerContactForm.get('email').enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.customerContactForm.value;
    let customerContact: ICustomerContact = null;

    if (this.data.mode == 'add') {
      customerContact = {
        CUS_ID: this.data.customerId,
        CONTACT_NAME: formValue.name,
        CONTACT_EMAIL: formValue.email,
        CONTACT_USER: this.authService.userId
      }
    } else if (this.data.mode == 'edit') {
      customerContact = {
        CONTACT_ID: this.data.customerContact.CONTACT_ID,
        CUS_ID: this.data.customerId,
        CONTACT_NAME: formValue.name,
        CONTACT_EMAIL: formValue.email,
        CONTACT_USER: this.authService.userId
      }
    } else if (this.data.mode == 'delete') {
      customerContact = {
        CONTACT_ID: this.data.customerContact.CONTACT_ID,
        CONTACT_USER: this.authService.userId
      }
    }

    this.closeDialog(customerContact);
  }

  areInputsValid(): boolean {
    const isNameInvalid = this.customerContactForm.get('name').invalid;
    const isEmailInvalid = this.customerContactForm.get('email').invalid;
    return isNameInvalid || isEmailInvalid;
  }
}
