import { IAgreement } from 'src/app/models/agreement.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IAttachmentDialog {
  element?: IAgreement | Array<IAgreement>;
}

@Component({
  selector: 'dhl-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss']
})
export class AttachmentDialogComponent implements OnInit {
  files: Array<File> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAttachmentDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AttachmentDialogComponent>,
  ) { }

  ngOnInit(): void {    
  }

  close(value: [Array<IAgreement>, Array<File>]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newAgreement: Array<IAgreement> = [];

    if (this.data.element instanceof Array) {
      newAgreement = this.data.element.map((element) => {
        return {
          DIM_SK_CUS_RUL: element.DIM_SK_CUS_RUL,
          DIM_RUL_USER: this.authService.userId
        }
      });
    } else {
      newAgreement.push({
        DIM_SK_CUS_RUL: this.data.element.DIM_SK_CUS_RUL,
        DIM_RUL_USER: this.authService.userId
      });
    }

    this.close([newAgreement, this.files]);
  }

  onUpload(files: Array<File>) {
    files.forEach(file => this.files.push(file));
  }
}
