export interface IShipmentList {
  SHIP_ID: Array<number>;
  SHIP_USER?: string;
}

export interface IShipment {
  SHIP_ID?: number;
  SHIP_HBL?: string;
  SHIP_CODE?: string;
  SHIP_MBL?: string;
  SHIP_DUP_MBL?: string;
  SHIP_REFERENCE?: string;
  SHIP_CO_CODE?: string;
  SHIP_COU_CODE?: number;
  SHIP_COMM_CODE?: string;
  SHIP_CTN_MODE?: string;
  SHIP_DPT_CODE?: string;
  SHIP_DLV_COU?: number;
  SHIP_INC?: string;
  SHIP_PU_COU?: number;
  SHIP_TRANS_MODE?: string;
  SHIP_ETA?: Date;
  SHIP_ETD?: Date;
  SHIP_ATA?: Date;
  SHIP_ATD?: Date;
  SHIP_CNEE?: number;
  SHIP_LOC_CUS?: number;
  SHIP_NTF_PT?: number;
  SHIP_EXP?: number;
  SHIP_POL?: string;
  SHIP_POD?: string;
  SHIP_DESTINY?: string;
  SHIP_STATUS?: 0 | 1;
  SHIP_USER?: string;
  SHIP_VESSEL?: string;
  SHIP_CONSOL_TYPE?: string;
  SHIP_EXC_DESC?: Date;
  SHIP_EXC_DATE?: Date;
  SHIP_CMT_ATCH?: number;
  SHIP_CUS?: string;
  SHIP_CAR?: string;
  SHIP_ATD_START_DT?: Date;
  SHIP_ATD_FINAL_DT?: Date;
  SHIP_ATA_START_DT?: Date;
  SHIP_ATA_FINAL_DT?: Date;
  SHIP_INC_START_DT?: Date;
  SHIP_INC_FINAL_DT?: Date;
  SHIP_CTN_COUNT?: number;
  DRT_HBL?: string;
  DRT_STATUS?: string;
  DRT_ACTIVE?: boolean;
  DRT_USER?: string;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface IException {
  EXC_ID?:number;
  SHIP_ID?:number;
  EXC_DESC?:string;
  EXC_CODE?:string;
  EXC_STAFF_CODE?:string;
  EXC_INC_DATETIME?:string;
  EXC_STATUS?: 0 | 1;
  EXC_USER?:string;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface IMasterbill {
  MBL_ID?: number;
  SHIP_ID?: number;
  MBL_NUMBER?: string;
  MBL_CONSOL_ID?: string;
  MBL_CONSOL_TYPE?: string;
  MBL_VES_ARR_DATE?: Date;
  MBL_VES_DEP_DATE?: Date;
  MBL_VES_NAME?: string;
  MBL_VOY_FLT_NUMBER?: string;
  MBL_CAR?: number;
  MBL_STATUS?: 0 | 1;
  MBL_USER?: string;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface IContainer {
  CTN_ID?: number;
  PRTL_CTN_ID?: number;
  SHIP_ID?: number;
  CTN_NUMBER?: string;
  CTN_HBL?: string;
  SHIP_DPT_CODE?: string;
  CTN_MBL?: string;
  CTN_TYPE?: number;
  CTN_CUSTOMER?: string;
  CTN_POL?: string;
  CTN_POD?: string;
  CTN_ATA?: Date;
  CTN_ATD?: Date;
  CTN_GOU?: Date;
  CTN_END_REFERENCE?: Date;
  CTN_GIN?: Date;
  CTN_LDG?: Date;
  CTN_FRT?: number;
  CTN_RUL?: number;
  CTN_STATUS?: 0 | 1;
  CTN_USER?: string;
  CTN_DESTINY?: 0 | 1;
  CTN_PROCESS_STATUS?: number;
  CTN_PF_STATUS?: number;
  SHIP_ATD?: Date;
  CTN_CAR?: string;
  CTN_EDIT?: boolean;
  INVOICE_STATUS?: number;
  CTN_STATUS_LIST?: string;
  CTN_DUP_MBL?: number;
  CTN_ONHOLD?: number;
  CTN_ONHOLD_CMT?: string;
  CTN_ATA_START_DT?: Date;
  CTN_ATA_FINAL_DT?: Date;
  CTN_GOU_START_DT?: Date;
  CTN_GOU_FINAL_DT?: Date;
  CTN_GIN_START_DT?: Date;
  CTN_GIN_FINAL_DT?: Date;
  CTN_LDG_START_DT?: Date;
  CTN_LDG_FINAL_DT?: Date;
  CTN_INC_START_DT?: Date;
  CTN_INC_FINAL_DT?: Date;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
  CTN_IS_PARTIAL?: 0 | 1;
  START_DATE?: Date;
}
