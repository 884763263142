<h1 mat-dialog-title>Filter RPA Logs</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Service</mat-label>
        <mat-select name="service" formControlName="service">
          <mat-option value="DEMURRAGE">Demurrage</mat-option>
          <mat-option value="DETENTION">Detention</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('service')" *ngIf="fieldHasValue('service')">
          <mat-icon color="primary">close</mat-icon>    
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>MBL</mat-label>
        <input matInput type="text" name="mbl" formControlName="mbl">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('mbl')" (click)="resetField('mbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Container</mat-label>
        <input matInput type="text" name="container" formControlName="container">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('container')" (click)="resetField('container')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('carrier')" (click)="resetField('carrier')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>RPA</mat-label>
        <mat-select name="rpa" formControlName="rpa">
          <mat-option *ngFor="let rpa of rpaList" [value]="rpa.LDR_FUN">{{rpa.LDR_FUN}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('rpa')" *ngIf="fieldHasValue('rpa')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status">
          <mat-option *ngFor="let status of rpaStatus" [value]="status.LDR_STATUS_EXEC">{{status.LDR_STATUS_EXEC}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('status')" *ngIf="fieldHasValue('status')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Month</mat-label>
        <mat-select name="month" formControlName="month">
          <mat-option value="1">January</mat-option>
          <mat-option value="2">February</mat-option>
          <mat-option value="3">March</mat-option>
          <mat-option value="4">April</mat-option>
          <mat-option value="5">May</mat-option>
          <mat-option value="6">June</mat-option>
          <mat-option value="7">July</mat-option>
          <mat-option value="8">August</mat-option>
          <mat-option value="9">September</mat-option>
          <mat-option value="10">October</mat-option>
          <mat-option value="11">November</mat-option>
          <mat-option value="12">December</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('month')" *ngIf="fieldHasValue('month')">
          <mat-icon color="primary">close</mat-icon>    
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Year</mat-label>
        <mat-select name="year" formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('year')" *ngIf="fieldHasValue('year')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetFilters()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>