import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IContainer } from 'src/app/models/shipment.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IOnHoldDialog {
  element?: IContainer | Array<IContainer>;
}

@Component({
  selector: 'dhl-onhold-dialog',
  templateUrl: './onhold-dialog.component.html',
  styleUrls: ['./onhold-dialog.component.scss']
})
export class OnholdDialogComponent implements OnInit {
  comment: string = '';
  form: UntypedFormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IOnHoldDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<OnholdDialogComponent>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      onhold: new UntypedFormControl()
    });
  }

  close(value: [Array<IContainer>, string]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newContainer: Array<IContainer> = [];

    if (this.data.element instanceof Array) {      
      newContainer = this.data.element.map((element) => {
        if(this.verifyStatus(element)){
          return {
            CTN_ID: element.CTN_ID,
            SHIP_ID: element.SHIP_ID,
            CTN_ONHOLD: this.form.value.onhold,
            CTN_ONHOLD_CMT: this.comment,
            CTN_USER: this.authService.userId
          }
        }
      });
    } else {
      if(this.verifyStatus(this.data.element)){
        newContainer.push({
          CTN_ID: this.data.element.CTN_ID,
          SHIP_ID: this.data.element.SHIP_ID,
          CTN_ONHOLD: this.form.value.onhold,
          CTN_ONHOLD_CMT: this.comment,
          CTN_USER: this.authService.userId
        });
      }
    }

    this.close([newContainer, this.comment]);
  }

  verifyStatus(data: IContainer): boolean{
    let valid: boolean = false;
    const validStatus = [0,1,2,3,4,6,7,8];
    validStatus.forEach((value)=>{
      if(data.CTN_PROCESS_STATUS == value){
        valid = true;
      }
    });

    return valid;
  }

  onComment(comment: string) {
    this.comment = comment;
  }

  showComment(): boolean{
    return this.form.get('onhold').value == 1 ? true : false;
  }

}