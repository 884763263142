import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { ICustomer } from 'src/app/models/customer.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';

export interface ICustomerGroupDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  customerGroup?: ICustomerGroup;
}

@Component({
  selector: 'dhl-customer-groups-dialog',
  templateUrl: './customer-groups-dialog.component.html',
  styleUrls: ['./customer-groups-dialog.component.scss']
})
export class CustomerGroupsDialogComponent implements OnInit {
  isFormEnabled: boolean = true;
  customerGroupForm: UntypedFormGroup;
  customers: Array<ICustomer>;

  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CustomerGroupsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICustomerGroupDialog, private customerService: CustomerService, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit() {    
    this.customerGroupForm = this.formBuilder.group({
      name: new UntypedFormControl(this.data.customerGroup?.CUS_GRP_NAME, [Validators.required]),
      customers: new UntypedFormControl((this.data.customerGroup?.CUS_LIST) ? (this.data.customerGroup.CUS_LIST.toString()).split(',').map(Number) : null),
      customersFilter: new UntypedFormControl('')
    });

    if(this.data.mode == 'edit'){
      this.isFormEnabled = false;
      this.customerGroupForm.disable();
    }

    this.getCustomers({ CUS_FILTER: this.customerGroupForm.value.customers?.join('|'), IS_SELECT: 1 });

    this.customerGroupForm.get('customersFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));
  }

  getCustomers(filter: ICustomer): void {
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  filterCustomers(filter: string): void {
    const final = filter ? `${filter}|${this.customerGroupForm.value.customers?.join('|')}` : this.customerGroupForm.value.customers ? `${this.customerGroupForm.value.customers?.join('|')}` : '';
    this.customerService.getCustomer({ CUS_FILTER: final ? final : null, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  closeDialog(customerGroup: ICustomerGroup){
    this.dialogRef.close(customerGroup);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.customerGroupForm.get('name').enable();
    this.customerGroupForm.get('customers').enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.customerGroupForm.value;
    let newCustomerGroup: ICustomerGroup;

    if(this.data.mode == 'add'){
      newCustomerGroup = {
        CUS_GRP_NAME: formValue.name,
        CUS_GRP_USER: this.authService.userId,
        CUS_GRP_STATUS: 1,
        CUS_LIST: formValue.customers
      }
    } else if(this.data.mode == 'edit'){
      newCustomerGroup = {
        CUS_GRP_ID: this.data.customerGroup.CUS_GRP_ID,
        CUS_GRP_NAME: formValue.name,
        CUS_GRP_USER: this.authService.userId,
        CUS_GRP_STATUS: 1,
        CUS_LIST: formValue.customers
      }
    } else if(this.data.mode == 'delete'){
      newCustomerGroup = {
        CUS_GRP_ID: this.data.customerGroup.CUS_GRP_ID,
        CUS_GRP_NAME: null,
        CUS_GRP_USER: this.authService.userId,
        CUS_GRP_STATUS: 0,
        CUS_LIST: null
      }
    }

    this.closeDialog(newCustomerGroup);
  }

  fieldHasValue(formField: string): boolean {
    return this.customerGroupForm.get(formField).value;
  }

  resetField(formField: string): void {
    this.customerGroupForm.get(formField).setValue(null);
  }

  areInputsValid(): boolean {
    const isNameInvalid = this.customerGroupForm.get('name').invalid;
    return isNameInvalid;
  }
}