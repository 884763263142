<h1 mat-dialog-title>Filter Payment Order</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">            
      <mat-form-field>
        <mat-label>DDM Payment Order</mat-label>
        <input matInput type="text" formControlName="id">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('id')" (click)="resetField('id')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field> 
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select name="type" formControlName="type">
          <mat-option value="0">Original</mat-option>
          <mat-option value="1">Extra</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('type')" (click)="resetField('type')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>           
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('carrier')" (click)="resetField('carrier')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">            
      <mat-form-field>
        <mat-label>SAT</mat-label>
        <input matInput type="text" formControlName="sat">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('sat')" (click)="resetField('sat')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>    
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status">
          <mat-option value="0">Waiting for SAT</mat-option>
          <mat-option value="1">Closed</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('status')" (click)="resetField('status')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>  
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Payment Date Range</mat-label>
        <mat-date-range-input [rangePicker]="payRangePicker">
          <input matStartDate name="payStart" formControlName="payStart" placeholder="Start date">
          <input matEndDate name="payFinal" formControlName="payFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="payRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="form.get('payStart').setValue(null); form.get('payFinal').setValue(null)" *ngIf="form.get('payStart').value !== null || form.get('payFinal').value !== null">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #payRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Inclusion Date Range</mat-label>
        <mat-date-range-input [rangePicker]="incRangePicker">
          <input matStartDate name="incStart" formControlName="incStart" placeholder="Start date">
          <input matEndDate name="incFinal" formControlName="incFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="incRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="form.get('incStart').setValue(null); form.get('incFinal').setValue(null)" *ngIf="form.get('incStart').value !== null || form.get('incFinal').value !== null">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #incRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="close(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetForm()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>
