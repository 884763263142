import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAgreement } from 'src/app/models/agreement.model';
import { ICarrier } from 'src/app/models/parameter.model';

export interface IDefaultRuleFilterDialog {
  element: IAgreement;
  defaultRuleType: string;
  carrierList: Array<ICarrier>;
}

@Component({
  selector: 'dhl-default-rule-filter-dialog',
  templateUrl: './default-rule-filter-dialog.component.html',
  styleUrls: ['./default-rule-filter-dialog.component.scss']
})
export class DefaultRuleFilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  defaultRuleType: string;
  carriers: Array<ICarrier> = [];
  filteredCarriers: Array<ICarrier> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DefaultRuleFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDefaultRuleFilterDialog
  ) { }

  ngOnInit(): void {  
    this.defaultRuleType = this.data.defaultRuleType;
    this.carriers = this.data.carrierList;
    this.filteredCarriers = this.data.carrierList;

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.data.element.DIM_SK_CUS_RUL),
      name: new UntypedFormControl(this.data.element.DIM_RUL_DEF_NAM),
      service: new UntypedFormControl(this.data.element.DIM_SERVICE),
      carrier: new UntypedFormControl(this.data.element.DIM_CAR),
      carrierFilter: new UntypedFormControl(''),
      status: new UntypedFormControl(this.data.element.DIM_STATUS),
      valStart: new UntypedFormControl(this.data.element.DIM_VAL_START_DT),
      valFinal: new UntypedFormControl(this.data.element.DIM_VAL_FINAL_DT),
      incStart: new UntypedFormControl(this.data.element.DIM_INC_START_DT),
      incFinal: new UntypedFormControl(this.data.element.DIM_INC_FINAL_DT)
    });

    this.form.get('carrierFilter').valueChanges.subscribe(value => this.filterCarriers(value));
  }

  filterCarriers(filter: string): void {
    this.filteredCarriers = this.data.carrierList.filter((element) => {
      return element.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase().trim()) || 
             element.RELATIPF_APA3CD.toLowerCase().includes(filter.toLowerCase().trim());
    });
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  closeDialog(filter: IAgreement): void {
   this.dialogRef.close(filter);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    const agreement: IAgreement = { 
      DIM_SK_CUS_RUL: this.form.value.id,     
      DIM_RUL_DEF_NAM: this.form.value.name,
      DIM_STATUS: this.form.value.status,
      DIM_CAR: this.form.value.carrier,
      DIM_SERVICE: this.form.value.service,
      DIM_VAL_START_DT: this.form.value.valStart,
      DIM_VAL_FINAL_DT: this.form.value.valFinal,
      DIM_INC_START_DT: this.form.value.incStart,
      DIM_INC_FINAL_DT: this.form.value.incFinal
    };

    this.closeDialog(agreement);
  }

  reset(): void {
    this.form.setValue({
      id: null,
      name: null,
      service: null,
      carrier: null,
      carrierFilter: '',
      status: null,
      valStart: null,
      valFinal: null,
      incStart: null,
      incFinal: null
    });
  }
}
