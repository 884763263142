import { TitleCasePipe, Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Subject } from 'rxjs';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';
import { IComment } from 'src/app/models/comment.model';
import { ICustomer } from 'src/app/models/customer.model';
import { ICountry, IPort } from 'src/app/models/parameter.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { IAttachment } from 'src/app/models/attachment.model';
import { IContainer, IException, IMasterbill, IShipment } from 'src/app/models/shipment.model';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AwsService } from 'src/app/services/aws.service';
import { CommentService } from 'src/app/services/comment.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { ContainersDialogComponent } from './shipment-dialogs/containers-dialog/containers-dialog.component';
import { ExceptionsDialogComponent } from './shipment-dialogs/exceptions-dialog/exceptions-dialog.component';
import { MasterbillDialogComponent } from './shipment-dialogs/masterbill-dialog/masterbill-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss']
})
export class ShipmentComponent implements OnInit {
  isShipmentInformationOpen: boolean = true;
  isExceptionsOpen: boolean = false;
  isMasterbillsOpen: boolean = false;
  isContainersOpen: boolean = false;

  shipmentForm: UntypedFormGroup;
  exceptionForm: UntypedFormGroup;
  masterBillForm: UntypedFormGroup;
  containerForm: UntypedFormGroup;

  shipmentId: number;
  shipment: IShipment;
  exceptions: Array<IException>;
  masterBills: Array<IMasterbill>;
  containers: Array<IContainer>;
  destiny: string;

  customers: Array<ICustomer>;
  consignees: Array<ICustomer>;
  shippers: Array<ICustomer>;
  notifies: Array<ICustomer>;
  filteredCustomers: Subject<Array<ICustomer>> = new Subject<Array<ICustomer>>();

  ports: Array<IPort>;
  filteredPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();

  countries: Array<ICountry>;
  filteredCountries: Subject<Array<ICountry>> = new Subject<Array<ICountry>>();

  exceptionsTableDataSource = new MatTableDataSource<IException>();
  exceptionsTableDisplayedColumns: Array<string> = ['EXC_DESC', 'EXC_CODE', 'EXC_STAFF_CODE', 'EXC_INC_DATETIME', 'actions']

  @ViewChild(MatTable) exceptionsTable: MatTable<IException>;
  @ViewChild('exceptionsSort') exceptionsSort: MatSort;
  @ViewChild('exceptionsPaginator') exceptionsPaginator: MatPaginator;

  masterbillTableDataSource = new MatTableDataSource<IMasterbill>();
  masterbillTableDisplayedColumns: Array<string> = ['MBL_NUMBER', 'MBL_CONSOL_ID', 'MBL_CONSOL_TYPE', 'MBL_VES_NAME', 'MBL_VES_ARR_DATE', 'MBL_VES_DEP_DATE', 'MBL_VOY_FLT_NUMBER', 'MBL_CAR', 'actions'];

  @ViewChild(MatTable) masterbillTable: MatTable<IException>;
  @ViewChild('masterbillSort') masterbillSort: MatSort;
  @ViewChild('masterbillPaginator') masterbillPaginator: MatPaginator;

  containersTableDataSource = new MatTableDataSource<IMasterbill>();
  containersTableDisplayedColumns: Array<string> = ['CTN_NUMBER', 'CTN_TYPE', 'CTN_ATA', 'CTN_GOU', 'CTN_GIN', 'CTN_LDG','CTN_PROCESS_STATUS','actions'];

  @ViewChild(MatTable) containersTable: MatTable<IException>;
  @ViewChild('containersSort') containersSort: MatSort;
  @ViewChild('containersPaginator') containersPaginator: MatPaginator;

  shipmentAction: string = '';

  isSidebarOpen = false;
  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];

  fieldsLocked = false;

  constructor(
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private shipmentService: ShipmentService,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    private awsService: AwsService,
    private commentService: CommentService,
    private attachmentService: AttachmentService,
    private timelineService: TimelineService,
    private authService: AuthService,
    private toast: HotToastService,
    private titleCasePipe: TitleCasePipe,
    private location: Location
  ) {
    this.titleService.setTitle('DHL | Shipment');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.shipmentId = params.id;
      this.shipmentAction = params.action;
    });

    this.getCustomers(null, 'customer');

    this.parameterService.getPort().subscribe((ports) => {
      this.ports = ports;
      this.filteredPorts.next(ports);
    });

    this.parameterService.getCountry().subscribe((countries) => {
      this.countries = countries;
      this.filteredCountries.next(countries);
    });

    this.shipment = this.route.snapshot.data['shipment'] && this.route.snapshot.data['shipment'][0];
    
    if(this.shipment) this.titleService.setTitle(`DHL | Shipment (${this.shipment?.SHIP_HBL})`);

    if (this.shipment) {
      const customer: number = Number(this.shipment.SHIP_LOC_CUS);
      const consignee: number = Number(this.shipment.SHIP_CNEE);
      const shipper: number = Number(this.shipment.SHIP_EXP);
      const notify: number = Number(this.shipment.SHIP_NTF_PT);

      this.checkDestiny();

      this.isExceptionsOpen = true;
      this.isMasterbillsOpen = true;
      this.isContainersOpen = true;

      this.getExceptions(this.shipment);
      this.getMasterbills(this.shipment);
      this.getContainers(this.shipment);

      this.getCustomers(customer, 'customer');
      this.getCustomers(consignee, 'consignee');
      this.getCustomers(shipper, 'shipper');
      this.getCustomers(notify, 'notify');

      this.getComments();
      this.getAttachments();
      this.getTimeline();
    }else{
      this.getCustomers(null, 'customer');
      this.getCustomers(null, 'consignee');
      this.getCustomers(null, 'shipper');
      this.getCustomers(null, 'notify');
    }

    this.shipmentForm = this.formBuilder.group({
      hbl: new UntypedFormControl(this.shipment?.SHIP_HBL, [Validators.required]),
      code: new UntypedFormControl(this.shipment?.SHIP_CODE, [Validators.required]),
      reference: new UntypedFormControl(this.shipment?.SHIP_REFERENCE),
      companyCode: new UntypedFormControl(this.shipment?.SHIP_CO_CODE, [Validators.required]),
      countryCode: new UntypedFormControl(this.getId(this.shipment?.SHIP_COU_CODE), [Validators.required]),
      countryCodeFilter: new UntypedFormControl(''),
      commodityCode: new UntypedFormControl(this.shipment?.SHIP_COMM_CODE),
      containerMode: new UntypedFormControl(this.shipment?.SHIP_CTN_MODE),
      departmentCode: new UntypedFormControl(this.shipment?.SHIP_DPT_CODE),
      deliveryCountry: new UntypedFormControl(this.getId(this.shipment?.SHIP_DLV_COU)),
      deliveryCountryFilter: new UntypedFormControl(''),
      incoterms: new UntypedFormControl(this.shipment?.SHIP_INC),
      pickupCountry: new UntypedFormControl(this.getId(this.shipment?.SHIP_PU_COU)),
      pickupCountryFilter: new UntypedFormControl(''),
      transportMode: new UntypedFormControl(this.shipment?.SHIP_TRANS_MODE.toLowerCase()),
      eta: new UntypedFormControl(this.shipment?.SHIP_ETA),
      etd: new UntypedFormControl(this.shipment?.SHIP_ETD),
      ata: new UntypedFormControl(this.shipment?.SHIP_ATA),
      atd: new UntypedFormControl(this.shipment?.SHIP_ATD),
      consignee: new UntypedFormControl(this.getId(this.shipment?.SHIP_CNEE)),
      consigneeFilter: new UntypedFormControl(''),
      localClient: new UntypedFormControl(this.getId(this.shipment?.SHIP_LOC_CUS)),
      localClientFilter: new UntypedFormControl(''),
      notifyParty: new UntypedFormControl(this.getId(this.shipment?.SHIP_NTF_PT)),
      notifyPartyFilter: new UntypedFormControl(''),
      shipper: new UntypedFormControl(this.getId(this.shipment?.SHIP_EXP)),
      shipperFilter: new UntypedFormControl(''),
      pol: new UntypedFormControl(this.getId(Number(this.shipment?.SHIP_POL))),
      polFilter: new UntypedFormControl(''),
      pod: new UntypedFormControl(this.getId(Number(this.shipment?.SHIP_POD))),
      podFilter: new UntypedFormControl('')
    });

    this.shipmentForm.get('countryCodeFilter').valueChanges.subscribe((value) => {
      this.filterCountries(value);
    });

    this.shipmentForm.get('deliveryCountryFilter').valueChanges.subscribe((value) => {
      this.filterCountries(value);
    });

    this.shipmentForm.get('pickupCountryFilter').valueChanges.subscribe((value) => {
      this.filterCountries(value);
    });

    this.shipmentForm.get('localClientFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterCustomers(value);
    });

    this.shipmentForm.get('consigneeFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterConsignee(value);
    });

    this.shipmentForm.get('notifyPartyFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterNotify(value);
    });

    this.shipmentForm.get('shipperFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterShipper(value);
    });

    this.shipmentForm.get('polFilter').valueChanges.subscribe((value) => {
      this.filterPorts(value);
    });

    this.shipmentForm.get('podFilter').valueChanges.subscribe((value) => {
      this.filterPorts(value);
    });
  }

  getCustomers(id: number, type: string): void{
    if(type === 'customer'){
      this.customerService.getCustomer({ CUS_ID: id ? id : null, IS_SELECT: 1 }).subscribe((customers)=>{
        this.customers = customers;
      });
    }

    if(type === 'consignee'){
      this.customerService.getCustomer({ CUS_ID: id ? id : null, IS_SELECT: 1 }).subscribe((customers)=>{
        this.consignees = customers;
      });
    }

    if(type === 'shipper'){
      this.customerService.getCustomer({ CUS_ID: id ? id : null, IS_SELECT: 1 }).subscribe((customers)=>{
        this.shippers = customers;
      });
    }

    if(type === 'notify'){
      this.customerService.getCustomer({ CUS_ID: id ? id : null, IS_SELECT: 1 }).subscribe((customers)=>{
        this.notifies = customers;
      });
    }
  }

  getExceptions(shipment: IShipment): void {
    this.shipmentService.getException(shipment).subscribe((exceptions) => {
      this.exceptionsTableDataSource.data = exceptions;
      this.changeDetectorRef.detectChanges();
      this.exceptionsTableDataSource.sort = this.exceptionsSort;
      this.exceptionsTableDataSource.paginator = this.exceptionsPaginator;
    });
  }

  getMasterbills(shipment: IShipment): void {
    this.shipmentService.getMasterbill(shipment).subscribe((masterbills) => {
      this.masterbillTableDataSource.data = masterbills;
      this.changeDetectorRef.detectChanges();
      this.masterbillTableDataSource.sort = this.masterbillSort;
      this.masterbillTableDataSource.paginator = this.masterbillPaginator;
    });
  }

  getContainers(shipment: IShipment): void {
    this.shipmentService.getContainer(shipment).subscribe((containers) => {
      this.containersTableDataSource.data = containers;
      this.changeDetectorRef.detectChanges();
      this.containersTableDataSource.sort = this.containersSort;
      this.containersTableDataSource.paginator = this.containersPaginator;

      this.lockFields(containers);
    });
  }

  filterCustomers(filter: string): void {
    if (!this.shipmentForm.get('localClient').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
    })
  }

  filterConsignee(filter: string): void {
    if (!this.shipmentForm.get('consignee').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1 }).subscribe((customers)=>{
      this.consignees = customers;
    })
  }

  filterShipper(filter: string): void {
    if (!this.shipmentForm.get('shipper').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1 }).subscribe((customers)=>{
      this.shippers = customers;
    })
  }

  filterNotify(filter: string): void {
    if (!this.shipmentForm.get('notifyParty').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1 }).subscribe((customers)=>{
      this.notifies = customers;
    })
  }

  filterPorts(value: string): void {
    this.filteredPorts.next(this.ports?.filter((port) => {
      return port.DHLPORTS_AIAOCD.toLowerCase().includes(value.toLowerCase());
    }));
  }

  filterCountries(value: string): void {
    this.filteredCountries.next(this.countries?.filter((country) => {
      return country.COU_CODE.toLowerCase().includes(value.toLowerCase());
    }));
  }

  onShipmentFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.shipmentForm.value;

    const shipment: IShipment = {
      SHIP_HBL: formValue.hbl,
      SHIP_CODE: formValue.code,
      SHIP_REFERENCE: formValue.reference,
      SHIP_CO_CODE: formValue.companyCode,
      SHIP_COU_CODE: formValue.countryCode,
      SHIP_COMM_CODE: formValue.commodityCode || null,
      SHIP_CTN_MODE: formValue.containerMode || null,
      SHIP_DPT_CODE: formValue.departmentCode || null,
      SHIP_DLV_COU: formValue.deliveryCountry || null,
      SHIP_INC: formValue.incoterms || null,
      SHIP_PU_COU: formValue.pickupCountry || null,
      SHIP_TRANS_MODE: formValue.transportMode || null,
      SHIP_ETA: formValue.eta,
      SHIP_ETD: formValue.etd,
      SHIP_ATA: formValue.ata,
      SHIP_ATD: formValue.atd,
      SHIP_CNEE: formValue.consignee || null,
      SHIP_LOC_CUS: formValue.localClient || null,
      SHIP_NTF_PT: formValue.notifyParty || null,
      SHIP_EXP: formValue.shipper || null,
      SHIP_POL: formValue.pol || null,
      SHIP_POD: formValue.pod || null,
      SHIP_USER: this.authService.userId
    }

    if (!this.shipment) {
      shipment.SHIP_STATUS = 1;
      this.shipmentService.postShipment(shipment).subscribe((response) => {
        this.shipment = response;
        this.checkDestiny();
        this.isShipmentInformationOpen = false;
        this.isExceptionsOpen = true;
        this.isMasterbillsOpen = true;
        this.isContainersOpen = true;
        this.toast.success('Shipment registered successfully.');
        this.shipmentForm.disable();
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    } else {
      shipment.SHIP_ID = this.shipment.SHIP_ID;
      this.shipmentService.putShipment(shipment).subscribe((response) => {
        this.toast.success(String(response));
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }
  }

  openExceptionsDialog(data: IDialogData<IException>): void {
    const dialogRef = this.dialog.open(ExceptionsDialogComponent, { data });

    dialogRef.afterClosed().subscribe((exception: IException) => {
      if (!exception) return;

      exception.SHIP_ID = Number(this.shipment.SHIP_ID);

      if (data.mode === 'add') {
        this.shipmentService.postException(exception).subscribe((response) => {
          this.toast.success(String(response));
          this.getExceptions(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else if (data.mode === 'edit') {
        this.shipmentService.putException(exception).subscribe((response) => {
          this.toast.success(String(response));
          this.getExceptions(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else if (data.mode === 'delete') {
        this.shipmentService.putException(exception).subscribe(() => {
          this.toast.success('Exception deleted successfully.');
          this.getExceptions(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  openMasterbillDialog(data: IDialogData<IMasterbill>): void {
    const dialogRef = this.dialog.open(MasterbillDialogComponent, { data });

    dialogRef.afterClosed().subscribe((masterbill: IMasterbill) => {
      if (!masterbill) return;

      masterbill.SHIP_ID = this.shipment.SHIP_ID;

      if (data.mode === 'add') {
        this.shipmentService.postMasterbill(masterbill).subscribe((response) => {
          this.toast.success(String(response));
          this.getMasterbills(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else if (data.mode === 'edit') {
        this.shipmentService.putMasterbill(masterbill).subscribe((response) => {
          this.toast.success(String(response));
          this.getMasterbills(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else if (data.mode === 'delete') {
        this.shipmentService.putMasterbill(masterbill).subscribe(() => {
          this.toast.success('Masterbill deleted successfully.');
          this.getMasterbills(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  openContainersDialog(data: IDialogData<IContainer>): void {
    data.destiny = this.destiny;
    const dialogRef = this.dialog.open(ContainersDialogComponent, { data });
    dialogRef.afterClosed().subscribe((container: IContainer) => {
      if (!container) return;

      container.SHIP_ID = this.shipment.SHIP_ID;

      if (data.mode === 'add') {
        this.shipmentService.postContainer(container).subscribe((response) => {
          this.toast.success(String(response));
          this.getContainers(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else if (data.mode === 'edit') {
        this.shipmentService.putContainer(container).subscribe((response) => {
          this.toast.success(String(response));
          this.getContainers(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      } else if (data.mode === 'delete') {
        this.shipmentService.putContainer(container).subscribe(() => {
          this.toast.success('Container deleted successfully.');
          this.getContainers(this.shipment);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  refreshPage(): void {
    location.reload();
    window.scrollTo(0, 0);
  }

  getId(value: number): number | string {
    if (value) {
      return !isNaN(parseInt(value.toString())) ? Number(value) : '';
    } else {
      return '';
    }
  }

  checkDestiny(): void{
    this.destiny = this.shipment?.SHIP_DESTINY;
    if (this.destiny == 'DEMURRAGE'){
      const list: Array<string> = [
        'CTN_GIN',
        'CTN_LDG'
      ]

      list.forEach((item) =>{
        const index = this.containersTableDisplayedColumns.indexOf(item);
        this.containersTableDisplayedColumns.splice(index, 1);
      });
    }else{
      const index = this.containersTableDisplayedColumns.indexOf('CTN_ATA');
      this.containersTableDisplayedColumns.splice(index, 1);
    }
  }

  getStatusProcess(id: number): string {
    const statusProcess: Array<string> = [
      '',
      '',
      `${this.shipment.SHIP_DESTINY == 'DEMURRAGE' ? 'Ship Arrived' : 'Waiting Loading'}`,
      'Container Returned',
      `${this.titleCasePipe.transform(this.shipment.SHIP_DESTINY)} Calculated`,
      'Invoice Generated',
      'Cancelled',
      `No ${this.titleCasePipe.transform(this.shipment.SHIP_DESTINY)}`,
      'Agreement Not Found',
      'Invoice Sent'
    ];

    return statusProcess[id];
  }

  date(date: string | null): string | null {
    return date ? new Date(date).toLocaleDateString('pt-BR') : null;
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `shipment`, REG_ID: this.shipmentId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: this.shipmentId,
          COMM_REF_GROUP: `shipment`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, error => this.toast.error(error.error.Message));
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: `shipment`, REG_ID: this.shipmentId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, `shipment`, [{ REG_ID: this.shipmentId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    const timeline: ITimeline = {
      TIMELINE_GROUP: 'shipment',
      TIMELINE_REG_ID: this.shipmentId?.toString()
    }

    this.timelineService.getTimeline(timeline).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  lockFields(containers: IContainer[]){
    containers.forEach(ctn => {
      if(ctn.CTN_PROCESS_STATUS == 9) {
        this.fieldsLocked = true;
      }
    });
  }

  goBack(){
    this.location.back();
  }
}