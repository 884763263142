import { HotToastService } from '@ngneat/hot-toast';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ICarrier } from 'src/app/models/parameter.model';
import { IUniqueTerm } from 'src/app/models/unique-term';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { IAttachment } from 'src/app/models/attachment.model';
import { UniqueTermService } from 'src/app/services/unique-term.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AwsService } from 'src/app/services/aws.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ParameterService } from 'src/app/services/parameter.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { ITimeline } from 'src/app/models/timeline.model';
import { IComment } from 'src/app/models/comment.model';
import { CommentService } from 'src/app/services/comment.service';
import { TimelineService } from 'src/app/services/timeline.service';

@Component({
  selector: 'dhl-carrier-unique-term',
  templateUrl: './carrier-unique-term.component.html',
  styleUrls: ['./carrier-unique-term.component.scss']
})
export class CarrierUniqueTermComponent implements OnInit {

  carrUniqueTermForm: UntypedFormGroup;

  term: IUniqueTerm = {};
  uniqTermId = 0;

  carriers: Array<ICarrier>;
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  isSidebarOpen = false;
  comments: Array<IComment> = [];
  timeline: Array<ITimeline> = [];
  attachments: Array<IAttachment> = [];
  files: Array<File> = [];
  attachmentTab = false;
  hasFile = false;
  isFormDisabled: boolean = false;
  showAttachments: boolean = true;

  showToUploadTab = false;

  minEndDate: Date;

  constructor(
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private uniqueTermService: UniqueTermService,
    private parameterService: ParameterService,
    private toast: HotToastService,
    private attachmentService: AttachmentService,
    private commentService: CommentService,
    private timelineService: TimelineService,
    private awsService: AwsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.titleService.setTitle('DHL | Unique Terms (Carriers)');
    this.parameterService.getCarrier().subscribe((carriers) => {
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });
  }

  ngOnInit() {
    this.term = this.route.snapshot.data['carrierUniqueTerm'] && this.route.snapshot.data['carrierUniqueTerm'][0];

    this.carrUniqueTermForm = this.formBuilder.group({
      uniName: new UntypedFormControl(this.term?.UNI_NAME, [Validators.required]),
      uniReceivedDt: new UntypedFormControl(this.term?.UNI_RECEIVED_DT, [Validators.required]),
      uniStartDt: new UntypedFormControl(this.term?.UNI_START_DT, [Validators.required]),
      uniEndDt: new UntypedFormControl(this.term?.UNI_END_DT, [Validators.required]),      
      carrier: new UntypedFormControl(this.term?.CARR_ID, [Validators.required]),
      carrierFilter: new UntypedFormControl(''),
      uniComment: new UntypedFormControl(this.term?.UNI_COMMENT, [Validators.required])
    });

    this.attachmentTab = this.term != null ? true : false;

    this.minEndDate = this.carrUniqueTermForm.get('uniStartDt').value;

    if (this.term) {
      this.getAttachments();
      this.getComments();
      this.getTimeline();
    }

    this.route.url.subscribe((url) => {
      if (url[2]?.path === 'view') {
        this.isFormDisabled = true;
        this.carrUniqueTermForm.disable();
      } else if (!url[2]?.path) {
        this.showAttachments = false;
      }
    });

    this.carrUniqueTermForm.get('carrierFilter').valueChanges.subscribe(() => {
      this.filterCarriers();
    });

    this.carrUniqueTermForm.get('uniStartDt').valueChanges.subscribe((date: Date) => {
      this.minEndDate = this.carrUniqueTermForm.get('uniStartDt').value;
    });
  }

  filterCarriers(): void {
    if (!this.carriers?.length) return;
    const filterValue = this.carrUniqueTermForm.get('carrierFilter').value.toLowerCase();
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.RELATIPF_APCPTK.toLowerCase().includes(filterValue) || carrier.RELATIPF_APA3CD.toLowerCase().includes(filterValue)));
  }

  getErrorMessage(formControl: string): string {
    if (this.carrUniqueTermForm.get(formControl).hasError('email')) {
      return 'You must enter a valid email';
    } else if (this.carrUniqueTermForm.get(formControl).hasError('number')) {
      return 'The value must be a number';
    } else if (this.carrUniqueTermForm.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

  onFormSubmit(e: Event){
    e.preventDefault();

    const formValue = this.carrUniqueTermForm.value;

    let term: IUniqueTerm = {
      UNI_NAME: encodeURIComponent(formValue.uniName),
      UNI_RECEIVED_DT: formValue.uniReceivedDt,
      UNI_START_DT: formValue.uniStartDt,
      UNI_END_DT: formValue.uniEndDt,
      CARR_ID: formValue.carrier,
      UNI_COMMENT: formValue.uniComment,
      UNI_USER: this.authService.userId
    }

    if(!this.term) {
      this.uniqueTermService.post(term).subscribe((response) => {
        this.toast.success(String(response));
        this.getTermId(term);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }
    else{
      term.UNI_ID = this.term.UNI_ID;
      term.UNI_STATUS = 1;

      this.uniqTermId = term.UNI_ID;

      this.uniqueTermService.put(term).subscribe((response) => {
        this.toast.success(String(response));
        if(this.files.length > 0) {
          this.uploadFiles(this.files);
        }
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }

  }

  getTermId(term: IUniqueTerm){
    this.uniqueTermService.getTermId(term).subscribe((id: number) => {
      this.uniqTermId = id;     
      if(this.uniqTermId > 0){
        this.uploadFiles(this.files);      }
      else {
        this.router.navigate(['/unique-terms/carriers']);
      }
    });
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `carrier_unique_term`, REG_ID: this.term.UNI_ID }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: this.term.UNI_ID,
          COMM_REF_GROUP: `carrier_unique_term`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, error => this.toast.error(error.error.Message));
  }

  getAttachments(): void {
    if(this.term == undefined) {
      return;
    }

    this.attachmentService.getAttachments({ ATCH_REF_GROUP: `carrier_unique_term`, REG_ID: this.term.UNI_ID }).subscribe(
      (attachments) => {
        this.attachments = attachments;
        this.hasFile = attachments.length > 0 ? true : false;
      }
    );
  }

  onUpload(files: Array<File>) {
    this.files = [];
    files.forEach(file => {
      this.files.push(file);
    });

    this.hasFile = true;
  }

  uploadFiles(files: Array<File>) {
    this.awsService
      .uploadFiles(files, `carrier_unique_term`, [{ REG_ID: this.uniqTermId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () =>'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.files = null;
          this.router.navigate(['/unique-terms/carriers']);
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    const timeline: ITimeline = {
      TIMELINE_GROUP: 'carrier_unique_term',
      TIMELINE_REG_ID: this.term.UNI_ID?.toString()
    }

    this.timelineService.getTimeline(timeline).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

}