<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="customerNotificationForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <div class="form-group-row">
        <mat-form-field>
          <mat-label>Code</mat-label>
          <input matInput name="code" maxlength="100" formControlName="code" required>
          <mat-error *ngIf="customerNotificationForm.get('code').invalid">{{getCodeErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput name="name" maxlength="100" formControlName="name" required>
          <mat-error *ngIf="customerNotificationForm.get('name').invalid">{{getNameErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput name="value" maxlength="4000" formControlName="value" required>
        <mat-error *ngIf="customerNotificationForm.get('value').invalid">{{getValueErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the notification parameter {{data.customerNotification.NOTIF_PARAM_NAME}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="customerNotificationForm.invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="customerNotificationForm.invalid">Ok</button>
    </div>
  </form>
</div>