<h1 mat-dialog-title>Filter Financial</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Job Number</mat-label>
        <input matInput type="text" formControlName="jobNumber">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('jobNumber')" (click)="resetField('jobNumber')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Charge Code</mat-label>
        <mat-select name="chargeCode" formControlName="chargeCode">
          <mat-option value="DDEM">DDEM</mat-option>
          <mat-option value="ODET">ODET</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('chargeCode')" (click)="resetField('chargeCode')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="form-group">      
      <mat-form-field>
        <mat-label>Trn Dept</mat-label>
        <mat-select name="trnDept" formControlName="trnDept">
          <mat-option value="FES">FES</mat-option>
          <mat-option value="FFE">FFE</mat-option>
          <mat-option value="FFI">FFI</mat-option>
          <mat-option value="FIS">FIS</mat-option>
          <mat-option value="FLE">FLE</mat-option>
          <mat-option value="FLI">FLI</mat-option>
          <mat-option value="IPR">IPR</mat-option>
          <mat-option value="WFI">WFI</mat-option>
          <mat-option value="WLI">WLI</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('trnDept')" (click)="resetField('trnDept')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Line Type</mat-label>
        <mat-select name="lineType" formControlName="lineType">
          <mat-option value="ACR">ACR</mat-option>
          <mat-option value="CST">CST</mat-option>
          <mat-option value="REV">REV</mat-option>
          <mat-option value="WIP">WIP</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('lineType')" (click)="resetField('lineType')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="form-group">      
      <mat-form-field>
        <mat-label>Posting Period (Month)</mat-label>
        <mat-select name="periodMonth" formControlName="periodMonth">
          <mat-option value="1">January</mat-option>
          <mat-option value="2">February</mat-option>
          <mat-option value="3">March</mat-option>
          <mat-option value="4">April</mat-option>
          <mat-option value="5">May</mat-option>
          <mat-option value="6">June</mat-option>
          <mat-option value="7">July</mat-option>
          <mat-option value="8">August</mat-option>
          <mat-option value="9">September</mat-option>
          <mat-option value="10">October</mat-option>
          <mat-option value="11">November</mat-option>
          <mat-option value="12">December</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('periodMonth')" (click)="resetField('periodMonth')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Posting Period (Year)</mat-label>
        <mat-select name="periodYear" formControlName="periodYear">
          <mat-option *ngFor="let year of years" [value]="year.FBF_POST_PERIOD_YEAR">{{year.FBF_POST_PERIOD_YEAR}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('periodYear')" (click)="resetField('periodYear')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!-- <div class="form-group">      
      <mat-form-field>
        <mat-label>Client Name</mat-label>
        <mat-select name="client" formControlName="client">
          <mat-option>
            <ngx-mat-select-search formControlName="clientFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let client of filteredClients" [value]="client.CUS_NAME">{{client.CUS_CODE}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('client')" (click)="resetField('client')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div> -->

    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="close(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetForm()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>