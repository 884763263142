import { CustomerService } from 'src/app/services/customer.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICustomer, ICustomerException, ICustomerNotification } from 'src/app/models/customer.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface INotificationFilterDialog {
  element: ICustomerNotification;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;
  customers: Array<ICustomer>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INotificationFilterDialog,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      customer: new UntypedFormControl(this.data.element.CUS_ID),
      customerFilter: new UntypedFormControl(''),
      invoiceId: new UntypedFormControl(this.data.element.INVOICE_ID),
      status: new UntypedFormControl(this.data.element.NOTIFICATION_SITUATION)
    });

    this.getCustomers({ CUS_ID: this.form.get('customer').value, IS_SELECT: 1 });
    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers
    })
  }

  filterCustomers(filter: string): void {
    if (!this.form.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  closeDialog(filter: ICustomerException): void {
    this.dialogRef.close(filter);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    const notification: ICustomerNotification = {
      CUS_ID: this.form.value.customer ?? null,
      INVOICE_ID: this.form.value.invoiceId,
      NOTIFICATION_SITUATION: this.form.value.status
    };

    this.closeDialog(notification);
  }

  reset(): void {
    this.form.setValue({
      customer: null,
      customerFilter: '',
      invoiceId: null,
      status: null
    });
  }

}
