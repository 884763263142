import { IPayable } from './../../../../models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IChangeStatusDialog {
  element: IPayable | Array<IPayable>;
}

@Component({
  selector: 'dhl-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.scss']
})
export class ChangeStatusDialogComponent implements OnInit {
  form: UntypedFormGroup;

  comment: string = '';
  files: Array<File> = [];

  payableStatus: Array<string> = [
    "New Process",
    "Under Analysis",
    "Dispute DHL",
    "Dispute Carrier",
    "Programed Payment",
    "Paid (Dispute)",
    "Paid",
    "Invoice Canceled"
  ];

  payableSubStatus: Array<string> = [
    "Under Internal Review",
    "FT & PD Incorrect",
    "Incorrect Invoice",
    "Partial Invoice",
    "Invoice Not Found",
    "Waiting Confirmation (Free-time)",
    "Waiting for Loss Approval",
    "Waiting to Process Billing",
    "Waiting Confirmation of Ship Transfer",
    "Ok to Pay",
    "Paid",
    "Old Payment",
    "Invoice Canceled",
    "Ship Delay",
    "Terminal Window Delay",
    "Waiting Payment Order",
    "Waiting Reimbursement",
    "Dispute",
    "Waiting Customer to Pay"
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChangeStatusDialog,
    private dialogRef: MatDialogRef<ChangeStatusDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      status: new UntypedFormControl(this.data.element instanceof Array ? '' : String(this.data.element?.PB_PRC_ST)),
      subStatus: new UntypedFormControl(this.data.element instanceof Array ? '' : String(this.data.element?.PB_PRC_SUB_ST))
    });
  }

  close(value: [Array<IPayable>, string, Array<File>]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newPayable: Array<IPayable> = [];

    if (this.data.element instanceof Array) {
      newPayable = this.data.element.map((element) => {
        return {
          PB_ID: element.PB_ID,
          PB_PRC_ST: this.form.value.status,
          PB_PRC_SUB_ST: this.form.value.subStatus,
          PB_CMT: this.comment,
          PB_USER: this.authService.userId
        }
      });
    } else {
      newPayable.push({
        PB_ID: this.data.element.PB_ID,
        PB_PRC_ST: this.form.value.status,
        PB_PRC_SUB_ST: this.form.value.subStatus,
        PB_CMT: this.comment,
        PB_USER: this.authService.userId
      });
    }

    this.close([newPayable, this.comment, this.files]);
  }

  onComment(comment: string) {
    this.comment = comment;
  }

  onUpload(files: Array<File>) {
    this.files = [];
    files.forEach(file => this.files.push(file));
  }

  isValidComment(): boolean{
    if(this.data.element instanceof Array || !this.data.element.PB_CMT_USER_NAME){
      return false;
    }else{
      return true;
    }
  }

  validateOpenOptions(): boolean{
    if (this.data.element instanceof Array) {
      return true;
    }else{
      if(this.data.element.PB_PRC_ST === 6){
        return false;
      }else{
        return true;
      }
    }
  }

  validateCloseOptions(): boolean{
    if (this.data.element instanceof Array) {
      return false;
    }else{
      if(this.data.element.PB_PRC_ST === 6){
        return true;
      }else{
        return false;
      }
    }
  }
}
