import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { IContainer } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';

export class ISelectedDetentionDatesDialog {
  element?: IContainer | Array<IContainer>;
  title: string;
}

@Component({
  selector: 'dhl-selected-detention-dates',
  templateUrl: './selected-detention-dates.component.html',
  styleUrls: ['./selected-detention-dates.component.scss']
})
export class SelectedDetentionDatesComponent implements OnInit {

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISelectedDetentionDatesDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<SelectedDetentionDatesComponent>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      date: new FormControl('')
    });

    console.log('Dados: ' + JSON.stringify(this.data));
  }

  onFormSubmit(e: Event) {
    e.preventDefault();

    let newContainers: Array<IContainer> = [];

    if(this.data.element instanceof Array) {
      if(this.data.title == 'Gate In Date') {
        newContainers = this.data.element.map((element) => {
          return {

            SHIP_ID: element.SHIP_ID,
            CTN_ID: element.CTN_ID,
            CTN_NUMBER: element.CTN_NUMBER,
            //CTN_TYPE: element.CTN_TYPE,
            CTN_GOU: element.CTN_GOU,
            CTN_GIN: new Date(this.form.get('date').value),
            CTN_LDG: element.CTN_LDG,
            CTN_END_REFERENCE: element.CTN_END_REFERENCE,
            CTN_STATUS: element.CTN_STATUS,
            CTN_USER: this.authService.userId

            //SHIP_ID: element.SHIP_ID,
            //CTN_ID: element.CTN_ID,
            //CTN_NUMBER: element.CTN_NUMBER,
            //CTN_GIN: this.form.value.date,
            //CTN_USER: this.authService.userId
          }
        })
      }

      if(this.data.title == 'Loading Date') {
        newContainers = this.data.element.map((element) => {
          return {
            SHIP_ID: element.SHIP_ID,
            CTN_ID: element.CTN_ID,
            CTN_NUMBER: element.CTN_NUMBER,
            CTN_GOU: element.CTN_GOU,
            CTN_GIN: element.CTN_GIN,
            CTN_LDG: new Date(this.form.value.date),
            CTN_END_REFERENCE: element.CTN_END_REFERENCE,
            CTN_STATUS: element.CTN_STATUS,
            CTN_USER: this.authService.userId
          }
        })
      }

      if(this.data.title == 'Custom Date') {
        newContainers = this.data.element.map((element) => {
          return {
            SHIP_ID: element.SHIP_ID,
            CTN_ID: element.CTN_ID,
            CTN_NUMBER: element.CTN_NUMBER,
            CTN_GOU: element.CTN_GOU,
            CTN_GIN: element.CTN_GIN,
            CTN_LDG: element.CTN_LDG,
            CTN_END_REFERENCE: new Date(this.form.value.date),
            CTN_STATUS: element.CTN_STATUS,
            CTN_USER: this.authService.userId
          }
        })
      }
    }
    else {
      if(this.data.title == 'Gate In') {
        newContainers.push({
          SHIP_ID: this.data.element.SHIP_ID,
          CTN_ID: this.data.element.CTN_ID,
          CTN_NUMBER: this.data.element.CTN_NUMBER,
          CTN_GOU: this.data.element.CTN_GOU,
          CTN_GIN: new Date(this.form.value.date),
          CTN_LDG: this.data.element.CTN_LDG,
          CTN_END_REFERENCE: this.data.element.CTN_END_REFERENCE,
          CTN_STATUS: this.data.element.CTN_STATUS,
          CTN_USER: this.authService.userId
        })
      }

      if(this.data.title == 'Loading Date') {
        newContainers.push({
          SHIP_ID: this.data.element.SHIP_ID,
          CTN_ID: this.data.element.CTN_ID,
          CTN_NUMBER: this.data.element.CTN_NUMBER,
          CTN_GOU: this.data.element.CTN_GOU,
          CTN_GIN: this.data.element.CTN_GIN,
          CTN_LDG: new Date(this.form.value.date),
          CTN_END_REFERENCE: this.data.element.CTN_END_REFERENCE,
          CTN_STATUS: this.data.element.CTN_STATUS,
          CTN_USER: this.authService.userId
        })
      }

      if(this.data.title == 'Custom Date') {
        newContainers.push({
          SHIP_ID: this.data.element.SHIP_ID,
          CTN_ID: this.data.element.CTN_ID,
          CTN_NUMBER: this.data.element.CTN_NUMBER,
          CTN_GOU: this.data.element.CTN_GOU,
          CTN_GIN: this.data.element.CTN_GIN,
          CTN_LDG: this.data.element.CTN_LDG,
          CTN_END_REFERENCE: new Date(this.form.value.date),
          CTN_STATUS: this.data.element.CTN_STATUS,
          CTN_USER: this.authService.userId
        })
      }
    }

    this.closeDialog([newContainers, '']);
  }

  closeDialog(value: [Array<IContainer>, string]): void {
    this.dialogRef.close(value);
  }

}
