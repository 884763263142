
import { NgModule } from "@angular/core";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from "src/app/components/components.module";
import { ExceptionFilterDialogComponent } from './exception-filter-dialog/exception-filter-dialog.component';
import { ExceptionDeleteAllDialogComponent } from './exception-delete-all-dialog/exception-delete-all-dialog.component';
import { ExceptionDeleteDialogComponent } from './exception-delete-dialog/exception-delete-dialog.component';

@NgModule({
    declarations:[    
        ExceptionFilterDialogComponent,
        ExceptionDeleteAllDialogComponent,
        ExceptionDeleteDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        NgxMatSelectSearchModule,
        ComponentsModule
    ]
})
export class ExceptionsDialogsModule { }