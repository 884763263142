import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

@Component({
  selector: 'dhl-import-pdf-dialog',
  templateUrl: './import-pdf-dialog.component.html',
  styleUrls: ['./import-pdf-dialog.component.scss']
})
export class ImportPdfDialogComponent implements OnInit {
  files: Array<File> = [];

  constructor(
    private dialogRef: MatDialogRef<ImportPdfDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  onSelect(e: NgxDropzoneChangeEvent): void {
    this.files.push(...e.addedFiles);
  }

  onRemove(file: File): void {
    this.files.splice(this.files.indexOf(file), 1);
  }

  close(): void {
    this.dialogRef.close();
  }
}
