<div class="user-list">
  <h1 mat-dialog-title>Groups of {{data.user.PI_USER_NAME}}</h1>
  <div class="actions-container">
    <mat-form-field class="customer-group-search">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix color="primary">search</mat-icon>
      <input matInput type="search" [(ngModel)]="filterValue" (input)="filterUserGroupTable(filterValue)">
      <button type="button" mat-icon-button matSuffix (click)="filterValue = ''; filterUserGroupTable(filterValue)" *ngIf="filterValue !== ''">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div mat-dialog-content>
    <div class="table-container">
      <table #userGroupTable mat-table [dataSource]="userGroupTableDataSource" matSort #userGroupSort="matSort">
        <ng-container matColumnDef="GRP_NAME">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Groups">Groups</th>
          <td mat-cell *matCellDef="let element">{{element.GRP_NAME}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="userGroupTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: userGroupTableDisplayedColumns;"></tr>
      </table>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button type="button" color="accent" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>
