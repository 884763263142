<form [formGroup]="exceptionsForm" (submit)="onFormSubmit($event)">
    <h2>Customer Exception</h2>
    <div>
        <div class="form-group">
            <mat-form-field>
                <mat-label>Customer</mat-label>
                <mat-select name="customer" formControlName="customer" [disabled]="!!exceptionsForm.get('customerGroup').value" (selectionChange)="setCustomerValidator('customer', ['customerGroup'])">
                    <mat-option>
                        <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search"
                            noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_CODE}} -
                        {{customer.CUS_NAME}}</mat-option>
                </mat-select>
                <button type="button" mat-icon-button matSuffix
                    (click)="exceptionsForm.get('customer').setValue(''); exceptionsForm.get('customerFilter').setValue(''); setCustomerValidator('customer', ['customerGroup'])"
                    *ngIf="!!exceptionsForm.get('customer').value" [disabled]="isFormDisabled">
                    <mat-icon color="primary">close</mat-icon>
                </button>
                <mat-error *ngIf="exceptionsForm.get('customer').invalid">{{getErrorMessage('customer')}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Customer Group</mat-label>
                <mat-select name="customerGroup" formControlName="customerGroup" [disabled]="!!exceptionsForm.get('customer').value" (selectionChange)="setCustomerValidator('customerGroup', ['customer'])">
                    <mat-option>
                        <ngx-mat-select-search formControlName="customerGroupFilter" placeholderLabel="Search"
                            noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let customerGroup of filteredCustomerGroups | async" [value]="customerGroup.CUS_GRP_ID">{{customerGroup.CUS_GRP_NAME}}</mat-option>
                </mat-select>
                <button type="button" mat-icon-button matSuffix
                    (click)="exceptionsForm.get('customerGroup').setValue(''); exceptionsForm.get('customerGroupFilter').setValue(''); setCustomerValidator('customerGroup', ['customer'])"
                    *ngIf="!!exceptionsForm.get('customerGroup').value" [disabled]="isFormDisabled">
                    <mat-icon color="primary">close</mat-icon>
                </button>
                <mat-error *ngIf="exceptionsForm.get('customerGroup').invalid">{{getErrorMessage('customerGroup')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>Cut Off</mat-label>
                <mat-select name="cutOff" formControlName="cutOff" (selectionChange)="checkCuteOff()">
                    <mat-option value="0">No</mat-option>
                    <mat-option value="1">Yes</mat-option>
                </mat-select>
                <mat-error *ngIf="exceptionsForm.get('cutOff').invalid">{{getErrorMessage('cutOff')}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Cut Off Days</mat-label>
                <input matInput name="cutOffDays" formControlName="cutOffDays">
                <mat-error
                    *ngIf="exceptionsForm.get('cutOffDays').invalid">{{getErrorMessage('cutOffDays')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>Reference</mat-label>
                <mat-select name="reference" formControlName="reference">
                    <mat-option value="0">No</mat-option>
                    <mat-option value="1">Yes</mat-option>
                </mat-select>
                <mat-error *ngIf="exceptionsForm.get('reference').invalid">{{getErrorMessage('reference')}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Review</mat-label>
                <mat-select name="review" formControlName="review">
                    <mat-option value="0">No</mat-option>
                    <mat-option value="1">Yes</mat-option>
                </mat-select>
                <mat-error *ngIf="exceptionsForm.get('review').invalid">{{getErrorMessage('review')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>Requestor</mat-label>
                <mat-chip-list #requestorList formControlName="requestor">
                    <mat-chip *ngFor="let requestor of requestors" (removed)="removeRequestor(requestor)">
                        {{requestor}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input name="requestor" formControlName="requestor" 
                        [matChipInputFor]="requestorList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addRequestor($event)">
                </mat-chip-list>
                <mat-error
                    *ngIf="exceptionsForm.get('requestor').invalid">{{getErrorMessage('requestor')}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Received Date</mat-label>
                <input matInput name="receivedDate" formControlName="receivedDate"
                    [matDatepicker]="receivedDatePicker">
                <mat-datepicker-toggle matSuffix [for]="receivedDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #receivedDatePicker></mat-datepicker>
                <mat-error
                    *ngIf="exceptionsForm.get('receivedDate').invalid">{{getErrorMessage('receivedDate')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input matInput name="startDate" formControlName="startDate" [matDatepicker]="startDatePicker">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-error
                    *ngIf="exceptionsForm.get('startDate').invalid">{{getErrorMessage('startDate')}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>End Date</mat-label>
                <input matInput name="endDate" formControlName="endDate" [matDatepicker]="endDatePicker">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                <mat-error *ngIf="exceptionsForm.get('endDate').invalid">{{getErrorMessage('endDate')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>Analysis Deadline Default</mat-label>
                <mat-select name="defaultDeadline" formControlName="defaultDeadline" (selectionChange)="checkAnalysisDeadline()">
                    <mat-option value="0">No</mat-option>
                    <mat-option value="1">Yes</mat-option>
                </mat-select>
                <mat-error *ngIf="exceptionsForm.get('defaultDeadline').invalid">{{getErrorMessage('defaultDeadline')}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Analysis Deadline</mat-label>
                <input matInput name="analysisDeadline" formControlName="analysisDeadline">
                <mat-error
                    *ngIf="exceptionsForm.get('analysisDeadline').invalid">{{getErrorMessage('analysisDeadline')}}</mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>Observation</mat-label>
                <textarea matInput name="observation" formControlName="observation"></textarea>
                <mat-error
                    *ngIf="exceptionsForm.get('observation').invalid">{{getErrorMessage('observation')}}</mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <dhl-attachment [attachments]="attachments" [showAttachmentsTab]="showAttachments"
                [showUploadTab]="!isFormDisabled" (upload)="onUpload($event)" [offSideAttachments]="true"
                [showRenameFile]="true">
            </dhl-attachment>
        </div>

    </div>

    <div class="actions-group">
        <a mat-button color="accent" [routerLink]="['/customer/exceptions']">Cancel</a>
        <button mat-flat-button type="submit" color="accent"
            [disabled]="exceptionsForm.invalid || isFormDisabled">Save</button>
    </div>
</form>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
    <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
    <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
    <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
<button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar"
    (click)="openSidebar()" *ngIf="this.exception">
    <mat-icon>menu_open</mat-icon>
</button>