import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function freetimeValidator(container: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const ctnIsPartial = container.CTN_IS_PARTIAL == 0;
    const ctnFrt = container.CTN_FRT;

    if (!ctnIsPartial) {
      return null;
    }

    const ctnStart = new Date(container.START_DATE || container.CTN_GOU).getTime();
    const ctnGin = new Date(control.value).getTime();
    const differenceInDays = Math.ceil((ctnGin - ctnStart) / (1000 * 60 * 60 * 24));

    if (differenceInDays <= ctnFrt) {
      return { freetimeInvalid: true };
    }

    return null;
  };
}