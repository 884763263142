import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dhl-customer-delete-all-dialog',
  templateUrl: './customers-delete-all-dialog.component.html',
  styleUrls: ['./customers-delete-all-dialog.component.scss']
})
export class CustomerDeleteAllDialogComponent {

  constructor(public dialogRef: MatDialogRef<CustomerDeleteAllDialogComponent>) { }

  closeDialog(willDelete: boolean) {
    this.dialogRef.close(willDelete);
  }
}
