<div class="exception-container">
  <div class="exceptions" *ngIf="customer_exception || group_exception; else noExceptions">
    <mat-tab-group>
      <mat-tab label="Customer" *ngIf="customer_exception">
        <div class="exception">
          <div class="exception-customer">
            <h2>Customer Exceptions</h2>
            <div class="form-group">
              <h3>Customer</h3>
              <p>{{customer_exception?.CUS_NAME}}</p>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Cut Off</h3>
                <p>{{activeMap.get(customer_exception?.EXCEPTION_CUT_OFF)}}</p>
              </div>
              <div class="form-group">
                <h3>Cut Off Days</h3>
                <p>{{customer_exception?.EXCEPTION_CUT_OFF_DAYS}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Reference</h3>
                <p>{{activeMap.get(customer_exception?.EXCEPTION_REFERENCE)}}</p>
              </div>
              <div class="form-group">
                <h3>Review</h3>
                <p>{{activeMap.get(customer_exception?.EXCEPTION_REVIEW)}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Requestor</h3>
                <p>{{customer_exception?.EXCEPTION_REQUESTOR}}</p>
              </div>
              <div class="form-group">
                <h3>Received Date</h3>
                <p>{{customer_exception?.EXCEPTION_RECEIVED_DATE | date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Start Date</h3>
                <p>{{customer_exception?.EXCEPTION_START_DATE | date:'dd/MM/yyyy'}}</p>
              </div>
              <div class="form-group">
                <h3>End Date</h3>
                <p>{{customer_exception?.EXCEPTION_END_DATE | date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Analysis Deadline Default</h3>
                <p>{{activeMap.get(customer_exception?.EXCEPTION_DEFAULT_DEADLINE)}}</p>
              </div>
              <div class="form-group">
                <h3>Analysis Deadline</h3>
                <p>{{customer_exception?.EXCEPTION_DEFAULT_DEADLINE === '1' ? customer_exception?.ANALYSIS_DEADLINE_DEFAULT : customer_exception?.EXCEPTION_ANALYSIS_DEADLINE}}h</p>
              </div>
            </div>
            <div class="form-group">
              <h3>Observation</h3>
              <textarea style="resize: none;" rows="10" disabled>{{customer_exception?.EXCEPTION_OBSERVATION}}</textarea>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Group" *ngIf="group_exception">
        <div class="exception">
          <div class="exception-group">
            <h2>Group Exceptions</h2>
            <div class="form-group">
              <h3>Group</h3>
              <p>{{group_exception?.CUS_GRP_NAME}}</p>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Cut Off</h3>
                <p>{{activeMap.get(group_exception?.EXCEPTION_CUT_OFF)}}</p>
              </div>
              <div class="form-group">
                <h3>Cut Off Days</h3>
                <p>{{group_exception?.EXCEPTION_CUT_OFF_DAYS}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Reference</h3>
                <p>{{activeMap.get(group_exception?.EXCEPTION_REFERENCE)}}</p>
              </div>
              <div class="form-group">
                <h3>Review</h3>
                <p>{{activeMap.get(group_exception?.EXCEPTION_REVIEW)}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Requestor</h3>
                <p>{{group_exception?.EXCEPTION_REQUESTOR}}</p>
              </div>
              <div class="form-group">
                <h3>Received Date</h3>
                <p>{{group_exception?.EXCEPTION_RECEIVED_DATE | date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Start Date</h3>
                <p>{{group_exception?.EXCEPTION_START_DATE | date:'dd/MM/yyyy'}}</p>
              </div>
              <div class="form-group">
                <h3>End Date</h3>
                <p>{{group_exception?.EXCEPTION_END_DATE | date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
            <div class="form-container">
              <div class="form-group">
                <h3>Analysis Deadline Default</h3>
                <p>{{activeMap.get(group_exception?.EXCEPTION_DEFAULT_DEADLINE)}}</p>
              </div>
              <div class="form-group">
                <h3>Analysis Deadline</h3>
                <p>{{group_exception?.EXCEPTION_DEFAULT_DEADLINE === '1' ? group_exception?.ANALYSIS_DEADLINE_DEFAULT : group_exception?.EXCEPTION_ANALYSIS_DEADLINE}}h</p>
              </div>
            </div>
            <div class="form-group">
              <h3>Observation</h3>
              <textarea style="resize: none;" rows="10" disabled>{{group_exception?.EXCEPTION_OBSERVATION}}</textarea>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>     
  </div>  
  <ng-template #noExceptions>
    <div class="exception">
      <div class="empty-exceptions">
        No exceptions found for this registry
      </div>
    </div>
  </ng-template>
  <div class="exception" *ngIf="customer_contacts">
    <div class="exception-customer">
      <div class="form-group">
        <h3>Customer Contacts</h3>
        <p style="text-align: left;">{{customer_contacts}}</p>
      </div>
    </div>
  </div>
</div>