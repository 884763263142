import { ComponentsModule } from 'src/app/components/components.module';
import { AgreementDeleteAllDialogComponent } from './agreement-delete-all-dialog/agreement-delete-all-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { HotToastModule } from '@ngneat/hot-toast';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AgreementDeleteDialogComponent } from './agreement-delete-dialog/agreement-delete-dialog.component';
import { AgreementFilterDialogComponent } from './agreement-filter-dialog/agreement-filter-dialog.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { AttachmentDialogComponent } from './attachment-dialog/attachment-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HotToastModule,
    NgxMatSelectSearchModule,
    ComponentsModule
  ],
  declarations: [
    AgreementDeleteAllDialogComponent,
    AgreementDeleteDialogComponent,
    AgreementFilterDialogComponent,
    CommentDialogComponent,
    AttachmentDialogComponent
  ]
})
export class AgreementsDialogsModule { }
