<form [formGroup]="defaultRuleForm" (submit)="onFormSubmit($event)">      
  <div class="expandable">
    <button type="button" class="open-expandable" [ngClass]="{'open': isAgreementParametersOpen}" (click)="isAgreementParametersOpen = !isAgreementParametersOpen">
      <span>Standard {{defaultRuleTitle}}</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isAgreementParametersOpen}">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput name="name" formControlName="name">
        </mat-form-field>
        <mat-form-field *ngIf="defaultRuleType != 'dhl'">
          <mat-label>Service</mat-label>
          <mat-select name="service" formControlName="service">
            <mat-option value="demurrage">DEMURRAGE</mat-option>
            <mat-option value="detention">DETENTION</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="defaultRuleType != 'carrier'">
          <mat-label>Type</mat-label>
          <mat-select name="type" formControlName="type">
            <mat-option value="sale">SALE</mat-option>
            <mat-option value="purchase">PURCHASE</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="defaultRuleType == 'carrier'">
          <mat-label>Carrier</mat-label>
          <mat-select name="carrier" formControlName="carrier">
            <mat-option>
              <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Container Type</mat-label>
          <mat-select name="containerType" formControlName="containerType" multiple>
            <mat-option>
              <ngx-mat-select-search formControlName="containerTypeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let type of filteredContainerTypes | async" [value]="type.DIM_SK_CTN_TYP">{{type.CONTYPPF_QAMPZ}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Start Date</mat-label>
          <input matInput name="startDate" formControlName="startDate" [matDatepicker]="startDatePicker">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Validity Date</mat-label>
          <input matInput name="validityDate" formControlName="validityDate" [matDatepicker]="validityDatePicker" [min]="minValidityDate">
          <mat-datepicker-toggle matSuffix [for]="validityDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #validityDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select name="status" formControlName="status">
            <mat-option [value]="1">ACTIVE</mat-option>
            <mat-option [value]="0">INACTIVE</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Origin Trade</mat-label>
          <mat-select name="originTrade" formControlName="originTrade" multiple [disabled]="isFieldDisabled('originCountry', 'pol')">
            <mat-option>
              <ngx-mat-select-search formControlName="originTradeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let region of filteredRegions | async" [value]="region.REG_ID">{{region.REG_NAME}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Origin Country</mat-label>
          <mat-select name="originCountry" formControlName="originCountry" multiple [disabled]="isFieldDisabled('originTrade', 'pol')"> 
            <mat-option>
              <ngx-mat-select-search formControlName="originCountryFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>POL</mat-label>
          <mat-select name="pol" formControlName="pol" multiple [disabled]="isFieldDisabled('originTrade', 'originCountry')">
            <mat-option>
              <ngx-mat-select-search formControlName="polFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let port of filteredPorts | async" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Destination Trade</mat-label>
          <mat-select name="destinationTrade" formControlName="destinationTrade" multiple [disabled]="isFieldDisabled('destinationCountry', 'pod')">
            <mat-option>
              <ngx-mat-select-search formControlName="destinationTradeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let region of filteredRegions | async" [value]="region.REG_ID">{{region.REG_NAME}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Destination Country</mat-label>
          <mat-select name="destinationCountry" formControlName="destinationCountry" multiple [disabled]="isFieldDisabled('destinationTrade', 'pod')">
            <mat-option>
              <ngx-mat-select-search formControlName="destinationCountryFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>POD</mat-label>
          <mat-select name="pod" formControlName="pod" multiple [disabled]="isFieldDisabled('destinationTrade', 'destinationCountry')">
            <mat-option>
              <ngx-mat-select-search formControlName="podFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let port of filteredPorts | async" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group spaced">            
        <mat-form-field>
          <mat-label>Freetime</mat-label>
          <input matInput name="freetime" formControlName="freetime" (input)="getBeginDayValue('freetime', 'beginDay1', 'valuePerDay1'); clearPerDiem('freetime', 0);">
        </mat-form-field>
      </div>
      <div *ngIf="defaultRuleForm.get('freetime').value.trim() && !defaultRuleForm.get('freetime').invalid">
        <div class="form-group-container">
          <h3>1st Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay1" formControlName="beginDay1" readonly>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay1" formControlName="endDay1" (input)="handleEndDayInput('endDay1', 'beginDay2', 'valuePerDay2', 1)" (blur)="checkValidDate('endDay1', 'beginDay1')">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay1" formControlName="valuePerDay1">
            </mat-form-field>
          </div>
        </div>
        <div class="form-group-container" *ngIf="defaultRuleForm.get('endDay1').value.trim() && !defaultRuleForm.get('endDay1').invalid">
          <h3>2nd Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay2" formControlName="beginDay2" readonly>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay2" formControlName="endDay2" (input)="handleEndDayInput('endDay2', 'beginDay3', 'valuePerDay3', 2)" (blur)="checkValidDate('endDay2', 'beginDay2')">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay2" formControlName="valuePerDay2" (blur)="checkValidValuePerDay('valuePerDay2', 'valuePerDay1')">
            </mat-form-field>
          </div>
        </div>
        <div class="form-group-container" *ngIf="defaultRuleForm.get('endDay2').value.trim() && !defaultRuleForm.get('endDay2').invalid">
          <h3>3rd Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay3" formControlName="beginDay3" readonly>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay3" formControlName="endDay3" (input)="handleEndDayInput('endDay3', 'beginDay4', 'valuePerDay4', 3)" (blur)="checkValidDate('endDay3', 'beginDay3')">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay3" formControlName="valuePerDay3" (blur)="checkValidValuePerDay('valuePerDay3', 'valuePerDay2')">
            </mat-form-field>
          </div>
        </div>
        <div class="form-group-container" *ngIf="defaultRuleForm.get('endDay3').value.trim() && !defaultRuleForm.get('endDay3').invalid">
          <h3>4th Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay4" formControlName="beginDay4" readonly>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay4" formControlName="endDay4" (blur)="checkValidDate('endDay4', 'beginDay4')">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay4" formControlName="valuePerDay4" (blur)="checkValidValuePerDay('valuePerDay4', 'valuePerDay3')">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions-group">
    <a mat-button color="accent" [routerLink]="['/agreements/default', defaultRuleType]">Cancel</a>
    <button mat-flat-button type="submit" color="accent" [disabled]="defaultRuleForm.invalid">Save Standard Rule</button>
  </div>
</form>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
<button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar" (click)="openSidebar()" *ngIf="this.defaultRule">
  <mat-icon>menu_open</mat-icon>
</button>