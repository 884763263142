import { IFinancial, IFinancialBudget } from './../../../../../models/financial.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinancialService } from 'src/app/services/financial.service';
import { AuthService } from 'src/app/services/auth.service';

export interface IBudgetDialog {
  element?: IFinancialBudget;
}

@Component({
  selector: 'dhl-budget-dialog',
  templateUrl: './budget-dialog.component.html',
  styleUrls: ['./budget-dialog.component.scss']
})
export class BudgetDialogComponent implements OnInit {
  form: UntypedFormGroup;  
  budgets: Array<IFinancialBudget> = [];
  requestType: number = 0;
  financialYears: Array<IFinancial>;

  readonly numberToMonthMap = new Map([
    [1, 'Jan'],
    [2, 'Feb'],
    [3, 'Mar'],
    [4, 'Apr'],
    [5, 'May'],
    [6, 'Jun'],
    [7, 'Jul'],
    [8, 'Aug'],
    [9, 'Sep'],
    [10, 'Oct'],
    [11, 'Nov'],
    [12, 'Dec']
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBudgetDialog,
    private dialogRef: MatDialogRef<BudgetDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private financialService: FinancialService
  ) { }

  ngOnInit() {
    this.financialService.getFinancialYears().subscribe((years)=>{
      let actualYear: IFinancial = {
        FBF_POST_PERIOD_YEAR: new Date().getFullYear()
      }

      let exists: boolean = years.map(value => value.FBF_POST_PERIOD_YEAR).indexOf(actualYear.FBF_POST_PERIOD_YEAR) > 0;

      if(!exists) years.push(actualYear);

      this.financialYears = years;
    })

    this.form = this.formBuilder.group({
      year: new UntypedFormControl(this.data?.element.BDGT_YEAR, Validators.required),
      budget1: new UntypedFormControl('', Validators.required),
      budget2: new UntypedFormControl('', Validators.required),
      budget3: new UntypedFormControl('', Validators.required),
      budget4: new UntypedFormControl('', Validators.required),
      budget5: new UntypedFormControl('', Validators.required),
      budget6: new UntypedFormControl('', Validators.required),
      budget7: new UntypedFormControl('', Validators.required),
      budget8: new UntypedFormControl('', Validators.required),
      budget9: new UntypedFormControl('', Validators.required),
      budget10: new UntypedFormControl('', Validators.required),
      budget11: new UntypedFormControl('', Validators.required),
      budget12: new UntypedFormControl('', Validators.required)
    });

    this.form.get('year').valueChanges.subscribe((year)=>{
      for (let index = 1; index <= 12; index++) {
        this.form.get('budget'+ index).setValue('');
      }

      this.financialService.getBudget({"BDGT_YEAR": year}).subscribe((budgets)=>{
        this.budgets = budgets;
        this.budgets.map((budget, key)=>{
          this.form.get('budget'+(key+1)).setValue(budget?.BDGT_VALUE);
        });
      });
    });
  }

  close(): void {
    this.dialogRef.close([this.budgets, this.requestType]);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();    
    if(this.budgets.length !== 0){
      this.requestType = 1;
      this.budgets.map((budget, key)=>{
        budget.BDGT_VALUE = this.form.get('budget'+ (key+1)).value,
        budget.BDGT_USER = this.authService.userId
      });
    }else{
      this.requestType = 2;
      this.budgets = [];
      for(const month of this.numberToMonthMap){
        let budget: IFinancialBudget = {
          BDGT_YEAR: this.form.value.year,
          BDGT_MONTH: month[0],
          BDGT_VALUE: this.form.get('budget'+month[0]).value,
          BDGT_USER: this.authService.userId
        }
        this.budgets.push(budget);      
      }
    }

    this.close();   
  }

  getErrorMessage(formControl: string): string {
    if (this.form.get(formControl).hasError('required')) {
      return 'You must enter a value'; 
    } else if (this.form.get(formControl).hasError('pattern')) {
      return 'The value must be a number';   
    } else {
      return '';
    }
  }

}
