import { PagesDeleteAllDialogComponent } from './pages-delete-all-dialog/pages-delete-all-dialog.component';
import { PagesViewDialogComponent } from './pages-view-dialog/pages-view-dialog.component';
import { PageActionDialogComponent } from './page-action-dialog/page-action-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { HotToastModule } from '@ngneat/hot-toast';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PagesDialogComponent } from './pages-dialog/pages-dialog.component';


@NgModule({
  declarations: [
    PagesDialogComponent,
    PageActionDialogComponent,
    PagesViewDialogComponent,
    PagesDeleteAllDialogComponent,
    PagesViewDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HotToastModule,
    NgxMatSelectSearchModule
  ]
})
export class PagesDialogsModule { }
