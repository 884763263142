import { IFinancial, IFinancialYear } from '../../../../../models/financial.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinancialService } from 'src/app/services/financial.service';

export interface IContainerDialog {
  element?: IFinancialYear;
}

@Component({
  selector: 'dhl-container-dialog',
  templateUrl: './container-dialog.component.html',
  styleUrls: ['./container-dialog.component.scss']
})
export class ContainerDialogComponent implements OnInit {
  form: UntypedFormGroup;  
  containers: Array<IFinancialYear> = [];
  requestType: number = 0;
  financialYears: Array<IFinancial>;
  actualMonth: number = new Date().getMonth() + 1;
  actualYear: number = new Date().getFullYear();
  selectedYear: number;

  readonly numberToMonthMap = new Map([
    [1, 'Jan'],
    [2, 'Feb'],
    [3, 'Mar'],
    [4, 'Apr'],
    [5, 'May'],
    [6, 'Jun'],
    [7, 'Jul'],
    [8, 'Aug'],
    [9, 'Sep'],
    [10, 'Oct'],
    [11, 'Nov'],
    [12, 'Dec']
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IContainerDialog,
    private dialogRef: MatDialogRef<ContainerDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private financialService: FinancialService
  ) { }

  ngOnInit() {
    this.financialService.getFinancialYears().subscribe((years)=>{
      let actualYear: IFinancial = {
        FBF_POST_PERIOD_YEAR: this.actualYear
      }

      let exists: boolean = years.map(value => value.FBF_POST_PERIOD_YEAR).indexOf(actualYear.FBF_POST_PERIOD_YEAR) > 0;

      if(!exists) years.push(actualYear);

      this.financialYears = years;
    })

    this.form = this.formBuilder.group({
      year: new UntypedFormControl('', Validators.required),
      imp1: new UntypedFormControl(''),
      imp2: new UntypedFormControl(''),
      imp3: new UntypedFormControl(''),
      imp4: new UntypedFormControl(''),
      imp5: new UntypedFormControl(''),
      imp6: new UntypedFormControl(''),
      imp7: new UntypedFormControl(''),
      imp8: new UntypedFormControl(''),
      imp9: new UntypedFormControl(''),
      imp10: new UntypedFormControl(''),
      imp11: new UntypedFormControl(''),
      imp12: new UntypedFormControl(''),
      exp1: new UntypedFormControl(''),
      exp2: new UntypedFormControl(''),
      exp3: new UntypedFormControl(''),
      exp4: new UntypedFormControl(''),
      exp5: new UntypedFormControl(''),
      exp6: new UntypedFormControl(''),
      exp7: new UntypedFormControl(''),
      exp8: new UntypedFormControl(''),
      exp9: new UntypedFormControl(''),
      exp10: new UntypedFormControl(''),
      exp11: new UntypedFormControl(''),
      exp12: new UntypedFormControl('')
    });

    this.form.get('year').valueChanges.subscribe((year)=>{
      this.selectedYear = year;
      for (let index = 1; index <= 12; index++) {
        this.form.get('imp'+ index).setValue('');
        this.form.get('exp'+ index).setValue('');
      }

      this.financialService.getContainer({FIN_YEAR: year}).subscribe((containers)=>{
        this.containers = containers;
        this.containers.map((container, key)=>{
          this.form.get('imp'+(key+1)).setValue(container?.FIN_IMP);
          this.form.get('exp'+(key+1)).setValue(container?.FIN_EXP);
        });
      });
    });
    
  }

  close(): void {
    this.dialogRef.close([this.containers, this.requestType]);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();    
    this.requestType = 1;
    for(const month of this.numberToMonthMap){    
      if((month[0] - 1) <= this.containers.length - 1){
        this.containers[month[0]-1].FIN_IMP = this.form.get('imp'+ month[0]).value;
        this.containers[month[0]-1].FIN_EXP = this.form.get('exp'+ month[0]).value;
      }else{
        if(this.form.get('imp'+ month[0]).value || this.form.get('exp'+ month[0]).value){
          let container: IFinancialYear = {
            FIN_IMP: this.form.get('imp'+ month[0]).value,
            FIN_EXP: this.form.get('exp'+ month[0]).value,
            FIN_MONTH: month[0],
            FIN_YEAR: this.form.value.year
          }
          this.containers.push(container);
        }
      }
    }
    
    this.close();   
  }

  getErrorMessage(formControl: string): string {
    if (this.form.get(formControl).hasError('required')) {
      return 'You must enter a value'; 
    } else if (this.form.get(formControl).hasError('pattern')) {
      return 'The value must be a number';   
    } else {
      return '';
    }
  }

}
