import { CustomerService } from 'src/app/services/customer.service';
import { IFinancialCustomer, IFinancialTopCustomer, IFinancialYear } from '../../models/financial.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ApexChartOptions } from 'src/app/interfaces/apex-chart.interface';
import { FinancialService } from 'src/app/services/financial.service';
import { CurrencyPipe, formatDate } from '@angular/common';
import { ChartComponent } from 'ng-apexcharts';
import { cloneDeep } from 'lodash';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'dhl-financial-customers-dashboard',
  templateUrl: './financial-customers-dashboard.component.html',
  styleUrls: ['./financial-customers-dashboard.component.scss']
})
export class FinancialCustomersDashboardComponent implements OnInit {
  hideLoader: boolean;
  hideLoaderTop: boolean;
  hideLoaderCus: boolean;
  reportYears: Array<IFinancialYear>;
  customers: Array<IFinancialCustomer> = [];
  filteredCustomers: Array<IFinancialCustomer> = [];

  month = new FormControl([new Date().getMonth() + 1]);
  year = new FormControl(new Date().getFullYear());
  customer = new FormControl(null);
  customerFilter = new FormControl('');

  readonly date = new Date();

  financialCustomerAnualTableDataSource = new MatTableDataSource<IFinancialCustomer>();
  financialCustomerAnualTableDisplayedColumns: Array<string> = [
    'REP_CUS_MONTH',
    'REP_CUS_REV',
    'REP_CUS_COS',
    'REP_CUS_ACT_BRL',
    'REP_CUS_ACT_EUR',
    'REP_CUS_PY_EUR'
  ];

  @ViewChild(MatTable) financialCustomerAnualTable: MatTable<IFinancialCustomer>;

  financialCustomerTableDataSource = new MatTableDataSource<IFinancialCustomer>();
  financialCustomerTableDisplayedColumns: Array<string> = [
    'REP_CUS_NAME',
    'REP_CUS_REV',
    'REP_CUS_COS',
    'REP_CUS_ACT_BRL',
    'REP_CUS_ACT_EUR',
    'REP_CUS_PY_EUR'
  ];  
  @ViewChild(MatTable) financialCustomerTable: MatTable<IFinancialCustomer>;  
  @ViewChild('financialCustomerPaginator') financialCustomerPaginator: MatPaginator;

  financialTopCustomerTableDataSource = new MatTableDataSource<IFinancialTopCustomer>();
  readonly financialTopCustomerTableDisplayedColumns: Array<string> = [
    'TOP_CUS_NAME',
    'TOP_CUS_PDT',
    'TOP_CUS_JAN',
    'TOP_CUS_FEV',
    'TOP_CUS_MAR',
    'TOP_CUS_APR',
    'TOP_CUS_MAY',
    'TOP_CUS_JUN',
    'TOP_CUS_JUL',
    'TOP_CUS_AUG',
    'TOP_CUS_SEP',
    'TOP_CUS_OCT',
    'TOP_CUS_NOV',
    'TOP_CUS_DEC',
    'TOP_CUS_REV'
  ];

  topCustomersColumns: Array<string> = [];

  @ViewChild(MatTable) financialTopCustomerTable: MatTable<IFinancialTopCustomer>;

  readonly colorScheme = [
    '#666666',
    '#FFCC00',
    '#D40511',
    '#000000',
    '#333333',
    '#8c8c8c',
    '#b2b2b2',
    '#e5e5e5',
    '#ebebeb',
    '#f2f2f2'
  ];

  readonly numberToMonthMap = new Map([
    [1, 'January'],
    [2, 'February'],
    [3, 'March'],
    [4, 'April'],
    [5, 'May'],
    [6, 'June'],
    [7, 'July'],
    [8, 'August'],
    [9, 'September'],
    [10, 'October'],
    [11, 'November'],
    [12, 'December']
  ]);

  @ViewChild('mainChart') mainChart: ChartComponent;
  public mainChartOptions: ApexChartOptions = {
    colors: this.colorScheme,
    series: [
      {
        name: 'Past Year GP EUR',
        type: 'column',
        data: []
      },
      {
        name: 'Actual GP EUR',
        type: 'column',
        data: []
      },
    ],
    chart: {
      height: 350,
      type: 'line',
      stacked: false
    },
    stroke: {
      width: [0, 0, 4]
    },
    xaxis: {
      categories: Array.from(this.numberToMonthMap.values())
    },
    yaxis: [
      {
        seriesName: 'Past Year GP EUR',
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: this.colorScheme[0]
        },
        labels: {
          formatter: (y) => this.currencyPipe.transform(y, 'EUR'),
          style: {
            colors: this.colorScheme[0]
          }
        },
        title: {
          text: 'GP EUR',
          style: {
            color: this.colorScheme[0]
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        show: false,
        seriesName: 'Past Year GP EUR',
        labels: {
          formatter: (y) => this.currencyPipe.transform(y, 'EUR'),
          style: {
            colors: this.colorScheme[0]
          }
        }
      }
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: 30
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  };

  openGroups: [boolean, boolean, boolean] = [true, true, true];

  constructor(
    private titleService: Title,
    private financialService: FinancialService,
    private customerService: CustomerService,    
    private exporter: XlsxExporterService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('DHL | Analytics (Customers)');

    this.customerService.getFinancialCustomers().subscribe((customers) => {
      this.customers = customers;
      this.filteredCustomers = customers;
    });

    this.financialService.getReportYear().subscribe((years)=>{
      this.reportYears = years;
    });

    this.getAnualData();
    this.getTopCustomers();
    this.getCustomersData();

    this.year.valueChanges.subscribe(() => {
      this.getAnualData();
      this.getCustomersData();
      this.getTopCustomers();
    });

    this.month.valueChanges.subscribe(() => {
      this.getCustomersData();
    });

    this.customer.valueChanges.subscribe(() => {
      this.getAnualData();
      this.getCustomersData();
      this.getTopCustomers();
    });

    this.customerFilter.valueChanges.subscribe((filter) => {
      this.filteredCustomers = this.customers.filter(value => value.REP_CUS_NAME?.toLowerCase().includes(filter?.toLowerCase()))
    });
  }

  isOverflow(element: string) {
    return element && element.length > 20;
  }

  getAnualData(): void {
    this.financialCustomerAnualTableDataSource.data = [];
    if (!this.financialCustomerAnualTableDataSource.filteredData) this.hideLoader = false;
    
    const filter: IFinancialCustomer = {
      REP_CUS_YEAR: this.year.value,
      REP_CUS_LIST: this.customer.value?.join(';')
    }

    if (this.financialCustomerAnualTableDataSource.filteredData.length === 0) this.hideLoader = true;

    this.financialService.getCustomersYear(filter).subscribe((data) => {      
      this.hideLoader = true;

      const customersData = cloneDeep(data);

      if(data.length > 1){
        const newRow: IFinancialCustomer = customersData.reduce((prev, next) => {
          const element: IFinancialCustomer = {
            REP_CUS_NAME: 'Sum',
            REP_CUS_REV: prev.REP_CUS_REV + next.REP_CUS_REV,
            REP_CUS_COS: prev.REP_CUS_COS + next.REP_CUS_COS,
            REP_CUS_ACT_BRL: prev.REP_CUS_ACT_BRL + next.REP_CUS_ACT_BRL,
            REP_CUS_ACT_EUR: prev.REP_CUS_ACT_EUR + next.REP_CUS_ACT_EUR,
            REP_CUS_PY_EUR: prev.REP_CUS_PY_EUR + next.REP_CUS_PY_EUR
          }
          return element;
        });
  
        customersData.push(newRow);
      }

      this.financialCustomerAnualTableDataSource.data = customersData;
      this.mainChart.updateSeries([
        { data: data.map(value => Number(value?.REP_CUS_PY_EUR) || 0) },
        { data: data.map(value => Number(value?.REP_CUS_ACT_EUR) || 0) }
      ]);     
    });
  }

  getCustomersData(): void {
    this.financialCustomerTableDataSource.data = [];
    if (!this.financialCustomerTableDataSource.filteredData) this.hideLoader = true;
    
    const filter: IFinancialCustomer = {
      REP_CUS_YEAR: this.year.value,
      REP_MONTH_LIST: this.month.value?.join(','),
      REP_CUS_LIST: this.customer.value?.join(';')
    }

    if (this.financialTopCustomerTableDataSource.filteredData.length === 0) this.hideLoaderCus = true;
    this.financialService.getCustomers(filter).subscribe((data) => {
      this.hideLoaderCus = true;

      const customersData = cloneDeep(data);

      if(data.length > 1){
        const newRow: IFinancialCustomer = customersData.reduce((prev, next) => {
          const element: IFinancialCustomer = {
            REP_CUS_NAME: 'Sum',
            REP_CUS_REV: prev.REP_CUS_REV + next.REP_CUS_REV,
            REP_CUS_COS: prev.REP_CUS_COS + next.REP_CUS_COS,
            REP_CUS_ACT_BRL: prev.REP_CUS_ACT_BRL + next.REP_CUS_ACT_BRL,
            REP_CUS_ACT_EUR: prev.REP_CUS_ACT_EUR + next.REP_CUS_ACT_EUR,
            REP_CUS_PY_EUR: prev.REP_CUS_PY_EUR + next.REP_CUS_PY_EUR
          }
          return element;
        });
  
        customersData.push(newRow);
      }

      this.financialCustomerTableDataSource.data = customersData;
      this.financialCustomerTableDataSource.paginator = this.financialCustomerPaginator;
    });
  }

  getTopCustomers(): void {
    this.financialTopCustomerTableDataSource.data = [];

    const filter: IFinancialTopCustomer = {
      TOP_CUS_YEAR: this.year.value,
      TOP_CUS_LIST: this.customer.value?.join(';')
    }

    if (this.financialTopCustomerTableDataSource.filteredData.length === 0) this.hideLoaderTop = true;
    this.financialService.getTopCustomers(filter).subscribe((data) => {
      this.hideLoaderTop = true;

      const topCustomersData = cloneDeep(data);
      
      if(data.length > 1){
        const newRow: IFinancialTopCustomer = topCustomersData.reduce((prev, next) => {
          const element: IFinancialTopCustomer = {
            TOP_CUS_NAME: 'Sum',
            TOP_CUS_PDT: '',
            TOP_CUS_JAN: prev.TOP_CUS_JAN + next.TOP_CUS_JAN,
            TOP_CUS_FEV: prev.TOP_CUS_FEV + next.TOP_CUS_FEV,
            TOP_CUS_MAR: prev.TOP_CUS_MAR + next.TOP_CUS_MAR,
            TOP_CUS_APR: prev.TOP_CUS_APR + next.TOP_CUS_APR,
            TOP_CUS_MAY: prev.TOP_CUS_MAY + next.TOP_CUS_MAY,
            TOP_CUS_JUN: prev.TOP_CUS_JUN + next.TOP_CUS_JUN,
            TOP_CUS_JUL: prev.TOP_CUS_JUL + next.TOP_CUS_JUL,
            TOP_CUS_AUG: prev.TOP_CUS_AUG + next.TOP_CUS_AUG,
            TOP_CUS_SEP: prev.TOP_CUS_SEP + next.TOP_CUS_SEP,
            TOP_CUS_OCT: prev.TOP_CUS_OCT + next.TOP_CUS_OCT,
            TOP_CUS_NOV: prev.TOP_CUS_NOV + next.TOP_CUS_NOV,
            TOP_CUS_DEC: prev.TOP_CUS_DEC + next.TOP_CUS_DEC,
            TOP_CUS_REV: prev.TOP_CUS_REV + next.TOP_CUS_REV
          }
          return element;
        });
        
        topCustomersData.push(newRow);
      }

      this.topCustomersColumns = this.financialTopCustomerTableDisplayedColumns;
      this.financialTopCustomerTableDataSource.data = topCustomersData;
    });
  }

  getSelectedMonthsString(): string {
    return this.month.value.map(value => this.numberToMonthMap.get(value)).join(', ')
  }

  exportTopCustomers(): void {
    const headers = {
      TOP_CUS_YEAR: 'YEAR',
      TOP_CUS_NAME: 'CUSTOMER',
      TOP_CUS_PDT: 'PRODUCT',
      TOP_CUS_JAN: 'JANUARY',
      TOP_CUS_FEV: 'FEBRUARY',
      TOP_CUS_MAR: 'MARCH',
      TOP_CUS_APR: 'APRIL',  
      TOP_CUS_MAY: 'MAY',
      TOP_CUS_JUN: 'JUNE',
      TOP_CUS_JUL: 'JULY',
      TOP_CUS_AUG: 'AUGUST',
      TOP_CUS_SEP: 'SEPTEMBER',
      TOP_CUS_OCT: 'OCTOBER',
      TOP_CUS_NOV: 'NOVEMBER',
      TOP_CUS_DEC: 'DECEMBER',
      TOP_CUS_REV: 'REVENUE TOTAL'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IFinancialTopCustomer> = [...this.financialTopCustomerTableDataSource.filteredData];
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `financialCustomerDashboard(TopCustomers)_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  exportAnualPerformance(): void {
    const headers = {
      REP_CUS_YEAR: 'YEAR',
      REP_CUS_MONTH: 'MONTH',
      REP_CUS_REV: 'REVENUE (BRL)',
      REP_CUS_COS: 'COST (BRL)',
      REP_CUS_ACT_BRL: 'ACTUAL GP (BRL)',
      REP_CUS_ACT_EUR: 'ACTUAL GP (EUR)',
      REP_CUS_PY_EUR: 'PAST YEAR GP (EUR)'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IFinancialCustomer> = [...this.financialCustomerAnualTableDataSource.filteredData];
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `financialCustomerDashboard(AnualPerformance)_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  exportCustomersPerformance(): void {
    const headers = {
      REP_CUS_YEAR: 'YEAR',
      REP_CUS_NAME: 'CUSTOMER',
      REP_CUS_REV: 'REVENUE (BRL)',
      REP_CUS_COS: 'COST (BRL)',
      REP_CUS_ACT_BRL: 'ACTUAL GP (BRL)',
      REP_CUS_ACT_EUR: 'ACTUAL GP (EUR)',
      REP_CUS_PY_EUR: 'PAST YEAR GP (EUR)'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IFinancialCustomer> = [...this.financialCustomerTableDataSource.filteredData];
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `financialCustomerDashboard(CustomersPerformance)_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }
}
