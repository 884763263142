import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { IPayableDuplicate, IPayableImport } from 'src/app/models/payable.model';
import { PayableService } from 'src/app/services/payable.service';
import { cloneDeep } from 'lodash';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dhl-import-payable-dialog',
  templateUrl: './import-payable-dialog.component.html',
  styleUrls: ['./import-payable-dialog.component.scss']
})
export class ImportPayableDialogComponent implements OnInit {
  protected bucketName = environment.bucketName;
  templateUrl = `https://${this.bucketName}.s3.amazonaws.com/template/payables-template.xlsx`;
  
  files: Array<File> = [];
  duplicates: Array<IPayableDuplicate> = [];
  dataSource = new MatTableDataSource<IPayableImport>([]);
  displayedColumns: Array<string> = [
    'carrier',
    'invoiceNumbers',
    'emissionDate',
    'expirationDate',
    'mbl',
    'booking',
    'container',
    'equipmentType',
    'contract',
    'pol',
    'pod',
    'totalDays',
    'freeDays',
    'chargeDays',
    'startDate',
    'endDate',
    'value',
    'importStatus'
  ];

  types: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  ];

  canImport: boolean = true;
  countInvoices: number = 0;

  @ViewChild(MatTable) table: MatTable<IPayableImport>;
  @ViewChild('sort') sort: MatSort;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ImportPayableDialogComponent>,
    private payableService: PayableService,
    private authService: AuthService,
    private toast: HotToastService
  ) { }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
    this.dataSource.sort = this.sort;
  }

  onSelect(e: NgxDropzoneChangeEvent): void {
    this.files.push(...e.addedFiles);

    const reader = new FileReader();
    const file = this.files[0];

    reader.onload = () => {
      const data = reader.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const excelData = workbook.SheetNames.reduce((initial: any, name: any) => {
        return XLSX.utils.sheet_to_json(workbook.Sheets[name]);
      }, {});

      var sheet_name_list = workbook.SheetNames;
      let columnHeaders = [];
      for (var sheetIndex = 0; sheetIndex < sheet_name_list.length; sheetIndex++) {
          var worksheet = workbook.Sheets[sheet_name_list[sheetIndex]];
          for (let key in worksheet) {
              let regEx = new RegExp("^\(\\w\)\(1\){1}$");
              if (regEx.test(key) == true) {
                  columnHeaders.push(worksheet[key].v);
              }
          }
      }

      const payableHeaders = [
        'CARRIER',
        'INVOICE_NUMBERS',
        'EMISSION_DATE',
        'EXPIRATION_DATE',
        'MBL',
        'BOOKING',
        'CONTAINER',
        'EQUIPMENT_TYPE',
        'CONTRACT',
        'POL',
        'POD',
        'TOTAL_DAYS',
        'FREETIME',
        'CHARGE_DAYS',
        'START_DATE',
        'END_DATE',
        'VALUE'
      ];
      
      let validFile = true;

      if(JSON.stringify(payableHeaders)!==JSON.stringify(columnHeaders)){
        validFile = false;
      }
      
      if (!validFile) {
        this.files = [];
        this.dataSource.data = [];
        this.toast.error('File is not a valid Payable spreadsheet');
        return;
      }else{
        const jsonData: IPayableImport[] = excelData.map((data: any) => {
          return {
            PB_CAR: data.CARRIER,
            PB_INV_NUM: data.INVOICE_NUMBERS,
            PB_EMIS_DT: Number(data.EMISSION_DATE) ? new Date(new Date(Date.UTC(0, 0, data.EMISSION_DATE)).setHours(0,0,0,0)) : null,
            PB_EXP_DT: Number(data.EXPIRATION_DATE) ? new Date(new Date(Date.UTC(0, 0, data.EXPIRATION_DATE)).setHours(0,0,0,0)) : null,
            PB_MBL: data.MBL,
            PB_BK: data.BOOKING,
            PB_CTN: (() => {
              const container = data.CONTAINER.replace(/\s+/g, '');
              const regex = /^[A-Za-z]{4}\d{7}$/;
              return regex.test(container) ? container : null;
            })(),
            PB_CTN_TYPE: data.EQUIPMENT_TYPE,
            PB_CONT: data.CONTRACT,
            PB_POL: data.POL,
            PB_POD: data.POD,
            PB_TL_DAYS: this.isNumberValid(data.TOTAL_DAYS) ? Number(data.TOTAL_DAYS) : null,
            PB_FRT: this.isNumberValid(data.FREETIME) ? Number(data.FREETIME) : null,
            PB_CH_DAYS: this.isNumberValid(data.CHARGE_DAYS) ? Number(data.CHARGE_DAYS) : null,
            PB_ST_DT: Number(data.START_DATE) ? new Date(new Date(Date.UTC(0, 0, data.START_DATE)).setHours(0,0,0,0)) : null,
            PB_END_DT: Number(data.END_DATE) ? new Date(new Date(Date.UTC(0, 0, data.END_DATE)).setHours(0,0,0,0)) : null,
            PB_VAL_USD: Number(data.VALUE) || null,
            PB_PRC_ST: '0',
            PB_PRC_SUB_ST: '0',
            IS_VALID: 0
          }          
        });

        this.countInvoices = jsonData.length;

        jsonData.forEach((data) => {       
          if (          
            (data.PB_EMIS_DT && !this.isDateValid(data.PB_EMIS_DT)) ||
            (data.PB_EXP_DT && !this.isDateValid(data.PB_EXP_DT)) ||
            (data.PB_TL_DAYS && !this.isNumberValid(data.PB_TL_DAYS)) ||
            !this.isNumberValid(data.PB_FRT) ||
            (data.PB_CH_DAYS && !this.isNumberValid(data.PB_CH_DAYS)) ||
            (data.PB_ST_DT && !this.isDateValid(data.PB_ST_DT)) ||
            (data.PB_END_DT && !this.isDateValid(data.PB_END_DT)) ||
            !this.isNumberValid(data.PB_VAL_USD) ||
            !data.PB_CAR ||
            !data.PB_INV_NUM ||
            !data.PB_CTN
          ) {
            data.IS_VALID = 1;
            this.canImport = false;
          }
        });

        this.payableService.checkDuplicate({ PB_INV_LIST: jsonData.map((item)=> item.PB_INV_NUM + ';' + item.PB_CTN).join('|') }).subscribe((response: Array<IPayableDuplicate>)=>{
          if(response.length > 0){
            jsonData.map((item)=>{
              response.forEach((dup)=>{
                if(dup.PB_INV_NUM == item.PB_INV_NUM && dup.PB_CTN == item.PB_CTN) item.IS_VALID = 2;
              })
            });

            this.canImport = false;
          }

          this.dataSource.data = jsonData;
        })   
      }
    };

    reader.readAsBinaryString(file);
  }

  onRemove(file: File): void {
    this.files.splice(this.files.indexOf(file), 1);
    this.canImport = true;
  }

  close(): void {
    this.dialogRef.close();
  }

  import(): void {
    const data: Array<IPayableImport> = cloneDeep(this.dataSource.data);
    data.forEach((item) => {
      delete item.IS_VALID;
      item.PB_USER = this.authService.userId;
    });

    const ref = this.toast.loading('Saving...',{autoClose: false});
    this.payableService.postAll(data).subscribe((response) => {
      ref.close();
      this.toast.success(String(response));
      this.close();
    }, (error) => {
      this.toast.error(error.error.Message);
    });
  }

  isDateValid(value: any): boolean {
    return !isNaN(value) && value instanceof Date;
  }

  isNumberValid(value: number): boolean {
    return typeof value === 'number' && !isNaN(value);
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }
}
