import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICusNotifParam } from 'src/app/models/parameter.model';
import { AuthService } from 'src/app/services/auth.service';

export interface ICustomerNotificationDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  customerNotification?: ICusNotifParam;
}

@Component({
  selector: 'dhl-customer-notification-dialog',
  templateUrl: './customer-notification-dialog.component.html',
  styleUrls: ['./customer-notification-dialog.component.scss']
})
export class CustomerNotificationDialogComponent implements OnInit {
  customerNotificationForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  
  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CustomerNotificationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICustomerNotificationDialog, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit() {
    this.customerNotificationForm = this.formBuilder.group({
      code: new UntypedFormControl(this.data.customerNotification?.NOTIF_PARAM_CODE, [Validators.required]),
      name: new UntypedFormControl(this.data.customerNotification?.NOTIF_PARAM_NAME, [Validators.required]),
      value: new UntypedFormControl(this.data.customerNotification?.NOTIF_PARAM_VALUE, [Validators.required])
    });

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.customerNotificationForm.disable();
    }
  }

  closeDialog(customerNotification: ICusNotifParam) {
    this.dialogRef.close(customerNotification);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.customerNotificationForm.enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.customerNotificationForm.value;
    let newCustomerNotification: ICusNotifParam;

    if (this.data.mode == 'add') {
      newCustomerNotification = {
        NOTIF_PARAM_CODE: formValue.code,
        NOTIF_PARAM_NAME: formValue.name,
        NOTIF_PARAM_VALUE: formValue.value,
        NOTIF_PARAM_USER: this.authService.userId
      }
    } else if (this.data.mode == 'edit') {
      newCustomerNotification = {
        NOTIF_PARAM_ID: this.data.customerNotification.NOTIF_PARAM_ID,
        NOTIF_PARAM_CODE: formValue.code,
        NOTIF_PARAM_NAME: formValue.name,
        NOTIF_PARAM_VALUE: formValue.value,
        NOTIF_PARAM_USER: this.authService.userId
      }
    } else if (this.data.mode == 'delete') {
      newCustomerNotification = {
        NOTIF_PARAM_ID: this.data.customerNotification.NOTIF_PARAM_ID,
        NOTIF_PARAM_USER: this.authService.userId
      }
    }

    this.closeDialog(newCustomerNotification);
  }

  getCodeErrorMessage(): string {
    if (this.customerNotificationForm.get('code').hasError('required')) {
      return 'You must enter a code';
    } else {
      return '';
    }
  }

  getNameErrorMessage(): string {
    if (this.customerNotificationForm.get('name').hasError('required')) {
      return 'You must enter a name';
    } else {
      return '';
    }
  }

  getValueErrorMessage(): string {
    if (this.customerNotificationForm.get('value').hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

}
