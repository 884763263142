import { AttachmentService } from 'src/app/services/attachment.service';
import { HotToastService } from '@ngneat/hot-toast';
import { AgreementDeleteAllDialogComponent } from './agreements-dialogs/agreement-delete-all-dialog/agreement-delete-all-dialog.component';
import { formatDate, TitleCasePipe } from '@angular/common';
import { IAgreement, IAgreementList } from '../../models/agreement.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { XlsxExporterService } from 'mat-table-exporter';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild, ChangeDetectorRef, inject } from '@angular/core';
import { AgreementService } from '../../services/agreement.service';
import { AgreementDeleteDialogComponent } from './agreements-dialogs/agreement-delete-dialog/agreement-delete-dialog.component';
import { Title } from '@angular/platform-browser';
import { MatTableFilter } from 'mat-table-filter';
import { AgreementFilterDialogComponent, SelectionFilter } from './agreements-dialogs/agreement-filter-dialog/agreement-filter-dialog.component';
import { FilterService } from 'src/app/services/filter.service';
import { CommentDialogComponent, ICommentDialog } from './agreements-dialogs/comment-dialog/comment-dialog.component';
import { IComment, ICommentRef } from 'src/app/models/comment.model';
import { AttachmentDialogComponent, IAttachmentDialog } from './agreements-dialogs/attachment-dialog/attachment-dialog.component';
import { IAttachment, IAttachmentRef } from 'src/app/models/attachment.model';
import { CommentService } from 'src/app/services/comment.service';
import { AwsService } from 'src/app/services/aws.service';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { ITimeline } from 'src/app/models/timeline.model';
import { TimelineService } from 'src/app/services/timeline.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {
  pageKey = '';
  hideLoader: boolean;
  agreementType: string;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IAgreement>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl('');

  filterEntity: IAgreement = {
    DIM_SK_CUS_RUL: null,
    DIM_CUS_CODE: null,
    DIM_CUS_GRP: null,
    DIM_STATUS_RUL: null,
    DIM_CAR: null,
    DIM_SERVICE: null,
    DIM_DESCRIPTION: null,
    DIM_CTN_TYPE: null,
    DIM_DOSIERPF_BHFFTX: null,
    DIM_ORIGIN: null,
    DIM_ORIGIN_FIELD: null,
    DIM_DESTINATION: null,
    DIM_DESTINATION_FIELD: null,
    DIM_ST_START_DT: null,
    DIM_ST_FINAL_DT: null,
    DIM_VAL_START_DT: null,
    DIM_VAL_FINAL_DT: null,
    DIM_INC_START_DT: null,
    DIM_INC_FINAL_DT: null,
  };

  selectionFilter: SelectionFilter = {
    customer: null,
    origin: null,
    destination: null
  }
  
  filtersNumber: number = 0;

  agreementTableDataSource = new MatTableDataSource<IAgreement>();
  agreementExportDataSource = new MatTableDataSource<IAgreement>();
  filteredAgreementTableDataSource = new MatTableDataSource<IAgreement>();
  agreementTableDisplayedColumns: Array<string> = ['select', 'DIM_SK_CUS_RUL', 'DIM_DOSIERPF_BHFFTX', 'DIM_DESCRIPTION','DIM_NAC_FAK','DIM_SERVICE', 'DIM_CAR', 'DIM_CUS_NAME', 'DIM_ORIGIN', 'DIM_DESTINATION', 'DIM_CTN_TYPE', 'DIM_VL_FRT_AMT', 'DIM_PERDIEM', 'DIM_DT_STR_EFF', 'DIM_DT_FIN_EFF', 'DIM_STATUS_RUL', 'DIM_CMT_ATCH', 'actions'];

  @ViewChild(MatTable) agreementTable: MatTable<IAgreement>;
  @ViewChild('agreementSort') agreementSort: MatSort;
  @ViewChild('agreementPaginator') agreementPaginator: MatPaginator;

  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  agreementId: number;

  agreementTitle: string;

  mySubscription;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    public dialog: MatDialog,
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private exporter: XlsxExporterService,
    private agreementService: AgreementService,
    private awsService: AwsService,
    private attachmentService: AttachmentService,
    private authService: AuthService,
    private commentService: CommentService,
    private toast: HotToastService,
    private filterService: FilterService,
    private timelineService: TimelineService,
    private titleCase: TitleCasePipe,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false; 
  }
  
  ngOnInit() {    
    this.route.url.subscribe((url) => {
      // defines pageKey as 'agreementsPurchase' or 'agreementsSale'
      this.pageKey = url[0].path + url[1].path.slice(0, 1).toUpperCase() + url[1].path.slice(1);
      this.globalFilter.setValue(this.filterService.getSearch(this.pageKey) || '');
    });

    this.route.params.subscribe((params) => {
      this.agreementType = params.type;
      this.agreementTitle = this.agreementType
    });
    
    this.titleService.setTitle(`DHL | Agreement (${this.titleCase.transform(this.agreementTitle)})`);
        
    const filter = this.filterService.getFilter('agreement' + this.agreementType);

    if (filter) {
      this.filterEntity = filter[0];
      this.selectionFilter = filter[1];
      this.filtersNumber = this.filterService.getFilterQuantity(filter[0]);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getAgreements(this.filterEntity);
    });

    this.getAgreements(this.filterEntity);
  }

  getAgreements(filter: IAgreement): void {    
    let agreementsType: IAgreement = {
      DIM_TYPE: this.agreementType,
      DIM_RUL_DEF: 0
    };

    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredAgreementTableDataSource.data.length === 0) this.hideLoader = false;
    this.agreementService.getAgreements({
      ...filter,
      ...agreementsType,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((agreements: Array<IAgreement>) => {
      this.hideLoader = true;      
      this.agreementTableDataSource.data = agreements;
      this.filteredAgreementTableDataSource.data = agreements;
      this.length = agreements[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredAgreementTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.filteredAgreementTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAgreements(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getAgreements(this.filterEntity);
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(AgreementDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {
        let agreementsIds: Array<number> = [];
        this.selection.selected.forEach((agreement) => {
          agreementsIds.push(+agreement.DIM_SK_CUS_RUL);
        });

        let agreements: IAgreementList = {
          DIM_SK_CUS_RUL: agreementsIds
        }

        this.agreementService.deleteAgreements(agreements).subscribe((response) => {
          this.getAgreements(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  deleteAgreement(data: IAgreement) {
    const dialogRef = this.dialog.open(AgreementDeleteDialogComponent, {
      data: {
        id: data.DIM_SK_CUS_RUL
      }
    });

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {

        let agreementsIds: Array<number> = [];

        agreementsIds.push(+data.DIM_SK_CUS_RUL);

        let agreements: IAgreementList = {
          DIM_SK_CUS_RUL: agreementsIds,
          DIM_RUL_USER: this.authService.userId
        }

        this.agreementService.deleteAgreements(agreements).subscribe((response) => {
          this.getAgreements(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  insertComment(data: ICommentDialog): void {
    const dialog = this.dialog.open(CommentDialogComponent, { data });

    dialog.afterClosed().subscribe(([agreements, comment]: [Array<IAgreement>, string]) => {
      if (agreements.length === 0) {
        return;
      }

      if (comment) {
        const commentRefs: Array<ICommentRef> = [];

        agreements.forEach(agreement => {
          commentRefs.push({
            REG_ID: Number(agreement.DIM_SK_CUS_RUL),
            COMM_REF_GROUP: 'agreement',
            COMM_REF_USER: this.authService.userId
          });
        });

        const newComment: IComment = {
          COMM_MESSAGE: comment,
          COMM_USER: this.authService.userId,
          COMM_REF: commentRefs
        }

        this.commentService.postComment(newComment).subscribe((response) => {
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  insertAttachment(data: IAttachmentDialog): void {
    const dialog = this.dialog.open(AttachmentDialogComponent, { data });

    dialog.afterClosed().subscribe(([agreements, files]: [Array<IAgreement>, Array<File>]) => {
      if (agreements.length === 0 || files.length === 0) {
        return;
      }

      const attachmentRefs: Array<IAttachmentRef> = [];

      agreements.forEach(agreement => {
        attachmentRefs.push({
          REG_ID: Number(agreement.DIM_SK_CUS_RUL)
        });
      });

      this.awsService
        .uploadFiles(files, 'agreement', attachmentRefs)
        .pipe(this.toast.observe({
          loading: 'Uploading files...',
          success: () => 'Files uploaded successfully.',
          error: (e) => `Error uploading files: ${e}`
        }))
        .subscribe((attachments) => {
          this.attachmentService.postAttachment(attachments).subscribe((response) => {
            this.toast.success(String(response));
          }, (error) => {
            this.toast.error(error.error.Message)
          });
        });
    });
  }

  export(): void {
    const headers = {
      DIM_AGREEMENT_TEXT: 'AGREEMENT',
      DIM_SK_CUS_RUL: 'DDM AGREEMENT',
      DIM_DESCRIPTION: 'AGREEMENT TYPE',
      DIM_NAC_FAK: 'FAK / NAC',
      DIM_DOSIERPF_BHFFTX: 'HOUSE BILL / MASTER BILL / SHIPMENT',
      DIM_SERVICE: 'SERVICE',
      DIM_CAR: 'CARRIER',
      DIM_CUS_NAME: 'CUSTOMER',
      DIM_ORIGIN: 'ORIGIN',
      DIM_DESTINATION: 'DESTINATION',
      DIM_CTN_TYPE: 'TYPE',
      DIM_VL_FRT_AMT: 'FREETIME',
      DIM_PERDIEM: 'PERDIEM',
      DIM_DT_STR_EFF_TEXT: 'START DATE',
      DIM_DT_FIN_EFF_TEXT: 'VALIDITY DATE',
      DIM_DT_BID_EXP_TEXT: 'BID EXPIRATION',
      DIM_DGF: 'DHL CONTRACT',
      DIM_CONTRACT: 'CARRIER CONTRACT',
      DIM_REQUESTED: 'REQUESTED BY',
      DIM_APPROVED: 'APPROVED BY',
      DIM_STATUS_RUL: 'AGREEMENT STATUS',
      DIM_DT_INC_TEXT: 'CREATION DATE',
      DIM_RUL_CMT: 'COMMENT',
      DIM_RUL_USER: 'USER'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.agreementService.getAgreements({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder,
          DIM_TYPE: this.agreementType,
          DIM_RUL_DEF: 0
      }).subscribe((dataExport)=>{
        this.agreementExportDataSource.data = dataExport;

        const data: Array<IAgreement> = this.prepareDataToExport([...this.agreementExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `${this.agreementType}Agreements_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    } else {
      const data: Array<IAgreement> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `${this.agreementType}Agreements_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IAgreement>): Array<IAgreement> {
    const dataToExport: Array<IAgreement> = [];

    const datesText = {
      DIM_AGREEMENT_TEXT: this.agreementType.toUpperCase(),
      DIM_DT_STR_EFF_TEXT: '',
      DIM_DT_FIN_EFF_TEXT: '',
      DIM_DT_BID_EXP_TEXT: '',
      DIM_DT_INC_TEXT: ''
    }

    data.forEach((agreement) => {
      const newData = {...agreement, ...datesText};

      if(newData.DIM_DT_STR_EFF !== null) {
        newData.DIM_DT_STR_EFF_TEXT = moment(newData.DIM_DT_STR_EFF).format('DD/MM/YYYY');
      }

      if(newData.DIM_DT_FIN_EFF !== null) {
        newData.DIM_DT_FIN_EFF_TEXT = moment(newData.DIM_DT_FIN_EFF).format('DD/MM/YYYY');
      }

      newData.DIM_DT_BID_EXP_TEXT = newData.DIM_DT_BID_EXP ? moment(newData.DIM_DT_BID_EXP).format('DD/MM/YYYY') : null;
      newData.DIM_DT_INC_TEXT = newData.DIM_DT_INC ? moment(newData.DIM_DT_INC).format('DD/MM/YYYY') : null;

      delete newData.DIM_DT_STR_EFF;
      delete newData.DIM_DT_FIN_EFF;
      delete newData.DIM_DT_BID_EXP;
      delete newData.DIM_DT_INC;
      //delete newData.DIM_RUL_USER;
      delete newData.DIM_RUL_DEF_NAM;
      delete newData.DIM_STATUS;
      delete newData.DIM_TYPE;
      delete newData.DIM_CMT_ATCH;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(AgreementFilterDialogComponent, {
      data: {
        element: this.filterEntity,
        selectionFilter: this.selectionFilter
      }
    });

    dialog.afterClosed().subscribe(([filters, field]) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity = filters;

      this.selectionFilter.customer = field.customer;
      this.selectionFilter.origin = field.origin;
      this.selectionFilter.destination = field.destination;

      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;
      
      this.filterService.setFilter('agreement' + this.agreementType, [this.filterEntity, this.selectionFilter]);

      this.getAgreements(this.filterEntity);
    });
  }

  openSidebar(agreementId: number): void {
    this.agreementId = agreementId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';    
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `agreement`, REG_ID: this.agreementId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.agreementId),
          COMM_REF_GROUP: `agreement`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'agreement', REG_ID: this.agreementId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'agreement', [{ REG_ID: this.agreementId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'agreement', TIMELINE_REG_ID: this.agreementId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }
}
