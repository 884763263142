import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dhl-agreement-dialog-purchase-sale',
  templateUrl: './agreement-dialog-purchase-sale.component.html',
  styleUrls: ['./agreement-dialog-purchase-sale.component.scss']
})
export class AgreementDialogPurchaseSaleComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AgreementDialogPurchaseSaleComponent>
  ) { }

  ngOnInit() {
  }

  closeDialog(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }

}
