<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="containerTypeForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <div class="form-group-row">
        <mat-form-field>
          <mat-label>Code</mat-label>
          <input matInput type="text" name="code" maxlength="7" formControlName="code" required>
          <mat-error *ngIf="containerTypeForm.get('code').invalid">{{getContainerTypeErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>TEUS Quantity</mat-label>
          <input matInput type="text" name="teus" maxlength="1" formControlName="teus" required>
          <mat-error *ngIf="containerTypeForm.get('teus').invalid">{{getTeusErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput type="text" name="description" maxlength="70" formControlName="description" required>
        <mat-error *ngIf="containerTypeForm.get('description').invalid">{{getDescriptionErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the container type {{data.containerType.CONTYPPF_QAMPZ}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="areInputsValid()">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="areInputsValid()">Ok</button>
    </div>
  </form>
</div>