import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IPrtlCtn } from '../models/prtl-ctn';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrtlCtnService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };


  /*getPrtlCtn(container: IPrtlCtn): Observable<Array<IPrtlCtn>> {
    return this.httpClient.get<Array<IPrtlCtn>>(`${this.apiURL}/api/prtl-ctn?filter=${JSON.stringify(container)}`, this.httpOptions);
  }*/

  getPrtlCtn(filter: IPrtlCtn = {}): Observable<Array<IPrtlCtn>> {
    return this.httpClient.get<Array<IPrtlCtn>>(`${this.apiURL}/api/prtl-ctn?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  post(container: IPrtlCtn): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/prtl-ctn/`, container, this.httpOptions);
  }

  put(container: IPrtlCtn): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/prtl-ctn/`, container, this.httpOptions);
  }

  getCtnFrt(filter: IPrtlCtn): Observable<number>{
    return this.httpClient.get<number>(`${this.apiURL}/api/prtl-ctn/freetime?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }
}
