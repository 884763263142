import { ContainerDialogComponent } from './financial-dialogs/financial-dialogs/container-dialog/container-dialog.component';
import { BudgetDialogComponent } from './financial-dialogs/financial-dialogs/budget-dialog/budget-dialog.component';
import { RateDialogComponent } from './financial-dialogs/financial-dialogs/rate-dialog/rate-dialog.component';
import { ImportFinancialDialogComponent } from './financial-dialogs/financial-dialogs/import-financial-dialog/import-financial-dialog.component';
import { ICustomer } from './../../models/customer.model';
import { IFinancial, IFinancialBudget, IFinancialRate } from './../../models/financial.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { HotToastService, Toast } from '@ngneat/hot-toast';
import { XlsxExporterService } from 'mat-table-exporter';
import { FilterService } from 'src/app/services/filter.service';
import { FinancialService } from 'src/app/services/financial.service';
import { MatTableFilter } from 'mat-table-filter';
import { ICarrier } from 'src/app/models/parameter.model';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { FilterDialogComponent } from './financial-dialogs/financial-dialogs/filter-dialog/filter-dialog.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dhl-financial-list',
  templateUrl: './financial-list.component.html',
  styleUrls: ['./financial-list.component.scss']
})
export class FinancialListComponent implements OnInit {
  pageKey = 'financialList';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IFinancial>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IFinancial = {
    FBF_JOB_NUM: null,
    FBF_CHARGE_CODE: null,
    FBF_TRN_DEPT: null,
    FBF_LINE_TYPE: null,
    FBF_POST_PERIOD: null,
    FBF_POST_PERIOD_YEAR: null,
    FBF_CLIENT_NAME: null
  };
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;

  financialTableDataSource = new MatTableDataSource<IFinancial>();
  filteredFinancialTableDataSource = new MatTableDataSource<IFinancial>();
  financialTableDisplayedColumns: Array<string> = [
    'select',
    'FBF_JOB_NUM',
    'FBF_CHARGE_CODE',
    'FBF_TRN_DEPT',
    'FBF_LINE_TYPE',
    'FBF_LOCAL_AMOUNT',
    'FBF_TRN_ORG_NAME',
    'FBF_JOB_OPEN_DT',
    'FBF_HAWB',
    'FBF_MAWB',
    'FBF_POST_PERIOD',
    'FBF_POST_PERIOD_YEAR'
  ];

  customerList: Array<ICustomer> = []
  carrierList: Array<ICarrier> = []

  @ViewChild(MatTable) financialTable: MatTable<IFinancial>;
  @ViewChild('financialSort') financialSort: MatSort;
  @ViewChild('financialPaginator') financialPaginator: MatPaginator;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private exporter: XlsxExporterService,
    private financialService: FinancialService,
    private filterService: FilterService,
    private toast: HotToastService,
  ) {
    this.titleService.setTitle('DHL | Analytics (FRP List)');
   }

  ngOnInit(): void {    
    const filter = this.filterService.getFilter('financial');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }
    
    this.getFinancials(this.filterEntity);

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.setGlobalFilter(value);
    });
  }

  getFinancials(filter: IFinancial): void{
    this.financialTableDataSource.filteredData = [];
    if (this.financialTableDataSource.filteredData.length === 0) this.hideLoader = false;
    this.financialService.getFilter(filter).subscribe((financials) => {
      this.hideLoader = true;
      this.financialTableDataSource.data = financials;
      this.changeDetectorRef.detectChanges();
      this.financialTableDataSource.sort = this.financialSort;
      this.financialTableDataSource.paginator = this.financialPaginator;

      this.setGlobalFilter(this.globalFilter.value);
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredFinancialTableDataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? (
      this.selection.clear()
    ) : (
      this.filteredFinancialTableDataSource.filteredData.forEach((row) => this.selection.select(row))
    );
  }

  setGlobalFilter(filter: string): void {
    this.financialTableDataSource.filter = filter;
  }

  filterTable(filter: string): void {
    this.financialTableDataSource.filter = filter;
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data:{
        element: this.filterEntity
      }
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity.FBF_JOB_NUM = filters.jobNumber;
      this.filterEntity.FBF_CHARGE_CODE = filters.chargeCode;
      this.filterEntity.FBF_TRN_DEPT = filters.trnDept;
      this.filterEntity.FBF_LINE_TYPE = filters.lineType;
      this.filterEntity.FBF_POST_PERIOD = filters.periodMonth;
      this.filterEntity.FBF_POST_PERIOD_YEAR = filters.periodYear;
      this.filterEntity.FBF_CLIENT_NAME = filters.client;

      this.filterService.setFilter('financial', this.filterEntity);

      this.getFinancials(this.filterEntity);
    });
  }

  openImportDialog(): void {
    const dialog = this.dialog.open(ImportFinancialDialogComponent);
    dialog.afterClosed().subscribe((isImport: boolean)=>{
      if(isImport) this.getFinancials(this.filterEntity);
    })   
  }

  openRateDialog(): void{
    const dialog = this.dialog.open(RateDialogComponent);
    dialog.afterClosed().subscribe(([rate, requestType])=>{
      if(!rate || (rate && requestType === 0)) return;

      if(requestType === 2){
        this.financialService.postRate(rate).subscribe((response)=>{
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }else{
        this.financialService.putRate(rate).subscribe((response)=>{
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }

    });
  }

  openBudgetDialog(): void{
    const dialog = this.dialog.open(BudgetDialogComponent);
    dialog.afterClosed().subscribe(([budgets, requestType])=>{
      if(budgets.length === 0 || (budgets.lengh > 0 && requestType === 0)){
        return;
      }

      if(requestType === 2){
        this.financialService.postBudget(budgets).subscribe((response)=>{
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }else{
        this.financialService.putBudget(budgets).subscribe((response)=>{
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }
  
  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  openContainerDialog(): void{
    const dialog = this.dialog.open(ContainerDialogComponent);
    dialog.afterClosed().subscribe(([containers, requestType])=>{
      if(containers.length === 0 || containers.length > 0 && requestType === 0) return;

      this.financialService.putContainer(containers).subscribe((response)=>{
        this.toast.success(String(response));
      },(error)=>{
        this.toast.error(error.error.Message);
      })
    })
  }

  export(): void {
    const headers = {
      FBF_JOB_NUM: "JOB NUM",
      FBF_SALES_GROUP: "SALES GROUP",
      FBF_CHARGE_GROUP: "CHARGE GROUP",
      FBF_CHARGE_CODE: "CHARGE CODE",
      FBF_CHARGE_CODE_DESC: "CHARGE CODE & DESC",
      FBF_TRN_BRANCH: "TRN BRANCH",
      FBF_TRN_DEPT: "TRN DEPT",
      FBF_TRN_DESC: "TRN DESC",
      FBF_LINE_TYPE: "LINE TYPE",
      FBF_TRN_RECOG_DT_TEXT: "TRN RECOG DATE",
      FBF_INVOICE_NUMBER: "INVOICE NUMBER",
      FBF_INVOICE_DATE_TEXT: "INVOICE DATE",
      FBF_LOCAL_AMOUNT: "LOCAL AMOUNT",
      FBF_APPORTIONED: "IS APPORTIONED (Y/N)",
      FBF_MOST_RELEV_CONSOL: "MOST RELEVANT CONSOL",
      FBF_LEDGER_TYPE: "LEDGER TYPE",
      FBF_TRN_TYPE: "TRN TYPE",
      FBF_TRN_ORG: "TRN ORG",
      FBF_TRN_ORG_NAME: "TRN ORG NAME",
      FBF_POSTING_DT_TEXT: "POSTING DATE",
      FBF_ACCT_GRP_CD: "ACCT GRP CD",
      FBF_JOB_RECOG_MTH_YYYYMM: "JOB RECOG MONTH (YYYY/MM)",
      FBF_TRN_RECOG_MTH_YYYYMM: "TRN RECOG MONTH (YYYY/MM)",
      FBF_OUT_OF_PERIOD: "OUT OF PERIOD",
      FBF_IS_AUTO_RATED: "IS AUTO RATED",
      FBF_RATING_OVERRIDDEN: "RATING OVERRIDDEN",
      FBF_CFX_PERCENT_TYPE: "CFX PERCENT TYPE",
      FBF_CREATED_USER: "CREATED USED",
      FBF_JOB_STATUS: "JOB STATUS",
      FBF_JOB_BRANCH: "JOB BRANCH",
      FBF_JOB_DEPT: "JOB DEPT",
      FBF_JOB_OPEN_DT_TEXT: "JOB OPEN DATE",
      FBF_JOB_RECOG_DT: "JOB RECOG DATE",
      FBF_JOB_CLOSED_DT_TEXT: "JOB CLOSE DATE",
      FBF_JOB_SL_REP: "JOB SALES REP",
      FBF_JOB_NUM_COMPANY: "JOB NUMBER COMPANY",
      FBF_TRN_INCOME: "TRN INCOME",
      FBF_TRN_PROFIT: "TRN PROFIT",
      FBF_TRN_EXPENSE: "TRN EXPENSE",
      FBF_INCOME_EUR: "INCOME EUR",
      FBF_TRN_PROFIT_EUR: "TRN PROFIT EUR",
      FBF_EXPENSE_EUR: "EXPENSE EUR",
      FBF_OS_VAT: "OS VAT",
      FBF_TRANSPORT_MODE: "TRANSPORT MODE",
      FBF_OS_AMOUNT: "OS AMOUNT",
      FBF_EXCHANGE_RATE: "EXCHANGE RATE",
      FBF_OS_CCY: "OS CCY",
      FBF_LOCAL_CLIENT: "LOCAL CLIENT",
      FBF_SHIP_SUPPLIER: "SHIPPER SUPPLIER",
      FBF_CONSIGNEE_IMPORTER: "CONSIGNEE IMPORTER",
      FBF_HAWB: "HAWB",
      FBF_MAWB: "MAWB",
      FBF_ORIGIN_ETD: "ORIGIN ETD",
      FBF_DESTINATION_ETA: "DESTINATION ETA",
      FBF_SUMARY_DESC: "SUMMARY DESC",
      FBF_DEBTOR_CODE: "DEBTOR CODE",
      FBF_POST_PERIOD: "POSTING PERIOD (MONTH)",
      FBF_POST_PERIOD_YEAR: "POSTING PERIOD (YEAR)",
      FBF_CLIENT_NAME: "CLIENT NAME"
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<IFinancial> = this.prepareDataToExport([...this.financialTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<IFinancial> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `financials_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<IFinancial>): Array<IFinancial> {
    const dataToExport: Array<IFinancial> = [];

    const datesText = {
      FBF_TRN_RECOG_DT_TEXT: "",
      FBF_INVOICE_DATE_TEXT: "",
      FBF_POSTING_DT_TEXT: "",
      FBF_JOB_OPEN_DT_TEXT: "",
      FBF_JOB_CLOSED_DT_TEXT: ""
    }

    data.forEach((financial) => {
      const newData = {...financial, ...datesText};

      newData.FBF_TRN_RECOG_DT_TEXT = newData.FBF_TRN_RECOG_DT && moment(newData.FBF_TRN_RECOG_DT).format('DD/MM/YYYY');
      newData.FBF_INVOICE_DATE_TEXT = newData.FBF_INVOICE_DATE && moment(newData.FBF_INVOICE_DATE).format('DD/MM/YYYY');
      newData.FBF_POSTING_DT_TEXT = newData.FBF_POSTING_DT && moment(newData.FBF_POSTING_DT).format('DD/MM/YYYY');
      newData.FBF_JOB_OPEN_DT_TEXT = newData.FBF_JOB_OPEN_DT && moment(newData.FBF_JOB_OPEN_DT).format('DD/MM/YYYY');
      newData.FBF_JOB_CLOSED_DT_TEXT = newData.FBF_JOB_CLOSED_DT && moment(newData.FBF_JOB_CLOSED_DT).format('DD/MM/YYYY');

      delete newData.FBF_ID;
      delete newData.FBF_INCLUDED_DT;
      delete newData.FBF_TRN_RECOG_DT;
      delete newData.FBF_INVOICE_DATE;
      delete newData.FBF_POSTING_DT;
      delete newData.FBF_JOB_OPEN_DT;
      delete newData.FBF_JOB_CLOSED_DT;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
