import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dhl-user-group-delete-all-dialogs',
  templateUrl: './user-group-delete-all-dialogs.component.html',
  styleUrls: ['./user-group-delete-all-dialogs.component.scss']
})
export class UserGroupDeleteAllDialogsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UserGroupDeleteAllDialogsComponent>) { }

  ngOnInit() {
  }

  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}
