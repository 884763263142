import { IShipment } from 'src/app/models/shipment.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface ICommentDialog {
  element?: IShipment | Array<IShipment>;
}

@Component({
  selector: 'dhl-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {
  comment: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICommentDialog,
    private authService: AuthService,
    private dialogRef: MatDialogRef<CommentDialogComponent>,
  ) { }

  ngOnInit(): void {    
  }

  close(value: [Array<IShipment>, string]): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newShipment: Array<IShipment> = [];

    if (this.data.element instanceof Array) {      
      newShipment = this.data.element.map((element) => {
        return {
          SHIP_ID: element.SHIP_ID,
          SHIP_USER: this.authService.userId
        }
      });
    } else {
      newShipment.push({
        SHIP_ID: this.data.element.SHIP_ID,        
        SHIP_USER: this.authService.userId
      });
    }

    this.close([newShipment, this.comment]);
  }

  onComment(comment: string) {
    this.comment = comment;
  }
}
