<h1 mat-dialog-title>{{data.mode}} Container</h1>
<div mat-dialog-content>
  <form [formGroup]="containerForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>Container Number</mat-label>
        <input matInput name="number" formControlName="number">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Container Type</mat-label>
        <mat-select name="type" formControlName="type" [disabled]="fieldsLocked">
          <mat-option>
            <ngx-mat-select-search formControlName="typeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let containerType of filteredContainerTypes | async" [value]="containerType.DIM_SK_CTN_TYP">{{containerType.CONTYPPF_QAMPZ}}</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="containerForm.get('type').setValue('')" *ngIf="!!containerForm.get('type').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode !== 'delete'">
      <mat-form-field>
        <mat-label>{{destiny == 'DETENTION' ? 'Gate Out' : 'Empty Return'}}</mat-label>
        <input matInput name="gou" formControlName="gou" [matDatepicker]="gouPicker" [disabled]="fieldsLocked">
        <mat-datepicker-toggle matSuffix [for]="gouPicker"></mat-datepicker-toggle>
        <mat-datepicker #gouPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="destiny == 'DETENTION'">
        <mat-label>Gate In</mat-label>
        <input matInput name="gin" formControlName="gin" [matDatepicker]="ginPicker"  [disabled]="fieldsLocked">
        <mat-datepicker-toggle matSuffix [for]="ginPicker"></mat-datepicker-toggle>
        <mat-datepicker #ginPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="destiny == 'DETENTION'">
        <mat-label>Loading</mat-label>
        <input matInput name="ldg" formControlName="ldg" [matDatepicker]="ldgPicker" [disabled]="fieldsLocked">
        <mat-datepicker-toggle matSuffix [for]="ldgPicker"></mat-datepicker-toggle>
        <mat-datepicker #ldgPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode === 'delete'">Do you really wish to delete the container {{data.element.CTN_ID}}?</p>
    <div mat-dialog-actions *ngIf="data.mode !== 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'add'">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode === 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode === 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent">Save</button>
    </div>
  </form>
</div>
