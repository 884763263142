
<h1 mat-dialog-title>Filter Unique Terms</h1>
<div mat-dialog-content>
  <form [formGroup]="filterForm" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Received Date Range</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate name="start_date" formControlName="start_date" placeholder="Start Date">
          <input matEndDate name="finish_date" formControlName="finish_date" placeholder="Finish Date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('start_date').setValue(''); filterForm.get('finish_date').setValue('')" *ngIf="filterForm.get('start_date').value || filterForm.get('finish_date').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #rangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status_situation" formControlName="status_situation">
          <mat-option value="CURRENT">CURRENT</mat-option>
          <mat-option value="EXPIRED">EXPIRED</mat-option>
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="filterForm.get('status_situation').setValue('')" *ngIf="filterForm.get('status_situation').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetFilters()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>

