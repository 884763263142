import { IPayable, IPaymentOrder } from 'src/app/models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier } from 'src/app/models/parameter.model';

export interface IFilterDialog {
  element: IPaymentOrder;
  carrierList: Array<ICarrier>
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;

  carriers: Array<ICarrier> = [];
  filteredCarriers: Array<ICarrier> = [];
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog
  ) { }

  ngOnInit(): void {
    this.carriers = this.data?.carrierList;
    this.filteredCarriers = this.data?.carrierList;

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.data?.element.PO_ID),
      type: new UntypedFormControl(this.data?.element.PO_TYPE),
      carrier: new UntypedFormControl(this.data?.element.PO_CAR),
      carrierFilter: new UntypedFormControl(''),
      sat: new UntypedFormControl(this.data?.element.PO_SAT),
      status: new UntypedFormControl(this.data?.element.PO_PRC_ST),
      payStart: new UntypedFormControl(this.data?.element.PO_PAY_START_DT),
      payFinal: new UntypedFormControl(this.data?.element.PO_PAY_FINAL_DT),
      incStart: new UntypedFormControl(this.data?.element.PO_INC_START_DT),
      incFinal: new UntypedFormControl(this.data?.element.PO_INC_FINAL_DT)
    });

    this.form.get('carrierFilter').valueChanges.subscribe(value => this.filterCarriers(value));
  }

  filterCarriers(filter: string): void {
    this.filteredCarriers = this.data.carrierList.filter((element) => {
      return element.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase().trim()) || 
             element.RELATIPF_APA3CD.toLowerCase().includes(filter.toLowerCase().trim());
    });
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  close(filters: IPaymentOrder): void {
    this.dialogRef.close(filters);
  }

  resetForm(): void {
    this.form.setValue({
      id: null,
      type: null,
      carrier: null,
      carrierFilter: '',
      sat: null,
      status: null,
      payStart: null,
      payFinal: null,
      incStart: null,
      incFinal: null
    })
  }

  handleSubmit(e: Event): void {
    e.preventDefault();

    let filter: IPaymentOrder = {
      PO_ID: this.form.value.id,
      PO_TYPE: this.form.value.type,
      PO_CAR: this.form.value.carrier,
      PO_SAT: this.form.value.sat,
      PO_PRC_ST: this.form.value.status,
      PO_PAY_START_DT: this.form.value.payStart,
      PO_PAY_FINAL_DT: this.form.value.payFinal,
      PO_INC_START_DT: this.form.value.incStart,
      PO_INC_FINAL_DT: this.form.value.incFinal
    }

    this.dialogRef.close(filter);
  }
}
