export interface ILog {
  LOG_ID: number;
  LOG_USER: string;
  LOG_GROUP: string;
  LOG_ENTITY?: string;
  LOG_DATETIME: string;
  LOG_ACTION?: string;
}

export interface IGetLog {
  LOG_ID?: number;
  LOG_USER?: string;
  LOG_GROUP?: string;
  LOG_START_DATETIME?: string;
  LOG_FINAL_DATETIME?: string;
}

export interface IRpaLog{
  LDR_LOG_ID?: number;
	LDR_FUN?: string;
	LDR_CTN?: string;
	LDR_CTN_ID?: number;
	LDR_STATUS_EXEC?: string;
	LDR_EXC_DATETIME?: Date;
	LDR_DESC?: string;
  LDR_MONTH?: number;
	LDR_YEAR?: number;
  LDR_SERVICE?: string;
  LDR_MBL?: string;
  LDR_CAR?: string;
  LDR_CAR_ID?: number;
}

export interface IRpaIndicators{
	LDR_CONS?: number;
	LDR_CONS_OK?: number;
	LDR_CONS_ERR?: number;
	LDR_CONS_PEND?: number;	
}

export interface IRpaJson{
  idContainer?: string;
  usuario?: string;
  container?: string;
  codigo?: string;
  status_exec?: string;
  status?: boolean;
  instancia?: number;
  dataImportacao?: Date;
  dataExecucao?: Date;
  data_Retirada?: Date;
  data_Entrada?: Date;
  data_Embarque?: Date;
}

export interface IIntegrationLog{
  LI_ID?: string;
  LI_STATUS?: string;
  LI_ITEM?: string;
  LI_MESSAGE?: string;
  LI_STATUS_ITEM?: string;
  LI_DATE?: Date;
  LI_HBL?: string;
  LI_SHIP_CODE?: string;
  LI_MONTH?: number;
  LI_YEAR?: number;
  LI_INC_DATE?: Date;
  LI_SHIP_ID?: string;
  EXPORT?: number;
  SEARCH?: string;
  SORT_COL?: string;
  SORT_ORDER?: string;
  PAGE_NUMBER?: number;
  PAGE_SIZE?: number;
  TOTAL_ROWS?: number;
  TOTAL_PAGES?: number;
  RNUM?: number;
}

export interface IIntegrationIndicators{
	LDI_REC_FILES?: number;
  LDI_LOAD_OK?: number;
  LDI_LOAD_ERR?: number;
  LDI_PEND_FILES?: number;
  LDI_MONTH?: number;
  LDI_YEAR?: number;
}