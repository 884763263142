import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): string {
    const fullName = value.split(' ');
    let initials = '';

    if (fullName.length !== 1) {
      initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    } else {
      initials = fullName[0].substring(0, 2);
    }

    return initials.toUpperCase();
  }

}
