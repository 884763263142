import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IPage } from '../models/page.model';
import { IUserGroupList } from '../models/user-group-list.model';
import { IUserGroup } from '../models/user-group.model';
import { IUser } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export abstract class UserGroupService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getUserGroup(): Observable<Array<IUserGroup>> {
    return this.httpClient.get<Array<IUserGroup>>(`${this.apiURL}/api/user/group/`, this.httpOptions);
  }

  getAllUsers(userGroup: IUserGroup): Observable<Array<IUser>> {
    return this.httpClient.get<Array<IUser>>(`${this.apiURL}/api/user/group/users?filter=${JSON.stringify(userGroup)}`, this.httpOptions);
  }

  getAllPages(userGroup: IUserGroup): Observable<Array<IPage>> {
    return this.httpClient.get<Array<IPage>>(`${this.apiURL}/api/user/group/pages?filter=${JSON.stringify(userGroup)}`, this.httpOptions);
  }

  postUserGroup(userGroup: IUserGroup): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/user/group?filter=${JSON.stringify(userGroup)}`, null, this.httpOptions);
  }

  putUserGroup(userGroup: IUserGroup): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/user/group?filter=${JSON.stringify(userGroup)}`, null, this.httpOptions);
  }

  deleteSelectedUserGroups(userGroups: IUserGroupList): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/user/group/deletelist?filter=${JSON.stringify(userGroups)}`, null, this.httpOptions);
  }
}
