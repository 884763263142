import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';

import { CountryDialogComponent } from './country-dialog/country-dialog.component';
import { ContainerTypeDialogComponent } from './container-type-dialog/container-type-dialog.component';
import { PortDialogComponent } from './port-dialog/port-dialog.component';
import { CarrierDialogComponent } from './carrier-dialog/carrier-dialog.component';
import { RegionDialogComponent } from './region-dialog/region-dialog.component';

import { HotToastModule } from '@ngneat/hot-toast';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomerNotificationDialogComponent } from './customer-notification-dialog/customer-notification-dialog.component';

@NgModule({
  declarations: [
    CountryDialogComponent,
    ContainerTypeDialogComponent,
    PortDialogComponent,
    CarrierDialogComponent,
    RegionDialogComponent,
    CustomerNotificationDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HotToastModule.forRoot( {
      reverseOrder: true,
      position: 'bottom-right'
    }),
    NgxMatSelectSearchModule
  ],
  exports: [
    CountryDialogComponent,
    ContainerTypeDialogComponent,
    PortDialogComponent,
    CarrierDialogComponent,
    RegionDialogComponent
  ]
})
export class ParametersDialogsModule { }
