import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomerDialogComponent } from './customers-dialog/customers-dialog.component';
import { CustomerActionDialogComponent } from './customers-action-dialog/customers-action-dialog.component';
import { CustomerDeleteAllDialogComponent } from './customers-delete-all-dialog/customers-delete-all-dialog.component';
import { CustomerFilterDialogComponent } from './customers-filter-dialog/customers-filter-dialog.component';
import { CustomersContactDialogComponent } from './customers-contact-dialog/customers-contact-dialog.component';
import { ComponentsModule } from "../../../components/components.module";
import { CustomerContactViewDialogComponent } from './customer-contact-view-dialog/customer-contact-view-dialog.component';
import { CustomerContactsImportDialogComponent } from './customer-contacts-import-dialog/customer-contacts-import-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
    declarations: [
        CustomerDialogComponent,
        CustomerActionDialogComponent,
        CustomerDeleteAllDialogComponent,
        CustomerFilterDialogComponent,
        CustomersContactDialogComponent,
        CustomerContactViewDialogComponent,
        CustomerContactsImportDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        NgxMatSelectSearchModule,
        NgxDropzoneModule,
        ComponentsModule
    ]
})
export class CustomersDialogsModule { }
