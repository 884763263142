import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IUserFilterDialog {
  userName: string;
  isAdmin: string;
  isActive: string;
}

@Component({
  selector: 'dhl-users-filter-dialog',
  templateUrl: './users-filter-dialog.component.html',
  styleUrls: ['./users-filter-dialog.component.scss']
})
export class UsersFilterDialogComponent implements OnInit {
  filterForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<UsersFilterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IUserFilterDialog, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      userName: new UntypedFormControl(this.data.userName),
      isAdmin: new UntypedFormControl(this.data.isAdmin),
      isActive: new UntypedFormControl(this.data.isActive)
    });
  }

  closeDialog(filters: IUserFilterDialog) {
    this.dialogRef.close(filters);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.filterForm.value;

    let filters: IUserFilterDialog = {
      userName: formValue.userName.trim(),
      isAdmin: formValue.isAdmin.trim(),
      isActive: formValue.isActive.trim()
    }

    this.closeDialog(filters);
  }

  resetFilters(): void {
    this.filterForm.setValue({
      userName: '',
      isAdmin: '',
      isActive: ''
    });
  }
}
