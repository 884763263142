<h1 mat-dialog-title>On Hold</h1>
<form [formGroup]="form" (submit)="handleSubmit($event)">  
  <div mat-dialog-content class="wide">
    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select name="onhold" formControlName="onhold">
        <mat-option value="0">Deactivate</mat-option>
        <mat-option value="1">Activate</mat-option>
      </mat-select>
    </mat-form-field>
    <dhl-comment [showCommentsTab]="false" [offSideComments]="true" *ngIf="showComment()" (comment)="onComment($event)"></dhl-comment>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="showComment() && !comment">Apply</button>
    </div>
  </div>
</form>