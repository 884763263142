import { CustomerGroupService } from 'src/app/services/customer-group.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAgreement } from 'src/app/models/agreement.model';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier, IContainerType, ICountry, IPort, IRegion } from 'src/app/models/parameter.model';
import { ParameterService } from 'src/app/services/parameter.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface IAgreementFilterDialog {
  element: IAgreement;
  selectionFilter: SelectionFilter;
}

export interface SelectionFilter{
  customer: string,
  origin: string,
  destination: string
}

@Component({
  selector: 'dhl-agreement-filter-dialog',
  templateUrl: './agreement-filter-dialog.component.html',
  styleUrls: ['./agreement-filter-dialog.component.scss']
})
export class AgreementFilterDialogComponent implements OnInit {
  form: UntypedFormGroup;

  customers: Array<ICustomer>;

  customerGroups: Array<ICustomerGroup>;
  filteredCustomerGroups: Array<ICustomerGroup>;

  carriers: Array<ICarrier>;
  filteredCarriers: Array<ICarrier>;

  containers: Array<IContainerType>;
  filteredContainers: Array<IContainerType>;

  regions: Array<IRegion>;
  filteredRegions: Array<IRegion>;

  countries: Array<ICountry>;
  filteredCountries: Array<ICountry>;

  ports: Array<IPort>;
  filteredPorts: Array<IPort>;

  selectionFilter: SelectionFilter = {
    customer: null,
    origin: null,
    destination: null
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AgreementFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAgreementFilterDialog,
    private parameterService: ParameterService,
    private customerService: CustomerService,
    private customerGroupService: CustomerGroupService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.data.element.DIM_SK_CUS_RUL),
      hbl: new UntypedFormControl(this.data.element.DIM_DOSIERPF_BHFFTX),
      customer: new UntypedFormControl(this.data.element.DIM_CUS_CODE),
      customerFilter: new UntypedFormControl(''),
      customerGroup: new UntypedFormControl(this.data.element.DIM_CUS_GRP),
      customerGroupFilter: new UntypedFormControl(''),
      status: new UntypedFormControl(this.data.element.DIM_STATUS_RUL),
      service: new UntypedFormControl(this.data.element.DIM_SERVICE),
      description: new UntypedFormControl(this.data.element.DIM_DESCRIPTION),
      carriers: new UntypedFormControl(this.data.element.DIM_CAR),
      carriersFilter: new UntypedFormControl(''),
      containerType: new UntypedFormControl(this.data.element.DIM_CTN_TYPE),
      containersTypeFilter: new UntypedFormControl(''),
      originRegion: new UntypedFormControl(this.data.selectionFilter.origin === 'originRegion' ? this.data.element.DIM_ORIGIN : ''),
      originRegionFilter: new UntypedFormControl(''),
      originCountry: new UntypedFormControl(this.data.selectionFilter.origin === 'originCountry' ? this.data.element.DIM_ORIGIN : ''),
      originCountriesFilter: new UntypedFormControl(''),
      originPort: new UntypedFormControl(this.data.selectionFilter.origin === 'originPort' ? this.data.element.DIM_ORIGIN : ''),
      originPortsFilter: new UntypedFormControl(''),
      destinyRegion: new UntypedFormControl(this.data.selectionFilter.destination === 'destinyRegion' ? this.data.element.DIM_DESTINATION : ''),
      destinyRegionFilter: new UntypedFormControl(''),
      destinyCountry: new UntypedFormControl(this.data.selectionFilter.destination === 'destinyCountry' ? this.data.element.DIM_DESTINATION : ''),
      destinyCountriesFilter: new UntypedFormControl(''),
      destinyPort: new UntypedFormControl(this.data.selectionFilter.destination === 'destinyPort' ? this.data.element.DIM_DESTINATION : ''),
      destinyPortsFilter: new UntypedFormControl(''),
      stStart: new UntypedFormControl(this.data.element.DIM_ST_START_DT),
      stFinal: new UntypedFormControl(this.data.element.DIM_ST_FINAL_DT),
      valStart: new UntypedFormControl(this.data.element.DIM_VAL_START_DT),
      valFinal: new UntypedFormControl(this.data.element.DIM_VAL_FINAL_DT),
      incStart: new UntypedFormControl(this.data.element.DIM_INC_START_DT),
      incFinal: new UntypedFormControl(this.data.element.DIM_INC_FINAL_DT),
    });

    this.getCustomers({ CUS_ID: this.form.get('customer').value, IS_SELECT: 1 });
    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    this.getCustomerGroups();
    this.form.get('customerGroupFilter').valueChanges.subscribe(value => this.filterCustomerGroups(value));

    this.toggleField('customer', 'customerGroup');
    this.toggleField('customerGroup', 'customer');

    this.getCarriers();
    this.form.get('carriersFilter').valueChanges.subscribe(value => this.filterCarriers(value));

    this.getContainers();
    this.form.get('containersTypeFilter').valueChanges.subscribe(value => this.filterContainers(value));

    this.getRegions();
    this.form.get('originRegionFilter').valueChanges.subscribe(value => this.filterRegions(value));
    this.form.get('destinyRegionFilter').valueChanges.subscribe(value => this.filterRegions(value));

    this.getCountries();
    this.form.get('originCountriesFilter').valueChanges.subscribe(value => this.filterCountries(value));
    this.form.get('destinyCountriesFilter').valueChanges.subscribe(value => this.filterCountries(value));

    this.getPorts();
    this.form.get('originPortsFilter').valueChanges.subscribe(value => this.filterPorts(value));
    this.form.get('destinyPortsFilter').valueChanges.subscribe(value => this.filterPorts(value));
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers
    })
  }

  filterCustomers(filter: string): void {
    if (!this.form.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getCustomerGroups(): void{
    this.customerGroupService.getCustomerGroup().subscribe((customerGroups)=>{
      this.customerGroups = customerGroups;
      this.filteredCustomerGroups = customerGroups;
    })
  }

  filterCustomerGroups(filter: string): void {
    if(this.customerGroups){
      this.filteredCustomerGroups = this.customerGroups.filter((element) => {
        return element.CUS_GRP_NAME.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }
  }

  getCarriers(): void {
    this.parameterService.getCarrier().subscribe((carr: Array<ICarrier>) => {
      this.carriers = carr;
      this.filteredCarriers = carr;
    });
  }

  filterCarriers(filter: string): void {
    if(this.carriers) {
      this.filteredCarriers = this.carriers.filter((element) => {
        return element.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }
  }

  getContainers(): void {
    this.parameterService.getContainerType().subscribe((ctn: Array<IContainerType>) => {
      this.containers = ctn;
      this.filteredContainers = ctn;
    });
  }

  filterContainers(filter: string): void {
    if(this.containers) {
      this.filteredContainers = this.containers.filter((element) => {
        return element.CONTYPPF_QAMPZ.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }
  }

  getRegions(): void {
    this.parameterService.getRegion().subscribe((reg: Array<IRegion>) => {
      this.regions = reg;
      this.filteredRegions = reg;
    });
  }

  filterRegions(filter: string): void {
    if(this.regions) {
      this.filteredRegions = this.regions.filter((element) => {
        return element.REG_NAME.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }
  }

  getCountries(): void {
    this.parameterService.getCountry().subscribe((cou: Array<ICountry>) => {
      this.countries = cou;
      this.filteredCountries = cou;
    });
  }

  filterCountries(filter: string): void {
    if(this.countries) {
      this.filteredCountries = this.countries.filter((element) => {
        return element.COU_CODE.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }
  }

  getPorts(): void {
    this.parameterService.getPort().subscribe((port: Array<IPort>) => {
      this.ports = port;
      this.filteredPorts = port;
    });
  }

  filterPorts(filter: string): void {
    if(this.ports) {
      this.filteredPorts = this.ports.filter((element) => {
        return element.DHLPORTS_AIAOCD.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue(null);
  }

  closeDialog(filter: IAgreement): void {
    this.dialogRef.close([filter, this.selectionFilter]);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();

    this.selectField();

    const agreement: IAgreement = {
      DIM_SK_CUS_RUL: this.form.value.id,
      DIM_CUS_CODE: this.form.value.customer ? this.form.value.customer : null,
      DIM_CUS_GRP: this.form.value.customerGroup ? this.form.value.customerGroup : null,
      DIM_STATUS_RUL: this.form.value.status,
      DIM_CAR: this.form.value.carriers,
      DIM_SERVICE: this.form.value.service,
      DIM_DESCRIPTION: this.form.value.description,
      DIM_CTN_TYPE: this.form.value.containerType,
      DIM_DOSIERPF_BHFFTX: this.form.value.hbl,
      DIM_ORIGIN: this.form.value.originRegion || this.form.value.originCountry || this.form.value.originPort || null,
      DIM_ORIGIN_FIELD: this.selectionFilter.origin,
      DIM_DESTINATION: this.form.value.destinyRegion || this.form.value.destinyCountry || this.form.value.destinyPort || null,
      DIM_DESTINATION_FIELD: this.selectionFilter.destination,
      DIM_ST_START_DT: this.form.value.stStart,
      DIM_ST_FINAL_DT: this.form.value.stFinal,
      DIM_VAL_START_DT: this.form.value.valStart,
      DIM_VAL_FINAL_DT: this.form.value.valFinal,
      DIM_INC_START_DT: this.form.value.incStart,
      DIM_INC_FINAL_DT: this.form.value.incFinal
    };

    this.closeDialog(agreement);
  }

  reset(): void {
    this.form.setValue({
      id: null,
      hbl: null,
      customer: null,
      customerFilter: '',
      customerGroup: null,
      customerGroupFilter: '',
      status: null,
      service: null,
      description: null,
      carriers: null,
      carriersFilter: '',
      containerType: null,
      containersTypeFilter: '',
      originRegion: null,
      originRegionFilter: '',
      originCountry: null,
      originCountriesFilter: '',
      originPort: null,
      originPortsFilter: '',
      destinyRegion: null,
      destinyRegionFilter: '',
      destinyCountry: null,
      destinyCountriesFilter: '',
      destinyPort: null,
      destinyPortsFilter: '',
      stStart: null,
      stFinal: null,
      valStart: null,
      valFinal: null,
      incStart: null,
      incFinal: null
    });

    this.selectionFilter = {
      customer: null,
      origin: null,
      destination: null
    };
    
    this.toggleField('customer', 'customerGroup');
    this.toggleField('customerGroup', 'customer');
  }

  toggleField(field: string, fieldToDisable: string): void {
    if (this.form.get(field).value) {
      this.selectionFilter.customer = field;
      this.form.get(fieldToDisable).setValue('');
      this.form.get(fieldToDisable).disable();
    } else {
      this.form.get(fieldToDisable).enable();
    }
  }

  toggleOriginDestinyFields(field: string, fieldToDisable1: string, fieldToDisable2: string): void {
    if (this.form.get(field).value) {
      if(field.includes('origin')){
        this.selectionFilter.origin = field;
      }else{
        this.selectionFilter.destination = field;
      }
      this.form.get(fieldToDisable1).setValue('');
      this.form.get(fieldToDisable1).disable();

      this.form.get(fieldToDisable2).setValue('');
      this.form.get(fieldToDisable2).disable();
    } else {
      this.form.get(fieldToDisable1).enable();
      this.form.get(fieldToDisable2).enable();
    }
  }

  selectField(){
    this.selectionFilter = {
      customer: null,
      origin: null,
      destination: null
    };

    const originOptions = [
      'originRegion',
      'originCountry',
      'originPort'
    ];

    const destinyOptions = [
      'destinyRegion',
      'destinyCountry',
      'destinyPort'
    ];

    for(const field of originOptions){
      if(this.form.get(field).value){
        this.selectionFilter.origin = field
      }
    }

    for(const field of destinyOptions){
      if(this.form.get(field).value){
        this.selectionFilter.destination = field
      }
    }
  }

}