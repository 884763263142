import { IUniqueTerm } from 'src/app/models/unique-term';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'dhl-unique-terms-filter-dialog',
  templateUrl: './unique-terms-filter-dialog.component.html',
  styleUrls: ['./unique-terms-filter-dialog.component.scss']
})
export class UniqueTermsFilterDialogComponent implements OnInit {
  filterForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<UniqueTermsFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUniqueTerm,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.filterForm = this.formBuilder.group({
      start_date: new UntypedFormControl(this.data?.START_DATE),
      finish_date: new UntypedFormControl(this.data?.FINISH_DATE),
      status_situation: new UntypedFormControl(this.data?.UNI_SITUATION)
    });
  }

  closeDialog(filters: IUniqueTerm) {
    this.dialogRef.close(filters);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.filterForm.value;

    let filters: IUniqueTerm = {
      START_DATE: formValue.start_date,
      FINISH_DATE: formValue.finish_date,
      UNI_SITUATION: formValue.status_situation
    }
    
    this.closeDialog(filters);
  }

  resetFilters(): void {
    this.filterForm.setValue({
      start_date: null,
      finish_date: null,
      status_situation: null
    });
  }

}
