import { CommentService } from 'src/app/services/comment.service';
import { ICommentRef, IComment } from 'src/app/models/comment.model';
import { CustomDateDialogComponent } from './dialogs/custom-date-dialog/custom-date-dialog.component';
import { XlsxExporterService } from 'mat-table-exporter';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HotToastService } from '@ngneat/hot-toast';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';
import { IShipment, IContainer } from 'src/app/models/shipment.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { DateDialogComponent, IDateDialog } from './dialogs/date-dialog/date-dialog.component';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ICustomer } from 'src/app/models/customer.model';
import { IContainerType, ICarrier, IPort } from 'src/app/models/parameter.model';
import { FilterDialogComponent } from './dialogs/filter-dialog/filter-dialog.component';
import { MatTableFilter } from 'mat-table-filter';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { ISelectedDetentionDatesDialog, SelectedDetentionDatesComponent } from './dialogs/selected-detention-dates/selected-detention-dates.component';
import { IProcessPayContainer } from 'src/app/models/process-pay-container';
import { ProcessPayContainerService } from 'src/app/services/process-pay-container.service';
import { IProcessContainer } from 'src/app/models/process-container.model';
import { InvoiceService } from 'src/app/services/invoice.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { IOnHoldDialog, OnholdDialogComponent } from './dialogs/onhold-dialog/onhold-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IAttachment } from 'src/app/models/attachment.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { AwsService } from 'src/app/services/aws.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { DetentionExcelImportDialogComponent } from './dialogs/detention-excel-import-dialog/detention-excel-import-dialog.component';
import { IPrtlCtnDetentionDialog, PrtlCtnDetentionDialogComponent } from './dialogs/prtl-ctn-detention-dialog/prtl-ctn-detention-dialog.component';
import { IPrtlCtn } from 'src/app/models/prtl-ctn';
import { PrtlCtnService } from 'src/app/services/prtl-ctn.service';
import { INewPrtlCtnDetentionDialog, NewPrtlDetentionDialogComponent } from './dialogs/new-prtl-detention-dialog/new-prtl-detention-dialog.component';

@Component({
  selector: 'dhl-detention-manual',
  templateUrl: './detention-manual.component.html',
  styleUrls: ['./detention-manual.component.scss']
})
export class DetentionManualComponent implements OnInit {
  pageKey = 'detentionManual';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IContainer>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filterEntity: IContainer = {
    CTN_NUMBER: null,
    CTN_IS_PARTIAL: null,
    CTN_HBL: null,
    CTN_MBL: null,
    CTN_TYPE: null,
    CTN_CUSTOMER: null,
    CTN_CAR: null,
    CTN_POL: null,
    CTN_POD: null,
    CTN_STATUS_LIST: null,
    CTN_RUL: null,
    CTN_ONHOLD: null,
    CTN_GOU_START_DT: null,
    CTN_GOU_FINAL_DT: null,
    CTN_GIN_START_DT: null,
    CTN_GIN_FINAL_DT: null,
    CTN_LDG_START_DT: null,
    CTN_LDG_FINAL_DT: null,
    CTN_INC_START_DT: null,
    CTN_INC_FINAL_DT: null
  };
  filterType: MatTableFilter = MatTableFilter.ANYWHERE;
  filtersNumber: number = 0;

  detentionExportDataSource = new MatTableDataSource<IContainer>();
  detentionTableDataSource = new MatTableDataSource<IContainer>();
  filteredDetentionTableDataSource = new MatTableDataSource<IContainer>();
  detentionTableDisplayedColumns: Array<string> = [
    'select',
    'CTN_NUMBER',
    'CTN_TYPE',
    'CTN_CUSTOMER',
    'CTN_HBL',
    'SHIP_DPT_CODE',
    'CTN_CAR',
    'CTN_MBL',
    'CTN_POL',
    'CTN_POD',
    'START_DATE',
    'CTN_GOU',
    'CTN_GIN_GOU',
    'CTN_GIN',
    'CTN_LDG_GIN',
    'CTN_LDG',
    'CTN_FRT',
    'CTN_RUL',
    'CTN_PROCESS_STATUS',
    'CTN_PF_STATUS',
    'CTN_ONHOLD',
    'PRTL_CTN',
    'ADD_PARTIAL',
    'actions'
  ];

  customerList: Array<ICustomer> = [];
  containerTypeList: Array<IContainerType> = [];
  carrierList: Array<ICarrier> = [];
  portList: Array<IPort> = [];

  statusProcess: Array<string> = [
    '',
    '',
    'Waiting Loading',
    'Container Returned',
    'Detention Calculated',
    'Invoice Generated',
    'Cancelled',
    'No Detention',
    'Agreement Not Found',
    'Invoice Sent'
  ];

  statusPayForecastCtn: Array<string> = [
    '',
    '',
    'Waiting Loading',
    'Container Returned',
    'Detention Calculated',
    '',//'Invoice Generated',
    '',//'Cancelled',
    'No Detention',
    'Agreement Not Found'
  ];

  selectedDate = 'End reference';

  @ViewChild(MatTable) detentionTable: MatTable<IContainer>;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  shipmentId: number;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private shipmentService: ShipmentService,
    private invoiceService: InvoiceService,
    private payCtnService: ProcessPayContainerService,
    private toast: HotToastService,
    private exporter: XlsxExporterService,
    private authService: AuthService,
    private filterService: FilterService,
    private commentService: CommentService,
    private awsService: AwsService,
    private attachmentService: AttachmentService,
    private timelineService: TimelineService,
    private prtlCtnService: PrtlCtnService
  ) {
    this.titleService.setTitle('DHL | Detention (Overview Analysis)');
  }

  ngOnInit(): void {
    const filter = this.filterService.getFilter('detentionProcess');
    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getContainers(this.filterEntity);
    });

    this.getContainers(this.filterEntity);
  }

  getContainers(filter: IContainer): void {
    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredDetentionTableDataSource.data.length === 0) this.hideLoader = false;
    this.shipmentService.getContainers({
      ...filter,
      CTN_DESTINY: 1,
      SEARCH: search,
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder,
      PAGE_NUMBER: this.pageNumber,
      PAGE_SIZE: this.pageSize
    }).subscribe((containers) => {
      this.hideLoader = true;
      this.detentionTableDataSource.data = containers;
      this.filteredDetentionTableDataSource.data = containers;
      this.length = containers[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredDetentionTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle(){
    this.isAllSelected() ? this.selection.clear() : this.filteredDetentionTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  openDateGateOutDialog(data: IDialogData<IContainer>): void {
    const dialogRef = this.dialog.open(DateDialogComponent, { data });

    dialogRef.afterClosed().subscribe((dates: IDateDialog) => {
      if (!dates) return;

      if(!dates?.willDelete){
        if (data.element.CTN_GIN && dates.element.CTN_GOU > new Date(data.element.CTN_GIN)) {
          this.toast.error('The Gate Out date must be earlier than or equal to the Gate In date');
          return;
        }

        if (data.element.CTN_LDG && dates.element.CTN_GOU > new Date(data.element.CTN_LDG)) {
          this.toast.error('The Gate Out date must be earlier than or equal to the Loading date');
          return;
        }

        if (dates.element.CTN_GOU > new Date()) {
          this.toast.error('The Gate Out date must be less then or equal to the current date');
          return;
        }
      }


      const newContainer: IContainer = {
        SHIP_ID: data.element.SHIP_ID,
        CTN_ID: data.element.CTN_ID,
        CTN_NUMBER: data.element.CTN_NUMBER,
        CTN_GOU: dates.element?.CTN_GOU,
        CTN_GIN: dates.element?.CTN_GIN,
        CTN_LDG: dates.element?.CTN_LDG,
        CTN_END_REFERENCE: data.element.CTN_END_REFERENCE,
        CTN_USER: this.authService.userId
      }

      this.shipmentService.putContainer(newContainer).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  openDateGateInDialog(data: IDialogData<IContainer>): void {
    const dialogRef = this.dialog.open(DateDialogComponent, { data });

    dialogRef.afterClosed().subscribe((dates: IDateDialog) => {
      if (!dates) return;

      if(!dates?.willDelete){
        if (data.element.CTN_GOU && dates.element.CTN_GIN < new Date(data.element.CTN_GOU)) {
          this.toast.error('The Gate In date must be later than or equal to the Gate Out date');
          return;
        }

        if (data.element.CTN_LDG && dates.element.CTN_GIN > new Date(data.element.CTN_LDG)) {
          this.toast.error('The Gate In date must be earlier than or equal to the Loading date');
          return;
        }

        if (dates.element.CTN_GIN > new Date()) {
          this.toast.error('The Gate In date must be less then or equal to the current date');
          return;
        }
      }


      const newContainer: IContainer = {
        SHIP_ID: data.element.SHIP_ID,
        CTN_ID: data.element.CTN_ID,
        CTN_NUMBER: data.element.CTN_NUMBER,
        CTN_GOU: dates.element.CTN_GOU,
        CTN_GIN: dates.element.CTN_GIN,
        CTN_LDG: dates.element.CTN_LDG,
        CTN_END_REFERENCE: data.element.CTN_END_REFERENCE,
        CTN_USER: this.authService.userId
      }

      this.shipmentService.putContainer(newContainer).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  openDateLoadingDialog(data: IDialogData<IContainer>): void {
    const dialogRef = this.dialog.open(DateDialogComponent, { data });

    dialogRef.afterClosed().subscribe((dates: IDateDialog) => {
      if (!dates) return;

      if(!dates?.willDelete){
        if (data.element.CTN_GIN && dates.element.CTN_LDG < new Date(data.element.CTN_GIN)) {
          this.toast.error('The Loading date must be later than or equal to the Gate In date');
          return;
        }

        if (data.element.CTN_GOU && dates.element.CTN_LDG < new Date(data.element.CTN_GOU)) {
          this.toast.error('The Loading date must be later than or equal to the Gate Out date');
          return;
        }

        if (dates.element.CTN_LDG > new Date()) {
          this.toast.error('The Loading date must be less then or equal to the current date');
          return;
        }
      }


      const newContainer: IContainer = {
        SHIP_ID: data.element.SHIP_ID,
        CTN_ID: data.element.CTN_ID,
        CTN_NUMBER: data.element.CTN_NUMBER,
        CTN_GOU: dates.element.CTN_GOU,
        CTN_GIN: dates.element.CTN_GIN,
        CTN_LDG: dates.element.CTN_LDG,
        CTN_END_REFERENCE: data.element.CTN_END_REFERENCE,
        CTN_USER: this.authService.userId
      }

      this.shipmentService.putContainer(newContainer).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  getStatusProcess(id: number): string{
    return this.statusProcess[id];
  }

  getStatusPayForecastCtn(id: number): string{
    return this.statusPayForecastCtn[id];
  }

  export(): void {
    const headers = {
      CTN_NUMBER:'CONTAINER',
      CTN_TYPE:'TYPE',
      CTN_CUSTOMER:'CUSTOMER',
      CTN_HBL:'HOUSE BILL',
      SHIP_DPT_CODE:'DEPT',
      CTN_CAR: 'CARRIER',
      CTN_MBL:'MASTER BILL',
      CTN_POL:'ORIGIN PORT',
      CTN_POD:'DESTINATION PORT',
      CTN_GOU_TEXT:'GATE OUT',
      CTN_GIN_GOU: 'DAYS',
      CTN_GIN_TEXT:'GATE IN',
      CTN_LDG_GIN: 'DAYS',
      CTN_LDG_TEXT:'LOADING',
      CTN_FRT: 'FREETIME',
      CTN_RUL: 'DDM AGREEMENT',
      CTN_PROCESS_STATUS_TEXT:'PROCESS STATUS',
      CTN_PF_STATUS_TEXT: 'PAYABLE STATUS',
      CTN_ONHOLD_CMT: 'ONHOLD COMMENT',
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.shipmentService.getContainers({
          ...this.filterEntity,
          EXPORT: 1,
          CTN_DESTINY: 1,
          SEARCH: search,
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder
      }).subscribe((dataExport)=>{
        this.detentionExportDataSource.data = dataExport;

        const data: Array<IContainer> = this.prepareDataToExport([...this.detentionExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `${this.pageKey}_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    }else{
      const data: Array<IContainer> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `${this.pageKey}_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IContainer>): Array<IContainer> {
    const dataToExport: Array<IContainer> = [];

    const datesText = {
      CTN_GOU_TEXT : '',
      CTN_GIN_TEXT : '',
      CTN_END_REFERENCE_TEXT: '',
      CTN_LDG_TEXT : '',
      CTN_PROCESS_STATUS_TEXT: '',
      CTN_PF_STATUS_TEXT: ''
    }

    data.forEach((container) => {
      const newData = {...container, ...datesText};

      newData.CTN_PROCESS_STATUS_TEXT = this.statusProcess[newData.CTN_PROCESS_STATUS];
      newData.CTN_PF_STATUS_TEXT = this.statusPayForecastCtn[newData.CTN_PF_STATUS];

      newData.CTN_GOU_TEXT = newData.CTN_GOU && moment(newData.CTN_GOU).format('DD/MM/YYYY');
      newData.CTN_GIN_TEXT = newData.CTN_GIN && moment(newData.CTN_GIN).format('DD/MM/YYYY');
      newData.CTN_LDG_TEXT = newData.CTN_LDG && moment(newData.CTN_LDG).format('DD/MM/YYYY');

      newData.CTN_ONHOLD_CMT = decodeURIComponent(newData.CTN_ONHOLD_CMT);

      delete newData.START_DATE;
      delete newData.CTN_GOU;
      delete newData.CTN_GIN;
      delete newData.CTN_LDG;
      delete newData.CTN_END_REFERENCE;
      delete newData.CTN_IS_PARTIAL;
      delete newData.CTN_ID;
      delete newData.SHIP_ID;
      delete newData.SHIP_ATD;
      delete newData.CTN_STATUS;
      delete newData.CTN_USER;
      delete newData.INVOICE_STATUS;
      delete newData.CTN_PROCESS_STATUS;
      delete newData.CTN_PF_STATUS;
      delete newData.CTN_DUP_MBL;
      delete newData.CTN_ONHOLD;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getContainers(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getContainers(this.filterEntity);
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: {
        element: this.filterEntity,
        statusList: this.statusProcess
      }
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);
      this.filterEntity = filters;
      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('detentionProcess', this.filterEntity);

      this.getContainers(this.filterEntity);
    });
  }

  recalculatePurchase(containers: IContainer | Array<IContainer>) {
    let newContainers: Array<IProcessPayContainer> = [];

    if(containers instanceof Array){
      for(const container  of containers){
        if(this.validateRecalculate(container,'purchase')){
          newContainers.push({
            PF_SHIP: container.SHIP_ID,
            PF_CTN: container.CTN_NUMBER,
            PF_USER: this.authService.userId
          });
        }
      }
    }else{
      newContainers.push({
        PF_SHIP: containers.SHIP_ID,
        PF_CTN: containers.CTN_NUMBER,
        PF_USER: this.authService.userId
      });
    }

    if(newContainers.length > 0){
      this.payCtnService.recalculateContainer(newContainers).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }

  }

  recalculateSale(containers: IContainer | Array<IContainer>) {
    let newContainers: Array<IProcessContainer> = [];

    if(containers instanceof Array){
      for(const container  of containers){
        if(this.validateRecalculate(container,'sale')){
          newContainers.push({
            PROCESS_SHIP: container.SHIP_ID,
            PROCESS_CTN: container.CTN_NUMBER,
            PROCESS_USER: this.authService.userId
          });
        }
      }
    }else{
      newContainers.push({
        PROCESS_SHIP: containers.SHIP_ID,
        PROCESS_CTN: containers.CTN_NUMBER,
        PROCESS_USER: this.authService.userId
      });
    }

    if(newContainers.length > 0){
      this.invoiceService.recalculateContainer(newContainers).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }

  }

  validateRecalculate(container: IContainer, type: string): boolean{
    let result: boolean = false;

    if(type === 'sale'){
      if(container.CTN_PROCESS_STATUS != 9 && container.CTN_ONHOLD != 1){
        result = true;
      }
    }else{
      if(container.CTN_ONHOLD != 1){
        result = true;
      }
    }

    return result;
  }

  openOnHoldDialog(data: IOnHoldDialog): void {
    const dialog = this.dialog.open(OnholdDialogComponent, { data });

    dialog.afterClosed().subscribe(([containers, comment]: [Array<IContainer>, string]) => {
      if (containers.length === 0) {
        return;
      }

      if (comment) {
        const commentRefs: Array<ICommentRef> = [];

        console.log(containers);

        containers.forEach(container => {
          commentRefs.push({
            REG_ID: Number(container.SHIP_ID),
            COMM_REF_GROUP: 'shipment',
            COMM_REF_USER: this.authService.userId
          });
        });

        const newComment: IComment = {
          COMM_MESSAGE: comment,
          COMM_USER: this.authService.userId,
          COMM_REF: commentRefs
        }

        this.commentService.postComment(newComment).subscribe((response) => {
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }

      this.shipmentService.putOnHold(containers).subscribe((response) => {
        this.toast.success(String(response));
        this.getContainers(this.filterEntity);
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  openSidebar(shipmentId: number): void {
    this.shipmentId = shipmentId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `shipment`, REG_ID: this.shipmentId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.shipmentId),
          COMM_REF_GROUP: `shipment`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'shipment', REG_ID: this.shipmentId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'shipment', [{ REG_ID: this.shipmentId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'shipment', TIMELINE_REG_ID: this.shipmentId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  import(){
    const dialog = this.dialog.open(DetentionExcelImportDialogComponent);

    dialog.afterClosed().subscribe((isImport: boolean)=>{
      if(isImport) this.getContainers(this.filterEntity);
    });
  }

  openPrtlDialog(data: IPrtlCtnDetentionDialog): void {
    const dialogRef = this.dialog.open(PrtlCtnDetentionDialogComponent, {
      data: data
    });
  }

  async openNewPrtlDialog(data: INewPrtlCtnDetentionDialog){
    if(data.container.CTN_IS_PARTIAL == 0 && !data.container.CTN_FRT){
      try {
        const freetime = await this.getCtnFrtAsync(data.container.SHIP_ID, data.container.CTN_NUMBER);
        data.container.CTN_FRT = freetime;        
      } catch (error) {
        this.toast.error(error.error.Message);
        return;
      }
    }

    let dialogRef = this.dialog.open(NewPrtlDetentionDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result: IPrtlCtn) => {
      if (!result) return;

      if (data.mode == 'add') {
        this.prtlCtnService.post(result).subscribe((response) => {
          this.toast.success(String(response));
          this.getContainers(this.filterEntity);
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  async getCtnFrtAsync(shipId: number, ctnNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.prtlCtnService.getCtnFrt({ PROCESS_SHIP: shipId, PROCESS_CTN: ctnNumber })
        .subscribe(
          freetime => {
            resolve(freetime);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
