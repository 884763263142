<h1 mat-dialog-title>Filter Detention</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Container</mat-label>
        <input matInput type="text" name="container" formControlName="container">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('container')" (click)="resetField('container')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Container Type</mat-label>
        <mat-select name="type" formControlName="type" [disabled]="containerTypes.length === 0">
          <mat-option>
            <ngx-mat-select-search formControlName="typeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let containerType of filteredContainerTypes | async" [value]="containerType.DIM_SK_CTN_TYP">{{containerType.CONTYPPF_QAMPZ}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('type')" (click)="resetField('type'); resetField('typeFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>      
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>House Bill</mat-label>
        <input matInput type="text" name="hbl" formControlName="hbl">
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('hbl')" (click)="resetField('hbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Master Bill</mat-label>
        <input matInput type="text" name="mbl" formControlName="mbl">
        <button type="button" mat-icon-button matSuffix *ngIf="fieldHasValue('mbl')" (click)="resetField('mbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer">
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('customer')" (click)="resetField('customer'); resetField('customerFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <mat-select name="carrier" formControlName="carrier" [disabled]="carriers.length === 0">
          <mat-option>
            <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('carrier')" (click)="resetField('carrier'); resetField('carrierFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Origin Port</mat-label>
        <mat-select name="originPort" formControlName="originPort" [disabled]="ports.length === 0">
          <mat-option>
            <ngx-mat-select-search formControlName="originPortFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let originPort of filteredOriginPorts | async" [value]="originPort.DIM_SK_PORT">{{originPort.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('originPort')" (click)="resetField('originPort'); resetField('originPortFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>     
      <mat-form-field>
        <mat-label>Destination Port</mat-label>
        <mat-select name="destinationPort" formControlName="destinationPort" [disabled]="ports.length === 0">
          <mat-option>
            <ngx-mat-select-search formControlName="destinationPortFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let destinationPort of filteredDestinationPorts | async" [value]="destinationPort.DIM_SK_PORT">{{destinationPort.DHLPORTS_AIAOCD}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('destinationPort')" (click)="resetField('destinationPort'); resetField('destinationPortFilter')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>Gate Out Date Range</mat-label>
        <mat-date-range-input [rangePicker]="gouRangePicker">
          <input matStartDate name="gouStart" formControlName="gouStart" placeholder="Start date">
          <input matEndDate name="gouFinal" formControlName="gouFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="gouRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('gouStart'); resetField('gouFinal')" *ngIf="fieldHasValue('gouStart') || fieldHasValue('gouFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #gouRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Gate In Date Range</mat-label>
        <mat-date-range-input [rangePicker]="ginRangePicker">
          <input matStartDate name="ginStart" formControlName="ginStart" placeholder="Start date">
          <input matEndDate name="ginFinal" formControlName="ginFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="ginRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('ginStart'); resetField('ginFinal')" *ngIf="fieldHasValue('ginStart') || fieldHasValue('ginFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #ginRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Loading Date Range</mat-label>
        <mat-date-range-input [rangePicker]="ldgRangePicker">
          <input matStartDate name="ldgStart" formControlName="ldgStart" placeholder="Start date">
          <input matEndDate name="ldgFinal" formControlName="ldgFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="ldgRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('ldgStart'); resetField('ldgFinal')" *ngIf="fieldHasValue('ldgStart') || fieldHasValue('ldgFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #ldgRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Inclusion Date Range</mat-label>
        <mat-date-range-input [rangePicker]="incRangePicker">
          <input matStartDate name="incStart" formControlName="incStart" placeholder="Start date">
          <input matEndDate name="incFinal" formControlName="incFinal" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="incRangePicker"></mat-datepicker-toggle>
        <button type="button" mat-icon-button matSuffix (click)="resetField('incStart'); resetField('incFinal')" *ngIf="fieldHasValue('incStart') || fieldHasValue('incFinal')">
          <mat-icon color="primary">close</mat-icon>
        </button>
        <mat-date-range-picker  #incRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="reset()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>