import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICustomer, ICustomerContact, ICustomerException, ICustomerNotification } from 'src/app/models/customer.model';
import { IJoinCustomerList } from '../models/join-customer-group.model';
import { IFinancialCustomer } from '../models/financial.model';

@Injectable({
  providedIn: 'root'
})
export abstract class CustomerService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {    
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getCustomer(filter: ICustomer = {}): Observable<Array<ICustomer>> {
    return this.httpClient.get<Array<ICustomer>>(`${this.apiURL}/api/customer?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getFinancialCustomers(): Observable<Array<IFinancialCustomer>> {
    return this.httpClient.get<Array<IFinancialCustomer>>(`${this.apiURL}/api/financial/customers`, this.httpOptions);
  }

  postCustomer(customer: ICustomer): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/customer?filter=${JSON.stringify(customer)}`, null, this.httpOptions);
  }

  putCustomer(customer: ICustomer): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer?filter=${JSON.stringify(customer)}`,  null,this.httpOptions);
  }

  deleteSelectedCustomers(customers: IJoinCustomerList): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer/list?filter=${JSON.stringify(customers)}`, null, this.httpOptions);
  }

  //-----------------------Customer Contact-----------------------

  getCustomerContact(filter: ICustomerContact = {}): Observable<Array<ICustomerContact>> {
    return this.httpClient.get<Array<ICustomerContact>>(`${this.apiURL}/api/customer/contact?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  postCustomerContact(contact: ICustomerContact): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/customer/contact`, contact, this.httpOptions);
  }

  putCustomerContact(contact: ICustomerContact): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer/contact`, contact, this.httpOptions);
  }

  deleteCustomerContact(contact: ICustomerContact): Observable<Object> {
    return this.httpClient.delete(`${this.apiURL}/api/customer/contact?filter=${JSON.stringify(contact)}`, this.httpOptions);
  }

  importCustomerContact(contacts: Array<ICustomerContact>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/customer/contact/list`, contacts, this.httpOptions);
  }

  //-----------------------Customer Exception-----------------------

  getCustomerException(filter: ICustomerException): Observable<Array<ICustomerException>> {
    return this.httpClient.get<Array<ICustomerException>>(`${this.apiURL}/api/customer/exception?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  postCustomerException(exception: ICustomerException): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/customer/exception`, exception, this.httpOptions);
  }

  putCustomerException(exception: ICustomerException): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer/exception`, exception, this.httpOptions);
  }

  deleteCustomerException(exceptions: Array<ICustomerException>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer/exception/delete`, exceptions, this.httpOptions);
  }

  getCustomerExceptionId(exception: ICustomerException): Observable<Number> {
    return this.httpClient.get<Number>(`${this.apiURL}/api/customer/exception/id?filter=${JSON.stringify(exception)}`, this.httpOptions);
  };

  //-----------------------Customer Notification-----------------------

  getCustomerNotification(filter: ICustomerNotification): Observable<Array<ICustomerNotification>> {
    return this.httpClient.get<Array<ICustomerNotification>>(`${this.apiURL}/api/customer/notification?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  postCustomerNotification(notifications: Array<ICustomerNotification>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/customer/notification`, notifications, this.httpOptions);
  }

  /*putCustomerNotification(notification: ICustomerNotification): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer/notification`, notification, this.httpOptions);
  }

  deleteCustomerNotification(notification: ICustomerNotification): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customer/notification`, notification, this.httpOptions);
  }*/
}
