import { FinancialService } from 'src/app/services/financial.service';
import { IFinancial, IFinancialRate } from './../../../../../models/financial.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IRateDialog {
  element?: IFinancialRate;
}

@Component({
  selector: 'dhl-rate-dialog',
  templateUrl: './rate-dialog.component.html',
  styleUrls: ['./rate-dialog.component.scss']
})
export class RateDialogComponent implements OnInit {
  form: UntypedFormGroup;
  rate: IFinancialRate;
  requestType: number = 0;
  financialYears: Array<IFinancial>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRateDialog,
    private dialogRef: MatDialogRef<RateDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private financialService: FinancialService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.financialService.getFinancialYears().subscribe((years)=>{
      let actualYear: IFinancial = {
        FBF_POST_PERIOD_YEAR: new Date().getFullYear()
      }

      let exists: boolean = years.map(value => value.FBF_POST_PERIOD_YEAR).indexOf(actualYear.FBF_POST_PERIOD_YEAR) > 0;

      if(!exists) years.push(actualYear);

      this.financialYears = years;
    })

    this.form = this.formBuilder.group({
      year: new UntypedFormControl(this.data?.element.FRR_YEAR, Validators.required),
      rate: new UntypedFormControl(this.data?.element.FRR_RATE, Validators.required)
    });

    this.form.get('year').valueChanges.subscribe((year)=>{      
      this.form.get('rate').setValue('');
      this.financialService.getRate({FRR_YEAR: year}).subscribe((rate)=>{
        this.rate = rate[0];
        this.form.get('rate').setValue(rate[0]?.FRR_RATE);
      });
    });
  }

  close(): void {
    this.dialogRef.close([this.rate, this.requestType]);   
  }

  handleSubmit(e: Event): void {
    e.preventDefault();

    const newRate: IFinancialRate = {
      FRR_YEAR: this.form.value.year,
      FRR_RATE: this.form.value.rate,
      FRR_USER: this.authService.userId
    }

    if(this.rate){
      this.requestType = 1;
      this.rate.FRR_RATE = this.form.value.rate;
      this.rate.FRR_USER = this.authService.userId;
    }else{
      this.requestType = 2;    
      this.rate = newRate;
    }

    this.close();
  }

  getErrorMessage(formControl: string): string {
    if (this.form.get(formControl).hasError('required')) {
      return 'You must enter a value'; 
    } else if (this.form.get(formControl).hasError('pattern')) {
      return 'The value must be a number';   
    } else {
      return '';
    }
  }

}
