import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateDialogComponent } from './date-dialog/date-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OnholdDialogComponent } from './onhold-dialog/onhold-dialog.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { DemurrageExcelImportDialogComponent } from './demurrage-excel-import-dialog/demurrage-excel-import-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PrtlCtnDemurrageDialogComponent } from './prtl-ctn-demurrage-dialog/prtl-ctn-demurrage-dialog.component';
import { NewPrtlCtnDemurrageDialogComponent } from './new-prtl-ctn-demurrage-dialog/new-prtl-ctn-demurrage-dialog.component';

@NgModule({
  declarations: [
    DateDialogComponent,
    FilterDialogComponent,
    OnholdDialogComponent,
    DemurrageExcelImportDialogComponent,
    PrtlCtnDemurrageDialogComponent,
    NewPrtlCtnDemurrageDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    ComponentsModule,
    NgxMatSelectSearchModule,
    NgxDropzoneModule
  ]
})
export class DemurrageManualDialogsModule { }
