import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IState } from '../models/state.model';

@Injectable({
  providedIn: 'root'
})
export abstract class StateService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getState(state: IState = null): Observable<Array<IState>> {
    return this.httpClient.get<Array<IState>>(`${this.apiURL}/api/state${state ? '?filter=' + JSON.stringify(state) : ''}`, this.httpOptions);
  }
}