import { NgModule } from "@angular/core";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from "src/app/components/components.module";
import { ChangeStatusDialogComponent } from "./change-status-dialog/change-status-dialog.component";
import { FilterDialogComponent } from "./filter-dialog/filter-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxMatSelectSearchModule,
    ComponentsModule
  ],
  declarations: [
    ChangeStatusDialogComponent,
    FilterDialogComponent
  ]
})
export class ShipmentsDrtDialogsModule { }
