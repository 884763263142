import { Validators } from '@angular/forms';
import { IPaymentOrder } from '../../../../models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IPoExtraDialog {
  element: IPaymentOrder;
}

@Component({
  selector: 'dhl-po-extra-dialog',
  templateUrl: './po-extra-dialog.component.html',
  styleUrls: ['./po-extra-dialog.component.scss']
})
export class PoExtraDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPoExtraDialog,
    private dialogRef: MatDialogRef<PoExtraDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      totalBrl: new UntypedFormControl('', [Validators.required])
    });
  }

  close(value: IPaymentOrder): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newPaymentOrder: IPaymentOrder ={
      PO_ID: this.data.element.PO_ID,
      PO_VAL_BRL: this.form.value.totalBrl,
      PO_USER: this.authService.userId
    }

    this.close(newPaymentOrder);
  }

  getErrorMessage(formControl: string): string {
    if (this.form.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }
}
