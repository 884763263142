import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplyDiscountDialogComponent } from './apply-discount-dialog/apply-discount-dialog.component';
import { ChangeStatusDialogComponent } from './change-status-dialog/change-status-dialog.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { AttachmentDialogComponent } from './attachment-dialog/attachment-dialog.component';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { SelectedDetentionDatesComponent } from '../../detention-manual/dialogs/selected-detention-dates/selected-detention-dates.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomerExceptionInfoDialogComponent } from './customer-exception-info-dialog/customer-exception-info-dialog.component';

@NgModule({
  declarations: [
    ApplyDiscountDialogComponent,
    ChangeStatusDialogComponent,
    FilterDialogComponent,
    CommentDialogComponent,
    AttachmentDialogComponent,
    SelectedDetentionDatesComponent,
    CustomerExceptionInfoDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    ComponentsModule,
    NgxMatSelectSearchModule
  ]
})
export class InvoicesDialogsModule { }
