<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="customerForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput type="text" name="code" maxlength="20" formControlName="code" required>
        <mat-error *ngIf="customerForm.get('code').invalid">You must enter a code</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" name="name" maxlength="100" formControlName="name" required>
        <mat-error *ngIf="customerForm.get('name').invalid">You must enter a name</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>Address</mat-label>
        <input matInput type="text" name="address" maxlength="400" formControlName="address" required>
        <mat-error *ngIf="customerForm.get('address').invalid">You must enter an address</mat-error>
      </mat-form-field>      
    </div>
    <div class="form-group" *ngIf="data.mode != 'delete'">   
      <mat-form-field>  
        <mat-label>State</mat-label>
        <mat-select name="state" formControlName="state" required>
          <mat-option>
            <ngx-mat-select-search formControlName="stateFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let state of filteredStates | async" [value]="state.STT_CODE">
            {{state.STT_CODE}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="customerForm.get('state').invalid">You must select a state</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Branch</mat-label>
        <input matInput type="text" name="unloco" maxlength="200" formControlName="unloco" required>
        <mat-error *ngIf="customerForm.get('unloco').invalid">You must enter an unloco</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>CNPJ</mat-label>
        <input matInput type="text" name="tax" maxlength="200" formControlName="tax" required>
        <mat-error *ngIf="customerForm.get('tax').invalid">You must enter an CNPJ</mat-error>
      </mat-form-field>      
      <mat-form-field>
        <mat-label>Group</mat-label>
        <mat-select name="group" formControlName="group">
          <mat-option>
            <ngx-mat-select-search formControlName="groupFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customerGroup of filteredCustomerGroups | async" [value]="customerGroup.CUS_GRP_ID">
            {{customerGroup.CUS_GRP_NAME}}
          </mat-option>          
        </mat-select>
        <button type="button" mat-icon-button matSuffix (click)="customerForm.get('group').setValue(null);" *ngIf="!!customerForm.get('group').value">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the customer {{data.customer.CUS_NAME}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="areInputsValid()">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="areInputsValid()">Ok</button>
    </div>
  </form>
</div>