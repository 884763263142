import { IUserGroup } from 'src/app/models/user-group.model';
import { UserGroupService } from 'src/app/services/user-group.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { IPage } from 'src/app/models/page.model';
import { ReplaySubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

export interface IPagesDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  page?: IPage;
}

@Component({
  selector: 'dhl-page-dialog',
  templateUrl: './pages-dialog.component.html',
  styleUrls: ['./pages-dialog.component.scss']
})
export class PagesDialogComponent implements OnInit {
  isFormEnabled: boolean = true;
  pageForm: UntypedFormGroup;
  pageGroups: Array<IUserGroup>;
  filteredPages: ReplaySubject<Array<IUserGroup>> = new ReplaySubject<Array<IUserGroup>>(1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PagesDialogComponent>,
    private userGroupService: UserGroupService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public data: IPagesDialog,
  ) { }

  ngOnInit() {

    this.pageForm = this.formBuilder.group({
      name: new UntypedFormControl(this.data.page?.PAG_NAME,[Validators.required]),
      url: new UntypedFormControl(this.data.page?.PAG_URL, [Validators.required]),
      pageGroups: new UntypedFormControl((this.data.page?.GRP_LIST) ? (this.data.page.GRP_LIST.toString()).split(',')
        .map(Number): null),
      pageGroupFilter: new UntypedFormControl('')
    });

    this.getPageGroup();

    if(this.data.mode == 'edit'){
      this.isFormEnabled = false;
      this.pageForm.disable();
    }
  }

  getPageGroup(): void {
    this.userGroupService.getUserGroup().subscribe((pageGroups: Array<IUserGroup>) => {
      this.pageGroups = pageGroups;
      this.filteredPages.next(pageGroups);
      if (this.data.mode == 'add' || this.data.mode == 'edit') {
        this.pageForm.get('pageGroupFilter').valueChanges.subscribe(() => {
          this.filterPages();
        });
      }
    })
  }

  enableForm(): void {
    this.isFormEnabled = true;
    this.pageForm.get('name').enable();
    this.pageForm.get('url').enable();
    this.pageForm.get('pageGroups').enable();
  }

  filterPages(): void {
    const filterValue = this.pageForm.get('pageGroupFilter').value.toLowerCase();
    this.filteredPages.next(this.pageGroups.filter((group) => group.GRP_NAME.toLowerCase().includes(filterValue)));
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.pageForm.value;
    let page: IPage;

    if(this.data.mode === 'add') {
      page = {
        PAG_NAME: formValue.name,
        PAG_URL: formValue.url,
        PAG_STATUS: 1,
        PAG_USER: this.authService.userId,
        GRP_LIST: formValue.pageGroups
      }
    } else if (this.data.mode === 'edit') {
      page = {
        PAG_ID: this.data.page.PAG_ID,
        PAG_NAME: formValue.name,
        PAG_URL: formValue.url,
        PAG_STATUS: 1,
        PAG_USER: this.authService.userId,
        GRP_LIST: formValue.pageGroups
      }
    } else if (this.data.mode === 'delete') {
      page = {
        PAG_ID: this.data.page.PAG_ID,
        PAG_NAME: formValue.name,
        PAG_URL: formValue.url,
        PAG_STATUS: 0,
        PAG_USER: this.authService.userId,
        GRP_LIST: null
      }
    }

    this.closeDialog(page);
  }

  closeDialog(page: IPage){
    this.dialogRef.close(page);
  }

  areInputsValid(): boolean {
    const isNameInvalid = this.pageForm.get('name').invalid;
    const isUrlInvalid = this.pageForm.get('url').invalid;
    return isNameInvalid || isUrlInvalid;
  }
}
