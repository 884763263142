import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContainerTotal, DashboardRequest, InvoiceTotal, TopCustomers } from '../models/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getInvoiceDetention(filter: DashboardRequest): Observable<Array<InvoiceTotal>> {
    return this.httpClient.get<Array<InvoiceTotal>>(`${this.apiURL}/api/dashboard/detention-invoices?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getInvoiceDemurrage(filter: DashboardRequest): Observable<Array<InvoiceTotal>> {
    return this.httpClient.get<Array<InvoiceTotal>>(`${this.apiURL}/api/dashboard/demurrage-invoices?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getTotalInvoices(filter: DashboardRequest): Observable<Array<InvoiceTotal>> {
    return this.httpClient.get<Array<InvoiceTotal>>(`${this.apiURL}/api/dashboard/invoices?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getInvoicesAverage(filter: DashboardRequest): Observable<Array<InvoiceTotal>> {
    return this.httpClient.get<Array<InvoiceTotal>>(`${this.apiURL}/api/dashboard/avg-invoices?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getContainerDetention(filter: DashboardRequest): Observable<Array<ContainerTotal>> {
    return this.httpClient.get<Array<ContainerTotal>>(`${this.apiURL}/api/dashboard/detention-containers?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getContainerDemurrage(filter: DashboardRequest): Observable<Array<ContainerTotal>> {
    return this.httpClient.get<Array<ContainerTotal>>(`${this.apiURL}/api/dashboard/demurrage-containers?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getTopCustomers(filter: DashboardRequest): Observable<Array<TopCustomers>> {
    return this.httpClient.get<Array<TopCustomers>>(`${this.apiURL}/api/dashboard/top-customers?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }
}
