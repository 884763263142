<header>
  <h2>User - Pages</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="deleteSelected()">
          <mat-icon>delete</mat-icon>
          <span>Delete All</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="openPagesDialog({title: 'Add page', mode: 'add'})">Create Page</button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="pagesTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #pagesTable [dataSource]="pagesTableDataSource" matSort #pagesSort="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="PAG_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
        <td mat-cell *matCellDef="let element">{{element.PAG_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="PAG_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
        <td [matTooltipDisabled]="!isOverflow(element.PAG_NAME)" [matTooltip]="element.PAG_NAME" mat-cell *matCellDef="let element">{{element.PAG_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="PAG_URL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">URL</th>
        <td [matTooltipDisabled]="!isOverflow(element.PAG_URL)" [matTooltip]="element.PAG_URL" mat-cell *matCellDef="let element">{{element.PAG_URL}}</td>
      </ng-container>
      <ng-container matColumnDef="pageGroups">
        <th mat-header-cell *matHeaderCellDef>Groups</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="openPagesViewDialog({page: element})" *ngIf="element.GRP_NAME_LIST" >View Groups</button>
          <p class="empty-groups" *ngIf="element.GRP_NAME_LIST == null">Empty Group</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" (click)="openPagesDialog({title: 'Edit page', mode: 'edit', page: element})">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button mat-icon-button (click)="openPagesDialog({title: 'Delete page', mode: 'delete', page: element})">
            <mat-icon color="primary">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="pagesTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: pagesTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator #pagesPaginator [pageSizeOptions]="[5, 10, 15, 20]" pageSize="20" showFirstLastButtons aria-label="Select page of Pages"></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
