import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dhl-page-action-dialog',
  templateUrl: './page-action-dialog.component.html',
  styleUrls: ['./page-action-dialog.component.scss']
})
export class PageActionDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
