import { Injectable } from '@angular/core';
import { ContainerIntegration } from '../models/container-integration';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContainerIntegrationService {

  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };

  putContainerIntegrationExcel(containers: ContainerIntegration[]): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/container/dates/integration`, containers, this.httpOptions);
  }

}
