<div class="parameter-group">
  <div class="parameter-heading">
    <h3>Container Partial ({{data.container.CTN_NUMBER}})</h3>
    <div class="form-group">
      <mat-form-field class="parameter-search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [(ngModel)]="filterPrtlCtnValue" (input)="filterPrtlCtnTable(filterPrtlCtnValue)">
        <button type="button" mat-icon-button matSuffix (click)="filterPrtlCtnValue = ''; filterPrtlCtnTable(filterPrtlCtnValue)" *ngIf="filterPrtlCtnValue != ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-stroked-button color="accent" class="btn-export" (click)="exportPrtlCtn()" [disabled]="prtlCtnTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
  <div class="parameter-body">
    <ng-container *ngIf="hideLoader; else loader">
      <table mat-table #regionTable [dataSource]="prtlCtnTableDataSource" matSort #customerContactSort="matSort">
        <ng-container matColumnDef="PRTL_CTN_ORDER">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Partial Order">Partial Order</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRTL_CTN_ORDER)" [matTooltip]="element.PRTL_CTN_ORDER" mat-cell *matCellDef="let element">{{element.PRTL_CTN_ORDER}}</td>
        </ng-container>        
        <ng-container matColumnDef="PRTL_CTN_GOU">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate Out">Gate Out</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRTL_CTN_GOU)" [matTooltip]="element.PRTL_CTN_GOU" mat-cell *matCellDef="let element">{{element.PRTL_CTN_GOU | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="PRTL_CTN_GIN">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate In">Gate In</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRTL_CTN_GIN)" [matTooltip]="element.PRTL_CTN_GIN" mat-cell *matCellDef="let element">{{element.PRTL_CTN_GIN | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="PRTL_CTN_LDG">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRTL_CTN_LDG)" [matTooltip]="element.PRTL_CTN_LDG" mat-cell *matCellDef="let element">{{element.PRTL_CTN_LDG | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="PRTL_CTN_PROCESS_STATUS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Process Status">Process Status</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRTL_CTN_PROCESS_STATUS)" [matTooltip]="element.PRTL_CTN_PROCESS_STATUS" mat-cell *matCellDef="let element">{{getStatusProcess(element.PRTL_CTN_PROCESS_STATUS)}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="prtlCtnTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: prtlCtnTableDisplayedColumns;"></tr>
      </table>
    </ng-container>
    <ng-template #loader>
      <!-- <dhl-spinner></dhl-spinner> -->
    </ng-template>
  </div>
  <div class="parameter-footer">
    <button mat-icon-button (click)="getPrtlCtn()">
      <mat-icon color="primary">refresh</mat-icon>
    </button>
    <mat-paginator #prtlCtnPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label="Select page of containers"></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button type="button" color="accent" (click)="closeDialog()">Close</button>
</div>
