import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IGetLog, ILog } from 'src/app/models/log.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { LogService } from 'src/app/services/log.service';
import { SelectionModel } from '@angular/cdk/collections';
import { LogsFilterDialogComponent } from './logs-dialogs/logs-filter-dialog/logs-filter-dialog.component';
import { XlsxExporterService } from 'mat-table-exporter';
import moment from 'moment';
import { FilterService } from 'src/app/services/filter.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dhl-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  pageKey = 'logs';
  hideLoader: boolean;

  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');

  filtersNumber: number = 0;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<ILog>(this.allowMultiSelect, this.initialSelection);
  logFilters: IGetLog = {
    LOG_USER: '',
    LOG_GROUP: '',
    LOG_START_DATETIME: '',
    LOG_FINAL_DATETIME: ''
  };

  logTableDataSource = new MatTableDataSource<ILog>();
  logTableDisplayedColumns: Array<string> = ['select', 'LOG_ID', 'LOG_USER', 'LOG_ACTION', 'LOG_GROUP', 'LOG_ENTITY', 'LOG_DATETIME', 'changes'];

  @ViewChild(MatTable) logTable: MatTable<ILog>;
  @ViewChild('logSort') logSort: MatSort;
  @ViewChild('logPaginator') logPaginator: MatPaginator;

  constructor(
    private titleService: Title,
    private logService: LogService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private exporter: XlsxExporterService,
    private filterService: FilterService
  ) {
    this.titleService.setTitle('DHL | Logs (DDM)');
  }

  ngOnInit(): void {
    const filter = this.filterService.getFilter('log');
    if (filter) {
      this.logFilters = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.getLogs(this.logFilters);

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.filterCustomerTable(value);
    });
  }

  getLogs(logFilters: IGetLog): void {
    if(!this.logTableDataSource.filteredData) this.hideLoader = false;
    this.logFilters.LOG_START_DATETIME == "" ? this.logFilters.LOG_START_DATETIME = moment().subtract(1, 'days').format("MM/DD/YYYY").toString() : null;
    this.logFilters.LOG_FINAL_DATETIME == "" ? this.logFilters.LOG_FINAL_DATETIME = moment().format("MM/DD/YYYY").toString() : null;
    this.logService.getLogs(logFilters).subscribe((logs: Array<ILog>) => {
      this.hideLoader = true;
      this.logTableDataSource.data = logs;
      this.changeDetectorRef.detectChanges();
      this.logTableDataSource.sort = this.logSort;
      this.logTableDataSource.paginator = this.logPaginator;

      this.filterCustomerTable(this.globalFilter.value);
    });
  }

  filterCustomerTable(filter: string): void {
    this.logTableDataSource.filter = filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.logTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.logTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  setFilters(): void {
    const dialogRef = this.dialog.open(LogsFilterDialogComponent, {
      data: this.logFilters
    });

    dialogRef.afterClosed().subscribe((filters: IGetLog) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.logFilters.LOG_USER = filters.LOG_USER;
      this.logFilters.LOG_GROUP = filters.LOG_GROUP;
      this.logFilters.LOG_START_DATETIME = filters.LOG_START_DATETIME;
      this.logFilters.LOG_FINAL_DATETIME = filters.LOG_FINAL_DATETIME;

      this.filterService.setFilter('log', this.logFilters);

      this.getLogs(this.logFilters);
    });
  }

  export(): void {
    const headers = {
      LOG_USER: 'USER',
      LOG_ACTION: 'ACTION',
      LOG_GROUP: 'GROUP',
      LOG_ENTITY: 'ENTITY',
      LOG_DATETIME: 'DATE'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<ILog> = this.prepareDataToExport([...this.logTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<ILog> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `logs_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<ILog>): Array<ILog> {
    const dataToExport: Array<ILog> = [];

    data.forEach((invoice) => {
      const newData = {...invoice};

      if(newData.LOG_DATETIME !== null) {
        newData.LOG_DATETIME = moment(newData.LOG_DATETIME).format('DD/MM/YYYY');
      }

      delete newData.LOG_ID;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
