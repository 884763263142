import { Observable } from 'rxjs';
import { IShipment, IException, IMasterbill, IContainer, IShipmentList } from '../models/shipment.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class ShipmentDrtService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };

  getShipmentDrt(shipment: IShipment): Observable<Array<IShipment>> {
    return this.httpClient.get<Array<IShipment>>(`${this.apiURL}/api/shipment/drt?filter=${JSON.stringify(shipment)}`, this.httpOptions);
  }

  postShipmentDrt(shipments: Array<IShipment>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/shipment/drt`, shipments, this.httpOptions);
  }

}
