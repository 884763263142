<h1 mat-dialog-title>Comment</h1>
<form (submit)="handleSubmit($event)">
  <div mat-dialog-content class="wide">
    <dhl-comment [showCommentsTab]="false" (comment)="onComment($event)"></dhl-comment>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="!comment">Apply</button>
    </div>
  </div>
</form>
