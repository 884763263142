import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';
import { Moment } from 'moment';
import { IContainer } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';


export interface IDateDialog {
  element: IContainer;
  willDelete: boolean;
}

@Component({
  selector: 'dhl-date-dialog',
  templateUrl: './date-dialog.component.html',
  styleUrls: ['./date-dialog.component.scss']
})
export class DateDialogComponent implements OnInit {
  dateForm: UntypedFormGroup = this.formBuilder.group({
    gateOut: new UntypedFormControl(this.data.element.CTN_GOU),
    gateIn: new UntypedFormControl(this.data.element.CTN_GIN),
    loading: new UntypedFormControl(this.data.element.CTN_LDG)
  });;

  maxGateOut: Date = this.dateForm.get('gateIn').value || this.dateForm.get('loading').value || new Date();
  minGateIn: Date = this.dateForm.get('gateOut').value;
  maxGateIn: Date = this.dateForm.get('loading').value || new Date();
  minLoading: Date = this.dateForm.get('gateIn').value || this.dateForm.get('gateOut').value;
  maxLoading: Date = new Date();

  isPartial = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DateDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<IContainer>,
  ) { }

  ngOnInit(): void {
    this.isPartial = Number(this.data.element.CTN_IS_PARTIAL) === 1;

    this.dateForm.get('gateOut').valueChanges.subscribe((date: Date) => {
      this.minGateIn = date;
      this.minLoading = this.dateForm.get('gateIn').value || date;
    });

    this.dateForm.get('gateIn').valueChanges.subscribe((date: Date) => {
      this.maxGateOut = date || this.dateForm.get('loading').value || new Date();
      this.minLoading = date || this.dateForm.get('gateOut').value;
    });

    this.dateForm.get('loading').valueChanges.subscribe((date: Date) => {
      this.maxGateOut = this.dateForm.get('gateIn').value || date || new Date();
      this.maxGateIn = date || new Date();
    });
  }

  closeDialog(date: IDateDialog): void {
    this.dialogRef.close(date);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    if (this.data.mode === 'add' || this.data.mode === 'edit') {
      
      const container: IContainer = {
        SHIP_ID: this.data.element.SHIP_ID,
        CTN_ID: this.data.element.CTN_ID,
        CTN_NUMBER: this.data.element.CTN_NUMBER,
        CTN_GOU: this.dateForm.get('gateOut').value,
        CTN_GIN: this.dateForm.get('gateIn').value,
        CTN_LDG: this.dateForm.get('loading').value,
        CTN_USER: this.authService.userId
      }

      const dates: IDateDialog = {
        element: container,
        willDelete: false
      }
      this.closeDialog(dates);
    } else if (this.data.mode === 'delete') {

      const container: IContainer = {
        SHIP_ID: this.data.element.SHIP_ID,
        CTN_ID: this.data.element.CTN_ID,
        CTN_NUMBER: this.data.element.CTN_NUMBER,
        CTN_GOU: this.data.date !== 'GOU' ? this.dateForm.get('gateOut').value : null,
        CTN_GIN: this.data.date !== 'GIN' ? this.dateForm.get('gateIn').value : null,
        CTN_LDG: this.data.date !== 'LDG' ? this.dateForm.get('loading').value : null,
        CTN_USER: this.authService.userId
      }

      const dates: IDateDialog = {
        element: container,
        willDelete: true
      }

      this.closeDialog(dates);
    }
  }
}
