import { Component } from '@angular/core';

@Component({
  selector: 'dhl-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent {

  constructor() { }

}
