import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';
import { IContainerType } from 'src/app/models/parameter.model';
import { IContainer } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'dhl-containers-dialog',
  templateUrl: './containers-dialog.component.html',
  styleUrls: ['./containers-dialog.component.scss']
})
export class ContainersDialogComponent implements OnInit {
  destiny: string;

  containerForm: UntypedFormGroup;

  containerTypes: Array<IContainerType>;
  filteredContainerTypes: Subject<Array<IContainerType>> = new Subject<Array<IContainerType>>();
  fieldsLocked = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ContainersDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<IContainer>,
    private parameterService: ParameterService
  ) { }

  ngOnInit(): void {
    this.parameterService.getContainerType().subscribe((containerTypes) => {
      this.containerTypes = containerTypes;
      this.filteredContainerTypes.next(containerTypes);
      this.destiny = this.data.destiny;
    });

    this.containerForm = this.formBuilder.group({
      number: new UntypedFormControl(this.data.element?.CTN_NUMBER),
      type: new UntypedFormControl(this.data.element?.CTN_TYPE),
      typeFilter: new UntypedFormControl(''),
      gou: new UntypedFormControl(this.data.element?.CTN_GOU),
      gin: new UntypedFormControl(this.data.element?.CTN_GIN),
      ldg: new UntypedFormControl(this.data.element?.CTN_LDG)
    });

    this.containerForm.get('typeFilter').valueChanges.subscribe((value) => {
      this.filterContainerTypes(value);
    });

    this.lockFields();
  }

  closeDialog(container: IContainer): void {
    this.dialogRef.close(container);
  }

  filterContainerTypes(value: string): void {
    this.filteredContainerTypes.next(this.containerTypes.filter((containerType) => {
      return containerType.CONTYPPF_QAMPZ.toLowerCase().includes(value.toLowerCase());
    }));
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.containerForm.value;
    let newContainer: IContainer;

    if (this.data.mode === 'add') {
      newContainer = {
        CTN_ID: null,
        CTN_NUMBER: formValue.number,
        CTN_TYPE: formValue.type,
        CTN_GOU: formValue.gou,
        CTN_GIN: formValue.gin,
        CTN_LDG: formValue.ldg,
        CTN_STATUS: 1,
        CTN_USER: this.authService.userId
      }
    } else if (this.data.mode === 'edit') {
      newContainer = {
        CTN_ID: this.data.element.CTN_ID,
        CTN_NUMBER: formValue.number,
        CTN_TYPE: formValue.type,
        CTN_GOU: formValue.gou,
        CTN_GIN: formValue.gin,
        CTN_LDG: formValue.ldg,
        CTN_STATUS: 1,
        CTN_USER: this.authService.userId
      }
    } else if (this.data.mode === 'delete') {
      newContainer = {
        CTN_ID: this.data.element.CTN_ID,
        CTN_NUMBER: null,
        CTN_TYPE: null,
        CTN_GOU: null,
        CTN_GIN: null,
        CTN_LDG: null,
        CTN_STATUS: 0,
        CTN_USER: this.authService.userId
      }
    }

    this.closeDialog(newContainer);
  }

  lockFields(){
    if(this.data.element.CTN_PROCESS_STATUS == 9) {
      this.fieldsLocked = true;
    }
  }
}
