<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="customerContactForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" name="name" maxlength="20" formControlName="name" required>
        <mat-error *ngIf="customerContactForm.get('name').invalid">You must enter a name</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" name="email" maxlength="100" formControlName="email" required>
        <mat-error *ngIf="customerContactForm.get('email').invalid">You must enter a email</mat-error>
      </mat-form-field>
    </div>    
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the customer contact {{data.customerContact.CONTACT_NAME}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="areInputsValid()">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="areInputsValid()">Ok</button>
    </div>
  </form>
</div>