import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { HotToastService } from '@ngneat/hot-toast';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { concatMap } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';

export interface jwtToken {
  name: string,
  nbf: number,
  exp: number,
  iat: number
}

@Component({
  selector: 'dhl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hidePassword = true;
  loginForm: UntypedFormGroup;
  baseUrl: string = window.location.origin;

  hash: string = '';

  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toast: HotToastService,
    private httpClient: HttpClient
  ) {
    this.titleService.setTitle('DHL | Login');
  }

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      user: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required])
    });
    
    this.route.params.subscribe((params) => this.hash = params.hash);
    if (this.hash) {
      this.authService.loginFromHash(this.hash)
      .pipe(
        concatMap((response) => {
          return combineLatest([
            of(response),
            this.authService.getCredentials(response.access_token)
          ]);
        })
      )
      .subscribe(([login, user]) => {
        localStorage.setItem('token_type', login.token_type);
        localStorage.setItem('access_token', login.access_token);
        localStorage.setItem('expires_in', login.expires_in.toString());
        localStorage.setItem('expiry', (((new Date).getTime() / 1000) + parseInt(login['expires_in'].toString())).toString());

        this.authService.userId = user.USER_ID.toUpperCase();
        this.authService.userName = user.USER_NAME;
        this.authService.userAllowedUrls = user.PAG_URL.split(', ');
        this.authService.isUserAdmin = user.USER_IS_ADMIN === 'Y';
        this.authService.hasLoadedInfo = true;

        this.toast.success('User logged in successfully.');
        this.router.navigate(['/']);
      }, () => {
        this.toast.error('Token invalid.');
      });
    }
  }

  login(e: Event): void {
    e.preventDefault();
    const formValue = this.loginForm.value;
    const user: IUser = {
      PI_USER_ID: formValue.user,
      PI_USER_PASS: formValue.password
    }

    this.authService.login(user)
      .pipe(
        concatMap((response) => {
          return combineLatest([
            of(response),
            this.authService.getCredentials(response.access_token)
          ]);
        })
      )
      .subscribe(([login, user]) => {
        localStorage.setItem('token_type', login.token_type);
        localStorage.setItem('access_token', login.access_token);
        localStorage.setItem('expires_in', login.expires_in.toString());
        localStorage.setItem('expiry', (((new Date).getTime() / 1000) + parseInt(login['expires_in'].toString())).toString());

        this.authService.userId = user.USER_ID.toUpperCase();
        this.authService.userName = user.USER_NAME;
        this.authService.userAllowedUrls = user.PAG_URL.split(', ');
        this.authService.isUserAdmin = user.USER_IS_ADMIN === 'Y';
        this.authService.hasLoadedInfo = true;

        this.toast.success('User logged in successfully.');
        this.router.navigate(['/']);
      }, () => {        
        this.toast.error('User or password incorrect.');
      });
  }

  getUserErrorMessage(): string {
    if (this.loginForm.get('user').hasError('required')) {
      return 'You must enter an email';
    } else if (this.loginForm.get('user').hasError('email')) {
      return 'Not a valid email';
    } else {
      return '';
    }
  }

  getPasswordErrorMessage(): string {
    if (this.loginForm.get('password').hasError('required')) {
      return 'You must enter a password';
    } else {
      return '';
    }
  }
}
