import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { CustomerGroupService } from 'src/app/services/customer-group.service';

@Component({
  selector: 'dhl-customer-action-dialog',
  templateUrl: './customers-action-dialog.component.html',
  styleUrls: ['./customers-action-dialog.component.scss']
})
export class CustomerActionDialogComponent implements OnInit {
  groupForm: UntypedFormGroup;
  customerGroups: Array<ICustomerGroup> = [];
  filteredCustomerGroups: ReplaySubject<Array<ICustomerGroup>> = new ReplaySubject<Array<ICustomerGroup>>(1);

  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<CustomerActionDialogComponent>, private customerGroupService: CustomerGroupService) { }

  ngOnInit(): void {
    this.getCustomerGroup();

    this.groupForm = this.formBuilder.group({
      group: new UntypedFormControl('', Validators.required),
      groupFilter: new UntypedFormControl('')
    });

    this.groupForm.get('groupFilter').valueChanges.subscribe(() => {
      this.filterGroups();
    });
  }
  
  getCustomerGroup(): void {
    this.customerGroupService.getCustomerGroup().subscribe((customerGroups: Array<ICustomerGroup>) => {
      this.customerGroups = customerGroups;
      this.filteredCustomerGroups.next(customerGroups);
    });
  }

  filterGroups(): void {
    const filterValue = this.groupForm.get('groupFilter').value.toLowerCase();
    this.filteredCustomerGroups.next(this.customerGroups.filter((group) => group.CUS_GRP_NAME.toLowerCase().includes(filterValue)));
  }

  closeDialog(customerGroup: ICustomerGroup) {
    this.dialogRef.close(customerGroup);
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.groupForm.value;
    let customerGroup: ICustomerGroup = null;

    customerGroup = {
      CUS_GRP_ID: formValue.group
    }

    this.closeDialog(customerGroup);
  }
}
