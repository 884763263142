<h1 mat-dialog-title>Filter RPA Logs</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>ID</mat-label>
        <input matInput type="text" name="id" formControlName="id">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('id')" (click)="resetField('id')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">      
      <mat-form-field>
        <mat-label>HBL</mat-label>
        <input matInput type="text" name="hbl" formControlName="hbl">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('hbl')" (click)="resetField('hbl')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Shipment ID</mat-label>
        <input matInput type="text" name="ship" formControlName="ship">
        <button mat-icon-button type="button" matSuffix *ngIf="fieldHasValue('ship')" (click)="resetField('ship')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Message</mat-label>
        <mat-select name="message" formControlName="message">
          <mat-option *ngFor="let message of messageList" [value]="message?.LI_MESSAGE">{{message?.LI_MESSAGE}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('message')" *ngIf="fieldHasValue('message')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status Item</mat-label>
        <mat-select name="statusItem" formControlName="statusItem">
          <mat-option *ngFor="let status of statusList" [value]="status?.LI_STATUS_ITEM">{{status?.LI_STATUS_ITEM | titlecase }}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('statusItem')" *ngIf="fieldHasValue('statusItem')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Month</mat-label>
        <mat-select name="month" formControlName="month">
          <mat-option value="1">January</mat-option>
          <mat-option value="2">February</mat-option>
          <mat-option value="3">March</mat-option>
          <mat-option value="4">April</mat-option>
          <mat-option value="5">May</mat-option>
          <mat-option value="6">June</mat-option>
          <mat-option value="7">July</mat-option>
          <mat-option value="8">August</mat-option>
          <mat-option value="9">September</mat-option>
          <mat-option value="10">October</mat-option>
          <mat-option value="11">November</mat-option>
          <mat-option value="12">December</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('month')" *ngIf="fieldHasValue('month')">
          <mat-icon color="primary">close</mat-icon>    
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Year</mat-label>
        <mat-select name="year" formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('year')" *ngIf="fieldHasValue('year')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="resetFilters()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>