import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IExceptionDeleteDialog {
  id: number;
}

@Component({
  selector: 'dhl-exception-delete-dialog',
  templateUrl: './exception-delete-dialog.component.html',
  styleUrls: ['./exception-delete-dialog.component.scss']
})
export class ExceptionDeleteDialogComponent {
  id: number;

  constructor(
    public dialogRef: MatDialogRef<ExceptionDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IExceptionDeleteDialog
  ) {
    this.id = data.id;
  }

  closeDialog(willDelete: boolean): void {
    this.dialogRef.close(willDelete);
  }

}
