<section>
  <div class="expandable">
    <button type="button" class="open-expandable" [ngClass]="{'open': isShipmentInformationOpen}" (click)="isShipmentInformationOpen = !isShipmentInformationOpen">
      <span>Shipment Information</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isShipmentInformationOpen}">
      <form [formGroup]="shipmentForm" (submit)="onShipmentFormSubmit($event)">
        <div class="form-group">
          <mat-form-field>
            <mat-label>House Bill of Landing</mat-label>
            <input matInput name="hbl" formControlName="hbl">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Shipment ID</mat-label>
            <input matInput name="code" formControlName="code">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Reference</mat-label>
            <input matInput name="reference" formControlName="reference">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Company Context</mat-label>
            <input matInput name="companyCode" formControlName="companyCode" maxlength="5">
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Country Context</mat-label>
            <mat-select name="countryCode" formControlName="countryCode">
              <mat-option>
                <ngx-mat-select-search formControlName="countryCodeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('countryCode').setValue('')" *ngIf="!!shipmentForm.get('countryCode').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Packline Commodity Code</mat-label>
            <input matInput name="commodityCode" formControlName="commodityCode">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Declaration Container Mode</mat-label>
            <input matInput name="containerMode" formControlName="containerMode">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Product Code</mat-label>
            <input matInput name="departmentCode" formControlName="departmentCode">
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Delivery Agent Country</mat-label>
            <mat-select name="deliveryCountry" formControlName="deliveryCountry">
              <mat-option>
                <ngx-mat-select-search formControlName="deliveryCountryFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('deliveryCountry').setValue('')" *ngIf="!!shipmentForm.get('deliveryCountry').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Inconterm</mat-label>
            <input matInput name="incoterms" formControlName="incoterms">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Pickup Agent Country</mat-label>
            <mat-select name="pickupCountry" formControlName="pickupCountry">
              <mat-option>
                <ngx-mat-select-search formControlName="pickupCountryFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('pickupCountry').setValue('')" *ngIf="!!shipmentForm.get('pickupCountry').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Method of Transport</mat-label>
            <mat-select name="transportMode" formControlName="transportMode">
              <mat-option value="sea">SEA</mat-option>
              <mat-option value="roa">ROA</mat-option>
              <mat-option value="rai">RAI</mat-option>
              <mat-option value="air">AIR</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Shipment ETA</mat-label>
            <input matInput name="eta" formControlName="eta" [matDatepicker]="etaPicker">
            <mat-datepicker-toggle matSuffix [for]="etaPicker"></mat-datepicker-toggle>
            <mat-datepicker #etaPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Shipment ETD</mat-label>
            <input matInput name="etd" formControlName="etd" [matDatepicker]="etdPicker">
            <mat-datepicker-toggle matSuffix [for]="etdPicker"></mat-datepicker-toggle>
            <mat-datepicker #etdPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Shipment ATA</mat-label>
            <input matInput name="ata" formControlName="ata" [matDatepicker]="ataPicker" [disabled]="fieldsLocked">
            <mat-datepicker-toggle matSuffix [for]="ataPicker"></mat-datepicker-toggle>
            <mat-datepicker #ataPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Shipment ATD</mat-label>
            <input matInput name="atd" formControlName="atd" [matDatepicker]="atdPicker">
            <mat-datepicker-toggle matSuffix [for]="atdPicker"></mat-datepicker-toggle>
            <mat-datepicker #atdPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Consignee</mat-label>
            <mat-select name="consignee" formControlName="consignee" [disabled]="fieldsLocked">
              <mat-option>
                <ngx-mat-select-search formControlName="consigneeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let consignee of consignees" [value]="consignee.CUS_ID">{{consignee.CUS_CODE}} - {{consignee.CUS_NAME}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('consignee').setValue('')" *ngIf="!!shipmentForm.get('consignee').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Local Client</mat-label>
            <mat-select name="localClient" formControlName="localClient" [disabled]="fieldsLocked">
              <mat-option>
                <ngx-mat-select-search formControlName="localClientFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_CODE}} - {{customer.CUS_NAME}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('localClient').setValue(''); shipmentForm.get('localClientFilter').setValue('')" *ngIf="!!shipmentForm.get('localClient').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Notify Party</mat-label>
            <mat-select name="notifyParty" formControlName="notifyParty" [disabled]="fieldsLocked">
              <mat-option>
                <ngx-mat-select-search formControlName="notifyPartyFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let notify of notifies" [value]="notify.CUS_ID">{{notify.CUS_CODE}} - {{notify.CUS_NAME}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('notifyParty').setValue(''); shipmentForm.get('notifyPartyFilter').setValue('')" *ngIf="!!shipmentForm.get('notifyParty').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Shipper</mat-label>
            <mat-select name="shipper" formControlName="shipper">
              <mat-option>
                <ngx-mat-select-search formControlName="shipperFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let shipper of shippers" [value]="shipper.CUS_ID">{{shipper.CUS_CODE}} - {{shipper.CUS_NAME}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('shipper').setValue(''); shipmentForm.get('shipperFilter').setValue('')" *ngIf="!!shipmentForm.get('shipper').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Port of Loading</mat-label>
            <mat-select name="pol" formControlName="pol" [disabled]="fieldsLocked">
              <mat-option>
                <ngx-mat-select-search formControlName="polFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let port of filteredPorts | async" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('pol').setValue('')" *ngIf="!!shipmentForm.get('pol').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Port of Discharge</mat-label>
            <mat-select name="pod" formControlName="pod" [disabled]="fieldsLocked">
              <mat-option>
                <ngx-mat-select-search formControlName="podFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let port of filteredPorts | async" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
            </mat-select>
            <button type="button" mat-icon-button matSuffix (click)="shipmentForm.get('pod').setValue('')" *ngIf="!!shipmentForm.get('pod').value" [disabled]="shipmentAction === 'detail'">
              <mat-icon color="primary">close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="actions-group">
          <button mat-flat-button color="accent" type="submit" [disabled]="shipmentForm.disabled" *ngIf="shipmentAction !== 'detail'">Save Shipment</button>
        </div>
      </form>
    </div>
  </div>
  <div class="expandable">
    <button type="button" class="open-expandable" [ngClass]="{'open': isExceptionsOpen}" (click)="isExceptionsOpen = !isExceptionsOpen" [disabled]="!shipment">
      <span>Exceptions</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isExceptionsOpen || !shipment}">
      <div class="table-container">
        <table mat-table #exceptionsTable [dataSource]="exceptionsTableDataSource" matSort #exceptionsSort="matSort" *ngIf="exceptionsTableDataSource.data.length !== 0">
          <ng-container matColumnDef="EXC_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">ID</th>
            <td mat-cell *matCellDef="let element">{{element.EXC_ID}}</td>
          </ng-container>
          <ng-container matColumnDef="EXC_DESC">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Description</th>
            <td mat-cell *matCellDef="let element">{{element.EXC_DESC}}</td>
          </ng-container>
          <ng-container matColumnDef="EXC_CODE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">Code</th>
            <td mat-cell *matCellDef="let element">{{element.EXC_CODE}}</td>
          </ng-container>
          <ng-container matColumnDef="EXC_STAFF_CODE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Staff Code">Staff Code</th>
            <td mat-cell *matCellDef="let element">{{element.EXC_STAFF_CODE}}</td>
          </ng-container>
          <ng-container matColumnDef="EXC_INC_DATETIME">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort Timestamp">Timestamp</th>
            <td mat-cell *matCellDef="let element">{{element.EXC_INC_DATETIME | date:'dd/MM/YYYY'}}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="shipmentAction !== 'detail'">
                <button mat-icon-button (click)="openExceptionsDialog({mode: 'edit', element})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openExceptionsDialog({mode: 'delete', element})">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="exceptionsTableDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: exceptionsTableDisplayedColumns;"></tr>
        </table>
        <div class="actions-container" [ngClass]="{right: shipmentAction === 'detail'}">
          <button mat-flat-button color="accent" (click)="openExceptionsDialog({mode: 'add'})" *ngIf="shipmentAction !== 'detail'">Add Exception</button>
          <mat-paginator #exceptionsPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label *ngIf="exceptionsTableDataSource.data.length !== 0"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <div class="expandable">
    <button type="button" class="open-expandable" [ngClass]="{'open': isMasterbillsOpen}" (click)="isMasterbillsOpen = !isMasterbillsOpen" [disabled]="!shipment">
      <span>Master Bills</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isMasterbillsOpen || !shipment}">
      <div class="table-container">
        <table mat-table #masterbillTable [dataSource]="masterbillTableDataSource" matSort #masterbillSort="matSort" *ngIf="masterbillTableDataSource.data.length !== 0">
          <ng-container matColumnDef="MBL_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">ID</th>
            <td mat-cell *matCellDef="let element">{{element.MBL_ID}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_NUMBER">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by MBL">MBL</th>
            <td mat-cell *matCellDef="let element">{{element.MBL_NUMBER}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_CONSOL_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Consol ID">Consol ID</th>
            <td mat-cell *matCellDef="let element">{{element.MBL_CONSOL_ID}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_CONSOL_TYPE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Consol Type">Consol Type</th>
            <td mat-cell *matCellDef="let element">{{element.MBL_CONSOL_TYPE}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_VES_NAME">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Vessel">Vessel</th>
            <td mat-cell *matCellDef="let element">{{element.MBL_VES_NAME}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_VES_ARR_DATE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Vessel Arrival Date">Vessel Arrival Date</th>
            <td mat-cell *matCellDef="let element">{{date(element.MBL_VES_ARR_DATE)}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_VES_DEP_DATE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Vessel Departure Date">Vessel Departure Date</th>
            <td mat-cell *matCellDef="let element">{{date(element.MBL_VES_DEP_DATE)}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_VOY_FLT_NUMBER">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Voyage">Voyage</th>
            <td mat-cell *matCellDef="let element">{{element.MBL_VOY_FLT_NUMBER}}</td>
          </ng-container>
          <ng-container matColumnDef="MBL_CAR">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
            <td mat-cell *matCellDef="let element">{{element.MBL_CAR_NAME}}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="shipmentAction !== 'detail'">
                <button mat-icon-button (click)="openMasterbillDialog({mode: 'edit', element})">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openMasterbillDialog({mode: 'delete', element})" *ngIf="fieldsLocked == false">
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="masterbillTableDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: masterbillTableDisplayedColumns;"></tr>
        </table>
        <div class="actions-container" [ngClass]="{right: shipmentAction === 'detail'}">
          <button mat-flat-button color="accent" (click)="openMasterbillDialog({mode: 'add'})" *ngIf="shipmentAction !== 'detail'">Add Masterbill</button>
          <mat-paginator #masterbillPaginator [pageSizeOptions]="[5]" showFirstLastButtons aria-label *ngIf="masterbillTableDataSource.data.length !== 0"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <div class="expandable">
    <button type="button" class="open-expandable" [ngClass]="{'open': isContainersOpen}" (click)="isContainersOpen = !isContainersOpen" [disabled]="!shipment">
      <span>Containers</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isContainersOpen || !shipment}">
      <div class="table-container">
        <table mat-table #containersTable [dataSource]="containersTableDataSource" matSort #containersSort="matSort" *ngIf="containersTableDataSource.data.length !== 0">
          <ng-container matColumnDef="CTN_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">ID</th>
            <td mat-cell *matCellDef="let element">{{element.CTN_ID}}</td>
          </ng-container>
          <ng-container matColumnDef="CTN_NUMBER">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Number">Number</th>
            <td mat-cell *matCellDef="let element">{{element.CTN_NUMBER}}</td>
          </ng-container>
          <ng-container matColumnDef="CTN_TYPE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type">Type</th>
            <td mat-cell *matCellDef="let element">{{element.CTN_TYPE_NAME}}</td>
          </ng-container>
          <ng-container matColumnDef="CTN_ATA" *ngIf="destiny == 'DEMURRAGE'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gated In / Arrival">Arrival</th>
            <td mat-cell *matCellDef="let element">{{date(shipment.SHIP_ATA)}}</td>
          </ng-container>
          <ng-container matColumnDef="CTN_GOU">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate Out / Empty Return">{{destiny == 'DETENTION' ? 'Gate Out' : 'Empty Return'}}</th>
            <td mat-cell *matCellDef="let element">{{date(element.CTN_GOU)}}</td>
          </ng-container>
          <ng-container matColumnDef="CTN_GIN" *ngIf="destiny == 'DETENTION'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gated In / Arrival">{{destiny == 'DETENTION' ? 'Gate In' : 'Arrival'}}</th>
            <td mat-cell *matCellDef="let element">{{date(element.CTN_GIN)}}</td>
          </ng-container>
          <ng-container matColumnDef="CTN_LDG" *ngIf="destiny == 'DETENTION'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
            <td mat-cell *matCellDef="let element">{{date(element.CTN_LDG)}}</td>
          </ng-container>
          <ng-container matColumnDef="CTN_PROCESS_STATUS">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Status</th>
            <td mat-cell *matCellDef="let element">{{getStatusProcess(element.CTN_PROCESS_STATUS)}}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="shipmentAction !== 'detail'">
                <button mat-icon-button (click)="openContainersDialog({mode: 'edit', element})" *ngIf="element.CTN_PROCESS_STATUS != 9">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="openContainersDialog({mode: 'delete', element})" *ngIf="element.CTN_PROCESS_STATUS != 9" >
                  <mat-icon color="primary">delete</mat-icon>
                </button>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="containersTableDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: containersTableDisplayedColumns;"></tr>
        </table>
        <div class="actions-container" [ngClass]="{right: shipmentAction === 'detail'}">
          <button mat-flat-button color="accent" (click)="openContainersDialog({mode: 'add'})" *ngIf="shipmentAction !== 'detail'">Add Container</button>
          <mat-paginator #containersPaginator [pageSizeOptions]="[15]" showFirstLastButtons aria-label *ngIf="containersTableDataSource.data.length !== 0"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <div class="actions-group">
    <a mat-flat-button color="accent" (click)="goBack()" *ngIf="(!shipmentId && !shipment) || shipmentId">Return To List</a>
    <a mat-stroked-button color="accent" (click)="goBack()" *ngIf="shipment && !shipmentId">Return To List</a>
    <button mat-flat-button color="accent" (click)="refreshPage()" *ngIf="shipment && !shipmentId">Create New Shipment</button>
  </div>
  <dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
    <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
    <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
    <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
  </dhl-sidebar>
  <button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar" (click)="openSidebar()" *ngIf="this.shipment">
    <mat-icon>menu_open</mat-icon>
  </button>
</section>