import { DefaultRuleFilterDialogComponent } from './default-rule-filter-dialog/default-rule-filter-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { HotToastModule } from '@ngneat/hot-toast';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DefaultRuleDeleteAllDialogComponent } from './default-rule-delete-all-dialog/default-rule-delete-all-dialog.component';
import { DefaultRuleDeleteDialogComponent } from './default-rule-delete-dialog/default-rule-delete-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HotToastModule,
    NgxMatSelectSearchModule
  ],
  declarations: [
    DefaultRuleDeleteAllDialogComponent,
    DefaultRuleDeleteDialogComponent,
    DefaultRuleFilterDialogComponent
  ]
})
export class DefaultRulesDialogsModule { }
