import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier, IPort } from 'src/app/models/parameter.model';
import { IShipment } from 'src/app/models/shipment.model';
import { CustomerService } from 'src/app/services/customer.service';
import { ParameterService } from 'src/app/services/parameter.service';

export interface IFilterDialog {
  element?: IShipment
}

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  filterForm: UntypedFormGroup;

  customers: Array<ICustomer>;

  carriers: Array<ICarrier>;
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  ports: Array<IPort>;
  filteredOriginPort: Subject<Array<IPort>> = new Subject<Array<IPort>>();
  filteredDestinationPort: Subject<Array<IPort>> = new Subject<Array<IPort>>();

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog,
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private parameterService: ParameterService
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      service: new UntypedFormControl(this.data.element?.SHIP_DESTINY),
      code: new UntypedFormControl(this.data.element?.SHIP_CODE),
      hbl: new UntypedFormControl(this.data.element?.SHIP_HBL),
      mbl: new UntypedFormControl(this.data.element?.SHIP_MBL),
      customer: new UntypedFormControl(this.data.element?.SHIP_CUS),
      customerFilter: new UntypedFormControl(),
      carrier: new UntypedFormControl(this.data.element?.SHIP_CAR),
      carrierFilter: new UntypedFormControl(),
      status: new UntypedFormControl(this.data.element?.DRT_STATUS),
      incoterm: new UntypedFormControl(this.data.element?.SHIP_INC),
      mode: new UntypedFormControl(this.data.element?.SHIP_CTN_MODE),
      dept: new UntypedFormControl(this.data.element?.SHIP_DPT_CODE),
      originPort: new UntypedFormControl(this.data.element?.SHIP_POL),
      originPortFilter: new UntypedFormControl(),
      destinationPort: new UntypedFormControl(this.data.element?.SHIP_POD),
      destinationPortFilter: new UntypedFormControl(),
      atdStart: new UntypedFormControl(this.data.element?.SHIP_ATD_START_DT),
      atdFinal: new UntypedFormControl(this.data.element?.SHIP_ATD_FINAL_DT),
      ataStart: new UntypedFormControl(this.data.element?.SHIP_ATA_START_DT),
      ataFinal: new UntypedFormControl(this.data.element?.SHIP_ATA_FINAL_DT),
      incStart: new UntypedFormControl(this.data.element?.SHIP_INC_START_DT),
      incFinal: new UntypedFormControl(this.data.element?.SHIP_INC_FINAL_DT)
    });

    this.getCustomers({ CUS_ID: this.filterForm.get('customer').value, IS_SELECT: 1, CUS_COUNTRY: 'BR' });
    this.getCarriers();
    this.getPorts();

    this.filterForm.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    this.filterForm.get('carrierFilter').valueChanges.subscribe(value => this.filterCarriers(value));

    this.filterForm.get('originPortFilter').valueChanges.subscribe(value => this.filterOriginPort(value));

    this.filterForm.get('destinationPortFilter').valueChanges.subscribe(value => this.filterDestinationPort(value));
  }

  closeDialog(filters: IShipment) {
    this.dialogRef.close(filters);
  }
  
  fieldHasValue(formField: string): boolean {
    return this.filterForm.get(formField).value;
  }

  resetField(formField: string): void {
    this.filterForm.get(formField).setValue(null);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.filterForm.value;

    let filters: IShipment = {
      SHIP_DESTINY: formValue.service,
      SHIP_CODE: formValue.code,
      SHIP_HBL: formValue.hbl,
      SHIP_MBL: formValue.mbl,
      SHIP_CUS: formValue.customer,
      SHIP_CAR: formValue.carrier,
      DRT_STATUS: formValue.status,
      SHIP_INC: formValue.incoterm,
      SHIP_CTN_MODE: formValue.mode,
      SHIP_DPT_CODE: formValue.dept,
      SHIP_POL: formValue.originPort,
      SHIP_POD: formValue.destinationPort,
      SHIP_ATD_START_DT: formValue.atdStart,
      SHIP_ATD_FINAL_DT: formValue.atdFinal,
      SHIP_ATA_START_DT: formValue.ataStart,
      SHIP_ATA_FINAL_DT: formValue.ataFinal,
      SHIP_INC_START_DT: formValue.incStart,
      SHIP_INC_FINAL_DT: formValue.incFinal,
    }

    this.closeDialog(filters);
  }

  resetFilters(): void {
    this.filterForm.setValue({
      service:null,
      code:null,
      hbl:null,
      mbl:null,
      customer:null,
      customerFilter:'',
      carrier:null,
      carrierFilter:'',
      status:null,
      incoterm:null,
      mode:null,
      dept:null,
      originPort:null,
      originPortFilter:'',
      destinationPort:null,
      destinationPortFilter:'',
      atdStart:null,
      atdFinal:null,
      ataStart:null,
      ataFinal:null,
      incStart:null,
      incFinal:null
    });
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getCarriers(): void{
    this.parameterService.getCarrier().subscribe((carriers) => {
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });
  }

  getPorts(): void{
    this.parameterService.getPort().subscribe((ports) => {
      this.ports = ports;
      this.filteredOriginPort.next(ports);
      this.filteredDestinationPort.next(ports);
    });
  }

  filterCustomers(filter: string): void {
    if (!this.filterForm.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter ? filter : null, IS_SELECT: 1, CUS_COUNTRY: 'BR' }).subscribe((customers)=>{
      this.customers = customers;
    });
  }
  
  filterCarriers(filter: string): void {
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.RELATIPF_APCPTK.toLowerCase().includes(filter) || carrier.RELATIPF_APA3CD.toLowerCase().includes(filter)));
  }

  filterOriginPort(filter: string): void {
    this.filteredOriginPort.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter)));
  }

  filterDestinationPort(filter: string): void {
    this.filteredDestinationPort.next(this.ports.filter((port) => port.DHLPORTS_AIAOCD.toLowerCase().includes(filter)));
  }

}