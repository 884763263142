import { IProcessContainer } from './../../../../models/process-container.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';


export interface IProcessCustomDateDialog {
  element: IProcessContainer,
  dateType: string
}

@Component({
  selector: 'app-process-ctn-end-reference-dialog',
  templateUrl: './process-ctn-end-reference-dialog.component.html',
  styleUrls: ['./process-ctn-end-reference-dialog.component.scss']
})
export class ProcessCtnEndReferenceDialogComponent implements OnInit {
  dateForm: FormGroup;
  minDate: Date;
  maxDate: Date;

  comment: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ProcessCtnEndReferenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IProcessCustomDateDialog,
  ) { }

  ngOnInit() {
    let date: Date;
    switch(this.data.dateType){
      case 'gateIn':
        date = this.data.element.PROCESS_GIN;
        break;
      case 'loading':
        date = this.data.element.PROCESS_LDG;
        break;
    }    

    this.dateForm = this.formBuilder.group({
      date: new FormControl(date, [Validators.required])
    });

    this.minDate = this.data.element.PROCESS_GOU;
    this.maxDate = this.data.element.PROCESS_LDG;
  }

  closeDialog(value: [IProcessContainer, string]): void {
    this.dialogRef.close(value);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    let newProcessContainer: IProcessContainer = {...this.data.element};
    newProcessContainer.PROCESS_END_REFERENCE = this.dateForm.value.date;
    this.closeDialog([newProcessContainer, this.comment]);
  }

  onComment(comment: string) {
    this.comment = comment;
  }

}
