import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IPayable, IPayableComment } from '../models/payable.model';
import { PayableService } from '../services/payable.service';

@Injectable({
  providedIn: 'root'
})
export class PayableResolver implements Resolve<[Array<IPayable>, Array<IPayableComment>]> {

  constructor(private payableService: PayableService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const payable: IPayable = {
      PB_ID: route.params.id
    }
    const returnPayable = this.payableService.get(payable).toPromise();
    const comments = this.payableService.getPayableComment(payable).toPromise();
    return Promise.all([returnPayable, comments]);
  }
}
