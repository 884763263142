import { IPayable } from 'src/app/models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IDeleteDialog {
  element: IPayable;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  invNumber: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDeleteDialog
  ) { }

  ngOnInit() {
    this.invNumber = this.data.element?.PB_INV_NUM;
  }

  close(willDelete: boolean): void {
    this.dialogRef.close([willDelete, this.data.element]);
  }

}
