import { IFinancialCustomer } from './../../models/financial.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ApexChartOptions } from 'src/app/interfaces/apex-chart.interface';
import { IFinancialMonth, IFinancialYear } from 'src/app/models/financial.model';
import { FinancialService } from 'src/app/services/financial.service';
import { CurrencyPipe, formatDate } from '@angular/common';
import { ChartComponent } from 'ng-apexcharts';
import { XlsxExporterService } from 'mat-table-exporter';

@Component({
  selector: 'dhl-financial-dashboard',
  templateUrl: './financial-dashboard.component.html',
  styleUrls: ['./financial-dashboard.component.scss']
})
export class FinancialDashboardComponent implements OnInit {
  hideLoader: boolean;
  reportYears: Array<IFinancialYear>;

  month = new UntypedFormControl(new Date().getMonth() + 1);
  year = new UntypedFormControl(new Date().getFullYear());

  readonly date = new Date();

  financialReportTableDataSource = new MatTableDataSource<IFinancialYear>();
  filteredFinancialReportTableDataSource = new MatTableDataSource<IFinancialYear>();
  financialReportTableDisplayedColumns: Array<string> = [
    'FIN_MONTH',
    'FIN_EXP',
    'FIN_IMP',
    'FIN_REV_BRL',
    'FIN_COS_BRL',
    'FIN_ACT_BRL',
    'FIN_ACT_EUR',
    'FIN_PY_EUR',
    'FIN_BUD_EUR',
    'FIN_ACT_BUD'
  ];

  @ViewChild(MatTable) financialReportTable: MatTable<IFinancialCustomer>;

  financialCustomerTableDataSource = new MatTableDataSource<IFinancialCustomer>();
  financialCustomerTableDisplayedColumns: Array<string> = [
    'TOP_CUS_NAME',
    'TOP_CUS_PDT',
    'TOP_CUS_JAN',
    'TOP_CUS_FEV',
    'TOP_CUS_MAR',
    'TOP_CUS_APR',
    'TOP_CUS_MAY',
    'TOP_CUS_JUN',
    'TOP_CUS_JUL',
    'TOP_CUS_AUG',
    'TOP_CUS_SEP',
    'TOP_CUS_OCT',
    'TOP_CUS_NOV',
    'TOP_CUS_DEC',
    'TOP_CUS_REV'
  ];

  @ViewChild(MatTable) financialCustomerTable: MatTable<IFinancialCustomer>;

  readonly colorScheme = [
    '#666666',
    '#FFCC00',
    '#D40511',
    '#000000',
    '#333333',
    '#8c8c8c',
    '#b2b2b2',
    '#e5e5e5',
    '#ebebeb',
    '#f2f2f2'
  ];

  readonly numberToMonthMap = new Map([
    [1, 'January'],
    [2, 'February'],
    [3, 'March'],
    [4, 'April'],
    [5, 'May'],
    [6, 'June'],
    [7, 'July'],
    [8, 'August'],
    [9, 'September'],
    [10, 'October'],
    [11, 'November'],
    [12, 'December']
  ]);

  @ViewChild('mainChart') mainChart: ChartComponent;
  public mainChartOptions: ApexChartOptions = {
    colors: this.colorScheme,
    series: [
      {
        name: 'Past Year GP EUR',
        type: 'column',
        data: []
      },
      {
        name: 'Actual GP EUR',
        type: 'column',
        data: []
      },
      {
        name: 'Actual vs Budget',
        type: 'line',
        data: []
      }
    ],
    chart: {
      height: 350,
      type: 'line',
      stacked: false
    },
    stroke: {
      width: [0, 0, 4]
    },
    // title: {
    //   text: 'Anual Performance GP • Demurrage and Detention',
    //   align: 'left',
    //   style: {
    //     color: 'black',
    //     fontFamily: 'Delivery',
    //     fontSize: '16px'
    //   }
    // },
    xaxis: {
      categories: Array.from(this.numberToMonthMap.values())
    },
    yaxis: [
      {
        seriesName: 'Past Year GP EUR',
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: this.colorScheme[0]
        },
        labels: {
          formatter: (y) => this.currencyPipe.transform(y, 'EUR'),
          style: {
            colors: this.colorScheme[0]
          }
        },
        title: {
          text: 'GP EUR',
          style: {
            color: this.colorScheme[0]
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        show: false,
        seriesName: 'Past Year GP EUR',
        labels: {
          formatter: (y) => this.currencyPipe.transform(y, 'EUR'),
          style: {
            colors: this.colorScheme[0]
          }
        }
      },
      {
        seriesName: 'Actual vs Budget',
        opposite: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: this.colorScheme[0]
        },
        labels: {
          formatter: (y) => y.toFixed(0) + '%',
          style: {
            colors: this.colorScheme[0]
          }
        },
        title: {
          text: 'Percentage',
          style: {
            color: this.colorScheme[0]
          }
        }
      }
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: 30
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  };

  @ViewChild('barChart') barChart: ChartComponent;
  public barChartOptions: ApexChartOptions = {
    colors: this.colorScheme,
    series: [
      {
        name: 'Last Month GP EUR',
        type: 'column',
        data: [5]
      },
      {
        name: 'Actual GP EUR',
        type: 'column',
        data: [10]
      }
    ],
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        tools: {
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    stroke: {
      width: [0, 0, 4]
    },
    xaxis: {
      categories: [''],
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
      {
        seriesName: 'Last Month GP EUR',
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: this.colorScheme[0]
        },
        labels: {
          formatter: (y) => this.currencyPipe.transform(y, 'EUR'),
          style: {
            colors: this.colorScheme[0]
          }
        },
        title: {
          text: 'GP EUR',
          style: {
            color: this.colorScheme[0]
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        show: false,
        seriesName: 'Last Month GP EUR',
        labels: {
          formatter: (y) => this.currencyPipe.transform(y, 'EUR'),
          style: {
            colors: this.colorScheme[1]
          }
        }
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: 30
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  };

  @ViewChild('cricketChart') cricketChart: ChartComponent;
  public cricketChartOptions: ApexChartOptions = {
    colors: this.colorScheme,
    series: [],
    chart: {
      height: 350,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '14px',
            fontWeight: 'normal',
            color: '#373D3F',
            offsetY: 155
          },
          value: {
            offsetY: -2,
            fontSize: '36px',
            fontFamily: 'Delivery',
            fontWeight: 'bold',
            formatter: function(val) {
              return val + '%';
            }
          }
        }
      }
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: 30
      }
    },
    labels: [
      '% Actual vs Budget'
    ]
  };

  openGroups: [boolean, boolean] = [true, true];

  constructor(
    private titleService: Title,
    private financialService: FinancialService,    
    private exporter: XlsxExporterService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('DHL | Analytics (Finance)');

    this.financialService.getReportYear().subscribe((years)=>{
      this.reportYears = years;
    });

    this.getAnualData();
    this.getMonthlyData();

    this.year.valueChanges.subscribe(() => {
      this.getAnualData();
      this.getMonthlyData();
    });

    this.month.valueChanges.subscribe(() => {
      this.getMonthlyData();
    });
  }

  isOverflow(element: string) {
    return element && element.length > 20;
  }

  getAnualData(): void {
    if (!this.financialReportTableDataSource.filteredData) this.hideLoader = false;
    
    const filter: IFinancialYear = {
      FIN_YEAR: this.year.value
    }

    this.financialService.getYear(filter).subscribe((data) => {
      this.hideLoader = true;
      this.financialReportTableDataSource.data = data.reverse();
      this.mainChart.updateSeries([
        { data: data.map(value => Number(value?.FIN_PY_EUR) || 0) },
        { data: data.map(value => Number(value?.FIN_ACT_EUR) || 0) },
        { data: data.map(value => Number(value?.FIN_ACT_BUD) || 0) }
      ]);
    });
  }

  getMonthlyData(): void {
    const filter: IFinancialMonth = {
      FIN_MONTH: this.month.value,
      FIN_YEAR: this.year.value
    }
    
    this.financialService.getMonth(filter).subscribe((data) => {
      this.barChart.updateSeries([
        { data: [data[0]?.FIN_LAST_EUR] || [0] },
        { data: [data[0]?.FIN_ACT_EUR] || [0] },
      ]);

      this.cricketChart.updateSeries([data[0]?.FIN_ACT_BUD || 0]);
    });
  }

  export(): void {
    const headers = {
      FIN_YEAR: 'YEAR',
      FIN_MONTH: 'MONTH',
      FIN_EXP: 'EXPORTATION',
      FIN_IMP: 'IMPORTATION',
      FIN_REV_BRL: 'REVENUE (BRL)',
      FIN_COS_BRL: 'COST (BRL)',
      FIN_ACT_BRL: 'ACTUAL GP (BRL)',
      FIN_ACT_EUR: 'ACTUAL GP (EUR)',
      FIN_PY_EUR: 'PAST YEAR GP (EUR)',
      FIN_BUD_EUR: 'BUDGET GP (EUR)',
      FIN_ACT_BUD: 'ACTUAL VS BUDGET (%)'
    }

    const dataToExport: Array<any> = [headers];
    const data: Array<IFinancialYear> = [...this.financialReportTableDataSource.filteredData];
    dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `financialDashboard(AnualPerformance)_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }
}
