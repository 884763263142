import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from 'src/app/interfaces/dialog-data.interface';
import { IException } from 'src/app/models/shipment.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'dhl-exceptions-dialog',
  templateUrl: './exceptions-dialog.component.html',
  styleUrls: ['./exceptions-dialog.component.scss']
})
export class ExceptionsDialogComponent implements OnInit {
  exceptionsForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ExceptionsDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData<IException>
  ) { }

  ngOnInit(): void {
    this.exceptionsForm = this.formBuilder.group({
      description: new UntypedFormControl(this.data.element?.EXC_DESC),
      code: new UntypedFormControl(this.data.element?.EXC_CODE),
      staffCode: new UntypedFormControl(this.data.element?.EXC_STAFF_CODE),
      timeStamp: new UntypedFormControl(this.data.element?.EXC_INC_DATETIME)
    });
  }

  closeDialog(exception: IException): void {
    this.dialogRef.close(exception);
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.exceptionsForm.value;
    let newException: IException;

    if (this.data.mode === 'add') {
      newException = {
        EXC_ID: null,
        EXC_DESC: formValue.description,
        EXC_CODE: formValue.code,
        EXC_STAFF_CODE: formValue.staffCode,
        EXC_INC_DATETIME: formValue.timeStamp,
        EXC_STATUS: 1,
        EXC_USER: this.authService.userId
      }
    } else if (this.data.mode === 'edit') {
      newException = {
        EXC_ID: this.data.element.EXC_ID,
        EXC_DESC: formValue.description,
        EXC_CODE: formValue.code,
        EXC_STAFF_CODE: formValue.staffCode,
        EXC_INC_DATETIME: formValue.timeStamp,
        EXC_STATUS: 1,
        EXC_USER: this.authService.userId
      }
    } else if (this.data.mode === 'delete') {
      newException = {
        EXC_ID: this.data.element.EXC_ID,
        EXC_DESC: null,
        EXC_CODE: null,
        EXC_STAFF_CODE: null,
        EXC_INC_DATETIME: null,
        EXC_STATUS: 0,
        EXC_USER: this.authService.userId
      }
    }

    this.closeDialog(newException);
  }
}
