import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ChartComponent } from 'ng-apexcharts';
import { Observable, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApexChartOptions } from 'src/app/interfaces/apex-chart.interface';
import { IChartData } from 'src/app/interfaces/chart-data.interface';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CurrencyPipe, formatDate } from '@angular/common';
import { DashboardRequest } from 'src/app/models/dashboard.model';
import { ICustomer } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { XlsxExporterService } from 'mat-table-exporter';

@Component({
  selector: 'dhl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  month = new UntypedFormControl([]);
  customer = new UntypedFormControl([]);
  customerFilter = new UntypedFormControl('');

  invoiceDetention$ = new Observable<Array<IChartData>>();
  invoiceDemurrage$ = new Observable<Array<IChartData>>();
  totalInvoices$ = new Observable<Array<IChartData>>();
  invoicesAverage$ = new Observable<Array<IChartData>>();
  containers$ = new Observable<Array<IChartData>>();
  topCustomers$ = new Observable<Array<IChartData>>();

  customers: Array<ICustomer> = [];
  filteredCustomers: Array<ICustomer> = [];
  
  readonly colorScheme = [
    '#D40511',
    '#FFCC00',
    '#333333',
    '#666666',
    '#8c8c8c',
    '#b2b2b2',
    '#cccccc',
    '#e5e5e5',
    '#ebebeb',
    '#f2f2f2'
  ];

  readonly months: Array<string> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  @ViewChild('containerChart') containerChart: ChartComponent;
  public containerChartOptions: ApexChartOptions = {
    colors: this.colorScheme,
    series: [
      {
        name: 'Import',
        type: 'column',
        data: []
      },
      {
        name: 'Export',
        type: 'column',
        data: []
      }
    ],
    chart: {
      height: 440,
      type: 'line',
      toolbar: {
        tools: {
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    stroke: {
      width: [0, 0]
    },
    title: {
      text: 'Container flow',
      align: 'left',
      style: {
        color: 'black',
        fontFamily: 'Delivery',
        fontSize: '16px'
      }
    },
    xaxis: {
      categories: [''],
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
      {
        seriesName: 'Import',
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: this.colorScheme[0]
        },
        labels: {
          style: {
            colors: this.colorScheme[0]
          }
        },
        title: {
          text: 'Ammount',
          style: {
            color: this.colorScheme[0]
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        show: false,
        seriesName: 'Import'
      }
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: 30
      }
    },
    legend: {
      horizontalAlign: 'left'
    }
  }

  @ViewChild('topCustomersChart') topCustomersChart: ChartComponent;
  public topCustomersChartOptions: ApexChartOptions = {
    colors: this.colorScheme,
    series: [
      {
        name: 'Demurrage',
        data: []
      },
      {
        name: 'Detention',
        data: []
      }
    ],
    chart: {
      height: 440,
      type: 'bar',
      stacked: true
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Top customers',
      align: 'left',
      style: {
        color: 'black',
        fontFamily: 'Delivery',
        fontSize: '16px'
      }
    },
    xaxis: {
      labels: {
        formatter: (y) => this.currencyPipe.transform(y)
      },
      categories: [],
    },
    yaxis: {
      seriesName: 'Value',
      labels: {
        style: {
          fontWeight: 'bold'
        },
      },
    },
    tooltip: {
      y: {
        formatter: (y) => this.currencyPipe.transform(y, 'USD ', 'code')
      }
    }
  }

  constructor(
    private titleService: Title,
    private dashboardService: DashboardService,
    private customerService: CustomerService,
    private currencyPipe: CurrencyPipe,    
    private exporter: XlsxExporterService,
  ) { }
  
  ngOnInit(): void {
    this.titleService.setTitle('DHL | Home');

    this.getData();
    this.month.valueChanges.subscribe(() => this.getData());
    this.customer.valueChanges.subscribe(() => this.getData());
    this.customerFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    this.getCustomers({ CUS_FILTER: this.customer.value?.join('|'), IS_SELECT: 1 });
  }

  getCustomers(filter: ICustomer) {
    this.customerService.getCustomer(filter).subscribe((data) => {
      this.customers = data;
      this.filteredCustomers = data;
    });
  }

  filterCustomers(filter: string): void {
    const final = filter ? `${filter}|${this.customer.value === '' ? this.customer.value?.join('|') : undefined}` : this.customer.value ? `${this.customer.value?.join('|')}` : '';
    this.customerService.getCustomer({ CUS_FILTER: final ? final : null, IS_SELECT: 1 }).subscribe((customers)=>{
      this.customers = customers;
      this.filteredCustomers = customers;
    });
  }

  getData(): void {
    const dashboardRequest: DashboardRequest = {
      MONTH_LIST: this.month.value?.join(','),
      CUS_LIST: this.customer.value?.join(',')
    }

    this.invoiceDetention$ = this.dashboardService.getInvoiceDetention(dashboardRequest).pipe(
      map((value) => value.map((value) => ({ name: 'Total Detention', value: value.TOTAL_AMOUNT })))
    );

    this.invoiceDemurrage$ = this.dashboardService.getInvoiceDemurrage(dashboardRequest).pipe(
      map((value) => value.map((value) => ({ name: 'Total Demurrage', value: value.TOTAL_AMOUNT })))
    );

    this.totalInvoices$ = this.dashboardService.getTotalInvoices(dashboardRequest).pipe(
      map((value) => value.map((value) => ({ name: 'Total Invoices', value: value.TOTAL_AMOUNT })))
    );

    this.invoicesAverage$ = this.dashboardService.getInvoicesAverage(dashboardRequest).pipe(
      map((value) => value.map((value) => ({ name: 'Invoices Average', value: value.TOTAL_AMOUNT })))
    );

    const containerDetention$ = this.dashboardService.getContainerDetention(dashboardRequest).pipe(
      map((value) => value.map((value) => ({ name: 'Export', value: value.CTN_TOTAL })))
    );

    const containerDemurrage$ = this.dashboardService.getContainerDemurrage(dashboardRequest).pipe(
      map((value) => value.map((value) => ({ name: 'Import', value: value.CTN_TOTAL })))
    );

    this.containers$ = zip(containerDemurrage$, containerDetention$).pipe(map(x => x[0].concat(x[1])));
    this.containers$.subscribe((data) => {
      this.containerChart.updateSeries([
        { data: [data[0].value] },
        { data: [data[1].value] }
      ]);
    });

    this.topCustomers$ = this.dashboardService.getTopCustomers(dashboardRequest).pipe(
      map((value) => value.slice(0, 10).map((value) => ({ name: value.CUS_NAME, value: value.TOTAL_DEMURRAGE, value2: value.TOTAL_DETENTION})))
    );
    this.topCustomers$.subscribe((data) => {
      this.topCustomersChart.updateOptions({
        xaxis: {
          categories: [...data.map(value => value.name)]
        }
      });
      this.topCustomersChart.updateSeries([
        { data: [...data.map(value => value.value)] },
        { data: [...data.map(value => value.value2)] }
      ]);
    });
  }

  export(): void {
    const headers = {
      
    }

    const dataToExport: Array<any> = [headers];
    //const data: Array<IPayable> = this.prepareDataToExport([...this.filteredPayableTableDataSource.filteredData]);
    //dataToExport.push(...data);

    this.exporter.export(dataToExport, {
      fileName: `payables_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }
}
