import { IUser } from 'src/app/models/user.model';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { IUserGroup } from 'src/app/models/user-group.model';
import { UserService } from 'src/app/services/user.service';

export interface IUserViewDialog {
  user?: IUser;
}

@Component({
  selector: 'dhl-users-view-dialog',
  templateUrl: './users-view-dialog.component.html',
  styleUrls: ['./users-view-dialog.component.scss']
})
export class UserViewDialogComponent implements OnInit {
  userGroupTableDataSource: MatTableDataSource<IUserGroup>;
  userGroupTableDisplayedColumns: Array<string> = ['GRP_NAME'];
  filterValue: string = '';

  @ViewChild(MatTable) userGroupTable: MatTable<ICustomerGroup>;
  @ViewChild('userGroupSort') userGroupSort: MatSort;

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: IUserViewDialog,
    private dialogRef: MatDialogRef<UserViewDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getUserGroups()
  }

  getUserGroups(): void {
    const user: IUser = {
      PI_USER_ID: this.data.user.PI_USER_ID
    }

    this.userService.getAllGroups(user).subscribe((groups) => {
      this.userGroupTableDataSource = new MatTableDataSource<IUserGroup>(groups);
      this.changeDetectorRef.detectChanges();
      this.userGroupTableDataSource.sort = this.userGroupSort;      
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  filterUserGroupTable(filterValue: string) {
    this.userGroupTableDataSource.filter = filterValue;
  }

}
