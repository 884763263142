import { IContainer } from './../models/shipment.model';
import { IRpaLog, IRpaIndicators, IIntegrationLog, IIntegrationIndicators } from './../models/log.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILog, IGetLog } from '../models/log.model';

@Injectable({
  providedIn: 'root'
})
export abstract class LogService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  //-----------------------Logs DDM-----------------------
  getLogs(log: IGetLog): Observable<Array<ILog>> {
    return this.httpClient.get<Array<ILog>>(`${this.apiURL}/api/log?filter=${JSON.stringify(log)}`, this.httpOptions);
  }

  getLogData(log: IGetLog): Observable<Object> {
    return this.httpClient.get(`${this.apiURL}/api/log/data?filter=${JSON.stringify(log)}`, this.httpOptions)
  }

  getLogUsers(): Observable<Array<ILog>> {
    return this.httpClient.get<Array<ILog>>(`${this.apiURL}/api/log/user/`, this.httpOptions)
  }

  getLogGroups(): Observable<Array<ILog>> {
    return this.httpClient.get<Array<ILog>>(`${this.apiURL}/api/log/group/`, this.httpOptions)
  }

  //-----------------------Logs RPA-----------------------
  getRpaLogs(filter: IRpaLog): Observable<Array<IRpaLog>> {
    return this.httpClient.get<Array<IRpaLog>>(`${this.apiURL}/api/log/rpa?filter=${JSON.stringify(filter)}`, this.httpOptions)
  }

  getRpaJson(filter: IRpaLog): Observable<IRpaLog> {
    return this.httpClient.get<IRpaLog>(`${this.apiURL}/api/log/rpa/json?filter=${JSON.stringify(filter)}`, this.httpOptions)
  }

  getRpaIndicators(filter: IRpaLog): Observable<Array<IRpaIndicators>> {
    return this.httpClient.get<Array<IRpaIndicators>>(`${this.apiURL}/api/log/rpa/indicators?filter=${JSON.stringify(filter)}`, this.httpOptions)
  }

  getRpaList(): Observable<Array<IRpaLog>> {
    return this.httpClient.get<Array<IRpaLog>>(`${this.apiURL}/api/log/rpa/list`, this.httpOptions)
  }

  getRpaStatusList(): Observable<Array<IRpaLog>> {
    return this.httpClient.get<Array<IRpaLog>>(`${this.apiURL}/api/log/rpa/status/list`, this.httpOptions)
  }

  //-----------------------Logs Integration-----------------------
  getIntegrationLogs(filter: IIntegrationLog): Observable<Array<IIntegrationLog>> {
    return this.httpClient.get<Array<IIntegrationLog>>(`${this.apiURL}/api/log/integration?filter=${JSON.stringify(filter)}`, this.httpOptions)
  }

  getIntegrationJson(filter: IIntegrationLog): Observable<IIntegrationLog> {
    return this.httpClient.get<IIntegrationLog>(`${this.apiURL}/api/log/integration/json?filter=${JSON.stringify(filter)}`, this.httpOptions)
  }

  getIntegrationIndicators(filter: IIntegrationIndicators): Observable<Array<IIntegrationIndicators>> {
    return this.httpClient.get<Array<IIntegrationIndicators>>(`${this.apiURL}/api/log/integration/indicators?filter=${JSON.stringify(filter)}`, this.httpOptions)
  }

  getIntegrationMessage(): Observable<Array<IIntegrationLog>> {
    return this.httpClient.get<Array<IIntegrationLog>>(`${this.apiURL}/api/log/integration/message/list`, this.httpOptions)
  }

  getIntegrationStatusItem(): Observable<Array<IIntegrationLog>> {
    return this.httpClient.get<Array<IIntegrationLog>>(`${this.apiURL}/api/log/integration/status/list`, this.httpOptions)
  }

  //-----------------------Containers List-----------------------
  getContainers(): Observable<Array<IContainer>> {
    return this.httpClient.get<Array<IContainer>>(`${this.apiURL}/api/container/list`, this.httpOptions)
  }
}