import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICustomerGroup } from '../models/customer-group.model';
import { ICustomer } from '../models/customer.model';
import { IJoinCustomerGroup, IJoinCustomerList } from '../models/join-customer-group.model';

@Injectable({
  providedIn: 'root'
})
export abstract class CustomerGroupService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getCustomerGroup(): Observable<Array<ICustomerGroup>> {
    return this.httpClient.get<Array<ICustomerGroup>>(`${this.apiURL}/api/customergroup/`, this.httpOptions);
  }

  postCustomerGroup(customerGroup: ICustomerGroup): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/customergroup?filter=${JSON.stringify(customerGroup)}`, null, this.httpOptions);
  }

  putCustomerGroup(customerGroup: ICustomerGroup): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customergroup?filter=${JSON.stringify(customerGroup)}`, null, this.httpOptions);
  }

  deleteSelectedGroups(groups: IJoinCustomerList): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/customergroup/list?filter=${JSON.stringify(groups)}`, null, this.httpOptions);
  }

  getAllCustomers(customerGroup: IJoinCustomerGroup): Observable<Array<ICustomer>> {
    return this.httpClient.get<Array<ICustomer>>(`${this.apiURL}/api/joincustomergroup/customer?filter=${JSON.stringify(customerGroup)}`, this.httpOptions);
  }
}
