import { Observable } from 'rxjs';
import { IAgreement, IAgreementList, IPerdiem, IShipData } from '../models/agreement.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class AgreementService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json'
    })
  };

  getAgreements(agreements: IAgreement): Observable<Array<IAgreement>> {
    return this.httpClient.get<Array<IAgreement>>(`${this.apiURL}/api/agreements?filter=${JSON.stringify(agreements)}`, this.httpOptions);
  }

  getAgreement(agreement: IAgreement): Observable<IAgreement> {
    return this.httpClient.get<IAgreement>(`${this.apiURL}/api/agreement?filter=${JSON.stringify(agreement)}`, this.httpOptions);
  }

  getShipData(agreement: IAgreement): Observable<Array<IShipData>> {
    return this.httpClient.get<Array<IShipData>>(`${this.apiURL}/api/agreement/ship/data?filter=${JSON.stringify(agreement)}`, this.httpOptions);
  }

  deleteAgreements(agreements: IAgreementList): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/agreements/deletelist?filter=${JSON.stringify(agreements)}`, null, this.httpOptions);
  }

  postAgreement(agreement: IAgreement): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/agreement`, agreement, this.httpOptions);
  }

  putAgreement(agreement: IAgreement): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/agreement`, agreement, this.httpOptions);
  }

  getDefaultRules(agreement: IAgreement): Observable<Array<IAgreement>> {
    return this.httpClient.get<Array<IAgreement>>(`${this.apiURL}/api/agreement/perdiem-type?filter=${JSON.stringify(agreement)}`, this.httpOptions);
  }

  getPerdiem(agreement: IAgreement): Observable<Array<IPerdiem>> {
    return this.httpClient.get<Array<IPerdiem>>(`${this.apiURL}/api/agreement/perdiem?filter=${JSON.stringify(agreement)}`, this.httpOptions);
  }

  getAgreementsPurchseXSale(agreements: IAgreement): Observable<Array<IAgreement>> {
    return this.httpClient.get<Array<IAgreement>>(`${this.apiURL}/api/agreement/purchase-sale?filter=${JSON.stringify(agreements)}`, this.httpOptions);
  }

  relatesAgreementPurchaseXSale(agreement: IAgreement): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/agreement/purchase-sale?filter=${JSON.stringify(agreement)}`, null, this.httpOptions);
  }

  spotChecker(agreement: IAgreement): Observable<Object>{
    return this.httpClient.post(`${this.apiURL}/api/agreement/spot-checker`, agreement, this.httpOptions);
  }
}
