<h1 mat-dialog-title>Set Rate</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Year</mat-label>
        <mat-select name="year" formControlName="year">
          <mat-option *ngFor="let year of financialYears" [value]="year.FBF_POST_PERIOD_YEAR">{{year.FBF_POST_PERIOD_YEAR}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Rate</mat-label>
        <input matInput type="number" formControlName="rate">
        <mat-error *ngIf="form.get('rate').invalid">{{getErrorMessage('rate')}}</mat-error>
      </mat-form-field>
    </div>    

    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close()">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid">Apply</button>
    </div>
  </form>
</div>
