import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem('access_token');
    this.authService.getCredentials(token).subscribe((user) => {
      this.authService.userId = user.USER_ID.toUpperCase();
      this.authService.userName = user.USER_NAME;
      this.authService.userAllowedUrls = user.PAG_URL.split(', ');
      this.authService.isUserAdmin = user.USER_IS_ADMIN === 'Y';
      this.authService.hasLoadedInfo = true;
  
      if (!this.authService.userAllowedUrls.includes(state.url) && !this.authService.isUserAdmin) {
        console.log(state.url);
        this.router.navigate(['/']);
        return false;
      }
    });

    if (route.params.id) {
      state.url = state.url.replace(`/${route.params.id}`, '');
    }
    
    if (this.authService.isTokenExpired) {
      this.authService.logout();
      return false;
    }
   
    return true;
  }
}
