import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IUniqueTerm } from '../models/unique-term';
import { UniqueTermService } from '../services/unique-term.service';


@Injectable({
  providedIn: 'root'
})
export class UniqueTerm implements Resolve<IUniqueTerm> {

  constructor(private uniqueTermsService: UniqueTermService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const term: IUniqueTerm = {
      UNI_ID: route.params.id
    }
    return this.uniqueTermsService.getTerm(term);
  }
}
