import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { IRegion } from "src/app/models/parameter.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/services/auth.service";

export interface IRegionDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  region?: IRegion;
}

@Component({
  selector: 'region-dialog',
  templateUrl: 'region-dialog.component.html',
  styleUrls: ['region-dialog.component.scss']
})
export class RegionDialogComponent implements OnInit {
  regionForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  
  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<RegionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IRegionDialog, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit() {
    this.regionForm = this.formBuilder.group({
      name: new UntypedFormControl(this.data.region?.REG_NAME, [Validators.required]),
      description: new UntypedFormControl(this.data.region?.REG_DESCRIPTION, [Validators.required])
    });

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.regionForm.disable();
    }
  }

  closeDialog(region: IRegion) {
    this.dialogRef.close(region);
  }

  enableForm() {
    this.isFormEnabled = true;
    this.regionForm.get('description').enable();
  }

  onFormSubmit(e: Event){
    e.preventDefault();
    const formValue = this.regionForm.value;
    let newRegion: IRegion;

    if (this.data.mode == 'add') {
      newRegion = {
        REG_NAME: formValue.name,
        REG_DESCRIPTION: formValue.description,
        REG_USER: this.authService.userId
      }
    } else if (this.data.mode == 'edit') {
      newRegion = {
        REG_ID: this.data.region.REG_ID,
        REG_NAME: formValue.name,
        REG_DESCRIPTION: formValue.description,
        REG_STATUS: 1,
        REG_USER: this.authService.userId
      }
    } else if (this.data.mode == 'delete') {
      newRegion = {
        REG_ID: this.data.region.REG_ID,
        REG_NAME: null,
        REG_DESCRIPTION: null,
        REG_STATUS: 0,
        REG_USER: this.authService.userId
      }
    }

    this.closeDialog(newRegion);
  }

  getNameErrorMessage(): string {
    if (this.regionForm.get('name').hasError('required')) {
      return 'You must enter a name';
    } else {
      return '';
    }
  }

  getDescriptionErrorMessage(): string {
    if (this.regionForm.get('description').hasError('required')) {
      return 'You must enter a description';
    } else {
      return '';
    }
  }
}