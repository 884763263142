import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ChangeStatusDialogComponent } from './change-status-dialog/change-status-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { ImportPayableDialogComponent } from './import-payable-dialog/import-payable-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImportPdfDialogComponent } from './import-pdf-dialog/import-pdf-dialog.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
  declarations: [
    FilterDialogComponent,
    ImportPayableDialogComponent,
    ImportPdfDialogComponent,
    ChangeStatusDialogComponent,
    DeleteDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgxDropzoneModule,
    ComponentsModule,
    NgxMatSelectSearchModule
  ]
})
export class PayablesDialogsModule { }
