<nav>
  <div class="nav-content">
    <button class="menu-button" (click)="isSidebarOpen$.next(!isSidebarOpen$.value)">
      <span class="material-symbols-outlined">{{isSidebarOpen$.value ? 'close' : 'menu'}}</span>
    </button>
    <a class="logo" routerLink="/" (click)="onRouteChange(null)">
      <img src="assets/img/dhl-logo.svg" alt="DHL">
    </a>
  </div>
  <button mat-icon-button class="user-button" [matMenuTriggerFor]="menu">{{authService.userName | initials}}</button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="authService.logout()">
      <mat-icon>logout</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>
</nav>
<div class="sidebar" [ngClass]="{'open': isSidebarOpen$.value}">
  <div class="nav-title-container">
    <h3 class="nav-title">Menu</h3>
    <div class="nav-actions">
      <button class="expand-nav-button" (click)="toggleAllGroups(false)">
        <span class="material-symbols-outlined">remove</span>
      </button>
      <button class="expand-nav-button" (click)="toggleAllGroups(true)">
        <span class="material-symbols-outlined">add</span>
      </button>
    </div>
  </div>
  <ng-container *ngFor="let group of navigation">
    <div class="nav-group" *ngIf="canAccessGroup(group)">
      <div class="nav-group-title">
        <div>
          <span class="material-symbols-outlined">{{group.icon}}</span>
          <h3 class="nav-title">{{group.text}}</h3>
        </div>
        <button class="expand-nav-button" (click)="group.isExpanded = !group.isExpanded">
          <span class="material-symbols-outlined">{{group.isExpanded ? 'remove' : 'add'}}</span>
        </button>
      </div>
      <div class="nav-buttons">
        <ng-container *ngFor="let item of group.items">
          <a
            class="nav-button"
            [ngClass]="{'visible': group.isExpanded}"
            [routerLink]="item.path"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            (click)="onRouteChange(group)"
            *ngIf="canAccessItem(item.path)"
          >
            <span>{{item.text}}</span>
          </a>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="divider"></div>
  <div class="app-info">
    <p>v1.0.0.2</p>
    <p>Copyright Bonobo Tec © 2014 - {{year}}</p>
  </div>
</div>
<div
  class="dark-overlay"
  [ngClass]="{'open': isSidebarOpen$.value}"
  (click)="isSidebarOpen$.next(false)"
></div>
