import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IContainer, IException, IMasterbill, IShipment } from '../models/shipment.model';
import { ShipmentService } from '../services/shipment.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentResolver implements Resolve<[IShipment, Array<IException>, Array<IMasterbill>, Array<IContainer>]> {

  constructor(private shipmentService: ShipmentService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const shipment: IShipment = {
      SHIP_ID: route.params.id
    }
    const returnShipment = this.shipmentService.getSingleShipment(shipment).toPromise();
    const exceptions = this.shipmentService.getException(shipment).toPromise();
    const masterBills = this.shipmentService.getMasterbill(shipment).toPromise();
    const containers = this.shipmentService.getContainer(shipment).toPromise();
    return Promise.all([returnShipment, exceptions, masterBills, containers]);
  }
}
