import { ParameterService } from 'src/app/services/parameter.service';
import { DefaultRuleFilterDialogComponent } from './default-rules-dialogs/default-rule-filter-dialog/default-rule-filter-dialog.component';
import { HotToastService } from '@ngneat/hot-toast';
import { formatDate } from '@angular/common';
import { IAgreement, IAgreementList } from '../../models/agreement.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { XlsxExporterService } from 'mat-table-exporter';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AgreementService } from '../../services/agreement.service';
import { DefaultRuleDeleteAllDialogComponent } from './default-rules-dialogs/default-rule-delete-all-dialog/default-rule-delete-all-dialog.component';
import { DefaultRuleDeleteDialogComponent } from './default-rules-dialogs/default-rule-delete-dialog/default-rule-delete-dialog.component';
import { Title } from '@angular/platform-browser';
import { MatTableFilter } from 'mat-table-filter';
import { FilterService } from 'src/app/services/filter.service';
import { ICarrier } from 'src/app/models/parameter.model';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { IComment } from 'src/app/models/comment.model';
import { IAttachment } from 'src/app/models/attachment.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { CommentService } from 'src/app/services/comment.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AwsService } from 'src/app/services/aws.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-default-rules',
  templateUrl: './default-rules.component.html',
  styleUrls: ['./default-rules.component.scss']
})
export class DefaultRulesComponent implements OnInit {
  pageKey = '';
  hideLoader: boolean;
  defaultRuleType: string;
  defaultRuleTitle: string;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IAgreement>(this.allowMultiSelect, this.initialSelection);

  globalFilter: FormControl = new FormControl('');

  filterEntity: IAgreement = {
    DIM_SK_CUS_RUL:null,     
    DIM_RUL_DEF_NAM:null,
    DIM_STATUS:null,
    DIM_CAR:null,
    DIM_SERVICE:null,
    DIM_VAL_START_DT:null,
    DIM_VAL_FINAL_DT:null,
    DIM_INC_START_DT:null,
    DIM_INC_FINAL_DT:null
  };

  filtersNumber: number = 0;

  defaultRuleTableDataSource = new MatTableDataSource<IAgreement>();
  defaultRuleExportDataSource = new MatTableDataSource<IAgreement>();
  filteredDefaultRuleTableDataSource = new MatTableDataSource<IAgreement>();
  defaultRuleTableDisplayedColumns: Array<string> = ['select', 'DIM_SK_CUS_RUL', 'DIM_RUL_DEF_NAM', 'DIM_TYPE', 'DIM_SERVICE', 'DIM_CAR', 'DIM_DT_STR_EFF', 'DIM_DT_FIN_EFF', 'DIM_STATUS', 'DIM_DT_INC','DIM_CMT_ATCH','actions'];

  carrierList: Array<ICarrier> = []

  @ViewChild(MatTable) defaultRuleTable: MatTable<IAgreement>;
  @ViewChild('defaultRuleSort') defaultRuleSort: MatSort;
  @ViewChild('defaultRulePaginator') defaultRulePaginator: MatPaginator;

  isSidebarOpen = false;

  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];
  agreementId: number;

  defaultPage: string;

  length: number;
  pageNumber: number = 0;
  pageSize: number = 20;

  sortCol: string;
  sortOrder: string;

  constructor(
    public dialog: MatDialog,
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private exporter: XlsxExporterService,
    private agreementService: AgreementService,
    private toast: HotToastService,
    private filterService: FilterService,
    private authService: AuthService,
    private carrierService: ParameterService,
    private timelineService: TimelineService,
    private awsService: AwsService,
    private attachmentService: AttachmentService,
    private commentService: CommentService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false; 
  }

  ngOnInit() {
    this.route.url.subscribe((url) => {
      if (url[2].path === 'dhl') {
        this.pageKey = 'defaultRuleDhl';
        this.defaultPage = 'DHL';
      } else if (url[2].path === 'carrier') {
        this.pageKey = 'defaultRuleCarrier';
        this.defaultPage = 'Carrier';
      }

      this.titleService.setTitle(`DHL | Agreement (Standard ${this.defaultPage})`);

      this.globalFilter.setValue(this.filterService.getFilter(this.pageKey) || '');
    });

    this.route.params.subscribe((params) => {
      this.defaultRuleType = params.type;      
      this.defaultRuleTitle = (this.defaultRuleType == 'dhl' ? this.defaultRuleType.toUpperCase() : this.defaultRuleType.charAt(0).toUpperCase() + this.defaultRuleType.slice(1));
    });

    this.carrierService.getCarrier().subscribe(carriers => this.carrierList = carriers);

    if(this.defaultRuleType === 'dhl'){
      const newArr = this.defaultRuleTableDisplayedColumns.filter(column =>
        column !== 'DIM_SERVICE' && column !== 'DIM_CAR'
      )
      this.defaultRuleTableDisplayedColumns = newArr;
    }else{
      const newArr = this.defaultRuleTableDisplayedColumns.filter(column =>
        column !== 'DIM_TYPE'
      )
      this.defaultRuleTableDisplayedColumns = newArr;
    }

    const filter = this.filterService.getFilter('defaultRule' + this.defaultRuleType);

    if (filter) {
      this.filterEntity = filter;
      this.filtersNumber = this.filterService.getFilterQuantity(filter);
    }

    this.globalFilter.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.filterService.setSearch(this.pageKey, value);
      this.getDefaultRules(this.filterEntity);
    });

    this.getDefaultRules(this.filterEntity);
  }

  getDefaultRules(filter: IAgreement): void {    
    const defaultRulesType: IAgreement = {
      DIM_RUL_DEF_TYPE: this.defaultRuleType === 'dhl' ? 1 : 0,
      DIM_RUL_DEF: 1
    };

    const ref = this.pageSize > 20 ? this.toast.loading('Loading...',{autoClose: false}) : null;
    const search = this.filterService.getSearch(this.pageKey) || null;
    this.selection.clear();
    if(this.filteredDefaultRuleTableDataSource.data.length === 0) this.hideLoader = false;
    this.agreementService.getAgreements({
      ...filter,
      ...defaultRulesType,
      SEARCH: search, 
      SORT_COL: this.sortCol,
      SORT_ORDER: this.sortOrder, 
      PAGE_NUMBER: this.pageNumber, 
      PAGE_SIZE: this.pageSize
    }).subscribe((defaultRules: Array<IAgreement>) => {
      this.hideLoader = true;      
      this.defaultRuleTableDataSource.data = defaultRules;
      this.filteredDefaultRuleTableDataSource.data = defaultRules;
      this.length = defaultRules[0]?.TOTAL_ROWS;
      this.changeDetectorRef.detectChanges();
      ref?.close();
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredDefaultRuleTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.filteredDefaultRuleTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  onPaginateChange(event){
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getDefaultRules(this.filterEntity);
  }

  onSortChange(event){
    this.sortCol = event.active;
    this.sortOrder = event.direction;
    this.getDefaultRules(this.filterEntity);
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(DefaultRuleDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {
        let defaultRulesIds: Array<number> = [];
        this.selection.selected.forEach((defaultRule) => {
          defaultRulesIds.push(+defaultRule.DIM_SK_CUS_RUL);
        });

        let defaultRules: IAgreementList = {
          DIM_SK_CUS_RUL: defaultRulesIds
        }

        this.agreementService.deleteAgreements(defaultRules).subscribe((response) => {
          this.getDefaultRules(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  deleteDefaultRule(data: IAgreement) {
    const dialogRef = this.dialog.open(DefaultRuleDeleteDialogComponent, {
      data: {
        name: data.DIM_RUL_DEF_NAM
      }
    });

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {

        let defaultRulesIds: Array<number> = [];

        defaultRulesIds.push(+data.DIM_SK_CUS_RUL);

        let defaultRules: IAgreementList = {
          DIM_SK_CUS_RUL: defaultRulesIds,
          DIM_RUL_USER: this.authService.userId
        }

        this.agreementService.deleteAgreements(defaultRules).subscribe((response) => {
          this.getDefaultRules(this.filterEntity);
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  export(): void {
    const headers = {
      DIM_SK_CUS_RUL: 'DDM AGREEMENT',
      DIM_RUL_DEF_NAM: 'DESCRIPTION',
      DIM_SERVICE: 'SERVICE',
      DIM_CAR: 'CARRIER',
      DIM_TYPE: 'AGREEMENT TYPE',
      DIM_CTN_TYPE: 'CONTAINER TYPE',
      DIM_DT_STR_EFF_TEXT: 'START DATE',
      DIM_DT_FIN_EFF_TEXT: 'VALIDITY DATE',
      DIM_VL_FRT_AMT: 'FREETIME',
      DIM_PERDIEM: 'PERDIEM',
      DIM_STATUS_RUL: 'AGREEMENT STATUS',
      DIM_DT_INC_TEXT: 'CREATION DATE'
    }

    if (this.defaultRuleType === 'dhl'){
      delete headers['DIM_SERVICE'];
      delete headers['DIM_CAR'];
    }else{
      delete headers['DIM_TYPE'];
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const ref = this.toast.loading('Exporting...',{autoClose: false});
      const search = this.filterService.getSearch(this.pageKey) || null;
      this.agreementService.getAgreements({
          ...this.filterEntity,
          EXPORT: 1,
          SEARCH: search, 
          SORT_COL: this.sortCol,
          SORT_ORDER: this.sortOrder,
          DIM_RUL_DEF_TYPE: this.defaultRuleType === 'dhl' ? 1 : 0,
          DIM_RUL_DEF: 1
      }).subscribe((dataExport)=>{
        this.defaultRuleExportDataSource.data = dataExport;

        const data: Array<IAgreement> = this.prepareDataToExport([...this.defaultRuleExportDataSource.data]);
        dataToExport.push(...data);

        this.exporter.export(dataToExport, {
          fileName: `${this.defaultRuleType}StandardRules_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
          columnWidths: []
        });

        ref.close();
      });
    } else {
      const data: Array<IAgreement> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);

      this.exporter.export(dataToExport, {
        fileName: `${this.defaultRuleType}StandardRules_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
        columnWidths: []
      });
    }
  }

  prepareDataToExport(data: Array<IAgreement>): Array<IAgreement> {
    const dataToExport: Array<IAgreement> = [];

    const datesText = {
      DIM_DT_STR_EFF_TEXT: '',
      DIM_DT_FIN_EFF_TEXT: '',
      DIM_DT_INC_TEXT: ''
    }

    data.forEach((defaultRule) => {
      const newData = {...defaultRule, ...datesText};

      if(this.defaultRuleType === 'dhl'){
        delete newData.DIM_SERVICE;
        delete newData.DIM_CAR;
      }else{
        delete newData.DIM_TYPE
      }

      if(newData.DIM_DT_STR_EFF !== null) {
        newData.DIM_DT_STR_EFF_TEXT = moment(newData.DIM_DT_STR_EFF).format('DD/MM/YYYY');
      }

      if(newData.DIM_DT_FIN_EFF !== null) {
        newData.DIM_DT_FIN_EFF_TEXT = moment(newData.DIM_DT_FIN_EFF).format('DD/MM/YYYY');
      }

      newData.DIM_DT_INC_TEXT = newData.DIM_DT_INC ? moment(newData.DIM_DT_INC).format('DD/MM/YYYY') : null;

      delete newData.DIM_DT_STR_EFF;
      delete newData.DIM_DT_FIN_EFF;
      delete newData.DIM_DESCRIPTION;
      delete newData.DIM_CUS_NAME;
      delete newData.DIM_ORIGIN;
      delete newData.DIM_DESTINATION;
      delete newData.DIM_RUL_USER;
      delete newData.DIM_STATUS;
      delete newData.DIM_DT_INC;
      delete newData.DIM_CMT_ATCH;
      delete newData.DIM_DT_BID_EXP;
      delete newData.DIM_DGF;
      delete newData.DIM_CONTRACT;
      delete newData.DIM_REQUESTED;
      delete newData.DIM_APPROVED;
      delete newData.TOTAL_ROWS;
      delete newData.TOTAL_PAGES;
      delete newData.RNUM;
      dataToExport.push(newData);
    });

    return dataToExport;
  }

  openFilterDialog(): void {
    const dialog = this.dialog.open(DefaultRuleFilterDialogComponent, {
      data: {
        element: this.filterEntity,
        defaultRuleType: this.defaultRuleType,
        carrierList: this.carrierList
      }
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.filtersNumber = this.filterService.getFilterQuantity(filters);

      this.filterEntity = filters;

      this.pageNumber = 0;
      this.pageSize = this.pageSize > 20 ? 20 : this.pageSize;

      this.filterService.setFilter('defaultRule' + this.defaultRuleType, this.filterEntity);

      this.getDefaultRules(this.filterEntity);
    });
  }

  openSidebar(agreementId: number): void {
    this.agreementId = agreementId;

    this.getComments();
    this.getAttachments();
    this.getTimeline();

    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';    
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';

    this.comments = [];
    this.attachments = [];
    this.timeline = [];
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `agreement`, REG_ID: this.agreementId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: Number(this.agreementId),
          COMM_REF_GROUP: `agreement`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, (error) => {
      this.toast.error(error.error.Message)
    });
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: 'agreement', REG_ID: this.agreementId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, 'agreement', [{ REG_ID: this.agreementId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    this.timelineService.getTimeline({ TIMELINE_GROUP: 'agreement', TIMELINE_REG_ID: this.agreementId.toString() }).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }
}
