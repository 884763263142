import { IntegrationLogsDialogsModule } from './integration-logs/integration-logs-dialogs/integration-logs-dialogs.module';
import { RpaLogsDialogsModule } from './rpa-logs/rpa-logs-dialogs/rpa-logs-dialogs.module';
import { ProcessContainerDialogsModule } from './process-container/process-container-dialogs/process-container-dialogs.module';
import { PaymentOrderDialogsModule } from './payment-order/payment-order-dialogs/payment-order-dialogs.module';
import { PayablesGenerateDialogsModule } from './payables-generate/payables-generate-dialogs/payables-generate-dialogs.module';
import { UniqueTermsDialogsModule } from './unique-terms/unique-terms-dialogs/unique-terms-dialogs.module';
import { DetentionAutomaticDialogsModule } from './detention-automatic/dialogs/detention-automatic-dialogs.module';
import { DemurrageAutomaticDialogsModule } from './demurrage-automatic/dialogs/demurrage-automatic-dialogs.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametersDialogsModule } from './parameters/parameters-dialogs/parameters-dialogs.module';
import { CustomersDialogsModule } from './customers/customers-dialogs/customers-dialogs.module';
import { CustomerGroupDialogsModule } from './customer-groups/customer-groups-dialogs/customer-groups-dialogs.module';
import { LogsDialogsModule } from './logs/logs-dialogs/logs-dialogs.module';
import { UsersDialogsModule } from './users/users-dialogs/users-dialogs.module';
import { UserGroupsDialogsModule } from './user-groups/user-groups-dialogs/user-groups-dialogs.module';
import { PagesDialogsModule } from './pages/pages-dialogs/pages-dialogs.module';
import { ShipmentsDialogsModule } from './shipments/shipments-dialogs/shipments-dialogs.module';
import { AgreementDialogsModule } from './agreement/agreement-dialogs/agreement-dialogs.module';
import { AgreementsDialogsModule } from './agreements/agreements-dialogs/agreements-dialogs.module';
import { DefaultRulesDialogsModule } from './default-rules/default-rules-dialogs/default-rules-dialogs.module';
import { ShipmentDialogsModule } from './shipment/shipment-dialogs/shipment-dialogs.module';
import { DemurrageManualDialogsModule } from './demurrage-manual/dialogs/demurrage-manual-dialogs.module';
import { DetentionManualDialogsModule } from './detention-manual/dialogs/detention-manual-dialogs.module';
import { DefaultRuleDialogsModule } from './default-rule/default-rule-dialogs/default-rule-dialogs.module';
import { InvoicesDialogsModule } from './invoices/invoices-dialogs/invoices-dialogs.module';
import { PayablesDialogsModule } from './payables/payables-dialogs/payables-dialogs.module';
import { FinancialDialogsModule } from './financial-list/financial-dialogs/financial-dialogs/financial-dialogs.module';
import { ShipmentsDrtDialogsModule } from './shipments-drt/shipments-drt-dialogs/shipments-drt-dialogs.module';
import { ExceptionsDialogsModule } from './customer-exceptions/exceptions-dialogs/exceptions-dialogs.module';
import { NotificationLogsDialogsModule } from './customer-notification-logs/notification-logs-dialogs/notification-logs-dialogs.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ParametersDialogsModule,
    CustomersDialogsModule,
    CustomerGroupDialogsModule,
    LogsDialogsModule,
    UsersDialogsModule,
    UserGroupsDialogsModule,
    PagesDialogsModule,
    ShipmentsDialogsModule,
    AgreementDialogsModule,
    AgreementsDialogsModule,
    DefaultRulesDialogsModule,
    ShipmentDialogsModule,
    DemurrageManualDialogsModule,
    DetentionManualDialogsModule,
    DemurrageAutomaticDialogsModule,
    DetentionAutomaticDialogsModule,
    DefaultRuleDialogsModule,
    InvoicesDialogsModule,
    UniqueTermsDialogsModule,
    PayablesDialogsModule,
    PayablesGenerateDialogsModule,
    PaymentOrderDialogsModule,
    ProcessContainerDialogsModule,
    FinancialDialogsModule,
    RpaLogsDialogsModule,
    IntegrationLogsDialogsModule,
    ShipmentsDrtDialogsModule,
    ExceptionsDialogsModule,
    NotificationLogsDialogsModule
  ]
})
export class DialogsModule { }
