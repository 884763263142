import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dhl-agreement-dialog',
  templateUrl: './agreement-dialog.component.html',
  styleUrls: ['./agreement-dialog.component.scss']
})
export class AgreementDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AgreementDialogComponent>) { }

  ngOnInit() {
  }

  closeDialog(willClone: number): void {
    this.dialogRef.close(willClone);
  }
}
