<h1 mat-dialog-title *ngIf="data.title">Container Partial ({{data.container.CTN_NUMBER}})</h1>
<div mat-dialog-content>
  <form [formGroup]="newPrtlCtnForm" (submit)="onFormSubmit($event)">
    <div class="form-group" *ngIf="data.mode != 'delete'">
      <mat-form-field>
        <mat-label>Arrival</mat-label>
        <input matInput type="text" name="ctnStart" formControlName="ctnStart" [matDatepicker]="pickerStart" disabled>
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Empty Return</mat-label>
        <input matInput type="text" name="ctnGou" formControlName="ctnGou" [matDatepicker]="pickerGou" [min]="minGou" [max]="maxGou">
        <mat-datepicker-toggle matSuffix [for]="pickerGou"></mat-datepicker-toggle>
        <mat-datepicker #pickerGou></mat-datepicker>
        <mat-error *ngIf="newPrtlCtnForm.get('ctnGou').hasError('required')">You must enter a empty return date</mat-error>
        <mat-error *ngIf="newPrtlCtnForm.get('ctnGou').hasError('freetimeInvalid')">Not possible to generate parcial with this date</mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="data.mode == 'delete'">Do you really wish to delete the dates of {{data.prtlCtn.CTN_ID}}?</p>
    <div mat-dialog-actions *ngIf="data.mode != 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'add'" [disabled]="newPrtlCtnForm.invalid">Save</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="data.mode == 'delete'">Delete</button>
    </div>
    <div mat-dialog-actions *ngIf="data.mode == 'edit'">
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-flat-button type="button" color="accent" *ngIf="!isFormEnabled" (click)="enableForm();">Edit</button>
      <button mat-flat-button type="submit" color="accent" *ngIf="isFormEnabled" [disabled]="areInputsValid()">Ok</button>
    </div>
  </form>
</div>
