<div [ngClass]="{'customer-list': this.customerTableDataSource.data.length != 0}">
  <h1 mat-dialog-title>Customers of {{data.customerGroup.CUS_GRP_NAME}}</h1>
  <div class="actions-container" *ngIf="this.customerTableDataSource.data.length != 0">
    <mat-form-field class="customer-group-search">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix color="primary">search</mat-icon>
      <input matInput type="search" [(ngModel)]="filterValue" (input)="filterCustomerTable(filterValue)">
      <button type="button" mat-icon-button matSuffix (click)="filterValue = ''; filterCustomerTable(filterValue)" [attr.aria-label]="'Hide password'" *ngIf="filterValue != ''">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div mat-dialog-content>
    <div class="table-container" [ngClass]="{'hidden': this.customerTableDataSource.data.length == 0}" *ngIf="hasData">
      <table #customerTable mat-table [dataSource]="customerTableDataSource" matSort #customerSort="matSort">
        <ng-container matColumnDef="CUS_CODE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">Code</th>
          <td mat-cell *matCellDef="let element">{{element.CUS_CODE}}</td>
        </ng-container>
        <ng-container matColumnDef="CUS_NAME">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
          <td mat-cell *matCellDef="let element">{{element.CUS_NAME}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="customerTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: customerTableDisplayedColumns;"></tr>
      </table>
    </div>
    <p *ngIf="!hasData">Empty customer group.</p>
    <div mat-dialog-actions>
      <button mat-flat-button type="button" color="accent" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>


