export interface IFinancial {
  FBF_ID?: number;
  FBF_JOB_NUM?: string;
  FBF_SALES_GROUP?: string;
  FBF_CHARGE_GROUP?: string;
  FBF_CHARGE_CODE?: string;
  FBF_CHARGE_CODE_DESC?: string;
  FBF_TRN_BRANCH?: string;
  FBF_TRN_DEPT?: string;
  FBF_TRN_DESC?: string;
  FBF_LINE_TYPE?: string;
  FBF_TRN_RECOG_DT?: Date;
  FBF_INVOICE_NUMBER?: string;
  FBF_INVOICE_DATE?: Date;
  FBF_LOCAL_AMOUNT?: number;
  FBF_APPORTIONED?: string;
  FBF_MOST_RELEV_CONSOL?: string;
  FBF_LEDGER_TYPE?: string;
  FBF_TRN_TYPE?: string;
  FBF_TRN_ORG?: string;
  FBF_TRN_ORG_NAME?: string;
  FBF_POSTING_DT?: Date;
  FBF_ACCT_GRP_CD?: string;
  FBF_JOB_RECOG_MTH_YYYYMM?: string;
  FBF_TRN_RECOG_MTH_YYYYMM?: string;
  FBF_OUT_OF_PERIOD?: string;
  FBF_IS_AUTO_RATED?: string;
  FBF_RATING_OVERRIDDEN?: string;
  FBF_CFX_PERCENT_TYPE?: string;
  FBF_CREATED_USER?: string;
  FBF_JOB_STATUS?: string;
  FBF_JOB_BRANCH?: string;
  FBF_JOB_DEPT?: string;
  FBF_JOB_OPEN_DT?: Date;
  FBF_JOB_RECOG_DT?: number;
  FBF_JOB_CLOSED_DT?: Date;
  FBF_JOB_SL_REP?: string;
  FBF_JOB_NUM_COMPANY?: string;
  FBF_TRN_INCOME?: number;
  FBF_TRN_PROFIT?: number;
  FBF_TRN_EXPENSE?: string;
  FBF_INCOME_EUR?: number;
  FBF_TRN_PROFIT_EUR?: number;
  FBF_EXPENSE_EUR?: number;
  FBF_OS_VAT?: number;
  FBF_TRANSPORT_MODE?: string;
  FBF_OS_AMOUNT?: number;
  FBF_EXCHANGE_RATE?: number;
  FBF_OS_CCY?: string;
  FBF_LOCAL_CLIENT?: string;
  FBF_SHIP_SUPPLIER?: string;
  FBF_CONSIGNEE_IMPORTER?: string;
  FBF_HAWB?: string;
  FBF_MAWB?: string;
  FBF_ORIGIN_ETD?: number;
  FBF_DESTINATION_ETA?: number;
  FBF_SUMARY_DESC?: string;
  FBF_DEBTOR_CODE?: string;
  FBF_POST_PERIOD?: number;
  FBF_POST_PERIOD_YEAR?: number;
  FBF_CLIENT_NAME?: string;
  FBF_INCLUDED_DT?: Date;
}

export interface IFinancialImport{
  ['JOB NUM']?: string;
  ['SALES GROUP']?: string;
  ['CHARGE GROUP']?: string;
  ['CHARGE CODE']?: string;
  ['CHARGE CODE & DESC']?: string;
  ['TRN BRANCH']?: string;
  ['TRN DEPT']?: string;
  ['TRN DESC']?: string;
  ['LINE TYPE']?: string;
  ['TRN RECOG DATE']?: string;
  ['INVOICE NUMBER']?: string;
  ['INVOICE DATE']?: string;
  ['LOCAL AMOUNT']?: string;
  ['IS APPORTIONED (Y/N)']?: string;
  ['MOST RELEVANT CONSOL']?: string;
  ['LEDGER TYPE']?: string;
  ['TRN TYPE']?: string;
  ['TRN ORG']?: string;
  ['TRN ORG NAME']?: string;
  ['POSTING DATE']?: string;
  ['ACCT GRP CD']?: string;
  ['JOB RECOG MONTH (YYYY/MM)']?: string;
  ['TRN RECOG MONTH (YYYY/MM)']?: string;
  ['OUT OF PERIOD']?: string;
  ['IS AUTO RATED']?: string;
  ['RATING OVERRIDDEN']?: string;
  ['CFX PERCENT TYPE']?: string;
  ['CREATED USED']?: string;
  ['JOB STATUS']?: string;
  ['JOB BRANCH']?: string;
  ['JOB DEPT']?: string;
  ['JOB OPEN DATE']?: string;
  ['JOB RECOG DATE']?: string;
  ['JOB CLOSE DATE']?: string;
  ['JOB SALES REP']?: string;
  ['JOB NUMBER COMPANY']?: string;
  ['TRN INCOME']?: string;
  ['TRN PROFIT']?: string;
  ['TRN EXPENSE']?: string;
  ['INCOME EUR']?: string;
  ['TRN PROFIT EUR']?: string;
  ['EXPENSE EUR']?: string;
  ['OS VAT']?: string;
  ['TRANSPORT MODE']?: string;
  ['OS AMOUNT']?: string;
  ['EXCHANGE RATE']?: string;
  ['OS CCY']?: string;
  ['LOCAL CLIENT']?: string;
  ['SHIPPER SUPPLIER']?: string;
  ['CONSIGNEE IMPORTER']?: string;
  ['HAWB']?: string;
  ['MAWB']?: string;
  ['ORIGIN ETD']?: string;
  ['DESTINATION ETA']?: string;
  ['SUMMARY DESC']?: string;
  ['DEBTOR CODE']?: string;
  ['POSTING PERIOD (MONTH)']?: string;
  ['POSTING PERIOD (YEAR)']?: string;
  ['CLIENT NAME']?: string;
}

export interface IFinancialYear {
  FIN_YEAR?: number;
  FIN_MONTH?: number;
  FIN_EXP?: number;
  FIN_IMP?: number;
  FIN_REV_BRL?: number;
  FIN_COS_BRL?: number;
  FIN_ACT_BRL?: number;
  FIN_ACT_EUR?: number;
  FIN_PY_EUR?: number;
  FIN_BUD_EUR?: number;
  FIN_ACT_BUD?: number;
}

export interface IFinancialMonth {
  FIN_YEAR?: number;
  FIN_MONTH?: number;
  FIN_ACT_EUR?: number;
  FIN_LAST_EUR?: number;
  FIN_PY_EUR?: number;
  FIN_BUD_EUR?: number;
  FIN_ACT_BUD?: number;
}

export interface IFinancialYearTeste {
  FR_YEAR?: number;
  FR_MONTH?: number;
  FR_EXP?: number;
  FR_IMP?: number;
  FR_REV_BRL?: number;
  FR_COS_BRL?: number;
  FR_ACT_BRL?: number;
  FR_ACT_EUR?: number;
  FR_PY_EUR?: number;
  FR_BUD_EUR?: number;
  FR_ACT_BUD?: number;
}

export interface IFinancialMonthTeste {
  FR_YEAR?: number;
  FR_MONTH?: number;
  FR_ACT_EUR?: number;
  FR_LAST_EUR?: number;
  FR_PY_EUR?: number;
  FR_BUD_EUR?: number;
  FR_ACT_BUD?: number;
}

export interface IFinancialRate{
  FRR_ID?: number;
  FRR_RATE?: number;
  FRR_YEAR?: number;
  FRR_USER?: string;
}

export interface IFinancialBudget{
  BDGT_ID?: number;
  BDGT_VALUE?: number;
  BDGT_MONTH?: number;
  BDGT_YEAR?: number;
  BDGT_USER?: string;
}

export interface IFinancialCustomer{
  REP_CUS_YEAR?: number;
  REP_CUS_MONTH?: number;
  REP_CUS_CODE?: string;
  REP_CUS_NAME?: string;
  REP_CUS_REV?: number;
  REP_CUS_COS?: number;
  REP_CUS_ACT_BRL?: number;
  REP_CUS_ACT_EUR?: number;
  REP_CUS_PY_EUR?: number;
  REP_CUS_LIST?: string;
  REP_MONTH_LIST?: string;
}

export interface IFinancialTopCustomer{
  TOP_CUS_ID?: number;
  TOP_CUS_YEAR?: number;
  TOP_CUS_NAME?: string;
  TOP_CUS_PDT?: string;
  TOP_CUS_JAN?: number;
  TOP_CUS_FEV?: number;
  TOP_CUS_MAR?: number;
  TOP_CUS_APR?: number;  
  TOP_CUS_MAY?: number;
  TOP_CUS_JUN?: number;
  TOP_CUS_JUL?: number;
  TOP_CUS_AUG?: number;
  TOP_CUS_SEP?: number;
  TOP_CUS_OCT?: number;
  TOP_CUS_NOV?: number;
  TOP_CUS_DEC?: number;
  TOP_CUS_REV?: number;
  TOP_CUS_LIST?: string;
}


