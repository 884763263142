import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dhl-customer-groups-delete-all-dialog',
  templateUrl: './customer-groups-delete-all-dialog.component.html',
  styleUrls: ['./customer-groups-delete-all-dialog.component.scss']
})
export class CustomerGroupsDeleteAllDialogComponent {

  constructor(public dialogRef: MatDialogRef<CustomerGroupsDeleteAllDialogComponent>) { }

  closeDialog(willDelete: boolean) {
    this.dialogRef.close(willDelete);
  }
}
