import { PagesViewDialogComponent } from './pages-dialogs/pages-view-dialog/pages-view-dialog.component';
import { IPageList } from '../../models/page-list.model';
import { PageActionDialogComponent } from './pages-dialogs/page-action-dialog/page-action-dialog.component';
import { PagesDeleteAllDialogComponent } from './pages-dialogs/pages-delete-all-dialog/pages-delete-all-dialog.component';
import { PagesDialogComponent, IPagesDialog } from './pages-dialogs/pages-dialog/pages-dialog.component';
import { PageService } from '../../services/page.service';
import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import { XlsxExporterService } from 'mat-table-exporter';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { HotToastService } from '@ngneat/hot-toast';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableFilter } from 'mat-table-filter';
import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { IPage } from 'src/app/models/page.model';
import { IJoinGroupRelPageList } from 'src/app/models/join-group-rel-page.model';
import { IUserGroup } from 'src/app/models/user-group.model';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'dhl-page',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  pageKey = 'pages';
  hideLoader: boolean;
  initialSelection = [];
  allowMultiSelect = true;
  selection = new SelectionModel<IPage>(this.allowMultiSelect, this.initialSelection);
  
  globalFilter: FormControl = new FormControl(this.filterService.getSearch(this.pageKey) || '');
  
  page: IPage[] = [];
  //filterType: MatTableFilter;
  //filtersNumber: number = 0;
  //filterEntity: IPage;

  pagesTableDataSource = new MatTableDataSource<IPage>();
  //filteredPagesTableDataSource = new MatTableDataSource<IPage>();
  pagesTableDisplayedColumns: Array<string> = ['select', 'PAG_NAME', 'PAG_URL', 'pageGroups', 'actions'];

  @ViewChild(MatTable) pagesTable: MatTable<IPage>;
  @ViewChild('pagesSort') pagesSort: MatSort;
  @ViewChild('pagesPaginator') pagesPaginator: MatPaginator;

  constructor(
    private titleService: Title,
    private pageService: PageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private toast: HotToastService,
    private authService: AuthService,
    private exporter: XlsxExporterService,
    private filterService: FilterService
  ) {
    this.titleService.setTitle('DHL | Users (Pages)');
   }

  ngOnInit() {
    this.getPages();

    this.globalFilter.valueChanges.subscribe((value) => {
      this.filterService.setSearch(this.pageKey, value);
      this.filterPagesTable(value);
    });
  }

  getPages(): void {
    if(!this.pagesTableDataSource.filteredData) this.hideLoader = false;
    this.pageService.getPage().subscribe((pages: Array<IPage>) => {
      this.hideLoader = true;
      pages.forEach((page) => {
        delete page.PAG_USER;
        delete page.PAG_STATUS;
      });
      this.pagesTableDataSource.data = pages;
      this.changeDetectorRef.detectChanges();
      this.pagesTableDataSource.sort = this.pagesSort;
      this.pagesTableDataSource.paginator = this.pagesPaginator;

      this.filterPagesTable(this.globalFilter.value);
    });
  }

  filterPagesTable(filter: string) {
    this.pagesTableDataSource.filter = filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.pagesTableDataSource.filteredData.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.pagesTableDataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  isOverflow(element){
    if(element){
      return element.length > 20;
    }
  }

  openPagesDialog(data: IPagesDialog): void {
    const dialogRef = this.dialog.open(PagesDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((page: IPage) => {
      if(!page) return;

      if(data.mode == 'add') {
        this.pageService.postPage(page).subscribe((response) => {
          this.toast.success(String(response));
          this.getPages();
        }, (error) => {
          this.toast.error(error.error.Message);
          this.getPages();
        });
      }
      else {
        this.pageService.putPage(page).subscribe((response) => {
          if(data.mode == 'edit') {
            this.toast.success(String(response));
          }
          else {
            this.toast.success('Page removed successfuly');
          }
          this.getPages();
        }, (error) => {
          this.toast.error(error.error.Message);
          this.getPages();
        })
      }
    });
  }

  openPagesActionDialog(): void {
    const dialogRef = this.dialog.open(PageActionDialogComponent);

    dialogRef.afterClosed().subscribe((userGroup: IUserGroup) => {
      if (!userGroup) return;

      let pageIds: Array<number> = [];
      this.selection.selected.forEach((page) => {
        pageIds.push(page.PAG_ID);
      });

      const joinGroupRelPage: IJoinGroupRelPageList = {
        PAG_ID: pageIds,
        GRP_ID: [userGroup.GRP_ID],
        GRP_PAG_USER: this.authService.userId
      }

      this.pageService.putPagesInGroup(joinGroupRelPage).subscribe((response) => {
        this.getPages();
        this.selection.clear();
        this.toast.success(String(response));
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  openPagesViewDialog(data: IPage): void{
    this.dialog.open(PagesViewDialogComponent, {
      data: data
    });
  }

  deleteSelected(): void {
    const dialogRef = this.dialog.open(PagesDeleteAllDialogComponent);

    dialogRef.afterClosed().subscribe((willDelete: boolean) => {
      if (willDelete) {
        let pagesIds: Array<number> = [];
        this.selection.selected.forEach((page) => {
          pagesIds.push(page.PAG_ID);
        });

        let pages: IPageList = {
          LIST_PAG_ID: pagesIds,
          PAG_USER: this.authService.userId
        }

        this.pageService.deleteSelectedPages(pages).subscribe((response) => {
          this.getPages();
          this.selection.clear();
          this.toast.success(String(response));
        }, (error) => {
          this.toast.error(error.error.Message);
        });
      }
    });
  }

  export(): void {
    const headers = {
      PAG_NAME: 'NAME',
      PAG_URL: 'URL',
      GRP_NAME_LIST: 'GROUPS'
    }

    const dataToExport: Array<any> = [headers];

    if (this.selection.isEmpty()) {
      const data: Array<IPage> = this.prepareDataToExport([...this.pagesTableDataSource.filteredData]);
      dataToExport.push(...data);
    } else {
      const data: Array<IPage> = this.prepareDataToExport([...this.selection.selected]);
      dataToExport.push(...data);
    }

    this.exporter.export(dataToExport, {
      fileName: `pages_${formatDate(new Date(), 'dd-MM-yyyy_HH.mm.ss', 'en-US')}`,
      columnWidths: []
    });
  }

  prepareDataToExport(data: Array<IPage>): Array<IPage> {
    const dataToExport: Array<IPage> = [];

    data.forEach((page) => {
      const newData = {...page};
      delete newData.PAG_ID;
      delete newData.GRP_LIST;
      delete newData.PAG_STATUS;
      delete newData.PAG_USER;
      dataToExport.push(newData);
    });

    return dataToExport;
  }
}
