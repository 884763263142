<h1 mat-dialog-title>Generate Extra</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Total BRL</mat-label>
        <input matInput type="number" name="totalBrl" formControlName="totalBrl">
        <mat-error *ngIf="form.get('totalBrl').invalid">{{getErrorMessage('totalBrl')}}</mat-error>
      </mat-form-field>
    </div>        
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="form.invalid">Apply</button>
    </div>
  </form>
</div>
