import { IPayable } from 'src/app/models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IDiscountDialog {
  element?: IPayable | Array<IPayable>;
}

@Component({
  selector: 'app-discount-dialog',
  templateUrl: './discount-dialog.component.html',
  styleUrls: ['./discount-dialog.component.scss']
})
export class DiscountDialogComponent implements OnInit {
  form: UntypedFormGroup;
  minValue: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDiscountDialog,
    private dialogRef: MatDialogRef<DiscountDialogComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      value: new UntypedFormControl(this.data.element instanceof Array ? '' : this.data.element?.PB_DIS, Validators.required),
      type: new UntypedFormControl(this.data.element instanceof Array ? '' : String(this.data.element?.PB_DIS_TYPE), Validators.required)
    });

    if(this.data.element instanceof Array){
      let arrayValues: Array<number> = [];
      this.data.element?.map((item)=>{ arrayValues.push(item?.PB_VAL_USD) });
      this.minValue = Math.min(...arrayValues);
    }else{
      this.minValue = this.data.element.PB_VAL_USD;
    }

    console.log(this.minValue);
  }

  close(value: Array<IPayable>): void {
    this.dialogRef.close(value);
  }

  handleSubmit(e: Event): void {
    e.preventDefault();
    let newPayable: Array<IPayable> = [];

    if (this.data.element instanceof Array) {
      newPayable = this.data.element.map((element) => {
        return {
          PB_ID: element.PB_ID,
          PB_VAL_USD: element.PB_VAL_USD,
          PB_DIS: this.form.value.value,
          PB_DIS_TYPE: this.form.value.type,
          PB_USER: this.authService.userId
        }
      });
    } else {
      newPayable.push({
        PB_ID: this.data.element.PB_ID,        
        PB_VAL_USD: this.data.element.PB_VAL_USD,
        PB_DIS: this.form.value.value,
        PB_DIS_TYPE: this.form.value.type,
        PB_USER: this.authService.userId
      });
    }

    this.close(newPayable);
  }

  maxDiscount(): number {
    if (this.form.get('type').value === '0') {
      return 100;
    } else {
      if (!(this.data.element instanceof Array)) {
        return Number(this.data.element?.PB_VAL_USD);
      }
    }
  }

  getErrorMessage(formControl: string): string {
    if (this.form.get(formControl).hasError('number')) {
      return 'The value must be a number';
    } else if (this.form.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

}
