<div class="sidebar" [ngClass]="{open: isOpen}">
  <div class="sidebar-wrapper">
    <div class="top-container">
      <h2>Details</h2>
      <button mat-icon-button aria-label="Close sidebar" (click)="close.emit(this.isOpen)">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<div class="dark-overlay" [ngClass]="{visible: isOpen}" (click)="close.emit(this.isOpen)"></div>
