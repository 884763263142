import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { IAttachment } from 'src/app/models/attachment.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dhl-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {
  @Input() attachments: Array<IAttachment> = [];
  @Input() showAttachmentsTab: boolean = true;
  @Input() showUploadTab: boolean = true;
  @Input() showRenameFile: boolean = false;
  @Input() offSideAttachments: boolean = false;
  @Output() upload: EventEmitter<Array<File>> = new EventEmitter<Array<File>>();

  protected bucketName = environment.bucketName;
  bucketUrl = `https://${this.bucketName}.s3.amazonaws.com/`

  filesToUpload: Array<File> = [];
  filesRename: Array<File> = [];
  tabIndex: number = 0;
  types: Array<string> = [
    'application/vnd.ms-outlook',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'image/jpeg',
    'image/gif',
    'image/png',
    'text/csv',
    'text/plain',
    'message/rfc822'
  ];

  constructor(
    private toast: HotToastService
  ) { }

  ngOnInit(): void { }

  onSelect(e: NgxDropzoneChangeEvent): void {
    if (e.rejectedFiles.length !== 0) {
      this.toast.error('File extension not accepted or file too big');
    }

    this.filesToUpload.push(...e.addedFiles);
    this.filesRename.push(...e.addedFiles);

    if (this.offSideAttachments){
      this.upload.emit(this.filesToUpload);
    }
    
  }

  inputText(value:string, index:number){
    const newFile = new File([this.filesToUpload[index]], this.renameFile(value, this.filesToUpload[index].name));
    this.filesRename[index] = newFile;
    this.upload.emit(this.filesRename);    
  }

  renameFile(newFileName:string, fileName: string): string{    
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
    return `${newFileName}${fileExtension}`;
  }

  onRemove(index: number): void {
    this.filesToUpload.splice(index, 1);
    this.filesRename.splice(index, 1);

    if (this.offSideAttachments){
      this.upload.emit(this.filesRename);
    }
  }

  onUpload(): void {
    this.upload.emit(this.filesToUpload);
    this.filesToUpload = [];
    this.tabIndex = 0;
  }
}
