<main>
  <div class="logo-container">
    <a [routerLink]="['/']">
      <img src="assets/img/dhl-logo.svg" alt="DHL">
    </a>
  </div>
  <div class="not-found-content">
    <div class="not-found">
      <h1>404</h1>
      <h2>not found</h2>
    </div>
    <div class="redirect">
      <p>The page you were looking for does not exist.</p>
      <a mat-flat-button color="accent" [routerLink]="['/']">Return to Start</a>
    </div>
  </div>
</main>
