<header>
  <h2>Customer - Groups</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="deleteSelected()">
          <mat-icon>delete</mat-icon>
          <span>Delete All</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="openCustomerGroupDialog({title: 'Add customer group', mode: 'add'})">Create Customer Group</button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="customerGroupTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #customerGroupTable [dataSource]="customerGroupTableDataSource" matSort #customerGroupSort="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="CUS_GRP_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">ID</th>
        <td mat-cell *matCellDef="let element">{{element.CUS_GRP_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="CUS_GRP_NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">Name</th>
        <td mat-cell *matCellDef="let element">{{element.CUS_GRP_NAME}}</td>
      </ng-container>
      <ng-container matColumnDef="customers">
        <th mat-header-cell *matHeaderCellDef>Customers</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="openCustomerGroupViewDialog({customerGroup: element})" *ngIf="element.CUS_NAME_LIST">View Customers</button>
          <p class="empty-group" *ngIf="element.CUS_NAME_LIST == null">Empty Group</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" (click)="openCustomerGroupDialog({title: 'Edit customer group', mode: 'edit', customerGroup: element})">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button mat-icon-button (click)="openCustomerGroupDialog({title: 'Delete customer group', mode: 'delete', customerGroup: element})">
            <mat-icon color="primary">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="customerGroupTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: customerGroupTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator #customerGroupPaginator [pageSizeOptions]="[5, 10, 15, 20]" pageSize="20" showFirstLastButtons aria-label="Select page of customer group"></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
