import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private _filters: Map<string, any> = new Map();
  private _searches: Map<string, string> = new Map();

  constructor() { }

  setFilter(key: string, value: any): void {
    this._filters.set(key, value);
  }

  getFilter(key: string): any {
    return this._filters.get(key);
  }

  setSearch(key: string, value: string): void {
    this._searches.set(key, value);
  }

  getSearch(key: string): string {
    return this._searches.get(key);
  }

  getFilterQuantity(filter: Object): number {
    return Object.values(filter).filter(value => value).length;
  }
}