import {  debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/customer.service';
import { IPayable } from 'src/app/models/payable.model';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier, IContainerType } from 'src/app/models/parameter.model';
import { Subject } from 'rxjs';
import { ParameterService } from 'src/app/services/parameter.service';

export interface IFilterDialog {
  element: IPayable;
  carrierList: Array<ICarrier>;
}

@Component({
  selector: 'dhl-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: UntypedFormGroup;

  customers: Array<ICustomer> = [];
  carriers: Array<ICarrier> = [];
  filteredCarriers: Array<ICarrier> = [];

  containerTypes: Array<IContainerType> = [];
  filteredContainerTypes: Subject<Array<IContainerType>> = new Subject<Array<IContainerType>>();
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialog
  ) { }

  ngOnInit(): void {
    this.carriers = this.data?.carrierList;
    this.filteredCarriers = this.data?.carrierList;
    
    this.form = this.formBuilder.group({
      service: new UntypedFormControl(this.data?.element.PRC_SER),
      invoiceStatus: new UntypedFormControl(this.data?.element.PRC_INV_SITUATION),
      invNumber: new UntypedFormControl(this.data?.element.PB_INV_NUM),
      container: new UntypedFormControl(this.data?.element.PB_CTN),
      containerType: new UntypedFormControl(this.data.element?.PB_CTN_TYPE),
      containerTypeFilter: new UntypedFormControl(''),
      hbl: new UntypedFormControl(this.data?.element.PRC_HBL),
      mbl: new UntypedFormControl(this.data?.element.PB_MBL),
      customer: new UntypedFormControl(this.data?.element.PRC_CUS),
      customerFilter: new UntypedFormControl(''),
      carrier: new UntypedFormControl(this.data?.element.PB_CAR),
      carrierFilter: new UntypedFormControl(''),
      status: new UntypedFormControl(this.data?.element.PB_PRC_ST_LIST?.split(';')),
      subStatus: new UntypedFormControl(this.data?.element.PB_PRC_SUB_ST_LIST?.split(';')),
      verificationStatus: new UntypedFormControl(this.data?.element.PB_VER_ST_LIST?.split(';')),
      incStart: new UntypedFormControl(this.data?.element.PB_INC_START_DT),
      incFinal: new UntypedFormControl(this.data?.element.PB_INC_FINAL_DT)
    }); 

    this.getCustomers({ CUS_ID: this.form.get('customer').value, IS_SELECT: 1, CUS_COUNTRY: 'BR' });
    this.getContainerTypes();

    this.form.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.filterCustomers(value));

    this.form.get('containerTypeFilter')?.valueChanges.subscribe((value) => this.filterContainerTypes(value));

    this.form.get('carrierFilter').valueChanges.subscribe(value => this.filterCarriers(value));
  }

  getCustomers(filter: ICustomer): void{
    this.customerService.getCustomer(filter).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  getContainerTypes(): void{
    this.parameterService.getContainerType().subscribe((containerTypes)=>{
      this.containerTypes = containerTypes;
      this.filteredContainerTypes.next(containerTypes);
    });
  }

  filterCustomers(filter: string): void {
    if (!this.form.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1, CUS_COUNTRY: 'BR' }).subscribe((customers)=>{
      this.customers = customers;
    });
  }

  filterContainerTypes(value: string): void {
    this.filteredContainerTypes.next(this.containerTypes.filter((containerType) => containerType.CONTYPPF_QAMPZ.toLowerCase().includes(value.toLowerCase())));
  }
  
  filterCarriers(filter: string): void {
    this.filteredCarriers = this.data.carrierList.filter((element) => {
      return element.RELATIPF_APCPTK.toLowerCase().includes(filter.toLowerCase().trim()) || 
             element.RELATIPF_APA3CD.toLowerCase().includes(filter.toLowerCase().trim());
    });
  }

  fieldHasValue(formField: string): boolean {
    return this.form.get(formField).value;
  }

  resetField(formField: string): void {
    this.form.get(formField).setValue('');
  }

  close(filters: IPayable): void {
    this.dialogRef.close(filters);
  }

  resetForm(): void {
    this.form.setValue({
      service: null,
      invoiceStatus: null,
      invNumber: null,
      container: null,
      hbl: null,
      mbl: null,
      customer: null,
      customerFilter: '',
      containerType: null,
      containerTypeFilter: '',
      carrier: null,
      carrierFilter: '',
      status: null,
      subStatus: null,
      verificationStatus: null,
      incStart: null,
      incFinal: null
    })
  }

  handleSubmit(e: Event): void {
    e.preventDefault();

    let filter: IPayable = {
      PRC_SER: this.form.value.service,
      PRC_INV_SITUATION: this.form.value.invoiceStatus || null,
      PB_INV_NUM: this.form.value.invNumber,
      PB_CTN: this.form.value.container,
      PB_CTN_TYPE: this.form.value.containerType,
      PRC_HBL: this.form.value.hbl,
      PB_MBL: this.form.value.mbl,
      PRC_CUS: this.form.value.customer,
      PB_CAR: this.form.value.carrier,
      PB_PRC_ST_LIST: this.form.value.status ? this.form.value.status?.join(';') : null,
      PB_PRC_SUB_ST_LIST: this.form.value.subStatus ? this.form.value.subStatus?.join(';') : null,
      PB_VER_ST_LIST: this.form.value.verificationStatus ? this.form.value.verificationStatus?.join(';') : null,
      PB_INC_START_DT: this.form.value.incStart,
      PB_INC_FINAL_DT: this.form.value.incFinal
    }

    this.dialogRef.close(filter);
  }
}
