<h1 mat-dialog-title>Filter Notification Logs</h1>
<div mat-dialog-content>
  <form [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Customer</mat-label>
        <mat-select name="customer" formControlName="customer">
          <mat-option>
            <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_NAME}}</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('customer'); resetField('customerFilter');" *ngIf="fieldHasValue('customer')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Invoice ID</mat-label>
        <input matInput type="text" name="invoiceId" formControlName="invoiceId">
        <button type="button" mat-icon-button matSuffix (click)="resetField('invoiceId');" *ngIf="fieldHasValue('invoiceId')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status">
          <mat-option value="Active">Active</mat-option>
          <mat-option value="Closed">Closed</mat-option>
          <mat-option value="Dispute">Dispute</mat-option>
          <mat-option value="Pending">Pending</mat-option>
          <mat-option value="Failure">Failure</mat-option>
        </mat-select>
        <button mat-icon-button type="button" matSuffix (click)="resetField('status');" *ngIf="fieldHasValue('status')">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>    
    <div mat-dialog-actions>
      <button mat-button type="button" color="accent" (click)="closeDialog(null)">Cancel</button>
      <button mat-stroked-button type="button" color="accent" (click)="reset()">Reset All</button>
      <button mat-flat-button type="submit" color="accent">Apply</button>
    </div>
  </form>
</div>