import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IContainerType } from 'src/app/models/parameter.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

export interface IContainerTypeDialog {
  title?: string;
  mode: 'add' | 'edit' | 'delete';
  containerType?: IContainerType;
}

@Component({
  selector: 'container-type-dialog',
  templateUrl: 'container-type-dialog.component.html',
  styleUrls: ['container-type-dialog.component.scss']
})
export class ContainerTypeDialogComponent implements OnInit {
  containerTypeForm: UntypedFormGroup;
  isFormEnabled: boolean = true;
  
  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<ContainerTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IContainerTypeDialog, private authService: AuthService) {
    this.data = data;
  }

  ngOnInit(): void {
    this.containerTypeForm = this.formBuilder.group({
      code: new UntypedFormControl(this.data.containerType?.CONTYPPF_QAMPZ, [Validators.required]),
      teus: new UntypedFormControl(this.data.containerType?.CONTYPPF_QAMQB, [Validators.required, Validators.pattern('[0-9]*')]),
      description: new UntypedFormControl(this.data.containerType?.CONTYPPF_QAMQA, [Validators.required])
    });

    if (this.data.mode == 'edit') {
      this.isFormEnabled = false;
      this.containerTypeForm.disable();
    }
  }

  closeDialog(containerType: IContainerType): void {
    this.dialogRef.close(containerType);
  }

  enableForm(): void {
    this.isFormEnabled = true;
    this.containerTypeForm.get('description').enable();
    this.containerTypeForm.get('teus').enable();
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.containerTypeForm.value;
    let newContainerType: IContainerType;

    if (this.data.mode == 'add') {
      newContainerType = {
        CONTYPPF_QAMPZ: formValue.code,
        CONTYPPF_QAMQA: formValue.description,
        CONTYPPF_QAMQB: formValue.teus,
        CON_TYPE_USER: this.authService.userId
      }
    } else if (this.data.mode == 'edit') {
      newContainerType = {
        DIM_SK_CTN_TYP: this.data.containerType.DIM_SK_CTN_TYP,
        CONTYPPF_QAMPZ: formValue.code,
        CONTYPPF_QAMQA: formValue.description,
        CONTYPPF_QAMQB: formValue.teus,
        CON_TYPE_STATUS: 1,
        CON_TYPE_USER: this.authService.userId
      }
    } else if(this.data.mode == 'delete') {
      newContainerType = {
        DIM_SK_CTN_TYP: this.data.containerType.DIM_SK_CTN_TYP,
        CONTYPPF_QAMPZ: null,
        CONTYPPF_QAMQA: null,
        CONTYPPF_QAMQB: null,
        CON_TYPE_STATUS: 0,
        CON_TYPE_USER: this.authService.userId
      }
    }

    this.closeDialog(newContainerType);
  }

  getContainerTypeErrorMessage(): string {
    if (this.containerTypeForm.get('code').hasError('required')) {
      return 'You must enter a code';
    } else {
      return '';
    }
  }

  getTeusErrorMessage(): string {
    const teus = this.containerTypeForm.get('teus');

    if (teus.hasError('required')) {
      return 'You must enter a TEUS quantity';
    } else if (teus.hasError('pattern')) {
      return 'Must be a number';
    } else {
      return '';
    }
  }

  getDescriptionErrorMessage(): string {
    if (this.containerTypeForm.get('description').hasError('required')) {
      return 'You must enter a description';
    } else {
      return '';
    }
  }

  areInputsValid(): boolean {
    const code = this.containerTypeForm.get('code');
    const teus = this.containerTypeForm.get('teus');
    const description = this.containerTypeForm.get('description');

    return code.invalid || teus.invalid || description.invalid;
  }
}